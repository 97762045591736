import React from 'react';
import get from 'lodash/get';
import {
  Table,
  Glyphicon,
} from 'react-bootstrap';
import upperFirst from 'lodash/upperFirst';

import ImageModal from '../ImageModal';
import NO_UPLOADED_IMAGES from '../images/no-uploaded-images@3x.png';

const FavoritePropsModal = ({
  zoomImageIndex,
  images,
  showImage,
  close,
  previousImage,
  nextImage,
}) => {
  const zoomImage = images[zoomImageIndex] || {};
  const firstImage = get(zoomImage, 'images[0]', {});
  const hasFirstImage = !!get(zoomImage, 'images[0]');
  const imageURL = hasFirstImage ? firstImage.filename : NO_UPLOADED_IMAGES;

  const headerText = (
    <h5>
      {zoomImage.slug} ({zoomImageIndex + 1}/{images.length})
    </h5>
  );

  const footer = (
    <Table condensed>
      <thead>
        <tr>
          <th>ID</th>
          <th>Favorite Status</th>
          <th>In Stock ?</th>
          <th>Source</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{zoomImage.id}</td>
          <td>{upperFirst(zoomImage.like === 1 ? 'Liked': 'Disliked')}</td>
          <td>
            <Glyphicon glyph={zoomImage.out_of_stock ? 'remove' : 'ok'} />
          </td>
          <td>{zoomImage.source}</td>
          <td>${zoomImage.price}</td>
        </tr>
      </tbody>
    </Table>
  );

  return (
    <ImageModal
    imageURL={imageURL}
    showImage={showImage}
    close={close}
    previousImage={previousImage}
    nextImage={nextImage}
  >
      <div key="header">{headerText}</div>
      <div key="footer">{footer}</div>
  </ImageModal>
  )
}

export default FavoritePropsModal;

