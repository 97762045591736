import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import { NewAdminConfig } from '../config';
import modsyLogo from '../static/images/modsy-mini.png';
import { auth } from '../auth';

export default class Header extends Component {
  constructor() {
    super();
    this.state = {
      loggedIn: auth.loggedIn(),
    };
  }

  updateAuth(loggedIn) {
    this.setState({
      loggedIn: !!loggedIn,
    });
  }

  UNSAFE_componentWillMount() {
    auth.onChange = this.updateAuth.bind(this);
    auth.login();
  }

  componentDidMount () {
    const script = document.createElement("script");
    script.src = "https://dac3df4e29b543049fc7cbe991849770.js.ubembed.com";
    script.async = true;
    document.body.appendChild(script);
  }

  handleClick() {
    auth.onChange = this.updateAuth.bind(this);
    auth.logout(() => {
      this.context.router.push('/login');
    });
  }

  render() {
    // these nav elements are only shown to people in the `Payment` group
    const paymentLinks = auth.isInGroup('Payment') ? (
      <div>
        <Nav>
          <LinkContainer to={`/${NewAdminConfig.ORDERPROCESSING}`}>
            <NavItem> Order Processing </NavItem>
          </LinkContainer>
        </Nav>
        <Nav>
          <LinkContainer to={`/${NewAdminConfig.ORDERSREPORT}`}>
            <NavItem> Order Report </NavItem>
          </LinkContainer>
        </Nav>
      </div>
    ) : null;
    return (
      <Navbar>
        <Navbar.Header>
          <Navbar.Brand>
            <img src={modsyLogo} alt={modsyLogo} />
          </Navbar.Brand>
          <Navbar.Brand> Modsy </Navbar.Brand>
        </Navbar.Header>
        {this.state.loggedIn ? (
          <div>
            {/* <Nav>
              <LinkContainer to={`/${NewAdminConfig.ASSETDOWNLOADER}`}>
                <NavItem> Asset Download </NavItem>
              </LinkContainer>
            </Nav> */}
            {/* <Nav>
              <LinkContainer to={`${OldAdminConfig.PRODUCT}`}>
                <NavItem> Product Finder </NavItem>
              </LinkContainer>
            </Nav> */}
            {/* <Nav>
              <LinkContainer to={`/${NewAdminConfig.STYLEBOARD}`}>
                <NavItem> Styleboard </NavItem>
              </LinkContainer>
            </Nav> */}
            <Nav>
              <LinkContainer to={`/${NewAdminConfig.STYLEBOARDCOPIER}`}>
                <NavItem> Styleboard Copier </NavItem>
              </LinkContainer>
            </Nav>
            <Nav>
              <LinkContainer to="/jobs/history">
                <NavItem> Job Render History </NavItem>
              </LinkContainer>
            </Nav>
            <Nav>
              <LinkContainer to="/jobs/dashboard">
                <NavItem> Job Control Panel </NavItem>
              </LinkContainer>
            </Nav>
            <Nav>
              <LinkContainer to={`/${NewAdminConfig.USERS}`}>
                <NavItem> Users </NavItem>
              </LinkContainer>
            </Nav>
            {paymentLinks}
            <Nav>
              <NavItem onClick={this.handleClick.bind(this)}>Logout</NavItem>
            </Nav>
          </div>
        ) : (
          <Nav>
            <LinkContainer to="login">
              <NavItem> Login </NavItem>
            </LinkContainer>
          </Nav>
        )}
      </Navbar>
    );
  }
}

Header.contextTypes = {
  router: PropTypes.object,
};
