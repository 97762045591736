/**
 * Values used in the dropdown listing the shipping methods.
 */
export const PRODUCT_SHIPPING_METHODS = {
  unknown: 'Unknown',
  smallparcel: 'Small Parcel',
  fedexground: 'FedEx Ground',
  upsground: 'UPS Ground',
  standardground: 'Standard Ground',
  linebreak1: '',
  ltl: 'LTL',
  threshold: 'Threshold Delivery',
  whiteglove: 'White Glove',
  inhome: 'In-Home Delivery',
  basicfreight: 'Basic Freight Delivery',
  linebreak2: '',
  other: 'Other',
  instorepickup: 'In-Store Pickup',
  warehousepickup: 'Warehouse Pick Up',

  // Let's keep the old values to avoid losing the data for old/current orders.
  inhomeassembly: 'In-Home + Assembly',
  fedexexpress: 'FedEx Express',
  upsexpress: 'UPS Express',
  usps: 'USPS',
  groundleveldelivery: 'Ground Level Delivery',
  fedexoversize: 'FedEx Oversize',
  frontdoorltl: 'Front Door LTL',
  flatrate: 'Flat Rate Delivery',
};

/**
 * In the dropdown, we want some headers to group the shipping methods.
 */
export const SHIPPING_HEADER_VALUES = ['smallparcel', 'ltl', 'other'];

/**
 * Shipping method values used in old orders.
 * We are not using them anymore, but need to keep track of them.
 */
export const OLD_SHIPPING_METHODS_KEYS = [
  'inhomeassembly',
  'fedexexpress',
  'upsexpress',
  'usps',
  'groundleveldelivery',
  'fedexoversize',
  'frontdoorltl',
  'flatrate',
];

/**
 * Values used in Order Processing page > Order History tab.
 * In each history header to display a description.
 */
export const ORDER_HISTORY_TYPE_NAMES = {
  created: 'Order Placed',
  charge: 'Order Captured',
  refund: 'Refund Issued',
  note: 'Note',
  edit: 'Order Edited',
  view: 'Order Viewed',
  scraping: 'Scraping',
  add_product: 'Add Product',
};

/**
 * Values used in Order Processing page > Order History tab.
 * In each history header to display an emoji.
 */
export const ORDER_HISTORY_MESSAGE_ICONS = {
  created: {
    icon: '📍',
    label: 'created',
  },
  charge: {
    icon: '💰',
    label: 'money',
  },
  refund: {
    icon: '😭',
    label: 'crying',
  },
  note: {
    icon: '✏️',
    label: 'note',
  },
  scraping: {
    icon: '⚙',
    label: 'scraping',
  },
  edit: {
    icon: '📝',
    label: 'edit',
  },
  add_product: {
    icon: '🛋️',
    label: 'add product',
  },
  view: {
    icon: '👀',
    label: 'view',
  },
  default: {
    icon: '👶',
    label: 'baby',
  },
};

export const SHIPMENT_DELIVERY_STATUSES = {
  pending_shipment: 'Pending Shipment',
  ready_to_schedule: 'Ready to Schedule',
  scheduled: 'Scheduled',
  shipped: 'Shipped',
  delivered: 'Delivered',
  returned: 'Returned',
  in_home_delivery: 'In Home Delivery',
};

export const SHIPMENT_DELIVERY_CARRIERS = {
  DELR: 'Deliveright',
  FDEG: 'Fedex',
  unknown: 'Unknown',
  OTCL: 'OnTrac',
  RYDR: 'Ryder',
  UPSN: 'UPS',
  SUND: 'Sun Delivery',
  GLOT: 'GlobalTranz',
};

export const TRACKING_NUMBER_CARRIER_MATCH = [
  {
    pattern: /^(1Z\s?[0-9A-Z]{3}\s?[0-9A-Z]{3}\s?[0-9A-Z]{2}\s?[0-9A-Z]{4}\s?[0-9A-Z]{3}\s?[0-9A-Z]$|[\dT]\d{3}\s?\d{4}s?\d{3})$/i,
    carrier: 'UPSN'
  },
  {
    pattern: /^[kKJj]{1}[0-9]{10}$/,
    carrier: 'UPSN'
  },
  {
    pattern: /[0-9]{12}/,
    carrier: 'UPSN'
  },
  // {
  //   pattern: /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/,
  //   carrier: 'usps'
  // },
  // {
  //   pattern: /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}$|^9\d{15,21}$| ^91[0-9]+$| ^[A-Za-z]{2}[0-9]+US$/i,
  //   carrier: 'usps'
  // },
  // {
  //   pattern: /^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/,
  //   carrier: 'usps'
  // },
  // {
  //   pattern: /^91[0-9]+$/,
  //   carrier: 'usps'
  // },
  // {
  //   pattern: /^[A-Za-z]{2}[0-9]+US$/,
  //   carrier: 'usps'
  // },
  // {
  //   pattern: /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}$|^9\d{15,21}$| ^91[0-9]+$| ^[A-Za-z]{2}[0-9]+US$/i,
  //   carrier: 'usps'
  // },
  // {
  //   pattern: /^[A-Za-z]{2}[0-9]+US$/,
  //   carrier: 'usps'
  // },
  {
    pattern: /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/,
    carrier: 'FDEG'
  },
  {
    pattern: /^[0-9]{4} [0-9]{4} [0-9]{4}$/,
    carrier: 'FDEG'
  },
  {
    pattern: /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/,
    carrier: 'FDEG'
  },
  {
    pattern: /^[0-9]{15}$/,
    carrier: 'FDEG'
  },
  {
    pattern: /^6129999[0-9]{13}$/,
    carrier: 'FDEG'
  },
  {
    pattern: /^9261299[0-9]{15}$/,
    carrier: 'FDEG'
  },
  {
    pattern: /^C[0-9]{14}$/,
    carrier: 'OTCL'
  },
];

export const SCRAPED_RETAILERS = ["Pottery Barn"];  //Adding more eventually