import React from 'react';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';

import AmountControls from './AmountControls';
import axios from 'axios';

import { Alert, Button } from 'react-bootstrap';
import { ApiConfig } from '../config';

import { isNullOrUndefined } from 'util';

import { WLH_SOURCE_MAP, WLH_DESCRIPTOR_MAP } from '../constants';

export default class WhoLivesHere extends React.Component {
  state = {
    adults: {
      value: null,
      selected: false,
    },
    teens: {
      value: null,
      selected: false,
    },
    big_kids: {
      value: null,
      selected: false,
    },
    little_kids: {
      value: null,
      selected: false,
    },
    babies: {
      value: null,
      selected: false,
    },
    cats: {
      value: null,
      selected: false,
    },
    dogs: {
      value: null,
      selected: false,
    },
    other_pets: {
      value: null,
      selected: false,
    },
    isAlertVisible: false,
  };

  handleSaveResults = () => {
    const { isTextEnabled, phoneNumber, userId } = this.props;

    const {
      adults,
      teens,
      big_kids,
      little_kids,
      babies,
      cats,
      dogs,
      other_pets,
    } = this.state;

    if (
      isNullOrUndefined(this.props.adults) &&
      isNullOrUndefined(this.props.teens) &&
      isNullOrUndefined(this.props.big_kids) &&
      isNullOrUndefined(this.props.little_kids) &&
      isNullOrUndefined(this.props.babies) &&
      isNullOrUndefined(this.props.cats) &&
      isNullOrUndefined(this.props.dogs) &&
      isNullOrUndefined(this.props.other_pets)
    ) {
      // no info yet, POST instead of PATCH
      axios.post(`${ApiConfig.ABOUTYOU}?heidi=true`, {
        user: userId,
        in_home_adults: adults.value,
        in_home_teens: teens.value,
        in_home_big_kids: big_kids.value,
        in_home_little_kids: little_kids.value,
        in_home_babies: babies.value,
        in_home_cats: cats.value,
        in_home_dogs: dogs.value,
        in_home_other_pets: other_pets.value,
        phone: phoneNumber,
        texting_enabled: isTextEnabled,
      });
    } else {
      axios.patch(`${ApiConfig.ABOUTYOU}/${userId}?heidi=true`, {
        in_home_adults: adults.value,
        in_home_teens: teens.value,
        in_home_big_kids: big_kids.value,
        in_home_little_kids: little_kids.value,
        in_home_babies: babies.value,
        in_home_cats: cats.value,
        in_home_dogs: dogs.value,
        in_home_other_pets: other_pets.value,
        phone: phoneNumber,
        texting_enabled: isTextEnabled,
      });
    }

    this.setState({ isAlertVisible: false });

    const keyArray = Object.keys(this.state);

    // deselect each element
    keyArray.forEach((key) => {
      if (key !== 'isAlertVisible') {
        this.setState({
          [key]: {
            value: this.state[key].value,
            selected: false,
          },
        });
      }
    });
  };

  handleDismissAlert = () => {
    const keyArray = Object.keys(this.state);

    // reset the values and selected
    keyArray.forEach((key) => {
      if (key !== 'isAlertVisible')
        this.setState({
          [key]: {
            value: this.props[key],
            selected: false,
          },
        });
    });

    this.setState({ isAlertVisible: false });
  };

  componentDidUpdate() {
    const keyArray = Object.keys(this.state);

    keyArray.forEach((key) => {
      if (
        !isNullOrUndefined(this.props[key]) &&
        this.state[key].value === null
      ) {
        this.setState({
          [key]: {
            value: this.props[key],
            selected: false,
          },
        });
      }
    });
  }

  render() {
    const { isAlertVisible } = this.state;
    return (
      <Container>
        {isAlertVisible && (
          <Alert bsStyle="warning" onDismiss={this.handleDismissAlert}>
            <h4>Save Who Lives Here Info</h4>
            <p>
              Save Who Lives Here Info - if you don’t save or refresh changes
              will be lost!
            </p>
            <p>
              <Button onClick={this.handleDismissAlert}>Cancel</Button>
              <span> or </span>
              <Button onClick={this.handleSaveResults}>Save</Button>
            </p>
          </Alert>
        )}
        <Title>Who lives in the home</Title>
        <ImageRow>
          {Object.keys(this.state).map((key) => {
            const keyHasNoValue = isNaN(this.state[key].value);
            const isSelected = this.state[key].selected;

            if (key !== 'isAlertVisible')
              return (
                <Amount
                  key={key}
                  src={WLH_SOURCE_MAP[key]}
                  value={
                    keyHasNoValue || !this.state[key].value
                      ? 0
                      : this.state[key].value
                  }
                  descriptor={WLH_DESCRIPTOR_MAP[key]}
                  onChange={(value) => {
                    this.setState({
                      [key]: {
                        value,
                        selected: isSelected,
                      },
                      isAlertVisible: true,
                    });
                  }}
                  selected={isSelected}
                  onSelected={(value) => {
                    this.setState(
                      {
                        [key]: {
                          value: this.state[key].value,
                          selected: value,
                        },
                      },
                      () => {
                        this.setState({
                          isAlertVisible: Object.values(this.state).some(
                            (element) => element.selected
                          ),
                        });
                      }
                    );
                  }}
                />
              );

            return null;
          })}
        </ImageRow>
      </Container>
    );
  }
}

const Amount = ({
  src = '',
  value = 0,
  descriptor = '',
  onChange = () => {},
  selected = false,
  onSelected = () => {},
} = {}) => {
  if (isNaN(value)) return null;

  return selected ? (
    <Column>
      <Logo src={src} onClick={() => onSelected(!selected)} />
      <AmountControls
        value={value}
        onMinus={() => {
          onChange(Math.max(value - 1, 0));
        }}
        onPlus={() => {
          onChange(Math.min(value + 1, 99));
        }}
      />
    </Column>
  ) : (
    <Column onClick={() => onSelected(!selected)}>
      <Logo src={src} />
      <AmountText>{`${value} ${descriptor}`} </AmountText>
    </Column>
  );
};

const Container = styled.div`
  margin: 24px 0;
`;

const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 18px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 8px;

  width: 80px;

  cursor: pointer;
`;

const Logo = styled(Image)`
  position: relative;
  width: 40px;
  height: 40px;
  margin-bottom: 11px;
  left: 50%;
  transform: translateX(-50%);
`;

const AmountText = styled.h5`
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  color: #6d93c9;

  margin: 0;

  height: 30px;

  @media (hover: hover) {
    :hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 500;
`;
