/**
 * Common button styles. Generally
 * used for primary and secondary CTAs.
 */
import React from 'react';
import styled from 'styled-components';
import {
  white,
  eastBayBlue,
  sanMarinoBlue,
  catskillWhite,
  blue,
  altoGrey,
  gray
} from './colors';

/**
 * Primary CTA. This has a blue background
 * and white text.
 */
export const PrimaryCta = (props) => (
  <ButtonInternal priority='primary' {...props} />
);
PrimaryCta.displayName = 'PrimaryCta';

/**
 * Secondary CTA. This has a white background
 * and blue text.
 */
export const SecondaryCta = (props) => (
  <ButtonInternal priority='secondary' {...props} />
);
SecondaryCta.displayName = 'SecondaryCta';

/**
 * TertiaryCta CTA. This has a transparent background
 * and blue text.
 */
export const TertiaryCta = (props) => (
  <ButtonInternal priority='tertiary' {...props} />
);
TertiaryCta.displayName = 'TertiaryCta';

/**
 * Internal button structure for primary and secondary CTAs.
 */
const ButtonInternal = ({ children, ...other }) => {
  return (
    <BaseButton {...other} disabledStyle={other.disabled} >
      <span>
        <div className='text'>{children}</div>
      </span>
    </BaseButton>
  );
};

/**
 * Styles.
 */
const BaseButton = styled.button`
  position: relative;
  text-align: center;
  color: ${props => (props.priority === 'primary'
    ? white
    : (props.priority === 'secondary'
      ? eastBayBlue
      : blue
    )
  )};
  line-height: ${ p => p.buttonHeight || '32px'};
  width: ${ p => p.width || '100%'};
  box-sizing: border-box;
  vertical-align: middle;
  appearance: none;
  ${props => props.priority !== 'tertiary' && `
    text-decoration: none;
  `}
  
  max-width: 709px;
  cursor: pointer;
  border-radius: 0;
  ${props => props.priority === 'tertiary'
    ? `
    border: none;
  `
    : `
    border: 0.6px ${eastBayBlue} solid; 
  `}
  margin-right: ${p => p.mr || '0'};
  margin-left: ${p => p.ml || '0'};
  margin-top: ${p => p.mt || '0'};
  margin-bottom: ${p => p.mb || '0'};
  background-color: ${props => (props.priority === 'primary'
    ? eastBayBlue
    : (props.priority === 'secondary'
      ? white
      : 'transparent'
    )
  )};
  transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1),
    background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
  display: inline-block;
  outline: none;
  grid-column: ${ p => p.gc };
  grid-row: ${ p => p.gr };

  &:active,
  &:focus {
    outline: 0;
  }

  & .text {
    height: ${ p => p.buttonHeight || '32px'};
    ${props => props.priority === 'tertiary' && `
      text-decoration-line: underline;
    `}
  }
  
  ${props => props.disabledStyle && props.priority === 'primary' && `
    background-color: ${altoGrey};
    border-color: ${altoGrey};
  `}

  ${props => props.disabledStyle && props.priority === 'secondary' &&
  `
    border-color: ${altoGrey};
    color: ${gray};
  `}

  ${props => props.disabledStyle && props.priority === 'tertiary' &&
  `
    color: ${gray};
  `}

  @media (hover: hover) {
    &:hover:not(:disabled) {
      ${props => props.priority === 'primary'
    ? `
            background-color: ${sanMarinoBlue};
            border-color: ${sanMarinoBlue};
          `
    : (props.priority === 'secondary'
      ? `
            background-color: ${catskillWhite};
            border-color: ${blue};
          `
      : '')
}
    }
  }

  & {
    position: relative;
    transition: background-color 300ms ease-out;
  }

  & span {
    display: inline-block;
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
    vertical-align: middle;
    height: ${ p => p.buttonHeight || '33px'};
    transform: none;
  }

  & svg {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
    strokewidth: 5;
    stroke-color: transparent;
  }
`;
BaseButton.displayName = 'BaseButton';
