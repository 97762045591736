import React from 'react';
import styled from 'styled-components';

import { ImageIcon } from './styles';

const HOTSPOT_SIZE = 24;

export default function ProductLikeHotspots({
  hotspots = [],
  likesFiltering = '',
}) {
  const likedHotspots = hotspots.filter((hotspot) => hotspot.like_value > 0);
  return likedHotspots.map((hotspot) => (
    <Hotspot
      key={hotspot.instance_id}
      id={hotspot.instance_id}
      x={hotspot.x * 100}
      y={hotspot.y * 100}
      hotspotSize={HOTSPOT_SIZE}
    >
      <CenteredImageIcon
        alt="hotspot liked icon"
        className={'style-match-likes-icon'}
        padding={'0'}
        src={likesFiltering}
      />
    </Hotspot>
  ));
}

const Hotspot = styled.div`
  position: absolute;
  ${(props) => {
    const transformSize = props.hotspotSize / 2;

    return `
    left: ${props.x}%;
    top: ${props.y}%;
    transform: translate(-${transformSize}px, -${transformSize}px);
    width: ${props.hotspotSize}px;
    height: ${props.hotspotSize}px;
    `;
  }}

  background: white;
  box-sizing: border-box;
  border-radius: 50%;
`;

const CenteredImageIcon = styled(ImageIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
