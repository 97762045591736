import React from 'react';
import {
  Col,
  ControlLabel,
  FormGroup,
  Row,
  Radio,
} from 'react-bootstrap';
import get from 'lodash/get';

import {
  ADHERENCE_MESSAGES,
  ADHERENCE_DEFAULT,
  ADHERENCE_DO_NOT_EXCEED,
  FURNITURE_PURCHASE_URGENCY_CHOICES,
  MAX_TEXT_AREA_COUNT,
  pricePointsServerDataConverter,
} from '../../../../constants';

import OldBudget from './OldBudget';
import NewBudget from './NewBudget';
import IntendedPieces from './IntendedPieces';
import {
  hasOldBudgetFormat,
  setSpaceInfoBudget,
  getBudgetAdherenceCopy
} from './BudgetPanelReducer';
import TextareaAutosizeExtended from '../../../../sharedComponents/TextareaAutosizeExtended';
import { debounceMaxTextCharactersAlert } from '../../../../utils';
import PanelLink from '../../../../sharedComponents/PanelLink/PanelLink';
import { handleFormattingLinks } from '../../Utils';

export default function BudgetPanel(props) {
  const {
    contentDisplay,
    furniture_purchase_urgency,
    handleChange,
    intended_pieces_other,
    productCategories,
    setContentDisplayOption,
    spaceInfoV1,
    spaceListIsFetching,
    styleboardInfoId,
  } = props;

  const {
    created_at: createdAt,
    budget_min: budgetMin,
    budget_max: budgetMax,
    stick_to_budget: stickToBudget,
    do_not_exceed: doNotExceed,
    price_point: pricePoint,
  } = spaceInfoV1 || {};

  // Styleboards after certain date used new budget ranges vs. old single value; check to see if
  // old Budget value should be displayed
  const showOldBudget = hasOldBudgetFormat({ createdAt });

  // Check that space has an adherence limit and stickToBudget is ADHERENCE_DO_NOT_EXCEED
  const showDoNotExceed = stickToBudget && doNotExceed && stickToBudget === ADHERENCE_DO_NOT_EXCEED;

  // Fetch ADHERENCE_MESSAGES copy or default
  const stickToBudgetMessage = get(ADHERENCE_MESSAGES, stickToBudget, ADHERENCE_MESSAGES[ADHERENCE_DEFAULT]);

  const adherenceCopy = getBudgetAdherenceCopy({
    stickToBudgetMessage,
    showDoNotExceed,
    doNotExceed
  });

  return (
    <Row>
      <Col xs={12}>
        <FormGroup controlId="formControlsUrgency">
          <ControlLabel>
            When are you planning to shop for furniture?
          </ControlLabel>
          <Radio
            value={FURNITURE_PURCHASE_URGENCY_CHOICES.NO_DEADLINE}
            checked={
              FURNITURE_PURCHASE_URGENCY_CHOICES.NO_DEADLINE ===
              furniture_purchase_urgency
            }
            name="furniturePurchaseUrgency"
            onChange={e =>
              handleChange(parseInt(e.target.value, 10), 'furniture_purchase_urgency')}
            inline
          >
            No deadline
          </Radio>
          <Radio
            value={FURNITURE_PURCHASE_URGENCY_CHOICES.NEAR_FUTURE}
            checked={
              FURNITURE_PURCHASE_URGENCY_CHOICES.NEAR_FUTURE === furniture_purchase_urgency
            }
            name="furniturePurchaseUrgency"
            onChange={e =>
              handleChange(parseInt(e.target.value, 10), 'furniture_purchase_urgency')}
            inline
          >
            In the near future
          </Radio>
          <Radio
            value={FURNITURE_PURCHASE_URGENCY_CHOICES.ASAP}
            checked={
              FURNITURE_PURCHASE_URGENCY_CHOICES.ASAP === furniture_purchase_urgency
            }
            name="furniturePurchaseUrgency"
            onChange={e =>
              handleChange(parseInt(e.target.value, 10), 'furniture_purchase_urgency')}
            inline
          >
            ASAP
          </Radio>
        </FormGroup>
      </Col>
      {!spaceListIsFetching && productCategories && (
        <IntendedPieces {...props} />
      )}
      <Col xs={12} mt={40}>
        <FormGroup>
          <PanelLink
            header='Optional - Anything else we need to know about key pieces you want in this room?'
            link={handleFormattingLinks('', intended_pieces_other)}
            displayContent={contentDisplay && contentDisplay.isIntendedPiecesOther}
            onClick={() => setContentDisplayOption('isIntendedPiecesOther')}
          >
            <TextareaAutosizeExtended
              maxLength={MAX_TEXT_AREA_COUNT}
              id='intended_pieces_other'
              value={intended_pieces_other}
              onKeyUp={debounceMaxTextCharactersAlert}
              onChange={e => handleChange(e.target.value, 'intended_pieces_other')}
            />
          </PanelLink>
        </FormGroup>
      </Col>
      <Col xs={12}>
        {
          showOldBudget ?
            <OldBudget
              setOldBudget={setSpaceInfoBudget({
                styleboardInfoId
              })}
              budgetMax={budgetMax}
              budgetMin={budgetMin}
            /> :
            <NewBudget
              budgetMax={budgetMax}
              budgetMin={budgetMin}
            />
        }
      </Col>
      <Col xs={12}>
        <FormGroup controlId="formControlsHowToUseSpace" style={{marginBottom:'0px'}}>
          <ControlLabel>How closely do you want to stick to your budget?</ControlLabel>
          { adherenceCopy }
        </FormGroup>
      </Col>
      <Col xs = {12}>
        <FormGroup controlId="formControlAveragePricePoint" >
          <ControlLabel style={{ paddingRight: '10px' }}>Customer's Average Price Point: </ControlLabel>
          { pricePoint ? pricePointsServerDataConverter(pricePoint, true) : 'No Average Price Point' }
        </FormGroup>
      </Col>
    </Row>
  );
}
