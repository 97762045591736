import { keyframes } from 'styled-components';

const slideDownOne = keyframes`
  0% {
    transform: translateY(-32px);
    opacity: 1;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  20% {
    transform: translateY(0);
    opacity: 0;
  }

  30% {
    transform: translateY(0);
    opacity: 0;
  }

  40% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 0;
  }

  60% {
    transform: translateY(0);
    opacity: 0;
  }

  70% {
    transform: translateY(0);
    opacity: 0;
  }

  opacity: 0;
`;

const slideDownTwo = keyframes`
  0% {
    transform: translateY(-32px);
    opacity: 1;
  }

  10% {
    transform: translateY(-32px);
    opacity: 1;
  }

  20% {
    transform: translateY(0);
    opacity: 1;
  }

  30% {
    transform: translateY(0);
    opacity: 0;
  }

  40% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 0;
  }

  60% {
    transform: translateY(0);
    opacity: 0;
  }

  70% {
    transform: translateY(0);
    opacity: 0;
  }

  opacity: 0;
`;

const slideDownThree = keyframes`
  0% {
    transform: translateY(-32px);
    opacity: 1;
  }

  10% {
    transform: translateY(-32px);
    opacity: 1;
  }

  20% {
    transform: translateY(-32px);
    opacity: 1;
  }

  30% {
    transform: translateY(0);
    opacity: 1;
  }

  40% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 0;
  }

  60% {
    transform: translateY(0);
    opacity: 0;
  }

  70% {
    transform: translateY(0);
    opacity: 0;
  }

  opacity: 0;
`;

const slideDownFour = keyframes`
  0% {
    transform: translateY(-32px);
    opacity: 1;
  }

  10% {
    transform: translateY(-32px);
    opacity: 1;
  }

  20% {
    transform: translateY(-32px);
    opacity: 1;
  }

  30% {
    transform: translateY(-32px);
    opacity: 1;
  }

  40% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(0);
    opacity: 0;
  }

  60% {
    transform: translateY(0);
    opacity: 0;
  }
  70% {
    transform: translateY(0);
    opacity: 0;
  }

  opacity: 0;
`;

const slideDownFive = keyframes`
  0% {
    transform: translateY(-32px);
    opacity: 1;
  }

  10% {
    transform: translateY(-32px);
    opacity: 1;
  }

  20% {
    transform: translateY(-32px);
    opacity: 1;
  }

  30% {
    transform: translateY(-32px);
    opacity: 1;
  }

  40% {
    transform: translateY(-32px);
    opacity: 1;
  }

  50% {
    transform: translateY(0);
    opacity: 1;
  }

  60% {
    transform: translateY(0);
    opacity: 0;
  }

  70% {
    transform: translateY(0);
    opacity: 0;
  }

  opacity: 0;
`;

const slideDownSix = keyframes`
  0% {
    transform: translateY(-32px);
    opacity: 1;
  }

  10% {
    transform: translateY(-32px);
    opacity: 1;
  }

  20% {
    transform: translateY(-32px);
    opacity: 1;
  }

  30% {
    transform: translateY(-32px);
    opacity: 1;
  }

  40% {
    transform: translateY(-32px);
    opacity: 1;
  }

  50% {
    transform: translateY(-32px);
    opacity: 1;
  }

  60% {
    transform: translateY(0);
    opacity: 0;
  }

  70% {
    transform: translateY(0);
    opacity: 0;
  }

  opacity: 0;
`;

const slideUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  70% {
    transform: translateY(-32px);
    opacity: 1;
  }

  100% {
    transform: translateY(-32px);
    opacity: 1;
  }
`;

export {
  slideDownOne,
  slideDownTwo,
  slideDownThree,
  slideDownFour,
  slideDownFive,
  slideDownSix,
  slideUp
};
