import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import DesignsV2 from '../components/DesignComponents/DesignsV2';
import LoadingIcon from '../components/LoadingIcon';
import { ApiConfig, WAREHOUSE_CLIENT_URL } from '../config';
import setActiveDesign from '../actions/active-design';
import { DESIGNS_FILTER, DESIGN_SOURCE, SOURCE_TYPE } from '../constants';
import { bugsnagError } from '../services/bugsnag';
import Utils from '../utils';
import * as request from '../global/request';
import { WAREHOUSE_API_URL } from '../config';

class DesignWrapperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areAllDesignsExpanded: false,
      filter: DESIGNS_FILTER.PRODUCTS,
      loading: false,
      designsData: [],
    };
    this.page = 0;
  }

  componentDidMount() {
    const { dispatch, activeSpaceID } = this.props;
    const { designsData } = this.state;

    if (designsData && !isEmpty(designsData)) {
      if (designsData[0] && !isUndefined(designsData[0].design_id)) {
        const designProductsCache = [
          'space_data',
          activeSpaceID,
          'designs_data',
          designsData[0].design_id,
          'design_products',
        ];
        dispatch(setActiveDesign(designsData[0].design_id));
        dispatch(
          fetchDataIfNeeded(
            `${ApiConfig.DESIGNSV2}/${activeSpaceID}/designs/${designsData[0].design_id}`,
            'design_products',
            {
              keys: { space: activeSpaceID, design: designsData[0].design_id },
              cache: designProductsCache,
            }
          )
        ).catch((error) => bugsnagError(error));
      }
    }

    this.handleLoadNewPage();
  }

  componentDidUpdate(prevProps) {
    const { activeSpaceID, assistanceCount, dispatch } = this.props;
    if (prevProps.isFetchingDesigns !== this.props.isFetchingDesigns) {
      this.transformDesigns();
    }

    if (assistanceCount !== prevProps.assistanceCount) {
      const spaceAssistanceCache = [
        'space_data',
        activeSpaceID,
        'space_assistance',
      ];

      dispatch(
        fetchDataIfNeeded(
          // loads send assistance information
          `${ApiConfig.SPACE_ASSISTANCE}?spaceId=${activeSpaceID}&page_size=1000&format=json`,
          'space_assistance',
          { keys: { space: activeSpaceID, cache: spaceAssistanceCache } }
        )
      ).then((response) => {
        this.transformDesigns();
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, activeDesign, activeSpaceID } = this.props;
    if (nextProps.activeDesign !== activeDesign) {
      const designProductsCache = [
        'space_data',
        activeSpaceID,
        'designs_data',
        nextProps.activeDesign,
        'design_products',
      ];
      dispatch(
        fetchDataIfNeeded(
          `${ApiConfig.DESIGNSV2}/${activeSpaceID}/designs/${nextProps.activeDesign}`,
          'design_products',
          {
            keys: { space: activeSpaceID, design: nextProps.activeDesign },
            cache: designProductsCache,
          }
        )
      ).catch((error) => bugsnagError(error));
    }
  }

  // changes whether products or renders are displayed
  handleFilterChange = (filter) => {
    this.setState({ filter });
  };

  handleElsieClick = (id) => {
    window.open(
      `${WAREHOUSE_CLIENT_URL}/elsie?space=${this.props.activeSpaceID}&design=${id}`,
      '_blank'
    );
  };

  // allows user to expand or close all designs
  handleAreAllDesignsExpanded = () => {
    const { areAllDesignsExpanded, designsData } = this.state;

    this.setState({ areAllDesignsExpanded: !areAllDesignsExpanded });
    let clonedDesignsData = cloneDeep(designsData);

    for (let key in clonedDesignsData) {
      let design = clonedDesignsData[key];
      design.expanded = areAllDesignsExpanded ? false : true;
    }
    this.setState({ designsData: clonedDesignsData });
  };

  // expand/close individual containers
  toggleExpand = (key) => {
    const { designsData } = this.state;
    let clonedDesignsData = cloneDeep(designsData);
    clonedDesignsData[key].expanded = !clonedDesignsData[key].expanded;
    this.setState({ designsData: clonedDesignsData });
  };

  getWhoCreatedDesign = (createdBy, authorPosition, source) => {
    const isFromLiveSwap = DESIGN_SOURCE['Live Swap'] === source;
    const isFromBessieRedesign = DESIGN_SOURCE['Bessie Revision'] === source;

    if (createdBy === '') {
      switch (true) {
        case isFromLiveSwap:
          return 'From Live Swap';
        case isFromBessieRedesign:
          return 'From Bessie Revision';
        default:
          return 'Unknown';
      }
    } else if (authorPosition !== '') {
      return `${createdBy} (${authorPosition})`;
    }
    return createdBy;
  };

  handleLoadNewPage = () => {
    const { activeSpaceID, designsCount } = this.props;
    const { designsData } = this.state;

    if (designsCount === designsData.length) {
      return;
    }

    this.setState({ loading: true });
    this.page++;

    const url = `${WAREHOUSE_API_URL}/warehouse/api/v2/designs?spaceId=${activeSpaceID}&filter=heidi&sort=desc&page=${this.page}&format=json`;

    return request.get(url).then((response) => {
      const designResults = response.data.results;
      const transformedDesignsResults = this.transformDesigns(designResults);
      const designsForRender = [...designsData, ...transformedDesignsResults];
      this.setState({
        loading: false,
        designsData: designsForRender,
      });
    });
  };

  handleUpdateLayoutInfo = (updatedLayoutInfo) => {
    // Callback when a layout is selected
    // Updates the existing design information with
    // the new layout info
    const { designsData } = this.state;
    const updatedData = designsData.map((design) => {
      const updatedInfo = updatedLayoutInfo.find(
        (info) => info.id === design.id
      );
      if (updatedInfo) {
        design.layoutInfo = updatedInfo.layoutInfo;
      }
      return design;
    });
    this.setState({ designsData: updatedData });
  };

  getRedesignRequestData = (designID) => {
    const { spaceAssistanceData } = this.props;
    if (!spaceAssistanceData) {
      return;
    }

    let redesignReasons = [];
    spaceAssistanceData.forEach((value) => {
      if (parseInt(value.design_id, 10) === designID) {
        redesignReasons.push(value);
      }
    });
    //ensure the request that has not been addressed is the zero index
    return redesignReasons.sort((a, b) =>
      a.addressed > b.addressed ? 1 : b.addressed > a.addressed ? -1 : 0
    );
  };

  handleFormattingRenderData = (renders) => {
    Object.keys(renders).forEach((key) => {
      renders[key].render_time = renders[key].render_time
        ? `${renders[key].render_time.toString()}s`
        : 'N/A';
      renders[key].empty_room = renders[key].empty_room === true ? 'Yes' : 'No';
      renders[key].view_360 = renders[key].view_360 === true ? 'Yes' : 'No';
    });
    return renders;
  };

  formattedDesignsSnapshot = (designSnapshots) => {
    if (designSnapshots === null) {
      return;
    }

    let rendersArray = [];

    Object.keys(designSnapshots.renderings).forEach((key) => {
      Object.keys(SOURCE_TYPE).forEach((value) => {
        if (designSnapshots.source === SOURCE_TYPE[value].label) {
          let source = SOURCE_TYPE[value].type;
          designSnapshots.renderings[key][
            source
          ].created_at = Utils.getFormattedDateTime(
            designSnapshots.renderings[key][source].updated_at
          );
          rendersArray.push(designSnapshots.renderings[key][source]);
        }
      });
    });
    return rendersArray.filter(
      (value) => value.status === 'finished' || value.rendering_time !== -1
    );
  };

  /** Handles getting the date of a design/layout:
   * If it is a normal design, retrieves the latest "updated" date.
   * If it is a layout, retrieves the date when the layout is
   * sent to the user.  Otherwise defaults to Not Available
   */
  getSentOrUpdatedDesignDate = (layout_info, updated_at) => {
    const hasLayoutSentDate = layout_info && !!layout_info.sent_at;
    const hasDateDesignUpdated = !!updated_at;
    const layoutSentDate =
      hasLayoutSentDate &&
      Utils.getFormattedDateTime(layout_info.sent_at, 'long');
    const dateDesignUpdated =
      hasDateDesignUpdated && Utils.getFormattedDateTime(updated_at, 'long');
    let sentOrUpdatedDate = 'Not Available';

    switch (true) {
      case hasLayoutSentDate:
        sentOrUpdatedDate = layoutSentDate;
        break;
      case hasDateDesignUpdated:
        sentOrUpdatedDate = dateDesignUpdated;
        break;
      default:
        break;
    }
    return sentOrUpdatedDate;
  };

  transformDesigns = (designs) => {
    const { isFetchingDesigns } = this.props;
    const { areAllDesignsExpanded } = this.state;

    let transformedDesignsData = [];

    if (designs && !isFetchingDesigns) {
      transformedDesignsData = designs.map((design, index) => {
        const {
          author_position = '',
          comment = '',
          created_at = '',
          created_by = '',
          id = '', // Used for layout_info
          design_id = '',
          design_feedback = '',
          designsnapshot,
          layout_info = '',
          renders = [],
          style_note = '',
          source = '',
          status = '',
          updated_at = '',
        } = design;

        const isCustomerDesign = [
          DESIGN_SOURCE['Live Swap'],
          DESIGN_SOURCE['Bessie Revision'],
        ].includes(source);
        const createdBy = this.getWhoCreatedDesign(
          created_by,
          author_position,
          source
        );
        const sentOrUpdatedDate = this.getSentOrUpdatedDesignDate(
          layout_info,
          updated_at
        );
        const elsieURL = () => this.handleElsieClick(design_id);

        let designName = '';
        switch (true) {
          case design_id >= 90000:
            designName = 'Quality Control';
            break;
          case !!layout_info:
            designName = design.name;
            break;
          default:
            designName = `Design ${design_id}`;
            break;
        }
        const redesignRequest = this.getRedesignRequestData(design_id);
        const designSnapshot =
          designsnapshot &&
          designsnapshot !== null &&
          Object.keys(designsnapshot.renderings).length;
        const designRenders = designSnapshot
          ? this.formattedDesignsSnapshot(designsnapshot)
          : this.handleFormattingRenderData(renders);
        const isExpanded = index === 0 || areAllDesignsExpanded ? true : false;

        const designId = id || design_id;

        return {
          createdBy,
          createdWhere: comment,
          designFeedback: design_feedback,
          elsieURL,
          expanded: isExpanded,
          isCustomerDesign,
          id: designId,
          designIndex: design_id,
          layoutInfo: layout_info,
          designName,
          redesignRequest,
          renders: designRenders,
          sentOrUpdatedDate,
          styleNote: {
            note: style_note.trim(),
            dateCreated: Utils.getFormattedDate(created_at),
          },
          source: source,
          status: status,
        };
      });
    }

    return transformedDesignsData;
  };

  render() {
    const { areAllDesignsExpanded, designsData, loading } = this.state;
    const {
      activeSpaceID,
      data,
      dispatch,
      designsCount,
      isFetchingDesigns,
    } = this.props;

    // handle loading and errors
    if (this.props.error) {
      return (
        <Alert bsStyle="danger">
          <strong>Oops!</strong> Something went wrong fetching the design
          products. &nbsp;Please try reloading the page, or check the admin
          panel.
        </Alert>
      );
    } else if (this.props.isFetching || isFetchingDesigns) {
      return <LoadingIcon />;
    }

    return (
      <DesignsV2
        activeSpaceID={activeSpaceID}
        areAllDesignsExpanded={areAllDesignsExpanded}
        designsCount={designsCount}
        designsData={designsData}
        dispatch={dispatch}
        handleAreAllDesignsExpanded={this.handleAreAllDesignsExpanded}
        handleLoadNewPage={this.handleLoadNewPage}
        handleUpdateDesigns={this.handleUpdateDesigns}
        handleUpdateLayoutInfo={this.handleUpdateLayoutInfo}
        loading={loading}
        productData={data}
        toggleExpand={this.toggleExpand}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const assistanceCount = state.redesign_request_modal.assistanceCount;
  const activeSpaceID = state.space_data.activeID;
  const activeDesign = state.active_design;
  const bShowRedesignRequestModal =
    state.redesign_request_modal.bShowRedesignRequestModal;

  // empty object to trigger defaults if no space exists yet
  return {
    spaceAssistanceData: get(
      state,
      `space_data[${activeSpaceID}].space_assistance.data.results`
    ),
    isFetchingDesigns: get(
      state,
      `space_data[${activeSpaceID}].designsV2.isFetching`
    ),
    isFetching: get(
      state,
      `space_data[${activeSpaceID}].designs_data[${activeDesign}].design_products.isFetching`
    ),
    lastUpdated: get(
      state,
      `space_data[${activeSpaceID}].designs_data[${activeDesign}].design_products.lastUpdated`
    ),
    data: get(
      state,
      `space_data[${activeSpaceID}].designs_data[${activeDesign}].design_products.data`
    ),
    error: get(
      state,
      `space_data[${activeSpaceID}].designs_data[${activeDesign}].design_products.error`
    ),
    renderData: get(state, `space_data[${activeSpaceID}].renders.data`),
    designsCount: get(
      state,
      `space_data[${activeSpaceID}].designsV2.data.count`
    ),
    designsV2: get(
      state,
      `space_data[${activeSpaceID}].designsV2.data.results`
    ),
    activeDesign,
    assistanceCount,
    activeSpaceID,
    bShowRedesignRequestModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDesignChange: (design) => {
      dispatch(setActiveDesign(design));
    },
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignWrapperContainer);
