import React from 'react';
import styled from 'styled-components';

import {
  GridItem,
  GridImage,
  GridButton,
  GridContent,
  ImageIcon,
} from './styles';
import NewLinkIcon from '../../../../images/newLinkIcon.svg';
import ProductLikeHotspots from './ProductLikeHotspots';

export default function StyleMatch({ styleMatchData = {} }) {
  const image = styleMatchData.image;
  const roomLikeReasons = styleMatchData.reasons;
  const genomeType = styleMatchData.genomeType;
  const roomType = styleMatchData.roomType;
  const roomUrl = styleMatchData.roomUrl;
  const styleMatchHeader = styleMatchData.header;
  const likesFiltering = styleMatchData.likesFiltering;
  const index = styleMatchData.index;
  return (
    <GridContent
      border={'1px solid #F0F0F0'}
      borderRadius={'5px'}
      className="style-match-content-container"
      gridTemplateRows={'auto auto'}
      key={`style-match-content-${roomType}-${index}`}
      height="auto"
    >
      <PositionedContainer>
        <GridImage
          alt={'style match image'}
          className={'style-match-image'}
          src={image.url}
        />
        <ProductLikeHotspots
          hotspots={image.hotspots}
          likesFiltering={likesFiltering}
        />
      </PositionedContainer>
      <Footer height={'64px'} gridTemplateRows={'1fr'}>
        <GridItem gridTemplateColumns={'repeat(3, auto)'}>
          {roomLikeReasons && (
            <LikeReason className={'style-match-likes-item'}>
              {roomLikeReasons}
            </LikeReason>
          )}
          <GridButton
            className={'style-match-url-button'}
            data-test="grid-button-room-url"
            gridTemplateColumns={'repeat(2, max-content)'}
            onClick={roomUrl}
            margin={'0 8px 0 16px'}
            key={`grid-button-${roomType}-${index}`}
          >
            {styleMatchHeader}
            <ImageIcon
              alt={'link icon'}
              className={'style-match-link-icon'}
              padding={'0 0 0 8px'}
              src={NewLinkIcon}
            />
          </GridButton>
          <GridItem
            color={'#8B8B8B'}
            padding={'0 0 0 30px'}
            gridTemplateColumns={'1fr'}
          >
            {genomeType}
          </GridItem>
        </GridItem>
      </Footer>
    </GridContent>
  );
}

const PositionedContainer = styled.div`
  display: flex;
  position: relative;
  height: auto;
  margin-bottom: 64px;
`;

const Footer = styled(GridContent)`
  position: absolute;
  bottom: 0;
`;

const LikeReason = styled(GridItem)`
  margin-left: 16px;
`;
