import React from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import upperFirst from 'lodash/upperFirst';
import { auth } from '../auth';

import $ from 'jquery';
import cookie from 'js-cookie';

import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { fetchDataIfNeeded } from '../actions/api-data-request-generator';

import OrdersReportSearch from '../components/OrdersReportSearch';
import OrdersReportList from '../components/OrdersReportList';

import Utils from '../utils';
import { ApiConfig } from '../config';
import { DAY_OF_WEEK } from '../constants';

import * as request from '../global/request';
import { WAREHOUSE_API_URL } from '../config';

import { bugsnagError } from '../services/bugsnag';

const columnOptions = {
  grp: { name: 'Grouping', sort: 1 },
  orders: { name: 'Orders', sort: 1 },
  items_both: { name: 'Items', sort: 1 },
  items_returned: { name: 'Items Returned', sort: 1 },
  items: { name: 'Items', sort: 1 },
  subtotal: { name: 'Subtotal', money: 1, sort: 1 },
  shipping: { name: 'Shipping', money: 1 },
  credits: { name: 'User Credits', money: 1 },
  discount: { name: 'Discount', money: 1, sort: 1 },
  tiered_discount: { name: 'Inside Rwds', money: 1, sort: 1 },
  retailer_discount: { name: 'Rtlr Disc.', money: 1, sort: 1 },
  tax: { name: 'Tax', money: 1, sort: 1 },
  net: { name: 'Net', money: 1 },
  refunded_amount: { name: 'Refunded', money: 1 },
  refunded_amount_appeasement: { name: 'Refunded Appeasement', money: 1 },
  aov: { name: 'AOV', money: 1 },
  wholesale_tax: { name: 'WHLS Tax', money: 1 },
  wholesale_subtotal: { name: 'WHLS Cost', money: 1 },
  take_rate: { name: 'Take Rate', money: 'color' },

  collection: { name: 'Collection' },
  created_at: { name: 'Date' },
  status: { name: 'Status', sort: 1 },
};

class OrdersReportContainer extends React.Component {
  constructor() {
    super();

    const params = new URLSearchParams(window.location.search);

    let endDate = moment();
    let startDate = moment()
      .subtract(30, 'days')
      .startOf('month');
    if (params.get('date_end')) {
      endDate = moment(params.get('date_end'));
    }
    if (params.get('date_start')) {
      startDate = moment(params.get('date_start'));
    }

    this.state = {
      searchTerms: {
        date_start: params.get('date_start'),
        date_end: params.get('date_end'),
        time_period: params.get('time_period') || 'day',
        search: params.get('search') || '',
        source: params.get('source') || '',
        status: params.get('status') || '',
        // I *could* piggy back this value on `search`
        // and then have the back check if that is a valid coupon code
        coupon_code: params.get('coupon_code') || '',
        sort: params.get('sort') || 'grp',
      },

      focusedInput: null,
      startDate,
      endDate,
      orders: [],
      sources: {},
    };
  }

  componentDidMount() {
    this.getOrders(this.state);
    this.getSources();
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
    if (startDate && endDate) {
      const searchTerms = this.state.searchTerms;
      searchTerms.date_start = startDate.format('YYYY-MM-DD');
      searchTerms.date_end = endDate.format('YYYY-MM-DD');
      this.setState({ searchTerms });
      this.getOrders();
    }
  };

  getSources = async () => {
    try {
      const response = await request.get(`${WAREHOUSE_API_URL}/warehouse/api/sources`);
      const sources = {};
      response.data.map(source => sources[upperFirst(source.name)] = source.id);
      this.setState({ sources });
    } catch (error) {
      bugsnagError(error);
    }
  };

  getOrders = () => {
    browserHistory.push({
      pathname: window.location.pathname,
      search: `?${Utils.buildURLParams(this.state.searchTerms)}`,
    });

    const { dispatch } = this.props;
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.GET_ORDERS}`, 'get_orders', {
        params: this.state.searchTerms,
      }),
    ).catch((error) => bugsnagError(error));
  };

  updateSort = (name) => {
    let name_ = name;
    if (this.state.searchTerms.sort === name) {
      name_ += 'asc';
    }
    this.updateSearchTerm('sort', name_);
  };
  updateSearchTerm = (name, val) => {
    const searchTerms = this.state.searchTerms;
    searchTerms[name] = val;
    this.setState({ searchTerms });
    this.getOrders();
  };
  // handle when the user selects an option for the results to be filtered
  // filter parameter is an array of form [type, value]
  handleFilter = (filter) => {
    const type = filter[0];
    const value = filter[1];
    this.updateSearchTerm(type, value);
  };

  handleChange = (e) => {
    this.updateSearchTerm(e.target.name, e.target.value);
  };

  isExpandable = () => {
    if (this.state.searchTerms.time_period === 'day') return true;
    if (this.state.searchTerms.time_period === 'week') return true;
    if (this.state.searchTerms.time_period === 'month') return true;
    if (this.state.searchTerms.time_period === 'payment_method') return true;
    if (this.state.searchTerms.time_period === 'retailer_order_number') return true;
    if (this.state.searchTerms.time_period === 'agent_user') return true;
    if (this.state.searchTerms.time_period === 'designer_user') return true;
    if (this.state.searchTerms.time_period === 'stylist_user') return true;
    return false;
  };
  expandRow = (row) => {
    if (!this.isExpandable()) return;
    const selector = `#inner${row.grp_id || row.grp}`;
    const element = $(selector);
    if (element.css('display') === 'block') {
      // it's open, just close it and stop
      element.slideToggle();
      return;
    }
  
    // make an ajax request to get the stuff ....
    const params = Object.assign({}, this.state.searchTerms);
    params.time_period = 'order';
    if (this.state.searchTerms.time_period === 'day') {
      params.date_start = row.grp;
      params.date_end = row.grp;
    }
    if (this.state.searchTerms.time_period === 'week') {
      params.date_start = row.grp;
      params.date_end = moment(row.grp)
        .add(6, 'days')
        .format('YYYY-MM-DD');
    }
    if (this.state.searchTerms.time_period === 'month') {
      params.date_start = row.grp;
      params.date_end = moment(row.grp)
        .add(1, 'months')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    }
    if (this.state.searchTerms.time_period === 'payment_method') {
      params.payment_method = row.grp;
    }
    if (this.state.searchTerms.time_period === 'retailer_order_number') {
      params.retailer_order_number = row.grp;
    }
    if (this.state.searchTerms.time_period === 'agent_user') {
      params.agent_user = row.grp_id;
    }
    if (this.state.searchTerms.time_period === 'designer_user') {
      params.designer_user = row.grp_id;
    }
    if (this.state.searchTerms.time_period === 'stylist_user') {
      params.stylist_user = row.grp_id;
    }
    $.ajax({
      headers: {
        'Content-Type': 'application/json',
      },
      dataType: 'JSON',
      url: ApiConfig.GET_ORDERS,
      data: params,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        let html = '';
        const columnOrder = this.columnOrder();
        // loop on the rows
        for (let i = 0; i < data.rows.length; i += 1) {
          const row = data.rows[i];
          row.grp = `<span><a href="/order_processing?orderId=${row.grp}" target="_blank" rel="noopener noreferrer">${row.grp}</a></span>`;
          row.items_both = row.items;
          if(row.items_returned){
            row.items_both += ' ('+row.items_returned+')'
          }
          row.net = row.subtotal - (row.credits||0) - row.discount - row.tiered_discount - row.retailer_discount;

          // NOTE: These calculations should move to a domain layer and should be consolidated with the above logic
          let takeRate = 0;
          if (row.refunded_amount > 0) {

            // Break out appeasements from full refund
            const appeasements = row.refunded_amount_appeasement;
            const refundedAmount = (appeasements > 0) ? row.refunded_amount - appeasements : row.refunded_amount;
            const wholesaleRefund = row.wholesale_refund;
            const wholesaleDeduction = (wholesaleRefund > 0) ? row.wholesale_subtotal - wholesaleRefund : row.wholesale_subtotal;

            takeRate = ((row.net - refundedAmount) - wholesaleDeduction) - appeasements;
          }
          else {
            takeRate = row.net - row.wholesale_subtotal;
          }

          row.take_rate = takeRate;

          row.orders = row.status;
          html += `<div class="inner ${i % 2 === 0 ? 'even' : 'odd'}">`;
          // loop on the columns
          for (let j = 0; j < columnOrder.length; j += 1) {
            let className = '';
            if (columnOptions[columnOrder[j]].money === 'color' && 1 * row[columnOrder[j]] < 0) {
              className += 'red';
            }
            if (columnOptions[columnOrder[j]].money === 'color' && 1 * row[columnOrder[j]] > 0) {
              className += 'green';
            }
            if (columnOptions[columnOrder[j]].money) {
              row[columnOrder[j]] = Utils.formatMoney(row[columnOrder[j]]);
            }
            row.aov = moment(row.created_at).format('YYYY-MM-DD HH:mm'); // server returns it in PST
            html += `<span class="${columnOrder[j]} ${className}">${row[columnOrder[j]]}</span>`;
          }
          html += '<br class="clear" /></div>';
        }
        element.html(html);
        element.slideToggle();
      },
      error: (e) => {
        bugsnagError(e);
      },
    });
  };
  columnOrder = () => {
    const ret = [
      'grp',
      'aov',
      'orders',
      'items_both',
      'subtotal',
      'credits',
      'discount',
      'tiered_discount',
      'retailer_discount',
      'net',
      'refunded_amount',
      'tax',
      'shipping',
      'wholesale_tax',
      'wholesale_subtotal',
      'take_rate',
    ];
    if (this.state.searchTerms.time_period === 'product') {
      ret.splice(1, 0, 'collection');
    }
    if (this.state.searchTerms.time_period === 'order') {
      ret[1] = 'created_at';
      ret[2] = 'status';
    }
    return ret;
  };
  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };
  downloadCSV = () => {
    //taken from https://stackoverflow.com/a/14966131/562117

    const columnOrder = this.columnOrder();

    // replace 'items_both' with ['items', 'items_returned'] ... so they get in two different columns
    let index = columnOrder.indexOf('items_both');
    Array.prototype.splice.apply(columnOrder, [index+0, 1].concat(['items','items_returned' ]));

    // replace 'items_both' with ['items', 'items_returned'] ... so they get in two different columns
    index = columnOrder.indexOf('refunded_amount');
    Array.prototype.splice.apply(columnOrder, [index+0, 1].concat(['refunded_amount','refunded_amount_appeasement' ]));

    let csvContent = "data:text/csv;charset=utf-8,";
    let rows = [[]]

    // put the headers in the first row
    for (let i = 0; i < columnOrder.length; i += 1) {
      rows[0].push(columnOptions[columnOrder[i]].name)
    }

    // go through each data row, do some preprocessing, then add it to a new row in rows
    for(let i = 0; i < this.props.orders.data.rows.length; i += 1) {
      const row = this.props.orders.data.rows[i]
      // these values are derivative of primary metrics
      row.items_both = row.items
      if(row.items_returned){
        row.items_both += ' ('+row.items_returned+')'
      }
      row.created_at = moment(row.created_at).format('MM-DD kk:mm');
      row.net = row.subtotal - (row.credits||0) - row.discount - row.tiered_discount - row.retailer_discount
      row.aov = row.net / row.orders || 0;

      // NOTE: These calculations should move to a domain layer and should be consolidated with the above logic
      let takeRate = 0;
      if (row.refunded_amount > 0) {

        // Break out appeasements from full refund
        const appeasements = row.refunded_amount_appeasement;
        const refundedAmount = (appeasements > 0) ? row.refunded_amount - appeasements : row.refunded_amount;
        const wholesaleRefund = row.wholesale_refund;
        const wholesaleDeduction = (wholesaleRefund > 0) ? row.wholesale_subtotal - wholesaleRefund : row.wholesale_subtotal;

        takeRate = ((row.net - refundedAmount) - wholesaleDeduction) - appeasements;
      }
      else {
        takeRate = row.net - row.wholesale_subtotal;
      }

      row.take_rate = takeRate;

      if (this.state.searchTerms.time_period === 'day_of_week') row.grp = DAY_OF_WEEK[row.grp];
      if (this.state.searchTerms.time_period === 'order') row.grp =row.grp_id;
      rows.push([])
      for (let j = 0; j < columnOrder.length; j += 1) {
        rows[rows.length-1].push(row[columnOrder[j]] || 0)
      }
    }

    rows.forEach(function(rowArray){
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.style.visibility = 'hidden';
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "modsy_order_report.csv");
    link.innerHTML= "Click Here to download";
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  render() {
    if (!auth.isInGroup('Payment')) {
      return (
        <div
          style={{ textAlign: 'center', marginTop: '100px', fontSize: '20px' }}
        >
          You do not have permission to view this page.
          <br />
          Please contact your manager for access
        </div>
      );
    }

    const currentSourceIndex = Object.values(this.state.sources).indexOf(Number(this.state.searchTerms.source));
    const currentSourceName = Object.keys(this.state.sources)[currentSourceIndex];

    return (
      <div className="orders-report">
        <OrdersReportSearch
          handleFilter={this.handleFilter}
          handleChange={this.handleChange}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          downloadCSV={this.downloadCSV}
          sources={this.state.sources}
          state={this.state}
          currentSourceName={currentSourceName}
        />

        <br className="clear" />

        <OrdersReportList
          orders={this.props.orders}
          updateSort={this.updateSort}
          sort={this.state.searchTerms.sort}
          grouping={this.state.searchTerms.time_period}
          expandRow={this.isExpandable() ? this.expandRow : null}
          columnOptions={columnOptions}
          columnOrder={this.columnOrder}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

const mapStateToProps = (state) => {
  const orders = state.get_orders;
  return {
    orders,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersReportContainer);
