import React, { useEffect, useState } from 'react';
import autosize from 'autosize';
import { TextareaAutosizeExtended as TextareaAustosize } from './sharedStyledComponents';

const TextareaAutosizeExtended = (props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [invalidKeysPressed, setInvalidKeysPressed] = useState(false);

  useEffect(() => {
    setInvalidKeysPressed(true);
    setIsMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    }
  }, []);

  const onResize = (event) => {
    if(event.target.value === '') return;
    autosize(event.target);
      setTimeout(() => {
        event.target.style.display = 'flex';
        autosize.update(event.target);
       }, 350)
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter' || e.key === 'Backspace') {
      return setInvalidKeysPressed(false);
    }
    return setInvalidKeysPressed(true);
  }

  return <TextareaAustosize 
    onKeyDown={handleKeyDown} 
    async={ true } 
    onResize={(e) => {invalidKeysPressed && isMounted && onResize(e)}} 
    {...props} 
    />
}

export default TextareaAutosizeExtended;