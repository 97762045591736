import * as types from './action-types';

/**
 * Sets the raw list of product favorites.
 * This has limited info:
 * - like status,
 * - spaceIDs in which you can find the product,
 * - productID,
 * = reason of dislike (when available)
 *
 * ex:
 * list = [{...}, {
 *    reasons: ['too expensive']
 *    space_ids: []
 *    id: 1485261
 *    like: -1
 * }]
 */
export const setRawListOfUserFavorites = rawListOfUserFavorites => ({
  type: types.SET_RAW_LIST_OF_USER_FAVORITES,
  payload: rawListOfUserFavorites
});

/**
* Sets the complete list of product favorites.
* This has a lot more info like shipping info, availability, type, price, tags, promos, etc.
*/
export const setTransformedListOfUserFavorites = transformedListOfUserFavorites => ({
  type: types.SET_TRANSFORMED_LIST_OF_USER_FAVORITES,
  payload: transformedListOfUserFavorites
});

/**
* Sets the list of feedpage favorites for a user
* Feedpage favorites is an array with objects with pattern { style, room_type, slug, title }
*/
export const setUserFeedpageFavorites = feedpageFavorites => ({
  type: types.SET_FEEDPAGE_FAVORITES,
  payload: feedpageFavorites
});
