import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  Image,
  FormControl,
  Button,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import get from 'lodash/get';
import partition from 'lodash/partition';

import OrderProcessingDetailsSuborder from './OrderProcessingDetailsSuborder';
import LoadingIcon from '../LoadingIcon';
import Utils from '../../utils';
import { WAREHOUSE_API_URL } from '../../config';
import { getStatusList } from './utils';
import { ORDER_REPORT_GROUPING } from '../../../src/constants';
import { SCRAPED_RETAILERS } from './constants';
import SuborderMessage from './SuborderMessage';

export default function OrderProcessingDetailsOrder({
  addProduct = () => {},
  addProductDetails = undefined,
  addProductSearch = (productId = null) => {},
  automatedSuborders = [],
  datePicker = {},
  dirtyFields = {},
  manualSuborders = [],
  orderAction = (action, itemId, availableAmount, suborderId) => {},
  orderDetails = {},
  orderSourceDiscounts = {},
  orderShippingHistory = {},
  purchasingAgents = {},
  spinnerGif = undefined,
  updateOrderItemDetails = (name, value, orderItemId) => {},
  usersDesigners = {},
  usersStylists = {},
} = {}) {
  const orderDetailsData = orderDetails.data;
  const orderDetailsStatus = get(
    orderDetailsData,
    'order_details.status',
    null
  );
  const orderDetailsStatusOriginal = get(
    orderDetailsData,
    'order_details.status_original',
    null
  );

  const orderNumber = get(orderDetailsData, 'order_details.order_number', '');
  const orderId = get(orderDetailsData, 'order_details.id', null);
  const orderCreationTimestamp = get(
    orderDetailsData,
    'order_details.created_at',
    ''
  );

  const hasPurchasingAgents = !!get(purchasingAgents, 'data.count', 0);
  const allPurchasingAgents =
    hasPurchasingAgents && get(purchasingAgents, 'data.results', []);
  const agentUserId = get(orderDetailsData, 'order_details.agent_user_id', '');
  const allUsersDesigners = get(usersDesigners, 'data.results', []);
  const allUsersStylists = get(usersStylists, 'data.results', []);

  const noteForCustomer = get(
    orderDetailsData,
    'order_details.notes_for_customer',
    ''
  );

  const stylistUserId = get(
    orderDetailsData,
    'order_details.stylist_user_id',
    null
  );
  const designerUserId = get(
    orderDetailsData,
    'order_details.designer_user_id',
    null
  );

  const couponInfo =
    get(orderDetailsData, 'order_details.coupon_info', {}) || {};

  const isLuxeOrder = get(
    orderDetailsData,
    'order_details.is_luxe_order',
    false
  );

  const isAutomationProcessing = get(
    orderDetailsData,
    'order_details.is_automation_processing',
    false
  );

  const isAutomationFailure = get(
    orderDetailsData,
    'order_details.is_automation_failure',
    false
  );

  const ORDER_STATUSES = getStatusList(orderDetailsStatusOriginal);

  let orderStatusName = '';
  for (let i = 0; i < ORDER_STATUSES.length; i++) {
    if (ORDER_STATUSES[i].id === orderDetailsStatus) {
      orderStatusName = ORDER_STATUSES[i].name;
    }
  }

  let stylistTitle = !!stylistUserId
    ? 'Unknown User ' + stylistUserId
    : '--None Selected--';
  allUsersStylists.forEach((entry) => {
    const { id, first_name, last_name } = entry || {};

    if (id === stylistUserId) {
      stylistTitle = `${first_name} ${last_name}`;
    }
  });

  let designerTitle = !!designerUserId
    ? 'Unknown User ' + designerUserId
    : '--None Selected--';
  usersDesigners.data.results.forEach((entry) => {
    const { id, first_name, last_name } = entry || {};

    if (id === designerUserId) {
      designerTitle = `${first_name} ${last_name}`;
    }
  });

  // Let's split our suborders into 2 subarrays:
  // Scraped vs not Scraped
  const [ScrapedSuborders, notScrapedSuborders] = partition(
    Object.values(manualSuborders),
    (manualSuborder) => !!SCRAPED_RETAILERS.includes(manualSuborder.name)
  );

  let isScrapingFailure = false;
  let isScrapingProcessing = false;
  let i = 0;
  while (
    i < ScrapedSuborders.length &&
    !isScrapingFailure &&
    !isScrapingProcessing
  ) {
    let orderItems = ScrapedSuborders[i].items;
    isScrapingFailure = orderItems.some(
      (item) => item.shipping_details_scrape_status === 'failed'
    );
    isScrapingProcessing = orderItems.some(
      (item) => item.shipping_details_scrape_status === 'in_progress'
    );
    i++;
  }

  // Block to build agent title.
  const agent =
    (allPurchasingAgents &&
      allPurchasingAgents.length &&
      allPurchasingAgents.find((agent) => agent.id === agentUserId)) ||
    {};
  const { first_name = '', last_name = '' } = agent;
  const hasFirstOrLastName = first_name || last_name;
  const purchasingAgentTitle = hasFirstOrLastName
    ? `${first_name} ${last_name}`
    : '--None Selected--';
  return (
    <div className="customer-order">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1>Customer Order</h1>
        {isLuxeOrder && <LuxeBadge>Luxe</LuxeBadge>}
      </div>
      <hr />
      <CustomerOrderHeaderRight
        allPurchasingAgents={allPurchasingAgents}
        allUsersDesigners={allUsersDesigners}
        allUsersStylists={allUsersStylists}
        designerTitle={designerTitle}
        orderStatuses={ORDER_STATUSES}
        orderStatusName={orderStatusName}
        purchasingAgentTitle={purchasingAgentTitle}
        stylistTitle={stylistTitle}
        updateOrderItemDetails={updateOrderItemDetails}
      />
      <CustomerOrderHeaderLeft
        orderCreationDate={Utils.getDate(orderCreationTimestamp)}
        orderCreationTime={Utils.getTime(orderCreationTimestamp)}
        orderId={orderId}
        orderNumber={orderNumber}
      />
      <Header3>Order Details</Header3>
      {!!notScrapedSuborders.length && (
        <NotScrapedOrders>
          <Header4 color="black">Retailers - Not Scraped</Header4>
          {notScrapedSuborders.map((notScrapedSuborder) => {
            return (
              <OrderProcessingDetailsSuborder
                dirtyFields={dirtyFields}
                key={notScrapedSuborder.id}
                orderDetails={orderDetails}
                orderSourceDiscounts={orderSourceDiscounts}
                orderShippingHistory={orderShippingHistory}
                suborder={notScrapedSuborder}
                status={orderDetailsData.order_details.status_original}
                updateOrderItemDetails={updateOrderItemDetails}
                orderAction={orderAction}
                datePicker={datePicker}
                couponInfo={couponInfo}
              />
            );
          })}
        </NotScrapedOrders>
      )}
      {!!ScrapedSuborders.length && (
        <ScrapedOrders>
          <Header4 color="black">Retailers - Scraped</Header4>
          {isScrapingFailure
            ? SuborderMessage('error', 'scraping')
            : isScrapingProcessing
            ? SuborderMessage('processing', 'scraping')
            : ''}
          {ScrapedSuborders.map((ScrapedSuborder) => {
            return (
              <OrderProcessingDetailsSuborder
                dirtyFields={dirtyFields}
                key={ScrapedSuborder.id}
                orderDetails={orderDetails}
                orderSourceDiscounts={orderSourceDiscounts}
                orderShippingHistory={orderShippingHistory}
                suborder={ScrapedSuborder}
                status={orderDetailsData.order_details.status_original}
                updateOrderItemDetails={updateOrderItemDetails}
                orderAction={orderAction}
                datePicker={datePicker}
                couponInfo={couponInfo}
                isScrapedOrder={true}
              />
            );
          })}
        </ScrapedOrders>
      )}
      {!!automatedSuborders.length && (
        <AutomatedOrders>
          <Header4 color="white">Automated Orders</Header4>
          {isAutomationFailure
            ? SuborderMessage('error', 'automation')
            : isAutomationProcessing
            ? SuborderMessage('processing', 'automation')
            : ''}
          {automatedSuborders.map((automatedSuborder) => {
            return (
              <OrderProcessingDetailsSuborder
                dirtyFields={dirtyFields}
                key={automatedSuborder.id}
                orderDetails={orderDetails}
                orderSourceDiscounts={orderSourceDiscounts}
                orderShippingHistory={orderShippingHistory}
                suborder={automatedSuborder}
                status={orderDetailsData.order_details.status_original}
                updateOrderItemDetails={updateOrderItemDetails}
                orderAction={orderAction}
                datePicker={datePicker}
                couponInfo={couponInfo}
              />
            );
          })}
        </AutomatedOrders>
      )}
      <ProductSearchSection addProductSearch={addProductSearch} />
      <ProductSearchResults
        addProduct={addProduct}
        addProductDetails={addProductDetails}
        hasNoResults={
          addProductDetails &&
          addProductDetails.show &&
          addProductDetails.show === 'unknownProduct'
        }
        isAddingProduct={spinnerGif === 'addProduct'}
        isSearching={addProductDetails && addProductDetails.show}
      />
      <NotesForCustomer
        noteForCustomer={noteForCustomer}
        orderDetailsData={orderDetailsData}
        updateOrderItemDetails={updateOrderItemDetails}
      />
      <br />
      <OrangeButton
        buttonContent="Go To Wholesale Order (admin)"
        url={`${WAREHOUSE_API_URL}/admin_payment/payment/wholesaleorder/${orderDetailsData.order_details.wholesale.id}/#/tab/inline_0/`}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <OrangeButton
        buttonContent="Go To Customer Order (admin)"
        url={`${WAREHOUSE_API_URL}/admin_payment/payment/customerorder/${orderDetailsData.order_details.customer.id}/#/tab/inline_0/`}
      />
    </div>
  );
}

/**
 * As a first attempt to refactor this giant and complicated component,
 * we are breaking down the chunks of code into smaller React components.
 * TODO: Move each one into independent file and add unit testing.
 * asana ticket: https://app.asana.com/0/1131938021244497/1162546395325665
 */

function ProductSearchResults({
  addProduct = () => {},
  addProductDetails = {},
  hasNoResults = false,
  isAddingProduct = false,
  isSearching = false,
} = {}) {
  if (!isSearching) {
    return null;
  }

  if (hasNoResults) {
    return (
      <div className="suborder">
        <strong>Sorry! I can't find that product</strong>
      </div>
    );
  }

  const { productImage, productName, productPrice, sourceName } =
    addProductDetails || {};

  return (
    <div className="suborder">
      <div className="col1" style={{ height: 'inherit' }}>
        <Image src={productImage} />
      </div>
      <strong>{productName}</strong> - {sourceName}
      <div>{Utils.formatMoney(productPrice, 2)}</div>
      <Button className="action-button green" onClick={addProduct}>
        Add To Order
      </Button>
      {isAddingProduct && (
        <LoadingIcon size="rightOfButton" style={{ border: 'none' }} />
      )}
      <br className="clear" />
    </div>
  );
}

function ProductSearchSection({
  addProductSearch = (productId = null) => {},
} = {}) {
  return (
    <h3>
      &#8853;Add Product&nbsp;
      <input
        className="form-control"
        type="text"
        placeholder="search by product id"
        style={{ width: '200px' }}
        onChange={(e) => addProductSearch(e.target.value)}
      />
    </h3>
  );
}

function OrangeButton({ buttonContent = '', url = '' } = {}) {
  return (
    <Button
      rel="noopener noreferrer"
      className="action-button orange"
      onClick={() => window.open(url)}
    >
      {buttonContent}
    </Button>
  );
}

function NotesForCustomer({
  noteForCustomer = '',
  orderDetailsData = {},
  updateOrderItemDetails = (name, value, orderItemId) => {},
} = {}) {
  return (
    <Fragment>
      <h3>Notes For Customer</h3>

      <FormControl
        style={{ height: 100 }}
        componentClass="textarea"
        placeholder="These notes will be included in the receipt email when the order is captured ..."
        onChange={(e) => updateOrderItemDetails(e.target.name, e.target.value)}
        name="notes_for_customer"
        value={noteForCustomer || ''}
      />
    </Fragment>
  );
}

function CustomerOrderHeaderLeft({
  orderCreationDate = '',
  orderCreationTime = '',
  orderId = null,
  orderNumber = '',
} = {}) {
  return (
    <Fragment>
      <strong>Order Number: {orderNumber}</strong>
      <br />
      <strong>Order Id: {orderId}</strong>
      <br />
      Date Placed: {orderCreationDate} {orderCreationTime}
      <br />
    </Fragment>
  );
}
function CustomerOrderHeaderRight({
  allPurchasingAgents = [],
  allUsersDesigners = [],
  allUsersStylists = [],
  designerTitle = '',
  orderStatuses = [],
  orderStatusName = '',
  purchasingAgentTitle = '',
  stylistTitle = '',
  updateOrderItemDetails = (name, value, orderItemId) => {},
} = {}) {
  return (
    <table className="options-table">
      <tbody>
        <tr>
          <td>Order Status</td>

          <td>
            <ButtonToolbar>
              <DropdownButton
                title={`${orderStatusName}`}
                id="status"
                onSelect={(val) => updateOrderItemDetails('order_status', val)}
              >
                {orderStatuses.map((entry) => {
                  const { id, name } = entry || {};
                  return (
                    <MenuItem key={id} eventKey={id}>
                      {name}
                    </MenuItem>
                  );
                })}
              </DropdownButton>
            </ButtonToolbar>
          </td>
        </tr>

        <tr>
          <td>{ORDER_REPORT_GROUPING.agent_user}</td>

          <td>
            <ButtonToolbar>
              <DropdownButton
                title={purchasingAgentTitle}
                id="agent_user_id"
                onSelect={(val) => updateOrderItemDetails('agent_user_id', val)}
              >
                <MenuItem key={0} eventKey={''}>
                  --None Selected--
                </MenuItem>

                {allPurchasingAgents.length &&
                  allPurchasingAgents.map((agent) => {
                    const { id, first_name, last_name } = agent || {};
                    return (
                      <MenuItem key={id} eventKey={id}>
                        {first_name} {last_name}
                      </MenuItem>
                    );
                  })}
              </DropdownButton>
            </ButtonToolbar>
          </td>
        </tr>

        <tr>
          <td>Design Specialist</td>

          <td>
            <ButtonToolbar>
              <DropdownButton
                title={designerTitle}
                id="designer_user_id"
                onSelect={(val) =>
                  updateOrderItemDetails('designer_user_id', val)
                }
              >
                <MenuItem eventKey={''}>--None Selected--</MenuItem>

                {allUsersDesigners.length &&
                  allUsersDesigners.map((entry) => {
                    const { id, first_name, last_name } = entry || {};
                    return (
                      <MenuItem key={id} eventKey={id}>
                        {first_name} {last_name}
                      </MenuItem>
                    );
                  })}
              </DropdownButton>
            </ButtonToolbar>
          </td>
        </tr>

        <tr>
          <td>Style Advisor</td>

          <td>
            <ButtonToolbar>
              <DropdownButton
                title={stylistTitle}
                id="stylist_user_id"
                onSelect={(val) =>
                  updateOrderItemDetails('stylist_user_id', val)
                }
              >
                <MenuItem eventKey={''}>--None Selected--</MenuItem>

                {allUsersStylists.length &&
                  allUsersStylists.map((entry) => {
                    const { id, first_name, last_name } = entry || {};
                    return (
                      <MenuItem key={id} eventKey={id}>
                        {first_name} {last_name}
                      </MenuItem>
                    );
                  })}
              </DropdownButton>
            </ButtonToolbar>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

// Styles
const GroupOfOrders = styled.div`
  margin: 0px;
  padding: 1px;
  border-radius: 4px;
  box-shadow: 3px 1px 12px 6px;
`;

const NotScrapedOrders = styled(GroupOfOrders)`
  background: #ecf9fd;
`;

const ScrapedOrders = styled(GroupOfOrders)`
  background: #dddddd;
  margin: 30px 0 0 0;
`;

const AutomatedOrders = styled(GroupOfOrders)`
  background: #797373;
  margin: 30px 0 0 0;
`;

const Header4 = styled.h4`
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};
  padding: 5px;
`;

const Header3 = styled.h3`
  margin-bottom: 30px;
`;

const LuxeBadge = styled.div`
  margin-left: 2rem;
  font-size: 2rem;
  border: 1px solid #924b54;
  color: #924b54;
  padding: 0 1rem;
  border-radius: 4px;
  line-height: 3rem;
`;
