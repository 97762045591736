import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Button, Alert, Panel, Col, Grid, Row } from 'react-bootstrap';
import axios from 'axios';
import * as request from '../global/request';
import { WAREHOUSE_API_URL } from '../config';

export default class JobContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      job: {
        spaceId: '',
        designId: '',
        profile: 'draft',
      },
      cameraNameList: [],
      msg: '',
      showAlert: false,
      start: 0,
      limit: 50,
      pageIndex: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.start !== this.state.start) {
      this.fetchData(nextState.start);
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://dac3df4e29b543049fc7cbe991849770.js.ubembed.com';
    script.async = true;
    document.body.appendChild(script);
  }

  getColumnWidth(accessor, headerText) {
    const { data } = this.state;

    let max = 0;
    const maxWidth = 200;
    const magicSpacing = 18;

    data.forEach((d) => {
      if (d !== undefined && d[accessor] !== null) {
        if (String(d[accessor] || 'null').length > max) {
          max = String(d[accessor] || 'null').length;
        }
      }
    });

    return Math.min(maxWidth, Math.max(max, headerText.length) * magicSpacing);
  }

  fetchData = (start = 0) => {
    this.setState({ loading: true });
    const { limit, handle } = this.state;
    let url = `${WAREHOUSE_API_URL}/warehouse/api/v1/render/list?start=${start}&limit=${limit}`;
    if (handle) {
      url += `&handle=${handle}`;
    }
    request.get(url).then((res) => {
      if (res && res.data && res.data.results) {
        this.setState({
          data: [...res.data.results.jobs],
          loading: false,
          start,
          limit,
          pages: Math.round(res.data.results.total / limit),
        });
      } else {
        const msg = 'Something went wrong, please refresh and try again';
        this.toggleAlert(msg, 'danger');
      }
    });
  };

  handleGetCameras = () => {
    if (!this.spaceId.value || !this.designId.value) {
      alert('Please input a spaceID and designID');
      return;
    }

    const url = `${WAREHOUSE_API_URL}/warehouse/api/v1/spaces/${this.spaceId.value}/designs/${this.designId.value}/data`;

    request
      .get(url)
      .then((res) => {
        if (res.data.ok && res.data.results.length > 0) {
          const link = res.data.results.filter((f) => f.role === 'CAMERAS')[0]
            .url;
          this.loadCameraDataFromLink(link);
        } else {
          const msg = `No camera file found. Attempted to make GET request to ${url}.`;
          this.toggleAlert(msg, 'danger');
        }
      })
      .catch((e) => {
        this.toggleAlert(
          `Get Cameras failed. Attempted to make GET request to ${url}.`,
          'danger'
        );
      });
  };

  toggleAlert = (msg, type) => {
    this.setState({ showAlert: true, msg, type });
  };

  loadCameraDataFromLink = (url) => {
    const tempAxios = axios.create({ headers: {} });
    return tempAxios
      .get(url)
      .then((cameraData) => {
        let cameraDict;
        try {
          cameraDict = cameraData.data;
        } catch (err) {
          cameraDict = {};
          this.toggleAlert(
            `ERROR PARSNIG CAMERA FILE: ${err.message} file contents: ${cameraData}`,
            'danger'
          );
          return;
        }
        const cameraNameList = [];
        for (const key in cameraDict) {
          const pCamItem = cameraDict[key];
          if (
            !(pCamItem.pos || pCamItem['controller.position']) ||
            !pCamItem['target.pos'] ||
            !pCamItem.fov ||
            !pCamItem.name
          ) {
            continue;
          }
          cameraNameList.push(pCamItem.name);
        }

        this.setState({ cameraNameList });
      })
      .catch((e) => {
        this.toggleAlert(
          `Get Cameras failed. Attempted to make GET request to ${url}.`,
          'danger',
          JSON.stringify(e)
        );
      });
  };

  handleAlerts = () => {
    if (this.state.showAlert) {
      return (
        <Alert bsStyle={this.state.type}>
          <h4>
            {this.state.type === 'danger' ? 'You got an error!' : 'Success!'}
          </h4>
          <p>{this.state.msg}</p>
          <p>
            <Button onClick={() => this.setState({ showAlert: false })}>
              Close Alert
            </Button>
          </p>
        </Alert>
      );
    }
    return null;
  };

  handleStop = (handle) => {
    const api = `${WAREHOUSE_API_URL}/warehouse/api/v1/render/stop/${handle}`;
    request.post(api).then((res) => {
      if (res.data.ok) {
        this.fetchData(this.state.start);
        const msg = `Successly stopped render with handle: ${handle}. Refresh job list if job is still listed`;
        this.toggleAlert(msg, 'success');
      }
    });
  };

  handlePriority = (handle, priority) => {
    const api = `${WAREHOUSE_API_URL}/warehouse/api/v1/render/increase-priority/${handle}`;
    const data = {
      priority,
    };
    request
      .post(api, data)
      .then((res) => {
        if (res.data.ok) {
          this.fetchData(this.state.start);
          const msg = `Successly increased priority of ${handle} to ${priority}.`;
          this.toggleAlert(msg, 'success');
        } else {
          const msg = `Error: ${res.data.reason}`;
          this.toggleAlert(msg, 'danger');
        }
      })
      .catch(({ message }) => {
        this.toggleAlert(message, 'danger');
      });
  };

  resetCameraNames = () => {
    this.setState({ cameraNameList: [] });
  };

  startJob = (e) => {
    e.preventDefault();
    if (this.state.cameraNameList.length === 0) {
      alert('Please generate and select a camera first!');
      return;
    }

    const data = {
      settings: 'ModsyPreset',
      camera: this.cameraName.value,
      render_empty_room: false,
      render_room_custom_colors: false,
    };

    const url = `${WAREHOUSE_API_URL}/warehouse/api/v1/render/spaces/${this.spaceId.value}/designs/${this.designId.value}/start?profile=${this.profile.value}`;

    request
      .post(url, data)
      .then((res) => {
        if (res.data.ok) {
          this.fetchData(this.state.start);
          const msg = `Successly started Space: ${this.spaceId.value}, Design: ${this.designId.value}, Type: ${this.profile.value}. Refresh job list if running job isn't listed`;
          this.toggleAlert(msg, 'success');
        } else {
          const msg = `Oops! Something went wrong while trying to make a POST request to ${url} with OPTIONS: ${JSON.stringify(
            data
          )}. \n${res.data.reason}`;
          this.toggleAlert(msg, 'danger');
        }
      })
      .catch((e) => {
        this.toggleAlert(e, 'danger');
      });
  };

  renderCameraList = () => {
    if (this.state.cameraNameList.length === 0) {
      return (
        <Button bsStyle="primary" onClick={this.handleGetCameras}>
          Get Cameras
        </Button>
      );
    }

    return (
      <select
        style={{ margin: '20px' }}
        ref={(input) => {
          this.cameraName = input;
        }}
      >
        {this.state.cameraNameList.map((name, i) => {
          return (
            <option key={`${name}-${i}`} value={name}>
              {name}
            </option>
          );
        })}
      </select>
    );
  };

  handlePageIndex = (pageIndex) => {
    this.setState({ start: pageIndex * 50, pageIndex });
  };

  renderTable() {
    const { data, loading } = this.state;
    const columns = [
      {
        header: 'Job',
        columns: [
          {
            header: 'Handle',
            accessor: 'handle',
            minWidth: this.getColumnWidth('handle', 'Handle'),
          },
          {
            header: 'State',
            accessor: 'state',
          },
          {
            header: 'Room #',
            accessor: 'space_id',
          },
          {
            header: 'Design #',
            accessor: 'design_id',
          },
          {
            header: 'Priority',
            accessor: 'priority',
          },
        ],
      },
      {
        header: 'Actions',
        columns: [
          {
            header: 'Stop',
            render: (props) => {
              const { handle, state } = props.row;
              return (
                <div>
                  {state === 'stopped' ? (
                    'Job is currently stopped'
                  ) : (
                    <Button
                      onClick={() => this.handleStop(handle)}
                      block
                      bsStyle={'danger'}
                    >
                      {'Stop Render'}
                    </Button>
                  )}
                </div>
              );
            },
          },
          {
            header: 'Increase Priority',
            render: (props) => {
              const { handle } = props.row;
              return (
                <div>
                  <span>
                    <Button
                      onClick={() => this.handlePriority(handle, 50)}
                      block
                      bsStyle={'danger'}
                    >
                      {'High'}
                    </Button>
                    <Button
                      onClick={() => this.handlePriority(handle, 100)}
                      block
                      bsStyle={'danger'}
                    >
                      {'Highest'}
                    </Button>
                  </span>
                </div>
              );
            },
          },
        ],
      },
    ];

    return (
      <div>
        {this.handleAlerts()}
        <div>
          <Grid>
            <Row className="show-grid">
              <Col md={9}>
                <Panel header="Start a rendering job" bsStyle="primary">
                  <label htmlFor="spaceId">Space ID</label>
                  <input
                    style={{ margin: '20px' }}
                    ref={(input) => {
                      this.spaceId = input;
                    }}
                    onChange={this.resetCameraNames}
                    type="text"
                    id="spaceId"
                    required
                  />
                  <label htmlFor="designId">Design ID</label>
                  <input
                    style={{ margin: '20px' }}
                    ref={(input) => {
                      this.designId = input;
                    }}
                    onChange={this.resetCameraNames}
                    type="text"
                    id="designId"
                    required
                  />
                  {this.renderCameraList()}
                  <select
                    style={{ margin: '20px' }}
                    ref={(input) => {
                      this.profile = input;
                    }}
                  >
                    <option value="draft">Draft</option>
                    <option value="beauty">Beauty</option>
                  </select>
                  <br />
                  <Button bsStyle="success" onClick={this.startJob}>
                    Start Job
                  </Button>
                  <Button
                    style={{ margin: '20px' }}
                    onClick={() => this.fetchData(this.state.start)}
                  >
                    Refresh List
                  </Button>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </div>

        <div className="input-group col-lg-2" style={{ marginBottom: '20px' }}>
          <input
            id="Special"
            type="text"
            className="form-control"
            placeholder="Search Space ID"
            ref={(input) => (this.searchSpaceID = input)}
            onChange={(e) => this.setState({ handle: e.target.value })}
          />
          <div className="input-group-btn">
            <button
              onClick={() => this.fetchData(this.state.start)}
              className="btn btn-primary"
            >
              <i className="glyphicon glyphicon-search" />
            </button>
            <button
              onClick={() => {
                if (this.state.handle) {
                  this.searchSpaceID.value = '';
                  this.setState({ handle: '' }, () => {
                    this.fetchData(this.state.start);
                  });
                }
              }}
              className="btn btn-primary"
            >
              <i className="glyphicon glyphicon-remove" />
            </button>
          </div>
        </div>

        <ReactTable
          className="-striped -highlight"
          data={data}
          page={this.state.pageIndex}
          pages={this.state.pages}
          columns={columns}
          defaultPageSize={50}
          showFilters={false}
          loading={loading}
          showPageSizeOptions={false}
          onPageChange={(pageIndex) => this.handlePageIndex(pageIndex)}
        />
      </div>
    );
  }

  render() {
    return <div style={{ padding: '0px 25px 30px' }}>{this.renderTable()}</div>;
  }
}
