import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bugsnagError } from 'services/bugsnag';

import * as request from '../../global/request'; 
import uploadIcon from '../../images/icon-photos-upload.svg';
import removeIcon from '../../images/icon-photos-remove.svg';
import rotatePhotosIcon from '../../images/icon-photos-rotate.svg';
import {
  fetchDataIfNeeded
} from 'actions/api-data-request-generator';
import { ApiConfig } from 'config';
import { dataRefresh } from '../../actions/api-data-request-generator';
import Utils from '../../utils';
import LOADER_GIF from '../../components/images/ajax-loader-2.gif';

export default function useFloorPaintSampleImages({
  activeSpaceID = 0,
  data = [],
  needRefresh = false,
  error = false,
}) {
  const dispatch = useDispatch();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [additionalPhotos, setAdditionalPhotos] = useState(data || []); // default to an array here as additional photos loads as null

  /** Handles displaying and hiding the larger size image modal
   * Also ensures the correct index is shown
   */
  function toggleModal(index) {
    setIsOpen(!isOpen);
    setActiveIndex(index);
  }

  /** Handles displaying the previous image in the modal carousel */
  const increment = () => {
    const updatedActiveIndex = activeIndex < data.length - 1 ? activeIndex + 1 : 0;
    setActiveIndex(updatedActiveIndex);
  }

  /** Handles displaying the next image in the modal carousel */
  const decrement = () => {
    const updatedActiveIndex = activeIndex > 0 ? activeIndex - 1 : data.length - 1;
    setActiveIndex(updatedActiveIndex);
  }

  /** Handles initializing additional photos on load,
   * and refreshing the data when it changes */
  useEffect(() => {
    const additionalPhotosCache = ['user_data', activeSpaceID, 'additional_photos'];
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.ADDITIONAL_PHOTOS(activeSpaceID)}`,
        'additional_photos',
        {
          keys: { space: activeSpaceID },
          cache: additionalPhotosCache
        }
      )
    )
    .catch((error) => bugsnagError(error));
  }, [activeSpaceID, needRefresh, dispatch]) // eslint-disable-line
  
  // Formatting for the additional photos object
  useEffect(() => {
    if (!data) return;
    const copyOfAdditionalPhotos = [...data];
    copyOfAdditionalPhotos.map((key, index) => {
      key.uploaded_at = Utils.getFormattedDateTime(key.uploaded_at, 'short', true);
      key.photoIcons = {...photoIcons};
      return key;
    })
    setAdditionalPhotos(copyOfAdditionalPhotos);
  }, [data, needRefresh, dispatch])

  /** Handles deleting a single image and ensuring data refreshes */
  const deleteImage = (id, index) => {
    const copyOfAdditionalPhotos = [...additionalPhotos];
    copyOfAdditionalPhotos[index].photoIcons.remove = LOADER_GIF;
    setAdditionalPhotos(copyOfAdditionalPhotos);
    
    return request.deleteOption(`${ApiConfig.ADDITIONAL_PHOTOS(activeSpaceID)}?ids=${id}`)
      .then(() => {
        dispatch(dataRefresh('additional_photos', { space: activeSpaceID }))
      })
      .catch((error) => bugsnagError(error));
  }

  // Store the data that will be passed to the Upload Photos modal
  const uploadModalData = {
    title: 'Upload User Photos',
    url: ApiConfig.ADDITIONAL_PHOTOS(activeSpaceID),
    actionIdentifier: 'additional_photos_upload',
    refreshIdentifier:'additional_photos',
    method: 'put'
  }

  // Setup the modal content for the larger image view
  const { uploaded_at } = additionalPhotos[activeIndex] || '';

  const numberOfPhotos = data && data.length;

  const modalContent = {
    header: `${title} (${activeIndex + 1}/${numberOfPhotos})`,
    footer: `Uploaded at: ${uploaded_at}`
  }

  return {
    floorPaintSampleState: {
      activeIndex,
      activeSpaceID,
      additionalPhotos,
      modalContent,
      decrement,
      deleteImage,
      needRefresh,
      imageUploadInfo,
      increment,
      isOpen,
      setShowUploadModal,
      showUploadModal,
      title,
      toggleModal,
      uploadModalData
    }
  }
}

// Constant objects and variables
const title = 'Customer photos of flooring or paint samples';

const imageUploadInfo = {
  title: 'Upload samples',
  icon: uploadIcon,
  alt: 'upload icon'
}

const photoIcons = {
  remove: removeIcon,
  rotate: rotatePhotosIcon
}
