import React from 'react';

export default class Image extends React.Component {
  shouldComponentUpdate(nextProps) {
    // split at ? to account for changing query params from S3
    if(!this.props.src || !nextProps.src) { return };

    if (nextProps.src.split('?')[0] !== this.props.src.split('?')[0]) {
      this.img.className = `${this.props.className} invisible-image`;
      this.loader.style.display = 'inline-block';
      return true;
    }
    return false;
  }

  handleImageLoaded = () => {
    this.img.className = `${this.props.className} visible-image`;
    this.loader.style.display = 'none';
  };

  handleImageError = () => {
    // Nate TODO: handle image not found failures
    this.loader.className = 'image-not-found';
  };

  render() {
    const fixedClassProps = Object.assign({}, this.props);
    delete fixedClassProps.className;
    const nullSourceProps = Object.assign({}, this.props, { src: null });
    return (
      <React.Fragment>
        <img
          alt="main-component"
          className={`${this.props.className} invisible-image`}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageError}
          ref={el => (this.img = el)}
          src={this.props.src}
          {...fixedClassProps}
        />
        <img
          alt="loader-component"
          ref={el => (this.loader = el)}
          {...nullSourceProps}
        />
      </React.Fragment>
    );
  }
}
