import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import get from 'lodash/get';
import LoadingIcon from '../components/LoadingIcon';
import CustomProps from '../components/CustomProps';

const CustomPropsContainer = ({ isFetching, data, error, activeSpaceID, customProps }) => {
  // handle loading and errors
  if (error) {
    return (
      <Alert bsStyle="danger">
        <strong>Oops!</strong> Something went wrong fetching the custom props
        information.
      </Alert>
    );
  } else if (isFetching && !data) {
    return <LoadingIcon />;
  }

  return <CustomProps 
    props= { data } 
    customProps = { customProps } 
    spaceID= { activeSpaceID } 
    />;
};

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  return {
    isFetching: get(
      state,
      `space_data[${activeSpaceID}].custom_props.isFetching`,
    ),
    lastUpdated: get(
      state,
      `space_data[${activeSpaceID}].custom_props.lastUpdated`,
    ),
    data: get(state, `space_data[${activeSpaceID}].custom_props.data`),
    error: get(state, `space_data[${activeSpaceID}].custom_props.error`),
    activeSpaceID,
  };
};

export default connect(mapStateToProps)(CustomPropsContainer);
