import React from 'react';
import styled from 'styled-components';

export default function MobileCaptureGif({ url = '' }) {
  const {
    Image
  } = styles;
  return (
    <Image src={url} alt='mobile capture gif' />
  )
}

const styles = {
  Image: styled.img`
    margin-top: 4rem;
    object-fit: contain;
  `
}
