import React from 'react';
import {
  FormGroup,
  FormControl,
  Row,
  Col,
  Radio,
} from 'react-bootstrap';

import {
  ADDITIONAL_ROOM_PREFERENCE_OPTIONS,
  STREAMLINER_ROOM_PREFERENCE_OPTIONS,
  INCLUDE_IN_LAYOUT_OPTIONS,
  INCLUDE_IN_LAYOUT_OTHER_OPTION,
  KEEP_CURRENT_LAYOUT_OPTIONS,
  LAYOUT_MAIN_FOCUS_OPTIONS,
  LAYOUT_MAIN_FOCUS_OTHER_OPTION,
  MAX_TEXT_AREA_COUNT,
  NONE_OPTION,
  NoAnswerProvidedStr,
  TV_SIZE_OPTIONS
} from '../../../constants';
import PanelLink from '../../../sharedComponents/PanelLink/PanelLink';
import Checkbox from '../../../sharedComponents/Checkbox/Checkbox';
import TextareaAutosizeExtended from '../../../sharedComponents/TextareaAutosizeExtended';
import { handleFormattingLinks } from '../Utils';
import { debounceMaxTextCharactersAlert } from '../../../utils';

// TO DO: Convert this file to a Controller, and move each component to separate files
// This will in effect improve readability and make the code more maintainable
// asana ticket and respective sub tasks: https://app.asana.com/0/1133576522235947/1163830588554897
export default class RoomLayoutAndFunctionV1 extends React.Component {
  state = {
    editingTVPrefs: false
  }

  handleNoneOptionTypeChange = (type, value, limit = 0) => async e => {
    const { handleChange } = this.props;
    e.persist();
    if (e.target.checked) {
      let newValue;
      // Clear all values when none option selected
      if (value === NONE_OPTION) {
        newValue = [value];
        await handleChange('', `${type}_other`);
      } else {
        // if limit reached, do not add more values
        if (limit && this.props[type].length >= limit) { return; }

        const filteredValues = RoomLayoutAndFunctionV1.removeNoneOption(this.props[type]);
        newValue = [...filteredValues, value];
      }

      handleChange(newValue, type);
    } else {
      const values = this.props[type].filter(option => option !== value);
      handleChange(values, type);
    }
  }

  handleOtherOptionChange = (type, otherOption, limit = 0) => async (e) => {
    const { handleChange } = this.props;
    e.persist();
    const values = this.props[type].filter(option => option !== otherOption);
    // Only edit other text if limit hasn't been reached
    // or other already existed in values
    if (!limit || (values.length < limit)) {
      await handleChange(e.target.value, `${type}_other`);
    }

    if (e.target.value.length) {
      // do not add other option if at limit
      if (limit && values.length >= limit) { return; }
      const newValue = RoomLayoutAndFunctionV1.removeNoneOption(values);
      handleChange([...newValue, otherOption], type);
    } else {
      handleChange(values, type);
    }

  }

  static removeNoneOption (options) {
    // remove none option when selecting other type of option
    const newOptions = options.filter(option => option !== NONE_OPTION);
    return [...newOptions];
  }

  render() {
    const {
      alertVisible = false,
      additional_room_preferences = [],
      anything_else_design_needs = '',
      contentDisplay = {},
      doesMainFocusHaveContent = false,
      do_you_want_tv = false,
      what_size_tv = 0,
      where_to_place_tv = '',
      additional_media = '',
      handleChange = () => {},
      include_in_layout = [],
      include_in_layout_other = '',
      keep_current_layout = '',
      layout_main_focus = [],
      layout_main_focus_other = '',
      setContentDisplayOption = () => {},
      spaceList,
    } = this.props;

    const { editingTVPrefs } = this.state;
    const focusOptions = Object.assign({}, LAYOUT_MAIN_FOCUS_OPTIONS);
    const oldTVAnswer = layout_main_focus.includes(focusOptions['TV'].value);
    const wantTVStr = 'Yes, I want a TV';
    const dontWantTVStr = 'No, thanks';


    // Used to conditionally show the warning if the user has removed all choices since main focus is deprecated in PPO
    const hasLayoutMainFocusOther = !!layout_main_focus_other && layout_main_focus_other !== '';
    const hasLayoutMainFocus = layout_main_focus && layout_main_focus.length > 0;
    const shouldShowMainFocusWarningMsg = alertVisible && !hasLayoutMainFocus && !hasLayoutMainFocusOther;
    const mainFocusWarningMsg = shouldShowMainFocusWarningMsg && (
      'Saving with no information will result in this question being hidden. It is no longer used.'
    );

    if (do_you_want_tv !== null || !oldTVAnswer) {
      delete focusOptions['TV'];
    }
    const definiteNoTV = do_you_want_tv === 0 || do_you_want_tv === false;
    const definiteYesTV = do_you_want_tv === 1 || do_you_want_tv === true;

    const formatTVLink = () => {
      const isDoYouWantTVNull = do_you_want_tv === null;

      switch (true) {
        case isDoYouWantTVNull:
          return NoAnswerProvidedStr;
        case definiteYesTV:
          return getFormattedString(wantTVStr);
        case definiteNoTV:
          return getFormattedString(dontWantTVStr);
        default:
          return getFormattedString('');
        }
    }

    const getFormattedString  = (str = '') => {
      return str += `
      ${TV_SIZE_OPTIONS[what_size_tv] ? ', ' + TV_SIZE_OPTIONS[what_size_tv]: ''}
      ${where_to_place_tv ? ', ' + where_to_place_tv: ''}`
    };

    const mainFocus = (
      <FormGroup key='main' controlId="formControlsLayoutMainFocus">
        <PanelLink
          header='What should be the main focus of your layout?'
          warning={mainFocusWarningMsg}
          link={handleFormattingLinks('layout_main_focus', layout_main_focus_other)}
          displayContent={contentDisplay.isLayoutMainFocus}
          onClick={() => setContentDisplayOption('isLayoutMainFocus')}
        >
        {Object.values(focusOptions).map(({ value, label }, index) => {
          const formattedID = label.split(' ').join('-');
          return (
            <Checkbox
              data-testid={label}
              className={'layout_main_focus'}
              label={label}
              key={index}
              checked={layout_main_focus.includes(value)}
              value={formattedID}
              onChange={this.handleNoneOptionTypeChange('layout_main_focus', value, 2)}
            />
            );
          })}
          <TextareaAutosizeExtended
            value={layout_main_focus_other || ''}
            id='layout_main_focus_other'
            maxLength={MAX_TEXT_AREA_COUNT}
            onKeyUp={debounceMaxTextCharactersAlert}
            onChange={this.handleOtherOptionChange(
              'layout_main_focus',
              LAYOUT_MAIN_FOCUS_OTHER_OPTION,
            )}
          />
        </PanelLink>
      </FormGroup>
    );

    const includeInLayout = (
      <FormGroup key='include' controlId="formControlsIncludeInLayout">
        <PanelLink
          header='What would you like us to include in your layout?'
          link={handleFormattingLinks('include_in_layout', include_in_layout_other)}
          displayContent={contentDisplay.isIncludeInLayout}
          onClick={() => setContentDisplayOption('isIncludeInLayout')}
        >
          {Object.values(INCLUDE_IN_LAYOUT_OPTIONS[spaceList.space_type]).map(
            ({ value, label }, index) => {
              const formattedID = label.split(' ').join('-');
              return (
                  <Checkbox
                  className={'include_in_layout'}
                  label={label}
                  key={index}
                  checked={include_in_layout.includes(value)}
                  value={formattedID}
                  onChange={this.handleNoneOptionTypeChange('include_in_layout', value)}
                  />
              );
            }
          )}
          <TextareaAutosizeExtended
            maxLength={MAX_TEXT_AREA_COUNT}
            id='include_in_layout_other'
            value={include_in_layout_other || ''}
            onKeyUp={debounceMaxTextCharactersAlert}
            onChange={this.handleOtherOptionChange(
              'include_in_layout',
              INCLUDE_IN_LAYOUT_OTHER_OPTION
            )}
          />
        </PanelLink>
      </FormGroup>
    );

    const roomPrefs = (do_you_want_tv !== null || !oldTVAnswer)
      ? STREAMLINER_ROOM_PREFERENCE_OPTIONS
      : ADDITIONAL_ROOM_PREFERENCE_OPTIONS;

    const additionalPrefs = (
      <FormGroup key='addl' controlId="formControlsAdditionalRoomPreferences">
        <PanelLink
          header='Any additional room preferences?'
          link={handleFormattingLinks('additional_room_preferences', '')}
          displayContent={contentDisplay.isRoomPreferences}
          onClick={() => setContentDisplayOption('isRoomPreferences')}
        >
          {Object.values(roomPrefs).map(({ value, label }, index) => {
            const formattedID = label.split(' ').join('-');
            return (
              <Checkbox
                key={index}
                className='additional_room_preferences'
                label={label}
                checked={additional_room_preferences.includes(value)}
                value={formattedID}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleChange(
                      [...additional_room_preferences, value],
                      'additional_room_preferences'
                    );
                  } else {
                    const values = additional_room_preferences.filter(
                      option => option !== value
                    );
                    handleChange(values, 'additional_room_preferences');
                  }
                }}
              />
            );
          })}
        </PanelLink>
      </FormGroup>
    );

    const doYouWantTV = (
      <React.Fragment>
      <FormGroup key='tv' controlId="formControlsTVPreferences">
        <PanelLink
          header='Do you want a TV in this room?'
          link={formatTVLink()}
          displayContent={contentDisplay.isDoYouWantTV}
          onClick={() => setContentDisplayOption('isDoYouWantTV')}
        >
          <Radio
            checked={do_you_want_tv}
            value={1}
            onChange={e => handleChange(1, 'do_you_want_tv')}
          >
            {wantTVStr}
          </Radio>
          {do_you_want_tv
            ? <aside data-testid='doYouWantTV' style={{paddingLeft: '20px'}}>
              {editingTVPrefs
                ? [<p key={'what-size-tv'}>
                  Size:
                  <FormControl
                    componentClass="select"
                    style={{width: 'inherit', display: 'inline', marginLeft: '8px'}}
                    value={what_size_tv || 0}
                    onChange={e => handleChange(e.target.value, 'what_size_tv')}>
                      {Object.values(TV_SIZE_OPTIONS).map((val, ind) =>
                        <option key={ind} value={ind}>{val}</option>
                      )}
                  </FormControl>
                </p>,
                <p key='optional-tv-placement'>
                  Optional - TV placement preferences:
                </p>,
                <TextareaAutosizeExtended
                  key='where-to-place-tv'
                  value={where_to_place_tv || ''}
                  onChange={e => handleChange(e.target.value, 'where_to_place_tv')}
                />]
                : <div className='tvprefs' onClick={() => this.setState({editingTVPrefs: true})}>
                  <p>Size: {TV_SIZE_OPTIONS[what_size_tv] || 'None'}</p>
                  <p>Placement preferences: {where_to_place_tv && where_to_place_tv.length ? where_to_place_tv : 'None'}</p>
                </div>}
            </aside>
            : null}

          <Radio
            checked={definiteNoTV}
            value={0}
            onChange={e => handleChange(0, 'do_you_want_tv')}
          >
            {dontWantTVStr}
          </Radio>
          </PanelLink>
          </FormGroup>
          <FormGroup>
            <PanelLink
            header={`Is there any other media or tech you'd like us to include in your design?`}
            link={additional_media && additional_media.length ? additional_media : NoAnswerProvidedStr}
            displayContent={contentDisplay.isAdditionalMedia}
            onClick={() => setContentDisplayOption('isAdditionalMedia')}
            >
              <TextareaAutosizeExtended
                key='j'
                value={additional_media || ''}
                onChange={e => handleChange(e.target.value, 'additional_media')}
              />
          </PanelLink>
          </FormGroup>
      </React.Fragment>
    )

    const anythingElse = (
      <FormGroup key='else' controlId="formControlsAnythingElseDesignNeeds">
        <PanelLink
          header='Anything else we need to know to make sure your designs meet your needs?'
          link={handleFormattingLinks('', anything_else_design_needs,)}
          displayContent={contentDisplay.isAnythingElseDesignNeeds}
          onClick={() => setContentDisplayOption('isAnythingElseDesignNeeds')}
        >
          <TextareaAutosizeExtended
            value={anything_else_design_needs || ''}
            onChange={e => handleChange(e.target.value, 'anything_else_design_needs')}
          />
        </PanelLink>
      </FormGroup>
    );

    const currentLayout = (
      <FormGroup controlId="formControlsKeepCurrentLayout">
        <PanelLink
          header='Do you want to keep your current layout?'
          link={handleFormattingLinks('keep_current_layout', '')}
          displayContent={contentDisplay.isKeepCurrentLayout}
          onClick={() => setContentDisplayOption('isKeepCurrentLayout')}
        >
        {
          Object.keys(KEEP_CURRENT_LAYOUT_OPTIONS).map((key, index) => {
            const keepCurrentLayoutOption = KEEP_CURRENT_LAYOUT_OPTIONS[key];

            return (
              <Checkbox
              key={ `${key}-${index}` }
              className={'keep_current_layout'}
              label={keepCurrentLayoutOption}
              checked={ keep_current_layout === key }
              value={ key }
              onChange={(e) => {
                handleChange(e.target.value, 'keep_current_layout');
              }}
            />
            )
          })
        }

        </PanelLink>
      </FormGroup>
    )

    return (
      <Row>
        {(!oldTVAnswer || do_you_want_tv !== null)
          ? (
            <React.Fragment>
              <Col key='1' xs={12} sm={6}>
                {currentLayout}
                {doesMainFocusHaveContent && mainFocus}
                {includeInLayout}
                {additionalPrefs}
              </Col>,
              <Col key='2' xs={12} sm={6}>
                {doYouWantTV}
                {anythingElse}
              </Col>
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <Col key='1' xs={12} sm={6}>
                {currentLayout}
                {doesMainFocusHaveContent && mainFocus}
                {anythingElse}
              </Col>,
              <Col key='2' xs={12} sm={6}>
                {includeInLayout}
                {additionalPrefs}
              </Col>
            </React.Fragment>
          )}

      </Row>
    );
  }
}
