import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory, Redirect } from 'react-router';
import cookie from 'js-cookie';
import axios from 'axios';
import { BugsnagErrorBoundary as AppErrorBoundary } from './services/bugsnag';
import { unregister } from './registerServiceWorker';
import store from './store';
import Styleboard from './containers/Styleboard';
import Users from './containers/UsersContainer';
import OrdersReport from './containers/OrdersReportContainer';
import OrderProcessing from './containers/OrderProcessingContainer';
import CustomerInfoPortal from './containers/CustomerInfoPortalContainer';
import AssetDownloader from './containers/AssetDownloader';
import AssetShow from './containers/AssetShow';
import { StyleboardCopyProductsComponent } from './containers/SbCopyTool';
import { NewAdminConfig } from './config';
import AdminPMDenied from './components/PMDenied';
import JobContainer from './containers/JobContainer';
import Dashboard from './containers/Dashboard';
import AppContainer from './containers/App';
import Login from './containers/Login';

import { auth } from './auth';
import './sass/admin.css';

axios.interceptors.request.use(
  (config) => {
    if (cookie.get('jwtToken')) {
      config.headers.common.Authorization = `JWT ${cookie.get('jwtToken')}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function requireAuth(nextState, replace, callback) {
  if (!auth.loggedIn()) {
    const forwardTo = window.location.pathname + window.location.search;
    replace({
      pathname: `/login?forwardTo=${encodeURIComponent(forwardTo)}`,
      state: {
        nextPathname: nextState.location.pathname,
      },
    });
  }
  return callback();
}

function redirectToDashboard(nextState, replace) {
  if (auth.loggedIn()) {
    replace('/assets');
  }
}

ReactDOM.render(
  <AppErrorBoundary>
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="/" component={AppContainer}>
          <Redirect from="/" to="/assets" />

          <Route
            path={`${NewAdminConfig.ASSETDOWNLOADER_ROUTE}`}
            component={AssetDownloader}
            onEnter={requireAuth}
          />

          <Route
            path={`${NewAdminConfig.ASSETDOWNLOADER_ROUTE}(/:slug)`}
            component={AssetShow}
            onEnter={requireAuth}
          />

          <Route
            path="login(:params)"
            component={Login}
            onEnter={redirectToDashboard}
          />

          <Route
            path={`${NewAdminConfig.STYLEBOARDCOPIER_ROUTE}(/:slug)`}
            component={StyleboardCopyProductsComponent}
            onEnter={requireAuth}
          />

          <Route
            path={`${NewAdminConfig.STYLEBOARD_ROUTE}(/:slug)`}
            component={Styleboard}
            onEnter={requireAuth}
          />

          <Route
            path={NewAdminConfig.USERS_ROUTE}
            component={Users}
            onEnter={requireAuth}
          />

          <Route
            path={`${NewAdminConfig.USERS_ROUTE}(/:id)`}
            component={CustomerInfoPortal}
            onEnter={requireAuth}
          />

          <Route
            path={`${NewAdminConfig.SENDPMDENIED_ROUTE}(/:slug)`}
            component={AdminPMDenied}
            onEnter={requireAuth}
          />
          <Route
            path={NewAdminConfig.ORDERSREPORT}
            component={OrdersReport}
            onEnter={requireAuth}
          />
          <Route
            path={NewAdminConfig.ORDERPROCESSING}
            component={OrderProcessing}
            onEnter={requireAuth}
          />
          <Route
            path="jobs/history"
            component={JobContainer}
            onEnter={requireAuth}
          />
          <Route
            path="jobs/dashboard"
            component={Dashboard}
            onEnter={requireAuth}
          />
        </Route>
      </Router>
    </Provider>
  </AppErrorBoundary>,
  document.getElementById('root'),
);

unregister();
