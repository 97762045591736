import React from 'react';
import styled from 'styled-components';
import UploadModal from 'components/UploadModal';
import CustomImageModal from 'sharedComponents/CustomImageModal';

export default function FloorPaintSampleImages ({
  activeIndex = 0,
  additionalPhotos = [],
  modalContent = '',
  deleteImage = () => {},
  decrement = () => {},
  imageUploadInfo = {},
  increment = () => {},
  isOpen = false,
  setShowUploadModal = () => {},
  showUploadModal = false,
  title = {},
  toggleModal = () => {},
  uploadModalData = {}
}) {
  return (
    <Container>
      <Header>{title}</Header>
      <UploadButton onClick={() => { setShowUploadModal(true); }}>
        <Icon src={imageUploadInfo.icon} alt={imageUploadInfo.alt} />
        {imageUploadInfo.title}
      </UploadButton>
      <BorderLine />
      <UploadModal
        show={showUploadModal}
        close={() => {
          setShowUploadModal(false);
        }}
        title={uploadModalData.title}
        url={uploadModalData.url}
        actionIdentifier={uploadModalData.actionIdentifier}
        refreshIdentifier={uploadModalData.refreshIdentifier}
        method={uploadModalData.method}
      />
      {additionalPhotos && additionalPhotos.length === 0 ? (
        <Text>No photos yet.</Text>
      ) : (
        <ImagesContainer>
          {additionalPhotos.map((key, index) => {
            const { filename, id, uploaded_at, uploader, photoIcons } = key || {};
            const { remove } = photoIcons || {};
            const currentIndex = index + 1;
            return (
              <ImageContent key={'floor-paint-sample-image' + index}>
                <ContentRow>
                  <Item>{currentIndex}</Item>
                  {/* <Button>
                    <img src={photoIcons.rotate} alt="rotate icon" />
                  </Button> */}
                  <Button>
                    <img onClick={() => deleteImage(id, index)} src={remove || ''} alt="remove icon" />
                  </Button>
                </ContentRow>
                <ImageContainer onClick={() => toggleModal(index)}>
                  <Image src={filename} />
                </ImageContainer>
                <TextContainer>
                  <Text>{uploaded_at}</Text>
                  <Text>{uploader}</Text>
                </TextContainer>
              </ImageContent>
            );
          })}
        </ImagesContainer>
      )}
      <CustomImageModal
        activeIndex={activeIndex}
        decrement={decrement}
        headerContent={modalContent.header}
        footerContent={modalContent.footer}
        increment={increment}
        images={additionalPhotos}
        isOpen={isOpen}
        toggleModal={toggleModal}
      />
    </Container>
  );
}

const TextContainer = styled.div`
  /* Used as an empty wrapper so that each Text line
  does not take up a full grid space */
`;

const Text = styled.div`
  color: #8B8B8B;
  word-break: break-all;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  margin-top: 34px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 4px;
`;

const UploadButton = styled.button`
  width: 124px;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  padding: 0;
`;

const Header = styled.div`
  font-weight: 700 !important;
`;

const Icon = styled.img`
  margin-right: 4px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 1/ span 2;
  grid-gap: 10px;
`;

const ImageContent = styled.div`
  display: grid;
  grid-template-rows: 32px 1fr;
  width: 120px;
  height: auto;
  grid-gap: 8px;
`;

const ContentRow = styled.div`
  height: 100%;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 8px;
  align-items: center;
`;

const Item = styled.div`
  font-weight: 600 !important;
`;

const ImageContainer = styled.button`
  height: 120px;
  width: 100%;
  position: relative;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  cursor: pointer;
  background: none;
`;

const Image = styled.img`
  grid-row: 2;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const BorderLine = styled.div`
  border-bottom: 1px solid #CCCCCC;
  grid-column: 1 / span 2;
`;
