import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tab, Nav, NavItem, Badge, Glyphicon } from 'react-bootstrap';
import get from 'lodash/get';

import { ApiConfig, WAREHOUSE_API_URL } from '../config';
import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import Utils from '../utils';
import { bugsnagError } from '../services/bugsnag';

import PhotosAndMeasurementsContainer from '../containers/PhotosAndMeasurementsContainer';
import DesignsContainer from '../containers/DesignsContainer';
import CustomPropsContainer from '../containers/CustomPropsContainer';
import VisionContainer from '../containers/VisionContainer';
import OrderHistoryContainer from '../containers/OrderHistoryContainer';
import SpaceHistory from '../components/SpaceHistory/index';
import ImageModal from '../components/ImageModal';
import FavoritePropsContainer from '../containers/FavoritePropsContainer';

import { getSpaceStatus, getSpaceHistoryCount } from '../reducers/space-data-reducer';
import { getRawListOfUserFavorites } from '../reducers/favorites-list-reducer';
import { tabNames } from '../constants';
class SpaceTabNavigationContainer extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: tabNames.projectDetails,
      customProps: [],
    };
    this._isMounted = false;
  }

  componentDidMount() {
    const { dispatch, activeSpaceID } = this.props;
    this._isMounted = true;
    // photos and measurements data
    const dimensionsCache = ['space_data', activeSpaceID, 'dimensions'];
    const activityLogCache = ['space_data', activeSpaceID, 'activity_log'];
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.PHOTOS_AND_MEASURMENTS}`, 'dimensions', {
        params: { space_id: activeSpaceID, photos: 'all' },
        keys: { space: activeSpaceID },
        cache: dimensionsCache,
      })
    ).catch((error) => bugsnagError(error));

    // designs data
    const rendersCache = ['space_data', activeSpaceID, 'renders'];
    const designsCache = ['space_data', activeSpaceID, 'designsV2'];
    dispatch(
      fetchDataIfNeeded(
        // load renders for all designs
        `${ApiConfig.RENDERS}`,
        'renders',
        {
          params: { space_id: activeSpaceID },
          keys: { space: activeSpaceID },
          cache: rendersCache,
        }
      )
    ).catch((error) => bugsnagError(error));

    dispatch(
      fetchDataIfNeeded(
        // load designs feedback information
        `${ApiConfig.DESIGNSV2}?spaceId=${activeSpaceID}&filter=heidi&sort=desc&page=1`,
        'designsV2',
        {
          keys: { space: activeSpaceID },
          cache: designsCache,
        }
      )
    );

    // custom props data
    const customPropsCache = ['space_data', activeSpaceID, 'custom_props'];
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.CUSTOM_PROPS}`, 'custom_props', {
        params: { space_id: activeSpaceID },
        keys: { space: activeSpaceID },
        cache: customPropsCache,
      })
    ).catch((error) => bugsnagError(error));

    const productCategoriesCache = [
      'space_data',
      activeSpaceID,
      'product_categories',
    ];
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.PRODUCT_CATEGORIES(activeSpaceID)}`,
        'product_categories',
        {
          keys: { space: activeSpaceID },
          cache: productCategoriesCache,
        }
      )
    ).catch((error) => bugsnagError(error));

    this.getCustomPropsPages();

    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.GET_ACTIVITY_LOG(activeSpaceID)}`,
        'activity_log',
        {
          keys: { space: activeSpaceID },
          cache: activityLogCache,
        }
      )
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Nate TODO: definitely refactor this garbage to be more DRY
    if (nextProps.dimensionsRefresh !== this.props.dimensionsRefresh) {
      const { dispatch, activeSpaceID } = nextProps;

      // photos and measurements data
      const dimensionsCache = ['space_data', activeSpaceID, 'dimensions'];
      dispatch(
        fetchDataIfNeeded(`${ApiConfig.PHOTOS_AND_MEASURMENTS}`, 'dimensions', {
          params: { space_id: activeSpaceID, photos: 'all' },
          keys: { space: activeSpaceID },
          cache: dimensionsCache,
        })
      ).catch((error) => bugsnagError(error));
    }
    if (nextProps.customPropsRefresh !== this.props.customPropsRefresh) {
      const { dispatch, activeSpaceID } = nextProps;
      // custom props data
      const customPropsCache = ['space_data', activeSpaceID, 'custom_props'];
      dispatch(
        fetchDataIfNeeded(`${ApiConfig.CUSTOM_PROPS}`, 'custom_props', {
          params: { space_id: activeSpaceID },
          keys: { space: activeSpaceID },
          cache: customPropsCache,
        })
      ).catch((error) => bugsnagError(error));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { customPropsData } = this.props;

    if (
      customPropsData !== null &&
      prevProps.customPropsData !== null &&
      customPropsData !== prevProps.customPropsData
    ) {
      this.getCustomPropsPages();
    }
  }

  getCustomPropsPages = () => {
    const { activeSpaceID } = this.props;
    const url = `${WAREHOUSE_API_URL}/warehouse/api/v1/space_custom_prop?space_id=${activeSpaceID}`;
    new Promise((resolve, reject) => {
      Utils.getApiPages(url, [], resolve, reject);
    }).then((response) => {
      if (this._isMounted) {
        this.setState({ customProps: response });
      }
    });
  };

  handleSelect = (tab) => {
    this.setState({ activeTab: tab });
  };
  showImageModal = (url) => {
    this.setState({ imageModalUrl: url });
  };
  closeImageModal = () => {
    this.setState({ imageModalUrl: '' });
  };

  /**
   * Stores the key/value pairs data for each tab
   */
  handleTabsInfo = () => {
    const {
      activeSpaceID,
      activeSpaceStatus,
      designsCount,
      customPropsData,
      orderHistory,
      rawListOfUserFavorites,
      spaceHistoryCount,
      spaceInfo,
    } = this.props;

    const {
      customProps,
    } = this.state;

    const customPropsCount = customPropsData ? customPropsData.count : null;
    const favoritesInCurrentSpace =
      (rawListOfUserFavorites &&
        rawListOfUserFavorites.filter((value) =>
          value.space_ids.includes(activeSpaceID)
        )) ||
      [];

    const favoritesCount = favoritesInCurrentSpace.length;

    const OkGlyphIcon = <Glyphicon glyph="ok" />;

    return {
        projectDetails: {
            eventKey: tabNames.projectDetails,
            className: 'project-details-badge',
            tabLabel: 'Project Details',
            badgeLabel: OkGlyphIcon,
            component: (
              <VisionContainer
                componentType={tabNames.projectDetails}
              />
            ),
          },
          roomStyleAndColor: {
            eventKey: tabNames.roomStyleColor,
            className: 'room-style-color-badge',
            tabLabel: 'Room Style & Color',
            badgeLabel: OkGlyphIcon,
            component: (
              <VisionContainer
                componentType={tabNames.roomStyleColor}
              />
            ),
          },
      favoriteProducts: {
        eventKey: tabNames.favoriteProps,
        id: 'favorite-props-badge',
        tabLabel: 'Product Likes & Dislikes',
        badgeLabel: favoritesCount,
        component: <FavoritePropsContainer />,
      },
      photosMeasurements: {
        eventKey: tabNames.photosAndMeasurements,
        id: 'dimensions-badge',
        tabLabel: 'Photos & Measurements',
        badgeLabel: OkGlyphIcon,
        component: (
          <PhotosAndMeasurementsContainer spaceStatus={activeSpaceStatus} />
        ),
      },
      designLayouts: {
        eventKey: 'designs',
        id: 'designs-layout-badge',
        tabLabel: 'Designs & Layouts',
        badgeLabel: designsCount,
        component: <DesignsContainer />,
      },
      desiredProducts: {
        eventKey: tabNames.customProps,
        id: 'designs-badge',
        tabLabel: 'Existing Furniture',
        badgeLabel: customPropsCount,
        component: <CustomPropsContainer customProps={customProps} />,
      },
      orderHistory: {
        eventKey: tabNames.orderHistory,
        id: 'order-history-badge',
        tabLabel: 'Furniture Purchases',
        badgeLabel: orderHistory && orderHistory.quantity,
        component: (
          <OrderHistoryContainer
            orderHistory={orderHistory}
            showImageModal={this.showImageModal}
            spaceInfo={spaceInfo}
          />
        ),
      },
      spacesHistory: {
        eventKey: tabNames.spacesHistory,
        id: 'spaces-history-badge',
        tabLabel: 'History',
        badgeLabel: spaceHistoryCount,
        component: <SpaceHistory />,
      },
    };
  };

  render() {
    const { Container, Pane, Content } = Tab;

    const tabsInfo = this.handleTabsInfo();

    return (
      <Col xs={12}>
        <Container
          id="space-tab-navigation"
          activeKey={this.state.activeTab}
          className="space-navigation"
          onSelect={this.handleSelect}
        >
          <Row className="clearfix">
            <Col sm={3}>
              <Nav bsStyle="pills" stacked>
                {Object.keys(tabsInfo).map((key, index) => {
                  const eventKey = tabsInfo[key].eventKey;
                  const tabLabel = tabsInfo[key].tabLabel;
                  const tabID = tabsInfo[key].id;
                  const badgeLabel = tabsInfo[key].badgeLabel;

                  return (
                    <NavItem key={index} eventKey={eventKey}>
                      <Row>
                        <Col xs={10}>{tabLabel}</Col>
                        <Col xs={2}>
                          <Badge
                            id={tabID}
                            className={'space-container-tab-titles'}
                          >
                            {badgeLabel}
                          </Badge>
                        </Col>
                      </Row>
                    </NavItem>
                  );
                })}
              </Nav>
            </Col>
            <Col sm={9}>
              <Content animation className="space-navigation-content">
                {Object.keys(tabsInfo).map((key, index) => {
                  const eventKey = tabsInfo[key].eventKey;
                  const tabComponent = tabsInfo[key].component;
                  return (
                    <Pane
                      className={'space-container-tab-components'}
                      key={index}
                      eventKey={eventKey}
                    >
                      {tabComponent}
                    </Pane>
                  );
                })}
              </Content>
            </Col>
          </Row>
        </Container>
        <ImageModal
          imageURL={this.state.imageModalUrl}
          showImage={this.state.imageModalUrl}
          close={this.closeImageModal}
        />
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { space_data, user_data } = state;
  const activeSpaceID = space_data && space_data.activeID;
  const activeSpaceData =
    (space_data &&
      space_data.filteredSpaces &&
      space_data.filteredSpaces.find((space) => space.id === activeSpaceID)) ||
    {};
  const currentSpaceStatus = getSpaceStatus(state);
  const spaceHistoryCount = getSpaceHistoryCount(state, activeSpaceID);
  const rawListOfUserFavorites = getRawListOfUserFavorites(state);

  const activeSpaceStatus = currentSpaceStatus ? currentSpaceStatus : activeSpaceData.status;
  const userID = user_data && user_data.user_id;


  return {
    userID,
    activeSpaceID,
    activeSpaceStatus,
    rawListOfUserFavorites,
    spaceHistoryCount,

    dimensionsData: get(state, `space_data[${activeSpaceID}].dimensions.data`),
    dimensionsRefresh: get(
      state,
      `space_data[${activeSpaceID}].dimensions.needRefresh`
    ),
    designsData: get(state, `space_data[${activeSpaceID}].designsV2.data`),
    designsCount: get(
      state,
      `space_data[${activeSpaceID}].designsV2.data.count`
    ),
    customPropsData: get(
      state,
      `space_data[${activeSpaceID}].custom_props.data`
    ),
    customPropsRefresh: get(
      state,
      `space_data[${activeSpaceID}].custom_props.needRefresh`
    ),
    visionScreenComplete: get(
      state,
      `space_data[${activeSpaceID}].space_info.data.results[0].vision_screen_complete`
    ),
    orderHistory: get(state, `order_history.data`),
    spaceInfo: get(
      state,
      `space_data[${activeSpaceID}].space_info.data.results[0]`
    ),
  };
};

export default connect(mapStateToProps)(SpaceTabNavigationContainer);
