import React from 'react';
import styled, { keyframes } from "styled-components";

import checkIconWhite from '../../images/check_icon_white.svg';
import closeIconWhite from '../../images/close-icon-white.svg';
import { Loader } from '../../sharedComponents/loading/loading';

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; opacity: 0; }
  50% { margin-bottom: 15px; opacity: 0.5; }
  100% { margin-bottom: 0; opacity: 1;}
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Dot = styled.div`
  background-color: #067039;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${props => props.delay};
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
  opacity: 0.5;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  z-index: 5;
  top: 0;
  right: 0;
`

const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Image = styled.img`
  z-index: 4;
  padding: ${ props => props.padding };
  align-self: ${ props => props.self };
  position: ${ props => props.position };
  right: ${ props => props.right };
  cursor: ${ props => props.cursor };
`;

const Text = styled.div`
  display: flex;
  font-size: ${ props => props.smallFont ? '18px' : '30px' };
  font-weight: ${ props => props.semiBold && '500 !important' };
  line-height: ${ props => props.lineHeight ? props.lineHeight : '30px' };
  color: ${ props => props.color ? props.color : '#067039' };
  padding: ${ props => props.padding };
`;

const TextContainer = styled.div`
  padding: ${ props => props.padding };
`;

const RequestContainer = styled.div`
  width: 690px;
  height: auto;
  background: #067039;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  position: fixed;
  z-index: 10;
  bottom: 16px;
  right: 16px;
  padding: 24px;
`;

export function RedesignSuccessModal(
  {
    text,
  },
) {
  return (
    <React.Fragment>
      <Overlay />
      <Container>
        <InnerContainer>
          <Loader scale={3} position='relative' />
          <TextContainer padding='40px 0' >
            <Text semiBold>{text ? text : 'Loading'}
              <DotWrapper>
                <Dot delay="0s" />
                <Dot delay=".1s" />
                <Dot delay=".2s" />
              </DotWrapper>
            </Text>
          </TextContainer>
        </InnerContainer>
      </Container>
    </React.Fragment>
  );
}

export function RequestCreatedModal(
  {
    handleCloseIsStanzaTicketCreated,
    headerText,
    bodyText,
    isRequestEditable,
  },
) {
  return (
    <React.Fragment>
      <RequestContainer>
        <Image 
          alt='close icon' 
          cursor='pointer' 
          onClick={ handleCloseIsStanzaTicketCreated} 
          position='absolute' 
          right='24px' 
          src={closeIconWhite}
          />

        <Image
          self='start'
          padding='0 14px 0 0'
          src={checkIconWhite}
          alt='check icon white'
        />
        <TextContainer>
          <Text
            semiBold
            padding='0 0 8px 0'
            lineHeight='36px'
            color={'#FFF'}
          >
            {headerText}
          </Text>
          
          <Text
            padding={'0 0 8px 0'}
            lineHeight='24px'
            smallFont
            color={'#FFF'}
          >
            {bodyText}
          </Text>
        </TextContainer>
      </RequestContainer>
    </React.Fragment>
  );
}
