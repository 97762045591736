/**
 * This component represents the box on the right side,
 * containing the different action buttons.
 */
import React from 'react';
import { Button } from 'react-bootstrap';

import LoadingIcon from '../LoadingIcon';
import { USER_FACING_COPY } from '../../utils';

export default function OrderActions ({
  canSendOrderUpdateEmail = false,
  hasAutomatedOrders = false,
  hasOrderSavingFailed = false,
  hasRemainingRefund = false,
  isCapturing = false,
  isDirty = false,
  isOrderSaving = false,
  isPlacingAutomatedOrders = false,
  isSendingUpdateOrderEmail = false,
  onOpenStripeUrl = () => {},
  onOrderAction = () => {},
  onPlaceAutomatedOrders = () => {},
  onSaveOrder = () => {},
  onSendOrderUpdateEmail = () => {},
  showCapture = false,
  showPlaceAutomatedOrders = false,
  showRefund = false,
  showStripeButton = false,
} = {}) {
  const {
    orderActionsTitle,
    saveOrder,
    outstandingChanges,
    captureEmailReceipt,
    goToStripe,
    refundOrder,
    sendUpdateEmail,
    placeAutomatedOrders,
    emailReceipt
  } = USER_FACING_COPY.orderActions;

  return (
    <div className='info-box'>
      <div className='header'>
        {orderActionsTitle}
      </div>

      <div className='content'>
        <Button
          className='action-button green'
          disabled={!isDirty}
          onClick={onSaveOrder}
        >
          {saveOrder}
        </Button>

        {isOrderSaving &&
          <LoadingIcon size='rightOfButton' />
        }

        <div className='status_message'>
          {hasOrderSavingFailed &&
            outstandingChanges
          }
        </div>

        <br />

        {showCapture && (
          <span>
            <Button
              disabled={isDirty}
              className='action-button orange'
              onClick={() => onOrderAction('capture')}
            >
              {captureEmailReceipt}
            </Button>

            {isCapturing &&
              <LoadingIcon size='rightOfButton' />
            }
          </span>
        )}

        {showStripeButton && (
          <div>
            <Button
              disabled={isDirty}
              className='action-button orange'
              onClick={onOpenStripeUrl}
            >
              {goToStripe}
            </Button>
          </div>
        )}

        {showRefund && (
          <div>
            <Button
              disabled={isDirty}
              className='action-button orange'
              onClick={() => onOrderAction('refund')}
            >
              {refundOrder({ hasRemainingRefund })}
            </Button>
          </div>
        )}

        {canSendOrderUpdateEmail && (
          <div>
            <Button
              disabled={isDirty || isSendingUpdateOrderEmail}
              className='action-button orange'
              onClick={onSendOrderUpdateEmail}
            >
              {sendUpdateEmail}
            </Button>

            {isSendingUpdateOrderEmail &&
              <LoadingIcon size='rightOfButton' />
            }
          </div>
        )}

        {showPlaceAutomatedOrders && hasAutomatedOrders && (
          <div>
            <Button
              disabled={isDirty || isPlacingAutomatedOrders}
              className='action-button orange'
              onClick={onPlaceAutomatedOrders}
            >
              {placeAutomatedOrders}
            </Button>

            {isPlacingAutomatedOrders &&
              <LoadingIcon size='rightOfButton' />
            }
          </div>
        )}

        <div>
          <Button
            disabled={isDirty}
            className='action-button orange'
            onClick={() => onOrderAction('email_receipt')}
          >
            {emailReceipt}
          </Button>
        </div>
      </div>
    </div>
  );
}