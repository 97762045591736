import React from 'react';
import styled from 'styled-components';

const ZENDESK_DOMAIN = process.env.REACT_APP_ENV !== 'prod'
  ? 'https://modsy1529346777.zendesk.com'
  : 'https://modsy.zendesk.com';


const zenDeskUrl = (zendeskId) => {
  return `${ZENDESK_DOMAIN}/agent/users/${zendeskId}/requested_tickets`;
 };

const ExternalUserLinks = ({ userId, zendeskId }) => {
  return (
    <Container className="external-user-links" xs={6}>
      {zendeskId &&
        <a href={zenDeskUrl(zendeskId)}>Zendesk User Profile</a>
      }
    </Container>
  );
};

const Container = styled.div`
  width: 170px;
`;

export default ExternalUserLinks;
