import React from 'react';
import styled from 'styled-components';
import {
  BaseFlex,
  BaseGrid,
} from 'sharedComponents/sharedStyledComponents';
import closeIconWhite from 'images/close_button_white.svg';
import inactiveCheck from 'images/inactive_check.svg';
export default function QualityMetrics({
  hasMetricsTextFile = true,
  finalRecommendation = '',
  isRecommended = false,
  qualityMetricsResults = {},
}) {
  if (!hasMetricsTextFile) {
    return <TextContainer>No recommendation available </TextContainer>;
  }
  return (
    <Container>
      <ItemsGrid>
        {Object.keys(qualityMetricsResults).map((key) => {
          const result = qualityMetricsResults[key];
          const label = result.label;
          const passed = result.passed;
          return (
            <ItemContainer key={label}>
              <Item data-testid={label + '-item'} passed={passed}>
                <Image
                  src={passed ? inactiveCheck : closeIconWhite}
                  alt="quality icon"
                  data-testid={label + '-image'}
                />
                {label}
              </Item>
            </ItemContainer>
          );
        })}
      </ItemsGrid>
      <TextContainer>
        Recommendation:
        <Text data-testid="recommendation" passed={isRecommended}>
          {finalRecommendation}
        </Text>
      </TextContainer>
    </Container>
  );
}

const Image = styled.img`
  right: 0;
  top: 0;
  padding: 4px;
  height: 30px;
`;

const Container = styled.div`
  color: #ffffff;
`;
const ItemsGrid = styled(BaseGrid)`
  box-sizing: border-box;
  margin: 32px 0;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 8px;
  font-size: 12px;
  text-align: center;
`;

const TextContainer = styled(BaseFlex)`
  color: #000;
  justify-content: flex-end;
  font-size: 14px;
`;

const Text = styled.div`
  color: ${(props) => (props.passed ? '#0A6F38' : '#EF4C22')};
  margin-left: 8px;
`;

const ItemContainer = styled(BaseFlex)`
  max-width: 90px;
  height: 60px;
  flex: 1;
`;

const Item = styled(BaseFlex)`
  line-height: 12px;
  flex-direction: column;
  background: ${(props) => (props.passed ? '#0A6F38' : '#EF4C22')};
  border: ${(props) =>
    props.passed ? '2px solid #41538D' : '1px solid #CCCCCC'};
  box-shadow: ${(props) =>
    props.passed &&
    '0px 3px 3px rgba(0,0,0,0.14),0px 3px 4px rgba(0,0,0,0.12),0px 1px 8px rgba(0,0,0,0.2)'};
  border-radius: 4px;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;

  &:first-of-type {
    margin-left: 0;
  }
`;
