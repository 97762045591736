import React from 'react';
import { Button, ControlLabel, Modal } from 'react-bootstrap';
import { styleResults } from './constants';
export default function AddGenome({
  genomeId,
  handleSubmitGenome,
  isQuizVersionTwo,
  setGenomeID,
  setShouldShowAddGenomeModal,
  shouldShowAddGenomeModal,
}) {
  const handleClose = () => {
    setShouldShowAddGenomeModal(false);
  };

  const handleAddGenome = (genomeId) => {
    if (genomeId !== null) {
      handleSubmitGenome(genomeId);
    }
    handleClose();
  };

  return (
    <Modal show={shouldShowAddGenomeModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Genome</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ControlLabel></ControlLabel>
          <select onChange={(e) => setGenomeID(Number(e.target.value))}>
            <option key="-">-</option>
            {styleResults
              .map((key, index) => {
                const styleResult = key.styleResult;
                const styleID = key.id;
                return (
                  <option key={`${styleResult}-{index}`} value={styleID}>
                    {styleResult}
                  </option>
                );
              })
              .sort((elm1, elm2) => (elm1.key < elm2.key ? -1 : 1))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
        <Button bsStyle="primary" onClick={() => handleAddGenome(genomeId)}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
