import { useState, useEffect } from 'react';
import * as configcat from 'configcat-js';

import {
  CONFIGCAT_KEYS,
  IOS_CONFIGCAT_KEYS,
} from '../constants';

/** Handles getting feature flags from different repositories */
const getClient = (clientName = 'heidi') => {
  const reactAppEnv = process && process.env && process.env.REACT_APP_ENV;
  let configcatKeys = CONFIGCAT_KEYS;

  if (clientName === 'ios') {
    configcatKeys = IOS_CONFIGCAT_KEYS;
  }
  
  const isConfigCatSetForAppEnv =
    reactAppEnv && Object.keys(CONFIGCAT_KEYS).includes(reactAppEnv);

  const configcatApiKey =
    reactAppEnv && isConfigCatSetForAppEnv
      ? configcatKeys[reactAppEnv]
      : configcatKeys['dev'];

  return configcat.createClientWithLazyLoad(configcatApiKey);
};

/* call it like:
   async () => { this.setState({ myFlag: await fetchFlag('myFlag') }); }
*/
export const fetchFlag = (
  keyName,
  defaultVal = false,
  userVal = null,
  clientName = 'heidi'
) => {
  return new Promise((resolve, reject) => {
    try {
      getClient(clientName).getValue(
        keyName,
        defaultVal,
        (value) => resolve(value),
        userVal
      );
    } catch (e) {
      reject(e);
    }
  });
};

/* or call this as a React hook, by the hook rules. (https://reactjs.org/docs/hooks-rules.html)
   (it will run once, when your component mounts.)
   const flagVal = useFlag('myFlagName');
   or:
   const flagVal = useFlag('myFlagName', { defaultValue: true, userValue: <user object, see ConfigCat docs> });
*/
export function useFlag(
  flagName,
  { defaultValue, userValue, clientName } = {
    defaultValue: false,
    userValue: null,
    clientName: 'heidi',
  }
) {
  const [flagValue, setFlagValue] = useState(defaultValue);

  useEffect(() => {
    const doFlag = async () => {
      setFlagValue(
        await fetchFlag(flagName, defaultValue, userValue, clientName)
      );
    };
    doFlag();
  }, [flagName, defaultValue, userValue, clientName]);

  return flagValue;
}
