import React from 'react';
import styled, { keyframes } from 'styled-components';
import { CloseIcon, LeftCarot, RightCarot } from './icons';

const ScaleAnimation = keyframes`
  0% { transform: scale(1) };
  50% { transform: scale(1.3) };
  100% { transform: scale(1) };
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
`;

const CarotContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
`;

const CarotWrapper = styled.div`
  width: auto;
  height: auto;
  cursor: pointer;

  &:hover {
    animation: ${ScaleAnimation} 0.6s linear infinite;
  }
`;

const Container = styled.div`
  width:100%;
  font-family: "proxima-nova", sans-serif;
  padding-bottom: 16px;
`;

const HeroHeaderContent = styled.div`
  margin-bottom: 16px;
  font-size: 44px;
  line-height: 44px;
  font-weight: 500 !important;
`;

const HeroFooterContent = styled.div`
  padding-top: 10px;
  font-size: 16px;
  line-height: 20px;
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-template-rows: repeat(2, auto);
`;

const Text = styled.div`
  font-weight: ${ props => props.mainText ? '500 !important' : '200 !important' };
  grid-row: ${ props => props.gridRow };
  margin-right: 32px;
`;

const TextSpan = styled.span`
  color: #8B8B8B;
  font-weight: 200 !important;
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  align-self: center;
  user-select: none;
`;

const HeroImageWrapper = styled.div`
  width: auto;
  height: 100%;
  position: relative;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  pointer-events: none;
  height: 100%;
  background: #000;
  z-index: 9;
  top: 0;
  left: 0;
  opacity: ${ props => props.bOpacity ? '0.5' : '0' };
  transition: 0.5s;
`;

const HeroContentContainer = styled.div`
  position: fixed;
  background: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const HeroContentWrapper = styled.div`
  height: auto;
  max-height: 95%;
  position: relative;
  width: fit-content;
  max-width: 95%;
  background: #fff;
  display:flex;
  flex-direction: column;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14);
  padding: 60px;
`;

  const HeroImage = React.forwardRef(
    ({ 
      bShowHeroImages,
      currIndex, 
      footerData, 
      handleCurrentHeroImage, 
      handleHeroImageDisplay, 
      handleKeyDown,
      render,  
    }, ref) => {
  
    const getFooterContent = () => {
      const currRender = render[currIndex];
      let footerObject = footerData;
  
      Object.keys(footerObject).map((key) => {
        return footerObject = Object.assign(footerObject, { [key] : { ...footerObject[key], data: currRender[key] || 'N/A' } })
      })
      return footerObject;
    }
  
    return (
      <Container>
      {
        bShowHeroImages && 
        <React.Fragment>
          <HeroContentContainer >
            <HeroContentWrapper 
            ref={ ref}
            onKeyDown = {(e) => handleKeyDown(e) }
            tabIndex = '0'
          >
              <CloseIconContainer onClick = { handleHeroImageDisplay } ><CloseIcon /></CloseIconContainer>
              <HeroHeaderContent>Design { render[currIndex].variation_number }<TextSpan> Render { `${ currIndex + 1 } of ${ render.length }`  }</TextSpan></HeroHeaderContent>
              <HeroImageWrapper>
                <CarotContainer >
                  <CarotWrapper onClick = {() => { handleCurrentHeroImage(false, true, null) }} >
                    <LeftCarot />
                  </CarotWrapper>
                  <CarotWrapper onClick = {() => { handleCurrentHeroImage(true, false, null) }} >
                    <RightCarot />
                  </CarotWrapper>
                </CarotContainer>
                <Image src = { render[currIndex] && render[currIndex].imagefile } />
              </HeroImageWrapper>
              <HeroFooterContent>
              { footerData &&
              Object.keys(getFooterContent()).map((key, index) => {
                let content = getFooterContent()[key];
                return (
                  <React.Fragment key = { index }>
                  <Text mainText gridRow = { 1 } >{ content.label }</Text>
                  <Text gridRow = { 2 } >{ content.data }</Text>
                </React.Fragment>
                )
              })
              }
            </HeroFooterContent>
            </HeroContentWrapper>
          </HeroContentContainer>
        </React.Fragment>
      }
      <Overlay bOpacity = { bShowHeroImages } />
      </Container>
    )
  }
  )

export default  HeroImage;