import React from 'react';
import isEmpty from "lodash/isEmpty";
import { Row, Col, Alert } from 'react-bootstrap';

import ImageListContainer from '../containers/ImageListContainer';
import { IMAGE_LIST_TYPES } from '../constants';

const CustomProps = ({
  customProps = [],
  props = {},
  spaceID = null
} = {}) => {
  if (isEmpty(props)) {
    return null;
  }

  const { count } = props
  const hasNoProps = count === 0;

  return (
    <div className="custom-props">
      <Row>
        <Col md={12}>
          <ImageListContainer
            images={customProps}
            imageListType={IMAGE_LIST_TYPES.CUSTOM_PROPS}
            isFromCustomProps
            noArrowNavigation
            totalNumberOfProps={count}
            spaceID={spaceID}
          />
        </Col>
      </Row>

      {hasNoProps &&
        <Alert bsStyle="warning">
          <strong>Hmmm...</strong> Seems like the user does not have any custom
          props. If the space is not recent, please check the warehouse to
          confirm.
        </Alert>
      }
    </div>
  );
};

export default CustomProps;
