import React from 'react';
import styled from 'styled-components';

const Header = styled.h3`
`;
Header.displayName = 'Header';

const SpaceHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HistoryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height:30px;
  margin: 8px 0;
  background: #fafafa;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 8px;
`;

const HistoryItem = styled.div`
  display: inline-block;
  color: #000;
  line-height: 32px;
  color: ${props => props.color};
  word-break: break-word;
`;
HistoryItem.displayName = 'HistoryItem';

const ContentSpan = styled.span`
  color: #000;
  font-weight: 600 !important;
  padding-right: 4px;
`;

const HEADER_STR = 'History';

const getHistoryData = (historyData) => {
  return historyData && historyData.map((value, index) => 
    <HistoryItemContainer key={'history-item-container-' + index}>
    {
      Object.keys(value).map(key => {
        const item = value[key];
          return (
            <HistoryItem
              key={item.label}
              color={key === 'email' ? '#337ab7' : undefined}
            >
              <ContentSpan>
                {item.label}
              </ContentSpan>

              {item.value}
            </HistoryItem> 
          )  
      })
    }
    </HistoryItemContainer>
  )
}

export default function SpaceHistory ({ historyData }) {
  return (
    <SpaceHistoryContainer>
      <Header>{ HEADER_STR }</Header>
      { getHistoryData(historyData) }
    </SpaceHistoryContainer>
  );
}
