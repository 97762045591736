import React from 'react';
import styled from 'styled-components';

export default function Checkbox(props) {
  const { 
    checked = false,
    className = '',
    label = 'default label',
    onChange = () => {},
    value = '',
  } = props;
  return (
    <CheckBoxContainer>
      <CheckBox 
        id={value + '-id'}
        className={className ? className : 'check-box'}
        type='checkbox'
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <CheckBoxLabel htmlFor={value + '-id'}>{label}</CheckBoxLabel>
    </CheckBoxContainer>
  )
}

const CheckBoxContainer = styled.div`
  display: flex;
  flex-wrap:nowrap;
  flex-direction: row;
`;

const CheckBox = styled.input`
  cursor: pointer;
  display: inline-block;
`;

const CheckBoxLabel = styled.label`
  cursor: pointer;
  font-weight: 400;
  margin-left: 8px;
`;

CheckBox.displayName = 'CheckBox';
CheckBoxLabel.displayName = 'CheckBoxLabel';