import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Alert, Button, Col } from 'react-bootstrap';
import axios from 'axios';
import { WAREHOUSE_API_URL } from '../config';
import { CUSTOMERS_GENERAL_STYLE_STR } from '../constants';
import { dataRefresh } from '../actions/api-data-request-generator';
import ContentEditable from 'sharedComponents/ContentEditable';

const Container = styled.div`
  padding-left: 8px;
`;

const TextAreaTitle = styled.div`
  /* the ampersands override any styles from stylesheets from the parent */
  &&& {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
  }
`;

class InternalCustomerNotes extends React.PureComponent {
  state = {
    isAlertVisible: false,
    customerNotes: '',
  };

  componentDidUpdate(prevProps) {
    const { internalUserNotes } = this.props;

    if (
      internalUserNotes &&
      prevProps.internalUserNotes !== internalUserNotes
    ) {
      this.setState({ customerNotes: internalUserNotes });
    }
  }

  handleChange = (event) => {
    this.setState({
      customerNotes: event.target.value,
      isAlertVisible: true,
    });
  };

  handleCustomerInfoNeedsToRefresh(customerNotes) {
    const { dispatch } = this.props;
    dispatch(
      dataRefresh('basic_information', { internal_user_notes: customerNotes })
    );
  }

  handleSaveResults = () => {
    const { customerNotes } = this.state;
    const { userId } = this.props;
    axios
      .post(
        `${WAREHOUSE_API_URL}/api/users/internal-user-notes/${userId}?heidi=true`,
        {
          internal_user_notes: customerNotes,
        }
      )
      .then(() => {
        this.handleCustomerInfoNeedsToRefresh(customerNotes);
      });
    this.setState({ isAlertVisible: false });
  };

  handleDismissAlert = () => {
    const { internalUserNotes } = this.props;
    this.setState({
      isAlertVisible: false,
      customerNotes: internalUserNotes,
    });
  };

  render() {
    return (
      <Container>
        {this.state.isAlertVisible && (
          <Alert bsStyle="warning" onDismiss={this.handleDismissAlert}>
            <h4>Save Customer Notes</h4>
            <p>
              Save Internal Customer Notes - if you don’t save or refresh
              changes will be lost!
            </p>
            <p>
              <Button onClick={this.handleDismissAlert}>Cancel</Button>
              <span> or </span>
              <Button onClick={this.handleSaveResults}>Save</Button>
            </p>
          </Alert>
        )}
        <Col>
          <TextAreaTitle>
            {'Customer Preferences (Internal Notes):'}
          </TextAreaTitle>
          <ContentEditable
            placeholder={CUSTOMERS_GENERAL_STYLE_STR}
            html={this.state.customerNotes}
            onChange={this.handleChange}
          />
        </Col>
      </Container>
    );
  }
}

export default connect()(InternalCustomerNotes);
