// Reusable containers to limit the amount of duplicate code

import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components';

export const BaseGrid = styled.div`
  display: grid;
`;

export const BaseFlex = styled.div`
  display: flex;
`;

export const Container = styled.div`
  font-family: 'proxima-nova', sans-serif;
  height: auto;
  margin-bottom: 24px;
  border-radius: 4px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#FFF'};
  padding: 16px;
  position: relative;
`;

const QuoteSymbolContainer = styled.div`
  font-family: Graphik Web, sans-serif !important;
  font-size: 34px;
  line-height: 37px;
  color: ${(props) => (props.color ? props.color : '#000')};
  font-style: italic;
  transform: skew(30deg, 0deg);
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${(props) => (props.padding ? props.padding : '8px')};
`;

export const QuoteSymbol = ({ ...props }) => {
  return <QuoteSymbolContainer {...props}>"</QuoteSymbolContainer>;
};

export const TextareaAutosizeExtended = styled(TextareaAutosize)`
  width: 100%;
  border-radius: 4px;
  border-color: #ccc;
  padding: 8px;
  resize: none;
  box-sizing: border-box;
  min-height: ${(props) => props.minheight};

  ::placeholder {
    font-style: italic;
  }
  &:focus {
    outline: none !important;
    border-color: #719ece;
    box-shadow: 0 0 5px #719ece;
    color: #4978bc;
  }
`;
