/**
 * Event fired when user clicks on the panel link to show corresponding data
 * for the renovation choice.
 */
export function showRenovationChoices () {
  return {
    type: 'show-renovation-choices',
    payload: { show: true }
  };
}

/**
 * Event fired when user clicks on the panel link to show corresponding data
 * for the renovation question.
 */
export function showRenovationQuestion () {
  return {
    type: 'show-renovation-question',
    payload: { show: true }
  };
}

/**
 * Event fired when user clicks on the panel link to show corresponding data
 * for the shape choice.
 */
export function showShapeChoice () {
  return {
    type: 'show-shape-choice',
    payload: { show: true }
  }
}