import * as types from './action-types';

// sets the space tab key
export const setSpaceTabKey = key => ({
  type: types.SET_ACTIVE_SPACE_TAB_KEY,
  key,
});

// sets the space tab ID
export const setSpaceTabID = id => ({
  type: types.SET_ACTIVE_SPACE_TAB_ID,
  id,
});

export const setFilteredSpaces = spaces => ({
  type: types.SET_FILTERED_SPACES,
  spaces,
});

export const setSpaceStatus = status => ({
  type: types.SET_CURRENT_SPACE_STATUS,
  status,
});


