import React from 'react';
import {
  Col,
  FormControl,
  InputGroup,
  ControlLabel,
  Form,
  FormGroup,
} from 'react-bootstrap';
import CustomCheckbox from './CustomCheckbox';
import upperFirst from 'lodash/upperFirst';
import { CUSTOM_PROP_TYPE } from "../constants";

const CustomProductForm = ({
  zoomImage,
  sources,
  spaceCustomPropProductTypes,
  editField,
  fields,
  shouldShowAlternativesRequestDropdown,
  showProductSearchModal,
  toggleProductSearchModal,
  customProps
}) => {
  // assign for fields with potential falsy values
  let description;
  let price;
  if (fields.description !== undefined) {
    description = fields.description;
  } else {
    description = zoomImage.description || '';
  }
  if (fields.price !== undefined) {
    price = fields.price;
  } else {
    price = zoomImage.price ? parseInt(zoomImage.price, 10).toFixed(2) : '';
  }

  let sku;
  if (fields.external_id !== undefined) {
    sku = fields.external_id;
  } else {
    sku = zoomImage.external_id;
  }

  let alternativeRequest;
  if (fields.alternative_request !== undefined) {
    alternativeRequest = fields.alternative_request;
  } else {
    alternativeRequest = zoomImage.alternative_request || null;
  }

  const alternativeRequestCustomProps = customProps.filter((customProp) => {
    // TODO: Remove UNKNOWN custom props from options. We should only pick
    // from 'Find Alternative Request' custom props. But some 'Find Alternative
    // Request' custom props will have 'Unknown' type during the transition.
    return (
      customProp.type === CUSTOM_PROP_TYPE.FIND_ALTERNATIVE_REQUEST ||
      customProp.type === CUSTOM_PROP_TYPE.UNKNOWN
    );
  });

  return (
    <Form className="custom-product-form" horizontal>
      <FormGroup controlId="formDescriptionTextArea">
        <Col componentClass={ControlLabel} sm={3}>
          Description
        </Col>
        <Col sm={9}>
          <FormControl
            className="limit-width"
            value={description || ''}
            componentClass="textarea"
            placeholder="No description given."
            onChange={e => editField('description', e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="formSource">
        <Col componentClass={ControlLabel} sm={3}>
          Source
        </Col>
        <Col sm={9}>
          <FormControl
            value={
              fields.source_id ||
              (zoomImage.source ? zoomImage.source.id : 'N/A')
            }
            className="limit-height"
            componentClass="select"
            onChange={e => editField('source_id', e.target.value)}
          >
            <option value="null">Select</option>
            {sources.map(source => (
              <option key={source.id} value={source.id}>
                {upperFirst(source.name)}
              </option>
            ))}
          </FormControl>
        </Col>
      </FormGroup>
      {
        shouldShowAlternativesRequestDropdown() &&
        <FormGroup controlId="formAlternativeRequest">
        <Col componentClass={ControlLabel} sm={3}>
          Alternative Request
        </Col>
        <Col sm={9}>
          <FormControl
            value={alternativeRequest}
            className="limit-height"
            componentClass="select"
            onChange={e => editField('alternative_request', e.target.value)}
          >
            <option value="null">Select</option>
            {alternativeRequestCustomProps.map(alternativeRequestCustomProp => (
              <option key={alternativeRequestCustomProp.id} value={alternativeRequestCustomProp.id}>
                {upperFirst(alternativeRequestCustomProp.name)}
              </option>
            ))}
          </FormControl>
        </Col>
      </FormGroup>
      }
      <FormGroup controlId="formPrice">
        <Col componentClass={ControlLabel} sm={3}>
          Price
        </Col>
        <Col sm={3}>
          <FormControl
            type="text"
            className="limit-height"
            value={price}
            onChange={e => editField('price', e.target.value)}
          />
        </Col>
        <Col componentClass={ControlLabel} sm={3}>
          Out of Stock
        </Col>
        <Col sm={3}>
          <InputGroup className="checkbox-container">
            <CustomCheckbox
              handleClick={() =>
                editField(
                  'out_of_stock',
                  fields.out_of_stock !== undefined
                    ? !fields.out_of_stock
                    : !zoomImage.out_of_stock,
                )}
              checked={
                fields.out_of_stock !== undefined ? (
                  fields.out_of_stock
                ) : (
                  zoomImage.out_of_stock
                )
              }
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup controlId="formSource">
        <Col componentClass={ControlLabel} sm={3}>
          SKU
        </Col>
        <Col sm={9}>
          <FormControl
            type="text"
            className="limit-height"
            value={sku}
            onChange={e => editField('external_id', e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup controlId="formProductType">
        <Col componentClass={ControlLabel} sm={3}>
          Product Type
        </Col>
        <Col sm={9}>
          <FormControl
            value={
              fields.product_type_id ||
              (zoomImage.product_type ? zoomImage.product_type.id : 'N/A')
            }
            className="limit-height"
            componentClass="select"
            onChange={e => editField('product_type_id', e.target.value)}
          >
            <option value="null">Select</option>
            {spaceCustomPropProductTypes.map(pt => (
              <option key={pt.id} value={pt.id}>
                {pt.product_type}
              </option>
            ))}
          </FormControl>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default CustomProductForm;
