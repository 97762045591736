import React from 'react';
import { Modal, Button, FormControl, Col } from 'react-bootstrap';

import LoadingIcon from '../LoadingIcon';
import Utils from '../../utils';

export const OrderProcessingRefundModal = ({
  show,
  cancelRefund = () => {},
  processRefund = () => {},
  refundReason = () => {},
  availableAmount,
  availableShipping,
  availableTax,
  availableTieredDiscount,
  customAmount,
  handleModalProps = () => {},
  inProgress = false,
  itemId = null,
}) => {
  let header = `How much would you like to refund of the available ${Utils.formatMoney(
    availableAmount,
    2,
  )}?`;

  let description = (
    <Col md={12}>
      There is{' '}

      <strong>
        {Utils.formatMoney(availableAmount - availableShipping, 2, true)}
      </strong>{' '}

      available for the item (including {' '}
      <strong>{Utils.formatMoney(availableTax, 2, true)}</strong> tax).<br />
      Additionally, there is <strong>{Utils.formatMoney(availableShipping, 2, true)}</strong> available for the shipping

      <br />
      <br />

      There is <strong>{Utils.formatMoney(availableTieredDiscount, 2, true)}</strong> Insider Rewards attributed to this item.<br />

      <br />
    </Col>
  );

  if (!itemId) {
    header = 'Would you like to fully refund this order?';
    description = null;
  }

  return (
    <Modal show={show} onHide={cancelRefund} dialogClassName="custom-modal">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-lg">{header}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {description}

        {itemId ? (
          <Col md={3}>
            <FormControl
              onChange={e =>
                handleModalProps('refund', { customAmount: e.target.value })
              }
              name="customAmount"
              value={customAmount || ''}
              placeholder="amount to refund"
            />
          </Col>
        ) : null}

        <Col md={9}>
          <FormControl
            onChange={e =>
              handleModalProps('refund', { refundReason: e.target.value })
            }
            name="refundReason"
            value={refundReason || ''}
            placeholder="optional description here"
          />
        </Col>

        <br style={{ clear: 'both' }} />
      </Modal.Body>
      <Modal.Footer>
        {customAmount > availableAmount ? (
          <div
            className="alert alert-warning"
            style={{
              width: '337px',
              padding: '7px',
              float: 'left',
              textAlign: 'left',
              marginBottom: '0px',
            }}
          >
            The maximum refundable amount is{' '}
            {Utils.formatMoney(availableAmount, 2)}
          </div>
        ) : null}
        {inProgress ? <LoadingIcon size="leftOfButton" /> : null}

        <Button className="btn btn-danger" onClick={cancelRefund}>
          Cancel Refund
        </Button>

        <Button
          className="btn btn-success"
          onClick={processRefund}
          disabled={customAmount > availableAmount}
        >
          Do Refund
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const OrderProcessingConfirmProceedModal = ({
  show,
  close = () => {},
  actionToProceed = () => {},
  message = '',
}) => {
  return (
    <Modal show={show} dialogClassName="custom-modal">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-lg">{message}</Modal.Title>
      </Modal.Header>

      <Modal.Footer>
        <Button className="btn btn-danger" onClick={close}>
          Cancel
        </Button>

        <Button className="btn btn-success" onClick={actionToProceed}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const OrderProcessingCreateOrderModal = ({
  show,
  close = () => {},
  actionToProceed = () => {},
  handleModalProps = () => {},

  user_id = '',
  first_name = '',
  last_name = '',
  street = '',
  city = '',
  state = '',
  postal = '',
  country = '',
  phone = '',
}) => {
  return (
    <Modal show={show} dialogClassName="custom-modal">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-lg">
          Do you want to create a Manual Order?
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        This will create an "empty" Order. It will <strong>not</strong> include
        any items, or address information. It is up to you to add that
        information immediately after. Please only do this if you intend on
        fully creating this order. There is no undo.
        <br />
        <br />

        Please enter the user's id:

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { user_id: e.target.value })
          }
          name="user_id"
          value={user_id}
          placeholder="required User Id here"
        />

        <br />

        Provide customer's address:

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { first_name: e.target.value })
          }
          name="first_name"
          value={first_name}
          placeholder="First Name"
        />

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { last_name: e.target.value })
          }
          name="last_name"
          value={last_name}
          placeholder="Last Name"
        />

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { street: e.target.value })
          }
          name="street"
          value={street}
          placeholder="Street Address"
        />

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { city: e.target.value })
          }
          name="city"
          value={city}
          placeholder="City"
        />

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { state: e.target.value })
          }
          name="state"
          value={state}
          placeholder="State"
        />

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { postal: e.target.value })
          }
          name="postal"
          value={postal}
          placeholder="Postal Code"
        />

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { country: e.target.value })
          }
          name="country"
          value={country}
          placeholder="Country"
        />

        <FormControl
          onChange={e =>
            handleModalProps('createOrder', { phone: e.target.value })
          }
          name="phone"
          value={phone}
          placeholder="Phone"
        />
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn btn-danger" onClick={close}>
          Cancel
        </Button>

        <Button
          className="btn btn-success"
          disabled={!user_id}
          onClick={actionToProceed}
        >
          Create Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
