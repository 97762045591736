import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import PatchButtonContainer from '../containers/PatchButtonContainer';
import LoadingIcon from './LoadingIcon';

const PatchButton = ({
  buttonStyle,
  handleClick,
  children,
  loading,
  error,
  response,
  disabled,
}) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      className="heidi"
      overlay={
        <Popover
          id="error-popover"
          style={{
            display:
              error || (response && response[0].message) ? 'block' : 'none',
          }}
          className="heidi"
        >
          <span className={error ? 'rejected' : 'approved'}>
            {(response ? response[0].message : null) ||
              (error
                ? error.message
                : 'There was an error processing the request.')}
          </span>
        </Popover>
      }
    >
      <Button onClick={handleClick} className={buttonStyle} disabled={disabled}>
        {loading ? <LoadingIcon size="small" /> : children}
      </Button>
    </OverlayTrigger>
  );
};

export default PatchButtonContainer(PatchButton);
