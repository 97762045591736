import React, { Fragment } from 'react';
import map from 'lodash/map';
import numeral from 'numeral';
import { Grid, Col, Row, Checkbox } from 'react-bootstrap';
import { ProductModel } from '../models';
import { OldAdminConfig } from '../config';

export class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.product = new ProductModel();
  }
  UNSAFE_componentWillMount(propsListProps) {
    this.setState({ selected: {} });
  }

  selectionChanged(product, event) {
    const newSelected = this.state.selected;
    if (product.slug in newSelected) {
      delete newSelected[product.slug];
    }
    if (event.target.checked) {
      newSelected[product.slug] = product;
    }
    this.setState({ selected: newSelected });
    const selectedArray = map(newSelected, (x) => {
      return x;
    });
    this.props.selectionChangedCallback(selectedArray);
  }

  // TODO: <LinkContainer key={prod.slug} to={'/admin/warehouse/product/'+prod.id}>
  render() {
    const selectionBox = (product) => {
      return this.props.selectionChangedCallback == null ? (
        <div />
      ) : (
        <Checkbox
          type="checkbox"
          checked={product.slug in this.state.selected}
          onChange={this.selectionChanged.bind(this, product)}
        />
      );
    };

    return (
      <div>
        <Grid>
          <Row className='text-center' style={{margin: '30px 0'}}>
            <Col md={4}>
              <b>Name</b>
            </Col>
            <Col md={2}>
              <b>Image</b>
            </Col>
            <Col md={2}>
              <b>Source</b>
            </Col>
            <Col md={2}>
              <b>Price</b>
            </Col>
            <Col md={2}>
              <b>External id</b>
            </Col>
          </Row>
          <hr/>

          {this.props.productlist.map((prod, i) => {
            const href = `${OldAdminConfig.PRODUCT}/${prod.id}`;
            return (
              <Fragment>
                <Row key={i} className='text-center' style={{ display: 'flex', alignItems: 'center' }}>
                  <Col md={4}>
                    <Col md={2}>{selectionBox(prod)}</Col>
                    <Col md={10} style={{ paddingTop: '10px' }}>
                      <a href={href}>{prod.name}</a>
                    </Col>
                  </Col>
                  <Col md={2}>
                    <a href={href}>
                      <img
                        style={{ height: '75px', maxWidth: '150px' }}
                        src={this.product.getPrimaryImage(prod)}
                        alt="primary"
                      />
                    </a>
                  </Col>
                  <Col md={2}>
                    <a href={prod.url}>
                      {prod.source}
                    </a>
                  </Col>
                  <Col md={2}>
                    <a href={href}>
                      {numeral(prod.price).format('$0,0')}
                    </a>
                  </Col>
                  <Col md={2}>
                    <a href={prod.url}>
                      {prod.external_id}
                    </a>
                  </Col>
                </Row>
                <hr/>
              </Fragment>
            );
          })}
        </Grid>
      </div>
    );
  }
}
