import * as types from '../actions/action-types';

// state for first initialization
const initialData = {
  isFetching: false,
  data: null,
};

const apiPatchReducerGenerator = identifier => (
  state = initialData,
  action,
) => {
  // format identifier for types
  const typeIdentifier = identifier.toUpperCase();

  switch (action.type) {
    case types[`${typeIdentifier}_REQUEST`]:
      return Object.assign({}, state, {
        isFetching: true,
        error: null,
      });
    case types[`${typeIdentifier}_SUCCESS`]:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        lastUpdated: action.recievedAt,
        error: null,
      });
    case types[`${typeIdentifier}_FAILURE`]:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export default apiPatchReducerGenerator;
