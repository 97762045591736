import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import SpaceHistory from './SpaceHistory';
import * as request from '../../global/request';
import { ApiConfig } from '../../config';
import Utils from '../../utils';

export const SpaceHistoryController = ({
  activeTab,
  activeSpaceID, 
  }) => {

  const SPACES_HISTORY = 'spaces_history';
  const [isFetching, setIsFetching]  = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [nextPage, setNextPage] = useState(null);

  useEffect(() => {
    const loadHistoryData = () => {
      return request.get(`${ ApiConfig.GET_ACTIVITY_LOG(activeSpaceID) }`)
        .then(response => {   
          setHistoryData(formatHistoryData(response));
          setNextPage(response.data.next);
        });
    };

    loadHistoryData();
  }, [activeSpaceID]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.pageYOffset >= document.body.scrollHeight && activeTab === SPACES_HISTORY) {
        setIsFetching(true);
      }
    }
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeTab]);

  useEffect(() => {
    if(!isFetching) {
      return;
    };

    const loadNextPage = () => {
      if (nextPage === null) {
        return;
      };
      return request.get(nextPage)
        .then(response => {
          setHistoryData(prevState => [...prevState, ...formatHistoryData(response)]);
          setNextPage(response.data.next);
          setIsFetching(false);
          return;
        });
    }

    loadNextPage();
  }, [isFetching, nextPage])

  const formatHistoryData = (response) => {
    let formattedHistoryData = [];
      response.data.results.forEach(value => {
        formattedHistoryData.push({
          date_updated: { label: 'Date Updated:', value: Utils.getFormattedDateTime(value.updated_at) },
          email: { label: 'Email:', value:  value.user.email, },
          description: { label: 'Description:', value: value.description.replace(/_/g, ' ') },
        });
    });
    return formattedHistoryData;
  }

  return (
    <SpaceHistory
    historyData = { historyData }
    />
  )
}

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data && state.space_data.activeID;


  return {
    activeSpaceID,
  };
};

export default connect(mapStateToProps)(SpaceHistoryController);

