import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import RedesignRequest from '../components/RedesignRequestComponents/RedesignRequest';
import { ApiConfig } from '../config';
import {
  dataRefresh,
  fetchDataIfNeeded,
} from '../actions/api-data-request-generator';
import { bugsnagError } from '../services/bugsnag';
import {
  REDESIGN_PRIORITY_OPTIONS,
  REDESIGN_REQUEST_DROPDOWNS,
  REDESIGN_REQUEST_OPTIONS,
  SPACE_TYPES,
} from '../constants';
import { RequestCreatedModal } from '../components/RedesignRequestComponents/RedesignRequestModals';
import { setRedesignModalRequestDisplay } from '../actions/redesign-request-modal-action';
import * as request from '../global/request';
import { WAREHOUSE_API_URL } from '../config';
import {
  trackCreateRedesignRequest,
  trackCreateRedesignRequestFailToSaveToWarehouse,
} from '../services/segment';
class RedesignRequestContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.currentSpaceID = 0;
  }
  state = {
    currentSpaceData: {
      designIds: [],
      helpNote: '',
      email: '',
      firstName: '',
      lastName: '',
      packageType: '',
      roomType: '',
      spaceNumber: 0,
      spaceList: {},
      dropdowns: {},
      redesignRequestOptions: REDESIGN_REQUEST_OPTIONS,
    },
    warning: false,
    error: false,
    spaceAssistanceRequestCreated: false,
    spaceIds: null,
    showModal: false,
    redesignId: '',
  };

  componentDidMount() {
    const { activeSpaceID } = this.props;
    // Load initial data
    this.fetchSelectedSpaceData(activeSpaceID);
  }

  componentDidUpdate(prevProps, prevState) {
    const { bShowRedesignRequestModal } = this.props;

    if (bShowRedesignRequestModal !== prevProps.bShowRedesignRequestModal) {
      this.initialState();
    }
  }

  getCurrentSpaceDesignOptions = (space_id) => {
    const { spaceData } = this.props;
    let designIds = [];
    if (spaceData && spaceData[space_id])
      for (let key in spaceData[space_id].designs.data.results) {
        let design = spaceData[space_id].designs.data.results[key];
        designIds.push({
          value: design.design_id,
          label: `Design ${design.design_id} ${design.comment}`,
        });
      }
    let reversedIds = designIds.reverse();

    return reversedIds;
  };

  getAssistanceRequestDesignOption = (space_id) => {
    const { spaceAssistanceData } = this.props;

    let designID = 0;

    if (spaceAssistanceData.length > 0) {
      for (let key in spaceAssistanceData) {
        let data = spaceAssistanceData[key];

        if (data.addressed === 0) {
          designID = data.design_id;
          break;
        }
      }
    }
    const spaceDesignOptions = this.getCurrentSpaceDesignOptions(space_id);

    for (let key in spaceDesignOptions) {
      const option = spaceDesignOptions[key];
      if (option.value === Number(designID)) {
        return option;
      }
    }
  };

  initialState = () => {
    const {
      currentDesignStyleNote,
      data,
      isRequestEditable,
      selectedReasons,
      spaceListData,
    } = this.props;
    let space_id = this.currentSpaceID;

    let currentSpaceData = {};
    const initialDesignOption =
      this.getCurrentSpaceDesignOptions(space_id) &&
      this.getCurrentSpaceDesignOptions(space_id)[0];
    const editedDesignOption = this.getAssistanceRequestDesignOption(space_id);
    const currentDesignOption = isRequestEditable
      ? editedDesignOption
      : initialDesignOption;

    currentSpaceData = Object.assign(
      {},
      {
        designIds: this.getCurrentSpaceDesignOptions(space_id),
        dropdowns: Object.assign(REDESIGN_REQUEST_DROPDOWNS, {
          design_number: {
            ...REDESIGN_REQUEST_DROPDOWNS.design_number,
            options: this.getCurrentSpaceDesignOptions(space_id),
            currentOption: currentDesignOption,
          },
          redesign_priority: {
            ...REDESIGN_REQUEST_DROPDOWNS.redesign_priority,
            options: REDESIGN_PRIORITY_OPTIONS,
            currentOption: REDESIGN_PRIORITY_OPTIONS[0],
          },
        }),
        email: data.email,
        firstName: data.first_name,
        helpNote: isRequestEditable ? currentDesignStyleNote : '',
        lastName: data.last_name,
        roomType: this.getRoomType(space_id),
        packageType:
          spaceListData.package_types[0] &&
          spaceListData.package_types[0].replace(/_/g, ' '),
        spaceNumber: space_id,
        spaceList: this.getSpaceListData(),
        redesignRequestOptions: isRequestEditable
          ? selectedReasons
          : REDESIGN_REQUEST_OPTIONS,
      }
    );
    this.setState({ currentSpaceData });
  };

  getIsRedesignRequestPending = () => {
    const { spaceAssistanceData } = this.props;
    return spaceAssistanceData.some((value) => value.addressed === 0);
  };

  onSelectChange = (event, key) => {
    let currentSpaceData = cloneDeep(this.state.currentSpaceData);
    currentSpaceData.dropdowns[key].currentOption = event;

    this.setState({ currentSpaceData });
  };

  // Will fetch the data for a specific space
  fetchSelectedSpaceData = (space_id) => {
    if (!space_id) {
      return;
    }

    // set a global space id
    this.currentSpaceID = space_id;

    const { dispatch } = this.props;
    const designsCache = ['space_data', space_id, 'designs'];

    dispatch(
      fetchDataIfNeeded(
        // load designs information
        `${ApiConfig.DESIGNS}/${space_id}/designs`,
        'designs',
        { keys: { space: space_id }, cache: designsCache }
      )
    )
      .then(() => {
        this.initialState();
      })
      .catch((error) => bugsnagError(error));
  };

  getRoomType = (space_id) => {
    return this.props.filteredSpaces
      .filter((value) => value.id === space_id)
      .map((value) => SPACE_TYPES[value.space_type])[0];
  };

  getSpaceListData = () => {
    const { filteredSpaces } = this.props;
    let spaceListData = [];
    filteredSpaces
      .filter((value) => value.status === 'ready')
      .map((value) => {
        return spaceListData.push({
          value: value.id,
          label: `R${value.id} - ${SPACE_TYPES[value.space_type]}`,
        });
      });
    return spaceListData;
  };

  handleRedesignReasonsDisplay = (
    key,
    isSelected = false,
    isHovered = false
  ) => {
    let currentSpaceData = { ...this.state.currentSpaceData };

    currentSpaceData = Object.assign(
      { currentSpaceData },
      {
        ...currentSpaceData,
        redesignRequestOptions: {
          ...currentSpaceData.redesignRequestOptions,
          [key]: {
            ...currentSpaceData.redesignRequestOptions[key],
            selected: isSelected
              ? !currentSpaceData.redesignRequestOptions[key].selected
              : currentSpaceData.redesignRequestOptions[key].selected,
            isHovered: isHovered ? true : false,
          },
        },
      }
    );
    this.setState({ currentSpaceData });
  };

  handleNoteChange = (event) => {
    const currentSpaceData = { ...this.state.currentSpaceData };
    const currentState = currentSpaceData;
    const { name, value } = event.target;
    currentState[name] = value;
    this.setState({ currentSpaceData: currentState });
  };

  handleCloseIsStanzaTicketCreated = () => {
    this.setState({ spaceAssistanceRequestCreated: false });
  };

  handleIsAReasonSelected = () => {
    const { redesignRequestOptions } = this.state.currentSpaceData;
    const isAReasonSelected = Object.keys(redesignRequestOptions).some(
      (key) => redesignRequestOptions[key].selected
    );
    return isAReasonSelected;
  };

  extractRedesignId = (url) => {
    if (!url) {
      return '';
    }
    const result = url.substring(url.lastIndexOf('/') + 1);
    return result;
  };

  sendAssistanceRequest = () => {
    const { currentSpaceData } = this.state;
    const { assistanceID, dispatch, isRequestEditable } = this.props;
    const designId = get(
      currentSpaceData,
      'dropdowns.design_number.currentOption.value',
      null
    );
    let assistanceRequest = {
      designId,
      help: currentSpaceData.redesignRequestOptions.reason_help.selected,
      helpNote: currentSpaceData.helpNote,
      incorrect:
        currentSpaceData.redesignRequestOptions.reason_incorrect.selected,
      looking: currentSpaceData.redesignRequestOptions.reason_looking.selected,
      other: currentSpaceData.redesignRequestOptions.reason_other.selected,
      swap: currentSpaceData.redesignRequestOptions.reason_swap.selected,
      redesignPriority:
        currentSpaceData.dropdowns.redesign_priority.currentOption.value,
    };
    let editAssistanceRequest = {
      reason_help: currentSpaceData.redesignRequestOptions.reason_help.selected,
      reason_incorrect:
        currentSpaceData.redesignRequestOptions.reason_incorrect.selected,
      reason_looking:
        currentSpaceData.redesignRequestOptions.reason_looking.selected,
      reason_other:
        currentSpaceData.redesignRequestOptions.reason_other.selected,
      reason_swap: currentSpaceData.redesignRequestOptions.reason_swap.selected,
      reason_request: true,
      comment: currentSpaceData.helpNote,
    };

    if (currentSpaceData.helpNote === '' || !this.handleIsAReasonSelected()) {
      this.setState({ warning: true });
      return;
    }

    if (!isRequestEditable && this.getIsRedesignRequestPending()) {
      return;
    }

    const createRedesignData = {
      design_id: currentSpaceData.dropdowns.design_number.currentOption.value,
      redesign_priority:
        currentSpaceData.dropdowns.redesign_priority.currentOption.value,
      user_email: currentSpaceData.email,
      space_id: currentSpaceData.spaceNumber,
    };

    this.setState({ showModal: true });

    const url = `${WAREHOUSE_API_URL}/warehouse/api/spaces/${this.currentSpaceID}/send_assistance`;
    const editRequestUrl = `${WAREHOUSE_API_URL}/warehouse/api/v1/space_assistance/${assistanceID}`;
    trackCreateRedesignRequest(createRedesignData);

    if (isRequestEditable) {
      return request
        .patch(editRequestUrl, {
          ...editAssistanceRequest,
        })
        .then((response) => {
          this.setState({
            spaceAssistanceRequestCreated: true,
            showModal: false,
            redesignId: response.data.id || '',
            error: false,
          });
          this.initialState();
          dispatch(setRedesignModalRequestDisplay());
          return response.data;
        });
    }

    return request
      .post(url, {
        ...assistanceRequest,
      })
      .then((response) => {
        this.setState({
          spaceAssistanceRequestCreated: true,
          showModal: false,
          error: false,
          redesignId: response.data.id,
        });
        this.initialState();
        dispatch(setRedesignModalRequestDisplay());
        dispatch(
          dataRefresh('space_assistance', {
            space: this.state.currentSpaceData.spaceNumber,
          })
        );
        return response.data;
      })
      .catch((error) => {
        this.setState({
          spaceAssistanceRequestCreated: false,
          showModal: false,
          error: true,
        });
        trackCreateRedesignRequestFailToSaveToWarehouse(createRedesignData);
      });
  };

  render() {
    const {
      bShowRedesignRequestModal,
      currentDesignStyleNote,
      dispatch,
      isRequestEditable,
    } = this.props;

    const {
      currentSpaceData,
      error,
      spaceAssistanceRequestCreated,
      showModal,
      warning,
    } = this.state;
    return (
      <React.Fragment>
        <RedesignRequest
          bShowRedesignRequestModal={bShowRedesignRequestModal}
          currentSpaceData={currentSpaceData}
          currentDesignStyleNote={currentDesignStyleNote}
          dispatch={dispatch}
          error={error}
          fetchSelectedSpaceData={this.fetchSelectedSpaceData}
          getIsRedesignRequestPending={this.getIsRedesignRequestPending}
          handleIsAReasonSelected={this.handleIsAReasonSelected}
          handleRedesignReasonsDisplay={this.handleRedesignReasonsDisplay}
          isRequestEditable={isRequestEditable}
          onSelectChange={this.onSelectChange}
          sendAssistanceRequest={this.sendAssistanceRequest}
          handleNoteChange={this.handleNoteChange}
          showModal={showModal}
          warning={warning}
        />
        {spaceAssistanceRequestCreated && (
          <RequestCreatedModal
            handleCloseIsStanzaTicketCreated={
              this.handleCloseIsStanzaTicketCreated
            }
            headerText={'Revision request created'}
            bodyText={'Workflow ticket created.'}
            isRequestEditable={isRequestEditable}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  const assistanceID = state.redesign_request_modal.assistanceID;
  const bShowRedesignRequestModal =
    state.redesign_request_modal.bShowRedesignRequestModal;
  const currentDesignNum = state.redesign_request_modal.currentDesignNum;
  const filteredSpaces = state.space_data.filteredSpaces;
  const isRequestEditable = state.redesign_request_modal.isRequestEditable;
  const currentDesignStyleNote = state.redesign_request_modal.styleNote;
  const selectedReasons = state.redesign_request_modal.reasons;
  const spaceAssistanceData =
    state.space_data[activeSpaceID].space_assistance.data.results || [];
  const { data: spaceListData } = state.user_data.space_list;

  const { isFetching, data, error } = state.user_data.basic_information;

  const spaceData = state.space_data;

  return {
    activeSpaceID,
    assistanceID,
    bShowRedesignRequestModal,
    currentDesignStyleNote,
    currentDesignNum,
    data,
    error,
    filteredSpaces,
    isRequestEditable,
    isFetching,
    selectedReasons,
    spaceAssistanceData,
    spaceData,
    spaceListData: get(spaceListData, 'results').find(
      (space) => space.id === activeSpaceID
    ),
  };
};

export default connect(mapStateToProps)(RedesignRequestContainer);
