import React from 'react';
import styled from 'styled-components';
import Utils from '../../../../utils';
import { ZERO_DOLLAR_STR } from '../../../../constants';
export default function NewBudget ({
  budgetMin,
  budgetMax
} = {}) {
 
 const formatBudget = (budgetAmt = 0) => {
    if (budgetAmt === 0) {  
      return ZERO_DOLLAR_STR
 }
   return Utils.formatMoney(budgetAmt, 0);
 }

 const formattedBudgetMin = formatBudget(budgetMin);
 const formattedBudgetMax = formatBudget(budgetMax);

  return (
    <NewBudgetWrapper>
      <Header>Aggregate budget for intended purchases:</Header>
      <Content>{ `${formattedBudgetMin}-${formattedBudgetMax}` }</Content>
    </NewBudgetWrapper>
  );
}

const Header = styled.h6`
  font-size: 14px;
  font-weight: 700;
  margin: 0 10px 0 0;
`;
Header.displayName = 'Header';

const Content = styled.div`
`;

const NewBudgetWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`;
