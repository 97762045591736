import React from 'react';
import { connect } from 'react-redux';

import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import { getAboutYouData } from '../reducers/user-data-reducer';
import { ApiConfig } from '../config';
import { bugsnagError } from '../services/bugsnag';

import WhoLivesHere from '../components/WhoLivesHere';

class WhoLivesHereContainer extends React.PureComponent {
  componentDidMount() {
    const { dispatch, userId } = this.props;
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.ABOUTYOU}/${userId}`, 'about_you')
    ).catch((error) => bugsnagError(error));
  }
  render() {
    const { data, userId } = this.props;
    const whoLivesHere = data && data.who_lives_here;
    const phoneNumber = (data && data.phone) || '+10000000000';
    const isTextEnabled = (data && data.texting_enabled) || false;
    return (
      <React.Fragment>
        <WhoLivesHere
          {...whoLivesHere}
          userId={userId}
          phoneNumber={phoneNumber}
          isTextEnabled={isTextEnabled}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const data = getAboutYouData(state);
  const userId = state.user_data.user_id;

  return {
    data,
    userId,
  };
};

export default connect(mapStateToProps)(WhoLivesHereContainer);
