import React, { Component } from 'react';
import $ from 'jquery';
import cookie from 'js-cookie';
import { WAREHOUSE_API_URL } from '../config';

export default class AdminPMDenied extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flag: 0,
      note: '',
      error: false,
    };

    this.handEmailSubmit = this.handEmailSubmit.bind(this);
    this.notesubmit = this.notesubmit.bind(this);
  }

  notesubmit() {
    this.setState({ flag: 1, note: this.noteFromInput.value });
  }

  handEmailSubmit() {
    $.ajax({
      type: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      dataType: 'JSON',
      url: `${WAREHOUSE_API_URL}/warehouse/api/p_m_notaproved/`,
      data: JSON.stringify({
        note: this.state.note,
        user_id: this.props.location.query.user_id,
      }),
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        window.location = `${WAREHOUSE_API_URL}/admin/warehouse/styleboard`;
      },
      error: (e) => {
        this.setState({ error: e });
      },
    });
  }
  renderError() {
    return <div> {this.state.error.e} </div>;
  }
  renderNext() {
    return (
      <div>
        Specifically, {this.state.note}
        <br />
        <button type="button" onClick={this.handEmailSubmit}>
          Send Email
        </button>
      </div>
    );
  }

  renderEmptyNote() {
    return (
      <form>
        <strong>Reason for Denial</strong>
        <br />
        <textarea
          rows="6"
          cols="50"
          ref={(note) => {
            this.noteFromInput = note;
          }}
          name="ta"
        />
        <br />
        <button type="button" onClick={this.notesubmit}>
          Preview Email
        </button>
      </form>
    );
  }

  render() {
    let returnpage;
    if (this.state.e) {
      returnpage = this.renderError();
    }
    if (this.state.flag === 0) {
      returnpage = this.renderEmptyNote();
    } else {
      returnpage = this.renderNext();
    }
    return returnpage;
  }
}
