import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';

import PatchButton from '../../components/PatchButton';
import { patchData } from '../../actions/api-patch-request-generator';
import { ApiConfig } from '../../config';

export default function ButtonAddProp ({
  onClick = () => {},
  spaceID = null,
} = {}) {
  const addProp = (
    patchData(
      ApiConfig.CREATE_EMPTY_CUSTOM_PROP,
      'custom_prop_field_change',
      {
        data: { space_id: spaceID },
        method: 'post',
      },
    )
  );

  return (
    <ButtonToolbar>
      <PatchButton
        buttonStyle="approve-all-button"
        patchRequests={[addProp, () => onClick()]}
        identifier="custom_props"
      >
        Add Record
      </PatchButton>
    </ButtonToolbar>
  );
}
