import React from 'react';
import styled from 'styled-components';
import CheckIconGreen from '../../images/check_icon_green.svg';

export default function DesignHeaderLayoutStatus ({
  anyLayoutSelected,
  layoutInfo
}) {
  const getSelectedCopy = () => {
    if (layoutInfo.selected_by) {
      return 'Chosen by customer';
    }
    return '48 hours passed';
  };

  const getCopy = () => {
    if (layoutInfo.selected) {
      return getSelectedCopy();
    }
    return 'Waiting on customer';
  };

  const render = layoutInfo.selected || !anyLayoutSelected;

  return render
    ? (
      <Container selected={layoutInfo.selected}>
        {layoutInfo.selected && (
          <CheckMark src={CheckIconGreen} />
        )}
        {getCopy()}
      </Container>
    )
    : null;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.selected ? '#067039' : '#EF4E22'};
`;
Container.displayName = 'Container';

const CheckMark = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 8px;
`;
CheckMark.displayName = 'CheckMark';
