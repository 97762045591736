import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as request from '../global/request';
import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import { setLikeReasons } from '../actions/like-reasons-actions';
import SpaceTab from '../components/SpaceTab';
import LoadingIcon from '../components/LoadingIcon';
import { ApiConfig } from '../config';
import { bugsnagError } from '../services/bugsnag';

class SpaceTabContainer extends React.PureComponent {
  componentDidMount() {
    const { activeSpaceID, activeSpaceOrderID, dispatch, userID } = this.props;
    // was selected as room type (stored in data but not displayed rn)
    if (activeSpaceID) {
      // setup cache references
      // to do remove all other space info data other than space info v2
      // will need to double check that everything is accessible from the newer endpoint
      const designsV2 = ['space_data', activeSpaceID, 'designsV2'];
      const designFeedbackV2 = [
        'user_data',
        activeSpaceID,
        'design_feedbackV2',
      ];
      const spaceInfoCache = ['space_data', activeSpaceID, 'space_info'];
      const spaceInfoCacheV1 = ['space_data', activeSpaceID, 'space_infoV1'];
      const spaceInfoCacheV2 = ['space_data', activeSpaceID, 'space_infoV2'];
      const spaceAssistanceCache = [
        'space_data',
        activeSpaceID,
        'space_assistance',
      ];

      const styleCollectionsCache = [
        'space_data',
        activeSpaceID,
        'style_collections',
      ];
      const quizResultsV2Cache = [
        'space_data',
        activeSpaceID,
        'quiz_resultsV2',
      ];
      const likeReasonsURL = `${ApiConfig.LIKE_REASONS}`;
      request
        .get(likeReasonsURL)
        .then((response) => {
          if (response && response.data) {
            dispatch(setLikeReasons(response.data));
          }
        })
        .catch((error) => bugsnagError(error));

      dispatch(
        fetchDataIfNeeded(
          `${ApiConfig.STYLE_COLLECTIONS}`,
          'style_collections',
          {
            params: { space_id: activeSpaceID, style: 'quiz' },
            keys: { space: activeSpaceID },
            cache: styleCollectionsCache,
          }
        )
      );

      dispatch(
        fetchDataIfNeeded(
          `${ApiConfig.STYLE_QUIZ_V2}/${userID}/space/${activeSpaceID}?versions=1,2`,
          'quiz_resultsV2',
          { cache: quizResultsV2Cache }
        )
      ).catch((error) => bugsnagError(error));

      dispatch(
        fetchDataIfNeeded(
          // loads send assistance information
          `${ApiConfig.SPACE_ASSISTANCE}?spaceId=${activeSpaceID}&page_size=1000&format=json`,
          'space_assistance',
          {
            keys: { space: activeSpaceID },
            cache: spaceAssistanceCache,
          }
        )
      );

      dispatch(
        fetchDataIfNeeded(
          // load designs feedback information
          `${ApiConfig.DESIGNSV2}?spaceId=${activeSpaceID}&filter=heidi&sort=desc&page=1`,
          'designsV2',
          {
            params: { active: true },
            keys: { space: activeSpaceID },
            cache: designsV2,
          }
        )
      );

      dispatch(
        fetchDataIfNeeded(
          // load designs feedback information
          `${ApiConfig.DESIGN_FEEDBACK_V2}?userId=${userID}`,
          'design_feedbackV2',
          { keys: { space: activeSpaceID }, cache: designFeedbackV2 }
        )
      );

      dispatch(
        fetchDataIfNeeded(
          // load designs information
          `${ApiConfig.DESIGNS}/${activeSpaceID}`,
          'space_infoV1',
          { keys: { space: activeSpaceID }, cache: spaceInfoCacheV1 }
        )
      );

      dispatch(
        fetchDataIfNeeded(
          // load v2 designs information
          `${ApiConfig.SPACE_DATA_V2}/${activeSpaceID}`,
          'space_infoV2',
          { keys: { space: activeSpaceID }, cache: spaceInfoCacheV2 }
        )
      );

      // get project and order information for selected space (stored in space_data[spaceID])
      dispatch(
        fetchDataIfNeeded(`${ApiConfig.SPACE_INFO}`, 'space_info', {
          params: { space_id: activeSpaceID, ppo: 1 },
          keys: { space: activeSpaceID },
          cache: spaceInfoCache,
        })
      ).catch((error) => bugsnagError(error));
    }
    // TDO AVI: SOMETHING IS FUNKY HERE????
    if (activeSpaceOrderID) {
      // setup cache references
      const orderCache = ['space_data', activeSpaceID, 'order'];
      dispatch(
        fetchDataIfNeeded(
          `${ApiConfig.ORDERS}/${activeSpaceOrderID}`,
          'order',
          { keys: { space: activeSpaceID }, cache: orderCache }
        )
      ).catch((error) => bugsnagError(error));
    }
    if (this.props.userID) {
      const orderHistoryCache = [
        'space_data',
        this.props.userID,
        'order_history',
      ];
      dispatch(
        fetchDataIfNeeded(
          ApiConfig.ORDER_ITEM_HISTORY + '?user_id=' + this.props.userID,
          'order_history',
          { keys: { space: this.props.userID }, cache: orderHistoryCache }
        )
      ).catch((error) => bugsnagError(error));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activeSpaceID,
      dispatch,
      spaceAssistanceDataNeedsRefresh,
    } = this.props;
    const spaceAssistanceCache = [
      'space_data',
      activeSpaceID,
      'space_assistance',
    ];

    if (
      spaceAssistanceDataNeedsRefresh !==
      prevProps.spaceAssistanceDataNeedsRefresh
    ) {
      dispatch(
        fetchDataIfNeeded(
          // update send assistance info
          `${ApiConfig.SPACE_ASSISTANCE}?spaceId=${activeSpaceID}&page_size=1000&format=json`,
          'space_assistance',
          {
            keys: { space: activeSpaceID },
            cache: spaceAssistanceCache,
          }
        )
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, activeSpaceID } = nextProps;
    if (nextProps.spaceInfo.spaceInfoRefresh) {
      const spaceInfoCache = ['space_data', activeSpaceID, 'space_info'];
      dispatch(
        fetchDataIfNeeded(`${ApiConfig.SPACE_INFO}`, 'space_info', {
          params: { space_id: activeSpaceID, ppo: 1 },
          keys: { space: activeSpaceID },
          cache: spaceInfoCache,
        })
      ).catch((error) => bugsnagError(error));
    }

    if (nextProps.spaceList.spaceListRefresh) {
      const spaceListCache = ['user_data', 'space_list'];
      dispatch(
        fetchDataIfNeeded(`${ApiConfig.SPACE_LIST}/`, 'space_list', {
          params: { user_id: this.props.userID },
          cache: spaceListCache,
        })
      ).catch((error) => bugsnagError(error));
    }

    if (this.props.activeSpaceOrderID !== nextProps.activeSpaceOrderID) {
      // setup cache references
      const orderCache = ['space_data', activeSpaceID, 'order'];
      dispatch(
        fetchDataIfNeeded(
          `${ApiConfig.ORDERS}/${nextProps.activeSpaceOrderID}`,
          'order',
          { keys: { space: activeSpaceID }, cache: orderCache }
        )
      ).catch((error) => bugsnagError(error));
    }
  }

  render() {
    const {
      spaceInfo,
      orderInfo,
      designsData,
      userID,
      spaceName,
      spaceType,
      spaceStatus,
      spaceInitialEta,
      spaceDelayedEta,
      spaceEtaStatus,
      spaceEtaEditedAt,
      spaceDeliveredAt,
      dispatch,
    } = this.props;

    // handle loading and errors - only handle space info error here
    if (spaceInfo.spaceInfoError) {
      return (
        <Alert bsStyle="danger">
          <strong>Oops!</strong>&nbsp; Something went wrong fetching the order
          and space information.
        </Alert>
      );
    } else if (
      (orderInfo.orderIsFetching && !orderInfo.orderData) ||
      (spaceInfo.spaceInfoIsFetching && !spaceInfo.spaceInfoData)
    ) {
      return <LoadingIcon />;
    }

    return (
      <SpaceTab
        orderData={orderInfo.orderData}
        initialSpaceDesignsData={designsData}
        spaceInfoData={spaceInfo.spaceInfoData}
        userID={userID}
        activeSpaceID={this.props.activeSpaceID}
        spaceName={spaceName}
        spaceType={spaceType}
        spaceStatus={spaceStatus}
        spaceInitialEta={spaceInitialEta}
        spaceEtaStatus={spaceEtaStatus}
        spaceEtaEditedAt={spaceEtaEditedAt}
        spaceDeliveredAt={spaceDeliveredAt}
        spaceDelayedEta={spaceDelayedEta}
        dispatch={dispatch}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const userID = state.user_data.user_id;
  const activeSpaceID = state.space_data.activeID;
  const activeSpaceOrderID =
    state.space_data.filteredSpaces[state.space_data.activeKey].order_id;

  let spaceInfo = state.user_data.space_list.data.results[0];
  const spaces = state.user_data.space_list.data.results;
  Object.keys(spaces).forEach((space) => {
    if (spaces[space].id === activeSpaceID) {
      spaceInfo = spaces[space];
    }
  });

  // get space name and type
  const {
    name: spaceName,
    space_type: spaceType,
    status: spaceStatus,
  } = spaceInfo;
  // empty object to trigger defaults if no space exists
  const spaceData = state.space_data[activeSpaceID] || {};

  const { designsV2: designsData } = spaceData;

  const defaults = {
    isFetching: true,
    data: {},
    error: null,
    needRefresh: false,
  };

  const {
    isFetching: orderIsFetching,
    lastUpdated: orderLastUpdated,
    data: orderData,
    error: orderError,
  } = spaceData.order || { defaults };

  const {
    isFetching: spaceListIsFetching,
    lastUpdated: spaceListLastUpdated,
    data: spaceListData,
    error: spaceListError,
    needRefresh: spaceListRefresh,
  } = state.user_data.space_list || defaults;

  const {
    isFetching: spaceInfoIsFetching,
    lastUpdated: spaceInfoLastUpdated,
    data: spaceInfoData,
    error: spaceInfoError,
    needRefresh: spaceInfoRefresh,
  } = spaceData.space_info || defaults;

  // adjust orderData if there is an order error - don't fail everything
  const orderErrorData = {
    tag: 'Cannot get purchases - either an old or test space.',
    order_number: 'N/A',
  };

  // fetch eta status info fields for TurnaroundTimeTab
  const spaceInitialEta = get(spaceInfo, 'eta_status_info.eta');
  const spaceDelayedEta = get(spaceInfo, 'eta_status_info.delayed_eta');
  const spaceEtaStatus = get(spaceInfo, 'eta_status_info.eta_status');
  const spaceEtaEditedAt = get(spaceInfo, 'eta_status_info.eta_edited_at');
  const spaceDeliveredAt = get(spaceInfo, 'eta_status_info.delivered_at');

  const spaceAssistanceDataNeedsRefresh = get(
    spaceData,
    'space_assistance.needRefresh'
  );

  return {
    orderInfo: {
      orderIsFetching,
      orderLastUpdated,
      orderData: orderError ? orderErrorData : orderData,
      orderError,
    },
    spaceInfo: {
      spaceInfoIsFetching,
      spaceInfoLastUpdated,
      spaceInfoData,
      spaceInfoError,
      spaceInfoRefresh,
    },
    spaceList: {
      spaceListIsFetching,
      spaceListLastUpdated,
      spaceListData,
      spaceListError,
      spaceListRefresh,
    },
    activeSpaceID,
    activeSpaceOrderID,
    designsData,
    userID,
    spaceName,
    spaceStatus,
    spaceType,
    spaceInitialEta,
    spaceDelayedEta,
    spaceEtaStatus,
    spaceDeliveredAt,
    spaceEtaEditedAt,
    spaceAssistanceDataNeedsRefresh,
  };
};

export default connect(mapStateToProps)(SpaceTabContainer);
