import React from 'react';

// HOC to handle editable fields - controls state, clicks, etc.
const EditableFieldContainer = (ComposedEditableField) => {
  return class EditableField extends React.PureComponent {
    constructor(props) {
      super(props);
      const { initialValue } = props;
      this.state = {
        editable: false,
        value: initialValue,
      };
    }

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    componentDidUpdate(prevProps) {
      // handles if the initial state value changes after component has mounted
      if (this.props.initialValue !== prevProps.initialValue) {
        this.setState({ value: this.props.initialValue });
      }
    }

    handleClick = () => {
      this.setState({ editable: true });
    };

    handleClickOutside = () => {
      if (this.mounted) {
        this.setState({ editable: false });
      }
    };

    handleChange = (e) => {
      this.setState({ value: e.target.value });
    };

    render() {
      const editableLink = (
        <button className="editable-link" onClick={this.handleClick}>
          {this.props.linkValue || this.props.children}
        </button>
      );

      if (!this.state.editable) {
        return editableLink;
      }

      return (
        <span>
          {this.props.keepLink ? editableLink : null}
          <ComposedEditableField
            {...this.props}
            patchRequests={this.props.requestGenerator(this.state.value)}
            handleClickOutside={this.handleClickOutside}
            handleFieldChange={this.handleChange}
            identifier={this.props.identifier}
            next={this.handleClickOutside}
            {...this.state}
          />
        </span>
      );
    }
  };
};

export default EditableFieldContainer;
