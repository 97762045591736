import styled from 'styled-components';

import {
  BaseFlex,
  BaseGrid,
} from '../../../../sharedComponents/sharedStyledComponents';

export const GridItem = styled.div`
  color: ${(props) => (props.isDisabled ? '#8B8B8B' : '#000')};
  font-weight: ${(props) =>
    props.isHeaderItem ? '700 !important' : '500 !important'};
  border-bottom: 1px solid #ccc;
  grid-row: ${(props) => (props.gridRow ? props.gridRow : 'auto / auto')};
  grid-column: ${(props) =>
    props.gridColumn ? props.gridColumn : 'auto / auto'};
  display: flex;
  flex: 1;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  padding: 8px 8px 8px 0;
  margin-bottom: 8px;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'none'};
`;

export const GridItemButton = styled.button`
  border: 0;
  padding: 0;
  color: ${(props) => {
    if (props.isDisabled) {
      return '#8B8B8B';
    } else if (!props.isButtonLink) {
      return '#000';
    } else {
      return '#4978bc';
    }
  }};
  width: fit-content;
  cursor: ${(props) =>
    props.isDisabled || !props.isButtonLink ? 'auto' : 'pointer'};
  background: none;
  text-align: start;

  &:active,
  &:focus {
    outline: ${(props) => (props.isDisabled ? 'none' : 'medium invert color')};
  }
`;

GridItemButton.displayName = 'GridItemButton';

export const IconContainer = styled(BaseFlex)`
  border-radius: 30px;
  box-sizing: border-box;
  width: 26px;
  height: 20px;
  background-color: #ef4d21 !important;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const MainContainer = styled.div`
  padding: 0 16px 40px 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const Header = styled.h4``;

Header.displayName = 'Header';

export const ContainerGrid = styled(BaseGrid)`
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns ? props.gridTemplateColumns : 'repeat(4, 1fr)'};
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
`;
