import React from 'react';
import styled from 'styled-components';

import { CircleCheckIcon, PendingIcon } from '../../sharedComponents/icons';

export default function RequestStatus({
  paddingRight = '0',
  requestData = [],
  width = 'auto',
} = {}) {
  const REQUEST_COMPLETE_STR = 'Request complete';
  const REQUEST_PENDING_STR = 'Request pending';

  if (!requestData.length) {
    return null;
  }

  const isRequestDataAddressed = requestData[0].addressed > 0;

  return (
    <RequestStatusContainer
      paddingRight={paddingRight}
      width={width}
    >
      <React.Fragment>
        {isRequestDataAddressed ?
          <SpanText>
            <Text color={'#067039'} >{REQUEST_COMPLETE_STR}</Text>
            <CircleCheckIcon fill={'#067039'} />
          </SpanText>
          :
          <SpanText>
            <Text color={'#EF4E22'}>{REQUEST_PENDING_STR}</Text>
            <PendingIcon />
          </SpanText>
        }
      </React.Fragment>
    </RequestStatusContainer>
  );
}

// styles
const RequestStatusContainer = styled.div`
  display: flex;
  width: ${props => props.width};
  justify-content: flex-end;
  position: relative;
  padding-right: ${props => props.paddingRight};
  height: 100%;
  pointer-events: none;
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.color};
  padding-right: 8px;
`;

const SpanText = styled.span`
  display: flex;
  align-items: center;
  font-weight: 200 !important;
`;
