import React from 'react';
import {
  Button,
  FormGroup,
  FormControl,
  InputGroup,
  Glyphicon,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import enhanceWithClickOutside from 'react-click-outside';
import PatchButtonContainer from '../containers/PatchButtonContainer';
import EditableFieldContainer from '../containers/EditableFieldContainer';
import LoadingIcon from './LoadingIcon';

class EditableTextField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { validationError: null };
  }

  validateInput = () => {
    if (!this.props.validationRegex) {
      this.setState({ validationError: null });
      this.props.handleClick();
    }
    const result = this.props.validationRegex.test(this.props.value);
    if (result) {
      this.props.handleClick();
    } else {
      this.setState({ validationError: this.props.validationMessage });
    }
  };

  handleKeyPress = (e) => {
    switch (e.keyCode) {
      case 13:
        this.validateInput();
        break;
      case 27:
        this.props.handleClickOutside();
        break;
      default:
        break;
    }
  };

  handleClickOutside = this.props.handleClickOutside;

  render() {
    const validationState =
      this.props.error || this.state.validationError ? 'error' : null;
    return (
      <FormGroup
        className={this.props.styling || 'editable-text-field'}
        validationState={validationState}
      >
        <InputGroup>
          <OverlayTrigger
            trigger={['hover']}
            placement="top"
            overlay={
              <Popover
                id="error-popover"
                style={{
                  display:
                    this.props.error || this.state.validationError
                      ? 'block'
                      : 'none',
                }}
              >
                <span className="rejected">
                  {this.state.validationError || 'Error changing data.'}
                </span>
              </Popover>
            }
          >
            <FormControl
              type="text"
              onKeyDown={this.handleKeyPress}
              onChange={this.props.handleFieldChange}
              value={this.props.value}
              autoFocus
            />
          </OverlayTrigger>
          <InputGroup.Button>
            {/*  the handleClick prop should come from the PatchButtonContainer HOC */}
            <Button onClick={this.validateInput}>
              {this.props.loading ? (
                <LoadingIcon size="small" />
              ) : (
                <Glyphicon glyph="ok" />
              )}
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </FormGroup>
    );
  }
}

/**
  * enhanceWithClickOutside is an HOC that calls onClickOutside()
    whenever the user clicks outside the component
  *                        -----
  * PatchButtonContainer is an HOC that wraps a component to
  * provide patch request functionality
  *                        -----
  * EditableFieldContainer is an HOC that abstracts basic editable field needs
  * i.e. shows on click, hides on click anywhere outside, value updating, etc.
  */
export default EditableFieldContainer(
  PatchButtonContainer(enhanceWithClickOutside(EditableTextField)),
);
