import React from 'react';
import styled from 'styled-components';

import altCheckedSelectedIcon from '../../images/altCheckSelectedIcon.svg';
import checkSelectedIcon from '../../images/check-selected.svg';
import checkSelectedHoverIcon from '../../images/check_icon_selected_hover.svg';
import checkSelectedHoverOnIconOnly from '../../images/check_selected_hover_on_icon.svg'
import { device } from '../../constants';


const CheckButton = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-self: end;
  background-image: ${ props => {
    if(props.selected ) return `url(${checkSelectedIcon})`;
    if(!props.selected && props.hover) return `url(${altCheckedSelectedIcon})`;
  } };
  border: 1px solid #E3E1DB;
  border-radius: 50%;
  &:hover {
    background-image: ${ props => {
      if(props.selected ) return `url(${checkSelectedHoverIcon})`;
      if(!props.selected && props.hover) return `url(${checkSelectedHoverOnIconOnly})`;
    }};
  }
`;

const Image = styled.img`
  padding: 0 0 8px 0;
`;

const Text = styled.div`
  font-family: graphik;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;

  font-weight: ${ props => props.selected && '500 !important' };
`;

const ReasonsBoxContainer = styled.button`
  cursor: pointer;
  background: #FFFFFF;
  padding: 8px;
  box-sizing: border-box;
  border: ${ props => props.selected ? '2px solid #41538D' : '1px solid #CCCCCC' };
  box-shadow: ${ props => props.selected && '0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.14)' };
  border-radius: 4px;
  margin-right: 8px;
  width: 105px;
  height: 126px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(182, 201, 228, 0.24), rgba(182, 201, 228, 0.24)), #FFFFFF;
  }

  @media ${device.tablet} {
    width: 105px;
    margin: 0 8px 8px 0;
  }
`;

const ReasonsContainer = styled.div`
  display: flex;
  height: auto;
  margin-bottom: 32px;

  @media ${device.tablet} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ReasonsBoxGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100%;
  justify-items: center;
  text-align: center;
`;

export default class RedesignReasons extends React.PureComponent {
  state = {
    hover: false,
  }

  handleHover = () => {
    this.setState({hover: !this.state.hover});
  }

 render() {
   const { handleRedesignReasonsDisplay, redesignRequestOptions } = this.props;
   return (
        <ReasonsContainer>
          {
            Object.keys(redesignRequestOptions).map((key, index) => {
              let redesignRequestOption = redesignRequestOptions[key];
              return (
                <ReasonsBoxContainer 
                  hover = { redesignRequestOption.hover }
                  selected = { redesignRequestOption.selected } 
                  onClick = { () => { handleRedesignReasonsDisplay(key, true, true); } } 
                  onMouseEnter = { () => { handleRedesignReasonsDisplay(key, false, true); } } 
                  onMouseLeave = { () => { handleRedesignReasonsDisplay(key, false, false); } } 
                  key = { index }
                  >
                  <ReasonsBoxGrid>
                  <CheckButton hover = { redesignRequestOption.hover } selected = { redesignRequestOption.selected } />
                    <Image src = { redesignRequestOption.icon } />
                    <Text selected = { redesignRequestOption.selected } >
                    { redesignRequestOption.label }
                    </Text>
                  </ReasonsBoxGrid>
                </ReasonsBoxContainer>
              );
            })
          }
        </ReasonsContainer>
   )
 }
}