import React from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Alert } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import get from 'lodash/get';
import TagPicker from "./TagPicker";
import PatchButton from "./PatchButton";
import CustomProductForm from "./CustomProductForm";
import CustomProductInlines from "./CustomProductInlines";
import { CUSTOM_PROP_STATUS, CUSTOM_PROP_STATUS_MAP } from "../constants";
import { patchData } from "../actions/api-patch-request-generator";
import { postData } from "../actions/api-post-request-generator";
import { ApiConfig } from "../config";
import UploadModal from "./UploadModal";
import Utils from "../utils";

class CustomPropInformation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mappedTagCategories: null,
      showUploadModal: false,
      units: "in",
      fields: {},
      showProductSearchModal: false
    };
  }

  UNSAFE_componentWillMount() {
    const { zoomImage } = this.props;
    // map the tagCategories for O(1) access if not yet mapped
    if (!this.state.mappedTagCategories) {
      const mappedTagCategories = {};
      this.props.tagCategories.results.forEach(tagCatergory => {
        mappedTagCategories[tagCatergory.id] = { ...tagCatergory };
      });
      this.setState({ mappedTagCategories });
    }

    if (get(zoomImage, 'unit')) {
      this.handleUnitChange(zoomImage.unit);
    }
  }

  toggleProductSearchModal = () => {
    this.setState({
      showProductSearchModal: !this.state.showProductSearchModal
    });
  };

  // change the units of the dimensions
  handleUnitChange = units => {
    this.setState({ units });
  };

  editField = (field, value) => {
    const obj = Object.assign(
      {},
      this.state.fields,
      Utils.buildState(field, value)
    );
    this.setState({ fields: obj });
  };

  render() {
    // null check for sources API call
    if (
      !this.props.sources ||
      !this.props.spaceCustomPropProductTypes ||
      !this.props.tags ||
      !this.props.tagCategories ||
      !this.props.tagCategories.results
    ) {
      return null;
    }

    // reassign
    const { zoomImage, sources, spaceCustomPropProductTypes, customProps, activeSpaceID, dispatch } = this.props;

    const saveObject = {};
    Object.keys(this.state.fields).forEach(key => {
      saveObject[key] = this.state.fields[key];
    });

    const saveRequests = [
      postData(
        `${ApiConfig.SUBMIT_CUSTOM_PROP.replace("{id}", zoomImage.id)}`,
        "custom_prop_field_change",
        {
          data: { customProp: {
            ...this.state.fields,
            status: (zoomImage.status === CUSTOM_PROP_STATUS.FIND_ALTERNATIVE ||
              zoomImage.status === CUSTOM_PROP_STATUS.FIND_ALTERNATIVE_NOT_STARTED) ||
              zoomImage.status === CUSTOM_PROP_STATUS.FIND_ALTERNATIVE_REQUEST_NOT_LINKED
                ? zoomImage.status
                : CUSTOM_PROP_STATUS.PENDING
          } },
          method: "post"
        }
      )
    ];

    const approveProp = postData(
      `${ApiConfig.APPROVE_CUSTOM_PROP(zoomImage.id)}`,
      "custom_prop_status_change",
      { data: { data: {} } }
    );

    const deleteProp = patchData(
      `${ApiConfig.CUSTOM_PROPS}/${zoomImage.id}`,
      "custom_prop_field_change",
      { method: "delete" }
    );

    let alert;
    if (
      this.props.custom_prop_status_change &&
      this.props.custom_prop_status_change.data &&
      this.props.custom_prop_status_change.data.ok === false
    ) {
      alert = (
        <Alert bsStyle="warning">
          {this.props.custom_prop_status_change.data.reason}
        </Alert>
      );
    }

    const customPropStatusList = CUSTOM_PROP_STATUS_MAP[zoomImage.status] || [];
    const customPropStatusClassName = customPropStatusList[1];
    const customPropStatusTitle = customPropStatusList[0];
    const zoomImageTags = get(zoomImage, 'tags', []);
    const zoomImageTagsCount = zoomImageTags.length;
    const { showUploadModal } = this.state;
    return (
      <div>
        {alert}
        <Row>
          <Col xs={6}>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={3} className="right-align-text hack-vert-center">
                    <strong>Prop Status</strong>
                  </Col>
                  <Col
                    xs={9}
                    className="inline-cp-field-value hack-vert-center"
                  >
                    <strong className={customPropStatusClassName}>
                      {customPropStatusTitle}
                    </strong>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={6} className="right-align-text">
            <PatchButton
              buttonStyle="delete-prop-button"
              patchRequests={[deleteProp]}
              identifier="custom_props"
              next={this.props.close}
            >
              Delete Record
            </PatchButton>
            <Button
              className="add-image-button"
              onClick={() => {
                this.setState({ showUploadModal: true });
              }}
            >
              Add Image(s)
            </Button>
              <UploadModal
                isLegacy={true}
                show={showUploadModal}
                close={() => {
                  this.setState({ showUploadModal: false });
                }}
                title="Upload Custom Prop Photos"
                url={ApiConfig.UPLOAD_CUSTOM_PROP_IMAGE.replace(
                  "{propID}",
                  zoomImage.id
                )}
                actionIdentifier="custom_prop_photo_upload"
                refreshIdentifier="custom_props"
              />
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        <CustomProductInlines
          zoomImage={zoomImage}
          units={this.state.units}
          handleUnitChange={this.handleUnitChange}
          dispatch={dispatch}
          activeSpaceID={activeSpaceID}
        />
        <Row>
          <hr style={{ marginTop: 10 }} />
        </Row>
        <Row>
          <Col xs={6}>
            <Row>
              <Col xs={12}>
                <CustomProductForm
                  shouldShowAlternativesRequestDropdown = { this.props.shouldShowAlternativesRequestDropdown }
                  zoomImage={zoomImage}
                  sources={sources}
                  spaceCustomPropProductTypes={spaceCustomPropProductTypes}
                  editField={this.editField}
                  fields={this.state.fields}
                  showProductSearchModal={this.state.showProductSearchModal}
                  toggleProductSearchModal={this.toggleProductSearchModal}
                  customProps={customProps}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className="separated-rows">
              <Col xs={2} className="tags-label">
                <strong>Tags ({zoomImageTagsCount})</strong>
              </Col>
              <Col xs={10}>
                <TagPicker
                  zoomImage={zoomImage}
                  tagCategories={this.state.mappedTagCategories}
                  tags={this.props.tags}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="center-text save-and-approve">
          <PatchButton
            patchRequests={saveRequests}
            identifier="custom_props"
            disabled={
              isEmpty(this.state.fields) ||
              zoomImage.status === CUSTOM_PROP_STATUS.APPROVED
            }
          >
            Save
          </PatchButton>

          {zoomImage.status === CUSTOM_PROP_STATUS.NOT_STARTED ||
            (zoomImage.status === CUSTOM_PROP_STATUS.PENDING && (
              <PatchButton
                buttonStyle="approve-button"
                patchRequests={[approveProp]}
                identifier="custom_props"
                disabled={zoomImage.status === CUSTOM_PROP_STATUS.APPROVED}
              >
                Approve Prop
              </PatchButton>
            ))}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { edit_requests, space_data } = state;
  const { custom_prop_status_change } = edit_requests;
  const activeSpaceID = space_data.activeID;
  const customProps = get(state, `space_data[${activeSpaceID}].custom_props.data.results`);

  return {
    activeSpaceID,
    custom_prop_status_change,
    customProps
  };
}

export default connect(mapStateToProps)(CustomPropInformation);
