import React from 'react';

export const OrderSourceDiscounts = ({
  orderSourceDiscounts,
  sourceName,
}) => {
  if (
    orderSourceDiscounts &&
    orderSourceDiscounts.data &&
    sourceName in orderSourceDiscounts.data
  ) {
    return (
      <div className='sourcediscounts'>
        <div className='sourcediscounts-title'> Source Discounts Enabled at Checkout time </div>
        {orderSourceDiscounts.data[sourceName].map((discount, index, discounts) => {
          let status = discount.status.toLowerCase();
          let className = `sourcediscount ${status}`
          if(discounts.length === index + 1) {
            className += ' last-discount'
          }
          return (
            <div className={className} key={`discount-description-${index + 1}`}>
              {discount.description}
            </div>
          )})
        }
      </div>
    )
  }
  return <div/>;
}

export default OrderSourceDiscounts;
