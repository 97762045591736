import cookie from 'js-cookie';
import axios from 'axios';

// Apply default headers to the request
// Any headers that are specified in the options argument
// will take precedence
function applyDefaultHeaders(options) {
  options = options || {};
  options.headers = Object.assign(
    {},
    {
      'X-CSRFToken': cookie.get('csrftoken'),
    },
    options.headers,
  );
  return options;
}

export function get(path, options) {
  options = applyDefaultHeaders(options);
  return axios.get.apply(this, [path, options]);
}

export function post(path, data, options) {
  options = applyDefaultHeaders(options);
  options.headers = Object.assign(
    {},
    {
      'Content-Type': 'application/json',
    },
    options.headers,
  );
  return axios.post.apply(this, [path, data, options]);
}

export function put(path, data, options) {
  options = applyDefaultHeaders(options);
  options.headers = Object.assign(
    {},
    {
      'Content-Type': 'application/json',
    },
    options.headers,
  );
  return axios.put.apply(this, [path, data, options]);
}

export function patch(path, data, options) {
  options = applyDefaultHeaders(options);
  options.headers = Object.assign(
    {},
    {
      'Content-Type': 'application/json',
    },
    options.headers,
  );
  return axios.patch.apply(this, [path, data, options]);
}

export function deleteOption(path, data, options) {
  options = applyDefaultHeaders(options);
  options.headers = Object.assign(
    {},
    {
      'Content-Type': 'application/json',
    },
    options.headers,
  );
  return axios.delete.apply(this, [path, data, options]);
}
