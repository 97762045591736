import React from 'react';
import styled from 'styled-components';
import invert from 'lodash/invert';

import { transformConstants } from './utils';

import layoutIcon from './images/ic-L2-assistance-layout.svg';
import modelFixesIcon from './images/assistance-model-fixes.svg';
import productsIcon from './images/assistance-products.svg';
import styleIcon from './images/assistance-style.svg';
import questionMarkIcon from './images/questionMark_icon.svg';
import AdultsSVG from './components/images/adults-logo.svg';
import TeensSVG from './components/images/teens-logo.svg';
import BigKidsSVG from './components/images/big-kids-logo.svg';
import LittleKidsSVG from './components/images/little-kids-logo.svg';
import BabiesSVG from './components/images/babies-logo.svg';
import CatsSVG from './components/images/cats-logo.svg';
import DogsSVG from './components/images/dogs-logo.svg';
import OtherPetsSVG from './components/images/other-pets-logo.svg';

export const STANZA_URL =
  'https://app.zohocreator.com/leland14/stanza/record-summary/All_Redesigns_Report/';

export const CONFIGCAT_KEYS = {
  prod: 'ZZ7WCF60cOUveglv4p6dLw/86LTKy17A0GebpuAaGsg5A',
  staging: 'ZZ7WCF60cOUveglv4p6dLw/xoCieWSkjkiWZyiqwQCW_w',
  dev: 'ZZ7WCF60cOUveglv4p6dLw/TZLziAG3ZEyZlTpu9-UjGg',
};

export const IOS_CONFIGCAT_KEYS = {
  prod: 'ZZ7WCF60cOUveglv4p6dLw/whb4M0NPnE2gWRGD4Ah6zQ',
  staging: 'ZZ7WCF60cOUveglv4p6dLw/-1R21bmCzEGvlYJ2NNXRdg',
  dev: 'ZZ7WCF60cOUveglv4p6dLw/6AhQbAZlF0-YUBrxNTXaiQ',
};

export const WAREHOUSE_CONFIGCAT_KEYS = {
  prod: 'ZZ7WCF60cOUveglv4p6dLw/mupgtmcz0ESwzqsS9PzO1Q',
  staging: 'ZZ7WCF60cOUveglv4p6dLw/ahG7uwfI-ECox0ArVuTJkw',
  dev: 'ZZ7WCF60cOUveglv4p6dLw/lVziKAr9qk6dhhp8oX5YQw',
};

export const SEGMENT_KEYS = {
  prod: '3fbIsVxyQZErxIqQrZ4FhLVxK3Tv5fc6',
  staging: 'eopJUEbv8vsDU14VtMldNYY2YHDo2Hcs',
  dev: 'vfBehCN78AgjkctJyHZn085xr8Js9TSk',
};
// identifiers for API calls specific to a space
export const SPACE_SPECIFIC_API_IDENTIFIERS = [
  'additional_photos',
  'activity_log',
  'dimensions',
  'space_info',
  'order',
  'renders',
  'ppo_room_details',
  'style_collections',
  'designs',
  'designsV2',
  'space_infoV1',
  'space_infoV2',
  'custom_props',
  'product_categories',
  'showroom_info',
  'space_assistance',
];

// identifiers for API calls specific for a user (but not space)
export const USER_SPECIFIC_API_IDENTIFIERS = [
  'design_feedbackV2',
  'quiz_results',
  'quiz_resultsV2',
  'survey_additional_questions',
  'space_list',
  'basic_information',
  'products',
  'coupon',
  'favorite_props',
  'about_you',
];

// identifiers for API calls specific to a design
export const DESIGN_SPECIFIC_API_IDENTIFIERS = ['design_products'];

// identifiers for methods other than 'GET'
export const EDIT_REQUEST_IDENTIFIERS = [
  'additional_photos_upload',
  'user_photo_status_change',
  'measurement_status_change',
  'measurement_field_change',
  'custom_prop_status_change',
  'custom_prop_field_change',
  'gdrive_upload',
  'space_info_change',
  'space_list_change',
  'user_photo_upload',
  'send_photos_approved_email',
  'custom_prop_photo_upload',
  'custom_props_submit',
  'about_you_change',
  // 'billing_info_change', // TODO_REMOVE, do I need this here? it doesn't *seem* to be needed to work
];

export const API_ACTION_TYPES = ['REQUEST', 'SUCCESS', 'FAILURE', 'REFRESH'];

// enum selecting whether the user liked or disliked products
export const QUIZ_PRODUCTS_FILTER = {
  LIKED: true,
  DISLIKED: false,
};

// int for number of liked/disliked products per page
export const QUIZ_PRODUCTS_COUNT = 100;

// enum selecting whether to display products or renders
export const DESIGNS_FILTER = {
  PRODUCTS: 'Products',
  RENDERS: 'Renders',
};

// maps the different design statuses to readable strings
export const DESIGN_CHOICES = {
  0: 'Design in progress',
  1: 'Abandoned',
  2: 'Sent to user',
  4: 'Pending',
  5: 'Ready to send',
  6: 'Rejected',
};

export const DESIGN_STATUS = transformConstants({
  0: 'Design in progress',
  1: 'Abandoned',
  2: 'Sent to user',
  4: 'Pending',
  5: 'Ready to send',
  6: 'Rejected',
});

export const DESIGN_SOURCE = {
  'Initial Design': 1,
  Revision: 2,
  'Live Swap': 3,
  'Elsie New': 4,
  'Elsie Clone': 5,
  'Bessie Revision': 6,
  Showroom: 7,
};

export const SOURCE_TYPE = {
  studio: { label: 'studio', type: 'draft' },
  bessie: { label: 'bessie', type: 'beauty' },
};

// enum selecting whether to display Likes or Dislikes
export const FAVORITES = {
  LIKES: 'Likes',
  DISLIKES: 'Dislikes',
};

// enum selecting whether to filter spaces in the
// favorite props panels
export const SPACES_FILTER = {
  ACTIVE_SPACE: 'Current Space',
  ALL: 'All', // products likes come from many sources including studio, shop, and design-ideas
};

// maps space statuses to readable strings
export const SPACE_STATUS_FORMAT = {
  pending: 'Pending',
  inprogress: 'In Progress',
  'initial designs pending': 'Initial Designs Pending',
  ready: 'Ready',
  abandoned: 'Abandoned',
  'under modsy review': 'Under Modsy Review',
};

// enum for numeric user measurement status
export const MEASUREMENT_STATUS = {
  NOT_STARTED: 0,
  PENDING: 1,
  APPROVED: 2,
  NOT_APPROVED: 3,
};

// maps measurment status to description and style
export const MEASUREMENT_STATUS_MAP = {
  0: ['Measurements Not Started', 'default'],
  1: ['Measurements Pending Review', 'warning'],
  2: ['Measurements Approved', 'success'],
  3: ['Measurements Rejected', 'danger'],
};

// enum for numeric user image status to pass to backend
export const USER_IMAGE_STATUS = {
  NOT_STARTED: 0,
  PENDING: 1,
  APPROVED: 2,
  NOT_APPROVED: 3,
};

// maps user space photo status to description and style
export const PHOTO_STATUS = {
  pending: ['Pending Review', 'pending', USER_IMAGE_STATUS.PENDING],
  approved: ['Approved', 'approved', USER_IMAGE_STATUS.APPROVED],
  ng: ['Rejected', 'rejected', USER_IMAGE_STATUS.NOT_APPROVED],
};

export const PHOTO_STATUS_REJECTED = 'ng';

// maps identifiers to image list information
export const IMAGE_LIST_TYPES = {
  PHOTOS_AND_MEASUREMENTS: 0,
  DESGIN_PRODUCTS: 2,
  RENDERS: 3,
  CUSTOM_PROPS: 4,
  FAVORITE_PROPS: 5,
};

export const PROJECT_SCOPE_CHOICES_DEFAULT = 999;

// maps project scope identifiers from project questions to explanations
export const PROJECT_SCOPE_CHOICES = {
  0: 'Starting from scratch',
  1: 'Somewhere in between',
  2: 'Mostly furnished',
  999: 'Cannot Fetch Data',
};

// enum for numeric user measurement status
export const CUSTOM_PROP_STATUS = {
  NOT_STARTED: 0,
  PENDING: 1,
  APPROVED: 2,
  FIND_ALTERNATIVE_NOT_STARTED: 3,
  FIND_ALTERNATIVE: 4,
  FIND_ALTERNATIVE_REQUEST_NOT_LINKED: 5,
};

// map custom prop status to readable strings and text styles
export const CUSTOM_PROP_STATUS_MAP = {
  0: ['Not Started', ''],
  1: ['Pending Review', 'pending'],
  2: ['Approved', 'approved'],
  3: ['Stand-in Not Started', ''],
  4: ['Stand-in', 'alternative'],
  5: ['Stand-in Not Linked', 'pending'],
};

// enum for numeric user measurement status
export const CUSTOM_PROP_TYPE = {
  UNKNOWN: '0',
  FIND_ALTERNATIVE_REQUEST: '1',
  ALTERNATIVE_PRODUCT: '2',
  CUSTOM_PRODUCT: '3',
};

// map custom prop status to readable strings and text styles
export const CUSTOM_PROP_TYPE_MAP = {
  [CUSTOM_PROP_TYPE.UNKNOWN]: 'Unknown',
  [CUSTOM_PROP_TYPE.FIND_ALTERNATIVE_REQUEST]: 'Stand-in Request',
  [CUSTOM_PROP_TYPE.ALTERNATIVE_PRODUCT]: 'Stand-in',
  [CUSTOM_PROP_TYPE.CUSTOM_PRODUCT]: 'Existing Furniture',
};

// maps user filters to readable titles
export const USER_FILTERS = {
  all: 'Any',
  customer: 'Customers',
  user__is_staff: 'Staff',
};

export const ORDER_REPORT_GROUPING = {
  '': '--Select Grouping--',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  all: 'All Time',
  source: 'Source',
  product: 'Product',
  order: 'Order',
  status: 'Status',
  hour: 'Hour Of Day',
  day_of_week: 'Day Of Week',
  payment_method: 'Payment Method',
  customer: 'Customer',
  retailer_order_number: 'Retailer Order Number',
  agent_user: 'Purchasing Agent',
  designer_user: 'Design Specialist',
  stylist_user: 'Style Advisor',
  shipping_state: 'Shipping State',
};

export const DAY_OF_WEEK = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
};

export const AGENT_USERS = {
  '': '--None Selected--',
  149621: 'Kate Mazzera',
  209073: 'Anna Anderson',
  197039: 'Leilani Bisquera',
  141867: 'Lidia Tellez',
  200147: 'Jess Brown',
  210354: 'Monique Ramon',
  129735: 'Tyler Okuniewski',
};

export const ORDER_STATUS = {
  '': '--All Order Statuses--',
  0: 'starting ...',
  1: 'Created',
  4: 'Completed the order',
  // 5: 'Reviewed and approved',
  // 6: 'Order delivered',
  11: 'Actual amount confirm',
  12: 'Card charged',
  // 13: 'Transfer completed',
  7: 'Cancelled',
  8: 'Refund requested',
  10: 'Partially refunded',
  9: 'Refunded',
  '1,4,8,9,10,11,12': '**Completed', // all except starting, Cancelled
};

/**
 * This is to call/read statuses by using readable strings instead of numbers.
 * Note: The value here is now a string and not a number.
 *
  ORDER_STATUS_HUMAN_READABLE: {
   [...]
   Cancelled: "7"
   Refunded: "9"
   [...]
  }
 */
export const ORDER_STATUS_HUMAN_READABLE = invert(ORDER_STATUS);

// this is just an ordered list of the above statuses to preserve ordering
export const ORDER_STATUS_LIST = [
  '',
  '1,4,8,9,10,11,12',
  0,
  1,
  4,
  11,
  12,
  7,
  8,
  10,
  9,
];

export const DELIVERY_STATUSES = {
  '': '--Delivery Status--',
  pending_customer: 'Pending Cust App.',
  pending_management: 'Pending Mgmt App.',
  ordered: 'Ordered',
  ready_to_schedule: 'Ready to Schedule',
  scheduled: 'Scheduled',
  shipped: 'Shipped',
  delivered: 'Delivered',
  returned: 'Returned',
  return_requested: 'Return Requested',
  in_home_delivery: 'In Home Delivery',
  cancelled: 'Cancelled',
  exchanged: 'Exchanged',
};
export const DELIVERY_REASONS = {
  '': '--No Reason--',
  out_of_stock: 'Out Of Stock',
  backorder_dates: 'Backorder Dates',
  additional_shipping_charges: 'Addl Shipping $',
  could_not_use_credits: "Couldn't Use Credits",
  final_sale: 'Final Sale',
  changed_mind_on_items: 'Changed Mind Item(s)',
  retailer_requested: 'Retailer Requested Cancellation',
  specialty_promo_code: 'Specialty Promo Code',
  no_response_regarding_approval: 'No Response Re: Approval',
  international_shipping: 'Intl Shipping',
  price_discrepancy: 'Price Discrepancy',
  remaining_items: 'Remaining Items',
};
export const INVENTORY_STATUSES = {
  '': '--Inv. Status--',
  in_stock: 'In Stock',
  out_of_stock: 'Out of Stock',
  backordered: 'Backordered',
  mto: 'Made to Order',
  discontinued: 'Discontinued',
  canceled: 'Canceled',
};

// keys mapped to verbose space status
export const SPACE_STATUS = {
  0: 'Pending',
  1: 'Ready',
  8: 'Pre-Purchase',
  9: 'In Progress',
  999: 'Any',
};

/**
 * This constant is only used if the following GET request returns an error:
 * {WAREHOUSE_API_URL}/api/payment/get_metadata
 * Otherwise, we are storing the package types received from this request
 * See getAllActivePackages() method fired when UsersContainer mounts.
 */
export const PACKAGE_TYPES = {
  0: 'All Packages',
  '3D_CB2_Package': 'CB2 + Modsy',
  '3DSA_CB2_Package': 'CB2 + Modsy Style Advisor',
  '3D_Crate_Package': 'Crate and Barrel Package',
  CB_Empowered: 'Crate Empowered',
  CB_Standard: 'Crate Standard',
  CB_Supported: 'Crate Supported',
  CUSTOM_PRODUCT_1: 'Custom Product Add-On',
  PACKAGE_1: 'Essential Package',
  Modsy_Expedited: 'Expedited Turnaround',
  Design_Consult: 'Free Design Consultation',
  Live_Styling: 'Live Virtual Styling Session',
  VARIENT_A: 'Modsy',
  Mod_Des_Pac_199: 'Modsy + Style Advisor',
  '3D_Room_Model': 'Modsy Design Package',
  Design_Services: 'Modsy Design Services',
  '3D_OS_PACKAGE': 'Overstock + Modsy',
  '3DSA_OS_PACKAGE': 'Overstock + Modsy Style Advisor',
  PP_Standard: 'Partner Portal Standard',
  PACKAGE_2: 'Premium Package',
  repl4ce: 'Replace Package',
  'free-custom-prop': 'Requested Retailer Product',
  '3D_W-Elm_Package': 'West Elm + Modsy',
  WE_Empowered: 'West Elm Empowered',
  WE_Supported: 'West Elm Supported',
  Platinum_Package: 'Platinum Package',
  consult_package: 'Modsy Consult',
  Modsy_Shwrm_Pckg: 'Modsy Showroom', // id: 30, Modsy Express Showroom
  Modsy_Plus_Pckg: 'Modsy Plus', // id: 29, Modsy Plus Classic'
};

/**
 * The old packages get filtered before being sent to this endpoint:
 * https://www.warehouse.dev.modsy.com/api/payment/get_metadata
 * The team still need to keep track of these old purchases
 */
export const OLD_PACKAGES_TYPES = {
  CB_Empowered: 'Crate Empowered',
  CB_Standard: 'Crate Standard',
  CB_Supported: 'Crate Supported',
  PACKAGE_1: 'Essential Package',
  Modsy_Expedited: 'Expedited Turnaround',
  Design_Consult: 'Free Design Consultation',
  Live_Styling: 'Live Virtual Styling Session',
  Design_Services: 'Modsy Design Services',
  '3D_OS_PACKAGE': 'Overstock + Modsy',
  '3DSA_OS_PACKAGE': 'Overstock + Modsy Style Advisor',
  PP_Standard: 'Partner Portal Standard',
  PACKAGE_2: 'Premium Package',
  WE_Empowered: 'West Elm Empowered',
  WE_Supported: 'West Elm Supported',
};

export const PENDING_STATUS = {
  dimensions_pending: 'Pending: P&M approval',
  dimensions_rejected: 'Pending: Rejected',
  custom_props: 'Props approval',
  any: 'Any',
};

// maps values to space status
export const SPACE_STATUS_MAP = {
  PENDING: 0,
  READY: 1,
  PRE_PURCHASE: 8,
  IN_PROGRESS: 9,
  ANY: 999,
};

// fields to order users  by (column names)
export const USER_ORDER_BY = {
  'Room ID': 'id',
  Email: 'user__email',
  Name: 'user__last_name',
  'Staff Status': 'is_staff',
  'Date P&M submitted': 'spacemeasurement__updated_at',
};

// order by active, inactive, or all
export const ACTIVE_FILTERS = {
  true: 'Active',
  false: 'Inactive',
  all: 'All',
};

// maps identifier to contractor number
export const CONTRACTOR_MAP = {
  User: 0,
  Swing: 1,
  WeRPlay: 2,
  Hammer: 3,
  ArchCGI: 4,
};

// INCHES in a millimeter
export const MILLIMETERS_TO_INCHES = 0.0393701;
export const MILLIMETERS_TO_FEET = 0.00328084;
export const INCHES_TO_MILLIMETERS = 25.4;
export const FEET_TO_MILLIMETERS = 304.8;

export const SPACE_TYPE_CHOICES = {
  1: 'Living Room',
  2: 'Dining Room',
  3: 'Family Room',
  4: 'Office',
  5: 'Guest Room',
  6: 'Master Bedroom',
  7: 'Guest Bedroom',
  8: "Kid's Room",
  9: 'Nursery',
  10: 'Entry',
  11: 'Studio',
  999: 'Open Living / Dining Room',
  9999: 'Other',
};

const YES = 'Y';
const NO = 'N';
const NOT_SURE = 'M';

export const FURNITURE_PURCHASE_INTENT_CHOICES = {
  YES, // "Yes"
  NO, // "No"
  NOT_SURE, // "I'm Not Sure Yet"
};

const NO_DEADLINE = 1;
const NEAR_FUTURE = 2;
const ASAP = 3;

export const FURNITURE_PURCHASE_URGENCY_CHOICES = {
  NO_DEADLINE, // "No deadline"
  NEAR_FUTURE, // "In the near future"
  ASAP, // "ASAP"
};

const BLACK_AND_WHITE = 'Black & White';
const BLUES = 'Blues';
const BRIGHT_MULTICOLOR = 'Bright Multicolor';
const EARTH_TONES = 'Earth Tones';
const GREENS = 'Greens';
const JEWEL_TONES = 'Jewel Tones';
const NEUTRALS = 'Neutrals';
const PASTELS = 'Pastels';
const NOT_SURE_COLOR = 'Not Sure';

export const COLOR_FAMILY_CHOICES = {
  [BLACK_AND_WHITE]: {
    color: 'Black & White',
    showroomKey: 'black-and-white',
  },
  [BLUES]: {
    color: 'Blues',
    showroomKey: 'blues',
  },
  [BRIGHT_MULTICOLOR]: {
    color: 'Bright Multi-colors',
    showroomKey: 'bright-hues',
  },
  [EARTH_TONES]: {
    color: 'Earth Tones',
    showroomKey: 'earth-tones',
  },
  [GREENS]: {
    color: 'Greens',
    showroomKey: 'greens',
  },
  [JEWEL_TONES]: {
    color: 'Jewel Tones',
    showroomKey: 'jewel-tones',
  },
  [NEUTRALS]: {
    color: 'Neutrals',
    showroomKey: 'neutrals',
  },
  [PASTELS]: {
    color: 'Pastels',
    showroomKey: 'pastels',
  },
  [NOT_SURE_COLOR]: {
    color: 'Not Sure',
  },
};

const BUDGET_FRIENDLY = 'budget-friendly';
const MID_RANGE = 'mid-range';
const ONLY_THE_BEST = 'only-the-best';

export const SHOWROOM_PRICE_RANGES = {
  [BUDGET_FRIENDLY]: 'Budget-friendly (opening price point)',
  [MID_RANGE]: 'Mid-range (mid price point)',
  [ONLY_THE_BEST]: 'Only the best (high price point)',
};

const ANTHROPOLOGIE = 'Anthropologie';
const ARTICLE = 'Article';
const CB2 = 'CB2';
const CRATE = 'Crate & Barrel';
const DESIGN_WITHIN_REACH = 'Design Within Reach';
const HORCHOW = 'Horchow';
const POTTERY_BARN = 'Pottery Barn';
const SERENA_AND_LILY = 'Serena & Lily';
const TARGET = 'Target';
const WAYFAIR = 'Wayfair';
const WEST_ELM = 'West Elm';
const WILLIAMS_SONOMA = 'Williams Sonoma';
const JOYBIRD = 'Joybird';
const ALL_MODERN = 'All Modern';

export const BRANDS_MAP = {
  [ALL_MODERN]: ALL_MODERN,
  [ANTHROPOLOGIE]: ANTHROPOLOGIE,
  [ARTICLE]: ARTICLE,
  [CB2]: CB2,
  [CRATE]: CRATE,
  [DESIGN_WITHIN_REACH]: DESIGN_WITHIN_REACH,
  [HORCHOW]: HORCHOW,
  [JOYBIRD]: JOYBIRD,
  [POTTERY_BARN]: POTTERY_BARN,
  [SERENA_AND_LILY]: SERENA_AND_LILY,
  [TARGET]: TARGET,
  [WAYFAIR]: WAYFAIR,
  [WEST_ELM]: WEST_ELM,
  [WILLIAMS_SONOMA]: WILLIAMS_SONOMA,
};

const NEUTRAL = 'NE';
const POPS_OF_COLORS = 'PC';
const BOLD = 'BO';

export const COLOR_INTENSITY_CHOICES_MAP = {
  [NEUTRAL]: 'Neutral',
  [POPS_OF_COLORS]: 'Pops of Colors',
  [BOLD]: 'Bold',
};

export const BUDGET_FORMAT_TRANSITION_DATE = '08-20-2019';

export const BUDGET_CHOICES_DEFAULT = 'notSure';

export const BUDGET_CHOICES = {
  notSure: "I'm Not Sure",
  0: '$1000 or less',
  2500: '$2500',
  5000: '$5000',
  7500: '$7500',
  10000: '$10,000 or more',
};

export const NO_CONSULTATION = 0;
export const PRE_DESIGN_CONSULTATION = 1;
export const POST_DESIGN_CONSULTATION = 2;

export const CONSULTATION_CHOICES = {
  [NO_CONSULTATION]: 'No Choice',
  [PRE_DESIGN_CONSULTATION]: 'Pre-Design Consultation',
  [POST_DESIGN_CONSULTATION]: 'Post-Design Consultation',
};

export const KEEP_CURRENT_LAYOUT_OPTIONS = {
  Y: 'Yes, please!',
  N: "No, I'd like to see new ideas",
  S: "N/A - I'm starting from scratch",
};

export const SPACE_TYPES = {
  LIVING_ROOM: 1,
  DINING_ROOM: 2,
  FAMILY_ROOM: 3,
  OFFICE_ROOM: 4,
  GUEST_ROOM: 5,
  MASTER_BEDROOM: 6,
  GUEST_BEDROOM: 7,
  KIDS_ROOM: 8,
  NURSERY_ROOM: 9,
  ENTRY_ROOM: 10,
  STUDIO_ROOM: 11,
  OPEN_LIVING_DINING_ROOM: 999,
  OTHER_ROOM: 9999,
};

const CONVERSATIONAL = {
  value: 1,
  label: 'Conversational seating - for intimate gatherings',
};
const ENTERTAINING = {
  value: 2,
  label: 'Space for entertaining - for larger gatherings',
};
const ENTRYWAY = { value: 3, label: 'Entryway storage' };
const PLAY_AREA = { value: 4, label: 'Play area for my kids' };
const READING_NOOK = { value: 5, label: 'Reading nook' };
const WORKSPACE = { value: 6, label: 'Workspace' };
const BAR_AREA = { value: 7, label: 'Bar area' };
const VANITY = { value: 8, label: 'Vanity' };
const INCLUDE_IN_LAYOUT_NONE_OPTION = { value: 0, label: "I'm not sure" };
export const INCLUDE_IN_LAYOUT_OTHER_OPTION = 9;
export const NONE_OPTION = 0;

export const INCLUDE_IN_LAYOUT_OPTIONS = {
  [SPACE_TYPES.LIVING_ROOM]: {
    CONVERSATIONAL,
    ENTERTAINING,
    ENTRYWAY,
    PLAY_AREA,
    READING_NOOK,
    WORKSPACE,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.DINING_ROOM]: {
    BAR_AREA,
    READING_NOOK,
    WORKSPACE,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.FAMILY_ROOM]: {
    CONVERSATIONAL,
    ENTERTAINING,
    ENTRYWAY,
    PLAY_AREA,
    READING_NOOK,
    WORKSPACE,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.OPEN_LIVING_DINING_ROOM]: {
    CONVERSATIONAL,
    ENTERTAINING,
    ENTRYWAY,
    PLAY_AREA,
    READING_NOOK,
    WORKSPACE,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.OFFICE_ROOM]: {
    READING_NOOK,
    PLAY_AREA,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.GUEST_ROOM]: {
    READING_NOOK,
    WORKSPACE,
    VANITY,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.MASTER_BEDROOM]: {
    READING_NOOK,
    WORKSPACE,
    VANITY,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.GUEST_BEDROOM]: {
    READING_NOOK,
    WORKSPACE,
    VANITY,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.KIDS_ROOM]: {
    PLAY_AREA,
    READING_NOOK,
    WORKSPACE,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.NURSERY_ROOM]: {
    PLAY_AREA,
    READING_NOOK,
    WORKSPACE,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.ENTRY_ROOM]: {
    ENTRYWAY,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.STUDIO_ROOM]: {
    CONVERSATIONAL,
    ENTERTAINING,
    ENTRYWAY,
    READING_NOOK,
    WORKSPACE,
    VANITY,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
  [SPACE_TYPES.OTHER_ROOM]: {
    CONVERSATIONAL,
    ENTERTAINING,
    ENTRYWAY,
    READING_NOOK,
    WORKSPACE,
    VANITY,
    NONE: INCLUDE_IN_LAYOUT_NONE_OPTION,
  },
};

export const LAYOUT_MAIN_FOCUS_OPTIONS = {
  TV: { value: 1, label: 'TV' },
  FIREPLACE: { value: 2, label: 'Fireplace' },
  ARTWORK: { value: 3, label: 'Artwork' },
  OUTSIDE_VIEW: { value: 4, label: 'The view outside my window' },
  DESIGNERS_CHOICE: {
    value: 5,
    label: "Designer's choice! Show me what works best",
  },
  NONE: { value: 0, label: 'None' },
};

export const ADDITIONAL_ROOM_PREFERENCE_OPTIONS = {
  EXTRA_LIGHTING: { value: 1, label: 'Extra lighting' },
  EXTRA_STORAGE: { value: 2, label: 'Extra Storage' },
  EXTRA_GREENERY: { value: 3, label: 'Extra greenery' },
  KID_FRIENDLY: { value: 4, label: 'Kid friendly' },
  PET_FRIENDLY: { value: 5, label: 'Pet friendly' },
  DUAL_PURPOSE_FURNITURE: { value: 6, label: 'Dual purpose furniture' },
};

export const STREAMLINER_ROOM_PREFERENCE_OPTIONS = {
  EXTRA_STORAGE: { value: 2, label: 'Extra storage' },
  EXTRA_LIGHTING: { value: 1, label: 'Lots of lighting' },
  EXTRA_GREENERY: { value: 3, label: 'Lots of greenery' },
};

export const TV_SIZE_OPTIONS = {
  0: 'Small (less than 50")',
  1: 'Medium (50-65")',
  2: 'Large (over 65")',
};

export const LAYOUT_MAIN_FOCUS_OTHER_OPTION = 6;

export const PRICE_POINTS = ['Opening', 'Mid', 'High'];

const SERVER_PRICE_GROUP = ['L', 'M', 'H'];

const BUDGET = ['OPP', 'MPP', 'HPP'];

const SERVER_BUDGET = ['Low', 'Medium', 'High'];

export function convertedBudget(data) {
  return BUDGET[SERVER_BUDGET.indexOf(data)];
}

export function pricePointsServerDataConverter(data, dataIn) {
  if (dataIn) {
    return PRICE_POINTS[SERVER_PRICE_GROUP.indexOf(data)];
  } else {
    return SERVER_PRICE_GROUP[PRICE_POINTS.indexOf(data)];
  }
}

export const ADHERENCE_DEFAULT = -1;
export const ADHERENCE_GUIDELINE = 1;
export const ADHERENCE_PRIORITIZE = 2;
export const ADHERENCE_DO_NOT_EXCEED = 3;

export const ADHERENCE_MESSAGES = {
  [ADHERENCE_DEFAULT]: 'Not asked at time of onboarding',
  [ADHERENCE_GUIDELINE]: 'Use my budget as a guideline',
  [ADHERENCE_PRIORITIZE]: 'Prioritize quality over price',
  [ADHERENCE_DO_NOT_EXCEED]: 'Do not exceed',
};

// Store sizes for different resolutions
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1200px',
  laptopL: '1440px',
  desktop: '1920px',
  desktopL: '2560px',
};

// Store said sizes in descriptive objects
export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const REDESIGN_REQUEST_OPTIONS = {
  reason_swap: {
    label: 'Different products',
    icon: productsIcon,
    selected: false,
    hover: false,
  },
  reason_help: {
    label: 'Different layout',
    icon: layoutIcon,
    selected: false,
    hover: false,
  },
  reason_looking: {
    label: 'Design style',
    icon: styleIcon,
    selected: false,
    hover: false,
  },
  reason_incorrect: {
    label: '3D model fixes',
    icon: modelFixesIcon,
    selected: false,
    hover: false,
  },
  reason_other: {
    label: 'Other',
    icon: questionMarkIcon,
    selected: false,
    hover: false,
  },
};

export const CUSTOMER_FEEDBACK_OPTIONS = [
  { value: 'style_match', label: 'Style match' },
  { value: 'product_selection', label: 'Product selection' },
  { value: 'address_my_notes', label: 'Addressed my notes' },
  { value: 'color_choices', label: 'Color choices' },
  { value: 'furniture_layout', label: 'Furniture layout' },
  { value: 'three_d_room_model', label: '3d room model' },
];

// for use with React-Select
export const REDESIGN_PRIORITY_OPTIONS = [
  { value: 'standard', label: 'Standard turn-around' },
  { value: 'medium', label: 'Replace out of stock items (medium priority)' },
  { value: 'highest', label: 'Escalated (highest priority)' },
];

const ToolTipInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px 16px 0 16px;
`;

const UnorderedList = styled.ul`
  padding: 0;
  margin-left: 16px;
`;

const ListElement = styled.li`
  padding: 0;
`;

const ItalicText = styled.span`
  font-style: italic;
`;

const DesignToolTipContent = (
  <ToolTipInfoContainer>
    <UnorderedList key="designTooltip-1">
      <ListElement key="designTooltip-2">
        <div key="designTooltip-3">Choose the design number on which</div>
        <div key="designTooltip-4">the customer wants changes. </div>
      </ListElement>
      <ListElement key="designTooltip-5">
        <div key="designTooltip-6">For merchandise replacements,</div>
        <div key="designTooltip-7">select the highest design number</div>
        <div key="designTooltip-8">that contains the product to replace.</div>
      </ListElement>
      <ListElement key="designTooltip-9">
        <div key="designTooltip-10">
          <ItalicText key="designTooltip-11">Unknown</ItalicText> indicates that
          you don’t
        </div>
        <div key="designTooltip-12">know which design needs changes.</div>
      </ListElement>
    </UnorderedList>
  </ToolTipInfoContainer>
);

const RedesignPriorityToolTipContent = (
  <ToolTipInfoContainer>
    <div key="designTooltip-1">Redesign priority refers to the urgency</div>
    <div key="designTooltip-2">of response time.</div>
    <UnorderedList key="designTooltip-3">
      <ListElement key="designTooltip-4">
        <div key="designTooltip-5">
          <ItalicText key="designTooltip-6">Standard</ItalicText> – default{' '}
        </div>
      </ListElement>
      <ListElement key="designTooltip-7">
        <div key="designTooltip-8">
          <ItalicText key="designTooltip-9">Replace</ItalicText> out of stock
          items{' '}
        </div>
      </ListElement>
      <ListElement key="designTooltip-10">
        <div key="designTooltip-11">
          <ItalicText key="designTooltip-12">Escalated</ItalicText> – customer
          needs
        </div>
        <div key="designTooltip-13">immediate assistance</div>
      </ListElement>
    </UnorderedList>
  </ToolTipInfoContainer>
);

export const REDESIGN_REQUEST_DROPDOWNS = {
  design_number: {
    title: 'Design number',
    toolTip: DesignToolTipContent,
    currentOption: [{}],
  },
  redesign_priority: {
    title: 'Revision priority',
    toolTip: RedesignPriorityToolTipContent,
    currentOption: [{}],
  },
};

export const DropdownStyles = {
  control: (base) => ({
    ...base,
    // match with the menu
    height: '58px',
    borderRadius: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    '&:hover': {
      outline: '1px solid #4978BC;',
    },
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    border: '1px solid #4978BC;',
  }),
};

export const REDESIGN_REQUEST_ERROR_TYPES = {
  redesignReason: ' Please select at least one Revision reason.',
  helpNote: ' Please add a note.',
  requestPending: {
    header: 'Revision request pending',
    body:
      'Current space has a pending revision request.  If available, please select another space or edit the request from the designs tab.',
  },
  requestError: {
    header: 'Request not submitted!',
    body:
      "We couldn't create an assistance request in Admin Warehouse.  Wait a moment and try again. If the problem persists, notify the #tech-troubleshooting Slack channel.",
  },
  editRequestError: {
    header: 'Request not updated!',
    body:
      "We couldn't update this revision request in our warehouse.  Wait a moment and try again.  if the problem persists, notify the #tech-troubleshooting Slack channel.",
  },
};

export const WLH_SOURCE_MAP = {
  adults: AdultsSVG,
  teens: TeensSVG,
  big_kids: BigKidsSVG,
  little_kids: LittleKidsSVG,
  babies: BabiesSVG,
  cats: CatsSVG,
  dogs: DogsSVG,
  other_pets: OtherPetsSVG,
};

export const WLH_DESCRIPTOR_MAP = {
  adults: 'Adults',
  teens: 'Teens (13+)',
  big_kids: 'Big Kids (8-12)',
  little_kids: 'Little Kids (3-7)',
  babies: 'Babies (0-2)',
  cats: 'Cats',
  dogs: 'Dogs',
  other_pets: 'Other Pets',
};

export const ETA_UNKNOWN = -1;
export const ETA_ON_TARGET = 1;
export const ETA_DELAYED = 2;
export const ETA_VERY_DELAYED = 3;

export const SPACE_ETA_STATUSES = {
  [ETA_UNKNOWN]: 'Unknown',
  [ETA_ON_TARGET]: 'On Target',
  [ETA_DELAYED]: 'Delayed',
  [ETA_VERY_DELAYED]: 'Very Delayed',
};

export const DEFAULT_TURNAROUND_TIME_VALUE = '---';

export const monthNames = [
  { full: 'January', abbr: 'Jan' },
  { full: 'February', abbr: 'Feb' },
  { full: 'March', abbr: 'Mar' },
  { full: 'April', abbr: 'Apr' },
  { full: 'May', abbr: 'May' },
  { full: 'June', abbr: 'June' },
  { full: 'July', abbr: 'July' },
  { full: 'August', abbr: 'Aug' },
  { full: 'September', abbr: 'Sept' },
  { full: 'October', abbr: 'Oct' },
  { full: 'November', abbr: 'Nov' },
  { full: 'December', abbr: 'Dec' },
];

export const RENDER_DATA_TO_DISPLAY = {
  id: { label: 'ID', data: 0 },
  created_at: { label: 'Last updated', data: 0 },
  render_time: { label: 'Render time', data: '' },
  view_360: { label: '360 view', data: '' },
  empty_room: { label: 'Empty room', data: '' },
};

export const CUSTOMERS_ROOM_PREFERENCES_STR =
  'Add info about customer’s room preferences here - for example, “TV should remain on the wall it is in the photos';
export const CUSTOMERS_GENERAL_STYLE_STR =
  'Add info about customer’s general style and budget preferences - for example, “Hates brass and is budget-conscious when it comes to accessories';
export const ZERO_DOLLAR_STR = '$0';

export const NoAnswerProvidedStr = 'No answer provided';

// Even though the BE max set in place is 256, we use 257 since the util, maxTextCharactersAlert
// substracts one character value when the user hits the limit
export const MAX_TEXT_AREA_COUNT = 257;

export const tabNames = {
  customProps: 'custom_props',
  designs: 'designs',
  favoriteProps: 'favorite_props',
  orderHistory: 'order_history',
  photosAndMeasurements: 'photos_and_measurements',
  projectDetails: 'project_details',
  roomStyleColor: 'room_style_color',
  spacesHistory: 'spaces_history',
  vision: 'vision',
};
