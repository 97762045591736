import React from 'react';

export default function EditableDropdownOptions ({
  options = {}
} = {}) {
  return Object.entries(options).map(([key, value]) => (
    <option key={key} value={key}>
      {value}
    </option>
  ));
}
