import * as types from '../actions/action-types';
import spaceSpecificAPIReducer from './space-specific-api-reducers';
import {
  SPACE_SPECIFIC_API_IDENTIFIERS,
  DESIGN_SPECIFIC_API_IDENTIFIERS,
  API_ACTION_TYPES,
} from '../constants';
import get from 'lodash/get';

/*
 * Reduces information about the active space tab, and the space tab options.
 */

const buildAPIActions = () => {
  const actions = [];
  SPACE_SPECIFIC_API_IDENTIFIERS.concat(
    DESIGN_SPECIFIC_API_IDENTIFIERS
  ).forEach((identifier) => {
    API_ACTION_TYPES.forEach((type) => {
      const action = `${identifier.toUpperCase()}_${type}`;
      actions.push(types[action]);
    });
  });
  return actions;
};

const initialData = {
  activeKey: 0,
  activeID: null,
  filteredSpaces: [],
  status: null
};

const spaceDataReducer = (state = initialData, action) => {
  // handle API actions
  const API_ACTIONS = buildAPIActions();
  if (API_ACTIONS.indexOf(action.type) > -1) {
    return Object.assign({}, state, {
      [action.keys.space]: spaceSpecificAPIReducer(
        state[action.keys.space],
        action
      ),
    });
  }

  // handle regular actions
  switch (action.type) {
    case types.SET_ACTIVE_SPACE_TAB_KEY:
      return Object.assign({}, state, {
        activeKey: action.key,
      });
    case types.SET_ACTIVE_SPACE_TAB_ID:
      return Object.assign({}, state, {
        activeID: action.id,
      });
    case types.SET_FILTERED_SPACES:
      return Object.assign({}, state, {
        filteredSpaces: action.spaces.slice(),
      });
    case types.SET_CURRENT_SPACE_STATUS:
      return Object.assign({}, state, {
        status: action.status,
      });
    default:
      return state;
  }
};

export const getSpaceHistoryData = (state, spaceID) =>
  get(state, `space_data.${spaceID}.activity_log.data.results`, []);
export const getSpaceHistoryCount = (state, spaceID) =>
  get(state, `space_data.${spaceID}.activity_log.data.count`, 0);
export const getNextPageUrl = (state, spaceID) =>
  get(state, `space_data.${spaceID}.activity_log.data.next`, null);
export const getStyleCollections = (state, spaceID) =>
  get(state, `space_data.${spaceID}.style_collections.data`, {});
export const getSpaceName = (state) =>
  get(state, `space_data.filteredSpaces[0].space_type`, '');
export const getSpaceID = (state) =>
  get(state, `space_data.filteredSpaces[0].id`, '');
export const getSpaceStatus = (state) =>
  get(state, `space_data.status`);
export default spaceDataReducer;
