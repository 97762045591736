import React from 'react';
import $ from 'jquery';
import filter from 'lodash/filter';
import { Grid, Col, Panel, Row, Table, Thumbnail } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FileUploaderIcon } from '../../components/FileUploaderIcon';
import { ProductModel, StyleboardModel } from '../../models';
import { ApiConfig, NewAdminConfig } from '../../config';

export default class AssetDownloader extends React.Component {
  constructor(props) {
    super(props);
    this.UNSAFE_componentWillReceiveProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const slug = props.routeParams.slug;
    this.setState({
      styleboardModel: new StyleboardModel(),
      styleboardSlug: slug,
      productModel: new ProductModel(),
    });
    if (slug == null) {
      this.state.styleboardModel.getAllStyleboards(this.setState.bind(this));
    } else {
      this.state.styleboardModel.getStyleboardBySlug(
        slug,
        this.setState.bind(this),
      );
      this.state.styleboardModel.getAssetOptions(this.setState.bind(this));
    }
  }

  onAssetChange(asset) {
    let sbproduct;
    const sbmodel = this.state.styleboardModel;
    const sbproducts = filter(sbmodel.styleboard.products, (p) => {
      return p.product.id === asset.product;
    });
    for (sbproduct of sbproducts) {
      sbproduct.product.assets.push(asset);
    }
    this.setState({ styleboardModel: sbmodel });
  }

  onRowClick(slug) {
    this.state.styleboardModel.getStyleboardBySlug(
      slug,
      this.setState.bind(this),
    );
  }

  render() {
    const styleboard = this.state.styleboardModel.styleboard;
    const sblist = this.state.styleboardModel.all_styleboards;
    const { productModel } = this.state;
    // const last_td_style = { width: '1%', whiteSpace: 'nowrap', padding: '6px' };

    let view_result = (
      <div>
        <br />
      </div>
    );

    if (styleboard != null) {
      const style_product_lut = {};

      $.map(styleboard.products, (sbproduct, index) => {
        if (style_product_lut[sbproduct.style_assignment] != null) {
          style_product_lut[sbproduct.style_assignment].push(sbproduct);
        } else {
          style_product_lut[sbproduct.style_assignment] = [sbproduct];
        }
      });

      const stylelist = Object.keys(style_product_lut).sort();
      const colorlist = ['primary', 'success', 'info', 'warning', 'danger'];
      view_result = (
        <div>
          <h1>{styleboard.name}</h1>
          {stylelist.map((style, index) => {
            return (
              <Panel
                key={style}
                header={style}
                bsStyle={colorlist[index % colorlist.length]}
              >
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Image</th>
                      <th>
                        Assets<small>(file | status)</small>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {style_product_lut[style].map((sbproduct, productIndex) => {
                      return (
                        <tr key={sbproduct.id.toString()}>
                          <td nowrap>{sbproduct.product.name}</td>
                          <td>
                            <div style={{ width: '128px' }}>
                              <Thumbnail
                                src={productModel.getPrimaryImage(
                                  sbproduct.product,
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <table>
                              <thead>
                                <tr>
                                  <td />
                                  <td />
                                  <td />
                                </tr>
                              </thead>
                              <tbody>
                                {sbproduct.product.assets.map(
                                  (asset, assetIndex) => {
                                    return (
                                      <tr key={asset.asset}>
                                        <td key={`${asset.asset}_file`}>
                                          <a href={asset.assetfile}>
                                            {asset.asset}
                                          </a>
                                          ({asset.updated_at})
                                        </td>
                                        <td key={`${asset.asset}_status`}>
                                          <select
                                            className="status-select"
                                            onChange={this.changeStatus.bind(
                                              this,
                                              asset.pk,
                                              productIndex,
                                              assetIndex,
                                            )}
                                            value={asset.status}
                                          >
                                            {this.state.styleboardModel
                                              .status_options ? (
                                                this.state.styleboardModel.status_options.map(
                                                  (status) => {
                                                    return (
                                                      <option
                                                        key={status[1]}
                                                        value={status[1]}
                                                      >
                                                        {status[1]}
                                                      </option>
                                                    );
                                                  },
                                                )
                                              ) : null}
                                          </select>
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <FileUploaderIcon
                              key={sbproduct.product.id}
                              uploadUrl={ApiConfig.ASSETS3D}
                              slug={sbproduct.product.id}
                              onAssetChange={this.onAssetChange.bind(this)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Panel>
            );
          })}
        </div>
      );
    } else if (sblist != null) {
      // TODO: make this code reusable from styleboard.tsx
      view_result = (
        <Grid>
          <Row>
            <Col xs={3}>
              <b>Name</b>
            </Col>
            <Col xs={2}>
              <b>Email</b>
            </Col>
            <Col xs={5}>
              <b>Slug</b>
            </Col>
            <Col xs={2}>
              <b>Sponsor</b>
            </Col>
          </Row>
          {sblist.map((sbitem) => {
            return (
              <LinkContainer
                to={`${NewAdminConfig.ASSETDOWNLOADER}/${sbitem.slug}`}
                onClick={this.onRowClick.bind(this, sbitem.slug)}
              >
                <Row key={sbitem.slug}>
                  <Col xs={3}>{sbitem.name}</Col>
                  <Col xs={2}>{sbitem.user_email}</Col>
                  <Col xs={5}>{sbitem.slug}</Col>
                  <Col xs={2}>{sbitem.sponsor}</Col>
                </Row>
              </LinkContainer>
            );
          })}
        </Grid>
      );
    } else {
      view_result = <div>Loading...</div>;
    }
    return <div>{view_result}</div>;
  }

  changeStatus(assetId, productIndex, assetIndex, e) {
    let value = null;
    for (let i = 0; i < this.state.styleboardModel.status_options.length; i++) {
      if (this.state.styleboardModel.status_options[i][1] === e.target.value) {
        value = this.state.styleboardModel.status_options[i][0];
        break;
      }
    }

    this.state.styleboardModel.changeAssetStatusOrPhase(
      true,
      productIndex,
      assetIndex,
      assetId,
      value,
      e.target.value,
      this.setState.bind(this),
    );
  }

  changePhase(assetId, productIndex, assetIndex, e) {
    let value = null;
    for (let i = 0; i < this.state.styleboardModel.phase_options.length; i++) {
      if (this.state.styleboardModel.phase_options[i][1] === e.target.value) {
        value = this.state.styleboardModel.phase_options[i][0];
        break;
      }
    }

    this.state.styleboardModel.changeAssetStatusOrPhase(
      false,
      productIndex,
      assetIndex,
      assetId,
      value,
      e.target.value,
      this.setState.bind(this),
    );
  }
}
