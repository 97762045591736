import React from 'react';
import get from 'lodash/get';
import {
  Col,
  MenuItem,
  FormControl,
  ButtonToolbar,
  DropdownButton,
} from 'react-bootstrap';

import {
  ORDER_STATUS,
  ORDER_STATUS_LIST,
  DELIVERY_STATUSES,
  INVENTORY_STATUSES,
} from '../../constants';

export const OrderProcessingSearch = ({
  purchasingAgents,
  searchTerms,
  updateSearchTerm
} = {}) => {
  let purchasingAgentTitle = '--None Selected--';

  if (get(purchasingAgents, 'data.count')) {
    const agent = purchasingAgents.data.results.find(agent => agent.id === searchTerms.agent_user_id);
    if (agent) {
      purchasingAgentTitle = agent.first_name;
      if (agent.last_name) { purchasingAgentTitle += ` ${agent.last_name}`; }
    }
  }

  return (
    <div className="clear">
      <Col md={2}>
        <FormControl
          onChange={e => updateSearchTerm(e.target.name, e.target.value)}
          name="search"
          type="text"
          value={searchTerms.search}
          placeholder="Search: user, email, order number"
        />
      </Col>

      <Col md={2}>
        <ButtonToolbar>
          <DropdownButton
            title={ORDER_STATUS[searchTerms.order_status]}
            id="order_status"
            onSelect={filter => updateSearchTerm('order_status', filter)}
          >
            {ORDER_STATUS_LIST.map((key, i) => (
              <MenuItem key={i} eventKey={key}>
                {ORDER_STATUS[key]}
              </MenuItem>
            ))}
          </DropdownButton>
        </ButtonToolbar>
      </Col>

      <div style={{ float: 'right', paddingRight: '15px' }}>
        <ButtonToolbar>
          <DropdownButton
            title={DELIVERY_STATUSES[searchTerms.delivery_status]}
            id="delivery_status"
            onSelect={filter => updateSearchTerm('delivery_status', filter)}
          >
            {Object.keys(DELIVERY_STATUSES).map((key, i) => (
              <MenuItem key={i} eventKey={key}>
                {DELIVERY_STATUSES[key]}
              </MenuItem>
            ))}
          </DropdownButton>
        </ButtonToolbar>
      </div>
      <div style={{ float: 'right', paddingRight: '15px' }}>
        <ButtonToolbar>
          <DropdownButton
            title={INVENTORY_STATUSES[searchTerms.inventory_status]}
            id="inventory_status"
            onSelect={filter => updateSearchTerm('inventory_status', filter)}
          >
            {Object.keys(INVENTORY_STATUSES).map((key, i) => (
              <MenuItem key={i} eventKey={key}>
                {INVENTORY_STATUSES[key]}
              </MenuItem>
            ))}
          </DropdownButton>
        </ButtonToolbar>
      </div>

      <div style={{ float: 'right', paddingRight: '15px' }}>
        <ButtonToolbar>
          <DropdownButton
            title={purchasingAgentTitle}
            id="agent_user_id"
            onSelect={filter => updateSearchTerm('agent_user_id', filter)}
          >
            <MenuItem key={0} eventKey={''}>
              --None Selected--
            </MenuItem>

            {get(purchasingAgents, 'data.count') && purchasingAgents.data.results.map((agent, i) => (
              <MenuItem key={i + 1} eventKey={agent.id}>
                {agent.first_name} {agent.last_name}
              </MenuItem>
            ))}
          </DropdownButton>
        </ButtonToolbar>
      </div>

    </div>
  );
};
