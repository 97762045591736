import React from 'react';
import $ from 'jquery';
import cookie from 'js-cookie';
import { Grid, Col, Row, Button, Glyphicon } from 'react-bootstrap';
import { StyleboardModel } from '../models';
import { ProductList } from '../components/ProductList';
import { ApiConfig } from '../config';

export class StyleboardCopyProductsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.source_product_list = [];
    this.destination_product_list = [];
  }
  UNSAFE_componentWillMount() {
    this.setState({
      source_styleboard_model: new StyleboardModel(),
      destination_styleboard_model: new StyleboardModel(),
      source_slug: this.props.routeParams.slug,
      copying: false,
    });
  }

  sourceSlugChanged(event) {
    const slug = event.target.value;
    this.setState({ source_slug: slug });
  }

  destinationSlugChanged(event) {
    const slug = event.target.value;
    this.setState({ destination_slug: slug });
  }

  doCopy(event) {
    const url = ApiConfig.COPYSTYLEBOARDPRODUCTS;
    $.ajax({
      type: 'POST',
      url,
      dataType: 'json',
      data: {
        sourceslug: this.state.source_slug,
        destslug: this.state.destination_slug,
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        const model = this.state.destination_styleboard_model;
        model.styleboard = JSON.parse(data);
        this.setState({ destination_styleboard_model: model });
      },
    });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.source_styleboard_model.styleboard != null) {
      this.source_product_list = nextState.source_styleboard_model.styleboard.products.map(
        x => x.product,
      );
    } else {
      this.source_product_list = [];
    }
    if (nextState.destination_styleboard_model.styleboard != null) {
      this.destination_product_list = nextState.destination_styleboard_model.styleboard.products.map(
        x => x.product,
      );
    } else {
      this.destination_product_list = [];
    }
  }

  handleKeyPress(source, target) {
    if (target.charCode === 13) {
      if (source) this.submitSourceSlug();
      else this.submitDestSlug();
    }
  }

  render() {
    return (
      <div>
        <Grid>
          <Row>
            <Col md={5}>Source Styleboard</Col>
            <Col md={1} />
            <Col md={5}>Destination Styleboard</Col>
          </Row>
          <Row>
            <Col md={4}>
              <input
                type="text"
                value={this.state.source_slug}
                placeholder="Slug for source styleboard"
                onChange={this.sourceSlugChanged.bind(this)}
                onKeyPress={this.handleKeyPress.bind(this, true)}
              />
            </Col>
            <Col md={1}>
              <Button onClick={this.submitSourceSlug.bind(this)}>Search</Button>
            </Col>
            <Col md={1}>
              <Button bsSize="small" onClick={this.doCopy.bind(this)}>
                <Glyphicon glyph="arrow-right" />
                <br />
                Copy into
              </Button>
            </Col>
            <Col md={4}>
              <input
                type="text"
                value={this.state.destination_slug}
                placeholder="Search for Styleboard..."
                onChange={this.destinationSlugChanged.bind(this)}
                onKeyPress={this.handleKeyPress.bind(this, false)}
              />
            </Col>
            <Col md={1}>
              <Button onClick={this.submitDestSlug.bind(this)}>Search</Button>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <div style={{ fontSize: '80%' }}>
                <ProductList productlist={this.source_product_list} />
              </div>
            </Col>
            <Col md={1} />
            <Col md={5}>
              <div style={{ fontSize: '80%' }}>
                <ProductList productlist={this.destination_product_list} />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  submitSourceSlug() {
    this.state.source_styleboard_model.getStyleboardBySlug(
      this.state.source_slug,
      this.setState.bind(this),
    );
  }

  submitDestSlug() {
    this.state.destination_styleboard_model.getStyleboardBySlug(
      this.state.destination_slug,
      this.setState.bind(this),
    );
  }
}
