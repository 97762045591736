import styled from 'styled-components';

import { BaseGrid } from '../../../../sharedComponents/sharedStyledComponents';

export const Container = styled.div`
  box-sizing: border-box;
  margin: 0 16px;
  padding-bottom: 40px;
`;

Container.displayName = 'Container';

export const GridContainer = styled(BaseGrid)`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 18px;
`;

GridContainer.displayName = 'GridContainer';

export const GridContent = styled(BaseGrid)`
  position: relative;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '412px')};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0')};
  overflow: hidden;
`;

GridContent.displayName = 'GridContent';

export const GridImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

GridImage.displayName = 'GridImage';

export const ImageIcon = styled.img`
  padding: ${(props) => (props.padding ? props.padding : '0')};
`;

ImageIcon.displayName = 'ImageIcon';

export const GridItem = styled(BaseGrid)`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns
      ? props.gridTemplateColumns
      : 'repeat(2, max-content)'};
  align-items: center;
  color: ${(props) => (props.color ? props.color : '#000')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
`;

GridItem.displayName = 'GridItem';

export const GridButton = styled.button`
  border: 0;
  padding: ${(props) => (props.padding ? props.padding : '0')};
  color: #4978bc;
  width: fit-content;
  cursor: pointer;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns ? props.gridTemplateColumns : '1fr'};
  background: none;
`;

GridButton.displayName = 'GridButton';

export const Header = styled.h4`
  display: flex;
`;

Header.displayName = 'Header';

export const LikesDislikesDropdown = {
  control: (base) => ({
    ...base,
    height: '58px',
    borderRadius: 0,
    paddingBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    '&:hover': {
      outline: '1px solid #4978BC;',
    },
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    border: '1px solid #4978BC;',
  }),
};

export const DropdownContainer = styled.div`
  width: 177px;
  padding-bottom: 16px;
`;

DropdownContainer.displayName = 'DropdownContainer';
