import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Alert, Button } from 'react-bootstrap';
import { ApiConfig } from '../config';
import { isNullOrUndefined } from 'util';
import { dataRefresh } from '../actions/api-data-request-generator';
import ContentEditable from 'sharedComponents/ContentEditable';

export default class AboutYouInfo extends React.Component {
  state = {
    aboutYouText: '',
    isAlertVisible: false,
  };

  handleChange = (evt) => {
    this.setState({
      aboutYouText: evt.target.value,
      isAlertVisible: true,
    });
  };

  handleAboutYouNeedsToRefresh(userId, aboutYouText) {
    const { dispatch } = this.props;
    dispatch(
      dataRefresh('about_you', { user: userId, about_you: aboutYouText })
    );
  }

  handleSaveResults = () => {
    const { aboutYou, data, userId } = this.props;
    const { aboutYouText } = this.state;
    const phoneNumber = (data && data.phone) || '+10000000000';
    const isTextEnabled = (data && data.texting_enabled) || false;

    // we HAVE to POST if there isn't existing data
    // so we need to know if data exists in the first place
    if (isNullOrUndefined(data) && isNullOrUndefined(aboutYou)) {
      axios
        .post(`${ApiConfig.ABOUTYOU}?heidi=true`, {
          user: userId,
          about_you: aboutYouText,
          phone: phoneNumber,
          texting_enabled: isTextEnabled,
        })
        .then(() => {
          this.handleAboutYouNeedsToRefresh(userId, aboutYouText);
        });
    } else {
      if (aboutYou !== aboutYouText) {
        axios
          .patch(`${ApiConfig.ABOUTYOU}/${userId}?heidi=true`, {
            about_you: aboutYouText,
            phone: phoneNumber,
            texting_enabled: isTextEnabled,
          })
          .then(() => {
            this.handleAboutYouNeedsToRefresh(userId, aboutYouText);
          });
      }
    }
    this.setState({ isAlertVisible: false });
  };

  handleDismissAlert = () => {
    const { aboutYou } = this.props;
    this.setState({
      aboutYouText: aboutYou,
      isAlertVisible: false,
    });
  };

  componentDidUpdate(prevProps) {
    const { aboutYou } = this.props;

    if (aboutYou && prevProps.aboutYou !== aboutYou)
      this.setState({ aboutYouText: aboutYou });
  }

  render() {
    const { aboutYouText, isAlertVisible } = this.state;

    return (
      <Container>
        {isAlertVisible && (
          <Alert bsStyle="warning" onDismiss={this.handleDismissAlert}>
            <h4>Save About You Info</h4>
            <p>
              Save About You Info - if you don’t save or refresh changes will be
              lost!
            </p>
            <p>
              <Button onClick={this.handleDismissAlert}>Cancel</Button>
              <span> or </span>
              <Button onClick={this.handleSaveResults}>Save</Button>
            </p>
          </Alert>
        )}
        <Title>
          Tell us a bit about yourself and what brings you to Modsy.
        </Title>
        <ContentEditable html={aboutYouText} onChange={this.handleChange} />
      </Container>
    );
  }
}

const Container = styled.div`
  margin: 24px 0;
  padding-left: 8px;
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 500;
`;
