import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  PrimaryCta,
  SecondaryCta,
} from '../../../../../sharedComponents/button';
import newLinkIcon from '../../../../../images/newLinkIcon.svg';
import { ApiConfig } from '../../../../../config';
import { patchData } from '../../../../../actions/api-patch-request-generator';

export default function InspirationLinks(props) {
  const {
    activeSpaceID,
    dispatch,
    handleChange,
    inspiration_links,
    styleboardInfoId,
  } = props;
  const [inspirationData, setInspirationData] = useState({});
  useEffect(() => {
    const formattedInspirationLinks = () => {
      let inspirationLinksArray =
        (inspiration_links &&
          inspiration_links.length > 0 &&
          inspiration_links.split(',')) ||
        [];
      let formattedLinks = {};

      for (let key = 0; key < inspirationLinksArray.length; key++) {
        formattedLinks = Object.assign(formattedLinks, {
          [key]: {
            link: inspirationLinksArray[key],
            editedLink: inspirationLinksArray[key],
            isEditable: false,
          },
        });
      }

      return formattedLinks;
    };

    const formattedLinks = formattedInspirationLinks();
    setInspirationData(formattedLinks);
  }, [inspiration_links]);

  const handleSaveResultsToWarehouse = (links) => {
    dispatch(
      patchData(
        `${ApiConfig.SPACE_INFO}/${styleboardInfoId}?heidi=true`,
        'space_info_change',
        {
          data: { inspiration_links: links },
          params: { space_id: activeSpaceID, ppo: 1 },
        }
      )
    );
    // Make sure inspiration links are included when the global save is called
    // otherwise the string value will be empty when other values are changed
    handleChange(links, 'inspiration_links');
  };

  const addNewInspirationLink = () => {
    const linkKeys = Object.keys(inspirationData).map((value) => value);
    // Set a default index value in case there are no linkKeys
    let newIndexValue = 0;

    // Check if there are any values in the array
    if (linkKeys.length > 0) {
      // If there are get the highest key value and add one
      newIndexValue = Number(linkKeys.slice(-1)[0]) + 1;
    }

    // Now lets update the data, remembering not to mutate the state
    const newLink = {
      ...inspirationData,
      [newIndexValue]: { link: '', isEditable: true },
    };
    setInspirationData(newLink);
  };

  const handleInspirationLinkChange = (event, key) => {
    // update the current hyperlink as the user types
    setInspirationData({
      ...inspirationData,
      [key]: { ...inspirationData[key], editedLink: event.target.value },
    });
  };

  const processSavingAndDeletingLinks = (key, isDeleteAction = false) => {
    const copyOfInspirationData = { ...inspirationData };
    if (isDeleteAction) {
      delete copyOfInspirationData[key];
    } else {
      copyOfInspirationData[key].link = copyOfInspirationData[key].editedLink;
      copyOfInspirationData[key].isEditable = false;
    }

    const joinedLinksStr = Object.keys(copyOfInspirationData)
      .filter((key) => copyOfInspirationData[key].link !== '')
      .map((key) => copyOfInspirationData[key].link)
      .join(',');

    handleSaveResultsToWarehouse(joinedLinksStr);

    setInspirationData(copyOfInspirationData);
  };

  const saveInspirationLink = (key) => {
    processSavingAndDeletingLinks(key, false);
  };

  const editInspirationLink = (key) => {
    const copyOfInspirationData = { ...inspirationData };
    copyOfInspirationData[key].isEditable = !copyOfInspirationData[key]
      .isEditable;

    if (!copyOfInspirationData[key].isEditable) {
      copyOfInspirationData[key].editedLink = copyOfInspirationData[key].link;
    }
    if (
      copyOfInspirationData[key].link === '' &&
      copyOfInspirationData[key].editedLink === ''
    ) {
      delete copyOfInspirationData[key];
    }
    setInspirationData(copyOfInspirationData);
  };

  const deleteInspirationLink = (key) => {
    if (window.confirm('Are you sure you want to delete this link?')) {
      processSavingAndDeletingLinks(key, true);
    }
  };

  return (
    <Container>
      {inspirationData &&
        Object.keys(inspirationData).map((key, index) => {
          const editedLink = inspirationData[key].editedLink;
          const link = inspirationData[key].link;
          const isEditable = inspirationData[key].isEditable;
          const prefix = '//';
          let absoluteLink = link.trim();

          // if the link looks like a relative link, then make it absolute
          if (!absoluteLink.includes(prefix)) {
            absoluteLink = prefix.concat(absoluteLink);
          }

          return (
            <LinksContainer key={index}>
              <EditableLinkContainer>
                {isEditable ? (
                  <EditLinkTextArea
                    value={isEditable ? editedLink : link}
                    onChange={(event) =>
                      handleInspirationLinkChange(event, key)
                    }
                  />
                ) : (
                  <EditLink
                    href={absoluteLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {link}
                    <Image src={newLinkIcon} alt="link icon" />
                  </EditLink>
                )}
              </EditableLinkContainer>
              <ButtonsContainer>
                <PrimaryCta
                  width="64px"
                  buttonHeight="24px"
                  gc={isEditable ? 2 : 1}
                  gr={1}
                  onClick={() =>
                    isEditable
                      ? saveInspirationLink(key)
                      : editInspirationLink(key)
                  }
                >
                  {isEditable ? 'Save' : 'Edit'}
                </PrimaryCta>
                <SecondaryCta
                  width="64px"
                  buttonHeight="24px"
                  gc={isEditable ? 1 : 2}
                  gr={1}
                  onClick={() =>
                    isEditable
                      ? editInspirationLink(key)
                      : deleteInspirationLink(key)
                  }
                >
                  {isEditable ? 'Cancel' : 'Delete'}
                </SecondaryCta>
              </ButtonsContainer>
            </LinksContainer>
          );
        })}
      <EditableLinkContainer>
        <SecondaryCta
          width="auto"
          buttonHeight="30px"
          onClick={addNewInspirationLink}
        >
          Add new inspiration link
        </SecondaryCta>
      </EditableLinkContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 50px;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-content: end;
  grid-template-columns: repeat(2, 1fr);
`;

const EditLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

const EditableLinkContainer = styled.div`
  display: flex;
  flex: 1;
`;

const EditLinkTextArea = styled.textarea`
  resize: none;
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 26px;

  &:focus {
    outline: none !important;
    border-color: #719ece;
    box-shadow: 0 0 5px #719ece;
  }
`;

const Image = styled.img`
  padding-left: 8px;
`;

const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  padding: 8px 8px 8px 0;
  margin-bottom: 8px;
`;
