import React from 'react';
import styled from 'styled-components';

import { SecondaryCta } from '../../sharedComponents/button';
import {
  Container,
  QuoteSymbol,
} from '../../sharedComponents/sharedStyledComponents';
import {
  device,
  REDESIGN_REQUEST_OPTIONS /* STANZA_URL */,
} from '../../constants';
import RequestStatus from './RequestStatus';
import {
  setCurrentDesignNumber,
  setCurrentDesignsReasons,
  setCurrentDesignsStyleNote,
  setIsRequestEditable,
  setRedesignModalRequestDisplay,
  setRedesignRequestID,
} from '../../actions/redesign-request-modal-action';
/* import { LinkIcon } from '../../shared-ui-components.js/icons'; */

const ReasonsContainer = styled.div``;

/* const IconWrapper = styled.div`
  display: flex;
  padding-left: 8px;
`; */

const StanzaIdContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 16px 24px;
`;

const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const NoteContentContainer = styled.div`
  position: relative;
`;

const TextContainer = styled.div`
  position: relative;
  height: auto;
  min-height: 30px;
  padding-left: 24px;
`;

const SpanText = styled.span`
  padding: ${(props) => props.padding};
  font-size: 12px;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
`;

const Image = styled.img`
  z-index: 4;
  padding: ${(props) => (props.padding ? props.padding : '0 8px 16px 0')};
`;

const ButtonContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  padding-right: 8px;
  @media ${device.laptopM} {
    margin-bottom: 8px;
  }
`;

const Button = styled(SecondaryCta)`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 38px;
  border: 1px solid #41538d;
  box-sizing: border-box;
  background: transparent;
`;

const EditRequestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  padding-left: 24px;
`;

const Text = styled.div`
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  white-space: pre-wrap;
`;

export default class DesignRedesignRequest extends React.Component {
  getSelectedRedesignRequestOptions = () => {
    const { redesignRequest } = this.props;
    // Ensure we don't mutate the state.  Can't use object.assign because that only
    // creates a shallow copy
    const redesignRequestOptions = JSON.parse(
      JSON.stringify(REDESIGN_REQUEST_OPTIONS)
    );

    const selectedRedesignValues = Object.keys(REDESIGN_REQUEST_OPTIONS).map(
      (value) => {
        redesignRequestOptions[value].selected = redesignRequest[0][value];
        return Object.assign({}, { [value]: redesignRequestOptions[value] });
      }
    );
    return Object.assign({}, ...selectedRedesignValues);
  };

  handleDispatchingData = (assistanceID) => {
    const { dispatch, redesignRequest } = this.props;
    const dataToDispatch = [
      setRedesignModalRequestDisplay(),
      setCurrentDesignNumber(redesignRequest.design_id),
      setIsRequestEditable(true),
      setCurrentDesignsStyleNote(redesignRequest[0].comment),
      setCurrentDesignsReasons(this.getSelectedRedesignRequestOptions()),
      setRedesignRequestID(assistanceID),
    ];

    for (let i = 0; i < dataToDispatch.length; i++) {
      dispatch(dataToDispatch[i]);
    }
  };

  render() {
    const { redesignRequest } = this.props;
    const assistanceID = redesignRequest[0] && redesignRequest[0].id;
    const redesignCustomerNote =
      redesignRequest[0] && redesignRequest[0].comment;
    return (
      <Container backgroundColor="#EFF3F9">
        <ReasonsContainer>
          {redesignRequest.length > 0 && (
            <ImagesContainer>
              {Object.keys(REDESIGN_REQUEST_OPTIONS).map((value, index) => {
                let requestOption = REDESIGN_REQUEST_OPTIONS[value];
                return (
                  redesignRequest[0][value] && (
                    <SpanText key={index}>
                      <Image src={requestOption.icon} />
                      {requestOption.label}
                    </SpanText>
                  )
                );
              })}
            </ImagesContainer>
          )}
        </ReasonsContainer>
        <NoteContentContainer>
          <QuoteSymbol color="#6A93C5" padding="0" />
          <TextContainer>
            {/* We use dangerously set inner html in the event that the data returns a <br> in the future.  That
              way we can just format the data as HTML */}
            <Text padding="20px 0 16px 0">
              <div dangerouslySetInnerHTML={{ __html: redesignCustomerNote }} />
            </Text>
          </TextContainer>
        </NoteContentContainer>
        <StanzaIdContainer>
          <Text color="#8B8B8B">
            Related Stanza redesign id{' '}
            <SpanText color="#4978BC">{assistanceID}</SpanText>
          </Text>
          {/* temp disable link until BE is ready */}
          {/* <a target = '_blank' href = { `${ STANZA_URL }${ redesignRequest.stanza_record_id ? redesignRequest.stanza_record_id : '' }` }>
                <IconWrapper>
                  <LinkIcon fill = '#4978BC' />
                </IconWrapper>
              </a> */}
        </StanzaIdContainer>
        <EditRequestContainer>
          {redesignRequest[0] && redesignRequest[0].addressed === 0 && (
            <ButtonContainer
              onClick={() => {
                this.handleDispatchingData(assistanceID);
              }}
            >
              <Button children={'Edit Design Request'} />
            </ButtonContainer>
          )}
          <RequestStatus requestData={redesignRequest} />
        </EditRequestContainer>
      </Container>
    );
  }
}
