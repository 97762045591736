import React, { useEffect, useState, createRef } from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';

import Buttons from '../../../sharedComponents/buttons';
import RadioButton from '../../../sharedComponents/RadioButton';
import closeIcon from '../../../images/close_icon.svg';
import warningIcon from '../../../images/warning-icon.svg';

import { device } from '../../../constants';

const RequestButtonsStr = [
  { label: 'Cancel', type: 'secondary' },
  { label: 'Save Changes', type: 'primary' },
];

const CREATE_FEEDBACK_COPY = 'Add layout feedback';
const EDIT_FEEDBACK_COPY = 'Edit layout feedback';

export default function LayoutFeedbackOverlay(props) {
  return ReactDOM.createPortal(
    <LayoutFeedbackOverlayContent {...props} />,
    document.body
  );
}

const LayoutFeedbackOverlayContent = ({
  sendLayoutFeedback = () => {},
  onClose = () => {},
  currentLayoutFeedback = '',
  designName = '',
  selectedLayoutName = '',
  layoutSelected = false,
}) => {
  const [feedback, setFeedback] = useState(currentLayoutFeedback);
  const [selected, setSelected] = useState(layoutSelected || undefined);
  const [errors, setErrors] = useState({});
  let modalRef = createRef();

  const handleClickOutside = (event) => {
    if (
      modalRef &&
      modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    // this is used to get the slide-in transition
    if (modalRef && modalRef.current) modalRef.current.style.transform = 'none';
  }, [modalRef]);

  const onSave = () => {
    if (selected || selected === false) {
      sendLayoutFeedback(selected, feedback);
    } else {
      // if the user hasn't selected to mark this layout as selected
      // display error and don't submit
      setErrors({ ...errors, select: 'Please select one.' });
    }
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <Darken>
        <ModalContainer ref={modalRef}>
          <CloseButton onClick={onClose} />
          <Header>
            {currentLayoutFeedback ? EDIT_FEEDBACK_COPY : CREATE_FEEDBACK_COPY}
          </Header>
          <SubHeader>{`Add any feedback from the customer for ${designName}.`}</SubHeader>
          <Question>
            Do you want to mark this layout as chosen?{' '}
            {errors.select && <ErrorSpan>{errors.select}</ErrorSpan>}
          </Question>
          <Column>
            <Row>
              <RadioButton
                selected={selected}
                id="primary-radio"
                onClick={() => {
                  setErrors({ ...errors, select: '' });
                  setSelected(true);
                }}
              >
                Yes, mark layout as chosen
              </RadioButton>
              <RadioButton
                id="secondary-radio"
                selected={selected === false}
                onClick={() => {
                  setErrors({ ...errors, select: '' });
                  setSelected(false);
                }}
              >
                No, thanks
              </RadioButton>
            </Row>
            {selected && !layoutSelected && (
              <Warning>
                <Image src={warningIcon} />
                {`Marking ${designName} as chosen will override ${selectedLayoutName} from being chosen.`}
              </Warning>
            )}
          </Column>
          <Question>
            Layout feedback (copy customer request or enter your notes)
          </Question>
          <BottomFlowItems>
            <TextAreaContainer>
              <TextArea
                defaultValue={currentLayoutFeedback}
                placeholder="This layout works best but please make sure there’s enough walkway space between the door and the sofa."
                onChange={(evt) => setFeedback(evt.target.value)}
              />
            </TextAreaContainer>
            <RequestButtonContainer>
              {RequestButtonsStr.map((value, index) => {
                return (
                  <Buttons
                    id={
                      value.type === 'secondary'
                        ? 'secondary-cta'
                        : 'primary-cta'
                    }
                    key={index}
                    children={value.label}
                    onClick={value.type === 'secondary' ? onClose : onSave}
                    type={value.type}
                    mr={value.type === 'secondary' && '24px'}
                  />
                );
              })}
            </RequestButtonContainer>
          </BottomFlowItems>
        </ModalContainer>
      </Darken>
      <Overlay />
    </React.Fragment>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  pointer-events: none;
  height: 100%;
  background: #000;
  z-index: 9;
  top: 0;
  left: 0;
  opacity: 0.5;
  transition: 0.5s;
`;

const Darken = styled.div`
  position: fixed;
  top: 0;
  right: 0px;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.4);

  z-index: 10;
`;

const ModalContainer = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: 100%;
  max-width: 680px;
  min-width: 320px;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0px;
  padding: 60px;
  z-index: 10;
  transform: translateX(680px);
  transition: all 0.3s linear;

  background: #ffffff;

  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12),
    0px 24px 38px rgba(0, 0, 0, 0.14);

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const Header = styled.div`
  font-family: 'proxima-nova', sans-serif;
  font-size: 44px;
  line-height: 44px !important;
  letter-spacing: -0.669041px;
  color: #282727;
  font-weight: 500 !important;
  padding-bottom: 12px;
`;

const SubHeader = styled.div`
  font-size: 16px;
  line-height: 24px;

  margin-bottom: 40px;
`;

const Question = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  margin-bottom: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 32px;
`;

const TextAreaContainer = styled.div`
  flex: 1;
  margin-bottom: 32px;
`;

const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  padding: 24px;
  right: 0;
  top: 0;
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  background-color: #fff;
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: vertical;
  border-radius: 4px;
  border-color: #ccc;
  padding: 8px;
  height: 100%;
  min-height: 100px;

  &::placeholder {
    font-style: italic;
    color: #c4c4c4;
  }

  &:focus {
    outline: none !important;
    border-color: #719ece;
    box-shadow: 0 0 5px #719ece;
  }
`;

const BottomFlowItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RequestButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 56px;
  position: relative;
`;

const ErrorSpan = styled.span`
  margin-left: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;

  color: #e8371c;
`;

const Warning = styled.div`
  font-size: 12px;
  line-height: 14px;

  margin-top: 16px;
`;

const Image = styled.img`
  margin-right: 8px;
`;
