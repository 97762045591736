import { combineReducers } from 'redux';
// reducers
import spaceDataReducer from './space-data-reducer';
import activeDesignReducer from './active-design-reducer';
import userDataReducer from './user-data-reducer';
import apiDataReducerGenerator from './api-data-reducer-generator';
import editRequestReducer from './edit-request-reducer';
import favoritesListReducer from './favorites-list-reducer';
import redesignRequestModalReducer from './redesign-request-modal-reducer';
import likeReasonsReducer from './like-reasons-reducer';
import userAuthReducer from './user-auth-reducer';
/* STEPS TO ADD GET API CALL (USER-SPECIFIC OR SPACE-SPECIFIC)
 * 1) add identifier to constants/<TYPE> (i.e. USER_SPECIFIC_API_IDENTIFIERS, etc.)
 * 2) dispatch API action fetchDataIfNeeded() in container component (see declaration)
 * 3) add action constants <strong>following naming convention</strong> !important;
 */

/* STEPS TO ADD EDIT API CALL
 * 1) add identifier to constants/EDIT_REQUEST_IDENTIFIERS
 * 2) dispatch API action patchData() in container component (see declaration)
 * 3) add action constants <strong>following naming convention</strong> !important;
 */

// combine reducers
const reducers = combineReducers({
  redesign_request_modal: redesignRequestModalReducer,
  favorites: favoritesListReducer,
  like_reasons: likeReasonsReducer,
  space_data: spaceDataReducer,
  active_design: activeDesignReducer,
  user_data: userDataReducer,
  users: apiDataReducerGenerator('users'),
  sources: apiDataReducerGenerator('sources'),
  tags: apiDataReducerGenerator('tags'),
  get_orders: apiDataReducerGenerator('get_orders'),
  get_order_list: apiDataReducerGenerator('get_order_list'),
  order_details: apiDataReducerGenerator('order_details'),
  order_history: apiDataReducerGenerator('order_history'),
  order_shipping_history: apiDataReducerGenerator('order_shipping_history'),
  order_source_discounts: apiDataReducerGenerator('order_source_discounts'),
  purchasing_agents: apiDataReducerGenerator('purchasing_agents'),
  user_auth: userAuthReducer,
  users_designers: apiDataReducerGenerator('users_designers'),
  users_stylists: apiDataReducerGenerator('users_stylists'),
  tag_categories: apiDataReducerGenerator('tag_categories'),
  space_custom_prop_product_types: apiDataReducerGenerator(
    'space_custom_prop_product_types'
  ),
  edit_requests: editRequestReducer,
});

export default reducers;
