import React from 'react';
import styled from 'styled-components';
import OrderProcessingScrapingStatusIcon from './OrderProcessingScrapingStatusIcon';

// Assuming status null for not started, 1 for processed and 0 for failed
export default function OrderProcessingScrapingStatus(props) {
  let status = props.status;
  let message = props.message;
  return (
    <StatusContainer status={status}>
      <p>
        Scraping{' '}
        {status.includes('finished')
          ? 'successful'
          : status.includes('in_progress')
          ? 'processing'
          : status}
      </p>
      <StatusTooltip>
        <span>Scraping processing</span>
        Please wait for all orders to finish processing before making edits
      </StatusTooltip>
      <OrderProcessingScrapingStatusIcon status={status} message={message} />
    </StatusContainer>
  );
}

const StatusTooltip = styled.div`
  position: absolute;
  top: -85px;
  right: 0;
  background: #fff;
  color: #41434a;
  font-size: 12px;
  padding: 5px 10px;
  min-width: 200px;
  display: none;

  span {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
  }
`;

const StatusContainer = styled.div`
  color: ${({ status }) => (status === 'finished' ? '#067039' : '#ef4d21')};
  display: flex;
  font-size: 12px;
  margin: auto 0px auto auto;
  align-items: center;
  position: relative;

  p {
    font-size: 13px;
    margin: 0px 5px;
    width: max-content;
  }

  &:hover ${StatusTooltip} {
    display: block;
  }
`;
