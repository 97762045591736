import React from 'react';
import { Row, OverlayTrigger } from 'react-bootstrap';
import ImageThumbnail from '../ImageThumbnail';
import { flagTooltips } from '../../tooltips';

const RenderThumbnail = ({ index, image, open }) => {
  const updateDate = new Date(image.updated_at);
  let flagClass = 'empty';
  if (image.view_360) {
    flagClass = 'flag-360';
  } else if (image.empty_room) {
    flagClass = 'flag-empty-room';
  }

  return (
    <Row>
      <OverlayTrigger placement="top" overlay={flagTooltips[flagClass]}>
        <div className={flagClass} />
      </OverlayTrigger>
      <ImageThumbnail
        handleClick={() => open(index)}
        href="#"
        src={image.imagefile}
        alt="user-image"
      >
        <div key="footer" className="center-text thumbnail-footer">
          <strong>Render {index + 1}:</strong>
          {updateDate.toLocaleString()}
        </div>
      </ImageThumbnail>
    </Row>
  );
};

export default RenderThumbnail;
