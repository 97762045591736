import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import moreInfoIcon from '../../images/info_icon.svg';
import { DropdownStyles } from '../../constants';
import Tooltips from '../../sharedComponents/Tooltips';

const DropdownsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  height: auto;
  width: 100%;
  margin-bottom: 32px;
`;

const DropdownGrid = styled.div`
  display: grid;
`;

const Text = styled.div`
  font-family: graphik;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 16px;
  font-weight: 500 !important;
`;

export default function RedesignNumberAndPriority(props) {
  const { currentSpaceData, onSelectChange } = props;
  return (
    <DropdownsContainer>
      {
        Object.keys(currentSpaceData.dropdowns).map((key, index) => {
          let dropdown = currentSpaceData.dropdowns[key];
          return (
        <DropdownGrid key={index} >
          <Text>
            <span>{dropdown.title}
              <Tooltips content={dropdown.toolTip} icon={moreInfoIcon} toolTipClassName={dropdown} alt='more info icon' margin='0 0 0 8px'  />             
            </span>
          </Text>

          <Select
            key={dropdown}
            value={dropdown.currentOption}
            onChange={(event) => onSelectChange(event, key)}
            options={dropdown.options}
            placeholder={dropdown.currentOption} 
            styles={DropdownStyles}
          />
        </DropdownGrid>
          )
        })
      }
    </DropdownsContainer>
  )
}