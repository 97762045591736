import React from 'react';
import styled from 'styled-components';

export default function ProjectSummaryField ({
  header = '',
  children = null
} = {}) {
  return (
  <ProjectSummaryFieldWrapper>
    <Header>{header}</Header>

    <Value>{children}</Value>
  </ProjectSummaryFieldWrapper>
  );
}

const Header = styled.h6`
  max-width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 10px;
`;
Header.displayName = 'Header';

const Value = styled.div`
  font-size: 14px;
  line-height: 18px;
`;
Value.displayName = 'Value';

const ProjectSummaryFieldWrapper = styled.div`
  margin-bottom: 10px;
`;
