import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import RedesignReasons from './RedesignReasons';
import Buttons from '../../sharedComponents/buttons';
import RedesignRoomInfo from './RedesignRoomInfo';
import closeIcon from '../../images/close_icon.svg';
import errorIcon from '../../images/error_icon.svg';
import {
  setCurrentDesignNumber,
  setRedesignModalRequestDisplay,
  setCurrentDesignsReasons,
  setCurrentDesignsStyleNote,
  setIsRequestEditable,
} from '../../actions/redesign-request-modal-action';
import RedesignNumberAndPriority from './RedesignNumberAndPriority';
import { RedesignSuccessModal } from './RedesignRequestModals';
import {
  device,
  REDESIGN_REQUEST_ERROR_TYPES,
  REDESIGN_REQUEST_OPTIONS,
} from '../../constants';

const GlobalStyle = createGlobalStyle`
	body {
		overflow: ${(props) => (props.bShowRedesignRequestModal ? 'hidden' : 'scroll')};
	}
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  pointer-events: none;
  height: 100%;
  background: #000;
  z-index: 9;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.bShowRedesignRequestModal ? '0.5' : '0')};
  transition: 0.5s;
`;

const ModalContainer = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: 100%;
  max-width: 680px;
  min-width: 320px;
  background: #fff;
  position: fixed;
  top: 0;
  right: -680px;
  padding: 60px;
  z-index: 10;
  transform: ${(props) =>
    props.bShowRedesignRequestModal && 'translate(-680px)'};
  transition: all 0.3s linear;

  @media ${device.tablet} {
    max-width: ${(props) => props.bShowRedesignRequestModal && '100%'};
  }
`;

const RedesignHeader = styled.div`
  font-family: 'proxima-nova', sans-serif;
  font-size: 44px;
  line-height: 44px !important;
  letter-spacing: -0.669041px;
  color: #282727;
  font-weight: 500 !important;
  padding-bottom: 22px;
`;

const Image = styled.img`
  padding: 0 16px;
`;

const Text = styled.div`
  font-family: graphik;
  color: ${(props) => (props.color ? props.color : '000')};
  cursor: ${(props) => (props.cursor ? 'pointer' : 'auto')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '8px'};
  font-weight: ${(props) => props.semiBold && '500 !important'};
`;

const SpanText = styled.span`
  color: #e8371c;
  font-size: 14px;
`;

const TextAreaContainer = styled.div`
  flex: 1;
  margin-bottom: 24px;
`;

const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  padding: 24px;
  right: 0;
  top: 0;
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  background-color: #fff;
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: vertical;
  border-radius: 4px;
  border-color: #ccc;
  padding: 8px;
  height: 100%;
  min-height: 100px;

  &::placeholder {
    font-style: italic;
    color: #c4c4c4;
  }

  &:focus {
    outline: none !important;
    border-color: #719ece;
    box-shadow: 0 0 5px #719ece;
  }
`;

const BottomFlowItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RequestButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 56px;
  position: relative;
`;

const ErrorContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: auto;
  width: auto;
`;

const CANCEL_STR = 'Cancel';
const CREATE_REQUEST_STR = 'Create Request';
const EDIT_REQUEST_STR = 'Save Changes';

const RequestButtonsStr = [
  { label: CANCEL_STR, type: 'secondary' },
  { label: CREATE_REQUEST_STR, type: 'primary' },
];

export default class RedesignRequest extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  UNSAFE_componentWillMount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { bShowRedesignRequestModal } = this.props;
    if (
      bShowRedesignRequestModal &&
      this.redesignRequestModal &&
      !this.redesignRequestModal.contains(event.target)
    ) {
      this.handleClosingModal();
    }
  };

  handleClosingModal = () => {
    const { dispatch } = this.props;
    dispatch(setRedesignModalRequestDisplay());
    dispatch(setCurrentDesignNumber(null));
    dispatch(setCurrentDesignsReasons(REDESIGN_REQUEST_OPTIONS));
    dispatch(setCurrentDesignsStyleNote(''));
    dispatch(setIsRequestEditable(false));
  };

  errorMessage = (errorType) => {
    return (
      <ErrorContainer>
        <Image src={errorIcon} alt="error icon" />
        <TextAreaContainer>
          <Text color="#E8371C" fontSize="24px" lineHeight="24px" semiBold>
            {errorType === 'error'
              ? REDESIGN_REQUEST_ERROR_TYPES.requestError.header
              : REDESIGN_REQUEST_ERROR_TYPES.requestPending.header}
          </Text>
          <Text color="#E8371C" fontSize="18px" lineHeight="24px">
            {errorType === 'error'
              ? REDESIGN_REQUEST_ERROR_TYPES.requestError.body
              : REDESIGN_REQUEST_ERROR_TYPES.requestPending.body}
          </Text>
        </TextAreaContainer>
      </ErrorContainer>
    );
  };

  render() {
    const {
      bShowRedesignRequestModal,
      currentSpaceData,
      designOption,
      dispatch,
      fetchSelectedSpaceData,
      getIsRedesignRequestPending,
      error,
      handleNoteChange,
      handleIsAReasonSelected,
      handleRedesignReasonsDisplay,
      isRequestEditable,
      onSelectChange,
      priorityOption,
      sendAssistanceRequest,
      showModal,
      warning,
    } = this.props;

    const CREATE_REVISION_STR = 'Create a Revision Request';
    const EDIT_REVISION_STR = 'Edit a Revision Request';
    const helpNote = currentSpaceData.helpNote;
    const redesignRequestOptions = currentSpaceData.redesignRequestOptions;

    return (
      <React.Fragment>
        <GlobalStyle bShowRedesignRequestModal={bShowRedesignRequestModal} />
        <ModalContainer
          bShowRedesignRequestModal={bShowRedesignRequestModal}
          ref={(el) => (this.redesignRequestModal = el)}
        >
          {showModal && (
            <RedesignSuccessModal
              text={'Creating a ticket in Workflow'}
            />
          )}
          <CloseButton onClick={this.handleClosingModal} />
          <RedesignHeader>
            {isRequestEditable ? EDIT_REVISION_STR : CREATE_REVISION_STR}
          </RedesignHeader>
          {!error && (
            <Text>
              Requests created here will be added to the warehouse for this room
              number, which will then create a corresponding Stanza ticket.
            </Text>
          )}
          <RedesignRoomInfo
            bShowRedesignRequestModal={bShowRedesignRequestModal}
            currentSpaceData={currentSpaceData}
            dispatch={dispatch}
            fetchSelectedSpaceData={fetchSelectedSpaceData}
          />
          <Text fontSize="14px" semiBold>
            Revision reasons
            {warning && !handleIsAReasonSelected() && (
              <SpanText>{REDESIGN_REQUEST_ERROR_TYPES.redesignReason}</SpanText>
            )}
          </Text>
          <RedesignReasons
            redesignRequestOptions={redesignRequestOptions}
            handleRedesignReasonsDisplay={handleRedesignReasonsDisplay}
          />
          <Text fontSize="14px" semiBold>
            Revision details (copy customer request or enter your notes)
            {warning && helpNote === '' && (
              <SpanText>{REDESIGN_REQUEST_ERROR_TYPES.helpNote}</SpanText>
            )}
          </Text>
          <BottomFlowItems>
            <TextAreaContainer>
              <TextArea
                value={helpNote}
                name="helpNote"
                ref={(el) => (this.internalNotes = el)}
                placeholder="* Move reading nook closer to door&#10;* Couch in charcoal&#10;* Art is too expensive"
                onChange={handleNoteChange}
              />
            </TextAreaContainer>
            <RedesignNumberAndPriority
              designOption={designOption}
              currentSpaceData={currentSpaceData}
              onSelectChange={onSelectChange}
              priorityOption={priorityOption}
            />
            {!isRequestEditable &&
              getIsRedesignRequestPending() &&
              this.errorMessage('pending')}
            {error &&
              handleIsAReasonSelected() &&
              helpNote !== '' &&
              this.errorMessage()}
            <RequestButtonContainer>
              {RequestButtonsStr.map((value, index) => {
                let buttonLabel = value.label;
                const buttonType = value.type;
                if (buttonLabel === CREATE_REQUEST_STR && isRequestEditable) {
                  buttonLabel = EDIT_REQUEST_STR;
                }
                return (
                  <Buttons
                    key={index}
                    children={buttonLabel}
                    onClick={
                      buttonType === 'secondary'
                        ? this.handleClosingModal
                        : sendAssistanceRequest
                    }
                    type={buttonType}
                    mr={buttonType === 'secondary' && '24px'}
                  />
                );
              })}
            </RequestButtonContainer>
          </BottomFlowItems>
        </ModalContainer>
        <Overlay bShowRedesignRequestModal={bShowRedesignRequestModal} />
      </React.Fragment>
    );
  }
}
