import React from 'react';
import styled from 'styled-components';
import { Glyphicon } from 'react-bootstrap';

import ShipmentRow from './OrderProcessingShipmentRow';

export default function Shipments ({
  dirtyFields = {},
  item = {},
  updateOrderItemDetails = () => {},
  disabled=false
}) {

  // Intentionally omitting id from destructuring to retain readibility/context
  const { shipments } = item;

  /**
   *  Creates a blank shipment and adds it to the OrderItem for submission. 
   *  tempId is used for local tracking until the DB assigns an id.
   */
  const handleAddShipment = () => {
    const createdAt = new Date();
    const tempId = `temp-${createdAt.getTime()}`;
    const newShipment = {
      tempId,
      itemId: item.id,
      tracking_number: ''
    };

    updateOrderItemDetails('shipments', [...shipments, newShipment], item.id, { fieldSubKey: tempId });
  };

  /**
   *  This method is only to be used for shipments that were never
   *  sent to the API to be created in the DB, and exist only in
   *  memory on the client.
   *  For shipment deletion when the shipment exists in the DB, we must
   *  use an update (since the BE uses a soft delete).
   */
  const handleRemoveShipment = (tempId) => {
    const filteredShipments = shipments.slice().filter((shipment) => {
      return shipment.tempId !== tempId;
    });

    updateOrderItemDetails('shipments', filteredShipments, item.id);
  };

  /**
   *  Handles updates to shipment properties as well as soft deletes (by setting 
   *  deleted_at as something other than null).
   */
  const handleUpdateShipment = (updatedShipment) => {
    let updatedShipments = shipments.slice().filter((shipment) => {
      return (shipment.id && shipment.id !== updatedShipment.id) ||
             (shipment.tempId && shipment.tempId !== updatedShipment.tempId);
    });

    updatedShipments.push(updatedShipment);

    updateOrderItemDetails('shipments', updatedShipments, item.id, { fieldSubKey: (updatedShipment.id || updatedShipment.tempId) });
  };

  const { ShipmentsWrapper } = STYLES;

  return (
    <ShipmentsWrapper
      id={`order-${item.id}-shipments`}
      style={{  }}
    >
      <h4>Shipments</h4>

      {shipments.length > 0 ?
        <table>
          <tbody>
            {shipments
              .sort((a, b) => {
                const sortIdA = a.id || a.tempId;
                const sortIdB = b.id || b.tempId;

                return sortIdA - sortIdB;
              })
              .filter(shipment => shipment.deleted_at === 'toDelete' || !shipment.deleted_at)
              .map((shipment, i) => {
              return (
                <ShipmentRow
                  dirtyFields={dirtyFields}
                  key={i}
                  shipment={shipment}
                  handleUpdate={handleUpdateShipment}
                  handleRemove={handleRemoveShipment}
                  index={i}
                  item={item}
                />
              );
            })}
          </tbody>
        </table>
        :
        null
      }
      <button
        onClick={handleAddShipment}
        id={`add-shipment-button-${item.id}`}
        className='action-button green btn btn-default'
        style={{ margin: '1em 0' }}
        disabled={disabled}
      >
        <Glyphicon glyph='plus' style={{ margin: '0 .3em' }} />
        Add Shipment
      </button>
    </ShipmentsWrapper>
  )
};

const STYLES = {
  ShipmentsWrapper: styled.div`
    width: 100%;
    clear: both;
    margin: 2em;
    margin-left: 1em;
  `
}
