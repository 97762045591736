import React from 'react';
import LOADER_IMG from './images/ajax-loader-2.gif';
import LOADER_GIF from '../static/images/ajax-loader.gif';

const LoadingIcon = ({ styling, size = 'medium', style }) => {
  switch (size) {
    case 'medium':
      return (
        <div
         className={styling || 'padding-10'}
         style={ style }
         >
          <img alt="loader-gif" src={LOADER_GIF} />
        </div>
      );
    case 'small':
      return <img alt="loader-img" className={styling} src={LOADER_IMG} />;
    case 'rightOfButton':
      return (
        <img
          alt="loader-img"
          style={{ padding: '0px 0px 10px 10px' }}
          src={LOADER_IMG}
        />
      );
    case 'leftOfButton':
      return (
        <img
          alt="loader-img"
          style={{ padding: '0px 10px 10px 0px' }}
          src={LOADER_IMG}
        />
      );
    default:
      return null;
  }
};

export default LoadingIcon;
