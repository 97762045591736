import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import Image from './Image';

const ImageModal = ({
  imageURL,
  imageRotation,
  showImage,
  previousImage,
  nextImage,
  close,
  children,
  styling,
}) => {
  let headerText = null;
  let footerText = null;
  let overlay = null;

  React.Children.forEach(children, (child) => {
    switch (child.key) {
      case 'header':
        headerText = child;
        break;
      case 'footer':
        footerText = child;
        break;
      case 'overlay':
        overlay = child;
        break;
      default:
        break;
    }
  });

  return (
    <Modal show={showImage} className="heidi" bsSize="large" onHide={close}>
      <span className={`photo-modal ${styling}`}>
        <Modal.Header closeButton>{headerText}</Modal.Header>
        <Modal.Body className="center-text">
          {previousImage ? <i className="arrow left" onClick={previousImage} /> : null}
          <div className={overlay ? 'button-toggler' : ''}>
            {overlay}
            <Image
              src={imageURL}
              style={{
                transform: `rotate(${imageRotation || 0}deg) scale(${(imageRotation % 180 === 0 ? 1 : 0.7)})`,
              }}
              onClick={nextImage}
              className="img-modal-thumbnail img-thumbnail"
            />
          </div>
          {nextImage ? <i className="arrow right" onClick={nextImage} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={12} className="left-align-text">
              {footerText}
            </Col>
          </Row>
        </Modal.Footer>
      </span>
    </Modal>
  );
};

export default ImageModal;
