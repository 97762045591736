import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import get from 'lodash/get';
import axios from 'axios';
import $ from 'jquery';
import cookie from 'js-cookie';

import { auth } from '../auth';
import { fetchDataIfNeeded } from '../actions/api-data-request-generator';

import {
  OrderProcessingRefundModal,
  OrderProcessingConfirmProceedModal,
  OrderProcessingCreateOrderModal,
  OrderProcessingSearch,
  OrderProcessingList,
  OrderProcessingDetails,
} from '../components/OrderProcessing';
import { ORDER_STATUS_HUMAN_READABLE } from '../constants';

import { ApiConfig, WAREHOUSE_API_URL } from '../config';

import '../sass/components/orderProcessing.css';

import Utils, { USER_FACING_COPY } from '../utils';

import { patchData } from '../actions/api-patch-request-generator';
import { bugsnagError } from '../services/bugsnag';
import {
  trackStartOrderProcessing,
  trackEndOrderProcessing,
} from '../services/segment';
import { fetchFlag } from '../services/configcat';

const SEARCH_INPUT_KEY_DELAY = 250;

const ALLOWABLE_ORDER_STATUSES_FOR_ORDER_UPDATE_EMAILS = {
  'Completed the order': 4,
  'Order delivered': 6,
  'Refund requested': 8,
  Refunded: 9,
  'Partially refunded': 10,
  'Card charged': 12,
  'Transfer completed': 13,
};

const listHeaders = [
  { name: 'Oid', width: 40, sort: 'id' },
  { name: 'Agt', width: 20, sort: 'agent_id' },
  { name: 'Status', width: 120, sort: 'status' },
  { name: '', width: 58 },
  { name: 'Customer', width: 142 },
  { name: 'Date Placed', width: 150, sort: 'created_at' },
  { name: 'Total', width: 55, sort: 'total' },
];
const tieredDiscounts = [
  { minSubtotal: 500, discount: 50 },
  { minSubtotal: 1000, discount: 100 },
  { minSubtotal: 2500, discount: 300 },
];
const numericFields = [
  'wholesale_price',
  'wholesale_shipping_fee',
  'wholesale_tax',
  'shipping_fee',
  'discount',
  'retailer_discount',
  'sales_price',
  'wholesale_price',
  'wholesale_shipping_fee',
  'wholesale_tax',
];

class OrderProcessingContainer extends React.Component {
  constructor() {
    super();

    this.keyTimeouts = [];

    const params = new URLSearchParams(window.location.search);
    this.state = {
      searchTerms: {
        search: params.get('search') || '',
        order_status: params.get('order_status') || '',
        delivery_status: params.get('delivery_status') || '',
        inventory_status: params.get('inventory_status') || '',
        agent_user_id: params.get('agent_user_id') || '',
      },
      orderSort: '-id',
      navWidthSetting: 1,
      navWidth:
        listHeaders[0].width +
        listHeaders[1].width +
        listHeaders[2].width +
        listHeaders[3].width +
        12 +
        10 +
        10,
      orderId: params.get('orderId') * 1 || '',
      orderListPage: 1,
      orderListPageSize: 20,

      refundModalProps: {
        cancelRefund: () => {
          this.processModal('refund', false);
        },
        processRefund: () => {
          this.processModal('refund', true);
        },
        handleModalProps: this.handleModalProps,
      },

      dirtyBounceModalProps: {
        close: () => {
          this.handleModalProps('dirtyBounce', { show: false });
        },
      },
      datePicker: {
        setState: (orderItemId, field, val) => {
          if (!val) {
            return this.updateOrderItemDetails(field, null, orderItemId);
          }

          if (val.date) {
            this.updateOrderItemDetails(
              field,
              val.date.format('YYYY-MM-DD'),
              orderItemId
            );
          }
          this.handleModalProps(orderItemId + field, val);
        },
        focused: (orderItemId, field) => {
          return this.getModalProps(orderItemId + field);
        },
      },

      showPlaceAutomatedOrders: false,
      removeDescriptionOnSave: false,
    };
  }

  dirtyFields = {};
  setIsDirty(s, field, order_item_id, options = {}) {
    const { fieldSubKey } = options;
    this.isDirty = s;
    if (s) {
      window.addEventListener('beforeunload', this.onUnload);
      let key = field + (order_item_id || '') + (fieldSubKey || '');
      this.dirtyFields[key] = 1;
    } else {
      window.removeEventListener('beforeunload', this.onUnload);
      this.dirtyFields = {}; //clear out the object
    }
  }
  componentDidMount() {
    this.getOrders();
    this.getOrderDetails(true);
    this.getSourceDiscounts();
    this.getUsersByGroup();
    this.getPurchasingAgents();
    this.fetchShowPlaceAutomatedOrdersFlag();
    this.fetchRemoveDescriptionOnSaveFlag();

    // a little horrible thing that swaps classes for the right menu to make it fixed after scrolling a bit
    window.addEventListener('scroll', () => {
      let magicNumber = 172;
      if (this.messages) {
        magicNumber += this.messages.length * 65;
      }
      if (document.documentElement.scrollTop > magicNumber) {
        $('.right-bar').addClass('right-bar-fixed');
      } else {
        $('.right-bar').removeClass('right-bar-fixed');
      }
    });
  }
  onUnload(event) {
    event.returnValue = '';
  }

  setNavWidth(direction) {
    let newNavWidthSetting = this.state.navWidthSetting + direction;
    newNavWidthSetting = Math.min(
      Math.max(newNavWidthSetting, 0),
      listHeaders.length - 1
    );
    let newNavWidth = 8;
    for (let i = 0; i <= newNavWidthSetting; i += 1) {
      newNavWidth += listHeaders[i].width + 10;
    } // the 10 is for the <th> padding
    this.setState({
      navWidthSetting: newNavWidthSetting,
      navWidth: newNavWidth,
    });
  }
  setOrderSort = (column) => {
    if (column === this.state.orderSort) column = '-' + column;
    this.setState({ orderSort: column }, () => {
      this.getOrders();
    });
  };

  fetchShowPlaceAutomatedOrdersFlag = () => {
    fetchFlag('showPlaceAutomatedOrders')
      .then((value) => this.setState({ showPlaceAutomatedOrders: value }))
      .catch((err) => this.setState({ showPlaceAutomatedOrders: false }));
  };

  fetchRemoveDescriptionOnSaveFlag = () => {
    fetchFlag('remove_description_on_order_save')
      .then((value) => this.setState({ removeDescriptionOnSave: value }))
      .catch((err) => this.setState({ removeDescriptionOnSave: false }));
  };

  /** *************************************
   * the ajax functions, two kinds, to get data for the page
   ************************************** */
  checkDirtyBlock = (action) => {
    // if isDirty, throw up a modal to confirm action, pass in the action() param to execute if YES
    if (this.isDirty) {
      this.handleModalProps('dirtyBounce', {
        message:
          'You have unsaved changes, are you sure you want to navigate away?',
        show: true,
        actionToProceed: () => {
          if (action) action();
          this.handleModalProps('dirtyBounce', { show: false });
          this.setIsDirty(false);
        },
      });
    } else if (action) action(); // it's not dirty, just do the action
  };

  // This reduces the number of requests we make while keying a search term with little noticeable effect
  // to the user
  getOrdersWithDelay = (delay) => {
    while (this.keyTimeouts.length > 0) {
      clearTimeout(this.keyTimeouts.pop());
    }
    this.keyTimeouts.push(
      setTimeout(() => {
        this.getOrders();
      }, delay)
    );
  };

  getOrders = () => {
    this.setBrowserHistory();

    const { dispatch } = this.props;
    const params = this.state.searchTerms;
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.GET_ORDER_LIST}`, 'get_order_list', {
        params: Object.assign(params, {
          page_size: this.state.orderListPageSize,
          page: this.state.orderListPage - 1,
          sort: this.state.orderSort,
          tag: ['Product Purchase', 'Product Replacement'],
        }),
      })
    ).catch((error) => bugsnagError(error));
  };
  getUsersByGroup = () => {
    const { dispatch } = this.props;
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.USERS_BY_GROUP()}`,
        'users_designers', // limited by server limit page size of 50
        { params: { group: 14, orderBy: 'first_name' } } // group id of designers - 14
      )
    ).catch((error) => bugsnagError(error));
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.USERS_BY_GROUP()}`,
        'users_stylists', // limited by server limit page size of 50
        { params: { group: 15, orderBy: 'first_name' } } // group id of stylists - 15
      )
    ).catch((error) => bugsnagError(error));
  };
  getSourceDiscounts = () => {
    const { dispatch } = this.props;
    if (!this.state.orderId) return;
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.ORDER_SOURCE_DISCOUNTS(this.state.orderId)}`,
        'order_source_discounts'
      )
    ).catch((error) => bugsnagError(error));
  };
  getPurchasingAgents = () => {
    const { dispatch } = this.props;

    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.USERS_BY_GROUP()}`,
        'purchasing_agents',
        { params: { group: 17, orderBy: 'first_name', page_size: 500 } } // group id of purchasing agents - 17
      )
    ).catch((error) => bugsnagError(error));
  };

  getOrderDetails = (isDifferentOrder) => {
    if (!this.state.orderId) return;

    this.checkDirtyBlock(() => {
      this.setBrowserHistory();
      const { dispatch } = this.props;
      const params = isDifferentOrder ? { logOrderView: 1 } : {};
      dispatch(
        fetchDataIfNeeded(
          `${ApiConfig.ORDER_DETAILS(this.state.orderId)}`,
          'order_details',
          { params }
        )
      ).catch((error) => bugsnagError(error));
      this.getOrderHistory();
      this.getOrderShippingHistory();
    });
  };
  getOrderHistory = () => {
    const { dispatch } = this.props;
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.ORDER_HISTORY(this.state.orderId)}`,
        'order_history',
        {
          params: {},
        }
      )
    ).catch((error) => bugsnagError(error));
  };

  getOrderShippingHistory = () => {
    const { dispatch } = this.props;
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.ORDER_SHIPPING_HISTORY(this.state.orderId)}`,
        'order_shipping_history',
        {
          params: {},
        }
      )
    ).catch((error) => bugsnagError(error));
  };

  // build up get params ....
  setBrowserHistory = () => {
    const obj = {};
    let displayGetParams = false;
    for (const term in this.state.searchTerms) {
      if (term === 'page_size') continue; // don't include this
      if (term === 'tag') continue; // don't include this
      obj[term] = this.state.searchTerms[term];
      displayGetParams = true;
    }
    if (this.state.orderId) {
      obj.orderId = this.state.orderId;
      displayGetParams = true;
    }
    let search = '';
    if (displayGetParams) {
      search = `?${Utils.buildURLParams(obj)}`;
    }
    browserHistory.push({
      pathname: window.location.pathname,
      search,
    });
  };

  /** **************************************
   * setting state for input manipulation, then calling various ajax functions
   ************************************** */
  updateSearchTerm = (name, val) => {
    const searchTerms = Object.assign({}, this.state.searchTerms);
    searchTerms[name] = val;

    // Execute after the state is updated to avoid multiple calls
    // Callback function is executed in order
    this.setState({ searchTerms, orderListPage: 1 }, () => {
      this.getOrdersWithDelay(SEARCH_INPUT_KEY_DELAY);
    });
  };

  selectOrderListPage = (newPage) => {
    this.setState({ orderListPage: newPage }, function () {
      this.getOrders();
    });
  };

  showCreateOrder = () => {
    this.handleModalProps('createOrder', {
      message:
        'You have unsaved changes, are you sure you want to navigate away?',
      show: true,
      handleModalProps: this.handleModalProps,
      close: () => {
        this.handleModalProps('createOrder', { show: false });
      },
      actionToProceed: () => {
        this.handleModalProps('createOrder', { show: false });
        this.setIsDirty(false);

        this.ajaxRequest(
          `${WAREHOUSE_API_URL}/payment/api/admin/orders/0/details`,
          'POST',
          {
            action: 'createOrder',
            user_id: this.state.createOrderModalProps.user_id,
            first_name: this.state.createOrderModalProps.first_name,
            last_name: this.state.createOrderModalProps.last_name,
            street: this.state.createOrderModalProps.street,
            city: this.state.createOrderModalProps.city,
            state: this.state.createOrderModalProps.state,
            postal: this.state.createOrderModalProps.postal,
            country: this.state.createOrderModalProps.country,
            phone: this.state.createOrderModalProps.phone,
          },
          (data) => {
            this.messages = data.message;
            this.setIsDirty(false); // the form is no longer dirty
            if (data.ok) {
              this.selectOrder(data.order_id); // load the new order
            } else {
              this.forceUpdate();
            }
          }
        );
      },
    });
  };

  selectOrder = (oid) => {
    this.checkDirtyBlock(() => {
      if (oid !== this.state.orderId) this.messages = []; // clear out the messages if different order

      const isDifferentOrder = oid !== this.state.orderId;
      this.setState({ orderId: oid }, function () {
        this.getOrderDetails(isDifferentOrder);
      });
    });
  };

  updateOrderItemDetails = (name, value, orderItemId, options = {}) => {
    const { order_details } = this.props;
    const orderDetailsData = get(order_details, 'data.order_details', {});
    const subordersData = get(order_details, 'data.suborders', {});
    const orderItemsList = get(order_details, 'data.order_items', []);

    this.setIsDirty(true, name, orderItemId, options);

    // for this whitelist of fields, we force the value to be numeric
    // users commonly "paste" in things with "$", which is not good
    if (numericFields.indexOf(name) >= 0) {
      value = Utils.makeNumeric(value);
    }

    if (name === 'order_status') {
      const isOrderCancelled =
        value === Number(ORDER_STATUS_HUMAN_READABLE['Cancelled']);
      const isCompletedTheOrder =
        value === Number(ORDER_STATUS_HUMAN_READABLE['Completed the order']);
      orderDetailsData.status = value;

      if (isOrderCancelled || isCompletedTheOrder) {
        trackEndOrderProcessing({
          agentUserId: get(orderDetailsData, 'agent_user_id', null),
          orderId: get(orderDetailsData, 'id', null),
          products: get(order_details, 'data.products', {}),
          orderStatus: isOrderCancelled ? 'cancelled' : 'completed the order',
        });
      }
    } else if (name === 'agent_user_id') {
      trackStartOrderProcessing({
        agentUserId: value,
        orderId: get(orderDetailsData, 'id', null),
      });

      orderDetailsData.agent_user_id = value;
    } else if (name === 'designer_user_id') {
      orderDetailsData.designer_user_id = value;
    } else if (name === 'stylist_user_id') {
      orderDetailsData.stylist_user_id = value;
    } else if (name === 'shipping_fee' && subordersData[orderItemId]) {
      subordersData[orderItemId].shipping_fee = value;
    } else if (
      name === 'wholesale_shipping_fee' &&
      subordersData[orderItemId]
    ) {
      subordersData[orderItemId].wholesale_shipping_fee = value;
    } else if (name === 'wholesale_tax') {
      subordersData[orderItemId].wholesale_tax = value;
    } else if (name === 'tiered_discount') {
      orderDetailsData.tiered_discount = value;
    } else if (name === 'coupon_code') {
      orderDetailsData.coupon_code = value;
    } else if (name === 'notes_for_customer') {
      orderDetailsData.notes_for_customer = value;
    } else {
      let customerSubtotal = 0;
      for (let i = 0; i < orderItemsList.length; i += 1) {
        if (orderItemsList[i].id === orderItemId) {
          if (name === 'wholesale_price' || name === 'wholesale_shipping_fee') {
            orderItemsList[i].properties[name] = value;
          } else {
            orderItemsList[i][name] = value;
          }
        }
        //this amount needs to consider the salesprice LESS the retailer discount LESS refunded_amout
        customerSubtotal +=
          orderItemsList[i].quantity *
            (orderItemsList[i].sales_price -
              orderItemsList[i].retailer_discount) -
          orderItemsList[i].discount -
          orderItemsList[i].properties.refunded_amount;
      }
      let possibleDiscount = 0;
      for (let i = 0; i < tieredDiscounts.length; i += 1) {
        if (customerSubtotal >= tieredDiscounts[i].minSubtotal) {
          possibleDiscount = tieredDiscounts[i].discount;
        }
      }

      const possibleDiscount2 =
        (customerSubtotal * orderDetailsData.estimate.tiered_discount) /
        orderDetailsData.estimate.subtotal;
      let tiered_discount_possible = 0;
      // set the tiere_discount on the order based on some things, there are three cases
      // (1) there is midly complex logic for credits vs tiered_discount to use the max
      // this is difficult AFTER the order is placed, AND credit_amount is readonly
      // so just zero out tiered_discount, if there is ANY credit_amount ... should be good for >99% of cases
      if (orderDetailsData.credit_amount > 0) {
        tiered_discount_possible = 0;
        // (2) the current subtotal's tier is higher than the original tier, use that!
      } else if (possibleDiscount > orderDetailsData.estimate.tiered_discount) {
        tiered_discount_possible = possibleDiscount;
        // (3) the current subtotal's tier is lower than the original tier, use the original's percent applied to the current total
      } else if (possibleDiscount < orderDetailsData.estimate.tiered_discount) {
        tiered_discount_possible = possibleDiscount2.toFixed(2);
        // (4) at the same tier, use that one, simple!
      } else {
        tiered_discount_possible = possibleDiscount;
      }
      orderDetailsData.tiered_discount_possible = tiered_discount_possible;
    }
    this.setState(this.state);
  };
  /** **************************************
   * a general jquery ajax function to use
   ************************************** */
  ajaxRequest = (url, method, params, success, error) => {
    if (method === 'POST') params = JSON.stringify(params);
    $.ajax({
      type: method,
      headers: {
        'Content-Type': 'application/json',
      },
      dataType: 'JSON',
      url,
      data: params,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        this.setState({ spinnerGif: null }); // clear all spinner
        if (success) success(data);
      },
      error: (req, status, e) => {
        if (error) error(e);
        else {
          bugsnagError(e);
          alert(`${method} error!${e}`);
        }
      },
    });
  };

  // save the order, send a POST request to the api
  saveOrder = () => {
    this.setState({ spinnerGif: 'saveOrder' });

    // Issues were coming up when product descriptions were long.
    // Since the description is not needed in order updates, we can
    // remove them from the ajax call.
    const productsToSend = {};
    Object.entries(this.props.order_details.data.products).forEach(([k, v]) => {
      productsToSend[k] = {
        ...v,
        description: this.state.removeDescriptionOnSave ? '' : v.description,
      };
    });

    this.ajaxRequest(
      `${WAREHOUSE_API_URL}/payment/api/admin/orders/${this.state.orderId}/details`,
      'POST',
      {
        action: 'saveOrder',
        order: {
          ...this.props.order_details.data,
          products: productsToSend,
        },
      },
      (data) => {
        this.messages = data.message;
        if (data.ok) {
          this.setIsDirty(false); // the form is no longer dirty
          this.selectOrder(this.state.orderId); // reload the order, to ensure cleanliness
        } else {
          this.forceUpdate();
        }
      }
    );
  };

  /**
   * Logic to place automated orders via a POST request.
   * No data is needed to be sent in this POST request.
   */
  handlePlaceAutomatedOrders = async () => {
    const { orderId } = this.state;
    this.setState({ spinnerGif: 'placeAutomatedOrders' });

    const url = `${WAREHOUSE_API_URL}/order_automation/api/place_retailer_order/${orderId}`;
    // reset validation/error messages
    this.messages = [];

    try {
      /**
       * Axios does not return responses for POST on 500 errors.
       * Fix found here: https://github.com/axios/axios/issues/1143
       * Solution is to return true for all statuses.
       */
      const validateStatus = (status) => {
        return true;
      };
      const dataToSend = null;
      const response = await axios.post(url, dataToSend, { validateStatus });

      const responseStatus = response.status;
      const responseData = response.data;
      let messageLevel = '';

      /**
       * Since we return true for all statuses we need to handle the different situations.
       */
      if (responseStatus !== 200) {
        bugsnagError(
          `POST request for api: ${url} returned status ${responseStatus}`,
          { responseData }
        );

        messageLevel = 'error';
      } else {
        messageLevel = 'success';

        // Let's reload the order, with the new Retailer Order #.
        this.selectOrder(orderId);
      }

      // Let's display the validation/error messages on top of the page.
      this.messages = [
        {
          message: responseData,
          level: messageLevel,
          header: `${USER_FACING_COPY.orderActions.placeAutomatedOrders} response:`,
        },
      ];
    } catch (error) {
      bugsnagError(error, `POST request for this api failed: ${url}`);
    } finally {
      // clear all spinners
      this.setState({ spinnerGif: null });
      // Scroll to top of page where the validation/error messages are displayed.
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // send get request to order update email api to automatically send
  // order update email to customer
  sendOrderUpdateEmail = () => {
    const { order_details } = this.props;
    this.setState({ spinnerGif: 'sendOrderUpdateEmail' });
    this.ajaxRequest(
      `${WAREHOUSE_API_URL}/payment/api/admin/orders/send_order_update_email/${this.state.orderId}`,
      'GET',
      null,
      (data) => {
        if (data.ok) {
          this.messages = [
            {
              message: `Successfully sent order update email to ${order_details.data.user_details.email}.`,
            },
          ];
          this.selectOrder(this.state.orderId); // reload the order, to ensure cleanliness
        }
      }
    );
  };

  canSendOrderUpdateEmail = () =>
    Object.values(ALLOWABLE_ORDER_STATUSES_FOR_ORDER_UPDATE_EMAILS).includes(
      get(this.props, 'order_details.data.order_details.status')
    );

  orderAction = (action, itemId, availableAmount, suborderId) => {
    const { orderId } = this.state || {};
    const orderDetailsData = get(this.props.order_details, 'data', {});

    if (action === 'shippingEditable') {
      orderDetailsData.shipping_details.editable = !orderDetailsData
        .shipping_details.editable;
      this.setState(this.state);
    }

    if (action === 'billingEditable') {
      orderDetailsData.billing_details.editable = !orderDetailsData
        .billing_details.editable;
      this.setState(this.state);
    }

    // they want to capture, issue a GET request
    if (action === 'capture') {
      this.setState({ spinnerGif: 'capture' });
      const data = { no_redirect: 1 };
      const url = `${WAREHOUSE_API_URL}/admin_payment/payment/customerorder/${orderId}/capture/`;
      this.ajaxRequest(url, 'GET', data, (data) => {
        this.setIsDirty(false); // the form is no longer dirty
        this.messages = data;
        this.selectOrder(orderId); // reload the order, to ensure cleanliness
        trackEndOrderProcessing({
          agentUserId: get(
            orderDetailsData,
            'order_details.agent_user_id',
            null
          ),
          orderId: get(orderDetailsData, 'order_details.id', null),
          products: get(orderDetailsData, 'products', {}),
          orderStatus: 'complete',
        });
      });
    }

    if (action === 'email_receipt') {
      this.setState({ spinnerGif: 'capture' });
      const data = { no_redirect: 1 };
      const url = `${WAREHOUSE_API_URL}/admin_payment/payment/customerorder/${orderId}/email_receipt/`;
      this.ajaxRequest(url, 'GET', data, (data) => {
        this.setIsDirty(false); // the form is no longer dirty
        this.messages = data;
        this.selectOrder(orderId); // reload the order, to ensure cleanliness
      });
    }

    // they want to issue a refund, save some props, which opens a modal
    if (action === 'refund') {
      const availableShipping = itemId
        ? orderDetailsData.suborders[suborderId].refundable_shipping_fee
        : null;
      let availableTax = null;
      let availableTieredDiscount = null;
      for (var i = 0; i < orderDetailsData.order_items.length; i++) {
        if (orderDetailsData.order_items[i].id === itemId) {
          availableTax = orderDetailsData.order_items[i].tax;
          availableTieredDiscount =
            orderDetailsData.order_items[i].tiered_discount;
        }
      }

      this.handleModalProps('refund', {
        itemId,
        availableAmount,
        availableShipping,
        availableTax,
        availableTieredDiscount,
        customAmount: availableAmount ? availableAmount.toFixed(2) : null,
        show: true,
      });
    }
  };

  setAddressField = (field, obj) => (value) => {
    return [
      // issue the patch request
      patchData(
        `${ApiConfig.BILLING_INFO_CHANGE_URL}/${obj.id}`,
        'billing_info_change',
        {
          data: Utils.buildState(field, value),
        }
      ),
      // update state, so the new value will display to user
      () => {
        if (this.props.order_details.data.shipping_details.id === obj.id) {
          this.props.order_details.data.shipping_details[field] = value;
          this.setState(this.state);
        } else if (
          this.props.order_details.data.billing_details.id === obj.id
        ) {
          this.props.order_details.data.billing_details[field] = value;
          this.setState(this.state);
        } else {
          alert(`PROBLEM! Unable to find correct billing_details${obj.id}`);
        }
      },
    ];
  };

  /** **************************************
   * handle modal/dialog stuff
   ************************************** */
  processModal = (action, param1) => {
    const key = `${action}ModalProps`;
    const resetState = {
      show: false,
      inProgress: false,
      itemId: null,
      customAmount: null,
      availableAmount: null,
      refundReason: null,
    };

    // the modal is open, how take some action based on the user's input
    if (action === 'refund') {
      if (param1) {
        // YES! process refund
        // do the refund, send a GET request
        this.handleModalProps(action, { inProgress: true });
        const getParams = {
          no_redirect: 1,
          order_item_id: this.state[key].itemId,
          custom_amount: this.state[key].customAmount,
          refund_reason: this.state[key].refundReason,
        };
        this.ajaxRequest(
          `${WAREHOUSE_API_URL}/admin_payment/payment/customerorder/${this.state.orderId}/refund/`,
          'GET',
          getParams,
          (data) => {
            this.setIsDirty(false); // the form is no longer dirty
            this.messages = data;
            this.selectOrder(this.state.orderId); // reload the order, to ensure cleanliness
            this.handleModalProps(action, resetState);
          }
        );
      } else {
        // nope! cancel the refund
        this.handleModalProps(action, resetState); // clear/reset the values
      }
    }
  };

  /** **************************************
   * handle adding products
   ************************************** */
  addProductSearch = (productId) => {
    if (!productId) {
      this.handleModalProps('addProduct', { show: false });
    } else {
      const url = `${WAREHOUSE_API_URL}/warehouse/api/products/${productId}`;
      this.ajaxRequest(
        url,
        'GET',
        {},
        (data) => {
          this.handleModalProps('addProduct', {
            show: true,
            productId: data.id,
            productName: data.name,
            sourceName: data.source,
            productImage: data.images ? data.images[0].filename : null,
            productPrice: data.price,
          });
        },
        () => {
          // error handler, likely for 404 for no product found
          this.handleModalProps('addProduct', { show: 'unknownProduct' }); // clear it out
        }
      );
    }
  };
  addProduct = () => {
    // this happens on click of the "add" button this will:
    // (1) send a POST to the server for `action=addProduct` which will take care of adding the product
    // (2) upon success reload the order to re-display everything
    // show messages regardless

    this.setState({ spinnerGif: 'addProduct' });
    this.ajaxRequest(
      `${WAREHOUSE_API_URL}/payment/api/admin/orders/${this.state.orderId}/details`,
      'POST',
      {
        action: 'addProduct',
        product_id: this.state.addProductModalProps.productId * 1,
      },
      (data) => {
        this.messages = data.message;
        if (data.ok) {
          this.setIsDirty(false); // the form is no longer dirty
          this.selectOrder(this.state.orderId); // reload the order, to ensure cleanliness
          this.handleModalProps('addProduct', { show: false }); // clear the add product stuff
        } else {
          this.forceUpdate();
        }
      }
    );
  };

  // all the `addHistory` stuff bundled into one object, to make passing around easier ...
  addHistory = {
    editNote: (val) => {
      this.setState({ editNote: val });
    },
    noteValue: () => {
      return this.state && this.state.editNote ? this.state.editNote : '';
    },
    saveNote: () => {
      this.ajaxRequest(
        `${WAREHOUSE_API_URL}/api/payment/orderactivity`,
        'POST',
        {
          order: this.state.orderId,
          event_type: 'note',
          description: this.state.editNote,
        },
        () => {
          this.setState({ editNote: '' });
          this.getOrderHistory();
        }
      );
    },
  };

  // helper function that will assign key/values to a modal prop, within state
  handleModalProps = (action, obj, success) => {
    const key = `${action}ModalProps`;
    const newState = Object.assign({}, this.state[key], obj);
    this.setState(Utils.buildState(key, newState), () => {
      if (success) success();
    });
  };

  getModalProps = (action) => {
    const key = `${action}ModalProps`;
    return this.state[key] ? this.state[key].focused : null;
  };

  render() {
    if (!auth.isInGroup('Payment')) {
      return (
        <div
          style={{ textAlign: 'center', marginTop: '100px', fontSize: '20px' }}
        >
          You do not have permission to view this page.
          <br />
          Please contact your manager for access
        </div>
      );
    }

    //this just initializes the `tiered_discount_possible` by doing a "do nothing" call to updateOrderItemDetails which will generate that value
    if (
      this.props.order_details.data &&
      typeof this.props.order_details.data.order_details
        .tiered_discount_possible === 'undefined'
    ) {
      this.updateOrderItemDetails('', 1, -1);
      this.setIsDirty(false);
    }

    return (
      <div className="order-processing">
        <div className="search">
          <OrderProcessingSearch
            purchasingAgents={this.props.purchasingAgents}
            searchTerms={this.state.searchTerms}
            updateSearchTerm={this.updateSearchTerm}
          />
        </div>

        <div className="list" style={{ width: this.state.navWidth }}>
          <button onClick={() => this.setNavWidth(-1)}>&#x21a4;</button> &nbsp;
          <button onClick={() => this.getOrders()}>&#x21bb;</button>&nbsp;
          <button onClick={() => this.setNavWidth(1)}>&#x21a6;</button>
          <OrderProcessingList
            orders={this.props.orders}
            listHeaders={listHeaders}
            orderSort={this.state.orderSort}
            purchasingAgents={this.props.purchasingAgents}
            setOrderSort={this.setOrderSort}
            selectOrder={this.selectOrder}
            showCreateOrder={this.showCreateOrder}
            selectedOrder={this.state.orderId}
            orderListPage={this.state.orderListPage}
            orderListPageSize={this.state.orderListPageSize}
            selectOrderListPage={this.selectOrderListPage}
          />
        </div>

        <div className="details" style={{ left: this.state.navWidth }}>
          <OrderProcessingDetails
            addHistory={this.addHistory}
            addProduct={this.addProduct}
            addProductDetails={this.state.addProductModalProps}
            addProductSearch={this.addProductSearch}
            canSendOrderUpdateEmail={this.canSendOrderUpdateEmail()}
            datePicker={this.state.datePicker}
            dirtyFields={this.dirtyFields}
            isDirty={this.isDirty} // means there are unsaved changes
            messages={this.messages}
            onPlaceAutomatedOrders={this.handlePlaceAutomatedOrders}
            orderAction={this.orderAction}
            orderDetails={this.props.order_details}
            orderHistory={this.props.order_history}
            orderShippingHistory={this.props.order_shipping_history}
            orderSourceDiscounts={this.props.orderSourceDiscounts}
            purchasingAgents={this.props.purchasingAgents}
            saveOrder={this.saveOrder}
            sendOrderUpdateEmail={this.sendOrderUpdateEmail}
            setAddressField={this.setAddressField}
            showPlaceAutomatedOrders={this.state.showPlaceAutomatedOrders}
            spinnerGif={this.state.spinnerGif}
            updateOrderItemDetails={this.updateOrderItemDetails}
            usersDesigners={this.props.users_designers}
            usersStylists={this.props.users_stylists}
          />
        </div>

        <OrderProcessingCreateOrderModal
          {...this.state.createOrderModalProps}
        />

        <OrderProcessingRefundModal {...this.state.refundModalProps} />

        <OrderProcessingConfirmProceedModal
          {...this.state.dirtyBounceModalProps}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

const mapStateToProps = (state) => {
  const orders = state.get_order_list;
  const order_details = state.order_details;
  const order_history = state.order_history;
  const order_shipping_history = state.order_shipping_history;
  const orderSourceDiscounts = state.order_source_discounts;
  const purchasingAgents = state.purchasing_agents;
  const users_designers = state.users_designers;
  const users_stylists = state.users_stylists;

  return {
    orders,
    order_details,
    order_history,
    order_shipping_history,
    orderSourceDiscounts,
    purchasingAgents,
    users_designers,
    users_stylists,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderProcessingContainer);
