import * as types from '../actions/action-types';

const initialInformationReducer = (state = null, action) => {
  switch (action.type) {
    case types.SET_USER_ID:
      return action.id;
    default:
      return state;
  }
};

export default initialInformationReducer;
