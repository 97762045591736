import React from 'react';
import {
  FormControl,
  ButtonToolbar,
  Glyphicon,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';

import { SHIPMENT_DELIVERY_CARRIERS, SHIPMENT_DELIVERY_STATUSES } from './constants';
import { getCarrierByTrackingNumber } from './utils';

const ROW_WILL_BE_DELETED = 'The above row will be deleted when you save this order.';

export default function ShipmentRow ({
  dirtyFields = {},
  handleUpdate = () => {},
  handleRemove = () => {},
  shipment,
  index,
  item
}) {
  const { carrier, delivery_status, id, tempId, tracking_number } = shipment;

  const handleChange = (val, field) => {
    let updatedShipment;

    // Try to auto detect the carrier by tracking number
    if (field === 'tracking_number' && getCarrierByTrackingNumber(val)) {
      updatedShipment = {
        ...shipment,
        carrier: getCarrierByTrackingNumber(val),
        [field]: val
      };
    } else {
      updatedShipment = {
        ...shipment,
        [field]: val
      };
    }

    handleUpdate(updatedShipment);
  };

  const handleDelete = () => {
    // Shipment doesn't exist in the DB. We should just remove from 
    // memory on the client.
    if (!shipment.id) {
      handleRemove(shipment.tempId);
      return;
    }

    // The backend will implement a soft delete for any shipment with
    // a truthy value of deleted_at
    const updatedShipment = {
      ...shipment,
      deleted_at: 'toDelete'
    };

    handleUpdate(updatedShipment);
  };

  const toDelete = shipment.deleted_at === 'toDelete';
  const usableId = shipment.id || shipment.tempId;

  const MessageRow = ({ message }) => {
    return (
      <tr>
        <td colSpan='7'>{message}</td>
      </tr>
    );
  };

  return (
    <>
      <tr id={`shipment-row-${usableId}`} style={{ opacity: (toDelete ? '0.3' : '1' )}}>
        <td className='fieldLabel right'>
          Tracking Number
        </td>
        <td>
          <FormControl
            style={{ width: '125px' }}
            onChange={(e) => handleChange(e.target.value, 'tracking_number')}
            name={`shipment-${id || tempId}`}
            type='text'
            value={tracking_number}
            disabled={toDelete}
            className={dirtyFields['shipments'+item.id+usableId] ? 'dirty' : null}
          />
        </td>
        <td className='fieldLabel right' style={{ paddingLeft: '5px' }}>
          Deliver Status
        </td>
        <td>
          <ButtonToolbar>
            <DropdownButton
              style={{ minWidth: '100px' }}
              title={SHIPMENT_DELIVERY_STATUSES[delivery_status] || 'Delivery Status'}
              id={`item-${item.id}-delivery-status-${index}`}
              onSelect={val => handleChange(val, 'delivery_status')}
              className={dirtyFields['shipments'+item.id+usableId] ? 'dirty' : null}
              disabled={toDelete}
            >
              {Object.keys(SHIPMENT_DELIVERY_STATUSES).map((status) => {
                return (
                  <MenuItem
                    key={status}
                    eventKey={status}
                  >
                    {SHIPMENT_DELIVERY_STATUSES[status]}
                  </MenuItem>
                );
              })}
            </DropdownButton>
          </ButtonToolbar>
          
        </td>
        <td className='fieldLabel right' style={{ paddingLeft: '5px' }}>
          Carrier
        </td>
        <td>
          <ButtonToolbar>
            <DropdownButton
              style={{ minWidth: '100px' }}
              title={SHIPMENT_DELIVERY_CARRIERS[carrier] || 'Carrier'}
              id={`item-${item.id}-carrier-${index}`}
              onSelect={val => handleChange(val, 'carrier')}
              className={dirtyFields['shipments'+item.id+usableId] ? 'dirty' : null}
              disabled={toDelete}
            >
              {Object.keys(SHIPMENT_DELIVERY_CARRIERS).map((deliveryCarrier) => {
                return (
                  <MenuItem
                    key={deliveryCarrier}
                    eventKey={deliveryCarrier}
                  >
                    {SHIPMENT_DELIVERY_CARRIERS[deliveryCarrier]}
                  </MenuItem>
                );
              })}
            </DropdownButton>
          </ButtonToolbar>
          
        </td>
        <td style={{ paddingLeft: '10px' }}>
          <Glyphicon
            id={`shipment-remove-${usableId}`}
            glyph={'remove'}
            onClick={handleDelete}
            style={{ cursor: 'pointer', color: '#ef4d21' }}
          />
        </td>
      </tr>
      {toDelete && <MessageRow message={ROW_WILL_BE_DELETED} />}
    </>
  )
};