import React, { useState } from 'react';
import Select from 'react-select';

import {
  DropdownContainer,
  Container,
  GridContainer,
  GridItem,
  Header,
  LikesDislikesDropdown,
} from './styles';
import moreInfoIcon from '../../../../images/info_icon.svg';
import Tooltips from '../../../../sharedComponents/Tooltips';
import DislikedIcon from '../../../../images/DislikeHeart.svg';
import LikedIcon from '../../../../images/LikeHeart.svg';
import StyleMatch from './StyleMatch';

const options = [
  { value: 1, label: 'Likes' },
  { value: -1, label: 'Dislikes' },
];
export const DESIGN_IDEAS_STR = 'Design ideas for this room';
const getTooltipText = () => {
  const tooltipText = `<div style="padding: 8px 16px 0 16px; line-height:20px;"><p>Design 
        Ideas shown for each project <br /> are limited to those selected by the  <br /> customer 
        in Style Quiz.</p><p>Not shown are ideas for other projects,  <br /> other rooms types, 
        and those liked <br /> directly in Design Ideas since we don’t  <br /> know which of 
        those is relevant to this <br /> project.</p></div>`;

  return <span dangerouslySetInnerHTML={{ __html: tooltipText }} />;
};

export default function StyleMatches({
  hasStyleCollectionRooms = false,
  likeReasons = {},
  styleCollectionRooms = [],
}) {
  const [currentLikeOption, setCurrentLikeOption] = useState(options[0]);
  const noStyleCollectionRoomsStr = `There are no design ideas linked to this room.This is most likely because the customer took the old style quiz.`;

  const getFormattedLikeReasons = (roomLikeReasons) => {
    let likeReasonsCopy = {};
    let formattedRoomLikeReasons = [];
    for (let key in roomLikeReasons) {
      let likeReasonsValue = roomLikeReasons[key];
      for (let innerKey in likeReasons) {
        let currentLikeOption = likeReasons[innerKey].id;
        if (likeReasonsValue === currentLikeOption) {
          likeReasonsCopy = Object.assign(likeReasonsCopy, {
            [innerKey]: likeReasons[innerKey],
          });
        }
      }
    }
    formattedRoomLikeReasons = Object.keys(likeReasonsCopy).sort(
      (reason1, reason2) => {
        return likeReasonsCopy[reason1].order - likeReasonsCopy[reason2].order;
      }
    );
    return formattedRoomLikeReasons.join(', ');
  };

  const handleLikesFiltering = (collectionRoom, type = 'dropdown') => {
    const likeLabel = currentLikeOption.label;
    const likeStr = options[0].label;
    if (likeLabel === likeStr) {
      if (type === 'icon') {
        return LikedIcon;
      }
      return collectionRoom.like_value >= currentLikeOption.value;
    } else {
      if (type === 'icon') {
        return DislikedIcon;
      }
      return collectionRoom.like_value <= currentLikeOption.value;
    }
  };

  const handleRoomUrl = (url) => {
    window.open(`${url}`, '_blank');
  };

  const onSelectChange = (event) => {
    setCurrentLikeOption(event);
  };

  return (
    <Container>
      <Header>
        {DESIGN_IDEAS_STR}
        <Tooltips
          content={getTooltipText()}
          icon={moreInfoIcon}
          toolTipClassName={'design-ideas-tooltip'}
          alt="more info icon"
          margin="0 0 0 8px"
        />
      </Header>
      {hasStyleCollectionRooms ? (
        <React.Fragment>
          <GridItem gridTemplateRows={'1fr'}>
            <DropdownContainer>
              <Select
                // key={dropdown}
                value={currentLikeOption}
                onChange={(event) => onSelectChange(event)}
                options={options}
                placeholder={currentLikeOption}
                styles={LikesDislikesDropdown}
              />
            </DropdownContainer>
          </GridItem>
          <GridContainer>
            {styleCollectionRooms
              .sort((a, b) => b.like_value - a.like_value)
              .filter((key) => handleLikesFiltering(key))
              .map((key, index) => {
                const styleMatchData = {
                  image: key.image,
                  reasons: getFormattedLikeReasons(key.like_reasons),
                  genomeType: key.page_genome,
                  roomType: key.room_type || 'Not available',
                  spaceID: key.space_id || 'Not available',
                  roomUrl: () => handleRoomUrl(key.room_url),
                  header: `${key.room_type} ${key.space_id}`,
                  likesFiltering: handleLikesFiltering(key, 'icon'),
                  index: index,
                };
                return (
                  <StyleMatch
                    key={`style-match-${index}`}
                    styleMatchData={styleMatchData}
                  />
                );
              })}
          </GridContainer>
        </React.Fragment>
      ) : (
        <div>{noStyleCollectionRoomsStr}</div>
      )}
    </Container>
  );
}
