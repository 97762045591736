
export const getDelayedCopy = ({
  isDelayed,
  initialEta,
  etaEditedAt
}) => {
  switch(true) {
    case isDelayed:
      return `(Initially ${initialEta})`;
    default:
      return ''
  }
};
