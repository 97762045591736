import React from 'react';
import get from 'lodash/get';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import ImageModal from '../ImageModal';
import PatchButtonContainer from '../../containers/PatchButtonContainer';
import PatchButton from '../PatchButton';
import { patchData } from '../../actions/api-patch-request-generator';
import { PHOTO_STATUS } from '../../constants';
import { ApiConfig } from '../../config';

const PhotosAndMeasurementsModal = ({
  zoomImageIndex,
  images,
  showImage,
  close,
  captureType,
  previousImage,
  nextImage,
  activeSpaceID,
  currentSpace,
  currentSpaceRefresh,
}) => {
  const zoomImage = images[zoomImageIndex] || {};
  const imageDate = new Date(zoomImage.created_at);

  const isIOSCaptureType = captureType === 1;

  const setAsBeforeImage = patchData(
    `${ApiConfig.SET_FEATURE_IMAGE(activeSpaceID)}`,
    'space_info_change',
    { data: { featured_userimage: { id: zoomImage.id } } }
  );

  let isFeaturedImage;
  if (currentSpace) {
    let featuredImage = get(currentSpace, '0.featured_userimage.id', false);
    if (currentSpaceRefresh) {
      featuredImage = get(currentSpaceRefresh, 'featured_userimage.id', false);
    }

    isFeaturedImage = featuredImage === zoomImage.id;
  }

  const WrappedPatchButton = PatchButtonContainer(PatchButton);
  const photoStatusList = PHOTO_STATUS[zoomImage.status] || [];
  const photoStatusTitle = photoStatusList[0];
  const photoStatusClassName = photoStatusList[1];

  return (
    <ImageModal
      imageURL={zoomImage.url}
      imageRotation={zoomImage.rotation}
      showImage={showImage}
      close={close}
      previousImage={previousImage}
      nextImage={nextImage}
    >
      <div key="header">
        <h5>
          User Space Photo ({zoomImageIndex + 1}/{images.length}):
          <span className={photoStatusClassName}>
            &nbsp;<strong>{photoStatusTitle}</strong>
          </span>
        </h5>
      </div>
      <div key="footer">
        Uploaded <strong>{imageDate.toLocaleString()}</strong>
      </div>
      <div className="button-container-modal" key="overlay">
        {!isIOSCaptureType && (
          <React.Fragment>
            <Row>
              <WrappedPatchButton
                buttonStyle="approve-button"
                patchRequests={[setAsBeforeImage]}
                identifier="dimensions"
                disabled={isFeaturedImage}
              >
                {isFeaturedImage ? 'Studio before image' : 'Set as before'}
              </WrappedPatchButton>
            </Row>
          </React.Fragment>
        )}
      </div>
    </ImageModal>
  );
};

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  const currentSpace = state.space_data.filteredSpaces.filter(
    (space) => space.id === activeSpaceID
  );

  const currentSpaceRefresh = state.edit_requests.space_info_change.data;

  return {
    activeSpaceID,
    currentSpace,
    currentSpaceRefresh,
    captureType: get(
      state,
      `space_data[${activeSpaceID}].dimensions.data.results[0].capture_type`,
      0
    ),
  };
};

export default connect(mapStateToProps)(PhotosAndMeasurementsModal);
