import React from 'react';
import styled from 'styled-components';
import CE from 'react-contenteditable'; // Default import ContentEditable, use alias
import sanitizeHtml from 'sanitize-html';

/** Wrapper around react-contenteditable that ensures text and styles
 * are formatted correctly
 * https://github.com/lovasoa/react-contenteditable
 */
export default function ContentEditable(props) {
  return (
    <Container>
      {/* Fixes having to double click outside of container to lose focus
    See: https://stackoverflow.com/questions/34354085/clicking-outside-a-contenteditable-div-stills-give-focus-to-it */}
      &#8203;{/* zero width spaces */}
      <ContentEditableWrapper
        html={sanitizeHtml(props.sanitizedtext, {
          allowedTags: [],
          allowedAttributes: {},
        })}
        // Place the formatting text styles here as styled components does not parse it correctly
        // Allows keeping of new lines on Enter/Return without adding in <p> and/or <br> tags that we do not want
        style={{ display: 'inline-block', whiteSpace: 'pre-wrap' }}
        // Ensures paragraphs are kept when a user copies/pastes data from other sources
        onPaste={(e) => {
          e.preventDefault();
          const text = e.clipboardData.getData('text');
          document.execCommand('insertText', false, text);
        }}
        placeholder={(!props.isLinkType && props.placeholder) || 'No answer'}
        {...props}
      />
      &#8203;{/* zero width spaces */}
    </Container>
  );
}

// For web-kit-browsers, prevents having to double-click
// to lose focus
const Container = styled.span`
  white-space: nowrap;
`;

// The CSS here focuses on the styling of the container itself
const ContentEditableWrapper = styled(CE)`
  border-radius: 4px;
  padding: ${(props) => (props.islinktype ? '0' : '8px')};
  ${(props) => {
    switch (true) {
      case props.resize:
        return { resize: props.resize };
      case props.islinktype && !props.resize:
        return { resize: 'none' };
      default:
        return { resize: 'vertical' };
    }
  }}
  /* resize: ${(props) => (props.resize ? props.resize : 'vertical')}; */
  overflow: auto;
  border: ${(props) => (props.islinktype ? 'none' : '1px solid #CCCCCC')};
  width: 100%;
  
  &:empty:before {
  content: attr(placeholder);
  display: block;
  font-style: italic;
  color: #aaa;
}

  &:focus {
    outline: none !important;
    border-color: #719ece;
    box-shadow: 0 0 5px #719ece;
    color: #4978bc;
  }
`;
