import React from 'react';
import { connect } from 'react-redux';
import { dataRefresh } from '../actions/api-data-request-generator';
import { bugsnagError } from '../services/bugsnag';

// HOC to handle edit server requests
const PatchButtonContainer = (ComposedPatchButton) => {
  class PatchButton extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = { loading: false, error: false };
    }

    // patchRequests prop must be an ARRAY
    handleActionClick = () => {
      const {
        dispatch,
        patchRequests,
        identifier,
        activeSpaceID,
        next,
      } = this.props;
      this.setState({ loading: true });
      const asyncs = [];
      patchRequests.forEach((request) => {
        asyncs.push(dispatch(request));
      });
      // dispatch function must return a promise
      Promise.all(asyncs)
        .then((data) => {
          this.setState({ loading: false, error: null, response: data });
          // need to re-fetch P&M data since it has changed
          if (identifier) {
            dispatch(dataRefresh(identifier, { space: activeSpaceID }));
          }
          if (next) next();
        })
        .catch((error) => {
          this.setState({ loading: false, error });
          bugsnagError(error);
        });
    };

    render() {
      return (
        <ComposedPatchButton
          {...this.props}
          handleClick={this.handleActionClick}
          {...this.state}
        />
      );
    }
  }
  const mapStateToProps = (state) => {
    const activeSpaceID = state.space_data.activeID;
    return {
      activeSpaceID,
    };
  };
  return connect(mapStateToProps)(PatchButton);
};

export default PatchButtonContainer;
