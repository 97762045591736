import { combineReducers } from 'redux';
import apiDataReducerGenerator from './api-data-reducer-generator';
import * as types from '../actions/action-types';
import {
  DESIGN_SPECIFIC_API_IDENTIFIERS,
  API_ACTION_TYPES,
} from '../constants';

const buildDesignAPIReducers = () => {
  const apiReducers = {};
  DESIGN_SPECIFIC_API_IDENTIFIERS.forEach((identifier) => {
    apiReducers[identifier] = apiDataReducerGenerator(identifier);
  });
  return apiReducers;
};

const buildDesignAPIActions = () => {
  const actions = [];
  DESIGN_SPECIFIC_API_IDENTIFIERS.forEach((identifier) => {
    API_ACTION_TYPES.forEach((type) => {
      const action = `${identifier.toUpperCase()}_${type}`;
      actions.push(types[action]);
    });
  });
  return actions;
};

const designSpecificAPIReducers = (state = {}, action) => {
  const DESIGN_API_ACTIONS = buildDesignAPIActions();
  if (DESIGN_API_ACTIONS.indexOf(action.type) > -1) {
    return Object.assign({}, state, {
      [action.keys.design]: combineReducers(buildDesignAPIReducers())(
        state[action.keys.design],
        action,
      ),
    });
  }
  return state;
};

export default designSpecificAPIReducers;
