import React, { useState } from 'react';
import styled from 'styled-components';

import { SecondaryCta } from 'sharedComponents/button';
import { QuoteSymbol } from 'sharedComponents/sharedStyledComponents';
import LayoutFeedbackOverlay from './LayoutFeedbackOverlay';
import { UserCopy } from './utils';

export default function LayoutFeedback({
  designID = '',
  submitLayoutFeedback = () => {},
  currentLayoutFeedback = '',
  layoutFeedbackFormattedDate = '',
  designName = '',
  layoutSelected = false,
  anyLayoutSelected = false,
  selectedLayoutName = '',
}) {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const toggleLayoutFeedbackOverlay = () => {
    setOverlayVisible(!overlayVisible);
  };

  const onSubmitHandler = (selected, feedback = '') => {
    submitLayoutFeedback({ designID, selected, customerNotes: feedback });
    toggleLayoutFeedbackOverlay();
  };

  return (
    <LayoutFeedbackContainer>
      <LayoutFeedbackTitle>
        {currentLayoutFeedback ? 'Layout feedback' : 'Customer layout feedback'}
        <SpanText>{`- ${layoutFeedbackFormattedDate}`}</SpanText>
      </LayoutFeedbackTitle>
      <ContentContainer>
        {currentLayoutFeedback && (
          <React.Fragment>
            <QuoteSymbol padding="8px 8px 0 16px" color={'#6A93C5'} />
            <LayoutFeedbackCopy>{currentLayoutFeedback}</LayoutFeedbackCopy>
          </React.Fragment>
        )}
        <AddFeedbackCta
          onClick={toggleLayoutFeedbackOverlay}
          currentLayoutFeedback={currentLayoutFeedback}
        >
          {anyLayoutSelected ? UserCopy.EditCTATitle : UserCopy.AddCTATitle}
        </AddFeedbackCta>
      </ContentContainer>
      {overlayVisible && (
        <LayoutFeedbackOverlay
          sendLayoutFeedback={onSubmitHandler}
          onClose={toggleLayoutFeedbackOverlay}
          currentLayoutFeedback={currentLayoutFeedback}
          layoutSelected={layoutSelected}
          designName={designName}
        />
      )}
    </LayoutFeedbackContainer>
  );
}

const LayoutFeedbackContainer = styled.div`
  background-color: white;
  color: #000;
`;
LayoutFeedbackContainer.displayName = 'LayoutFeedbackContainer';

const ContentContainer = styled.div`
  position: relative;
  padding: 16px 41px;
  background: #eff3f9;
  border-radius: 4px;
`;
ContentContainer.displayName = 'ContentContainer';

const LayoutFeedbackTitle = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500 !important;
  padding-bottom: 8px;

  flex-direction: row;
`;
LayoutFeedbackTitle.displayName = 'LayoutFeedbackTitle';

const LayoutFeedbackCopy = styled.div`
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
`;
LayoutFeedbackCopy.displayName = 'LayoutFeedbackCopy';

const AddFeedbackCta = styled(SecondaryCta)`
  background-color: rgba(0, 0, 0, 0);
  padding: 7px 14px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  ${(props) =>
    props.currentLayoutFeedback &&
    `
    margin-top: 8px;
  `};
  > span {
    height: auto !important;
    > div {
      font-weight: 500;
      height: auto !important;
    }
  }
`;
AddFeedbackCta.displayName = 'AddFeedbackCta';

const SpanText = styled.span`
  display: flex;
  align-items: center;
  font-weight: 200 !important;
  color: #979797;
  margin-left: 6px;
`;
SpanText.displayName = 'SpanText';
