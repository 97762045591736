import React from 'react';
import styled from 'styled-components';
import {
  SuccessIcon,
  ExclamationIcon,
  ClockIcon,
} from 'sharedComponents/icons';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const DefaultStyles = { width: 18, height: 18 };
// Assuming status null for not started, 1 for processed and 0 for failed
export default function OrderProcessingScrapingStatusIcon(props) {
  let status = props.status;
  let message = props.message || '';
  const popover = (
    <Popover id="popover-basic">
      <p>
        <strong>Scraping Failed</strong>
      </p>
      <p>{message}</p>
    </Popover>
  );

  return (
    <StatusContainer status={status}>
      {status &&
      (status.includes('finished') || status.includes('successful')) ? (
        <SuccessIcon
          style={props.style ? props.style : DefaultStyles}
          circleFill={'white'}
          fill={'#067039'}
        />
      ) : status && status.includes('failed') ? (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={popover}
        >
          <IconDiv>
            <ExclamationIcon
              fill="#ef4d21"
              style={props.style ? props.style : DefaultStyles}
            />
          </IconDiv>
        </OverlayTrigger>
      ) : (
        status &&
        status.includes('in_progress') && (
          <ClockIcon
            style={props.style ? props.style : DefaultStyles}
            fill="#ef4d21"
          />
        )
      )}
    </StatusContainer>
  );
}

const StatusContainer = styled.div`
  color: ${({ status }) => (status === 'finished' ? '#067039' : '#ef4d21')};
  font-size: 12px;
  margin-right: 11px;
  line-height: 10px;

  p {
    font-size: 13px;
    padding-right: 7px;
  }
`;

const IconDiv = styled.div`
  :hover {
    cursor: pointer;
  }
`;
