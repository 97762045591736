import React from 'react';
import { Grid, Table } from 'react-bootstrap';
import { SPACE_STATUS } from '../constants';

const UserList = ({ userList, handleHeaderClick }) => {
  // return null if user list is not yet loaded
  if (!userList) return null;

  const getStatus = flag => SPACE_STATUS[flag];
  const headers = [
    {name: 'Room ID', filterable: true},
    {name: 'Name', filterable: true},
    {name: 'Email', filterable: true},
    {name: 'Room type', filterable: false},
    {name: 'Room Status', filterable: false},
    {name: 'Date P&M submitted', filterable: false},
    {name: 'P&M Status', filterable: false},
    {name: 'User type', filterable: true},
  ];

  return (
    <div>
      <Grid>
        {/* Set up the column headers */}
        <Table striped condensed hover>
          <thead>
            <tr>
              {headers.map((header, index) => {
                const isFilterable = header.filterable;

                if (isFilterable) {
                  return (
                    <th key={`header-filterable-${index}`}>
                      <button onClick={() => handleHeaderClick({ filter: header.name })}>
                        {header.name}
                      </button>
                    </th>
                  );
                }
                
                return (
                  <th key={`header-non-filterable-${index}`}>{header.name}</th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {userList.map((user, i) => {
              const href = `users/${user.user}`;
              const submitted =
                user.measurement_updated_at &&
                user.p_m_status === 'Pending Review'
                  ? new Date(user.measurement_updated_at).toLocaleDateString()
                  : null;
              return (
                <tr key={i}>
                  <td>
                    <a href={`${href}?spaceID=${user.id}`}>{user.id}</a>
                  </td>
                  <td>
                    <a href={href}>
                      {user.styleboard_user_lastname &&
                      user.styleboard_user_firstname ? (
                          `${user.styleboard_user_lastname}, ${user.styleboard_user_firstname}`
                        ) : null}
                    </a>
                  </td>
                  <td>
                    <p>{user.styleboard_user_email}</p>
                  </td>
                  <td>
                    <p>{user.space_type_name}</p>
                  </td>
                  <td>
                    <p>{getStatus(user.processed)}</p>
                  </td>
                  <td>
                    <p>{submitted}</p>
                  </td>
                  <td>
                    <p>{user.p_m_status}</p>
                  </td>
                  <td>
                    <p>
                      {user.styleboard_user_is_staff === 'True' ? (
                        'Staff'
                      ) : (
                        'Customer'
                      )}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Grid>
    </div>
  );
};

export default UserList;
