import React from 'react';
import { Grid, Col, Row, FormControl, Table, Thumbnail } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { NewAdminConfig } from '../config';
import { ProductModel, StyleboardModel } from '../models';

export default class Styleboard extends React.Component {
  constructor(props) {
    super(props);
    this.UNSAFE_componentWillReceiveProps(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const slug = props.routeParams.slug;
    this.setState({
      styleboardModel: new StyleboardModel(),
      styleboardSlug: slug,
    });
    if (slug == null) {
      this.state.styleboardModel.getAllStyleboards(this.setState.bind(this));
    } else {
      this.state.styleboardModel.getStyleboardBySlug(
        slug,
        this.setState.bind(this),
      );
    }
  }

  render() {
    const styleboard = this.state.styleboardModel.styleboard;
    const sblist = this.state.styleboardModel.all_styleboards;

    const onRowClick = (slug) => {
      this.state.styleboardModel.getStyleboardBySlug(
        slug,
        this.setState.bind(this),
      );
    };

    let view_result = (
      <div>
        <br />
      </div>
    );

    if (styleboard != null) {
      view_result = (
        <Grid>
          <Row>
            <Col xs={12}>
              <h1>{styleboard.name}</h1>
            </Col>
          </Row>
          <Row>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Layout Order</th>
                  <th>Style Assignment</th>
                  <th>Include in Lookbook</th>
                </tr>
              </thead>
              <tbody>
                {styleboard.products.map((sbproduct) => {
                  return (
                    <tr key={sbproduct.id.toString()}>
                      <td>{sbproduct.product.name}</td>
                      <td>
                        <Thumbnail
                          src={ProductModel.getPrimaryImage(sbproduct.product)}
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          value={sbproduct.layout_order.toString()}
                        />
                      </td>
                      <td>
                        <FormControl
                          type="text"
                          value={
                            sbproduct.style_assignment ? (
                              sbproduct.style_assignment.toString()
                            ) : (
                              0
                            )
                          }
                        />
                      </td>
                      <td>
                        <FormControl
                          type="checkbox"
                          value={sbproduct.include_in_lookbook}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            ;
          </Row>
        </Grid>
      );
    } else if (sblist != null) {
      view_result = (
        <Grid>
          <Row>
            <Col xs={3}>
              <b>Name</b>
            </Col>
            <Col xs={2}>
              <b>Email</b>
            </Col>
            <Col xs={5}>
              <b>Slug</b>
            </Col>
            <Col xs={2}>
              <b>Sponsor</b>
            </Col>
          </Row>
          {sblist.map(function (sbitem) {
            return (
              <LinkContainer
                to={`${NewAdminConfig.STYLEBOARD}/${sbitem.slug}`}
                onClick={onRowClick.bind(this, sbitem.slug)}
              >
                <Row key={sbitem.slug}>
                  <Col xs={3}>{sbitem.name}</Col>
                  <Col xs={2}>{sbitem.user_email}</Col>
                  <Col xs={5}>{sbitem.slug}</Col>
                  <Col xs={2}>{sbitem.sponsor}</Col>
                </Row>
              </LinkContainer>
            );
          })}
        </Grid>
      );
    } else {
      view_result = <div>Loading...</div>;
    }

    return <div>{view_result}</div>;
  }
}
