import React from 'react';
import { FormControl, Button } from 'react-bootstrap';

import OrderProcessingHistoryMessageIcon from './OrderProcessingHistoryMessageIcon';
import Utils from '../../utils';
import { ORDER_HISTORY_TYPE_NAMES } from './constants';
import OrderProcessingScrapingStatusIcon from './OrderProcessingScrapingStatusIcon';

export default function OrderProcessingHistory({
  orderHistory = {},
  addHistory = () => {},
} = {}) {
  // early exit
  if (!orderHistory.data) {
    return null;
  }

  return (
    <div className="customer-order history-order">
      <h1>Order History</h1>
      Note: "retailer_shipping_start_date" and "retailer_shipping_end_date"
      refer to delivery dates NOT shipping dates.
      <hr />
      <h3>Add Internal Note</h3>
      <FormControl
        style={{ height: 100 }}
        componentClass="textarea"
        placeholder="This note will *not* be disclosed to the customer."
        onChange={(e) => addHistory.editNote(e.target.value)}
        name="internal_note"
        value={addHistory.noteValue() || ''}
      />
      <Button
        style={{ marginTop: '5px', marginBottom: '0px' }}
        className="action-button green"
        onClick={() => addHistory.saveNote()}
      >
        Add Note
      </Button>
      <hr />
      <div className="historyHolder">
        {orderHistory.data.map((row, index) => {
          return (
            <div key={index} className="historyElement">
              <div className="historyHeader" style={{ display: 'flex' }}>
                {row.type === 'scraping' && row.message ? (
                  <OrderProcessingScrapingStatusIcon status={row.message} />
                ) : (
                  <OrderProcessingHistoryMessageIcon type={row.type} />
                )}
                {ORDER_HISTORY_TYPE_NAMES[row.type] || row.type}
                &nbsp;&bull;&nbsp;
                {Utils.getDate(row.datetime)}&nbsp;
                {Utils.getTime(row.datetime, true)}&nbsp;&bull;&nbsp;
                {row.user}
              </div>
              <div className="historyContent">
                {row.message ? (
                  row.type === 'scraping' ? (
                    <div style={{ display: 'flex' }}>
                      <p>{row.message}</p>
                    </div>
                  ) : (
                    row.message.split('\n').map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })
                  )
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
