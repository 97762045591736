import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import React from 'react';

import { auth } from '../../auth';

/**
 * Determine the Release stage.
 * Useful for grouping bugs by environment.
 */
let releaseStage = 'local-dev';
if (process.env.NODE_ENV === 'production') { // first make sure we are using production compiled build
  switch (process.env.REACT_APP_ENV) { // then switch based on environment
    case 'dev':
      releaseStage = 'development';
      break;

    case 'staging':
      releaseStage = 'staging';
      break;

    case 'prod':
      releaseStage = 'production';
      break;

    default:
      break;
  }
}

/**
 * This function is invoked prior to sending bug notifications to bugsnag.
 * Here we can dynamically inject userId and other relevant information.
 * For custom/handled errors, this can be overridden in the options param of notify.
*/
const beforeSend = report => {
  // If available, send user information.
  // Here, we extract user email from
  // the JWT stored in cookies
  if (auth.loggedIn()) {
    const userData = auth.unpackToken()
    report.user = {
      email: userData.email
    };
  }
};

/**
 * The bugsnag client with our configuration options.
 */
let config = {
  apiKey: '2f1f110ba2d7a69a839e291d52184b15',
  appVersion: process.env.REACT_APP_VERSION || 'APP_VERSION_UNDEFINED',
  beforeSend,
  releaseStage,
  notifyReleaseStages: [ 'development', 'production', 'staging' ]
};

if (releaseStage === 'local-dev') {
  config.autoBreadcrumbs = false; // do not hijack console logs
}

let bugsnagClient = bugsnag(config);

if (releaseStage === 'local-dev') {
  // Local dev optimization.
  // Override the notify call to console log.
  bugsnagClient.notify = (error, opts) => {
    console.log(error);
    if (opts) { console.log(opts); }
  };
}

/**
 * Attach bugsnag client to global window object
 * so that we can easily window.bugsnag.notify() anywhere.
 *
 * TODO: this couples the implementation to DOM and makes it
 * not platform agnostic. We should remove all references to
 * window.bugsnag in the code and just import it or investigate
 * using the Provider pattern.
 */
window.bugsnag = bugsnagClient;

/**
 * Default export the bugsnag client.
 * This is what calling components should be importing.
 *
 * Custom Error Reporting:
 * https://docs.bugsnag.com/platforms/browsers/reporting-handled-errors/
 */
export default bugsnagClient;

/**
 * Some helpers for sending notifications with custom metaData.
 * @param {string|Error} message - Either an error message or Error instance.
 * @param {Object} metaData - Custom metaData to include with error report
 */
export const bugsnagInfo = (message, metaData) => {
  _bugsnagCustomNotify(message, metaData, 'info');
};

export const bugsnagWarn = (message, metaData) => {
  _bugsnagCustomNotify(message, metaData, 'warning');
};

export const bugsnagError = (message, metaData) => {
  _bugsnagCustomNotify(message, metaData, 'error');
};

const _bugsnagCustomNotify = (message, metaData, severity) => {
  const error = (typeof message === 'string')
    ? new Error(message)
    : message;

  bugsnagClient.notify(
    error,
    {
      severity,
      metaData
    }
  );
};

/**
 * This is the bugsnag error boundary that wraps the entire React Application.
 * Any unhandled errors or rejections will be caught by this boundary.
 */
export const BugsnagErrorBoundary = bugsnagClient.use(createPlugin(React));
