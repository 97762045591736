import React from 'react';
import styled from 'styled-components';

/**
 * Returns a list of messages on top of the Order Processing page.
 * The list item is colored based on the message.level (success/error/warning/info).
 */
export default function OrderProcessingMessages ({
  messages = []
} = {}) {
  if (!messages.length) {
    return null;
  }

  const getMessageClassName = (message = {}) => {
    switch (message.level) {
      case 'success':
        return 'alert alert-success';
      case 'error':
        return 'alert alert-danger';
      case 'warning':
        return 'alert alert-warning';
      default:
        return 'alert alert-info';
    }
  };

  return (
    <MessageHolderBox>
      {messages.map((message, i) => {
        const messageClassName = getMessageClassName(message);
        const messageContent = message.message;

        return (
          <MessageBox
            key={`message-box-${i}`}
            className={messageClassName}
          >
            <PreformattedTextElement className={messageClassName}>
              {message.header && (<h4>{message.header}</h4>)}

              {JSON.stringify(messageContent, null, 2)}
            </PreformattedTextElement>
          </MessageBox>
        );
      })}
    </MessageHolderBox>
  );
}

const MessageHolderBox = styled.div`
  padding: 10px 10px 0 10px;
`;

const MessageBox = styled.div`
  margin-bottom: 10px;
`;

const PreformattedTextElement = styled.pre`
  border: 0;
`;
