export const STICK_TO_BUDGET_DO_NOT_EXCEED = 3;
export const STICK_TO_BUDGET_GUIDELINE = 1;
export const STICK_TO_BUDGET_PRIORITIZE = 2;

export const DO_NOT_EXCEED_NOT_SELECTED = -1;

export const LOW_PRICE_BAND_STRING = 'Low';
export const MEDIUM_PRICE_BAND_STRING = 'Medium';
export const HIGH_PRICE_BAND_STRING = 'High';

export const ERROR_DO_NOT_EXCEED_AMOUNT = 'Amount to not exceed cannot be null';

// Used to simplify *DRY* special requests data
// Note the below is only for old customer spaces
export const SPECIAL_REQUESTS_MAP = {
  additional_storage_needed: 'Lots of storage',
  extra_lighting: 'Extra lighting',
  kid_friendly: 'Kid friendly',
  pet_friendly: 'Pet friendly',
  tv_viewing_required: 'TV viewing',
};

// For old spaces we have several textareas for Space Details
// so again, let's keep the data dry and simplify our code
export const ROOM_LAYOUT_AND_FUNCTION_OPTIONS = {
  how_to_use_space: {
    header: 'How do you want to use this space?',
    formID: 'formControlsHowToUseSpace',
    identifier: 'isHowToUseSpace',
  },
  layout_considerations: {
    header:
      'Are there specific layout considerations we should know when designing your room?',
    formID: 'formControlsLayoutConsiderations',
    identifier: 'isLayoutConsiderations',
  },
  who_lives_here: {
    header: 'Who lives here?',
    formID: 'formControlsWhoLivesHere',
    identifier: 'isWhoLivesHere',
  },
};

export const CONTENT_DISPLAY_OPTIONS = {
  isAdditionalMedia: false,
  isAnythingElseDesignNeeds: false,
  isColorFamily: false,
  isColorIntensity: false,
  isColorsToAvoid: false,
  isDoYouWantTV: false,
  isFurnitureBrand: false,
  isHowToUseSpace: false,
  isIncludeInLayout: false,
  isIntendedPiecesOther: false,
  isKeepCurrentLayout: false,
  isLayoutMainFocus: false,
  isLayoutConsiderations: false,
  isRoomPreferences: false,
  isSpecialRequests: false,
  isStyleDescription: false,
  isWhoLivesHere: false,
};
