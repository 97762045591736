import React from 'react';
import styled from 'styled-components';

import singleCarotIcon from '../images/single_carot.svg';

const ExpandContainer = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img`
  transform: ${props => props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform .2s ease-out;
`;

export default function ExpandToggle(
  {
    expanded,
  },
) {
  return (
    <ExpandContainer>
      <Icon src={singleCarotIcon} expanded={expanded} alt='single carot icon' />
    </ExpandContainer>
  );
}