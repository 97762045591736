import * as types from '../actions/action-types';

const initialState = {
    assistanceCount: 0,
    assistanceID: 0,
    bShowRedesignRequestModal: false,
    currentDesignNum: null,
    isRequestEditable: false,
    reasons: [],
    styleNote: '',
}

const redesignRequestModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REDESIGN_REQUEST_MODAL_DISPLAY:
      return {
        ...state,
        bShowRedesignRequestModal: !state.bShowRedesignRequestModal 
      }
    case types.SET_CURRENT_DESIGN_NUMBER:
      return Object.assign({}, state, {
        currentDesignNum: action.designID,
    });
    case types.SET_IS_REQUEST_EDITABLE:
      return Object.assign({}, state, {
        isRequestEditable: action.isRequestEditable,
    });
    case types.SET_CURRENT_DESIGNS_REASONS:
    return Object.assign({}, state, {
      reasons: action.reasons,
    });
    case types.SET_CURRENT_DESIGN_STYLE_NOTE:
      return Object.assign({}, state, {
        styleNote: action.styleNote,
    });
    case types.SET_CURRENT_SPACE_ASSISTANCE_COUNT:
      return {
        ...state,
        assistanceCount: action.assistanceCount,
    };
    case types.SET_CURRENT_REDESIGN_REQUEST_ID:
      return {
        ...state,
        assistanceID: action.assistanceID,
    }
    default:
      return state;
  }
};

export default redesignRequestModalReducer;