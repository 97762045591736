import React from 'react';
import { Row, OverlayTrigger } from 'react-bootstrap';
import ImageThumbnail from '../ImageThumbnail';
import { CUSTOM_PROP_STATUS_MAP } from '../../constants';
import { flagTooltips } from '../../tooltips';
import NO_UPLOADED_IMAGES from '../images/no-uploaded-images@3x.png';

const CustomPropThumbnail = ({ index, image, open }) => {
  const photos = image.photos.filter(item => item.filename);
  return (
    <Row className="button-toggler">
      {/* {actionButtons} */}
      <OverlayTrigger
        placement="top"
        overlay={flagTooltips['flag-custom-prop']}
      >
        <div className="flag-custom-prop" />
      </OverlayTrigger>
      <ImageThumbnail
        handleClick={() => open(index)}
        src={photos.length ? photos[0].filename : NO_UPLOADED_IMAGES}
      >
        <div key="footer" className="thumbnail-footer">
          <strong>
            {image.name} ({photos.length} Photo{photos.length !== 1 ? 's' : ''}):&nbsp;
            <span className={CUSTOM_PROP_STATUS_MAP[image.status][1]}>
              {CUSTOM_PROP_STATUS_MAP[image.status][0]}
            </span>
          </strong>
        </div>
      </ImageThumbnail>
    </Row>
  );
};

export default CustomPropThumbnail;
