import { SEGMENT_KEYS } from '../../constants';
import { bugsnagError } from '../bugsnag';

const loadSegment = (token) => {
  let script = document.createElement('script');
  let segmentLoadSnippet = document.createTextNode(`
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
  analytics.load("${token}");
  analytics.page();
  }}();`);
  script.appendChild(segmentLoadSnippet);
  document.head.appendChild(script);
};

export const initSegment = () => {
  // use Development segment key by default if unknown env (else use the environment's segment key)
  const segmentToken = ( process.env.REACT_APP_ENV && Object.keys(SEGMENT_KEYS).includes(process.env.REACT_APP_ENV)
    ? SEGMENT_KEYS[process.env.REACT_APP_ENV]
    : SEGMENT_KEYS['dev']
  )
  loadSegment(segmentToken);
};

export const identifySegment = (id, data={}) => {
  window.analytics.identify(id, data);
};

export const trackSegmentEvent = ({
  event,
  data = {},
}) => {
  if (window.analytics && window.analytics.initialize) {
    try {
      window.analytics.track(event, data);

      if (process.env.REACT_APP_ENV !== 'prod') {
        console.log({event, data});
      }
    } catch (error) {
      bugsnagError(error);
    }
  }
};
