import React from 'react';
import styled from 'styled-components';

import HeroImage from './heroImage';
import { MagnifyGlassIcon } from './icons';

const Container = styled.div`
  width:100%;
  font-family: "proxima-nova", sans-serif;
  padding-bottom: 16px;
`;

const ThumbnailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(124px, 1fr) );
  grid-gap: 8px;
`;

const MainImageContainer = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;
  position: relative;
`;

const MainImage = styled.img`
  width: 100%;
  height: auto;
  padding: 0 0 8px 0;
`;

const ThumbnailWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: auto;
  background: #CCC;
  border: ${ props => props.selected ? '2px solid #EF4E22' : '1px solid rgba(0, 0, 0, 0.1)' };
  position: relative;
`;

const ThumbnailHover = styled.div`
  height:100%;
  width:100%;
  background: #FFF;
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s ease-in-out;

&:hover {
  opacity: 0.35;
}
`;

const Thumbnail = styled.img`
  width: 100%;
`;

const MagnifyGlassContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease, background 0.5s linear; 

  &:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
  }
`;

export default class ImageViewer extends React.Component {
  constructor(props) {
    super(props);
    this.heroImageModal = React.createRef();
  }
  state = {
    currIndex: 0,
    bShowHeroImages: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate() {
    if(this.heroImageModal.current !== null) {
      this.heroImageModal.current.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { bShowHeroImages } = this.state;
    if (bShowHeroImages && this.heroImageModal && !this.heroImageModal.current.contains(event.target)) {
      this.setState({bShowHeroImages: false});
    }
  }

  onClickImage = (currIndex) => {
    this.setState({currIndex});
  }

  handleCurrentHeroImage = (firstParam, secondParam, optionalParam) => {
    const { render } = this.props;
    const end = render.length -1;

    if(firstParam) {
      this.setState({currIndex: this.state.currIndex + 1 > end ? 0 : this.state.currIndex + 1});
    }
    else if (secondParam && !firstParam) {
      this.setState({currIndex: this.state.currIndex - 1 < 0 ? end : this.state.currIndex -1});
    }
    else if (optionalParam && !firstParam && !secondParam) {
      this.setState({bShowHeroImages: false});
    }
  }

  handleKeyDown =(e) => {
    /* store here for now, but ideally we want to create a global key manager for all the keys */
    const leftArrowKey = 'ArrowLeft',
          rightArrowKey = 'ArrowRight',
          escapeKey = 'Escape';

    this.handleCurrentHeroImage(e.key === leftArrowKey, e.key === rightArrowKey, e.key === escapeKey);
  }

  handleHeroImageDisplay = () => {
    this.setState({bShowHeroImages: !this.state.bShowHeroImages});
  }

  render() {
    const { currIndex } = this.state;
    const { render = [], renderThumbnails = true } = this.props;

    return (
      <Container>
      <HeroImage
      ref = { this.heroImageModal }
      { ...this.props }
      { ...this.state }
      { ...this }
      />
      <MainImageContainer onClick = { this.handleHeroImageDisplay } >
        <MagnifyGlassContainer><MagnifyGlassIcon /></MagnifyGlassContainer>
        <MainImage src = { render[currIndex] && render[currIndex].imagefile } />
      </MainImageContainer>
      { renderThumbnails &&
        <ThumbnailsContainer>
        { render.length > 0 &&
          render.map((value, index) => {
            return (
              <ThumbnailWrapper key = { value + index } selected = { currIndex === index } onClick = {() => { this.onClickImage(index) }} >
                <ThumbnailHover />
                <Thumbnail  src = { value.imagefile } alt = { index } />
              </ThumbnailWrapper>
            )
          })
        }
        </ThumbnailsContainer>
      }
      </Container>
    )
  }
}
