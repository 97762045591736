import React, { useState } from 'react';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';

import CaretUpSVG from '../images/caret-up.svg';

export default function EstimatedShipHistory({
  item = {},
  orderShippingHistory = {},
}) {
  const [showTable, setShowTable] = useState(false);

  const { data: shippingLogs = [] } = orderShippingHistory;

  const {
    BodyCell,
    Caret,
    DotSpacer,
    EmptyDataMessage,
    HeadCell,
    Header,
    Wrapper,
  } = styles;

  const formatDate = ({ date, includeTime = false }) => {
    if (!includeTime) {
      return date.toLocaleDateString('en-US');
    }

    const options = {
      timeZone: 'PST',
      timeZoneName: 'short',
      hour: 'numeric',
      minute: 'numeric',
    };

    return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString(
      'en-US',
      options
    )}`;
  };

  const getUserDisplay = ({ email = 'test@abc.com', timestamp = Date() }) => {
    return (
      <>
        {email}
        <DotSpacer>•</DotSpacer>
        {formatDate({ date: timestamp, includeTime: true })}
      </>
    );
  };

  const renderTable = () => {
    if (shippingLogs.length === 0) {
      return (
        <EmptyDataMessage>No shipping history data available</EmptyDataMessage>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <HeadCell style={{ width: '110px' }}>Delivery Start</HeadCell>
            <HeadCell style={{ width: '110px' }}>Delivery End</HeadCell>
            <HeadCell></HeadCell>
          </tr>
        </thead>
        <tbody>
          {shippingLogs
            .sort((a, b) => {
              return (
                new Date(b.change_timestamp) - new Date(a.change_timestamp)
              );
            })
            .map((log) => {
              const {
                shipping_start_date: startDate,
                shipping_end_date: endDate,
                change_timestamp: timestamp,
                user,
              } = log;
              return (
                <tr key={timestamp}>
                  <BodyCell>
                    {startDate
                      ? formatDate({ date: new Date(startDate) })
                      : '-'}
                  </BodyCell>
                  <BodyCell>
                    {endDate ? formatDate({ date: new Date(endDate) }) : '-'}
                  </BodyCell>
                  <BodyCell variant="author">
                    {getUserDisplay({
                      email: user,
                      timestamp: new Date(timestamp),
                    })}
                  </BodyCell>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <Wrapper id={`order-${item.id}-shipment-history`}>
      <Header onClick={() => setShowTable(!showTable)}>
        Estimated Delivery Date History
        <Caret invert={!showTable} src={CaretUpSVG} />
      </Header>

      {showTable ? renderTable() : null}
    </Wrapper>
  );
}

const styles = {
  Caret: styled(Image)`
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    ${(p) =>
      p.invert &&
      `
      transform: rotate(180deg);
    `}
  `,
  DotSpacer: styled.span`
    margin: auto 0.8rem;
  `,
  EmptyDataMessage: styled.div`
    font-style: italic;
    color: #888888;
  `,
  HeadCell: styled.th`
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    letter-spacing: -0.44px;
    color: #888888;
  `,
  Header: styled.h4`
    cursor: pointer;
    margin: 24px 0;
  `,
  BodyCell: styled.td`
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.44px;
    color: #333333;
    padding: 12px 0;

    ${(p) =>
      p.variant === 'author' &&
      `
      font-size: 12px;
      line-height: 16px;
      color: #8B8B8B;
    `}
  `,
  Wrapper: styled.div`
    width: 100%;
    clear: both;
    margin: 2em;
    margin-left: 1em;
  `,
};
