import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import get from 'lodash/get';
import { patchData } from '../../actions/api-patch-request-generator';
import {
  fetchDataIfNeeded,
  dataRefresh,
} from '../../actions/api-data-request-generator';
import { getFeedpageFavorites } from '../../reducers/favorites-list-reducer';
import { getStyleCollections } from '../../reducers/space-data-reducer';
import Utils from '../../utils';

import Vision from './components/Vision';

import { post } from '../../global/request';
import { ApiConfig, WAREHOUSE_API_URL } from '../../config';
import { CONTENT_DISPLAY_OPTIONS } from './components/constants';
import { QUIZ_PRODUCTS_FILTER } from '../../constants';
import { bugsnagError } from '../../services/bugsnag';
import { getLikeReasons } from '../../reducers/like-reasons-reducer';

class VisionContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      styleboardInfo: this.props.data,
      alertVisible: false,
      contentDisplay: CONTENT_DISPLAY_OPTIONS,
      genomeModalProps: {
        show: false,
        close: () => {
          this.closeModal('genomeModal');
        },
        data: [],
      },
      productsModalProps: {
        show: false,
        activePage: 1,
        data: [],
        userAction: QUIZ_PRODUCTS_FILTER.LIKED,
        close: () => {
          this.closeModal('productsModal');
        },
        handleActionChange: this.handleActionChange,
        handlePageSelect: this.handlePageSelect,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeSpaceID, data, quizResultsRefreshV2 } = this.props;

    const { styleboardInfo } = this.state;

    // Used for checking if intended_pieces has changed
    // as this object does not go through the handleChange
    // function like other styleboard items.
    // This is a temp band-aid. Ticket to refactor Intended Pieces is here
    // https://app.asana.com/0/1133576522235947/1182054496047633
    const hasIntendedPieces = data && data.intended_pieces;
    if (
      hasIntendedPieces &&
      styleboardInfo.intended_pieces.length !== data.intended_pieces.length
    ) {
      let copyOfStyleboardInfo = { ...styleboardInfo };
      copyOfStyleboardInfo.intended_pieces = data.intended_pieces;
      this.setState({ styleboardInfo: copyOfStyleboardInfo });
    }

    // Refresh Style_QuizV2
    if (quizResultsRefreshV2 !== prevProps.quizResultsRefreshV2) {
      const quizResultsV2Cache = [
        'space_data',
        activeSpaceID,
        'quiz_resultsV2',
      ];
      const { dispatch, userID } = this.props;
      dispatch(
        fetchDataIfNeeded(
          `${ApiConfig.STYLE_QUIZ_V2}/${userID}/space/${activeSpaceID}?versions=1,2`,
          'quiz_resultsV2',
          { cache: quizResultsV2Cache }
        )
      ).catch((error) => bugsnagError(error));
    }
  }

  /************************ Quiz Results V1 methods ****************************/

  // close modal given modal key (i.e. 'genomeModal')
  closeModal(modalKey) {
    const key = `${modalKey}Props`;
    const newState = Object.assign({}, this.state[key], {
      data: [],
      show: false,
    });
    this.setState(Utils.buildState(key, newState));
  }

  // open modal given modal key (i.e. 'productsModal')
  openModal = (modalKey, data) => {
    const key = `${modalKey}Props`;
    const newState = Object.assign({}, this.state[key], {
      data: data,
      show: true,
    });
    this.setState(Utils.buildState(key, newState));
  };

  // handle products pagination changes
  handlePageSelect = (page) => {
    const newState = Object.assign({}, this.state.productsModalProps, {
      activePage: page,
    });
    this.setState({ productsModalProps: newState });
  };

  // handle when the user selects to see disliked or liked products
  handleActionChange = (action) => {
    const newState = Object.assign({}, this.state.productsModalProps, {
      userAction: action,
      activePage: 1,
    });
    this.setState({ productsModalProps: newState });
  };

  handleSubmitGenome = (styleID) => {
    const { activeSpaceID, dispatch, userID } = this.props;

    axios
      .post(`${ApiConfig.STYLE_QUIZ_V2}/${userID}/space/${activeSpaceID}`, {
        style_combination: styleID,
      })
      .then(() => {
        dispatch(dataRefresh('quiz_resultsV2'));
      })
      .catch((error) => bugsnagError(error));
  };

  /******************* Project Details and Room Style & Color methods *******************/

  setContentDisplayOption = (key) => {
    let contentDisplay = { ...this.state.contentDisplay };
    contentDisplay[key] = !contentDisplay[key];
    this.setState({ contentDisplay });
  };

  resetContentDisplayOptions = () => {
    let contentDisplay = { ...this.state.contentDisplay };
    for (let key in contentDisplay) {
      contentDisplay[key] = false;
    }
    this.setState({ contentDisplay });
  };

  handleChange = async (value, key) => {
    // Since inspiration links are handled locally don't show the save modal
    if (!this.state.alertVisible && key !== 'inspiration_links') {
      this.setState({ alertVisible: true });
    }
    return this.setState({
      styleboardInfo: { ...this.state.styleboardInfo, [key]: value },
    });
  };

  handleAlertDismiss = () => {
    this.setState({ styleboardInfo: this.props.data, alertVisible: false });
  };

  handleSaveResults = () => {
    const { activeSpaceID, data, dispatch } = this.props;
    const { styleboardInfo } = this.state;
    const hasFurnitureData =
      styleboardInfo &&
      styleboardInfo.furniture_brands &&
      styleboardInfo.furniture_brands.length > 0;
    dispatch(
      patchData(
        `${ApiConfig.SPACE_INFO}/${data.id}?heidi=true`,
        'space_info_change',
        {
          data: styleboardInfo,
          params: { space_id: activeSpaceID, ppo: 1 },
        }
      )
    ).then(() => {
      // Lets let the server know to refresh space info data only
      // if there are no furniture brands, otherwise, let the furniture
      // request handle it below
      if (!hasFurnitureData)
        dispatch(dataRefresh('space_info', { space: activeSpaceID }));
    });

    // Looks like furniture brands does not update if patching.
    // From testing, I believe this is the reason why this separate call is in place
    // Also note brands is deprecated for newer spaces
    post(
      `${WAREHOUSE_API_URL}/warehouse/api/styleboardinfo/${data.id}/add_furniture_brand?heidi=true`,
      {
        furniture_brand: styleboardInfo.furniture_brands,
      }
    ).then(() => {
      // Lets let the server know to refresh space info data
      dispatch(dataRefresh('space_info', { space: activeSpaceID }));
    });
    this.resetContentDisplayOptions();
    this.setState({ alertVisible: false });
  };

  sendIntendedPieces = (spaceId, pcs) => {
    post(`${ApiConfig.ADD_INTENDED_PIECE(spaceId)}?heidi=${true}`, {
      pieces: pcs,
    }).then((response) => {
      // need to re-fetch bugdet data in styleboardinfo since it has changed
      this.props.dispatch(
        dataRefresh('space_info', { space: this.props.activeSpaceID })
      );
    });
  };

  render() {
    const {
      activeSpaceID,
      componentType,
      data,
      dispatch,
      error,
      likeReasons,
      packageType,
      productCategories,
      spaceListData,
      spaceListIsFetching,
      styleCollections,
      userFirstName,
      userFeedpageFavorites,
      userQuizData,
    } = this.props;
    const {
      contentDisplay,
      genomeModalProps,
      productsModalProps,
    } = this.state;
    // handle loading and errors
    if (error) {
      return (
        <Alert bsStyle="danger">
          <strong>Hmmm...</strong> Something went wrong fetching the photos and
          measurements.
        </Alert>
      );
    } else if (!data) {
      return <Alert bsStyle="warning">There are no results to load.</Alert>;
    }
    return (
      <Vision
        activeSpaceID={activeSpaceID}
        alertVisible={this.state.alertVisible}
        contentDisplay={contentDisplay}
        componentType={componentType}
        data={this.state.styleboardInfo}
        genomeModalProps={genomeModalProps}
        handleSubmitGenome={this.handleSubmitGenome}
        likeReasons={likeReasons}
        userQuizData={userQuizData}
        dispatch={dispatch}
        handleAlertDismiss={this.handleAlertDismiss}
        handleChange={this.handleChange}
        productsModalProps={productsModalProps}
        handleSaveResults={this.handleSaveResults}
        packageType={packageType}
        productCategories={productCategories}
        openModal={this.openModal}
        setContentDisplayOption={this.setContentDisplayOption}
        sendIntendedPieces={this.sendIntendedPieces}
        spaceListFetching={spaceListIsFetching}
        spaceList={spaceListData}
        spaceInfoV1={data}
        styleCollections={styleCollections}
        userFirstName={userFirstName}
        userFeedpageFavorites={userFeedpageFavorites}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  const userID = state.user_data.user_id;
  const userQuizData = state.user_data.quiz_resultsV2.data;
  const {
    isFetching: spaceListIsFetching,
    data: spaceListData,
  } = state.user_data.space_list;

  return {
    data: get(state, `space_data[${activeSpaceID}].space_info.data.results[0]`),
    likeReasons: getLikeReasons(state),
    userID,
    quizResultsRefreshV2: get(state, 'user_data.quiz_resultsV2.needRefresh'),
    userFeedpageFavorites: getFeedpageFavorites(state),
    userQuizData,
    packageType: get(
      state,
      `space_data[${activeSpaceID}].space_infoV2.data.service_packages_sku[0]`
    ),
    productCategories: get(
      state,
      `space_data[${activeSpaceID}].product_categories.data.results`
    ),
    styleCollections: getStyleCollections(state, activeSpaceID),
    styleboardInfo: get(
      state,
      `space_data[${activeSpaceID}].space_infoV2.data.styleboardinfo[0]`
    ),
    error: get(state, `space_data[${activeSpaceID}].space_info.data.error`),
    activeSpaceID,
    spaceListIsFetching,
    spaceListData: get(spaceListData, 'results').find(
      (space) => space.id === activeSpaceID
    ),
    userFirstName: get(state, 'user_data.basic_information.data.first_name'),
  };
};

export default connect(mapStateToProps)(VisionContainer);
