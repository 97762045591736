import React from 'react';
import MobileCaptureGif from './MobileCaptureGif';
import { connect } from 'react-redux';
import useMobileCaptureGif from './useMobileCaptureGif';

function MobileCaptureGifController({ activeSpaceID }) {
  const { url } = useMobileCaptureGif({
    activeSpaceID
  })
  return (
    <MobileCaptureGif
      url={url}
    />
  )
}

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID || 0;

  return {
    activeSpaceID
  }
}

export default connect(mapStateToProps)(MobileCaptureGifController);
