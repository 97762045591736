import React from 'react';
import { useSelector } from 'react-redux';
import CouponCode from './CouponCode';
import useCouponCode from '../useCouponCode';
import LoadingIcon from '../../../components/LoadingIcon';

export default function CouponCodeController() {
  const data = useSelector((state) => {
    const userID = state.user_data && state.user_data.user_id;
    const userCoupon =
      state.user_data && state.user_data.coupon && state.user_data.coupon.data;
    return {
      userID,
      userCoupon,
    };
  });

  const {
    couponCode,
    couponStatus,
    couponIssueDate,
    couponExpirationDate,
    hasCouponData,
    shouldShowCouponMessage,
    timesUsed,
  } = useCouponCode(data);

  if (!hasCouponData) {
    return <LoadingIcon />;
  }

  return (
    <CouponCode
      couponCode={couponCode}
      couponStatus={couponStatus}
      couponIssueDate={couponIssueDate}
      couponExpirationDate={couponExpirationDate}
      shouldShowCouponMessage={shouldShowCouponMessage}
      timesUsed={timesUsed}
    />
  );
}
