export const EDIT_PROFILE = 'EDIT_PROFILE';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// Login
export const LoginFields = { USERNAME: 'username', PASSWORD: 'password' };

// form
export const BillingFormFieldNames = {
  BILLING_ADDRESS_NAME: 'billingAddressName',
  BILLING_STREET_ADDRESS: 'billingStreetAddress',
  BILLING_CITY: 'billingCity',
  BILLING_STATE: 'billingState',
  BILLING_ZIP: 'billingZipcode',
  BILLING_COUNTRY: 'billingCountry',
  SHIPPING_ADDRESS_NAME: 'shippingAddressName',
  SHIPPING_STREET_ADDRESS: 'shippingStreetAddress',
  SHIPPING_PHONE_NUMBER: 'shippingPhoneNumber',
  SHIPPING_CITY: 'shippingCity',
  SHIPPING_STATE: 'shippingState',
  SHIPPING_ZIP: 'shippingZipcode',
  SHIPPING_COUNTRY: 'shippingCountry',
  CARD_NAME: 'cardName',
  CARD_NUMBER: 'cardNumber',
  EXPIRE_MONTH: 'expireMonth',
  EXPIRE_YEAR: 'expireYear',
  CVC: 'cvc',
  PROMO_CODE: 'promoCode',
  EMAIL: 'email',
  PASSWORD: 'password',
};

// Account Form
export const AccountFields = {
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  OLDPASS: 'oldPass',
  NEWPASS: 'newPass',
  CONFIRMPASS: 'confirmPass',
  EMAIL: 'email',
  EMAILPASS: 'emailPass',
};
// QUESTIONS
export const Questions = {
  ROOMS: [
    ['1', 'Living Room'],
    ['6', 'Bedroom'],
    ['2', 'Dining Room'],
    ['4', 'Office'],
    ['9', 'Nursery'],
    ['10', 'Entryway'],
    // ["3", 'Family Room'],
    // ["5", 'Guest Room'],
    // ["7", 'Guest Bedroom'],
    // ["8", 'Kid\'s Room'],
    // ["11", 'Studio']
    ['999', 'Open Living / Dining Room'],
  ],
  OTHER_ROOM_ID: '9999',
  OTHER_GOAL_ID: '9999',
  OTHER_STYLE_ID: '9999',
  STYLE_NOT_SURE_ID: '6',
  // NOTE: Should return these values from the server
  GOALS_TO_USE: [
    ['1', "I'm redecorating"],
    ['2', "I'm moving"],
    ['3', 'I need help with a layout'],
    ['4', "I'm looking for a few specific pieces (rug, sofa, etc.)"],
    ['5', "I'm moving in with someone"],
  ],
  STYLES: [
    ['1', 'Modern and Minimal'],
    ['2', 'Rustic and Warm'],
    ['3', 'Traditional and Comfortable'],
    ['4', 'Urban and Trendy'],
    ['5', 'Dramatic and Bold'],
    ['9999', 'Other'],
    ['6', "I'm not sure about my style"],
  ],
  WORKFLOW: ['room', 'goal', 'furniture', 'budget', 'style'],
  subcopyLookup: (selectedGoalIds) => {
    const goalsContain = id => selectedGoalIds.indexOf(id) >= 0;
    if (goalsContain('1')) {
      return "We'd love to help you remodel your space!";
    } else if (goalsContain('2')) {
      return "We'd love to help you design your new space!";
    } else if (goalsContain('3')) {
      return "We'd love to help you with your room layout!";
    } else if (goalsContain('4')) {
      return "We'd love to help you find those missing pieces to tie your room together!";
    } else if (goalsContain('5')) {
      return "We'd love to help you put the finishing touches on your space!";
    } else if (goalsContain('9999')) {
      return "We'd love to help you with your home design project!";
    }

    return '';
  },
  SCOPES_TO_USE: [
    [0, 'Starting from scratch', "I'm designing the whole room"],
    [
      1,
      'Somewhere in between',
      "I'm designing around a few pieces I already own",
    ],
    [2, 'Mostly furnished', 'I want to put the finishing touches on my room'],
  ],
};

// select options
export const Months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
export const Years = [
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
];
export const US_STATES = {
  al: 'Alabama',
  ak: 'Alaska',
  as: 'American Samoa',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  de: 'Delaware',
  dc: 'District of Columbia',
  fm: 'Micronesia',
  fl: 'Florida',
  ga: 'Georgia',
  gu: 'Guam',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  mh: 'Marshall Islands',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  mp: 'Northern Mariana Islands',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pw: 'Palau',
  pa: 'Pennsylvania',
  pr: 'Puerto Rico',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  vi: 'U.S. Virgin Islands',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming',
};

export const CA_STATES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
];

export const COUNTRIES = {
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  WF: 'Wallis and Futuna',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BT: 'Bhutan',
  JM: 'Jamaica',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Saint Eustatius and Saba ',
  BR: 'Brazil',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Belarus',
  BZ: 'Belize',
  RU: 'Russia',
  RW: 'Rwanda',
  RS: 'Serbia',
  TL: 'East Timor',
  RE: 'Reunion',
  TM: 'Turkmenistan',
  TJ: 'Tajikistan',
  RO: 'Romania',
  TK: 'Tokelau',
  GW: 'Guinea-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'South Georgia and the South Sandwich Islands',
  GR: 'Greece',
  GQ: 'Equatorial Guinea',
  GP: 'Guadeloupe',
  JP: 'Japan',
  GY: 'Guyana',
  GG: 'Guernsey',
  GF: 'French Guiana',
  GE: 'Georgia',
  GD: 'Grenada',
  GB: 'United Kingdom',
  GA: 'Gabon',
  SV: 'El Salvador',
  GN: 'Guinea',
  GM: 'Gambia',
  GL: 'Greenland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'Oman',
  TN: 'Tunisia',
  JO: 'Jordan',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HM: 'Heard Island and McDonald Islands',
  VE: 'Venezuela',
  PR: 'Puerto Rico',
  PS: 'Palestinian Territory',
  PW: 'Palau',
  PT: 'Portugal',
  SJ: 'Svalbard and Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Iraq',
  PA: 'Panama',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PE: 'Peru',
  PK: 'Pakistan',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  ZM: 'Zambia',
  EH: 'Western Sahara',
  EE: 'Estonia',
  EG: 'Egypt',
  ZA: 'South Africa',
  EC: 'Ecuador',
  IT: 'Italy',
  VN: 'Vietnam',
  SB: 'Solomon Islands',
  ET: 'Ethiopia',
  SO: 'Somalia',
  ZW: 'Zimbabwe',
  SA: 'Saudi Arabia',
  ES: 'Spain',
  ER: 'Eritrea',
  ME: 'Montenegro',
  MD: 'Moldova',
  MG: 'Madagascar',
  MF: 'Saint Martin',
  MA: 'Morocco',
  MC: 'Monaco',
  UZ: 'Uzbekistan',
  MM: 'Myanmar',
  ML: 'Mali',
  MO: 'Macao',
  MN: 'Mongolia',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  MU: 'Mauritius',
  MT: 'Malta',
  MW: 'Malawi',
  MV: 'Maldives',
  MQ: 'Martinique',
  MP: 'Northern Mariana Islands',
  MS: 'Montserrat',
  MR: 'Mauritania',
  IM: 'Isle of Man',
  UG: 'Uganda',
  TZ: 'Tanzania',
  MY: 'Malaysia',
  MX: 'Mexico',
  IL: 'Israel',
  FR: 'France',
  IO: 'British Indian Ocean Territory',
  SH: 'Saint Helena',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NA: 'Namibia',
  VU: 'Vanuatu',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NZ: 'New Zealand',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  CK: 'Cook Islands',
  XK: 'Kosovo',
  CI: 'Ivory Coast',
  CH: 'Switzerland',
  CO: 'Colombia',
  CN: 'China',
  CM: 'Cameroon',
  CL: 'Chile',
  CC: 'Cocos Islands',
  CA: 'Canada',
  CG: 'Republic of the Congo',
  CF: 'Central African Republic',
  CD: 'Democratic Republic of the Congo',
  CZ: 'Czech Republic',
  CY: 'Cyprus',
  CX: 'Christmas Island',
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Cape Verde',
  CU: 'Cuba',
  SZ: 'Swaziland',
  SY: 'Syria',
  SX: 'Sint Maarten',
  KG: 'Kyrgyzstan',
  KE: 'Kenya',
  SS: 'South Sudan',
  SR: 'Suriname',
  KI: 'Kiribati',
  KH: 'Cambodia',
  KN: 'Saint Kitts and Nevis',
  KM: 'Comoros',
  ST: 'Sao Tome and Principe',
  SK: 'Slovakia',
  KR: 'South Korea',
  SI: 'Slovenia',
  KP: 'North Korea',
  KW: 'Kuwait',
  SN: 'Senegal',
  SM: 'San Marino',
  SL: 'Sierra Leone',
  SC: 'Seychelles',
  KZ: 'Kazakhstan',
  KY: 'Cayman Islands',
  SG: 'Singapore',
  SE: 'Sweden',
  SD: 'Sudan',
  DO: 'Dominican Republic',
  DM: 'Dominica',
  DJ: 'Djibouti',
  DK: 'Denmark',
  VG: 'British Virgin Islands',
  DE: 'Germany',
  YE: 'Yemen',
  DZ: 'Algeria',
  US: 'United States',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'United States Minor Outlying Islands',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TT: 'Trinidad and Tobago',
  TR: 'Turkey',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Latvia',
  TO: 'Tonga',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LR: 'Liberia',
  LS: 'Lesotho',
  TH: 'Thailand',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TD: 'Chad',
  TC: 'Turks and Caicos Islands',
  LY: 'Libya',
  VA: 'Vatican',
  VC: 'Saint Vincent and the Grenadines',
  AE: 'United Arab Emirates',
  AD: 'Andorra',
  AG: 'Antigua and Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'U.S. Virgin Islands',
  IS: 'Iceland',
  IR: 'Iran',
  AM: 'Armenia',
  AL: 'Albania',
  AO: 'Angola',
  AQ: 'Antarctica',
  AS: 'American Samoa',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AW: 'Aruba',
  IN: 'India',
  AX: 'Aland Islands',
  AZ: 'Azerbaijan',
  IE: 'Ireland',
  ID: 'Indonesia',
  UA: 'Ukraine',
  QA: 'Qatar',
  MZ: 'Mozambique',
};

// Page Type constants for Welcome Page
export const WP_INITIAL = 'init';
export const WP_NEW_USER_WITHOUT_STYLEQUIZ = 'no_stylequiz';
export const WP_NEW_USER_WITH_STYLEQUIZ = 'completed_stylequiz';

export const SPACE_TYPE_CHOICES_DEFAULT = 999;

// these include unsupported space types that we don't want users
// to be able to choose anymore
export const SPACE_TYPES = {
  1: 'Living Room',
  2: 'Dining Room',
  3: 'Family Room',
  4: 'Office',
  5: 'Guest Room',
  6: 'Master Bedroom',
  7: 'Guest Bedroom',
  8: "Kid's Room",
  9: 'Nursery',
  10: 'Entry',
  11: 'Studio',
  999: 'Open Living / Dining',
  9999: 'Other',
};

export const SPACE_TYPE_CHOICES = {
  1: 'Living Room',
  2: 'Dining Room',
  4: 'Office',
  6: 'Master Bedroom',
  8: "Kid's Room",
  9: 'Nursery',
  10: 'Entry',
  11: 'Studio',
  999: 'Open Living / Dining',
};

export const DesignState = { ACTIVE: 'ready', PENDING: 'pending' };

export const DesignSource = {
  INITIAL: 'initial_design',
  REDESIGN: 'redesign',
  REPLACE: 'replace',
};

export const BillingFormState = { SHIPPING: 0, BILLING: 1, PAYMENT: 2 };

export const PackageType = { PACKAGE: 0, REPLACE: 1, PRODUCT: 26 };

// Dashboard
export const DashboardTab = {
  STYLE_QUIZ: 'stylequiz',
  PHOTOS: 'pm',
  CUSTOM_PROP: 'custom_prop',
};

export const PhotoPhase = {
  INSTRUCTIONS: 0,
  PHOTO: 1,
  MEASURE: 2,
  COMPLETE: 3,
};

export const StyleQuizStatus = {
  COMPLETE: 'completed',
  NOT_STARTED: 'not_started',
};

export const SpaceStatus = {
  INIT: 'init',
  READY: 'ready',
  IN_PROGRESS: 'inprogress',
  DESIGNS_PENDING: 'initial designs pending',
  PENDING: 'pending',
};

export const DashProgress = {
  INITIAL: 0,
  STYLE_QUIZ_COMPLETE: 1,
  PHOTOS_COMPLETE: 2
};

export const MeasurementFields = {
  LENGTH: 'length',
  WIDTH: 'width',
  HEIGHT: 'height',
  UNIT: 'unit',
  COMMENTS: 'comments',
};

export const DashStatus = {
  NOT_STARTED: 'not_started',
  PENDING: 'pending',
  APPROVED: 'approved',
  NG: 'ng',
};

export const SpaceFlags = {
  styleQuiz: 'showTipStyleQuizCompleted',
  pAndMSubmitted: 'showTipPMSubmitted',
  pAndMApproved: 'showTipPMApproved',
  designsReady: 'showTipDesignReady',
};

export const AllDashboardFlags = [
  SpaceFlags.pAndMApproved,
  SpaceFlags.pAndMSubmitted,
  SpaceFlags.styleQuiz,
];

export const sections = {
  Shipping: 'Shipping Address',
  Billing: 'Billing Address',
  Payment: 'Payment Info',
};

export const SignupFormFields = { email: 'email', password: 'password' };

export const ReplacementStatus = { Remove: 'remove' };

export const URGENCIES = {
  1: 'Yes',
  2: 'Not now',
  3: 'No',
};

export const IMAGE_SIZES = {
  SMALL: '_small',
  MEDIUM: '_medium',
  LARGE: '_large',
};

export const S3_URL = 'https://s3-us-west-2.amazonaws.com/modsy';
