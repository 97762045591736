import React from 'react';
import Masonry from 'react-masonry-component';
import { Image } from 'react-bootstrap';

export default class ProductsMasonry extends React.PureComponent {
  constructor(props) {
    super(props);

    // initialize the masonry to not display
    this.state = { masonryDisplay: 'none' };
  }

  // runs after the images have loaded in the masonry
  handleLoaded = () => {
    if (this.state.masonryDisplay === 'none') {
      this.setState({ masonryDisplay: 'block' });
    }
  };

  render() {
    const pageImages = this.props.data;

    // masonry options
    const masonryOptions = {
      columnWidth: 180,
      gutter: 10,
      transitionDuration: 0,
    };

    return (
      <Masonry
        className={'grid'}
        options={masonryOptions}
        onImagesLoaded={this.handleLoaded}
        style={{ display: `${this.state.masonryDisplay}` }}
      >
        {pageImages.map((image, i) => {
          return (
            <div key={i} className="grid-item">
              <Image
                src={`https://s3-us-west-2.amazonaws.com/modsy/ops-assets/style-quiz/${image
                  .product.picture}`}
                thumbnail
              />
            </div>
          );
        })}
      </Masonry>
    );
  }
}
