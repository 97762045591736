import React from 'react';
import { Modal, Button, Panel, Table, Thumbnail } from 'react-bootstrap';
import filter from 'lodash/filter';
import { FileUploaderIcon } from '../../components/FileUploaderIcon';
import { ProductModel, StyleboardModel } from '../../models';
import { ApiConfig } from '../../config';

export default class AssetDownloader extends React.Component {
  constructor(props) {
    super(props);
    const slug = props.routeParams.slug;
    this.state = {
      styleboardModel: new StyleboardModel(),
      styleboardSlug: slug,
      productModel: new ProductModel(),
      activePage: 1,
      showModal: false,
    };
  }

  componentDidMount() {
    this.state.styleboardModel.getStyleboardBySlug(
      this.state.styleboardSlug,
      this.setState.bind(this),
    );
    this.state.styleboardModel.getAssetOptions(this.setState.bind(this));
  }

  close = () => {
    this.props.history.goBack();
  };

  onAssetChange(asset) {
    let sbproduct;
    const sbmodel = this.state.styleboardModel;
    const sbproducts = filter(sbmodel.styleboard.products, (p) => {
      return p.product.id === asset.product;
    });
    for (sbproduct of sbproducts) {
      sbproduct.product.assets.push(asset);
    }
    this.setState({ styleboardModel: sbmodel });
  }

  onRowClick(slug) {
    this.setState({
      showModal: !this.state.showModal,
    });
    // this.state.styleboardModel.getStyleboardBySlug(
    //   slug,
    //   this.setState.bind(this)
    // )
  }

  changePhase(assetId, productIndex, assetIndex, e) {
    let value = null;
    for (let i = 0; i < this.state.styleboardModel.phase_options.length; i++) {
      if (this.state.styleboardModel.phase_options[i][1] === e.target.value) {
        value = this.state.styleboardModel.phase_options[i][0];
        break;
      }
    }

    this.state.styleboardModel.changeAssetStatusOrPhase(
      false,
      productIndex,
      assetIndex,
      assetId,
      value,
      e.target.value,
      this.setState.bind(this),
    );
  }

  changeStatus(assetId, productIndex, assetIndex, e) {
    let value = null;
    for (let i = 0; i < this.state.styleboardModel.status_options.length; i++) {
      if (this.state.styleboardModel.status_options[i][1] === e.target.value) {
        value = this.state.styleboardModel.status_options[i][0];
        break;
      }
    }

    this.state.styleboardModel.changeAssetStatusOrPhase(
      true,
      productIndex,
      assetIndex,
      assetId,
      value,
      e.target.value,
      this.setState.bind(this),
    );
  }

  render() {
    const styleboard =
      this.state.styleboardModel && this.state.styleboardModel.styleboard;

    const { productModel } = this.state;

    let viewResult = <div>Loading...</div>;
    if (styleboard != null) {
      // let firstArg = 0
      // let secondArg = 10
      // if (this.state.activePage !== 1) {
      //   firstArg = this.state.activePage * 10
      //   secondArg = (this.state.activePage + 1) * 10
      // }

      // const styleList = styleboard.products
      //   .filter(product => product.style_assignment)
      //   .sort((a, b) => b.name - a.name)
      //   .slice(firstArg, secondArg)

      const colorlist = ['primary', 'success', 'info', 'warning', 'danger'];
      viewResult = (
        <div>
          {styleboard.products.map((style, index) => {
            return (
              <Panel
                key={index}
                header={style.style_assignment}
                bsStyle={colorlist[index % colorlist.length]}
              >
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Image</th>
                      <th>
                        Assets<small>(file | status)</small>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={style.id.toString()}>
                      <td>{style.product.name}</td>
                      <td>
                        <div style={{ maxWidth: '128px' }}>
                          <Thumbnail
                            src={productModel.getPrimaryImage(style.product)}
                          />
                        </div>
                      </td>
                      <td>
                        <table>
                          <thead>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                          </thead>
                          <tbody>
                            {style.product.assets.map((asset, assetIndex) => {
                              return (
                                <tr key={asset.asset}>
                                  <td key={`${asset.asset}_file`}>
                                    <a href={asset.assetfile}>{asset.asset}</a>
                                    ({asset.updated_at})
                                  </td>
                                  <td key={`${asset.asset}_status`}>
                                    <select
                                      className="status-select"
                                      onChange={this.changeStatus.bind(
                                        this,
                                        asset.pk,
                                        // productIndex,
                                        assetIndex,
                                      )}
                                      value={asset.status}
                                    >
                                      {this.state.styleboardModel
                                        .status_options ? (
                                          this.state.styleboardModel.status_options.map(
                                            (status) => {
                                              return (
                                                <option
                                                  key={status[1]}
                                                  value={status[1]}
                                                >
                                                  {status[1]}
                                                </option>
                                              );
                                            },
                                          )
                                        ) : null}
                                    </select>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <FileUploaderIcon
                          key={style.product.id}
                          uploadUrl={ApiConfig.ASSETS3D}
                          slug={style.product.id}
                          onAssetChange={this.onAssetChange.bind(this)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Panel>
            );
          })}
        </div>
      );
    }

    const myModal = (
      <Modal bsSize="large" show onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>{styleboard && styleboard.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{viewResult}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close}>Close</Button>
        </Modal.Footer>
      </Modal>
    );

    return <div>{myModal}</div>;
  }
}
