/**
 * We just extracted the logic here.
 * But this component still needs refactoring.
 * asana ticket: https://app.asana.com/0/inbox/1131938021244494/1162546395325665/1163161175357890
 */
import React from 'react';
import { Table, Image } from 'react-bootstrap';

import Utils from 'utils';
import { DELIVERY_STATUSES } from '../constants';

function OrderHistoryContainer(props) {
  const { orderHistory, spaceInfo, showImageModal } = props;

  if (!(orderHistory && orderHistory.ok)) {
    return <div>Loading</div>;
  }

  if (!orderHistory.order_items.length) {
    return (
      <div>
        <br />
        There are no furniture purchases for this customer
      </div>
    );
  }

  let budgetText = null;
  if (spaceInfo) {
    let budget = spaceInfo.budget_min || spaceInfo.budget_max;
    if (budget !== -1) {
      let operator = (
        <span style={{ color: 'red', fontWeight: 'bold' }}>-</span>
      );
      if (budget - orderHistory.total < 0) {
        operator = (
          <span style={{ color: 'green', fontWeight: 'bold' }}>+</span>
        );
      }
      budgetText = (
        <span>
          <b style={{ fontWeight: 700 }}>
            Difference between Budget and Spent:{' '}
          </b>{' '}
          {operator}{' '}
          {Utils.formatMoney(Math.abs(budget - orderHistory.total), 2)}
        </span>
      );
    }
  }

  return (
    <div>
      <br />
      <b style={{ fontWeight: 700 }}>Total Amount Spent to Date: </b>{' '}
      {Utils.formatMoney(orderHistory.total, 2)}
      <br />
      {budgetText}
      <br />
      <br />
      <Table id="order_history_table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Design#</th>
            <th>Brand</th>
            <th>Qty</th>
            <th>Product Name</th>
            <th>Price Paid</th>
            <th>Date Purchased</th>
            <th>Delivery Status</th>
            <th>Order ID</th>
            <th>Product ID</th>
          </tr>
        </thead>
        <tbody>
          {orderHistory.order_items.map((item, index) => {
            const orderItemKey = `${index} - ${item.product_id}`;
            let style = {};
            if (!item.quantity) {
              style = { background: '#eee' };
            }
            return (
              <tr style={style} key={orderItemKey}>
                <td>
                  <Image
                    onClick={() =>
                      showImageModal(item.product.images[0].filename)
                    }
                    style={{ width: 75 }}
                    src={
                      item.product.images.length &&
                      item.product.images[0].filename
                    }
                  />
                </td>
                <td>{item.design_id}</td>
                <td>{item.product.source}</td>
                <td>{item.quantity || 'Cancelled'}</td>
                <td>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.product.url}
                  >
                    {item.product.name}
                  </a>
                </td>
                <td>
                  {Utils.formatMoney(item.total - item.tiered_discount, 2)}
                </td>
                <td>{Utils.getDate(item.order.created_at)}</td>
                <td>{DELIVERY_STATUSES[item.delivery_status]}</td>
                <td>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      '/order_processing?search=' +
                      item.order.id +
                      '&orderId=' +
                      item.order.id
                    }
                  >
                    {item.order.order_number}
                  </a>
                </td>
                <td>{item.product_id}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default OrderHistoryContainer;
