import React from 'react';
import styled from 'styled-components';

import {
  BUDGET_CHOICES,
  BUDGET_CHOICES_DEFAULT
} from '../../../../constants';

import EditableDropdownField from '../../../../components/EditableDropdownField';
import EditableDropdownOptions from '../../../../components/EditableDropdownOptions';


export default function OldBudget ({
  budgetMin,
  budgetMax,
  setOldBudget
} = {}) {
  const initialValue = budgetMin === -1 ? BUDGET_CHOICES_DEFAULT : budgetMax;

  return (
    <OldBudgetWrapper>
      <Header>Budget (old PPO question):</Header>
      <EditableDropdownField
        requestGenerator={setOldBudget}
        identifier="space_info"
        initialValue={initialValue}
        linkValue={BUDGET_CHOICES[initialValue]}
      >
        <EditableDropdownOptions
          options={BUDGET_CHOICES}
        />
      </EditableDropdownField>
    </OldBudgetWrapper>
  );
}

const Header = styled.h6`
  font-size: 14px;
  font-weight: 700;
  margin: 0 10px 0 0;
`;
Header.displayName = 'Header';

const OldBudgetWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`;
