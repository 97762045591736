/**
 * Common colors.
 */
export const red = '#EF4E22';
export const redDisabled = '#F9B8A7';
export const redTint = '#F38364';
export const redAlert = '#EB3200';
export const flamingo = '#EC583A';
export const packageWhite = '#F4F3F3';
export const paleBlue = '#F2F5F8';
export const lightBlue = '#B1CDEC';
export const saturatedLightBlue = '#145FCA';
export const hoverBlue = '#B6C9E4';
export const hoverFillBlue = '#B8C9E63D';
export const lightHoverBlue = '#EDF2F8';
export const darkerBlue = '#5A85C4';
export const darkestBlue = '#263D5D';
export const blue = '#4978BC';
export const blueTint = '#6D93C9';
export const darkBlue = '#4B78BA';
export const royalBlue = '#253C5E';
export const eastBayBlue = '#41538D';
export const sanMarinoBlue = '#4C63AD';
export const catskillWhite = '#E9ECF5';
export const havelockBlue = '#6885E3';
export const blackSqueeze = '#EFF3F9';
export const heather = '#BABFCE';
export const gallery = '#EDEDED';
export const purple = '#1D2E4B';
export const green = '#388D61';
export const greenTint = '#388D61';
export const darkGreen = '#067039';
export const yellow = '#FBD206';
export const yellowTint = '#FCDF50';
export const pink = '#F7ACBC';
export const dawnPink = '#F4EEE9';
export const brown = '#7F4720';
export const black = '#000000';
export const blackAlpha = '#00000099';
export const blackVeryAlpha = '#00000016';
export const grey = '#747474';
export const midGrey = '#ACA6A2';
export const lightGrey = '#E3E1DB';
export const darkGrey = '#39393A';
export const buttonGrey = '#CCCCCC';
export const textGrey = '#979797';
export const inputGrey = '#D5D5D5';
export const wildSandGrey = '#F6F6F6';
export const altoGrey = '#D1D1D1';
export const gray = '#8B8B8B';
export const sandBeige = '#FDF8F4';
export const seashell = '#F1F1F1';
export const alabaster = '#FAFAFA';
export const silverChalice = '#B1B1B1';
export const silver = '#BDBDBD';
export const white = '#FFFFFF';
export const whiteHalfAlpha = '#FFFFFF80';
export const semiTransparentBlack = 'rgba(0, 0, 0, 0.8)';
