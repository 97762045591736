import React from 'react';
import capitalize from 'lodash/capitalize';

import { ORDER_HISTORY_MESSAGE_ICONS } from './constants';

export default function OrderProcessingHistoryMessageIcon({
  type = 'default',
} = {}) {
  const currentIcon = ORDER_HISTORY_MESSAGE_ICONS[type];
  const { icon, label } = currentIcon || {};

  return (
    <span
      role="img"
      aria-label={capitalize(label)}
      style={{ opacity: 0.5, marginRight: '13px' }}
    >
      {icon}
    </span>
  );
}
