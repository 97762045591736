import cookie from 'js-cookie';

export const auth = {
  login(email, pass, cb) {
    cb = arguments[arguments.length - 1];
    const token = cookie.get('jwtToken');
    if (token) {
      if (cb) cb(true);
      this.onChange(true);
    }
  },

  getToken() {
    return cookie.get('jwtToken');
  },

  logout(cb) {
    this.onChange(false);
    cookie.remove('jwtToken');
    if (cb) cb(true);
  },

  // taken from https://stackoverflow.com/a/38552302/562117
  unpackToken() {
    const token = cookie.get('jwtToken');
    if (!token) {
      return false;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const ret = JSON.parse(window.atob(base64));
    if (ret.groups) {
      ret.groups = JSON.parse(ret.groups);
    }
    return ret;
  },

  isInGroup(group) {
    const info = this.unpackToken();
    return info && info.groups && info.groups.indexOf(group) >= 0;
  },

  loggedIn() {
    const info = this.unpackToken();
    const now = Math.round(new Date().getTime() / 1000);
    return info && info.exp > now;
  },
  onChange() {},
};
