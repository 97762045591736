import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, PanelGroup, Panel } from 'react-bootstrap';
import styled from 'styled-components';

import RoomLayoutAndFunction from './RoomLayoutAndFunction';
import RoomLayoutAndFunctionV1 from './RoomLayoutAndFunctionV1';
import StyleAndColor from './StyleAndColor';
import BudgetPanel from './Budget';
import ProjectSummary from './ProjectSummary';
import { tabNames } from '../../../constants';

const HeaderText = styled.h4`
  margin-top: 19px;
  margin-bottom: 24px;
`;

class Vision extends React.PureComponent {
  isPPOFlowWithDefaultBudgets = () => {
    // We want to hide the per piece budget for spaces created
    // before the date/time below. Why ? Because we used to give
    // default budgets to these old spaces and this is not relevant
    // to show budgets User has not selected him/herself in Heidi.

    // The date below refers to the commit "Enable classic flow price range":
    // dd8ec4eecb8b0157a911768de2d1c84776abe856
    const { spaceList } = this.props;

    const dateOfPackageCreation = new Date(spaceList.created_at);
    const dateOfDefaultBudgetRemoval = new Date(
      'May 28, 2019 06:45:30 GMT-0700'
    );

    return dateOfPackageCreation < dateOfDefaultBudgetRemoval;
  };

  render() {
    const {
      activeSpaceID,
      alertVisible,
      componentType,
      contentDisplay,
      dispatch,
      genomeModalProps,
      handleAlertDismiss,
      handleChange,
      handleSubmitGenome,
      handleSaveResults,
      likeReasons,
      packageType,
      productCategories,
      openModal,
      productsModalProps,
      sendIntendedPieces,
      setContentDisplayOption,
      showroomInfo,
      spaceList,
      spaceListIsFetching,
      spaceInfoV1,
      styleCollections,
      userFirstName,
      userFeedpageFavorites,
      userQuizData,
    } = this.props;
    const { id: sbInfoId, ...styleboardInfo } = this.props.data;

    // Gets the stored state for the main focus questions
    // so as to determine if we show the question
    const {
      layout_main_focus: layoutMainFocusState,
      layout_main_focus_other: layoutMainFocusOtherState
    } = spaceInfoV1 || {};

    // Used to conditionally hide the mainFocus choices and question as it is now deprecated in the main app
    const doesMainFocusHaveContent = (layoutMainFocusState && layoutMainFocusState.length > 0) || (layoutMainFocusOtherState && layoutMainFocusOtherState !== '');
    return (
      <React.Fragment>
        {alertVisible && (
          <Alert bsStyle="warning" onDismiss={handleAlertDismiss}>
            <h4>Save Vision results</h4>
            <p>If you don't save and refresh page changes will be lost!</p>
            <p>
              <Button onClick={handleAlertDismiss}>Cancel</Button>
              <span> or </span>
              <Button onClick={handleSaveResults}>Save</Button>
            </p>
          </Alert>
        )}
        <React.Fragment>
            {componentType === tabNames.roomStyleColor && (
              <React.Fragment>
                <HeaderText>Room Style & Color</HeaderText>
                <StyleAndColor
                  activeSpaceID={activeSpaceID}
                  contentDisplay={contentDisplay}
                  dispatch={dispatch}
                  genomeModalProps={genomeModalProps}
                  handleSubmitGenome={handleSubmitGenome}
                  handleChange={handleChange}
                  likeReasons={likeReasons}
                  openModal={openModal}
                  packageType={packageType}
                  productsModalProps={productsModalProps}
                  setContentDisplayOption={setContentDisplayOption}
                  showroomInfo={showroomInfo}
                  styleCollections={styleCollections}
                  styleboardInfoId={sbInfoId}
                  userFirstName={userFirstName}
                  userFeedpageFavorites={userFeedpageFavorites}
                  userQuizData={userQuizData}
                  {...styleboardInfo}
                />
              </React.Fragment>
            )}
            {componentType === tabNames.projectDetails && (
              <PanelGroup className="vision-details">
                <Panel
                    defaultExpanded
                    collapsible
                    header="Project Summary"
                    eventKey="0"
                  >
                    <ProjectSummary
                      handleChange={handleChange}
                      styleboardInfo={styleboardInfo}
                      spaceList={spaceList}
                    />
                  </Panel>
                <Panel
                  defaultExpanded
                  collapsible
                  header="Room Layout and Function"
                  eventKey="1"
                >
                  {styleboardInfo.ppo_version > 0 ? (
                    <RoomLayoutAndFunctionV1
                      alertVisible={alertVisible}
                      contentDisplay={contentDisplay}
                      doesMainFocusHaveContent={doesMainFocusHaveContent}
                      handleChange={handleChange}
                      setContentDisplayOption={setContentDisplayOption}
                      spaceList={spaceList}
                      {...styleboardInfo}
                    />
                  ) : (
                    <RoomLayoutAndFunction
                      contentDisplay={contentDisplay}
                      handleChange={handleChange}
                      setContentDisplayOption={setContentDisplayOption}
                      spaceList={spaceList}
                      {...styleboardInfo}
                    />
                  )}
                </Panel>
                <Panel defaultExpanded collapsible header="Budget" eventKey="3">
                  <BudgetPanel
                    {...styleboardInfo}
                    contentDisplay={contentDisplay}
                    dispatch={dispatch}
                    handleChange={handleChange}
                    handleSaveResults={handleSaveResults}
                    isPPOFlowWithDefaultBudgets={this.isPPOFlowWithDefaultBudgets()}
                    productCategories={productCategories}
                    showroomInfo={showroomInfo}
                    sendIntendedPieces={sendIntendedPieces}
                    setContentDisplayOption={setContentDisplayOption}
                    spaceList={spaceList}
                    spaceInfoV1={spaceInfoV1}
                    spaceListIsFetching={spaceListIsFetching}
                    styleboardInfoId={sbInfoId}
                  />
                </Panel>
              </PanelGroup>
            )}
          </React.Fragment>
      </React.Fragment>
    );
  }
}
export default connect()(Vision);
