import React from 'react';
import { FormControl } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Utils from '../../utils';
import { renderMoneySpan } from './utils';
import OrderProcessingDetailsOrderItem from './OrderProcessingDetailsOrderItem';
import OrderSourceDiscounts from './OrderSourceDiscounts';

export default function OrderProcessingDetailsSuborder({
  couponInfo = {},
  datePicker = {},
  dirtyFields = {},
  orderAction = (action, itemId, availableAmount, suborderId) => {},
  orderDetails = {},
  orderSourceDiscounts = {},
  orderShippingHistory = {},
  status = null,
  suborder = {},
  isScrapedOrder = false,
  updateOrderItemDetails = (name, value, orderItemId) => {},
} = {}) {
  // if free shipping has been applied to order check that order item
  // shipping type matches free shipping types and display indicator
  const { free_shipping_types: freeShippingTypes } = couponInfo;
  const eligibleFreeShipProducts = suborder.items.reduce((accum, item) => {
    const shippingType = parseInt(item.properties.shipping_type, 10);
    if (freeShippingTypes && freeShippingTypes.indexOf(shippingType) !== -1) {
      return { ...accum, [item.product_id]: true };
    }
    return accum;
  }, {});
  const suborderContainsFreeShipProducts = !isEmpty(eligibleFreeShipProducts);

  return (
    <div className="suborder">
      <h4>{suborder.name}</h4>

      <OrderSourceDiscounts
        orderSourceDiscounts={orderSourceDiscounts}
        sourceName={suborder.name}
      />

      {suborder.items.map((item, index) => {
        // This block to style children components is over complicated.
        // Needs a replacement with styled-components directly in the child components:
        // asana ticket: https://app.asana.com/0/1131938021244497/1162546395325665
        let className = 'orderitem';
        if (index === 0) className += ' first';
        if (index === suborder.items.length - 1) className += ' last';
        if (index % 2 === 1) className += ' odd';
        else className += ' even';

        const wholesaleClass =
          index === suborder.items.length - 1 ? 'wholesale last' : 'wholesale';
        const isEligibleFreeShipping =
          item.product_id in eligibleFreeShipProducts;
        return (
          <OrderProcessingDetailsOrderItem
            className={className}
            couponInfo={couponInfo}
            datePicker={datePicker}
            dirtyFields={dirtyFields}
            index={index}
            isEligibleFreeShipping={isEligibleFreeShipping}
            item={item}
            key={index}
            orderAction={orderAction}
            orderDetails={orderDetails}
            orderShippingHistory={orderShippingHistory}
            status={status}
            updateOrderItemDetails={updateOrderItemDetails}
            wholesaleClass={wholesaleClass}
            isScrapedOrder={isScrapedOrder}
          />
        );
      })}

      {suborder.quantity > 0 && (
        <div style={{ paddingBottom: '10px' }}>
          <WholesaleTotals
            dirtyFields={dirtyFields}
            suborder={suborder}
            updateOrderItemDetails={updateOrderItemDetails}
          />

          <SuborderTotals
            dirtyFields={dirtyFields}
            suborder={suborder}
            suborderContainsFreeShipProducts={suborderContainsFreeShipProducts}
            updateOrderItemDetails={updateOrderItemDetails}
          />
        </div>
      )}
      <br className="clear" />
    </div>
  );
}

/**
 * As a first attempt to refactor this giant and complicated component,
 * we are breaking down the chunks of code into smaller React components.
 * TODO: Move each one into independent file and add unit testing.
 * asana ticket: https://app.asana.com/0/1131938021244497/1162546395325665
 */
function WholesaleTotals({
  dirtyFields = {},
  suborder = {},
  updateOrderItemDetails = (name, value, orderItemId) => {},
} = {}) {
  const { id, wholesale_shipping_fee, wholesale_subtotal, wholesale_tax } =
    suborder || {};

  return (
    <div className="wholesale_totals">
      <table>
        <tbody>
          <tr>
            <td className="right">
              <strong>Subtotal</strong>
            </td>

            <td>{Utils.formatMoney(wholesale_subtotal, 2)}</td>
          </tr>

          <tr>
            <td className="right">
              <strong>Shipping</strong>
            </td>

            <td>{Utils.formatMoney(wholesale_shipping_fee, 2, true)}</td>
          </tr>

          <tr>
            <td className="right">
              <strong>Tax</strong>
            </td>

            <td>
              {' '}
              <FormControl
                style={{ width: '57px' }}
                onChange={(e) =>
                  updateOrderItemDetails(e.target.name, e.target.value, id)
                }
                name="wholesale_tax"
                value={wholesale_tax}
                className={dirtyFields[`wholesale_tax${id}`] ? 'dirty' : null}
              />
            </td>
          </tr>

          <tr>
            <td className="right">
              <strong>Total</strong>
            </td>

            <td>
              {Utils.formatMoney(
                wholesale_subtotal +
                  1 * wholesale_shipping_fee +
                  1 * wholesale_tax,
                2
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function SuborderTotals({
  dirtyFields = {},
  suborder = {},
  suborderContainsFreeShipProducts = false,
  updateOrderItemDetails = (name, value, orderItemId) => {},
} = {}) {
  const {
    discount,
    refunded_amount,
    retailer_discount,
    shipping_fee,
    subtotal,
    total,
    wholesale_shipping_fee,
    wholesale_subtotal,
    wholesale_tax,
  } = suborder || {};

  return (
    <div className="suborder_totals">
      <table>
        <tbody>
          <tr>
            <td className="right">
              {suborderContainsFreeShipProducts && (
                <FreeShipSuborderMessage>
                  *This suborder contains free shipping eligible products
                </FreeShipSuborderMessage>
              )}
            </td>
          </tr>
          <tr>
            <td className="right">
              <strong>Subtotal</strong>
            </td>

            <td style={{ width: 129 }}>{Utils.formatMoney(subtotal, 2)}</td>
          </tr>

          <tr>
            <td className="right">
              <strong>Shipping</strong>
            </td>

            <td>{shipping_fee}</td>
          </tr>

          {discount ? (
            <tr>
              <td className="right">
                <strong>Modsy Discount</strong>
              </td>

              <td>{Utils.formatMoney(discount, 2)}</td>
            </tr>
          ) : null}

          {retailer_discount ? (
            <tr>
              <td className="right">
                <strong>Retailer Discount</strong>
              </td>

              <td>{Utils.formatMoney(retailer_discount, 2)}</td>
            </tr>
          ) : null}

          {refunded_amount ? (
            <tr>
              <td className="right">
                <strong>Refunded Amount</strong>
              </td>
              <td>{Utils.formatMoney(refunded_amount, 2)}</td>
            </tr>
          ) : null}

          <tr>
            <td className="right">
              <strong>Total</strong>
            </td>

            <td>{Utils.formatMoney(total, 2)}</td>
          </tr>

          <tr>
            <td className="right">
              <strong>Margin</strong>
            </td>

            <td>
              {renderMoneySpan(
                total -
                  wholesale_subtotal -
                  1 * wholesale_shipping_fee -
                  1 * wholesale_tax,
                2,
                true
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

// Styles
const FreeShipSuborderMessage = styled.div`
  color: green;
  font-size: 12px;
`;
