import React from 'react';
import styled, { css } from 'styled-components';

import {
  slideDownOne,
  slideDownTwo,
  slideDownThree,
  slideDownFour,
  slideDownFive,
  slideDownSix
} from './keyframes';

import {
  red,
  blue,
  brown,
  pink,
  darkGreen,
  yellow
} from '../colors';


const Flex = styled.div`
  display: flex;
`;

export const darken = n => `rgba(0, 0, 0, ${n})`;

const delay = 0;
const length = 2000;

const largeSvg = [
  {
    clipPath: '0 0, 62px 63.7px, 62px 95px, 0 31.3px, 0 31.3px, 0 0, 0 0',
    backgroundColor: red,
    animation: css`${slideDownTwo} ${length}ms ${delay}ms infinite`,
  },
  {
    clipPath:
      '62px 0, 0 63.7px, 0 95px, 62px 31.3px, 62px 31.3px, 62px 0, 62px 0',
    backgroundColor: blue,
    animation: css`${slideDownOne} ${length}ms ${delay}ms infinite`,
  }
];

const smallSvg = [
  {
    clipPath: '31px 0px, 31px 31.4px, 0px 63px, 0px 31.5px, 31px 0px',
    backgroundColor: brown,
    animation: css`${slideDownThree} ${length}ms ${delay}ms infinite`,
  },
  {
    clipPath: '31px 31.6px, 31px 63px, 0 31.4px, 0 0, 31px 31.6px',
    backgroundColor: pink,
    animation: css`${slideDownFour} ${length}ms ${delay}ms infinite`,
  },
  {
    clipPath: '0px 31.6px, 0px 63px, 31px 31.4px, 31px 0px, 0px 31.6px',
    backgroundColor: darkGreen,
    animation: css`${slideDownFive} ${length}ms ${delay}ms infinite`,
  },
  {
    clipPath: '0 0, 0 31.4px, 31px 63px, 31px 31.5px, 0 0',
    backgroundColor: yellow,
    animation: css`${slideDownSix} ${length}ms ${delay}ms infinite`,
  }
];

const LargeOverlay = styled.div`
  position: absolute;
  background: 0 0 0 60vmax ${darken(1 / 2)}, 0 0 32px ${darken(1 / 4)};
  overflow: hidden;
  width: 62px;
  height: 95px;
  clip-path: polygon(${p => p.clipPath});
`;

const LargeSvg = styled.div`
  position: absolute;
  width: 62px;
  height: 95px;
  overflow-y: hidden;
  opacity: 1;
  transform: translateY(-32px);
  clip-path: polygon(${p => p.clipPath});
  background-color: ${p => p.backgroundColor};
  animation: ${p => p.animation};
`;

const SmallSvg = styled.div`
  position: absolute;
  width: 31px;
  height: 63px;
  overflow-y: hidden;
  clip-path: polygon(${p => p.clipPath});
  background-color: ${p => p.backgroundColor};
  animation: ${p => p.animation};
  opacity: 1;
  transform: translateY(-32px);
`;

const SmallOverlay = styled.div`
  position: absolute;
  width: 31px;
  height: 63px;
  overflow-y: hidden;
  background: 0 0 0 60vmax ${darken(1 / 2)}, 0 0 32px ${darken(1 / 4)};
  clip-path: polygon(${p => p.clipPath});
`;

const Container = styled.div`
  z-index: 9999999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: ${props => props.position ? props.position : 'fixed'};
  transform: translate(-50%, -50%);
  transform: ${ props => `scale(${ props.scale })` };
`;

const SmallWrap = styled.div`
  position: relative;
  width: 31px;
  height: 65px;
  display: flex;
`;

const LargeWrap = styled.div`
  position: relative;
  width: 62px;
  height: 95px;
  bottom: 32px;
`;

export function Loader ({ scale = 1, position, animated = true, dataTestId = 'modsy-heart-loader' }) {
  return (
    <Container position={position} data-testid={dataTestId} scale = { scale }>
      <Flex>
        {smallSvg.map((svg, index) => (
          <SmallWrap key={index}>
            <SmallSvg {...svg} animation={animated ? svg.animation : ''} />
            <SmallOverlay {...svg} animation={animated ? svg.animation : ''} />
          </SmallWrap>
        ))}
      </Flex>
      <Flex>
        {largeSvg.map((svg, index) => (
          <LargeWrap key={index}>
            <LargeSvg {...svg} animation={animated ? svg.animation : ''} />
            <LargeOverlay {...svg} animation={animated ? svg.animation : ''} />
          </LargeWrap>
        ))}
      </Flex>
    </Container>
  );
}
