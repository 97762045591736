import React from 'react';
import styled from 'styled-components';
import { Radio } from 'react-bootstrap';

import PanelLink from 'sharedComponents/PanelLink/PanelLink';
import { showShapeChoice } from '../events';

export default function SpaceShape ({
  currentShapeTtitle = '',
  dispatch = () => {},
  getShapeChoice = () => {},
  shouldShowShapeChoice = false,
  shapeData = {
    choices: []
  },
  shapeCopy = ''
}) {
  return (
    <Container>
      <PanelLink
        header={shapeCopy}
        link={currentShapeTtitle}
        displayContent={shouldShowShapeChoice}
        onClick={() => {
          dispatch(showShapeChoice());
        }}
      >
        {Object.keys(shapeData.choices).map((key, index) => {
          const { name, selected, title } = shapeData.choices[key] || {};
          return (
            <Radio
              data-testid='shape-choices'
              key={title}
              checked={selected}
              value={title}
              onChange={() => {getShapeChoice(name)}}
            >
              {title}
            </Radio>
          );
        })}

      </PanelLink>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;
