import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { SPACE_TYPES, SPACE_TYPE_CHOICES, SPACE_TYPE_CHOICES_DEFAULT } from '../../../../studio/constants';
import { 
    PROJECT_SCOPE_CHOICES, 
    PROJECT_SCOPE_CHOICES_DEFAULT,
    NO_CONSULTATION,
    CONSULTATION_CHOICES,
    CUSTOMERS_ROOM_PREFERENCES_STR } from '../../../../constants';

import EditableTextField from '../../../../components/EditableTextField';
import EditableDropdownField from '../../../../components/EditableDropdownField';
import EditableDropdownOptions from '../../../../components/EditableDropdownOptions';

import { setSpaceListFieldGenerator } from './ProjectSummaryReducer';
import ProjectSummaryField from './ProjectSummaryField';
import TextareaAutosizeExtended from '../../../../sharedComponents/TextareaAutosizeExtended';
export default function ProjectSummary ({
  handleChange,
  styleboardInfo = {},
  spaceList = {}
} = {}) {
  const redesignReason = get(styleboardInfo, 'room_redesign_reason', 'N/A');
  const projectScope = get(styleboardInfo, 'project_scope', PROJECT_SCOPE_CHOICES_DEFAULT);

  const spaceName = get(spaceList, 'name', '');
  const spaceType = get(spaceList, 'space_type', SPACE_TYPE_CHOICES_DEFAULT);

  // Check that space has a non-default consultation choice selected (has viewed the consultation screen)
  const { consultation, internal_notes } = styleboardInfo;
  const showConsultation = styleboardInfo && typeof consultation === 'number' && consultation !== NO_CONSULTATION;

  // This will not include legacy space types unless the space is already
  // one of those types, then it will show that type as well
  const SpaceTypesWithSelectedLegacy = {...SPACE_TYPE_CHOICES, [spaceType]: SPACE_TYPES[spaceType]};

  return (
    <React.Fragment>
      <ProjectSummaryWrapper>
        <ProjectSummaryColumn>
          <ProjectSummaryField
            header="Room Name"
          >
            <EditableTextField
              requestGenerator={setSpaceListFieldGenerator({ 
                field: 'name',
                styleboardInfo
              })}
              identifier="space_list"
              initialValue={spaceName}
              validationRegex={/^(?!\s*$).+/}
              validationMessage="Name cannot be blank"
              styling="inline-text-field"
            >
              <span>{spaceName}</span>
            </EditableTextField>
          </ProjectSummaryField>

          <ProjectSummaryField
            header="Room Type"
          >
            <EditableDropdownField
              requestGenerator={setSpaceListFieldGenerator({ 
                field: 'space_type',
                styleboardInfo
              })}
              identifier="space_list"
              initialValue={spaceType}
              linkValue={SPACE_TYPES[spaceType]}
            >
              <EditableDropdownOptions
                options={SpaceTypesWithSelectedLegacy}
              />
            </EditableDropdownField>
        </ProjectSummaryField>
        { showConsultation
            && 
            <ProjectSummaryField
            header="Scheduled Consultation"
            >
            {` ${get(CONSULTATION_CHOICES, consultation, CONSULTATION_CHOICES[NO_CONSULTATION])}`}
            <br />&nbsp;
            </ProjectSummaryField>
        }
        </ProjectSummaryColumn>
        <ProjectSummaryColumn>
          <ProjectSummaryField
            header="Scope"
          >
            {PROJECT_SCOPE_CHOICES[projectScope]}
          </ProjectSummaryField>
          <ProjectSummaryField
            header="Reason"
          >
            {redesignReason}
          </ProjectSummaryField>
        </ProjectSummaryColumn>
      </ProjectSummaryWrapper>
      <TextAreaTitle>Internal Room-Specific Notes:</TextAreaTitle>
      <TextareaAutosizeExtended
          placeholder={ CUSTOMERS_ROOM_PREFERENCES_STR }
          value={ internal_notes }
          onChange={e => handleChange(e.target.value, 'internal_notes')}
        />
    </React.Fragment>
    );
}

const ProjectSummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ProjectSummaryColumn = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const TextAreaTitle = styled.div`
/* the ampersands override any styles from stylesheets from the parent */
  &&& {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 8px;
  }
`;
