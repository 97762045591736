import React from 'react';
import $ from 'jquery';
import cookie from 'js-cookie';
import get from 'lodash/get';
import {
  Tab,
  Tabs,
  Alert,
  Col,
  Row,
  Nav,
  NavItem,
  MenuItem,
  NavDropdown,
} from 'react-bootstrap';
import { connect } from 'react-redux';

import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import {
  setSpaceTabKey,
  setSpaceTabID,
  setFilteredSpaces,
} from '../actions/space-data';
import SpaceTabContainer from './SpaceTabContainer';
import LoadingIcon from '../components/LoadingIcon';
import { ApiConfig } from '../config';
import { SPACE_TYPES } from '../studio/constants';
import { SPACE_STATUS_FORMAT } from '../constants';
import { browserHistory } from 'react-router';
import {
  setTransformedListOfUserFavorites,
  setRawListOfUserFavorites,
  setUserFeedpageFavorites,
} from '../actions/favorites-list';
import { getRawListOfUserFavorites } from '../reducers/favorites-list-reducer';
import { bugsnagError } from '../services/bugsnag';
import * as request from '../global/request';
import { setUserAuth } from '../actions/user-auth-actions';

class SpacesContainer extends React.PureComponent {
  constructor() {
    super();
    this.state = { prepurchaseSpace: false };
  }

  loadSpaceList = async () => {
    const { dispatch, userID } = this.props;
    const params = new URLSearchParams(window.location.search);
    const spaceID = params.get('spaceID');
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.SOURCES}`, 'sources', {
        cache: ['sources'],
      })
    ).catch((error) => {
      bugsnagError(error);
    });

    dispatch(
      fetchDataIfNeeded(`${ApiConfig.TAGS}`, 'tags', { cache: ['tags'] })
    ).catch((error) => {
      bugsnagError(error);
    });

    dispatch(
      fetchDataIfNeeded(`${ApiConfig.TAG_CATEGORIES}`, 'tag_categories', {
        cache: ['tag_categories'],
      })
    ).catch((error) => {
      bugsnagError(error);
    });

    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.SPACE_CUSTOM_PROP_PRODUCT_TYPES}`,
        'space_custom_prop_product_types',
        { cache: ['space_custom_prop_product_types'] },
      )
    ).catch((error) => {
      bugsnagError(error);
    });

    const userAuthUrl = `${ApiConfig.USERAUTH}`;

    request
      .get(userAuthUrl)
      .then((response) => {
        if (response && response.data) {
          dispatch(setUserAuth(response.data));
        }
      })
      .catch((error) => bugsnagError(error));

    dispatch(
      fetchDataIfNeeded(`${ApiConfig.SPACE_LIST}/`, 'space_list', {
        params: { user_id: userID },
      })
    )
      .then(() => {
        const { data, activeSpaceKey, error } = this.props;
        if (!error) {
          // filter spaces and load into redux
          const spaces = data.results.filter(
            (obj) => obj.status !== 'pre-purchase'
          );
          spaces.sort((a, b) => {
            return a.id < b.id ? 1 : -1;
          });

          let spaceTabID, tab;
          if (spaceID) {
            spaceTabID = parseInt(spaceID, 10);
            tab = spaces
              .map((space) => space.id === parseInt(spaceID, 10))
              .indexOf(true);

            if (tab === -1) {
              this.setState({ prepurchaseSpace: true });
            }
          } else {
            spaceTabID = spaces[activeSpaceKey].id;
            tab = 0;
          }

          const sortSpaces = spaces.slice();
          sortSpaces.splice(tab, 1);
          sortSpaces.unshift(spaces[tab]);

          dispatch(setSpaceTabID(spaceTabID));
          dispatch(setSpaceTabKey(0));
          dispatch(setFilteredSpaces(sortSpaces));
        }
      })
      .catch((error) => {
        bugsnagError(error);
      });

    /*
    Here we fetch the favorite props data which structure will be similar to below:
    0:
      id: 316124
      like: 1
      space_ids: (2) [536973, 537270]
      __proto__: Object
    1: {space_ids: Array(2), id: 71727, like: 1}
    2: {reasons: Array(2), space_ids: Array(29), id: 33085, like: -1}
    3: {reasons: Array(4), space_ids: Array(0), id: 33086, like: -1}
    4: {reasons: Array(1), space_ids: Array(23), id: 318272, like: -1}
    5: {reasons: Array(1), space_ids: Array(0), id: 21164, like: -1}
    */

    await dispatch(this.fetchAllUserFavorites(userID));

    await dispatch(this.fetchAllUserFeedpageFavorites(userID));

    /**
     * TODO: Clean up the blocks below. Do we need lines 128 - 134 ?
     * Replace AJAX call with request.get
     */
    const favoritesCache = ['user_data', userID, 'favorite_props'];
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.GET_FAVORITES_BY_USER_AND_PAGE({ userId: userID })}`,
        'favorite_props',
        {
          keys: { user: userID },
          cache: favoritesCache,
        }
      )
    )
      .then(() => {
        /*
      We need to compare the favorites data to the products api and
      add likes, reasons, and space_ids to the respective (based off of product id) response results.
      Snippet of finished data api result:
      0:
        additional_info: null
        assets: [{…}]
        id: 316124
        images: [{…}]
        like: 1
        space_ids: (2) [536973, 537270]
      */
        const { rawListOfUserFavorites, dispatch } = this.props || {};

        if (!rawListOfUserFavorites) {
          return;
        }

        const listOfFavoriteProductIds = rawListOfUserFavorites.map(
          (value) => value.id
        );
        const uniqueStringOfAllFavoriteProductIds = listOfFavoriteProductIds
          .map(String)
          .join(',');

        $.ajax({
          type: 'GET',
          dataType: 'json',
          beforeSend: (xhr) => {
            xhr.setRequestHeader(
              'Authorization',
              `JWT ${cookie.get('jwtToken')}`
            );
          },
          xhrFields: {
            withCredentials: true,
          },
          url: ApiConfig.GET_PRODUCT_LIST(uniqueStringOfAllFavoriteProductIds),
          success: (data) => {
            if (!data) {
              return;
            }
            // Here we are appending multiple values to the api data response
            // Specifically we need to get like, space_ids, and reasons, and append that
            // to the data that we send to redux
            const likesDislikesData = data;

            const dataToAppend = {
              like: 'like',
              space_ids: 'space_ids',
              reasons: 'reasons',
            };

            Object.keys(dataToAppend).forEach((key) => {
              likesDislikesData.map(
                (favoriteData) =>
                  (favoriteData[key] = rawListOfUserFavorites
                    .filter((product) => product.id === favoriteData.id)
                    .map((product) => product[key])[0])
              );
            });

            // Dispatches the data to redux to be accessed globally
            dispatch(setTransformedListOfUserFavorites(likesDislikesData));
          },
          error: (xhr, textStatus, errorThrown) => {
            console.error(
              "Can't retrieve favorites props data. Error:  " +
                textStatus +
                ' ' +
                errorThrown +
                ' ' +
                xhr.responseText
            );
          },
        });
      })
      .catch((error) => {
        bugsnagError(error);
      });
  }

  async componentDidMount() {
    await this.loadSpaceList();
  }

  async componentDidUpdate () {
    const { needRefresh } = this.props;

    if (needRefresh) {
      await this.loadSpaceList();
    }
  }

  /**
   * TODO: This is a patch. Ideally we would make the request on scroll
   or when user clicks to see the next page.
   */
  fetchAllUserFavorites = (userID) => async (dispatch) => {
    try {
      const response = await request.get(
        `${ApiConfig.GET_FAVORITES_BY_USER_AND_PAGE({ userId: userID })}`
      );
      // The number of favorites is limited to 50 elements per page
      let currentFavorites = get(response, 'data.results', []);
      const totalNumberOfPages = get(response, 'data.num_pages', 1);

      // Update Redux state with first page of results
      dispatch(setRawListOfUserFavorites(currentFavorites));

      // Iterate through all the pages of favorites
      for (let nextPage = 2; nextPage <= totalNumberOfPages; nextPage++) {
        const nextResponse = await request.get(
          `${ApiConfig.GET_FAVORITES_BY_USER_AND_PAGE({
            userId: userID,
            page: nextPage,
          })}`
        );
        const nextFavorites = get(nextResponse, 'data.results', []);
        currentFavorites = [...currentFavorites, ...nextFavorites];

        // Keep updating Redux state with next page of favorites
        dispatch(setRawListOfUserFavorites(currentFavorites));
      }
    } catch (error) {
      bugsnagError(error);
    }
  };

  /**
   * Fetch User Feedpage Favorites
   */
  fetchAllUserFeedpageFavorites = (userID) => async (dispatch) => {
    try {
      const response = await request.get(
        `${ApiConfig.GET_USER_FEEDPAGE_FAVORITES({ userId: userID })}`
      );

      const feedpageFavorites = get(response, 'data', []);
      // Update Redux state with results
      dispatch(setUserFeedpageFavorites(feedpageFavorites));
    } catch (error) {
      const responseStatus = get(error, 'response.status', null);
      responseStatus !== 404 && bugsnagError(error);
    }
  };

  handleTabSelect = (tab) => {
    const { dispatch, filteredSpaces } = this.props;
    browserHistory.push({
      pathname: window.location.pathname,
      search: '?spaceID=' + filteredSpaces[tab].id,
    });
    dispatch(setSpaceTabID(filteredSpaces[tab].id));
    const sortedSpaces = filteredSpaces.slice();
    if (tab > 3) {
      sortedSpaces.splice(tab, 1);
      sortedSpaces.unshift(filteredSpaces[tab]);
      dispatch(setFilteredSpaces(sortedSpaces));
      dispatch(setSpaceTabKey(0));
    } else {
      dispatch(setSpaceTabKey(tab));
    }
  };

  render() {
    // handle loading and errors
    if (this.props.error) {
      return (
        <Alert bsStyle="danger">
          <strong>Oops!</strong> Something went wrong fetching the user&#39;s
          spaces.
        </Alert>
      );
    } else if (this.props.isFetching) {
      return (
        <Tabs defaultActiveKey={'load'} id="load">
          <Tab eventKey={'load'} title={<LoadingIcon />} />
        </Tabs>
      );
    }

    // check if user has results, otherwise return error message
    if (!this.props.filteredSpaces.length) {
      return (
        <Alert bsStyle="warning">
          <strong>Heads up!</strong> This user doesn&#39;t have any pending or
          completed spaces.
        </Alert>
      );
    }

    let alert;
    if (this.state.prepurchaseSpace) {
      alert = (
        <Alert bsStyle="warning">
          <strong>Heads up!</strong> The space you selected is a pre-purchased
          space; please select a different space from a tab below.
        </Alert>
      );
    }

    // deconstruct Tab
    const { Container, Pane, Content } = Tab;

    const displaySpaces = this.props.filteredSpaces.slice();
    const hiddenSpaces = displaySpaces.splice(4);
    const tabTitle = (space, i) => {
      const title = space?.name || '';
      const status = SPACE_STATUS_FORMAT[space.status] || ' ';
      return (
        <div className="tab-title" key={i}>
          <h4 style={{fontWeight: '600'}}>{title}</h4>
          <p style={{color: '#8B8B8B'}}>{`${space.id} - ${status}`}</p>
        </div>
      );
    };

    const activeSpaceKey = this.props.activeSpaceKey;
    const spacesDropdown = hiddenSpaces.length ? (
      <NavDropdown eventKey="dropdown" className="dropdown spaces-tab-dropdown" title={''}>
        {hiddenSpaces.map((space, i) => {
          const type = SPACE_TYPES[space.space_type];
          const title = `${type} - R${space.id}`;
          const status = SPACE_STATUS_FORMAT[space.status] || 'Not available';
          return (
            <MenuItem eventKey={i + 4} key={i + 4}>
              <div style={{fontWeight: '600', fontSize: '16px'}} >{title}</div>
              <p style={{color: '#8B8B8B'}}>{`${space.id} - ${status}`}</p>
            </MenuItem>
          );
        })}
      </NavDropdown>
    ) : null;

    return (
      <div>
        {alert}
        <Container
          id="room-tabs"
          activeKey={activeSpaceKey}
          onSelect={this.handleTabSelect}
        >
          <Row className="clearfix">
            <Col xs={12}>
              <Nav bsStyle="tabs">
                {displaySpaces.map((space, i) => {
                  return (
                    <NavItem eventKey={i} key={i}>
                      {tabTitle(space, i)}
                    </NavItem>
                  );
                })}
                {spacesDropdown}
              </Nav>
            </Col>
            <Col xs={12}>
              <Content animation className="space-navigation-content">
                {displaySpaces.map((space, i) => {
                  return (
                    <Pane eventKey={i} key={displaySpaces[i].id}>
                      {this.props.activeSpaceKey === i ? (
                        <SpaceTabContainer />
                      ) : null}
                    </Pane>
                  );
                })}
              </Content>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userID = state.user_data.user_id;
  const activeSpaceKey = state.space_data.activeKey;
  const filteredSpaces = state.space_data.filteredSpaces;
  const { isFetching, lastUpdated, data, error, needRefresh } = state.user_data.space_list;

  return {
    isFetching,
    lastUpdated,
    data,
    error,
    userID,
    activeSpaceKey,
    needRefresh,
    filteredSpaces,
    rawListOfUserFavorites: getRawListOfUserFavorites(state),
  };
};

export default connect(mapStateToProps)(SpacesContainer);
