import React from 'react';
import { MenuItem, Row } from 'react-bootstrap';
import PatchButtonContainer from '../containers/PatchButtonContainer';
import LoadingIcon from './LoadingIcon';

const PatchMenuItem = ({
  buttonStyle,
  handleClick,
  children,
  loading,
  response,
  error,
}) => {
  const smallLoading = (
    <Row className="center-text">
      <LoadingIcon size="small" />
    </Row>
  );
  let message;
  if (error) {
    message = <strong className="rejected">Error!</strong>;
  } else if (response) {
    message = <strong className="approved">Success!</strong>;
  }
  return (
    <MenuItem onClick={handleClick} className={buttonStyle}>
      {loading ? smallLoading : children} {message}
    </MenuItem>
  );
};

export default PatchButtonContainer(PatchMenuItem);
