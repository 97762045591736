import React from 'react';
import styled from 'styled-components';

import { Image } from 'react-bootstrap';

import MinusSVG from './images/minus-icon.svg';
import PlusSVG from './images/plus-icon.svg';

export default ({
  value = 0,
  onMinus = () => {},
  onPlus = () => {}
}) => {
  return (
    <Container>
      <Button onClick={onMinus} id='minusButton'>
        <ButtonImage src={MinusSVG} />
      </Button>
      <NumberText>{value}</NumberText>
      <Button onClick={onPlus} id='plusButton'>
        <ButtonImage src={PlusSVG} />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px auto;
`;
Container.displayName = 'Container';

const Button = styled.div`
  position: relative;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;

  height: 26px;
  width: 26px;

  @media (hover: hover) {
    :hover {
      background: #EDF2F8;
      border: 1px solid #B6C9E4;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14);
    }
  }

  :active {
    box-shadow: inset 0 0 2px black;
  }
`;
Button.displayName = 'AmountButton';

const ButtonImage = styled(Image)`
  margin: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;
ButtonImage.displayName = 'ButtonImage';

const NumberText = styled.h6`
  width: 25px;

  text-align: center;
  font-weight: 500;

  margin: auto;
`;
NumberText.displayName = 'NumberText';
