/** Initial Information * */
export const SET_USER_ID = 'SET_USER_ID';

export const SET_LIKE_REASONS = 'SET_LIKE_REASONS';

/* For use with Revision Request and new designs, set a design number */
export const SET_CURRENT_DESIGN_NUMBER = 'SET_CURRENT_DESIGN_NUMBER';

/* Identifier for display state of the revision request modal */
export const SET_REDESIGN_REQUEST_MODAL_DISPLAY =
  'SET_REDESIGN_REQUEST_MODAL_DISPLAY ';

export const SET_IS_REQUEST_EDITABLE = 'SET_IS_REQUEST_EDITABLE';

export const SET_CURRENT_DESIGN_STYLE_NOTE = 'SET_CURRENT_DESIGN_STYLE_NOTE';

export const SET_CURRENT_DESIGNS_REASONS = 'SET_CURRENT_DESIGNS_REASONS';

export const SET_CURRENT_SPACE_ASSISTANCE_COUNT =
  'SET_CURRENT_SPACE_ASSISTANCE_COUNT';

export const SET_CURRENT_REDESIGN_REQUEST_ID =
  'SET_CURRENT_REDESIGN_REQUEST_ID';

/** Space Tab * */
export const SET_ACTIVE_SPACE_TAB_KEY = 'SET_ACTIVE_SPACE_TAB_KEY';
export const SET_ACTIVE_SPACE_TAB_ID = 'SET_ACTIVE_SPACE_TAB_ID';
export const SET_FILTERED_SPACES = 'SET_FILTERED_SPACES';

/** Designs * */
export const SET_ACTIVE_DESIGN = 'SET_ACTIVE_DESIGN';

/** Favorites * */
export const SET_RAW_LIST_OF_USER_FAVORITES = 'SET_RAW_LIST_OF_USER_FAVORITES';
export const SET_TRANSFORMED_LIST_OF_USER_FAVORITES =
  'SET_TRANSFORMED_LIST_OF_USER_FAVORITES';
export const SET_FEEDPAGE_FAVORITES = 'SET_USER_FEEDPAGE_FAVORITES';

/** * -- API GET REQUEST ACTIONS  -- * * */

/* Activity Log */
export const ACTIVITY_LOG_REQUEST = 'ACTIVITY_LOG_REQUEST';
export const ACTIVITY_LOG_SUCCESS = 'ACTIVITY_LOG_SUCCESS';
export const ACTIVITY_LOG_FAILURE = 'ACTIVITY_LOG_FAILURE';
export const ACTIVITY_LOG_REFRESH = 'ACTIVITY_LOG_REFRESH';

/** PRODUCT_CATEGORIES * */
export const PRODUCT_CATEGORIES_REQUEST = 'PRODUCT_CATEGORIES_REQUEST';
export const PRODUCT_CATEGORIES_SUCCESS = 'PRODUCT_CATEGORIES_SUCCESS';
export const PRODUCT_CATEGORIES_FAILURE = 'PRODUCT_CATEGORIES_FAILURE';
export const PRODUCT_CATEGORIES_REFRESH = ' PRODUCT_CATEGORIES_REFRESH';

/** User Information * */
export const BASIC_INFORMATION_REQUEST = 'BASIC_INFORMATION_REQUEST';
export const BASIC_INFORMATION_SUCCESS = 'BASIC_INFORMATION_SUCCESS';
export const BASIC_INFORMATION_FAILURE = 'BASIC_INFORMATION_FAILURE';
export const BASIC_INFORMATION_REFRESH = 'BASIC_INFORMATION_REFRESH';

// this retrieves the send assistance request data
export const SPACE_ASSISTANCE_REQUEST = 'SPACE_ASSISTANCE_REQUEST';
export const SPACE_ASSISTANCE_SUCCESS = 'SPACE_ASSISTANCE_SUCCESS';
export const SPACE_ASSISTANCE_FAILURE = 'SPACE_ASSISTANCE_FAILURE';
export const SPACE_ASSISTANCE_REFRESH = 'SPACE_ASSISTANCE_REFRESH';

/** About You Information * */
export const ABOUT_YOU_REQUEST = 'ABOUT_YOU_REQUEST';
export const ABOUT_YOU_SUCCESS = 'ABOUT_YOU_SUCCESS';
export const ABOUT_YOU_FAILURE = 'ABOUT_YOU_FAILURE';
export const ABOUT_YOU_REFRESH = 'ABOUT_YOU_REFRESH';

/* About You Change Request */
export const ABOUT_YOU_CHANGE_REQUEST = 'ABOUT_YOU_CHANGE_REQUEST';
export const ABOUT_YOU_CHANGE_SUCCESS = 'ABOUT_YOU_CHANGE_SUCCESS';
export const ABOUT_YOU_CHANGE_FAILURE = 'ABOUT_YOU_CHANGE_FAILURE';
export const ABOUT_YOU_CHANGE_REFRESH = 'ABOUT_YOU_CHANGE_REFRESH';

/** Style Quiz Results * */
export const QUIZ_RESULTS_REQUEST = 'QUIZ_RESULTS_REQUEST';
export const QUIZ_RESULTS_SUCCESS = 'QUIZ_RESULTS_SUCCESS';
export const QUIZ_RESULTS_FAILURE = 'QUIZ_RESULTS_FAILURE';
export const QUIZ_RESULTS_REFRESH = ' QUIZ_RESULTS_REFRESH';

/** Quiz Results.  Is a replacement for the original quiz results above
 * as it is backwards compatible with V1 quiz data
 */
export const QUIZ_RESULTSV2_REQUEST = 'QUIZ_RESULTSV2_REQUEST';
export const QUIZ_RESULTSV2_SUCCESS = 'QUIZ_RESULTSV2_SUCCESS';
export const QUIZ_RESULTSV2_FAILURE = 'QUIZ_RESULTSV2_FAILURE';
export const QUIZ_RESULTSV2_REFRESH = 'QUIZ_RESULTSV2_REFRESH';

/** Survey Additional Questions * */
export const SURVEY_ADDITIONAL_QUESTIONS_REQUEST =
  'SURVEY_ADDITIONAL_QUESTIONS_REQUEST';
export const SURVEY_ADDITIONAL_QUESTIONS_SUCCESS =
  'SURVEY_ADDITIONAL_QUESTIONS_SUCCESS';
export const SURVEY_ADDITIONAL_QUESTIONS_FAILURE =
  'SURVEY_ADDITIONAL_QUESTIONS_FAILURE';
export const SURVEY_ADDITIONAL_QUESTIONS_REFRESH =
  ' SURVEY_ADDITIONAL_QUESTIONS_REFRESH';

/** Space List * */
export const SPACE_LIST_REQUEST = 'SPACE_LIST_REQUEST';
export const SPACE_LIST_SUCCESS = 'SPACE_LIST_SUCCESS';
export const SPACE_LIST_FAILURE = 'SPACE_LIST_FAILURE';
export const SPACE_LIST_REFRESH = 'SPACE_LIST_REFRESH';

/** Space List Change * */
export const SPACE_LIST_CHANGE_REQUEST = 'SPACE_LIST_CHANGE_REQUEST';
export const SPACE_LIST_CHANGE_SUCCESS = 'SPACE_LIST_CHANGE_SUCCESS';
export const SPACE_LIST_CHANGE_FAILURE = 'SPACE_LIST_CHANGE_FAILURE';
export const SPACE_LIST_CHANGE_REFRESH = 'SPACE_LIST_CHANGE_REFRESH';

/** Order Information * */
export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAILURE = 'ORDER_FAILURE';
export const ORDER_REFRESH = 'ORDER_REFRESH';

/** Space Information * */
export const SPACE_INFO_REQUEST = 'SPACE_INFO_REQUEST';
export const SPACE_INFO_SUCCESS = 'SPACE_INFO_SUCCESS';
export const SPACE_INFO_FAILURE = 'SPACE_INFO_FAILURE';
export const SPACE_INFO_REFRESH = 'SPACE_INFO_REFRESH';
export const SET_CURRENT_SPACE_STATUS = 'SET_CURRENT_SPACE_STATUS';

/** Design Feedback V2 **/
export const DESIGN_FEEDBACKV2_REQUEST = 'DESIGN_FEEDBACKV2_REQUEST';
export const DESIGN_FEEDBACKV2_SUCCESS = 'DESIGN_FEEDBACKV2_SUCCESS';
export const DESIGN_FEEDBACKV2_FAILURE = 'DESIGN_FEEDBACKV2_FAILURE';
export const DESIGN_FEEDBACKV2_REFRESH = 'DESIGN_FEEDBACKV2_REFRESH';

/** Dimensions  * */
export const DIMENSIONS_REQUEST = 'DIMENSIONS_REQUEST';
export const DIMENSIONS_SUCCESS = 'DIMENSIONS_SUCCESS';
export const DIMENSIONS_FAILURE = 'DIMENSIONS_FAILURE';
export const DIMENSIONS_REFRESH = 'DIMENSIONS_REFRESH';

/** Designs Products * */
export const DESIGN_PRODUCTS_REQUEST = 'DESIGN_PRODUCTS_REQUEST';
export const DESIGN_PRODUCTS_SUCCESS = 'DESIGN_PRODUCTS_SUCCESS';
export const DESIGN_PRODUCTS_FAILURE = 'DESIGN_PRODUCTS_FAILURE';
export const DESIGN_PRODUCTS_REFRESH = 'DESIGN_PRODUCTS_REFRESH';

/** Designs Renders * */
export const RENDERS_REQUEST = 'RENDERS_REQUEST';
export const RENDERS_SUCCESS = 'RENDERS_SUCCESS';
export const RENDERS_FAILURE = 'RENDERS_FAILURE';
export const RENDERS_REFRESH = 'RENDERS_REFRESH';

/** Designs * */
export const DESIGNS_REQUEST = 'DESIGNS_REQUEST';
export const DESIGNS_SUCCESS = 'DESIGNS_SUCCESS';
export const DESIGNS_FAILURE = 'DESIGNS_FAILURE';
export const DESIGNS_REFRESH = 'DESIGNS_REFRESH';

/** Designs Version 2 * */
export const DESIGNSV2_REQUEST = 'DESIGNSV2_REQUEST';
export const DESIGNSV2_SUCCESS = 'DESIGNSV2_SUCCESS';
export const DESIGNSV2_FAILURE = 'DESIGNSV2_FAILURE';
export const DESIGNSV2_REFRESH = 'DESIGNSV2_REFRESH';

/** Design info * */
export const SPACE_INFOV1_REQUEST = 'SPACE_INFOV1_REQUEST';
export const SPACE_INFOV1_SUCCESS = 'SPACE_INFOV1_SUCCESS';
export const SPACE_INFOV1_FAILURE = 'SPACE_INFOV1_FAILURE';
export const SPACE_INFOV1_REFRESH = 'SPACE_INFOV1_REFRESH';

// space info v2
export const SPACE_INFOV2_REQUEST = 'SPACE_INFOV2_REQUEST';
export const SPACE_INFOV2_SUCCESS = 'SPACE_INFOV2_SUCCESS';
export const SPACE_INFOV2_FAILURE = 'SPACE_INFOV2_FAILURE';
export const SPACE_INFOV2_REFRESH = 'SPACE_INFOV2_REFRESH';

/** Custom Props * */
export const CUSTOM_PROPS_REQUEST = 'CUSTOM_PROPS_REQUEST';
export const CUSTOM_PROPS_SUCCESS = 'CUSTOM_PROPS_SUCCESS';
export const CUSTOM_PROPS_FAILURE = 'CUSTOM_PROPS_FAILURE';
export const CUSTOM_PROPS_REFRESH = 'CUSTOM_PROPS_REFRESH';

/** Showroom Info * */
export const SHOWROOM_INFO_REQUEST = 'SHOWROOM_INFO_REQUEST';
export const SHOWROOM_INFO_SUCCESS = 'SHOWROOM_INFO_SUCCESS';
export const SHOWROOM_INFO_FAILURE = 'SHOWROOM_INFO_FAILURE';
export const SHOWROOM_INFO_REFRESH = 'SHOWROOM_INFO_REFRESH';

/** Users * */
export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILURE = 'USERS_FAILURE';
export const USERS_REFRESH = 'USERS_REFRESH';

/** Coupons * */
export const COUPON_REQUEST = 'COUPON_REQUEST';
export const COUPON_SUCCESS = 'COUPON_SUCCESS';
export const COUPON_FAILURE = 'COUPON_FAILURE';
export const COUPON_REFRESH = 'COUPON_REFRESH';

/* Style collection */
export const STYLE_COLLECTIONS_REQUEST = 'STYLE_COLLECTIONS_REQUEST';
export const STYLE_COLLECTIONS_SUCCESS = 'STYLE_COLLECTIONS_SUCCESS';
export const STYLE_COLLECTIONS_FAILURE = 'STYLE_COLLECTIONS_FAILURE';
export const STYLE_COLLECTIONS_REFRESH = 'STYLE_COLLECTIONS_REFRESH';

/** Favorites * */
export const FAVORITE_PROPS_REQUEST = 'FAVORITE_PROPS_REQUEST';
export const FAVORITE_PROPS_SUCCESS = 'FAVORITE_PROPS_SUCCESS';
export const FAVORITE_PROPS_FAILURE = 'FAVORITE_PROPS_FAILURE';
export const FAVORITE_PROPS_REFRESH = 'FAVORITE_PROPS_REFRESH';

/** Sources * */
export const SOURCES_REQUEST = 'SOURCES_REQUEST';
export const SOURCES_SUCCESS = 'SOURCES_SUCCESS';
export const SOURCES_FAILURE = 'SOURCES_FAILURE';
export const SOURCES_REFRESH = 'SOURCES_REFRESH';

/** Tags * */
export const TAGS_REQUEST = 'TAGS_REQUEST';
export const TAGS_SUCCESS = 'TAGS_SUCCESS';
export const TAGS_FAILURE = 'TAGS_FAILURE';
export const TAGS_REFRESH = 'TAGS_REFRESH';

/** Tag Categories * */
export const TAG_CATEGORIES_REQUEST = 'TAG_CATEGORIES_REQUEST';
export const TAG_CATEGORIES_SUCCESS = 'TAG_CATEGORIES_SUCCESS';
export const TAG_CATEGORIES_FAILURE = 'TAG_CATEGORIES_FAILURE';
export const TAG_CATEGORIES_REFRESH = 'TAG_CATEGORIES_REFRESH';

/** Product Types **/
export const SPACE_CUSTOM_PROP_PRODUCT_TYPES_REQUEST =
  'SPACE_CUSTOM_PROP_PRODUCT_TYPES_REQUEST';
export const SPACE_CUSTOM_PROP_PRODUCT_TYPES_SUCCESS =
  'SPACE_CUSTOM_PROP_PRODUCT_TYPES_SUCCESS';
export const SPACE_CUSTOM_PROP_PRODUCT_TYPES_FAILURE =
  'SPACE_CUSTOM_PROP_PRODUCT_TYPES_FAILURE';
export const SPACE_CUSTOM_PROP_PRODUCT_TYPES_REFRESH =
  'SPACE_CUSTOM_PROP_PRODUCT_TYPES_REFRESH';

export const SET_USER_AUTH = 'SET_USER_AUTH';

/** Order Information * */
export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';
export const PRODUCTS_REFRESH = 'PRODUCTS_REFRESH';

/** * -- API PATCH REQUEST ACTIONS  -- * * */

/** User space photos * */
export const USER_PHOTO_STATUS_CHANGE_REQUEST =
  'USER_PHOTO_STATUS_CHANGE_REQUEST';
export const USER_PHOTO_STATUS_CHANGE_SUCCESS =
  'USER_PHOTO_STATUS_CHANGE_SUCCESS';
export const USER_PHOTO_STATUS_CHANGE_FAILURE =
  'USER_PHOTO_STATUS_CHANGE_FAILURE';

/** Custom props * */
export const CUSTOM_PROP_STATUS_CHANGE_REQUEST =
  'CUSTOM_PROP_STATUS_CHANGE_REQUEST';
export const CUSTOM_PROP_STATUS_CHANGE_SUCCESS =
  'CUSTOM_PROP_STATUS_CHANGE_SUCCESS';
export const CUSTOM_PROP_STATUS_CHANGE_FAILURE =
  'CUSTOM_PROP_STATUS_CHANGE_FAILURE';

/** User measurements * */
export const MEASUREMENT_STATUS_CHANGE_REQUEST =
  'USER_PHOTO_STATUS_CHANGE_REQUEST';
export const MEASUREMENT_STATUS_CHANGE_SUCCESS =
  'USER_PHOTO_STATUS_CHANGE_SUCCESS';
export const MEASUREMENT_STATUS_CHANGE_FAILURE =
  'USER_PHOTO_STATUS_CHANGE_FAILURE';

export const MEASUREMENT_FIELD_CHANGE_REQUEST =
  'MEASUREMENT_FIELD_CHANGE_REQUEST';
export const MEASUREMENT_FIELD_CHANGE_SUCCESS =
  'MEASUREMENT_FIELD_CHANGE_SUCCESS';
export const MEASUREMENT_FIELD_CHANGE_FAILURE =
  'MEASUREMENT_FIELD_CHANGE_FAILURE';

/** GDrive upload * */
export const GDRIVE_UPLOAD_REQUEST = 'GDRIVE_UPLOAD_REQUEST';
export const GDRIVE_UPLOAD_SUCCESS = 'GDRIVE_UPLOAD_SUCCESS';
export const GDRIVE_UPLOAD_FAILURE = 'GDRIVE_UPLOAD_FAILURE';

/** Space info change * */
export const SPACE_INFO_CHANGE_REQUEST = 'SPACE_INFO_CHANGE_REQUEST';
export const SPACE_INFO_CHANGE_SUCCESS = 'SPACE_INFO_CHANGE_SUCCESS';
export const SPACE_INFO_CHANGE_FAILURE = 'SPACE_INFO_CHANGE_FAILURE';

/** User photo uploads * */
export const USER_PHOTO_UPLOAD_REQUEST = 'USER_PHOTO_UPLOAD_REQUEST';
export const USER_PHOTO_UPLOAD_SUCCESS = 'USER_PHOTO_UPLOAD_SUCCESS';
export const USER_PHOTO_UPLOAD_FAILURE = 'USER_PHOTO_UPLOAD_FAILURE';

/** Send user photo approved email * */
export const SEND_PHOTOS_APPROVED_EMAIL_REQUEST =
  'SEND_PHOTOS_APPROVED_EMAIL_REQUEST';
export const SEND_PHOTOS_APPROVED_EMAIL_SUCCESS = 'USER_PHOTO_UPLOAD_SUCCESS';
export const SEND_PHOTOS_APPROVED_EMAIL_FAILURE =
  'SEND_PHOTOS_APPROVED_EMAIL_FAILURE';

/** Custom prop photo uploads * */
export const CUSTOM_PROP_PHOTO_UPLOAD_REQUEST =
  'CUSTOM_PROP_PHOTO_UPLOAD_REQUEST';
export const CUSTOM_PROP_PHOTO_UPLOAD_SUCCESS =
  'CUSTOM_PROP_PHOTO_UPLOAD_SUCCESS';
export const CUSTOM_PROP_PHOTO_UPLOAD_FAILURE =
  'CUSTOM_PROP_PHOTO_UPLOAD_FAILURE';

/** Custom prop field changes * */
export const CUSTOM_PROP_FIELD_CHANGE_REQUEST =
  'CUSTOM_PROP_FIELD_CHANGE_REQUEST';
export const CUSTOM_PROP_FIELD_CHANGE_SUCCESS =
  'CUSTOM_PROP_FIELD_CHANGE_SUCCESS';
export const CUSTOM_PROP_FIELD_CHANGE_FAILURE =
  'CUSTOM_PROP_FIELD_CHANGE_FAILURE';

/** Custom prop submit * */
export const CUSTOM_PROP_SUBMIT_REQUEST = 'CUSTOM_PROP_SUBMIT_REQUEST';
export const CUSTOM_PROP_SUBMIT_SUCCESS = 'CUSTOM_PROP_SUBMIT_SUCCESS';
export const CUSTOM_PROP_SUBMIT_FAILURE = 'CUSTOM_PROP_SUBMIT_FAILURE';

export const FEATURED_USERIMAGE_REQUEST = 'FEATURED_USERIMAGE_REQUEST';
export const FEATURED_USERIMAGE_SUCCESS = 'FEATURED_USERIMAGE_SUCCESS';
export const FEATURED_USERIMAGE_FAILURE = 'FEATURED_USERIMAGE_FAILURE';

export const GET_ORDERS_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDER_FAILURE';
export const GET_ORDERS_REFRESH = 'GET_ORDER_REFRESH';

export const GET_ORDER_LIST_REQUEST = 'GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_FAILURE = 'GET_ORDER_LIST_FAILURE';
export const GET_ORDER_LIST_REFRESH = 'GET_ORDER_LIST_REFRESH';

/** Retrieve PPO Data * */
export const PPO_ROOM_DETAILS_REQUEST = 'PPO_ROOM_DETAILS_REQUEST';
export const PPO_ROOM_DETAILS_SUCCESS = 'PPO_ROOM_DETAILS_SUCCESS';
export const PPO_ROOM_DETAILS_FAILURE = 'PPO_ROOM_DETAILS_FAILURE';
export const PPO_ROOM_DETAILS_REFRESH = 'PPO_ROOM_DETAILS_REFRESH';

/** Additional images, typically floor and paint swatches  */
export const ADDITIONAL_PHOTOS_REQUEST = 'ADDITIONAL_PHOTOS_REQUEST';
export const ADDITIONAL_PHOTOS_SUCCESS = 'ADDITIONAL_PHOTOS_SUCCESS';
export const ADDITIONAL_PHOTOS_FAILURE = 'ADDITIONAL_PHOTOS_FAILURE';
export const ADDITIONAL_PHOTOS_REFRESH = 'ADDITIONAL_PHOTOS_REFRESH';

export const ADDITIONAL_PHOTOS_UPLOAD_REQUEST =
  'ADDITIONAL_PHOTOS_UPLOAD_REQUEST';
export const ADDITIONAL_PHOTOS_UPLOAD_SUCCESS =
  'ADDITIONAL_PHOTOS_UPLOAD_SUCCESS';
export const ADDITIONAL_PHOTOS_UPLOAD_FAILURE =
  'ADDITIONAL_PHOTOS_UPLOAD_FAILURE';
export const ADDITIONAL_PHOTOS_UPLOAD_REFRESH =
  'ADDITIONAL_PHOTOS_UPLOAD_REFRESH';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';
export const ORDER_DETAILS_REFRESH = 'ORDER_DETAILS_REFRESH';

export const ORDER_HISTORY_REQUEST = 'ORDER_HISTORY_REQUEST';
export const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
export const ORDER_HISTORY_FAILURE = 'ORDER_HISTORY_FAILURE';
export const ORDER_HISTORY_REFRESH = 'ORDER_HISTORY_REFRESH';

export const ORDER_SHIPPING_HISTORY_REQUEST = 'ORDER_SHIPPING_HISTORY_REQUEST';
export const ORDER_SHIPPING_HISTORY_SUCCESS = 'ORDER_SHIPPING_HISTORY_SUCCESS';
export const ORDER_SHIPPING_HISTORY_FAILURE = 'ORDER_SHIPPING_HISTORY_FAILURE';
export const ORDER_SHIPPING_HISTORY_REFRESH = 'ORDER_SHIPPING_HISTORY_REFRESH';

export const ORDER_SOURCE_DISCOUNTS_REQUEST = 'ORDER_SOURCE_DISCOUNTS_REQUEST';
export const ORDER_SOURCE_DISCOUNTS_SUCCESS = 'ORDER_SOURCE_DISCOUNTS_SUCCESS';
export const ORDER_SOURCE_DISCOUNTS_FAILURE = 'ORDER_SOURCE_DISCOUNTS_FAILURE';
export const ORDER_SOURCE_DISCOUNTS_REFRESH = 'ORDER_SOURCE_DISCOUNTS_REFRESH';

export const USERS_STYLISTS_REQUEST = 'USERS_STYLISTS_REQUEST';
export const USERS_STYLISTS_SUCCESS = 'USERS_STYLISTS_SUCCESS';
export const USERS_STYLISTS_FAILURE = 'USERS_STYLISTS_FAILURE';
export const USERS_STYLISTS_REFRESH = 'USERS_STYLISTS_REFRESH';

export const USERS_DESIGNERS_REQUEST = 'USERS_DESIGNERS_REQUEST';
export const USERS_DESIGNERS_SUCCESS = 'USERS_DESIGNERS_SUCCESS';
export const USERS_DESIGNERS_FAILURE = 'USERS_DESIGNERS_FAILURE';
export const USERS_DESIGNERS_REFRESH = 'USERS_DESIGNERS_REFRESH';

export const BILLING_INFO_CHANGE_REQUEST = 'BILLING_INFO_CHANGE_REQUEST';
export const BILLING_INFO_CHANGE_SUCCESS = 'BILLING_INFO_CHANGE_SUCCESS';
export const BILLING_INFO_CHANGE_FAILURE = 'BILLING_INFO_CHANGE_FAILURE';

export const PURCHASING_AGENTS_REQUEST = 'PURCHASING_AGENTS_REQUEST';
export const PURCHASING_AGENTS_SUCCESS = 'PURCHASING_AGENTS_SUCCESS';
export const PURCHASING_AGENTS_FAILURE = 'PURCHASING_AGENTS_FAILURE';
export const PURCHASING_AGENTS_REFRESH = 'PURCHASING_AGENTS_REFRESH';
