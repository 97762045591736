import { trackSegmentEvent } from './segment';

export const trackCreateRedesignRequest = (createRedesignData) => {
  trackSegmentEvent({
    event: 'heidi_create_redesign_request',
    data: createRedesignData
  });
};

export const trackCreateRedesignRequestFailToSaveToStanza = (createRedesignData) => {
  trackSegmentEvent({
    event: 'heidi_create_redesign_request_fail_to_save_to_stanza',
    data: createRedesignData
  });
};

export const trackCreateRedesignRequestFailToSaveToWarehouse = (createRedesignData) => {
  trackSegmentEvent({
    event: 'heidi_create_redesign_request_fail_to_save_to_wh',
    data: createRedesignData
  });
};