import * as types from '../actions/action-types';

const activeDesignReducer = (state = 1, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_DESIGN:
      return action.design;
    default:
      return state;
  }
};

export default activeDesignReducer;
