import React from 'react';
import {
  Image,
  FormControl,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';

import Utils from '../../utils';
import { useFlag } from '../../services/configcat';
import { getStatusList } from './utils';

export default function OrderProcessingDetailsWholesale({
  dirtyFields = {},
  order_details = {},
  updateOrderItemDetails = (name, value, orderItemId) => {},
  suborders = {},
} = {}) {
  const useFormattedProductImageUrl = useFlag('useFormattedProductImageUrl');

  const data = order_details.data;

  // TODO, limit/cull this list based on the current status, ie don't allow invalid "status moving"
  const ORDER_STATUSES = getStatusList(data.order_details.status_original);

  let order_status_name = '';
  for (let i = 0; i < ORDER_STATUSES.length; i++) {
    if (ORDER_STATUSES[i].id === data.order_details.status) {
      order_status_name = ORDER_STATUSES[i].name;
    }
  }

  return (
    <div className="customer-order wholesale-order">
      <h1>Wholesale Order</h1>
      <hr />
      <ButtonToolbar style={{ float: 'right' }}>
        <DropdownButton
          title={`${order_status_name}`}
          id="status"
          onSelect={(val) => updateOrderItemDetails('order_status', val)}
        >
          {ORDER_STATUSES.map((entry, i) => (
            <MenuItem key={entry.id} eventKey={entry.id}>
              {entry.name}
            </MenuItem>
          ))}
        </DropdownButton>
      </ButtonToolbar>
      <strong>Order Number: {data.order_details.order_number}</strong> <br />
      <strong>Order Id: {data.order_details.id}</strong> <br />
      Date Placed: {Utils.getDate(data.order_details.created_at)} <br />
      <h3>Order Details</h3>
      {Object.keys(suborders).map((suborder_id) => {
        // loop on suborders
        const suborder = suborders[suborder_id];
        if (!suborder.quantity || suborder.quantity === '0') return true; // just don't display quan=0 items
        return (
          <div key={suborder_id} className="suborder">
            <h4>{suborder.name}</h4>
            {suborder.items.map((item, index) => {
              // loop on items
              if (!item.quantity || item.quantity === '0') return true; // just don't display quan=0 items

              const product = order_details.data.products[item.product_id];

              const productImageUrl = useFormattedProductImageUrl
                ? product.product_image
                : `https://modsy.s3.amazonaws.com/${product.product_image}`;

              return (
                <div key={index}>
                  <div className="col1">
                    <a
                      href={product.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image src={productImageUrl} />
                    </a>
                  </div>
                  <div className="orderitem">
                    <div
                      style={{
                        paddingLeft: 10,
                        paddingTop: 5,
                        fontSize: '16px',
                      }}
                    >
                      {product.name} (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.warehouse.modsy.com/admin/warehouse/product/${item.product_id}/`}
                      >
                        {item.product_id}
                      </a>
                      )
                    </div>

                    <div className="col2">
                      <table>
                        <tbody>
                          <tr>
                            <td className="right fieldLabel">Retailer Price</td>
                            <td>
                              <FormControl
                                onChange={(e) =>
                                  updateOrderItemDetails(
                                    e.target.name,
                                    e.target.value,
                                    item.id
                                  )
                                }
                                name="wholesale_price"
                                value={item.properties.wholesale_price}
                                className={
                                  dirtyFields[`wholesale_price${item.id}`]
                                    ? 'dirty'
                                    : null
                                }
                              />
                              <br />
                              {item.properties.wholesale_cost &&
                              parseFloat(item.properties.wholesale_cost) >
                                0.01 ? (
                                <button
                                  onClick={() =>
                                    updateOrderItemDetails(
                                      'wholesale_price',
                                      item.properties.wholesale_cost,
                                      item.id
                                    )
                                  }
                                >
                                  <span role="img" aria-label="Up">
                                    ☝️
                                  </span>
                                  {Utils.formatMoney(
                                    item.properties.wholesale_cost,
                                    2,
                                    true
                                  )}
                                </button>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td className="right fieldLabel">Quantity</td>
                            <td>{item.quantity}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col3" />
                  </div>
                  <br className="clear" />
                </div>
              );
              // end of item loop
            })}

            <div className="clear">
              <table>
                <tbody>
                  <tr>
                    <td className="right">
                      <strong>Subtotal</strong>
                    </td>
                    <td>{Utils.formatMoney(suborder.wholesale_subtotal, 2)}</td>
                  </tr>
                  <tr>
                    <td className="right">
                      <strong>Shipping</strong>
                    </td>

                    <td>
                      {Utils.formatMoney(
                        suborder.wholesale_shipping_fee,
                        2,
                        true
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="right">
                      <strong>Tax</strong>
                    </td>
                    <td>
                      {' '}
                      <FormControl
                        style={{ width: '57px' }}
                        onChange={(e) =>
                          updateOrderItemDetails(
                            e.target.name,
                            e.target.value,
                            suborder.id
                          )
                        }
                        name="wholesale_tax"
                        value={suborder.wholesale_tax}
                        className={
                          dirtyFields[`wholesale_tax${suborder.id}`]
                            ? 'dirty'
                            : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="right">
                      <strong>Total</strong>
                    </td>
                    <td>
                      {Utils.formatMoney(
                        suborder.wholesale_subtotal +
                          1 * suborder.wholesale_shipping_fee +
                          1 * suborder.wholesale_tax,
                        2
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
        // end of suborder loop
      })}
    </div>
  );
}
