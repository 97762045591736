import React from 'react';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import { GRID_COLUMN_COUNT } from '../QuizResults/constants';
import {
  ContainerGrid,
  GridItem,
  Header,
  MainContainer,
} from '../QuizResults/styles';
import { ImageIcon } from '../StyleMatch/styles';
import { UserFacingCopy } from './utils';
import NewLinkIcon from '../../../../images/newLinkIcon.svg';

/**
 * Component to Render Liked Design Feed Pages
 * Data for favorited pages is stored in the warehouse
 * response in SpacesContainer - fetchAllUserFeedpageFavorites
 * and stored in redux by favorites-list-reducer
 */
export default function LikedDesignIdeas({
  userFirstName = '',
  userFeedpageFavorites = [],
}) {
  const HeaderStr = `All of ${capitalize(userFirstName)}’s liked design ideas`;
  const hasFavorites =
    userFeedpageFavorites &&
    Array.isArray(userFeedpageFavorites) &&
    userFeedpageFavorites.length > 0;

  const formattedUserFeedpageFavorites = [...userFeedpageFavorites];

  const headerInfo = { title: 'Post Title', room_type: 'Room Type' };
  // added the headerInfo to the copied array to keep the DOM
  // implementation simplier
  formattedUserFeedpageFavorites.unshift(headerInfo);

  return (
    <MainContainer>
      <Header>{HeaderStr}</Header>
      {hasFavorites ? (
        <React.Fragment>
          <ContainerGrid gridTemplateColumns={GRID_COLUMN_COUNT}>
            {formattedUserFeedpageFavorites.map((key, index) => {
              const roomType = key.room_type;
              const title = key.title;
              const slug = key.slug;
              const style = key.style;

              const feedpageURL = getURLForFeedpageLink({
                roomType,
                style,
                slug,
              });
              return (
                <React.Fragment key={index}>
                  {/* This is the first column, with a header titled Post Title */}
                  {handleFirstColumnFormatting(feedpageURL, index, title)}
                  {/* this is the second column, with a header titled Room Type */}
                  <GridItem
                    textTransform={'capitalize'}
                    isHeaderItem={index === 0}
                  >
                    {removeDashesInRoomType(roomType)}
                  </GridItem>
                </React.Fragment>
              );
            })}
          </ContainerGrid>
        </React.Fragment>
      ) : (
        <div id="no-favorite-data">{UserFacingCopy.NoFavoritesTitle}</div>
      )}
    </MainContainer>
  );
}

/**
 * Helpers
 */

// With help from CSS capitalize converts
// the URL version of room to a readable
// format by removing the dashes
export const removeDashesInRoomType = (roomType) => {
  const dashRegex = /-/gi;
  return roomType.replace(dashRegex, ' ');
};

// Generate the URL to the design-feed page that was favorited
export const getURLForFeedpageLink = ({ roomType, style, slug }) => {
  let subdomain = '';
  switch (process.env.NODE_ENV) {
    case 'development':
      subdomain = 'dev.';
      break;
    case 'staging':
      subdomain = 'staging.';
      break;
    default:
      break;
  }
  return `https://www.${subdomain}modsy.com/design-ideas/${roomType}/${style}/${slug}`;
};

/** Header items are always the first item in the array.  As such,
 * this ensures that the first index uses GridItem and isheaderItem,
 * and that the remaining entries have clickable links.
 * This also ensures we have one location for updating any changes
 * to said column
 */
const handleFirstColumnFormatting = (feedpageURL, index, title) => {
  const columnFormatting = 'span 4';
  if (index === 0) {
    return (
      <GridItem gridColumn={columnFormatting} isHeaderItem>
        {title}
      </GridItem>
    );
  }
  return (
    <GridLink
      gridColumn={columnFormatting}
      key={`feedpage-link-${index}`}
      href={feedpageURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
      <ImageIcon alt={'link icon'} padding={'0 0 0 8px'} src={NewLinkIcon} />
    </GridLink>
  );
};

const GridLink = styled.a`
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  padding: 8px 8px 8px 0;
  margin-bottom: 8px;
  text-transform: capitalize;
  grid-row: ${(props) => props.gridRow};
  grid-column: ${(props) => props.gridColumn};
`;
