import React from 'react';

import { PrimaryCta, SecondaryCta, TertiaryCta } from './button';

export default function Buttons ({ type, ...props }) {

  const handleButtonType = () => {
    switch(type) {
      case 'secondary':
        return <SecondaryCta { ...props } />;
      case 'tertiary':
        return <TertiaryCta { ...props } />;
      default:
        return <PrimaryCta { ...props } />;
    }
  }

  return handleButtonType();
};

