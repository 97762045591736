import React from 'react';
import PhotosAndMeasurementsThumbnail from './PhotosAndMeasurementsThumbnail';
import DesignProductThumbnail from './DesignProductThumbnail';
import RenderThumbnail from './RenderThumbnail';
import CustomPropThumbnail from './CustomPropThumbnail';
import FavoritePropThumbnail from './FavoritePropThumbnail';
import { IMAGE_LIST_TYPES } from '../../constants';

const PhotoMeasurementThumbnailsToDisplay = ({ index, image, open }) => {
    return (
      <PhotosAndMeasurementsThumbnail
      index={index}
      image={image}
      open={open}
    />
    )
}

const ThumbnailGenerator = ({ index, image, open, imageListType }) => {

  switch (imageListType) {
    case IMAGE_LIST_TYPES.PHOTOS_AND_MEASUREMENTS:
      return (
        <PhotoMeasurementThumbnailsToDisplay
          index={index}
          image={image}
          open={open}
        />
      );
    case IMAGE_LIST_TYPES.DESIGN_PRODUCTS:
      return <DesignProductThumbnail index={index} image={image} open={open} />;
    case IMAGE_LIST_TYPES.RENDERS:
      return <RenderThumbnail index={index} image={image} open={open} />;
    case IMAGE_LIST_TYPES.CUSTOM_PROPS:
      return <CustomPropThumbnail index={index} image={image} open={open} />;
      case IMAGE_LIST_TYPES.FAVORITE_PROPS:
      return <FavoritePropThumbnail index={index} image={image} open={open} />;
    default:
      return null;
  }
};

export default ThumbnailGenerator;
