/**
 * TODO: Tried to refactor the logic with the helper method, but still needs improvement...
 * asana ticket: https://app.asana.com/0/1131938021244497/116254639532566
 *
 * In Order Details > Order Details Item,
 * the item has multiple boxes abut price, shipping,
 * tracking, delivery, promo, etc etc.
 *
 * If user clicks on some of the boxes, it needs to trigger
 * a date picker, or a dropdown, etc.
 *
 * This component is in charge of this logic.
 */

import React from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import {
  FormControl,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';

import Utils from '../../utils';
import { OLD_SHIPPING_METHODS_KEYS, SHIPPING_HEADER_VALUES } from './constants';

export default function RowNameAndInput({
  datePicker = {},
  dirtyFields = {},
  entry = {},
  item = {},
  updateOrderItemDetails = (name, value, orderItemId) => {},
  disabled = false
} = {}) {
  let value = null;

  value = getFieldValue({
    datePicker,
    dirtyFields,
    entry,
    item,
    disabled,
    updateOrderItemDetails,
  });

  return (
    <tbody>
      <tr>
        <td className="right fieldLabel">{entry.name}</td>
        <td>{value}</td>
      </tr>
    </tbody>
  );
}

/**
 * Method fired when SingleDatePicker is focused.
 * It allows us to clear the existing date on focus as
 * well as updating the value of focused in local state
 * which triggers the DatePicker to open.
 *
 * note: This is a hack to replace the non working prop `showClearDate={true}`
 */
const handleFocusChange = ({ datePicker, entry, focused, item }) => {
  // If we have an existing shipping date, let's clear it
  if (item[entry.field]) {
    datePicker.setState(item.id, entry.field, null);
  }

  datePicker.setState(item.id, entry.field, { focused });
};

// Helper method
const getFieldValue = ({
  datePicker = {},
  dirtyFields = {},
  entry = {},
  item = {},
  updateOrderItemDetails,
  disabled
}) => {
  const field = entry.field;

  const value = entry.value || item[field];

  // Let's list our different case variables
  const isSingleDate =
    field === 'retailer_shipping_start_date' ||
    field === 'retailer_shipping_end_date';

  const isDatePicker =
    field === 'retailer_shipping_end_date' ||
    field === 'retailer_shipping_start_date' ||
    field === 'availability_info';

  const isReadOnly = !!entry.read_only;

  const isMoneyReadOnly = isReadOnly && !!entry.money;

  const isMultiOptions = !!entry.options;

  const isDayBlocked = (field, day) => {
    const startDate = item['retailer_shipping_start_date'];
    const endDate = item['retailer_shipping_end_date'];
    const isPastDate = moment(day).isBefore(moment(), 'day');

    // This prevents the user from reversing start and end date. If any future
    // validation is needed, it can be added
    // here.
    switch (field) {
      case 'retailer_shipping_start_date':
        return isPastDate || (endDate && moment(day).isAfter(endDate, 'day'));
      case 'retailer_shipping_end_date':
        return (
          isPastDate || (startDate && moment(day).isBefore(startDate, 'day'))
        );
      default:
        return isPastDate;
    }
  };

  switch (true) {
    case isDatePicker:
      return (
        <SingleDatePicker
          id={item.id + field}
          isDayBlocked={(day) => {
            return isDayBlocked(field, day);
          }}
          date={item[field] ? moment(item[field]) : null}
          onDateChange={(date) => datePicker.setState(item.id, field, { date })}
          focused={datePicker.focused(item.id, field)}
          onFocusChange={({ focused }) =>
            handleFocusChange({ datePicker, entry, focused, item })
          }
          // optional  values
          isOutsideRange={(day) => {
            return false;
          }}
          placeholder=""
          disabled={disabled}
        />
      );
    case isMoneyReadOnly:
      return Utils.formatMoney(value, 2);
    case isReadOnly:
      return value;
    case isMultiOptions:
      // handling for item quantity value if not present in options
      // (will be marked as isDeprecated value if not present)
      const options =
        field === 'quantity'
          ? { [value]: value, ...entry.options }
          : { ...entry.options };

      return (
        <ButtonToolbar>
          <DropdownButton
            style={field !== 'quantity' ? { minWidth: '100px' } : null}
            title={`${options[item[field]]}`}
            id={field}
            onSelect={(val) => updateOrderItemDetails(field, val, item.id)}
            className={dirtyFields[field + item.id] ? 'dirty' : null}
            disabled={disabled}
          >
            {Object.keys(options).map((option) => {
              if (option === '') {
                return null; // landon silla: "the blank '' ones represent "any"/"all" which we don't want to display"
              }

              const isDefaultValue = option === 'unknown';
              const isDeprecatedValue =
                OLD_SHIPPING_METHODS_KEYS.includes(option) ||
                !(option in entry.options);
              const isHeaderValue = SHIPPING_HEADER_VALUES.includes(option);
              const isLineBreak = option.includes('linebreak');
              return (
                <MenuItem
                  key={option}
                  eventKey={option}
                  header={isHeaderValue}
                  style={{
                    cursor: 'default',
                    visibility: isLineBreak ? 'hidden' : 'unset',
                    display:
                      isDefaultValue || isDeprecatedValue ? 'none' : 'unset', // Hides the default and old values when dropdown is open
                    fontWeight: isHeaderValue ? 600 : 'regular',
                    fontSize: isHeaderValue ? '14px' : '12px',
                  }}
                  disabled={isDeprecatedValue}
                >
                  {options[option]}
                </MenuItem>
              );
            })}
          </DropdownButton>
        </ButtonToolbar>
      );
    default:
      return (
        <FormControl
          disabled={disabled}
          onChange={(e) =>
            updateOrderItemDetails(e.target.name, e.target.value, item.id)
          }
          name={field}
          value={item[field] || ''}
          placeholder={isSingleDate ? 'yyyy-mm-dd' : ''}
          className={dirtyFields[field + item.id] ? 'dirty' : null}
        />
      );
  }
};
