import React from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Glyphicon,
  Popover,
  OverlayTrigger,
  Label,
} from 'react-bootstrap';
import CircularProgressbar from 'react-circular-progressbar';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';

const GenomeModal = ({ data, show, close }) => {
  // stop execution if no data
  if (!data) return null;

  const attributesStr = 'Attributes';
  const closeStr = 'Close';
  const modalTitleStr = 'Style Breakdown';
  const styleStr = 'Styles';
  const styleData = [
    { style: data.style_1, styleValue: data.style_1_value, className: 'blue' },
    { style: data.style_2, styleValue: data.style_2_value, className: 'green' },
    {
      style: data.style_3,
      styleValue: data.style_3_value,
      className: 'purple',
    },
  ];

  const styleAttributeData = [
    {
      attribute: data.attribute_1,
      attributeValue: data.attribute_1_value,
      className: 'blue',
    },
    {
      attribute: data.attribute_2,
      attributeValue: data.attribute_2_value,
      className: 'green',
    },
    {
      attribute: data.attribute_3,
      attributeValue: data.attribute_3_value,
      className: 'purple',
    },
  ];

  const styleCombinationResult = get(data, 'style_combination.style_result');
  // tooltip for genome breakdown
  const genomeModalInfo = (
    <Popover id="genomeModalInfo" title="Style Breakdown Explanation">
      <p>
        The style breakdown details the top three themes that matched the
        products selected. For example, if the user selected ten products and
        six of them had the &#39;Industrial&#39; style tag, then Industrial
        would read 60%. Each product may have several tags. Attributes work
        similarly.
      </p>
    </Popover>
  );

  // generates circular progressbar given percentage as decimal
  const circularBar = (decimalValue) => {
    return (
      <div className="circular-graphic-container center-text">
        <CircularProgressbar
          initialAnimation
          percentage={Math.round(decimalValue * 100)}
        />
      </div>
    );
  };
  // generate genome modal
  return (
    <Modal show={show} onHide={close} className="heidi">
      <span className="genome-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitleStr} - {styleCombinationResult}
            <OverlayTrigger placement="bottom" overlay={genomeModalInfo}>
              <Glyphicon className="genome-info" glyph="info-sign" />
            </OverlayTrigger>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="circle-scss">
            {/* container div for each graphics object to assign fixed width and height */}
            <Row className="center-text label-padded">
              <Col>
                <h3>
                  <Label>{styleStr}</Label>
                </h3>
              </Col>
            </Row>
            <Row>
              {styleData.map((key, index) => {
                const style = key.style || '';
                const styleValue = key.styleValue || 0;
                const className = key.className;
                return (
                  <Col key={`${style}-${index}`} md={4} className={className}>
                    <Row>{circularBar(styleValue)}</Row>
                    <Row className="center-text">
                      <h4>{style}</h4>
                    </Row>
                  </Col>
                );
              })}
            </Row>
            <Row />
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row className="center-text label-padded">
              <Col>
                <h3>
                  <Label>{attributesStr}</Label>
                </h3>
              </Col>
            </Row>
            <Row>
              {styleAttributeData.map((key, index) => {
                const attribute = key.attribute || '';
                const attributeValue = key.attributeValue || 0;
                const className = key.className;
                return (
                  <Col
                    key={`${attribute}-${index}`}
                    md={4}
                    className={className}
                  >
                    <Row>{circularBar(attributeValue)}</Row>
                    <Row className="center-text">
                      <h4>{upperFirst(attribute)}</h4>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close}>{closeStr}</Button>
        </Modal.Footer>
      </span>
    </Modal>
  );
};

export default GenomeModal;
