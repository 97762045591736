import React from 'react';

import { GridItem, GridItemButton, IconContainer } from './styles';
import { AGREED_DEFAULT_STR } from './constants';

import {
  CheckmarkIcon,
  QuestionMarkIcon,
  StopIcon,
  XIcon,
} from '../../../../sharedComponents/icons';

const POLL_CHOICES = {
  0: <StopIcon />,
  1: <CheckmarkIcon />,
  2: <QuestionMarkIcon />,
  3: <XIcon />,
};

export default function QuizResult({
  dateTaken = '',
  openModal = () => {},
  genome = '',
  hasSurveyProducts = false,
  isQuizVersionTwo = false,
  quizData = {},
  pollAllignment = '',
  productsCount = 0,
  source = '',
  surveyProducts = '',
  shouldItemBeBold = false,
}) {
  return (
    <React.Fragment>
      <GridItem className="genome-style-result" isHeaderItem={shouldItemBeBold}>
        <GridItemButton
          isButtonLink={!isQuizVersionTwo}
          onClick={() => {
            !isQuizVersionTwo && openModal('genomeModal', quizData);
          }}
        >
          {genome}
        </GridItemButton>
      </GridItem>
      <GridItem isHeaderItem={shouldItemBeBold} isDisabled={isQuizVersionTwo}>
        {!isQuizVersionTwo ? (
          <IconContainer className="icon-checkmark">
            {POLL_CHOICES[pollAllignment]}
          </IconContainer>
        ) : (
          AGREED_DEFAULT_STR
        )}
      </GridItem>
      <GridItem
        isHeaderItem={shouldItemBeBold}
        className={`quiz-products-data`}
      >
        <GridItemButton
          isDisabled={!hasSurveyProducts || isQuizVersionTwo}
          isButtonLink={hasSurveyProducts}
          onClick={() => {
            !isQuizVersionTwo && openModal('productsModal', surveyProducts);
          }}
        >
          {productsCount}
        </GridItemButton>
      </GridItem>
      <GridItem isHeaderItem={shouldItemBeBold}>{source}</GridItem>
      <GridItem isHeaderItem={shouldItemBeBold}>{dateTaken}</GridItem>
    </React.Fragment>
  );
}
