import React from 'react';
import get from 'lodash/get';
import { Col, Row, Table, FormControl } from 'react-bootstrap';
import EditableTextField from './EditableTextField';
import { ApiConfig } from '../config';
import Utils from '../utils';
import { patchData } from '../actions/api-patch-request-generator';
import { dataRefresh } from '../actions/api-data-request-generator';
import { CUSTOM_PROP_TYPE } from "../constants";

export const PRODUCT_NAME_VAL_REG = /^[A-Za-z0-9\s-\W_]+$/;

const TYPE_OPTIONS = {
    [CUSTOM_PROP_TYPE.UNKNOWN] : "--",
    [CUSTOM_PROP_TYPE.ALTERNATIVE_PRODUCT] : "Alternative (selected by Find Alt team as stand-in)",
    [CUSTOM_PROP_TYPE.FIND_ALTERNATIVE_REQUEST] : "Find Alternative Request (original request to find stand-in for)",
    [CUSTOM_PROP_TYPE.CUSTOM_PRODUCT] : "Custom (customer paid for this exact piece to be used)"
}

const CustomProductInlines = ({ zoomImage, handleUnitChange, units, dispatch, activeSpaceID }) => {
  let shortenedURL;
  if (zoomImage.url) {
    shortenedURL =
      zoomImage.url.length > 40
        ? `${zoomImage.url.substring(0, 40)}...`
        : zoomImage.url;
  } else {
    shortenedURL = '';
  }

  const setMeasurementsField = field => (value) => {
    return [
      patchData(
        `${ApiConfig.CUSTOM_PROPS}/${zoomImage.id}`,
        'custom_prop_field_change',
        {
          data: Utils.buildState(field, value),
        },
      ),
    ];
  };

  const setCustomPropFieldGenerator = field => value => [
    patchData(
      `${ApiConfig.CUSTOM_PROPS}/${zoomImage.id}`,
      'custom_prop_field_change',
      { data: Utils.buildState(field, value) },
    ),
  ];

  const setTypeField = (value) => {
    dispatch(patchData(
        `${ApiConfig.CUSTOM_PROPS}/${zoomImage.id}`,
        'custom_prop_field_change',
        { data: Utils.buildState('type', value) },
    )).then((data) => {
        dispatch(dataRefresh('custom_props', { space: activeSpaceID }));
    })
  }

  const zoomImageType = get(zoomImage, 'type', []);
  const zoomImageTypeValue = zoomImageType.length === 0 ? CUSTOM_PROP_TYPE.UNKNOWN : zoomImageType;

  return (
    <Row>
      <Col xs={6}>
        <Row className="separated-rows">
          <Col xs={3} className="right-align-text">
            <strong>Name</strong>
          </Col>
          <Col xs={9} className="inline-cp-field-value">
            <EditableTextField
              id='custom-product-name'
              requestGenerator={setCustomPropFieldGenerator('name')}
              identifier="custom_props"
              initialValue={zoomImage.name || ''}
              validationRegex={PRODUCT_NAME_VAL_REG}
              validationMessage="Please enter a name."
              styling="inline-text-field"
            >
              <span>{zoomImage.name || 'No name given.'}</span>
            </EditableTextField>
          </Col>
        </Row>
        <Row className="separated-rows">
          <Col xs={3} className="right-align-text">
            <strong>Brand</strong>
          </Col>
          <Col xs={9} className="inline-cp-field-value">
            <EditableTextField
              requestGenerator={setCustomPropFieldGenerator('brand')}
              identifier="custom_props"
              initialValue={zoomImage.brand || ''}
              validationRegex={/^[A-Za-z0-9\s-]+$/}
              validationMessage="Please only use numbers, letters, dashes and spaces."
              styling="inline-text-field"
            >
              <span>{zoomImage.brand || 'No brand given.'}</span>
            </EditableTextField>
          </Col>
        </Row>
        <Row className="separated-rows">
          <Col xs={3} className="right-align-text">
            <strong>URL</strong>
          </Col>
          <Col xs={9} className="inline-cp-field-value">
            <EditableTextField
              requestGenerator={setCustomPropFieldGenerator('url')}
              identifier="custom_props"
              initialValue={zoomImage.url || ''}
              validationRegex={/^(ftp|http|https):\/\/[^ ']+$/}
              validationMessage="Please enter a valid URL."
              styling="inline-text-field"
            >
              <span>{shortenedURL || 'No link given'}</span>
            </EditableTextField>
          </Col>
        </Row>
        <Row className="separated-rows">
          <Col xs={3} className="right-align-text">
            <strong>Prop Request Type</strong>
          </Col>
          <Col xs={9} className="inline-cp-field-value">
            <FormControl
                value={zoomImageTypeValue}
                className="limit-height"
                componentClass="select"   
                onChange={e => setTypeField(e.target.value)}
            >
                {[  CUSTOM_PROP_TYPE.UNKNOWN, 
                    CUSTOM_PROP_TYPE.ALTERNATIVE_PRODUCT, 
                    CUSTOM_PROP_TYPE.FIND_ALTERNATIVE_REQUEST,
                    CUSTOM_PROP_TYPE.CUSTOM_PRODUCT
                ].map(type => (
                <option key={type} value={type}>
                    {TYPE_OPTIONS[type]}
                </option>
                ))}
            </FormControl>
          </Col>
        </Row>
      </Col>
      <Col xs={6} className="separated-rows">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Height</th>
              <th>Width</th>
              <th>Depth</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <EditableTextField
                  requestGenerator={setMeasurementsField('height')}
                  identifier="custom_props"
                  initialValue={zoomImage.height}
                  validationRegex={/^[+-]?(\d*\.)?\d+$/}
                  validationMessage="Please enter a number."
                  styling="dimensions-input"
                >
                  <span>
                    {zoomImage.height} {units}
                  </span>
                </EditableTextField>
              </td>
              <td>
                <EditableTextField
                  requestGenerator={setMeasurementsField('width')}
                  identifier="custom_props"
                  initialValue={zoomImage.width}
                  validationRegex={/^[+-]?(\d*\.)?\d+$/}
                  validationMessage="Please enter a number."
                  styling="dimensions-input"
                >
                  <span>
                    {zoomImage.width} {units}
                  </span>
                </EditableTextField>
              </td>
              <td>
                <EditableTextField
                  requestGenerator={setMeasurementsField('depth')}
                  identifier="custom_props"
                  initialValue={zoomImage.depth}
                  validationRegex={/^[+-]?(\d*\.)?\d+$/}
                  validationMessage="Please enter a number."
                  styling="dimensions-input"
                >
                  <span>
                    {zoomImage.depth} {units}
                  </span>
                </EditableTextField>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default CustomProductInlines;
