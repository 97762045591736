import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import upperFirst from 'lodash/upperFirst';
import ExternalUserLinksContainer from '../containers/ExternalUserLinksContainer';

const BasicInformation = ({ info }) => {
  // stop execution if no id was passed
  if (!info) return null;

  // get date joined in readable format
  const dateJoined = new Date(info.date_joined);

  // create html for active or deactive use
  const userStatus = info.is_active ? (
    <strong className="green">Active</strong>
  ) : (
    <strong className="red">Inactive</strong>
  );

  return (
    <Container className="basic-information">
      <Col xs={12}>
        <Row>
          <Col>
            <NameText>
              {upperFirst(info.last_name)}, {upperFirst(info.first_name)}
            </NameText>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              {userStatus} user. Joined Modsy on&nbsp;<strong>{dateJoined.toLocaleDateString()}.</strong>
            </p>
          </Col>
        </Row>
        <Flex>
          <Link
            href={`mailto:${info.email}?Subject=Hello%20From%20Modsy`}
            target="_top"
          >
            {info.email}
          </Link>
          <Divider>|</Divider>
          <ExternalUserLinksContainer />
        </Flex>
      </Col>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const NameText = styled.h1`
  margin-top: 0;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-bottom: 36px;
`;

const Divider = styled.div`
  margin: 0 8px;
`;

const Link = styled.a`
  color: black !important;
`;

export default BasicInformation;
