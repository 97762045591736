import React from 'react';
import {
  Row,
  Col,
  DropdownButton,
  MenuItem,
  ButtonToolbar,
  Alert,
} from 'react-bootstrap';

import ImageListContainer from '../containers/ImageListContainer';
import { IMAGE_LIST_TYPES, FAVORITES, SPACES_FILTER } from '../constants';

const FavoriteProps = ({ 
  favoritePropsList, 
  handleFilters,
  handleFilterChange,
  handleSpaceChange,
  handleFilterCount,
  likesDislikesFilter,
  spaceFilter,
  spaceID, 
  }) => {
  if (!favoritePropsList) return null;
  const favoritesCount = favoritePropsList.filter(value => value.space_ids.includes(spaceID)).length;
  let alert;
  if (favoritesCount === 0 && favoritePropsList.length === 0) {
    alert = (
      <Alert bsStyle="warning">
        <strong>Hmmm...</strong> This User does not have any liked/disliked products.  Please pick another User
      </Alert>
    );
  }

  const header = (
    <Row className="separation-border">
      <Col xs={12}>
        <div style={{fontSize:'1.4em', padding:'16px 0'}}>Likes/Dislikes ({favoritesCount})</div>
      </Col>
    </Row>
  );

  const dropdowns = (
    <ButtonToolbar>
      <DropdownButton
        title={spaceFilter}
        id="filter-dropdown-spaces"
        onSelect={handleSpaceChange}
      >
        <MenuItem eventKey={SPACES_FILTER.ACTIVE_SPACE}>Current Space</MenuItem>
        <MenuItem eventKey={SPACES_FILTER.ALL}>All</MenuItem>
      </DropdownButton>
      <DropdownButton
        title={`${likesDislikesFilter} (${handleFilterCount(likesDislikesFilter === FAVORITES.LIKES)})`}
        id="filter-dropdown-favorites"
        onSelect={handleFilterChange}
      >
        <MenuItem eventKey={FAVORITES.LIKES}>Likes ({handleFilterCount(true)})</MenuItem>
        <MenuItem eventKey={FAVORITES.DISLIKES}>Dislikes ({handleFilterCount(false)})</MenuItem>
      </DropdownButton>
    </ButtonToolbar>
  )

  return (
    <div className="favorite-favoriteProps">
    <div >{header}</div>
      <Row>
        <Col md={12}>
          {
          favoritePropsList.length > 0 &&
          <Row style={{marginLeft:0}}>{dropdowns}</Row>
          }
        <ImageListContainer
          images={handleFilters()}
          imageListType={IMAGE_LIST_TYPES.FAVORITE_PROPS}
          noArrowNavigation
        />
        </Col>
      </Row>
      {alert}
    </div>
  );
};

export default FavoriteProps;
