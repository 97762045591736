import * as types from '../actions/action-types';
import get from 'lodash/get';

const initialState = {
  rawListOfUserFavorites: [],
  data: [],
  feedpageFavorites: []
};

const favoritesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TRANSFORMED_LIST_OF_USER_FAVORITES:
      return {
        ...state,
        data: action.payload
      };

    case types.SET_RAW_LIST_OF_USER_FAVORITES:
      return {
        ...state,
        rawListOfUserFavorites: action.payload
      };

    case types.SET_FEEDPAGE_FAVORITES:
      return {
        ...state,
        feedpageFavorites: action.payload
      };

    default:
      return state;
  }
};

export const getRawListOfUserFavorites = state => get(state, 'favorites.rawListOfUserFavorites', []);
export const getConvertedListOfUserFavorites = state => get(state, 'favorites.data', []);
export const getFeedpageFavorites = state => get(state, 'favorites.feedpageFavorites', []);

export default favoritesListReducer;
