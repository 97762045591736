import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Modal, Button, Thumbnail } from 'react-bootstrap';
import moment from 'moment';
import * as request from 'global/request';

import { WAREHOUSE_API_URL } from '../config';

var errorStates = [
  'preparing_failed',
  'rendering_failed',
  'preparing_timeout',
  'rendering_timeout',
  'pending_timeout',
  'queued_timeout',
];

export default class JobContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      showModal: false,
      img: '',
      rowData: null,
      showErrorModal: false,
      showImageModal: false,
      start: 0,
      limit: 50,
      page: 1,
      pageIndex: 0,
      column: 'job_started_at',
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchData();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.start !== this.state.start) {
      this.fetchData(nextState.start);
    }

    if (nextState.column !== this.state.column) {
      this.fetchData(this.state.start, nextState.column);
    }
  }

  parseDate(date) {
    if (date !== '1970-01-01T08:00:00Z') {
      return moment(date).format('M/DD/YYYY, h:mm:ss a');
    }
    return 'nil';
  }

  close = () => {
    this.setState({
      showModal: false,
      showErrorModal: false,
      showImageModal: false,
    });
  };

  getColumnWidth(accessor, headerText) {
    const { data } = this.state;
    let max = 0;
    const maxWidth = 200;
    const magicSpacing = 18;

    data.forEach((d) => {
      if (d !== undefined && d[accessor] !== null) {
        if (String(d[accessor] || 'null').length > max) {
          max = String(d[accessor] || 'null').length;
        }
      }
    });
    return Math.min(maxWidth, Math.max(max, headerText.length) * magicSpacing);
  }

  fetchData = (start = 0, sortBy = 'job_started_at', filter = '') => {
    this.setState({ loading: true });
    const { limit } = this.state;
    request
      .get(
        `${WAREHOUSE_API_URL}/warehouse/api/v1/render/history?start=${start}&limit=${limit}&sort_by=${sortBy}&filter_type=${filter}`
      )
      .then((res) => {
        if (res && res.data) {
          this.setState({
            data: [...res.data.results.results],
            loading: false,
            start,
            limit,
            pages: Math.round(res.data.results.total / limit),
          });
        } else {
          const msg = 'Something went wrong, please refresh and try again';
          console.error(msg);
        }
      });
  };

  filterDate(date, filter) {
    return (
      String(date).startsWith(filter.value) ||
      String(date).endsWith(filter.value)
    );
  }

  showImage = (img) => {
    this.setState({
      img,
      showModal: true,
      showImageModal: true,
    });
  };

  handleErrorModal = (rowData) => {
    this.setState({
      showErrorModal: true,
      showModal: true,
      rowData: [rowData],
    });
  };

  handlePageIndex = (pageIndex) => {
    this.setState({ start: pageIndex * 50, pageIndex });
  };

  handleSort = (column) => {
    this.setState({ column: column.id });
  };

  renderTable() {
    const {
      data,
      img,
      showErrorModal,
      rowData,
      showImageModal,
      showModal,
    } = this.state;
    const columns = [
      {
        header: 'Job',
        columns: [
          {
            header: 'Image',
            accessor: 'src',
            hideFilter: true,
            render: (props) => {
              const hasImage = !errorStates.includes(props.row.final_state);
              const onClick = hasImage
                ? () => this.showImage(props.value)
                : () => this.handleErrorModal(props.row);
              return (
                <div>
                  {hasImage ? (
                    <Thumbnail onClick={onClick} href="#" src={props.value} />
                  ) : (
                    <Button onClick={onClick} block bsStyle={'danger'}>
                      Errored / No Image
                    </Button>
                  )}
                </div>
              );
            },
          },
          {
            header: 'Handle',
            accessor: 'job_handle',
            minWidth: this.getColumnWidth('job_handle', 'Handle'),
            hideFilter: true,
          },
          {
            header: 'Profile',
            accessor: 'job_profile',
            hideFilter: true,
          },
          {
            header: 'Tag',
            accessor: 'job_tag',
            hideFilter: true,
          },
          {
            header: 'state',
            accessor: 'final_state',
            hideFilter: true,
          },
          {
            header: 'Started At',
            accessor: 'job_started_at',
            minWidth: this.getColumnWidth('job_started_at', 'Started At'),
            render: (props) => this.parseDate(props.value),
            filterMethod: (filter, row) => {
              const id = filter.pivotId || filter.id;
              const date = this.parseDate(row[id]);
              return this.filterDate(date, filter);
            },
          },
          {
            header: 'Stopped At',
            accessor: 'job_stopped_at',
            minWidth: this.getColumnWidth('job_stopped_at', 'Stopped At'),
            render: (props) => this.parseDate(props.value),
            filterMethod: (filter, row) => {
              const id = filter.pivotId || filter.id;
              const date = this.parseDate(row[id]);
              return this.filterDate(date, filter);
            },
          },
          {
            header: 'Render Elapsed Time',
            accessor: 'rendering_elapsed_time',
            hideFilter: true,
          },
        ],
      },
      {
        header: 'User',
        columns: [
          {
            header: 'ID',
            accessor: 'user_id',
          },
          {
            header: 'Email',
            accessor: 'user_email',
          },
          {
            header: 'Started At',
            accessor: 'user_started_at',
            minWidth: this.getColumnWidth('user_started_at', 'Started At'),
            render: (props) => this.parseDate(props.value),
          },
        ],
      },
    ];

    const ERROR_COLUMNS = [
      {
        header: 'Error Details',
        columns: [
          {
            header: 'State',
            accessor: 'final_state',
          },
          {
            header: 'Details',
            accessor: 'details',
          },
          {
            header: 'Occurred At',
            accessor: 'occurred_at',
            render: (props) => this.parseDate(props.value),
            filterMethod: (filter, row) => {
              const id = filter.pivotId || filter.id;
              const date = this.parseDate(row[id]);
              return this.filterDate(date, filter);
            },
          },
        ],
      },
    ];

    const userColumns = [
      {
        header: 'User',
        columns: [
          {
            header: 'ID',
            accessor: 'user_id',
          },
          {
            header: 'Name',
            accessor: 'user_name',
          },
          {
            header: 'Email',
            accessor: 'user_email',
          },
          {
            header: 'Started At',
            accessor: 'user_started_at',
            minWidth: this.getColumnWidth('user_started_at', 'Started At'),
            render: (props) => this.parseDate(props.value),
            filterMethod: (filter, row) => {
              const id = filter.pivotId || filter.id;
              const date = this.parseDate(row[id]);
              return this.filterDate(date, filter);
            },
          },
          {
            header: 'Cancelled At',
            accessor: 'user_cancelled_at',
            minWidth: this.getColumnWidth('user_cancelled_at', 'Cancelled At'),
            render: (props) => this.parseDate(props.value),
            filterMethod: (filter, row) => {
              const id = filter.pivotId || filter.id;
              const date = this.parseDate(row[id]);
              return this.filterDate(date, filter);
            },
          },
        ],
      },
    ];

    const imgDiv = (
      <div>
        <img
          alt="img-div"
          style={{
            height: '100%',
            width: '100%',
          }}
          src={img}
        />
      </div>
    );

    const rowDiv = (
      <ReactTable
        className="-striped -highlight"
        data={rowData}
        columns={ERROR_COLUMNS}
        defaultPageSize={1}
        showPagination={false}
      />
    );

    let div;
    if (showErrorModal && showModal) {
      div = rowDiv;
    } else if (showImageModal && showModal) {
      div = imgDiv;
    }

    return (
      <div>
        <Modal bsSize="large" show={this.state.showModal} onHide={this.close}>
          <Modal.Body>{div}</Modal.Body>
          <Modal.Footer>
            <Button bsStyle={'primary'} onClick={this.close}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Button onClick={() => this.fetchData(this.state.start)}>
          Refresh List
        </Button>
        <Button
          onClick={() =>
            this.fetchData(this.state.start, 'job_started_at', 'product')
          }
        >
          View Turntables Only
        </Button>
        <ReactTable
          className="-striped -highlight"
          data={data}
          manual
          page={this.state.pageIndex}
          pages={this.state.pages}
          columns={columns}
          onSortingChange={(column) => this.handleSort(column)}
          defaultPageSize={50}
          showFilters={false}
          loading={this.state.loading}
          showPageSizeOptions={false}
          onPageChange={(pageIndex) => this.handlePageIndex(pageIndex)}
          defaultSorting={[
            {
              id: 'job_started_at',
              desc: true,
            },
          ]}
          SubComponent={(row) => {
            return (
              <div style={{ padding: '20px' }}>
                <ReactTable
                  data={[row.row]}
                  columns={userColumns}
                  defaultPageSize={1}
                  showPagination={false}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }

  render() {
    // const { loading } = this.state;
    return <div style={{ padding: '0px 25px 30px' }}>{this.renderTable()}</div>;
  }
}
