import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import get from 'lodash/get';

import LoadingIcon from 'components/LoadingIcon';
import FavoriteProps from 'components/FavoriteProps';
import { FAVORITES, SPACES_FILTER } from '../constants';
import { getConvertedListOfUserFavorites } from 'reducers/favorites-list-reducer';

class FavoritePropsContainer extends React.Component {
  state = {
    likesDislikesFilter: FAVORITES.LIKES,
    spaceFilter: SPACES_FILTER.ACTIVE_SPACE,
  };

  componentDidMount() {
    this.handleFilters();
  }

  // changes whether likes or dislikes are displayed
  handleFilterChange = (likesDislikesFilter) => {
    this.setState({ likesDislikesFilter });
  };

  //handle space filtering
  handleSpaceChange = (spaceFilter) => {
    this.setState({ spaceFilter });
  };

  // handle likes dislikes filtering
  handleLikesDislikesFilter = () => {
    const { favoritePropsList } = this.props;
    let likedProducts = favoritePropsList.filter((value) => value.like === 1);
    let dislikedProducts = favoritePropsList.filter((value) => value.like <= 0);
    if (this.state.likesDislikesFilter === FAVORITES.LIKES) {
      return likedProducts;
    } else {
      return dislikedProducts;
    }
  };

  // handles the space filtering
  handleFilters = () => {
    const { activeSpaceID } = this.props;
    let currentDesignsFavorites = this.handleLikesDislikesFilter().filter(
      (value) => value.space_ids.includes(activeSpaceID)
    );
    if (this.state.spaceFilter === SPACES_FILTER.ACTIVE_SPACE) {
      return currentDesignsFavorites;
    } else {
      return this.handleLikesDislikesFilter();
    }
  };

  handleFilterCount = (likes = true) => {
    const { favoritePropsList, activeSpaceID } = this.props;
    let likedProducts = favoritePropsList.filter((value) => value.like === 1);
    let dislikedProducts = favoritePropsList.filter((value) => value.like <= 0);
    if (likes) {
      if (this.state.spaceFilter === SPACES_FILTER.ACTIVE_SPACE) {
        return likedProducts.filter((value) =>
          value.space_ids.includes(activeSpaceID)
        ).length;
      } else {
        return likedProducts.length;
      }
    } else {
      if (this.state.spaceFilter === SPACES_FILTER.ACTIVE_SPACE) {
        return dislikedProducts.filter((value) =>
          value.space_ids.includes(activeSpaceID)
        ).length;
      } else {
        return dislikedProducts.length;
      }
    }
  };

  render() {
    const {
      isFetching,
      error,
      activeSpaceID,
      userID,
      favoritePropsList,
    } = this.props;
    if (error) {
      return (
        <Alert bsStyle="danger">
          <strong>Oops!</strong> Something went wrong fetching the favorite
          props information.
        </Alert>
      );
    }
    return isFetching && !this.props.data ? (
      <LoadingIcon />
    ) : (
      <FavoriteProps
        favoritePropsList={favoritePropsList}
        handleFilterChange={this.handleFilterChange}
        handleFilterCount={this.handleFilterCount}
        handleFilters={this.handleFilters}
        handleSpaceChange={this.handleSpaceChange}
        spaceID={activeSpaceID}
        userID={userID}
        {...this.state}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  const favoritePropsList = getConvertedListOfUserFavorites(state);
  const userID = state.user_data.user_id;
  const {
    isFetching,
    lastUpdated,
    data,
    error,
  } = state.user_data.favorite_props;

  return {
    activeSpaceID,
    data,
    error,
    favoritePropsList,
    favoritesData: get(state, 'user_data.favorite_props.data.results'),
    isFetching,
    lastUpdated,
    userID,
  };
};

export default connect(mapStateToProps)(FavoritePropsContainer);
