import React from 'react';
import { Col, Row, Label } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { WAREHOUSE_CLIENT_URL } from '../config';

import RedesignRequestContainer from '../containers/RedesignRequestContainer';
import { PrimaryCta, SecondaryCta } from '../sharedComponents/button';
import SpaceTabNavigationContainer from '../containers/SpaceTabNavigationContainer';
import TurnAroundTimeTab from './TurnAroundTimeTab';
import {
  setIsRequestEditable,
  setRedesignModalRequestDisplay,
} from '../actions/redesign-request-modal-action';
import { bugsnagError } from '../services/bugsnag';

export default class SpaceTab extends React.PureComponent {


  handleRedesignRequestModalDisplay = () => {
    const { dispatch } = this.props;
    dispatch(setRedesignModalRequestDisplay());
    dispatch(setIsRequestEditable(false));
  };

  // handle customer studio button click
  handleStudioClick = (e) => {
    e.preventDefault();
    const spaceInfoResult = get(this.props, 'spaceInfoData.results[0]', {});
    const { styleboard } = spaceInfoResult || {};
    const { activeSpaceID } = this.props;
    const spaceID = styleboard || activeSpaceID;
    const userID = get(this.props, 'userID', '');

    if (!spaceID) {
      const spaceName = get(this.props, 'spaceName', '');
      return bugsnagError('click to access Studio from Heidi failed', {
        userID,
        spaceName,
      });
    }

    // Equivalent of window.open(url) but safer.
    // https://gosink.in/are-you-making-website-vulnerable-target-blank-or-performance/
    const newWindow = window.open(
      `${WAREHOUSE_CLIENT_URL}/projects/${spaceID}`
    );
    newWindow.opener = null;
  };

  adjustBudget(obj) {
    // budget is a weird "two property" thing
    // so I'm faking this FE value and splitting it into two values
    // that the BE expects
    if (obj.hasOwnProperty('budget_max')) {
      if (obj.budget_max === 'notSure') {
        obj.budget_max = 0;
        obj.budget_min = -1;
      } else {
        obj.budget_min = 0;
      }
    }
    return obj;
  }

  render() {
    // reassign
    const order = this.props.orderData;
    const {
      initialSpaceDesignsData,
      spaceStatus,
      spaceInitialEta,
      spaceDelayedEta,
      spaceEtaStatus,
      spaceEtaEditedAt,
      spaceDeliveredAt,
    } = this.props;

    const initialSpaceHasDataAndIsReady =
      spaceStatus === 'ready' &&
      initialSpaceDesignsData &&
      initialSpaceDesignsData.data &&
      initialSpaceDesignsData.data.results.length !== 0;

    let info =
      this.props.spaceInfoData &&
      this.props.spaceInfoData.results &&
      this.props.spaceInfoData.results[0];

    // check for empty info (old space), fill with placeholder data if needed
    if (!info) {
      info = {
        budget_max: 0,
        room_redesign_reason: 'N/A',
        project_scope: 999,
        style_preferences: 'N/A',
      };
    }

    let orderDate = 'N/A';
    let orderTag = 'N/A';
    let coupon;
    if (order && !isEmpty(order)) {
      // get date created in readable format
      orderDate = new Date(order.created_at);

      // format coupon code and purchase tag
      orderTag = order.tag.replace(/,(?=\S)/g, ', ');
      coupon = order.coupon_code ? (
        <span>
          {' '}
          with coupon <Label bsStyle="success">{order.coupon_code}</Label>
        </span>
      ) : null;
    }

    return (
      <div className="space-tab-component">
        {initialSpaceHasDataAndIsReady && <RedesignRequestContainer />}
        <Row>
          <div className="general-info keep-min-width">
            <Col xs={12}>
              <Row className="header-row">
                <Col xs={2}>
                  <Row>
                    <strong>Order Number</strong>
                  </Row>
                  <Row>{(order && order.order_number) || 'N/A'}</Row>
                </Col>
                <Col xs={2}>
                  <Row>
                    <strong>Order Date</strong>
                  </Row>
                  <Row>{orderDate.toLocaleString()}</Row>
                </Col>
                <Col xs={3}>
                  <Row>
                    <strong>Package(s) Purchased</strong>
                  </Row>
                  <Row>
                    {orderTag}
                    {coupon}&nbsp;
                  </Row>
                </Col>

                <Col xs={3}>
                    <TurnAroundTimeTab
                      status={spaceEtaStatus}
                      initialEta={spaceInitialEta}
                      delayedEta={spaceDelayedEta}
                      etaEditedAt={spaceEtaEditedAt}
                      deliveredAt={spaceDeliveredAt}
                    />
                </Col>

                <Col xs={2}>
                  <Row>
                    <PrimaryCta
                      mb="8px"
                      onClick={(e) => this.handleStudioClick(e)}
                    >
                      View Studio
                    </PrimaryCta>
                  </Row>
                  {initialSpaceHasDataAndIsReady && (
                    <Row>
                      <SecondaryCta
                        onClick={this.handleRedesignRequestModalDisplay}
                      >
                        Request Revision
                      </SecondaryCta>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
        <Row>
          <Col>
            <SpaceTabNavigationContainer />
          </Col>
        </Row>
      </div>
    );
  }
}
