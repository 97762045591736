import React from 'react';
import styled from 'styled-components';

import { ClockIcon, ExclamationIcon } from '../../sharedComponents/icons';

export default function SuborderMessage(status, process) {
  let header = '';
  let text = '';
  if (status === 'error') {
    header = process + ' failed for some items';
    text = 'Please review the items and place any failed items manually';
  } else if (status === 'processing') {
    header = 'Some ' + process + ' orders are still processing';
    text = 'Please wait for all orders to finish before making any edits';
  }

  return (
    <ContainerDiv>
      <IconDiv>
        {status === 'error' ? <ExclamationIcon /> : <ClockIcon />}
      </IconDiv>
      <div>
        <FirstText>{header}</FirstText>
        <p>{text}</p>
      </div>
    </ContainerDiv>
  );
}

const ContainerDiv = styled.div`
  background: #ffffff;
  border: 1px solid #ef4e22;
  border-radius: 4px;
  padding: 10px;
  margin: 0px 5px 15px;
  padding: 15px 25px 13px;
  align-items: center;
  display: flex;

  p::first-letter {
    text-transform: capitalize;
  }
`;

const IconDiv = styled.div`
  padding-right: 25px;
`;

const FirstText = styled.p`
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #333333;
`;
