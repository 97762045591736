import React from 'react';
import moment from 'moment';

import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import '../sass/components/ordersReport.css';
import '../sass/components/dropdown-menu.css';

import { ORDER_STATUS, ORDER_STATUS_LIST, ORDER_REPORT_GROUPING } from '../constants';

import {
  MenuItem,
  FormControl,
  ButtonToolbar,
  DropdownButton,
  Button,
} from 'react-bootstrap';


const OrdersReportSearch = ({
  currentSourceName,
  state,
  handleFilter,
  handleChange,
  onDatesChange,
  onFocusChange,
  downloadCSV,
  sources,
}) => {
  return (
    <div className="order-report-search">
      <Button className=" action-button green" style={{ float: 'right', marginRight: '10px' }} onClick={() => downloadCSV()}>Download Current View</Button>
      <div className="search-element">
        <ButtonToolbar>
          <DropdownButton
            title={ORDER_REPORT_GROUPING[state.searchTerms.time_period]}
            id="time_period"
            onSelect={filter => handleFilter(['time_period', filter])}
          >
            {Object.keys(ORDER_REPORT_GROUPING).map((key, i) => (
              <MenuItem key={i} eventKey={key}>
                {ORDER_REPORT_GROUPING[key]}
              </MenuItem>
            ))}
          </DropdownButton>
        </ButtonToolbar>
      </div>

      <div className="search-element">
        <DateRangePicker
          startDate={state.startDate}
          startDateId="your_unique_start_date_id"
          endDate={state.endDate}
          endDateId="your_unique_end_date_id"
          onDatesChange={onDatesChange}
          focusedInput={state.focusedInput}
          onFocusChange={onFocusChange}
          // optional  values
          isOutsideRange={day => day.isAfter(moment())}
          minimumNights={0}
        />
      </div>

      <div className="search-element">
        <ButtonToolbar>
          <DropdownButton
            title={ORDER_STATUS[state.searchTerms.status]}
            id="order_status"
            onSelect={filter => handleFilter(['status', filter])}
          >
            {ORDER_STATUS_LIST.map((key, i) => (
              <MenuItem key={i} eventKey={key}>
                {ORDER_STATUS[key]}
              </MenuItem>
            ))}
          </DropdownButton>
        </ButtonToolbar>
      </div>

      <div className="search-element">
        <FormControl
          onChange={handleChange}
          name="search"
          type="text"
          id="the_thing"
          value={state.searchTerms.search}
          placeholder="Search: user, email, order number"
        />
      </div>

      <div className="search-element">
        <FormControl
          onChange={handleChange}
          name="coupon_code"
          type="text"
          value={state.searchTerms.coupon_code}
          placeholder="Promo Code"
        />
      </div>

      <div className="search-element scrollable">
        <ButtonToolbar>
          <DropdownButton
            title={currentSourceName || 'All Retailers'}
            id="source"
            onSelect={filter => handleFilter(['source', filter])}
          >
            <MenuItem key={''} eventKey={''}>
              All Retailers
            </MenuItem>
            {Object.entries(sources).map((source, i) => {
              const retailerName = source[0];
              const retailerId = Number(source[1]);
              return (
                <MenuItem key={i} eventKey={retailerId}>
                  {retailerName}
                </MenuItem>
              );
            })}
          </DropdownButton>
        </ButtonToolbar>
      </div>
    </div>
  );
};

export default OrdersReportSearch;
