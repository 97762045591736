import React from 'react';
import { Table, Glyphicon } from 'react-bootstrap';
import ImageModal from '../ImageModal';

const RenderModal = ({
  zoomImageIndex,
  images,
  showImage,
  close,
  previousImage,
  nextImage,
}) => {
  const zoomImage = images[zoomImageIndex];

  const headerText = (
    <h5>
      3D Render ({zoomImageIndex + 1}/{images.length})
    </h5>
  );

  const updateDate = new Date(zoomImage.updated_at);

  const footer = (
    <Table condensed>
      <thead>
        <tr>
          <th>ID</th>
          <th>Render Time</th>
          <th>View 360?</th>
          <th>Empty Room?</th>
          <th>Last Updated</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{zoomImage.id}</td>
          <td>{zoomImage.render_time}s</td>
          <td>
            <Glyphicon glyph={zoomImage.view_360 ? 'ok' : 'remove'} />
          </td>
          <td>
            <Glyphicon glyph={zoomImage.empty_room ? 'ok' : 'remove'} />
          </td>
          <td>{updateDate.toLocaleString()}</td>
        </tr>
      </tbody>
    </Table>
  );

  return (
    <ImageModal
      imageURL={zoomImage.imagefile}
      showImage={showImage}
      close={close}
      previousImage={previousImage}
      nextImage={nextImage}
    >
      <div key="header">{headerText}</div>
      <div key="footer">{footer}</div>
    </ImageModal>
  );
};

export default RenderModal;
