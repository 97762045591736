import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';

import ButtonAddProp from './ButtonAddProp';
import ModalGenerator from './ModalGenerator';

export default function CustomPropHeader ({
  imageListType = null,
  images = [],
  nextImage = () => {},
  previousImage = () => {},
  showImage = false,
  spaceID = null,
  totalNumberOfProps = 0,
} = {}) {
  const [showModal, setShowModal]  = useState(false);
  const [propWasAdded, setPropWasAdded]  = useState(false);

  /**
   * Flow:
   * - user clicks on ButtonAddProp,
   * - which increments totalNumberOfProps,
   * - which enables showModal and propWasAdded,
   * - resulting in showing ModalGenerator for prop recently added.
   */
  useEffect(() => {
    setShowModal(true);
  }, [totalNumberOfProps]);

  const handleButtonAddPropWasClicked = () => {
    setPropWasAdded(true);
  };

  /**
   * This variable handles the show/no show of this modal.
   * The additional showModal condition prevents the modal
   * to render when this component mounts.
   */
  const showModalForNewProp = showModal && propWasAdded;
  const indexOfPropRecentlyAdded = totalNumberOfProps - 1;
  return (
    <Row className="custom-props-header">
      <span>Existing Furniture ({ totalNumberOfProps })</span>

      <ButtonAddProp
        spaceID={spaceID}
        onClick={handleButtonAddPropWasClicked}
      />

      <ModalGenerator
        close={() => setShowModal(false)}
        imageListType={imageListType}
        images={images}
        nextImage={nextImage}
        previousImage={previousImage}
        showImage={showModalForNewProp}
        spaceID={spaceID}
        zoomImageIndex={indexOfPropRecentlyAdded}
      />
    </Row>
  );
}
