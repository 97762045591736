import React from 'react';
import styled from 'styled-components';
import { FormControl, Tabs, Tab } from 'react-bootstrap';
import get from 'lodash/get';
import partition from 'lodash/partition';

import LoadingIcon from '../LoadingIcon';
import Utils from '../../utils';
import { renderMoneySpan } from './utils';

import OrderProcessingDetailsOrder from './OrderProcessingDetailsOrder';
import OrderProcessingDetailsWholesale from './OrderProcessingDetailsWholesale';
import OrderProcessingHistory from './OrderProcessingHistory';
import OrderProcessingMessages from './OrderProcessingMessages';
import EditableAddress from '../../components/EditableAddress';
import OrderActions from './OrderActions';

export const OrderProcessingDetails = ({
  addHistory = {},
  addProduct = () => {},
  addProductDetails = undefined,
  addProductSearch = (productId) => {},
  canSendOrderUpdateEmail = false,
  datePicker = {},
  dirtyFields = {},
  isDirty = false,
  messages = [],
  onPlaceAutomatedOrders = () => {},
  orderAction = (action, itemId, availableAmount, suborderId) => {},
  orderDetails = {},
  orderHistory = {},
  orderShippingHistory = {},
  orderSourceDiscounts = {},
  purchasingAgents = {},
  saveOrder = () => {},
  sendOrderUpdateEmail = () => {},
  setAddressField = (field, obj) => {},
  showPlaceAutomatedOrders = false,
  spinnerGif = undefined,
  updateOrderItemDetails = (name, value, orderItemId) => {},
  usersDesigners = {},
  usersStylists = {},
} = {}) => {
  if (
    orderDetails.isFetching ||
    usersStylists.isFetching ||
    usersDesigners.isFetching
  ) {
    return (
      <div className="message-style">
        <LoadingIcon size="small" />
      </div>
    );
  }
  const data = orderDetails.data;

  if (!data) {
    return (
      <div>
        <OrderProcessingMessages messages={messages} />
        <div className="message-style">&#x2190; Select Order to the left</div>
      </div>
    );
  }

  /** ***********************************************
   * The logic below is very complicated and needs
   * some serious refactoring or at least comments.
   *
   * asana ticket:
   * https://app.asana.com/0/1131938021244497/1162546395325665
   *
   * On a high level we iterate over data.order_items which
   * is the main data returned from the BE, and we
   * populate this new suborders objects with some data
   * coming from data.order_items and data.suborders.
   ************************************************ */
  const suborders = {};
  let customerSubtotal = 0;
  let customerPromo = 0;
  let customerRetailerDiscount = 0;
  let customerShipping = 0;
  let customerTotal = 0;
  let customerRefundable = 0;
  let customerRefunded = 0;
  let wholesaleSubtotal = 0;
  let wholesaleShipping = 0;
  let wholesaleTax = 0;
  let wholesaleTotal = 0;

  data.order_items.map((orderItem) => {
    const {
      discount,
      quantity,
      retailer_discount: retailerDiscount,
      sales_price: salesPrice,
      suborder_id: suborderId,
    } = orderItem || {};

    // Let's add the source properties to our orderItem
    const associatedProductId = orderItem.product_id;
    const listOfProducts = get(orderDetails, 'data.products', []);
    const associatedProduct = listOfProducts[associatedProductId] || {};
    const sourceProperties = buildSourceProperties(associatedProduct);

    orderItem = {
      ...orderItem,
      sourceProperties,
    };

    if (!suborders[suborderId]) {
      const orderDetailsSuborder =
        (orderDetails &&
          orderDetails.data &&
          orderDetails.data.suborders[suborderId]) ||
        {};
      const {
        shipping_fee,
        source_name,
        wholesale_shipping_fee,
        wholesale_tax,
      } = orderDetailsSuborder;

      suborders[suborderId] = {
        id: suborderId,
        name: source_name,
        shipping_fee,
        quantity: 0,
        discount: 0,
        retailer_discount: 0,
        tiered_discount: 0,
        refunded_amount: 0,
        subtotal: 0,
        total: Number(shipping_fee),
        items: [],
        // Let's add the source properties to our suborder object
        sourceProperties,
        wholesale_shipping_fee,
        wholesale_tax,
        wholesale_subtotal: 0,
      };
      customerShipping += Number(shipping_fee);
      wholesaleShipping += Number(wholesale_shipping_fee);
      wholesaleTax += Number(wholesale_tax);
    }

    // do we care about retailer_discount here?
    suborders[suborderId].quantity += quantity;
    suborders[suborderId].discount += Number(discount);
    suborders[suborderId].retailer_discount += retailerDiscount * quantity;
    suborders[suborderId].tiered_discount += Number(orderItem.tiered_discount);
    suborders[suborderId].subtotal += quantity * salesPrice;
    suborders[suborderId].wholesale_subtotal +=
      quantity * orderItem.properties.wholesale_price;
    suborders[suborderId].refunded_amount +=
      orderItem.properties.refunded_amount;
    suborders[suborderId].total +=
      quantity * (salesPrice - retailerDiscount) -
      discount -
      orderItem.properties.refunded_amount;

    suborders[suborderId].items.push(orderItem);

    customerRetailerDiscount += retailerDiscount * quantity;
    customerRefundable += orderItem.properties.refundable_amount;
    customerRefunded += orderItem.properties.refunded_amount;
    customerSubtotal += quantity * salesPrice;
    customerPromo += Number(discount);
    customerTotal += quantity * (salesPrice - retailerDiscount) - discount;

    wholesaleSubtotal += quantity * orderItem.properties.wholesale_price;
    return 0; // this is required to squelch error
  });
  customerTotal -= data.order_details.tiered_discount;
  customerTotal -= data.order_details.credit_amount;
  customerTotal += Number(data.order_details.customer.tax);
  customerTotal += Number(data.order_details.customer.service_fee);
  customerTotal += customerShipping;
  wholesaleTotal = wholesaleSubtotal + wholesaleShipping + wholesaleTax;
  orderDetails.after_capture =
    [6, 12, 13, 10, 9, 8].indexOf(data.order_details.status_original) >= 0;

  const showCapture = data.order_details.ready_to_capture;
  const showStripeButton =
    !orderDetails.after_capture &&
    data.order_details.payment_details === 'No payment info found';
  const showRefund = data.order_details.ready_to_refund;

  const orderHistoryData = get(orderHistory, 'data', []);
  const historyNoteCount =
    orderHistoryData && Array.isArray(orderHistoryData)
      ? orderHistoryData.filter((row) => row.type === 'note').length
      : 0;
  /**
   * Data for wholesale summary
   */
  const wholesaleData = [
    { label: 'WS Subtotal', value: Utils.formatMoney(wholesaleSubtotal, 2) },
    { label: 'WS Shipping', value: Utils.formatMoney(wholesaleShipping, 2) },
    { label: 'WS Tax', value: Utils.formatMoney(wholesaleTax, 2) },
    { label: 'WS Total', value: Utils.formatMoney(wholesaleTotal, 2) },
    {
      label: 'Margin',
      value: renderMoneySpan(customerTotal - wholesaleTotal, true),
    },
    {
      label: 'Take Rate',
      value: renderMoneySpan(
        customerTotal -
          customerShipping -
          data.order_details.customer.tax -
          (wholesaleTotal - wholesaleShipping - wholesaleTax),
        true
      ),
    },
  ];

  /**
   * As an attempt to refactor this component,
   * let's encapsulate the logic into variables
   */
  const paymentDetails = get(data, 'order_details.payment_details', '');
  const hasPaymentDetails = !!paymentDetails;
  const thirdPartyPaymentUrl = get(
    data,
    'order_details.third_party_payment_url',
    ''
  );
  const hasThirdPartyPaymentUrl = !!thirdPartyPaymentUrl;
  const couponCode = get(data, 'order_details.coupon_code', '');
  const isCouponCodeDirtyField = !!dirtyFields.coupon_code;
  const tieredDiscount = get(data, 'order_details.tiered_discount', '');
  const isTieredDiscountDirtyField = !!dirtyFields.tiered_discount;
  const tieredDiscountPossible = get(
    data,
    'order_details.tiered_discount_possible',
    0
  );
  const creditAmount = get(data, 'order_details.credit_amount', 0);
  const customerTax = get(data, 'order_details.customer.tax', '');
  const serviceFee = get(data, 'order_details.customer.service_fee', '');
  const userFirstName = get(data, 'user_details.first_name', '');
  const userLastName = get(data, 'user_details.last_name', '');
  const userEmail = get(data, 'user_details.email', '');
  const stripeUrlForCustomerDashboard = `https://dashboard.stripe.com/search?query=${encodeURIComponent(
    userEmail
  )}`;
  const openStripeUrl = () => window.open(stripeUrlForCustomerDashboard);

  const isOrderSaving = spinnerGif === 'saveOrder';
  const hasOrderSavingFailed = isDirty && !isOrderSaving;
  const isCapturing = spinnerGif === 'capture';
  const hasRemainingRefund = get(data, 'order_details.status_original') === 10; // TODO: name this magic number.
  const isSendingUpdateOrderEmail = spinnerGif === 'sendOrderUpdateEmail';
  const hasCapturedOrder = !!orderDetails.after_capture;
  const hasRefundedCustomer = hasCapturedOrder && !!customerRefunded;

  // Let's split our suborders into 2 subarrays:
  // automatedSuborders vs manualSuborders
  const [automatedSuborders, manualSuborders] = partition(
    Object.values(suborders),
    (suborder) => !!suborder.sourceProperties.sourceOrderAutomationPlatformId
  );

  /**
   * Logic to place automated orders.
   */
  const hasAutomatedOrders = !!automatedSuborders.length;
  const isPlacingAutomatedOrders = spinnerGif === 'placeAutomatedOrders';

  return (
    <div>
      <OrderProcessingMessages messages={messages} />
      <Tabs defaultActiveKey={1} id="order-details-holder">
        <Tab eventKey={1} title="Customer Order">
          <OrderProcessingDetailsOrder
            addProduct={addProduct}
            addProductDetails={addProductDetails}
            addProductSearch={addProductSearch}
            automatedSuborders={automatedSuborders}
            datePicker={datePicker}
            dirtyFields={dirtyFields}
            manualSuborders={manualSuborders}
            orderAction={orderAction}
            orderDetails={orderDetails}
            orderSourceDiscounts={orderSourceDiscounts}
            orderShippingHistory={orderShippingHistory}
            purchasingAgents={purchasingAgents}
            spinnerGif={spinnerGif}
            updateOrderItemDetails={updateOrderItemDetails}
            usersDesigners={usersDesigners}
            usersStylists={usersStylists}
          />
        </Tab>

        <Tab eventKey={2} title="Wholesale Order">
          <OrderProcessingDetailsWholesale
            dirtyFields={dirtyFields}
            order_details={orderDetails}
            updateOrderItemDetails={updateOrderItemDetails}
            orderAction={orderAction}
            suborders={suborders}
          />
        </Tab>

        <Tab eventKey={3} title={`Order History (${historyNoteCount})`}>
          <OrderProcessingHistory
            orderHistory={orderHistory}
            addHistory={addHistory}
          />
        </Tab>
      </Tabs>

      <div className="right-bar fix-search">
        <OrderActions
          canSendOrderUpdateEmail={canSendOrderUpdateEmail}
          hasAutomatedOrders={hasAutomatedOrders}
          hasOrderSavingFailed={hasOrderSavingFailed}
          hasRemainingRefund={hasRemainingRefund}
          isCapturing={isCapturing}
          isDirty={isDirty}
          isOrderSaving={isOrderSaving}
          isPlacingAutomatedOrders={isPlacingAutomatedOrders}
          isSendingUpdateOrderEmail={isSendingUpdateOrderEmail}
          onOpenStripeUrl={openStripeUrl}
          onOrderAction={orderAction}
          onPlaceAutomatedOrders={onPlaceAutomatedOrders}
          onSaveOrder={saveOrder}
          onSendOrderUpdateEmail={sendOrderUpdateEmail}
          showCapture={showCapture}
          showPlaceAutomatedOrders={showPlaceAutomatedOrders}
          showRefund={showRefund}
          showStripeButton={showStripeButton}
        />

        <div className="info-box">
          <div className="header">Customer Details:</div>

          <div className="content">
            {userFirstName} &nbsp;
            {userLastName}
            <br />
            {userEmail}
          </div>
        </div>

        <div className="info-box">
          <div className="header">
            Shipping Address: &nbsp;
            <button onClick={() => orderAction('shippingEditable')}>
              <span role="img" aria-label="Edit">
                ✏️
              </span>
            </button>
          </div>

          <div className="content">
            <EditableAddress
              obj={data.shipping_details}
              setAddressField={setAddressField}
            />
          </div>
        </div>

        <div className="info-box">
          <div className="header">
            Billing Address: &nbsp;
            <button onClick={() => orderAction('billingEditable')}>
              <span role="img" aria-label="Edit">
                ✏️
              </span>
            </button>
          </div>

          <div className="content">
            <EditableAddress
              obj={data.billing_details}
              setAddressField={setAddressField}
            />
          </div>
        </div>

        <div className="info-box">
          <div className="header">Order Summary:</div>
          {hasPaymentDetails && (
            <FieldBox>
              <FieldLabel>Payment Details</FieldLabel>
              <FieldValue>
                {hasThirdPartyPaymentUrl ? (
                  <a
                    href={thirdPartyPaymentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    {paymentDetails}{' '}
                  </a>
                ) : (
                  `${paymentDetails.replace('American Express', 'Amex')}`
                )}
              </FieldValue>
            </FieldBox>
          )}

          <FieldBox>
            <FieldLabel>Promo Code</FieldLabel>
            <FormControl
              onChange={(e) =>
                updateOrderItemDetails(e.target.name, e.target.value)
              }
              name="coupon_code"
              value={couponCode || ''}
              className={isCouponCodeDirtyField ? 'dirty' : null}
            />
          </FieldBox>

          <FieldBox>
            <FieldLabel>Subtotal</FieldLabel>
            <FieldValue>{Utils.formatMoney(customerSubtotal, 2)}</FieldValue>
          </FieldBox>

          <FieldBox>
            <FieldLabel>Modsy Discount</FieldLabel>
            <FieldValue>{Utils.formatMoney(customerPromo, 2)}</FieldValue>
          </FieldBox>

          <FieldBox>
            <FieldLabel>Retailer Discount</FieldLabel>
            <FieldValue>
              {Utils.formatMoney(customerRetailerDiscount, 2)}
            </FieldValue>
          </FieldBox>

          <FieldBox>
            <FieldLabel>Insider Rewards</FieldLabel>
            <FormControl
              onChange={(e) =>
                updateOrderItemDetails(e.target.name, e.target.value)
              }
              name="tiered_discount"
              value={tieredDiscount}
              className={isTieredDiscountDirtyField ? 'dirty' : null}
            />
          </FieldBox>

          <FieldBox>
            <FieldLabel />
            {Math.abs(tieredDiscountPossible - parseFloat(tieredDiscount)) >
            0.01 ? (
              <button
                onClick={() =>
                  updateOrderItemDetails(
                    'tiered_discount',
                    tieredDiscountPossible
                  )
                }
              >
                <span role="img" aria-label="Up">
                  ☝️
                </span>
                {Utils.formatMoney(tieredDiscountPossible, 2, true)}
              </button>
            ) : null}
          </FieldBox>

          <FieldBox>
            <FieldLabel>User Credits</FieldLabel>
            <FieldValue>{Utils.formatMoney(creditAmount, 2)}</FieldValue>
          </FieldBox>

          <FieldBox>
            <FieldLabel>Shipping</FieldLabel>
            <FieldValue>{Utils.formatMoney(customerShipping, 2)}</FieldValue>
          </FieldBox>

          <FieldBox>
            <FieldLabel>Tax</FieldLabel>
            <FieldValue>{Utils.formatMoney(customerTax, 2)}</FieldValue>
          </FieldBox>

          <FieldBox>
            <FieldLabel>Service Fee</FieldLabel>
            <FieldValue>{Utils.formatMoney(serviceFee, 2)}</FieldValue>
          </FieldBox>

          <FieldBox>
            <FieldLabel>
              <strong>Total Amount</strong>
            </FieldLabel>
            <FieldValue>
              <strong>{Utils.formatMoney(customerTotal, 2)}</strong>
            </FieldValue>
          </FieldBox>

          {hasRefundedCustomer && (
            <FieldBox>
              <FieldLabel>Refunded Amount</FieldLabel>
              <FieldValue>{Utils.formatMoney(customerRefunded, 2)}</FieldValue>
            </FieldBox>
          )}

          {hasCapturedOrder && (
            <FieldBox>
              <FieldLabel>Refundable</FieldLabel>
              <FieldValue>
                {Utils.formatMoney(customerRefundable, 2)}
              </FieldValue>
            </FieldBox>
          )}

          {hasRefundedCustomer && (
            <FieldBox>
              <FieldLabel>Total W/ Refund</FieldLabel>
              <FieldValue>
                {Utils.formatMoney(customerTotal - customerRefunded, 2)}
              </FieldValue>
            </FieldBox>
          )}
        </div>

        <div className="info-box">
          <div className="header">Wholesale Summary:</div>

          {wholesaleData.map((wholesaleItem, index) => {
            return (
              <FieldBox key={`${wholesaleItem.label} - ${index}`}>
                <FieldLabel>{wholesaleItem.label}</FieldLabel>
                <FieldValue>{wholesaleItem.value}</FieldValue>
              </FieldBox>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const FieldBox = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
`;

const FieldLabel = styled.p`
  padding-right: 5px;
  font-size: 13px;
  line-height: 20px;
  color: grey;
  width: 40%;
  margin-bottom: 0;
`;

const FieldValue = styled.p`
  margin: 0;
`;

/**
 * Helper method to build the source properties object.
 */
const buildSourceProperties = (product = {}) => {
  const {
    source__name,
    source__order_automation_platform__id,
    source__order_automation_platform__name,
    source__id,
  } = product;

  return {
    sourceName: source__name,
    sourceOrderAutomationPlatformId: source__order_automation_platform__id,
    sourceOrderAutomationPlatformName: source__order_automation_platform__name,
    sourceId: source__id,
  };
};
