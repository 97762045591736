import React from 'react';
import { Grid, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import setUserID from '../actions/initial-information';

// container components
import BasicInformationContainer from './BasicInformationContainer';
import WhoLivesHereContainer from './WhoLivesHereContainer';
import AdditionalUserInfoContainer from './AdditionalUserInfoContainer';
import AboutYouInfoContainer from './AboutYouInfoContainer';
import InternalCustomerNotesContainer from './InternalCustomerNotesContainer';
import CouponCode from './CouponCode/index';
import SpacesContainer from './SpacesContainer';

function CustomerInfoPortalContainer(props) {
  const { dispatch } = props;
  dispatch(setUserID(props.params.id));

  return (
    <Grid className="heidi">
      <Row className="heidi">
        <Col xs={12}>
          <Row>
            <Col md={5}>
              <BasicInformationContainer />
              <WhoLivesHereContainer />
              <AdditionalUserInfoContainer />
            </Col>
            <Col md={7}>
              <CouponCode />
              <InternalCustomerNotesContainer />
              <AboutYouInfoContainer />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SpacesContainer />
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  );
}

export default connect()(CustomerInfoPortalContainer);
