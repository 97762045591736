import React from 'react';
import { Tooltip, Popover, Glyphicon } from 'react-bootstrap';

/** Collection of the informational tooltips and popovers in the customer information portal * */

// information about flags for renders/products (i.e. 360, Custom Prop, etc.)
export const flagTooltips = {
  'flag-360': (
    <Tooltip id="flag-360">
      Indicates that the render is for 360-degree viewing.
    </Tooltip>
  ),
  'flag-empty-room': (
    <Tooltip id="flag-empty-room">
      Indicates that the renders is of the empty room (for before and after)
    </Tooltip>
  ),
  'flag-custom-prop': (
    <Tooltip id="userPoll">Indicates the product is a Custom Prop</Tooltip>
  ),
  empty: <Tooltip id="empty" />,
};

// information about quiz results
export const quizTooltips = {
  dateTaken: (
    <Tooltip id="dateTaken">When the user took the style quiz.</Tooltip>
  ),
  genome: (
    <Tooltip id="genome">
      The calculated style genome. Click links for style breakdowns.
    </Tooltip>
  ),
  userPoll: (
    <Tooltip id="userPoll">
      Whether the user agrees with genome ( <Glyphicon glyph="ok" /> ),
      disagreed ( <Glyphicon glyph="remove" /> ), was undecided ({' '}
      <Glyphicon glyph="question-sign" /> ), or did not respond ({' '}
      <Glyphicon glyph="ban-circle" /> ).
    </Tooltip>
  ),
  products: (
    <Tooltip id="products">
      Products selected by user during the quiz. Click links for images.
    </Tooltip>
  ),
};

// popover for genome breakdown
export const quizProductsModalInfo = (
  <Popover id="productsModalInfo" title="Style Quiz Products Explanation">
    <p>
      This page displays all of the items the user indicated he/she liked or
      disliked (see navigation tabs to filter results) while navigating through
      the style quiz. The items do not exist in the warehouse, they are only
      meant to get a sense of the user&#39;s style.
    </p>
  </Popover>
);
