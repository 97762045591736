import React from 'react';
import styled from 'styled-components';

import ContentEditable from 'sharedComponents/ContentEditable';

export default function InternalNotesPM({
  handleChange = () => { },
  internalNotes = {},
}) {
  return (
    <Container>
      <FormLabel>{internalNotes.title}</FormLabel>
      <ContentEditable
        resize='vertical'
        data-testid="TextArea2"
        sanitizedtext={internalNotes.text}
        disabled={false}
        onChange={(e) => handleChange(e, internalNotes.type)}
      />
    </Container>
  );
}

const FormLabel = styled.div`
  font-weight: 700 !important;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const Container = styled.div`
  width: 100%;
`;
