import React from 'react';
import get from 'lodash/get';

import Utils from '../../utils';
import { ORDER_STATUS } from '../../constants';
import { TRACKING_NUMBER_CARRIER_MATCH } from './constants';

/**
 * Returns list of available statuses.
 * This list is based on your current status.
 */
export const getStatusList = (currentStatus = null) => {
  const listOfValidStatusIDs = setListOfValidStatusIDs(currentStatus);
  const statusList = [];

  listOfValidStatusIDs.forEach((id) => {
    const orderStatus = ORDER_STATUS[id];
    statusList.push({ id, name: orderStatus });
  });

  return statusList;
};

const setListOfValidStatusIDs = (currentStatus = null) => {
  switch (currentStatus) {
    case 0:
      return [0, 7, 4, 11];
    case 1:
      return [1, 11, 7, 4];
    case 4:
      return [4];
    case 11:
      return [7, 4, 11];
    case 12:
      return [12];
    case 7:
      return [7, 1];
    case 10:
      return [10];
    case 9:
      return [9];
    default:
      return [currentStatus];
  }
};

/**
 * Returns a span with formatted currency value.
 */
export function renderMoneySpan(total = 0, hasColor = false) {
  if (!total) return '-';
  const formattedValue = Utils.formatMoney(total, 2);

  if (hasColor) {
    if (total > 0) {
      return <span style={{ color: 'green' }}>{formattedValue} </span>;
    } else if (total < 0) {
      return <span style={{ color: 'red' }}>{formattedValue} </span>;
    }
  }
  return <span>{formattedValue} </span>;
}

/**
 * Builds the left column inside the order item box.
 */
export const buildColumn2 = ({
  deliveryReasons = [],
  deliveryStatuses = [],
  inventoryStatuses = [],
  item = {},
  product = {},
  options = {},
}) => {
  const column2 = [
    { name: 'Modsy Price', value: product.price, read_only: 1, money: 1 },
    { name: 'Retailer Order #', field: 'retailer_order_number' },
    {
      name: 'Shipping Type',
      field: 'retailer_shipping_type',
      options,
    },
    { name: 'Tracking Number', field: 'retailer_tracking_number' },
    { name: 'Shipping Fee', field: 'shipping_fee', money: 1 },
    { name: 'Delivery Start', field: 'retailer_shipping_start_date' },
    { name: 'Delivery End', field: 'retailer_shipping_end_date' },
    {
      name: 'Inventory Status',
      field: 'inventory_status',
      options: inventoryStatuses,
    },
    {
      name: 'Delivery Status',
      field: 'delivery_status',
      options: deliveryStatuses,
    },
    {
      name: 'Delivery Reason',
      field: 'delivery_reason',
      options: deliveryReasons,
    },
  ];

  if (item.inventory_status === 'backordered') {
    column2.push({
      name: 'Restock Date',
      field: 'availability_info',
    });
  }

  return column2;
};

/**
 * Builds the right column inside the order item box.
 */
export const buildColumn3 = ({
  isEligibleFreeShipping = false,
  item = {},
  orderDetails = {},
  product = {},
  quantities = [],
}) => {
  const column3 = [
    { name: 'Retailer Price', field: 'sales_price', money: 1 },
    { name: 'Quantity', field: 'quantity', options: quantities },
    { name: 'Retailer Discount', field: 'retailer_discount', money: 1 },
    { name: 'Modsy Promo', field: 'discount', money: 1, read_only: 1 },
    {
      name: 'External Id',
      value: product.external_id ? product.external_id : 'none',
      read_only: 1,
    },
    {
      name: 'Free Shipping Eligible',
      value: isEligibleFreeShipping ? 'true' : 'false',
      read_only: 1,
    },
    {
      name: 'From Shop',
      value: item.purchase_flow === 'shop' ? 'true' : 'false',
      read_only: 1,
    },
  ];

  const itemTotal =
    (item.sales_price - item.retailer_discount) * item.quantity - item.discount;

  if (item.properties.refunded_amount !== itemTotal) {
    column3.push({
      name: 'Item Total',
      value: itemTotal,
      read_only: 1,
      money: 1,
    });
  }

  if (item.properties.refunded_amount && orderDetails.after_capture) {
    column3.push({
      name: 'Previously Refunded',
      value: item.properties.refunded_amount,
      read_only: 1,
      money: 1,
    });
  }

  // if the order has been captured, "lock" some of the fields to be readonly
  if (orderDetails.after_capture) {
    column3[0].read_only = 1; // sales_price
    column3[1].read_only = 1; // quantity
    column3[2].read_only = 1; // discount
    column3[3].read_only = 1; // retailer_discount
  }

  return column3;
};

/**
 * Returns initials of user corresponding to given userId.
 */
export const getInitials = (users = {}, userId = null) => {
  const numberOfUsers = get(users, 'data.count');

  if (!numberOfUsers) {
    return '-';
  }

  const userResults = get(users, 'data.results', []);
  const user = userResults.find((user) => user.id === userId);

  if (user) {
    const { first_name, last_name } = user;

    const firstNameInitial = (first_name && first_name[0]) || '';
    const lastNameInitial = (last_name && last_name[0]) || '';

    return (firstNameInitial + lastNameInitial).toUpperCase();
  }

  return '-';
};

/**
 * Returns options under the form:
 * { 0: 0, 1: 1, ... n: n}
 */
export const buildOrderQuantities = (num, quantity_multiplier=1) => {
  const options = {};
  for (let i = 0; i <= num; i+=quantity_multiplier) {
    options[i] = i;
  }

  return options;
};

export const getCarrierByTrackingNumber = (trackingNumber) => {
  let carrier = null;

  if (!trackingNumber) {
    return null;
  }

  for (let i = 0; i < TRACKING_NUMBER_CARRIER_MATCH.length; i++) {
    if (TRACKING_NUMBER_CARRIER_MATCH[i].pattern.exec(trackingNumber)) {
      carrier = TRACKING_NUMBER_CARRIER_MATCH[i].carrier;
      break;
    }
  }

  return carrier;
};
