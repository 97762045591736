import React from 'react';
import styled from 'styled-components';
import { Radio } from 'react-bootstrap';

import PanelLink from 'sharedComponents/PanelLink/PanelLink';
import ContentEditable from 'sharedComponents/ContentEditable';

import { showRenovationChoices, showRenovationQuestion } from '../events';

export default function RenovationChoice({
  dispatch = () => {},
  getSelectedChoiceAndText = () => { },
  handleChange = () => { },
  roomData = {
    choices: {},
    currentText: '',
    currentKey: ''
  },
  shouldShowRenovationChoices,
  shouldShowRenovationQuestion,
}) {
  const { choices, currentText, currentKey } = roomData;

  let selectedChoiceTitle = '';
  if (currentKey) {
    selectedChoiceTitle = `${roomData.choices[currentKey].title}: ${roomData.choices[currentKey].subtitle}`;
  }
  return (
    <Container>
          <PanelLink
            header='Are you making any renovations or updates to your room?'
            link={selectedChoiceTitle}
            displayContent={shouldShowRenovationChoices}
            onClick={() => {
              dispatch(showRenovationChoices());
            }}
          >
            {Object.keys(roomData.choices).map((key, index) => {
              const name = key;
              const title = `${choices[key].title}: ${choices[key].subtitle}`;
              const isSelected = roomData.choices[key].selected;
              return (
                <Radio
                  data-testid='room-choices'
                  key={`${name}-${index}`}
                  checked={isSelected}
                  value={title}
                  onChange={() => {
                    getSelectedChoiceAndText(name);
                  }}
                >
                  {title}
                </Radio>
              );
            })}
          </PanelLink>
          {currentKey !== 'no-updates' && (
            <PanelWrapper>
              <PanelLink
                header='What changes are you planning in this space?'
                link={currentText}
                displayContent={shouldShowRenovationQuestion}
                onClick={() => {
                  dispatch(showRenovationQuestion());
                }}
              >
                <ContentEditable
                  resize='vertical'
                  data-testid='TextArea1'
                  sanitizedtext={currentText}
                  disabled={false}
                  onChange={(e) => handleChange(e)}
                />
              </PanelLink>
            </PanelWrapper>
          )}
    </Container>
  );
}

// We use this to set the middle text container's
// margin-top to match the figma design of 25px
// This is bc the Radio button comes in with a default
// of 10px margin-bottom
const PanelWrapper = styled.div`
  margin-top: 24px;
`;

const Container = styled.div`
  width: 100%;
`;
