import React from 'react';
import PhotosAndMeasurementsModal from './PhotosAndMeasurementsModal';
import DesignProductModal from './DesignProductModal';
import RenderModal from './RenderModal';
import CustomPropModal from './CustomPropModal';
import FavoritePropsModal from './FavoritePropModal';
import { IMAGE_LIST_TYPES } from '../../constants';

const ModalGenerator = ({
  zoomImageIndex,
  images,
  showImage,
  close,
  previousImage,
  nextImage,
  imageListType,
}) => {
  switch (imageListType) {
    case IMAGE_LIST_TYPES.PHOTOS_AND_MEASUREMENTS:
      return (
        <PhotosAndMeasurementsModal
          zoomImageIndex={zoomImageIndex}
          showImage={showImage}
          close={close}
          previousImage={previousImage}
          nextImage={nextImage}
          images={images}
        />
      );
    case IMAGE_LIST_TYPES.DESIGN_PRODUCTS:
      return (
        <DesignProductModal
          zoomImageIndex={zoomImageIndex}
          showImage={showImage}
          close={close}
          previousImage={previousImage}
          nextImage={nextImage}
          images={images}
        />
      );
    case IMAGE_LIST_TYPES.RENDERS:
      return (
        <RenderModal
          zoomImageIndex={zoomImageIndex}
          showImage={showImage}
          close={close}
          previousImage={previousImage}
          nextImage={nextImage}
          images={images}
        />
      );
    case IMAGE_LIST_TYPES.CUSTOM_PROPS:
      return (
        <CustomPropModal
          zoomImageIndex={zoomImageIndex}
          showImage={showImage}
          close={close}
          previousImage={previousImage}
          nextImage={nextImage}
          images={images}
        />
      );
    case IMAGE_LIST_TYPES.FAVORITE_PROPS:
      return (
        <FavoritePropsModal
          zoomImageIndex={zoomImageIndex}
          showImage={showImage}
          close={close}
          previousImage={previousImage}
          nextImage={nextImage}
          images={images}
        />
      );
    default:
      return null;
  }
};

export default ModalGenerator;
