import { combineReducers } from 'redux';
import apiDataReducerGenerator from './api-data-reducer-generator';
import { SPACE_SPECIFIC_API_IDENTIFIERS } from '../constants';
import designSpecificAPIReducers from './design-specific-api-reducers';

// returns object based on SPACE_SPECIFIC_API_IDENTIFIERS
const buildSpaceAPIReducers = () => {
  const apiReducers = {};
  SPACE_SPECIFIC_API_IDENTIFIERS.forEach((identifier) => {
    apiReducers[identifier] = apiDataReducerGenerator(identifier);
  });
  return apiReducers;
};

const spaceSpecificAPIReducer = combineReducers(
  Object.assign(
    {},
    {
      designs_data: designSpecificAPIReducers,
    },
    buildSpaceAPIReducers(),
  ),
);

export default spaceSpecificAPIReducer;
