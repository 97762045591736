import React from 'react';
import {
  FormGroup,
  Row,
  Col,
} from 'react-bootstrap';
import styled from 'styled-components';

import PanelLink from '../../../sharedComponents/PanelLink/PanelLink';
import Checkbox from '../../../sharedComponents/Checkbox/Checkbox';
import TextareaAutosizeExtended from '../../../sharedComponents/TextareaAutosizeExtended';
import { handleFormattingLinks } from '../Utils';
import { ROOM_LAYOUT_AND_FUNCTION_OPTIONS, SPECIAL_REQUESTS_MAP } from './constants';

const ContentContainer = styled.div`
  display: block;
`;

export default function RoomLayoutAndFunction(
  {
    contentDisplay = {},
    who_lives_here = '',
    handleChange = () => {},
    how_to_use_space = '',
    layout_considerations = '',
    kid_friendly = false,
    additional_storage_needed = false,
    extra_lighting = false,
    pet_friendly = false,
    tv_viewing_required = false,
    setContentDisplayOption = () => {},
  },
) {
  // Allows us to set the value and the link of each respective
  // text area in the ROOM_LAYOUT_AND_FUNCTION_OPTIONS function
  const spaceDetailTextAreas = {
    how_to_use_space,
    layout_considerations,
    who_lives_here,
  }

  // The special requests do not come in as a singular object
  // so we have to create one here, to aid in keeping our code DRY!
  const specialRequests = {
    kid_friendly,
    additional_storage_needed,
    extra_lighting,
    pet_friendly,
    tv_viewing_required,
  };

  return (
    <Row>
      <Col xs={12}>
      {
        Object.keys(ROOM_LAYOUT_AND_FUNCTION_OPTIONS).map((key, index) => {
          const controlID = ROOM_LAYOUT_AND_FUNCTION_OPTIONS[key].formID;
          const spaceHeader = ROOM_LAYOUT_AND_FUNCTION_OPTIONS[key].header;
          const spaceIdentifier = ROOM_LAYOUT_AND_FUNCTION_OPTIONS[key].identifier;
          return (
            <FormGroup key={`${key}-${index}`} controlId={controlID}>
              <PanelLink
                header={spaceHeader}
                link={spaceDetailTextAreas[key]}
                displayContent={contentDisplay[spaceIdentifier]}
                onClick={() => setContentDisplayOption(spaceIdentifier)}
              >
                <TextareaAutosizeExtended
                  value={spaceDetailTextAreas[key]}
                  onChange={e =>
                    handleChange(e.target.value, key)}
                />
              </PanelLink>
            </FormGroup>
          )
        })
      }
      </Col>
      <Col xs={12}>
        <FormGroup controlId="formControlsSpecialRequests">
          <PanelLink
            header='Special Requests'
            link={handleFormattingLinks('specialRequests', '')}
            displayContent={contentDisplay.isSpecialRequests}
            onClick={() => setContentDisplayOption('isSpecialRequests')}
          >
            <ContentContainer>
              {
                Object.keys(SPECIAL_REQUESTS_MAP).map((key, index) => {
                  const specialRequestValue = key;
                  const specialRequestLabel = SPECIAL_REQUESTS_MAP[key];

                  return (
                    <Checkbox
                      label={specialRequestLabel}
                      className={'specialRequests'}
                      value={key}
                      key={`${specialRequestLabel}-${index}`}
                      checked={specialRequests[specialRequestValue]}
                      onChange={(e) => {
                        handleChange(e.target.checked, specialRequestValue);
                      }}
                    />
                  )
                })
              }
            </ContentContainer>
          </PanelLink>
        </FormGroup>
      </Col>
    </Row>
  );
}
