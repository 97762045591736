import React from 'react';
import { connect } from 'react-redux';

import InternalCustomerNotes from '../components/InternalCustomerNotes';
import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import { ApiConfig } from '../config';
import { getBasicInformationNeedsRefresh, getUserBasicInfoData, getUserId } from '../reducers/user-data-reducer';
import { bugsnagError } from '../services/bugsnag/index';

class InternalCustomerNotesContainer extends React.Component {

  componentDidUpdate() {
    const { data, dispatch, internalCustomerNotesNeedRefresh, userId } = this.props;

    const basicInformationCache = ['basic_information'];

    if(internalCustomerNotesNeedRefresh) {
        dispatch(
          fetchDataIfNeeded(`${ApiConfig.USERS}/${userId}`, 'basic_information'),
          {
            keys:{internal_user_notes: data.internal_user_notes},
            cache: basicInformationCache,
          }
        ).catch((error) => bugsnagError(error));
    }
  }

  render() {
    const { data, dispatch, userId } = this.props;

    return(
      <React.Fragment>
        <InternalCustomerNotes 
          internalUserNotes={data && data.internal_user_notes}
          dispatch={dispatch}
          userId={userId}
        />
      </React.Fragment>
    )
  }
};

const mapStateToProps = (state) => {
  const data = getUserBasicInfoData(state);
  const userId = getUserId(state);
  const internalCustomerNotesNeedRefresh = getBasicInformationNeedsRefresh(state);

  return {
    data,
    internalCustomerNotesNeedRefresh,
    userId
  };
};

export default connect(mapStateToProps)(InternalCustomerNotesContainer);
