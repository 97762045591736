
import { NoAnswerProvidedStr } from '../../constants';

export const handleFormattingLinks = (value = '', textArea = '') => {
  const classNames = document.getElementsByClassName(value);
  const checkedValues = [];
  for(let i = 0; i < classNames.length; i++) {
    if(classNames[i].checked) {
      checkedValues.push(classNames[i].nextSibling.innerText);
    }
  }

  const hasOptions = !!checkedValues.length;
  const hasText = !!textArea;
  const hasOptionsAndText = hasOptions && hasText;

  switch(true) {
    case hasOptionsAndText:
      return `${checkedValues.join(', ')}, ${textArea}`;
    case hasOptions:
      return `${checkedValues.join(', ')}`;
    case hasText:
      return `${textArea}`;
    default:
      return NoAnswerProvidedStr;
  }
}