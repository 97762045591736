import React from 'react';
import { Row } from 'react-bootstrap';
import Image from './Image';

const ImageThumbnail = ({
  isFavoriteModal,
  handleClick,
  src,
  children,
  styling,
  favoritesStyling,
  imageContainerStyle,
  rotation,
}) => {
  let headerText = null;
  let footerText = null;

  React.Children.forEach(children, (child) => {
    // get the header and footer text passed in as children
    switch (child.key) {
      case 'header':
        headerText = child;
        break;
      case 'footer':
        footerText = child;
        break;
      default:
        break;
    }
  });

  let imageStyle = {};

  //if a rotation is provided, we apply some styling to do rotation and centering
  if (typeof rotation !== 'undefined') {
    imageStyle = {
      transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
      transition: 'all .4s',
      position: 'absolute',
      top: '50%',
      left: '50%',
    };
    imageContainerStyle = imageContainerStyle || {};
    imageContainerStyle['height'] = '430px';
    imageContainerStyle['width'] = '400px';
    imageContainerStyle['position'] = 'relative';

    if ((rotation || 0) % 180 === 90) {
      //on it's side
      imageStyle['height'] = '400px';
      imageStyle['width'] = '400px';
    } else {
      imageStyle['height'] = '400px';
      imageStyle['width'] = '400px';
    }
  }

  // Styles for Favorite Prop Thumbnails.  Ensures Image
  // containers are of the same width and height
  const sx = {
    imageContainer: {
      height: '250px',
      width: '250px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageList: {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      border: 'none',
      display: 'flex',
    },
    imageThumbnailContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  };

  return (
    <div
      className={`image-thumbnail${styling ? ` ${styling}` : ''}`}
      style={isFavoriteModal && sx.imageThumbnailContainer}
    >
      <Row>{headerText}</Row>
      <Row
        className="image-row"
        id={favoritesStyling}
        style={!isFavoriteModal ? imageContainerStyle : null}
      >
        <div style={isFavoriteModal ? sx.imageContainer : imageStyle}>
          <Image
            src={src}
            onClick={handleClick}
            className="img-thumbnail"
            style={isFavoriteModal && sx.imageList}
          />
        </div>
      </Row>
      <Row>{footerText}</Row>
    </div>
  );
};

export default ImageThumbnail;
