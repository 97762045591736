import debounce from 'lodash/debounce';
import constant from 'to-constant-case';

import {
  MAX_TEXT_AREA_COUNT,
  MILLIMETERS_TO_INCHES,
  MILLIMETERS_TO_FEET,
  monthNames,
} from './constants';
import * as request from './global/request';

export default class Utils {
  // builds state object (used for dynamic key generation)
  static buildState(key, value) {
    const returnObj = {};
    returnObj[key] = value;
    return returnObj;
  }

  // builds URL query params from object
  static buildURLParams(params) {
    const parts = [];
    Object.keys(params).forEach((key) => {
      if (params[key] && typeof params[key] !== 'object') {
        parts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        );
      }
    });
    return parts.join('&');
  }

  // gets proper units for passed number (in mm) given state
  static getConvertedValue(mm, currentUnits) {
    if (!mm) return 0;
    if (currentUnits === 'mm') {
      return mm.toFixed(2);
    }
    if (currentUnits === 'in') {
      return (mm * MILLIMETERS_TO_INCHES).toFixed(2);
    }
    if (currentUnits === 'ft') {
      return (mm * MILLIMETERS_TO_FEET).toFixed(2);
    }
  }

  // accepts a val_, a variable that is to be returned,
  // formated as a percent
  // digits - the number of digits to display
  static formatPercent(val_, digits) {
    let val = val_;
    if (!digits) digits = 1;
    if (val === 0 || !val) return '-';
    val = (val * 100).toFixed(digits);
    return `${val}%`;
  }

  // accepts val_, a variable that is to be returned,
  // formatted in a colored span
  // digits - the number of digits to display, 0 is default
  static formatMoney(val_, digits, showZero) {
    let val = val_;
    if (!digits) digits = 0;
    if ((val === 0 || !val) && !showZero) return '-';
    const isNeg = val < 0 ? '-' : '';
    val = Math.abs(val);

    val = val.toFixed(digits).replace(/./g, (c, i, a) => {
      return i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c;
    });
    return `$${isNeg}${val}`;
  }

  static getDate(ts) {
    // input looks like `2018-01-24T09:38:39.097-08:00`
    return ts.substring(0, 10);
  }

  static isValidDate(inputDate) {
    return inputDate instanceof Date && !isNaN(inputDate.valueOf());
  }

  static getShortFormattedDate(str) {
    let date = new Date(str);
    if (!str || !this.isValidDate(date)) {
      return '';
    }
    return `${1 + date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
  }

  static getFormattedDate(str, monthType) {
    let date = new Date(str);

    if (!str || !this.isValidDate(date)) {
      return '';
    }

    if (monthType === 'full') {
      return `${
        monthNames[date.getMonth()].full
      } ${date.getDate()} ${date.getFullYear()}`;
    }
    return `${
      monthNames[date.getMonth()].abbr
    } ${date.getDate()} ${date.getFullYear()}`;
  }

  static formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  /** Gets the formatted date and time
   * Also adds a conditional statment so that we can show
   * the date in different formats
   * If 'long' show the date as such: June 12 2020.
   * Else it will show the default which is short form: 6/12/2020
   */
  static getFormattedDateTime(str, type = 'short', reverse = false) {
    let date = new Date(str);
    let formattedDateType = this.getShortFormattedDate(str);

    if (type === 'long') {
      formattedDateType = this.getFormattedDate(str);
    }

    if (reverse) {
      return `${this.formatAMPM(date)} ${formattedDateType}`;
    }

    return `${formattedDateType} ${this.formatAMPM(date)}`;
  }

  static getTime(ts, noSeconds) {
    // input looks like `2018-01-24T09:38:39.097-08:00`
    if (noSeconds) return ts.substring(11, 11 + 5);
    return ts.substring(11, 11 + 8);
  }

  // take any string, strip everythign except numerals and decimal
  static makeNumeric(val) {
    let ret = val.replace(/[^\d.-]/g, '');
    if (ret === '') {
      ret = 0;
    }
    return ret;
  }

  static textAreaRows(item) {
    return item && Math.ceil(2 + item.length / 60);
  }

  // As the below function is recursive, be careful using it for api requests
  // that have a large number of pages as it could cause a stack overflow
  static getApiPages(url, data, resolve, reject) {
    return request
      .get(url)
      .then((response) => {
        const retrievedData = data.concat(response.data.results);
        if (response.data.next !== null) {
          this.getApiPages(response.data.next, retrievedData, resolve, reject);
        } else {
          resolve(retrievedData);
        }
      })
      .catch((error) => {
        reject(
          `Something went wrong loading the data, please refresh the page and try again. Error: ${error}`
        );
      });
  }

  // Used in conjunction for any textarea that has a
  // 256 text limit from the BE.
  static maxTextCharactersAlert(e) {
    let textLength = e.target.value.length;
    if (textLength >= MAX_TEXT_AREA_COUNT) {
      // We subtract one value from the max so that the user will always have 256 characters to use
      e.target.value = e.target.value.substring(0, textLength - 1);
      return alert(
        `There is a ${
          MAX_TEXT_AREA_COUNT - 1
        } character limit. Please shorten your entry, or if not possible, add more info into the Internal Room Notes field.`
      );
    }
  }
}

// Limit the amount of calls made when the event changes
export function debounceMaxTextCharactersAlert(e) {
  e.persist();
  let debounceFn = null;
  if (!debounceFn) {
    debounceFn = debounce(() => {
      Utils.maxTextCharactersAlert(e);
    }, 500);
  }
  debounceFn();
}

export const USER_FACING_COPY = {
  orderActions: {
    orderActionsTitle: `Order Actions:`,
    saveOrder: `Save Order!`,
    outstandingChanges: `(you have outstanding changes)`,
    captureEmailReceipt: `Capture + Email Receipt`,
    goToStripe: `Go To Stripe`,
    refundOrder: ({ hasRemainingRefund }) =>
      `Refund ${hasRemainingRefund ? 'Remaining' : 'Full'} Order`,
    sendUpdateEmail: `Send Update Email`,
    placeAutomatedOrders: `Place Automated Orders`,
    emailReceipt: `Email Current Receipt Only`,
  },
};

// iterate through the object and returns a new object
// that contains two objects to use id or name for reference
// eg DESIGN_STATUS.by_id (1, 2, 3, etc) or DESIGN_STATUS.by_name (ABANDONED, PENDING, etc)
export function transformConstants(list) {
  const by_name = {};
  const by_id = {};

  Object.keys(list).forEach(function (item) {
    return !list.length ? array(list[item], item) : add(item, list[item]);
  });

  function array(name, id) {
    add(id, name);
  }

  function add(id, name) {
    by_id[id] = name;
    by_name[constant(name)] = parseInt(id, 10);
  }

  return {
    by_id: by_id,
    by_name: by_name,
  };
}
