import * as types from '../actions/action-types';
import get from 'lodash/get';

const initialState = {
  data: {},
};

const userAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_AUTH:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getUserAuthEmail = (state) =>
  get(state, 'user_auth.data.email', '');

export default userAuthReducer;
