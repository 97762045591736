import { combineReducers } from 'redux';
import apiPatchReducerGenerator from './api-patch-reducer-generator';
import { EDIT_REQUEST_IDENTIFIERS } from '../constants';

// returns object based on EDIT_REQUEST_IDENTIFIERS
const buildEditRequestReducers = () => {
  const editReducers = {};
  EDIT_REQUEST_IDENTIFIERS.forEach((identifier) => {
    editReducers[identifier] = apiPatchReducerGenerator(identifier);
  });
  return editReducers;
};

const editRequestReducer = combineReducers(buildEditRequestReducers());

export default editRequestReducer;
