import React, { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { connect, useDispatch } from 'react-redux';

import ImageThumbnail from '../ImageThumbnail';
import { patchData } from '../../actions/api-patch-request-generator';
import { PHOTO_STATUS, USER_IMAGE_STATUS } from '../../constants';
import { ApiConfig } from '../../config';
import rotatePhotosIcon from '../../images/icon-photos-rotate.svg';
import removeIcon from '../../images/icon-photos-remove.svg';
import { dataRefresh } from '../../actions/api-data-request-generator';
import LOADER_GIF from '../images/ajax-loader-2.gif';
import { bugsnagError } from '../../services/bugsnag/index';

function PhotosAndMeasurementsThumbnail ({
  captureType = 0,
  index = 0,
  image = {},
  open = () => {},
  activeSpaceID = 0,
}) {
  const [rotation, setRotation] = useState(0);
  const [rotateLoading, setRotateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const dispatch = useDispatch();
  const identifier = 'dimensions';

  const isIOSCaptureType = captureType === 1;
  const currentImageCount = index + 1;
  const imageTitle = `Room Photo ${currentImageCount}:`;
  // Currently BE accepts, 90, 180, and 270
  const baseRotation = 90;

  const imageDate = new Date(image.created_at);
  const { url } = image;

  const rotateImage = () => {
    setRotateLoading(true);
    const rotateTotal = rotation + baseRotation;

    handleAPICall({
      activeSpaceID,
      data: {
        userimage_id: image.id,
        rotation: baseRotation,
      },
      dispatch,
      method: 'PATCH',
      mainIdentifier: 'space_info_change',
      url: `${ApiConfig.SET_IMAGE_ROTATION(activeSpaceID)}`,
      refreshIdentifier: identifier,
      setData: () => setRotation(rotateTotal),
      setLoading: () => setRotateLoading(false)
    });
  }

  const deleteImage = () => {
    setDeleteLoading(true);

    handleAPICall({
      activeSpaceID: activeSpaceID,
      data: { status: USER_IMAGE_STATUS.NOT_APPROVED },
      dispatch,
      method: 'delete',
      mainIdentifier: 'user_photo_status_change',
      url: ApiConfig.USER_IMAGE_ADMIN_DELETE.replace('{imageID}', image.id),
      refreshIdentifier: identifier,
      setData: () => {},
      setLoading: () => setDeleteLoading(false)
    });
  }

  return (
    <React.Fragment>
      <ImageThumbnail
        key={index}
        handleClick={(e) => {
          if (e.target.id !== 'glyph-button') open(index);
        }}
        src={url}
        rotation={rotation}
      >
        <GridContainer key="header" className="thumbnail-header">
          <div>
            <strong>
              {imageTitle}&nbsp;
              <span className={PHOTO_STATUS[image.status][1]}>
                {PHOTO_STATUS[image.status][0]}
              </span>{' '}
              &nbsp; &nbsp;
            </strong>
            <Text>
              {`${imageDate.toLocaleString()} `}
              <Dot />
              {`${getFileNameFromURL(image.url)} `}
            </Text>
          </div>

          {!isIOSCaptureType && (
            <ButtonsContainer>
              <Button
                className='rotate-photo-button'
                onClick={rotateImage}
              >
                <img
                className='rotate-photo-icon'
                src={rotateLoading ? LOADER_GIF : rotatePhotosIcon}
                alt="rotate icon"
                />
              </Button>

              <Button
                className='remove-photo-button'
                onClick={deleteImage}
              >
                <img
                className='remove-photo-icon'
                src={deleteLoading ? LOADER_GIF : removeIcon}
                alt="remove icon"
                />
              </Button>
            </ButtonsContainer>
          )}
        </GridContainer>
      </ImageThumbnail>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  const currentSpace = state.space_data.filteredSpaces.filter(
    (space) => space.id === activeSpaceID
  );

  const currentSpaceRefresh = state.edit_requests.space_info_change.data;

  return {
    activeSpaceID,
    captureType: get(
      state,
      `space_data[${activeSpaceID}].dimensions.data.results[0].capture_type`,
      0
    ),
    currentSpace,
    currentSpaceRefresh,
  };
};

/** Helper function */
const getFileNameFromURL = (fileUrlString) => {
  let fileUrlPathName = new URL(fileUrlString).pathname;
  return fileUrlPathName.substring(fileUrlPathName.lastIndexOf('/') + 1);
}

/** Helper function for the api calls */
const handleAPICall = (
  {
    activeSpaceID = 0,
    dispatch = () => {},
    url = '',
    mainIdentifier = '',
    data = {},
    method = '',
    refreshIdentifier = '',
    setData = () => {},
    setLoading = () => {}
  } = {}) => {
  dispatch(
    patchData(
      url,
      mainIdentifier,
      {
        data,
        method
      }
    )
  )
  .then(() => {
    dispatch(dataRefresh(refreshIdentifier, { space: activeSpaceID }))
    setData();
    setLoading();
  })
  .catch((error) => {
    bugsnagError(error);
    setLoading();
  })
}

export default connect(mapStateToProps)(PhotosAndMeasurementsThumbnail);

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Text = styled.div`
  color: #888;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  margin: 0 4px;
  background-color: #888;
  border-radius: 50%;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 4px;

  &:first-of-type {
    margin-right: 0.5rem;
  }
`;
