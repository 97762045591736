export const WAREHOUSE_API_URL = ((reactAppEnv) => {
  switch (reactAppEnv) {
    case 'dev':
      return 'https://www.warehouse.dev.modsy.com';
    case 'staging':
      return 'https://www.warehouse.staging.modsy.com';
    case 'prod':
      return 'https://www.warehouse.modsy.com';
    default:
      return process.env.WAREHOUSE_API_URL || 'http://localhost:8000';
  }
})(process.env.REACT_APP_ENV);

// with the splitting of the `www` repo for frontend and backend, we now have two
// groups of domains to point things to, use the above for "api" kinds of requests
// use this one here for urls to send users to in our "warehouse-client" repo
export const WAREHOUSE_CLIENT_URL = ((reactAppEnv) => {
  switch (reactAppEnv) {
    case 'dev':
      return 'https://www.dev.modsy.com';
    case 'staging':
      return 'https://www.staging.modsy.com';
    case 'prod':
      return 'https://www.modsy.com';
    // this presumes you are running warehouse-client on 3001
    default:
      return process.env.WAREHOUSE_CLIENT_URL || 'http://localhost:3001';
  }
})(process.env.REACT_APP_ENV);

export const ApiConfig = {
  BASE: `${WAREHOUSE_API_URL}/warehouse/api`,
  FEEDBASE: `${WAREHOUSE_API_URL}/feed/api`,
  QUIZBASE: `${WAREHOUSE_API_URL}/quiz/api`,
  PRODUCT_CATEGORIES(spaceId) {
    return `${this.BASE}/product-categories?spaceId=${spaceId}`;
  },
  GET_ACTIVITY_LOG(spaceID) {
    return `${WAREHOUSE_API_URL}/warehouse/api/activity_logs?styleboard_id=${spaceID}&sort=desc`;
  },
  get STYLE_COLLECTIONS() {
    return `${this.FEEDBASE}/collections?full_image_data=true`;
  },
  get LIKE_REASONS() {
    return `${this.QUIZBASE}/likereasons?ordered=true`;
  },
  PPO_DATA(spaceId) {
    return `${WAREHOUSE_API_URL}/warehouse/api/spaces/${spaceId}/ppo-data`;
  },
  get SURVEY_ADDTIONAL_QUESTIONS() {
    return `${WAREHOUSE_API_URL}/api/survey-additional-questions`;
  },
  get ASSETS3D() {
    return `${this.BASE}/product3dassets`;
  },
  get GET_BESSIE_LINK() {
    return `${this.BASE}/v1/spaces/{space_id}/designs/{design_id}/start-bessie`;
  },
  get PRODUCTS() {
    return `${this.BASE}/products`;
  },
  GET_USER_COUPONS(userId) {
    return `${this.BASE}/api/payment/get_user_coupons?user_id=${userId}`;
  },
  get USERS() {
    return `${this.BASE}/users`;
  },
  get SPACE_INFO() {
    return `${this.BASE}/styleboardinfo`;
  },
  get RENDERS() {
    return `${this.BASE}/styleboardrenders`;
  },
  get DESIGNS() {
    return `${this.BASE}/v1/spaces`;
  },
  get CUSTOM_PROPS() {
    return `${this.BASE}/v1/space_custom_prop`;
  },
  get CREATE_EMPTY_CUSTOM_PROP() {
    return `${this.BASE}/v1/custom_prop/create_empty_custom_prop`;
  },
  get ADD_CUSTOM_PROP_TAG() {
    return `${this.BASE}/v1/space_custom_prop/{propID}/add_tag`;
  },
  get REMOVE_CUSTOM_PROP_TAG() {
    return `${this.BASE}/v1/space_custom_prop/{propID}/remove_tag`;
  },
  get UPLOAD_CUSTOM_PROP_IMAGE() {
    return `${this.BASE}/v1/custom_prop/{propID}/upload-image`;
  },
  get TAGS() {
    return `${this.BASE}/tags`;
  },
  get TAG_CATEGORIES() {
    return `${this.BASE}/tag_categories`;
  },
  get SPACE_CUSTOM_PROP_PRODUCT_TYPES() {
    return `${this.BASE}/v1/space_custom_prop_product_types`;
  },
  get SOURCES() {
    return `${this.BASE}/sources`;
  },
  get SPACE_DATA_V2() {
    return `${this.BASE}/v2/spaces`;
  },
  get DESIGN_FEEDBACK_V2() {
    return `${this.BASE}/v2/designfeedbacks`;
  },
  get DESIGNSV2() {
    return `${this.BASE}/v2/designs`;
  },
  get STYLE_QUIZ() {
    return `${WAREHOUSE_API_URL}/api/user_quiz_results`;
  },
  get STYLE_QUIZ_V2() {
    return `${WAREHOUSE_API_URL}/api/user_quiz_results_space`;
  },
  // legacy for django admin
  get GDRIVE_UPLOAD() {
    return `${this.BASE}/gdrive_upload`;
  },
  // endpoint used in Heidi
  get UPLOAD_TO_GDRIVE() {
    return `${this.BASE}/upload_to_gdrive`;
  },
  get SEND_USER_PHOTOS_APPROVED_EMAIL() {
    return `${this.BASE}/send_user_images_approved_email`;
  },
  get ORDERS() {
    return `${WAREHOUSE_API_URL}/api/payment/orders`;
  },
  get ORDER_ITEM_HISTORY() {
    return `${WAREHOUSE_API_URL}/api/orders/get_order_items`;
  },
  get PHOTOS_AND_MEASURMENTS() {
    return `${this.BASE}/dimension_dataset`;
  },
  get BILLING_INFO_CHANGE_URL() {
    return `${WAREHOUSE_API_URL}/api/payment/billinginfo`;
  },
  get USER_IMAGES() {
    return `${this.BASE}/user_images`;
  },
  get USER_IMAGE_ADMIN_UPLOAD() {
    return `${this.BASE}/v1/{spaceID}/user_photo_upload`;
  },
  get USER_IMAGE_ADMIN_DELETE() {
    return `${this.BASE}/v1/{imageID}/admin_remove_photo`;
  },
  get STYLEBOARD() {
    return `${this.BASE}/styleboards`;
  },
  FORCE_SET_SPACE_TO_UNDER_REVIEW_STATUS(spaceID) {
    return `${this.BASE}/styleboards/${spaceID}/force_transition_to_under_review`;
  },
  SET_SPACE_TO_UNDER_REVIEW_STATUS(spaceID) {
    return `${this.BASE}/styleboards/${spaceID}/transition_to_under_review`;
  },
  SET_FEATURE_IMAGE(id) {
    return `${this.BASE}/styleboards/${id}/set_feature_image`;
  },
  SET_IMAGE_ROTATION(id) {
    return `${this.BASE}/styleboards/${id}/set_image_rotation`;
  },
  get ADMIN_STYLEBOARD() {
    return `${this.BASE}/admin/styleboards`;
  },
  get STYLEBOARD_ADDPRODUCT_FORMAT() {
    return `${this.STYLEBOARD}/{slug}/add_styleboard_product`;
  },
  get SEARCH_FOR_PRODUCTS() {
    return `${this.BASE}/search_for_products`;
  },
  get USER_STYLEBOARD() {
    return `${this.BASE}/user/styleboard`;
  },
  get COPYSTYLEBOARDPRODUCTS() {
    return `${this.BASE}/copy_styleboard_products`;
  },
  get LOGIN() {
    return `${this.BASE}/login`;
  },
  get LOGOUT() {
    return `${this.BASE}/logout`;
  },
  get CHANGENAME() {
    return `${this.BASE}/user/change_name`;
  },
  get CREATE_STYLEBOARD() {
    return `${this.BASE}/create_styleboard`;
  },
  get GET_STYLEBOARD_AND_INFO_BASE() {
    return `${this.BASE}/styleboard_and_info`;
  },
  get GET_LATEST_STYLEBOARD_AND_INFO_BASE() {
    return `${this.BASE}/spaces/latest`;
  },
  get CHANGEPASSWORD() {
    return `${this.BASE}/user/change_password`;
  },
  get CHANGEEMAIL() {
    return `${this.BASE}/user/change_email`;
  },
  get ACTIVATE() {
    return `${this.BASE}/user/activate`;
  },
  PAYMENT: '/api/payment',
  get RESET_PASSWORD() {
    return `${this.BASE}/user/reset-password`;
  },
  get PAYMENTMETA() {
    return `${this.PAYMENT}/get_metadata`;
  },
  get PLACEORDER() {
    return `${this.PAYMENT}/place_order`;
  },
  get UPDATECART() {
    return `${this.PAYMENT}/shopping_cart/current`;
  },
  USERAUTH: `${WAREHOUSE_API_URL}/api/users/info`,
  USERSIGNUP: `${WAREHOUSE_API_URL}/api/users/signup`,
  get CHANGE_RESET() {
    return `${this.BASE}/user/change-reset`;
  },
  get WELCOME_DATA() {
    return `${this.BASE}/user/get-welcome-data`;
  },
  get WELCOME_CONTINUE() {
    return `${this.BASE}/user/welcome-continue`;
  },
  get SPACE_LIST() {
    return `${this.BASE}/spacelist`;
  },
  get GET_ALTERNATIVES() {
    return `${this.BASE}/spaces/{spaceID}/alternatives/{productID}`;
  },
  get SET_FLAGS() {
    return `${this.BASE}/user/set-account-flags`;
  },
  get GET_ASSET_OPTIONS() {
    return `${this.BASE}/asset_options`;
  },
  get SET_ASSET_OPTIONS() {
    return `${this.BASE}/set_asset_options`;
  },
  get SPACE_SET_REMINDER() {
    return `${this.BASE}/spaces/{spaceID}/dashboard/set-reminder`;
  },
  get SPACE_SET_CURRENT_TAB() {
    return `${this.BASE}/spaces/{spaceID}/dashboard/set-current-tab`;
  },
  get UPLOAD_PHOTOS() {
    return `${this.BASE}/spaces/{spaceID}/dashboard/submit-photos`;
  },
  get REMOVE_PHOTOS() {
    return `${this.BASE}/spaces/{spaceID}/dashboard/remove-photos`;
  },
  get SUBMIT_MEASUREMENT() {
    return `${this.BASE}/spaces/{spaceID}/dashboard/submit-measurement`;
  },
  get UPDATE_SPACE_FLAGS() {
    return `${this.BASE}/spaces/{spaceID}/set-space-flags`;
  },
  get SPACE_ASSISTANCE() {
    return `${this.BASE}/v1/space_assistance`;
  },
  GET_SPACE(id) {
    return `${this.BASE}/spaces/${id}`;
  },
  GET_MOBILE_CAPTURE(spaceId) {
    return `${WAREHOUSE_API_URL}/mobicap/api/projects?space_id=${spaceId}`;
  },
  ADDITIONAL_PHOTOS(spaceId) {
    return `${this.BASE}/spaces/${spaceId}/dashboard/additional-photos`;
  },
  PRESIGNED_DOWNLOAD_URL() {
    return `${this.BASE}/s3-presigned-download-urls`;
  },
  PRESIGNED_UPLOAD_URL() {
    return `${this.BASE}/s3-presigned-upload-url`;
  },
  SPACE_SELECT_LAYOUT(spaceId) {
    return `${this.BASE}/v2/spaces/${spaceId}/layouts`;
  },
  SEND_ASSISTANCE_EMAIL(spaceId) {
    return `${this.BASE}/spaces/${spaceId}/send_assistance`;
  },
  SAVE_ONBOARDING_INFO: (spaceId) =>
    [`${WAREHOUSE_API_URL}/warehouse/api`, 'spaces', spaceId, 'info'].join('/'),
  APPLY_PROMO_CODE: () => `${WAREHOUSE_API_URL}/api/payment/apply_coupon`,
  get SEARCH_PRODUCTS() {
    return `${this.BASE}/spaces/products/search`;
  },
  GET_FAVORITES_BY_USER_AND_PAGE({ userId, page = 1 }) {
    return `${this.BASE}/favorites?user_id=${userId}&page=${page}`;
  },
  GET_USER_FEEDPAGE_FAVORITES({ userId }) {
    return `${this.BASE}/user/${userId}/feedpage-favorites`;
  },
  GET_PRODUCT_LIST(productId) {
    return `${this.BASE}/products/batch?id_list=${productId}`;
  },

  STYLE_QUIZ_ROOMS: `${WAREHOUSE_API_URL}/api/rooms_nested`,
  SUBMIT_STYLE_QUIZ: `${WAREHOUSE_API_URL}/api/survey_result`,
  FETCH_STYLE_PRODUCTS: `${WAREHOUSE_API_URL}/warehouse/api/style-products/`,
  FETCH_CATEGORIES: `${WAREHOUSE_API_URL}/warehouse/api/categories`,
  get GET_COLLECTIONS() {
    return `${this.BASE}/collections`;
  },
  get SUBMIT_CUSTOM_PROP() {
    return `${this.BASE}/v1/custom_prop/{id}/submit`;
  },
  APPROVE_CUSTOM_PROP(id) {
    return `${this.BASE}/v1/custom_prop/${id}/approve`;
  },

  APPROVE_USER_IMAGES(spaceId) {
    return `${this.BASE}/styleboards/${spaceId}/approve_all_images`;
  },

  REJECT_USER_IMAGES(spaceId) {
    return `${this.BASE}/styleboards/${spaceId}/reject_all_images`;
  },
  get GET_ORDERS() {
    return `${WAREHOUSE_API_URL}/api/orders/get_order_groupings`;
  },
  get GET_ORDER_LIST() {
    return `${WAREHOUSE_API_URL}/api/orders/get_orders`;
  },
  SHOWROOM_INFO(id) {
    return `${WAREHOUSE_API_URL}/showroom/api/showrooms/${id}`;
  },
  ORDER_DETAILS(id) {
    return `${WAREHOUSE_API_URL}/payment/api/admin/orders/${id}/details`;
  },
  ORDER_HISTORY(id) {
    return `${WAREHOUSE_API_URL}/payment/api/admin/orders/${id}/history`;
  },
  ORDER_SHIPPING_HISTORY(id) {
    return `${WAREHOUSE_API_URL}/payment/api/admin/orders/${id}/shipping_history`;
  },
  ORDER_SOURCE_DISCOUNTS(id) {
    return `${WAREHOUSE_API_URL}/warehouse/api/sourcediscounts/order/${id}`;
  },
  USERS_BY_GROUP() {
    return `${WAREHOUSE_API_URL}/warehouse/api/users`;
  },
  ADD_INTENDED_PIECE(styleboardId) {
    return `${WAREHOUSE_API_URL}/warehouse/api/styleboardinfo/${styleboardId}/update_intended_pieces`;
  },
  get ABOUTYOU() {
    return `${this.BASE}/about_users`;
  },
};
export const NewAdminConfig = {
  BASE: WAREHOUSE_API_URL,
  UNAUTHORIZED_ROUTE: 'unauthorized',
  get UNAUTHORIZED() {
    return this.UNAUTHORIZED_ROUTE;
  },
  ASSETDOWNLOADER_ROUTE: 'assets',
  get ASSETDOWNLOADER() {
    return this.ASSETDOWNLOADER_ROUTE;
  },
  PRODUCTFINDER_ROUTE: 'products',
  get PRODUCTFINDER() {
    return this.PRODUCTFINDER_ROUTE;
  },
  STYLEBOARD_ROUTE: 'styleboard',
  get STYLEBOARD() {
    return this.STYLEBOARD_ROUTE;
  },
  STYLEBOARDCOPIER_ROUTE: 'styleboard/copy',
  get STYLEBOARDCOPIER() {
    return this.STYLEBOARDCOPIER_ROUTE;
  },
  USERS_ROUTE: 'users',
  get USERS() {
    return this.USERS_ROUTE;
  },
  ORDERS_REPORT_ROUTE: 'orders_report',
  get ORDERSREPORT() {
    return this.ORDERS_REPORT_ROUTE;
  },
  ORDER_PROCESSING_ROUTE: 'order_processing',
  get ORDERPROCESSING() {
    return this.ORDER_PROCESSING_ROUTE;
  },
  SENDPMDENIED_ROUTE: 'send_pm_denied',
  get SENDPMDENIED() {
    return this.SENDPMDENIED_ROUTE;
  },
};

export const OldAdminConfig = {
  BASE: `${WAREHOUSE_API_URL}/admin/warehouse`,
  get PRODUCT() {
    return `${this.BASE}/product`;
  },
  get USER() {
    return `${this.BASE}/user`;
  },
  get STYLEBOARD() {
    return `${this.BASE}/styleboard`;
  },
};

export const Config = {
  BASE: '',
  get INTRO() {
    return `${this.BASE}/studio`;
  },
  get SPACE() {
    return `${this.INTRO}/space`;
  },
};

export const StaticConfig = {
  BASE: './static',
  get MODSY_LOGO() {
    return `${this.BASE}/images/icons/apple-icon-76x76.png`;
  },
  get LOADER_GIF() {
    return `${this.BASE}/images/ajax-loader.gif`;
  },
  get CLOSE_X() {
    return `${this.BASE}/images/close-icon.png`;
  },
  get CART_ICON() {
    return `${this.BASE}/images/cart-icon.png`;
  },
  get PAYMENT_SUCCESS_ICON() {
    return `${this.BASE}/images/payment-success.png`;
  },
  get HEART() {
    return `${this.BASE}/images/heart.png`;
  },
  get EMPTY_CART() {
    return `${this.BASE}/images/empty-cart.png`;
  },
  get ARROW_DOWN() {
    return `${this.BASE}/images/arrow-down.png`;
  },
  get ARROW_RIGHT() {
    return `${this.BASE}/images/arrow-right.png`;
  },
  get UPGRADE_ICON() {
    return `${this.BASE}/images/upgrade-icon.png`;
  },
  get SUCCESS() {
    return `${this.BASE}/images/success-img.png`;
  },
  get SHOPPING_TAG() {
    return `${this.BASE}/images/shopping-tag.svg`;
  },
  get STAR_ICON() {
    return `${this.BASE}/images/star-icon.svg`;
  },
  DASH_COMPLETE_ICON: './static/images/dash-complete-icon.svg',
  DASH_STYLE_ICON: './static/images/dash-style-icon.svg',
  DASH_PHOTOS_ICON: './static/images/dash-photos-icon.svg',
  DASH_MENU_ICON: './static/images/dash-menu-icon.svg',
  DASH_MENU_ARROW: './static/images/menu-arrow.svg',
  DASH_MOBILE_CHECK: './static/images/dash-mobile-check.svg',
  DASH_TIP_ICON: './static/images/dash-tip-icon.svg',
  DASH_WARNING_ICON: './static/images/dash-warning-icon.svg',
  DASH_MEASURE_ICON: './static/images/dash-measure-icon.svg',
  DASH_PENDING_ICON: './static/images/dash-pending-icon.svg',
  DASH_BLUE_TIP: './static/images/blue-tip.svg',
  DASH_ORANGE_PHOTOS: './static/images/orange-photos-icon.svg',
  DASH_ROTATE_TIP: './static/images/rotate-tip.png',
  DASH_LIGHTBULB: './static/images/lightbulb.svg',
  DASH_CURTAINS: './static/images/curtains-tip.svg',
  PHOTOS_CORNERS: './static/images/photos-corners.jpg',
  PHOTOS_CENTER: './static/images/photos-center.jpg',
  DISCUSSION_ICON: './static/images/discussion-icon.svg',
  DISCUSSION_ICON_GREY: './static/images/discussion-icon-grey.svg',
  PRODUCT_DELETE: './static/images/product-delete-icon.svg',
  SMALL_LOGO: './static/images/small-modsy-logo.svg',
  BLUE_CHECK: './static/images/blue-check.svg',
  GUIDE_RINGS: './static/images/guide-rings.svg',
  FORM_ERROR: './static/images/form-error.svg',
};
