import React from "react";
import styled from 'styled-components/macro';
import closeIconBlack from 'images/close_icon_black.svg';
import { LeftCarot, RightCarot } from './icons';

import Modal from "react-modal";
Modal.setAppElement('#root');

const customStyles = {
overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2',
  },
  content : {
    width: '50%',
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    top: 'none',
    left: 'none',
    right: 'none',
    bottom: 'none',
    borderRadius: '8px',
    position: 'relative'
  }
};

export default function CustomImageModal({
  activeIndex = 0,
  className = 'image-modal',
  decrement = () => {},
  increment = () => {},
  footerContent = '',
  headerContent = '',
  images = [],
  isOpen = false,
  toggleModal = () => {}
}) {
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={() => { document.body.style.overflow = 'hidden'; }} 
      onAfterClose={() => { document.body.removeAttribute('style'); }}
      onRequestClose={toggleModal}
      style={customStyles}
      contentLabel="Custom Modal"
    >
    <Icon 
      alt='close icon'
      onClick={toggleModal}
      src={closeIconBlack}
    />
    <HeaderContainer>{headerContent}</HeaderContainer>
    <ContentContainer>
    <ArrowContainer>
      <LeftCarotExtended fill='#EF4D21' className={className} onClick={decrement} />
    </ArrowContainer>
    {images && images.length > 0 && images.map((key, index) => {
        const image = key.filename;
        if (activeIndex === index) {
          return (
            <ImageContainer key={index}>
              <Image src={image} alt='custom-image'/>
            </ImageContainer>
          )
        };
        return null;
    })}
    <ArrowContainer>
      <RightCarotExtended fill='#EF4D21' className={className} onClick={increment} />
    </ArrowContainer>
    </ContentContainer>
    <FooterContainer>{footerContent}</FooterContainer>
    </Modal>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #CCCCCC;
  font-weight: 600;
  margin-bottom: 16px;
  padding-right: 48px;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 16px;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  border-top: 1px solid #CCCCCC;
  font-weight: 600;
  margin-top: 16px;
`;

const ArrowContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  width: 50px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

const Image = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
`;

const Icon = styled.img`
  cursor: pointer;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
`;

const LeftCarotExtended = styled(LeftCarot)`
  &.image-modal {
    cursor: pointer;
  }

  &.image-modal:hover path{
    fill: #c43812;
  }
`;

const RightCarotExtended = styled(RightCarot)`
  &.image-modal {
    cursor: pointer;
  }

  &.image-modal:hover path{
    fill: #c43812;
  }
`;
