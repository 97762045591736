import React from 'react';
import { Panel, Col, Row, FormControl, Glyphicon } from 'react-bootstrap';
import map from 'lodash/map';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import CustomCheckbox from './CustomCheckbox';
import PatchButton from './PatchButton';
import { patchData } from '../actions/api-patch-request-generator';
import { ApiConfig } from '../config';

export default class TagPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterSelected: true,
      searchTerms: '',
    };
  }

  updateSearchTerms = (e) => {
    this.setState({ searchTerms: e.target.value });
  };

  handleFilterSelectedClick = () => {
    this.setState({ filterSelected: !this.state.filterSelected });
  };

  render() {
    if (!this.props.tagCategories) return null;
    const header = (
      <Row>
        <Col xs={10} className="search">
          <Col xs={8}>
            <FormControl
              type="text"
              placeholder="Search tags..."
              className="input-height-limited"
              onChange={this.updateSearchTerms}
              value={this.state.searchTerms}
            />
          </Col>
          <Col xs={4} className="filter-selected right-align-text">
            Filter Selected
          </Col>
        </Col>
        <Col xs={2}>
          <CustomCheckbox
            styling="hack-vert-center"
            checked={this.state.filterSelected}
            handleClick={this.handleFilterSelectedClick}
          />
        </Col>
      </Row>
    );

    // reassign
    let tagList = this.props.tags.slice();
    const activeTagsSet = new Set(map(this.props.zoomImage.tags, 'id'));
    const tagCategories = this.props.tagCategories;

    // filter results
    if (this.state.filterSelected) {
      tagList = tagList.filter(tag => activeTagsSet.has(tag.id));
    }

    if (this.state.searchTerms) {
      tagList = tagList.filter((tag) => {
        let tagName;
        if (tag && tag.tag_category && tagCategories) {
          const tagKey = findKey(
            tagCategories,
            o => o.name === tag.tag_category,
          );
          tagName = `${tagCategories[tagKey].name}--${tag.name}`.toUpperCase();
        }

        return tagName.indexOf(this.state.searchTerms.toUpperCase()) >= 0;
      });
    }

    return (
      <span className="tag-picker">
        <Panel header={header}>
          <div className="tag-list">
            {tagList.map((tag) => {
              const checked = activeTagsSet.has(tag.id);
              let tagName;
              if (tag && tag.tag_category && tagCategories) {
                const tagKey = findKey(
                  tagCategories,
                  o => o.name === tag.tag_category,
                );
                tagName = tagCategories[tagKey].name;
              }
              return (
                <Row key={tag.id}>
                  <Col xs={10} className="tag-name">
                    <span className={checked ? 'selected-tag' : null}>
                      {`${tagName}--${get(tag, 'name')}`}
                    </span>
                  </Col>
                  <Col xs={2} className="checkbox-holder">
                    <PatchButton
                      buttonStyle="input-custom-checkbox"
                      patchRequests={[
                        patchData(
                          `${(checked
                            ? ApiConfig.REMOVE_CUSTOM_PROP_TAG
                            : ApiConfig.ADD_CUSTOM_PROP_TAG).replace(
                            '{propID}',
                            this.props.zoomImage.id,
                          )}`,
                          'custom_prop_field_change',
                          {
                            data: { tag_id: tag.id },
                            method: checked ? 'delete' : 'post',
                          },
                        ),
                      ]}
                      identifier="custom_props"
                    >
                      <Glyphicon
                        glyph="ok"
                        style={{ display: checked ? null : 'none' }}
                      />
                    </PatchButton>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Panel>
      </span>
    );
  }
}
