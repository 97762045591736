import React from 'react';
import { connect } from 'react-redux';

import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import { getAboutYouData, getAboutYouNeedsRefresh } from '../reducers/user-data-reducer';
import { ApiConfig } from '../config';
import { bugsnagError } from '../services/bugsnag';

import AdditionalUserInfo from '../components/AdditionalUserInfo';

class AdditionalUserInfoContainer extends React.PureComponent {
  componentDidMount() {
    this.handleUpdatingAdditionalUserInfo();
  }

  componentDidUpdate() {
    const { aboutYouNeedsRefresh } = this.props;
    if(aboutYouNeedsRefresh) {
      this.handleUpdatingAdditionalUserInfo();
    }
  }

  handleUpdatingAdditionalUserInfo = () => {
    const { dispatch, userId } = this.props;
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.ABOUTYOU}/${userId}`, 'about_you'),
    ).catch((error) => bugsnagError(error));
  }

  render() {
    const { data, dispatch, userId } = this.props;
    return(
        <React.Fragment>
          <AdditionalUserInfo
            data={ data }
            additionalInfo={data && data.additional_info}
            dispatch={ dispatch }
            userId={ userId }
          />
        </React.Fragment>
      )
    }
}

const mapStateToProps = (state) => {
  const data = getAboutYouData(state);
  const userId = state.user_data.user_id;
  const aboutYouNeedsRefresh = getAboutYouNeedsRefresh(state);

  return {
    aboutYouNeedsRefresh,
    data,
    userId
  };
};

export default connect(mapStateToProps)(AdditionalUserInfoContainer);
