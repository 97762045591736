import $ from 'jquery';
import cookie from 'js-cookie';
import { ApiConfig, StaticConfig } from './config';

export class ProductModel {
  constructor() {
    this.loading = false;
  }

  getPrimaryImage(product) {
    return product.images.length === 0
      ? StaticConfig.MODSY_LOGO
      : product.images[0].filename;
  }

  isLoading() {
    return this.loading;
  }

  getPage(url, success) {
    this.loading = true;
    $.ajax({
      type: 'GET',
      dataType: 'json',
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      xhrFields: {
        withCredentials: true,
      },
      url,
      success: (data) => {
        this.productListResponse = data;
        this.loading = false;
        success({ productModel: this, show_spinner: false });
      },
      error: (data) => {
        this.loading = false;
      },
    });
  }

  getListInitial(searchStr, maxPrice, hasAssets, success) {
    let url = `${ApiConfig.PRODUCTS}?page=1`;
    if (searchStr !== '' && searchStr != null) {
      url = `${url}&search=${searchStr}`;
    }
    if (hasAssets) {
      url += '&has_assets=true';
    }
    if (maxPrice) {
      url = `${url}&max_price=${maxPrice.toString()}`;
    }
    this.getPage(url, success);
  }

  getListPrevious(success) {
    // NOTE: this depends on pagination response having query parameters preserved. (Djangoism)
    if (this.productListResponse.previous != null) {
      this.getPage(this.productListResponse.previous, success);
    } else {
      success({ show_spinner: false });
    }
  }

  getListNext(success) {
    if (this.productListResponse.next != null) {
      this.getPage(this.productListResponse.next, success);
    } else {
      success({ show_spinner: false });
    }
  }

  getProductBySlug(slug, success) {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `${ApiConfig.PRODUCTS}/${slug}`,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        this.product = data;
        success({ productModel: this, productSlug: this.product.slug });
      },
      error: (data) => {
        this.product = null;
        success({ productModel: this });
      },
    });
  }
}

export class StyleboardModel {
  getAllStyleboards(success) {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ApiConfig.STYLEBOARD,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      xhrFields: {
        withCredentials: true,
      },
      success: (data) => {
        this.all_styleboards = data;
        success({ styleboardModel: this });
      },
      error: (data) => {
        this.all_styleboards = null;
        success({ styleboardModel: this });
      },
    });
  }

  getStyleboardBySlug(slug, success) {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      xhrFields: {
        withCredentials: true,
      },
      url: `${ApiConfig.STYLEBOARD}/${slug}`,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      success: (data) => {
        this.styleboard = data;
        success({
          styleboardModel: this,
          styleboardSlug: this.styleboard.slug,
        });
      },
      error: (data) => {
        this.styleboard = null;
        success({ styleboardModel: this });
      },
      statusCode: {
        404: () => {
          this.styleboard = null;
          success({ styleboardModel: this });
        },
      },
    });
  }

  addProductToStyleboard(product, customProp, spaceId, success, error) {
    $.ajax({
      type: 'POST',
      url: ApiConfig.STYLEBOARD_ADDPRODUCT_FORMAT.replace('{slug}', spaceId),
      xhrFields: {
        withCredentials: true,
      },
      data: {
        layout_order: 1,
        // TODO: hardcoded for now
        style_assignment: 1,
        // TODO: same
        product: product.slug,
        customProp,
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      success: (data) => {
        success();
      },
    });
  }

  getAssetOptions(success) {
    const model = this;
    $.ajax({
      type: 'GET',
      url: ApiConfig.GET_ASSET_OPTIONS,
      dataType: 'json',
      xhrFields: {
        withCredentials: true,
      },
      beforeSend(xhr) {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      success: (data) => {
        model.status_options = data.asset_status;
        model.phase_options = data.asset_phase;
        success({ styleboardModel: this });
      },
    });
  }

  changeAssetStatusOrPhase(
    status,
    productIndex,
    assetIndex,
    assetID,
    newValue,
    label,
    success,
  ) {
    const params = { pk: assetID };
    if (status) {
      params.status = newValue;
    } else {
      params.phase = newValue;
    }

    $.ajax({
      type: 'POST',
      url: ApiConfig.SET_ASSET_OPTIONS,
      xhrFields: {
        withCredentials: true,
      },
      data: JSON.stringify(params),
      contentType: 'application/json',
      dataType: 'json',
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      statusCode: {
        200: () => {
          const styleboard = this.styleboard;
          if (status) {
            styleboard.products[productIndex].product.assets[
              assetIndex
            ].status = label;
          } else {
            styleboard.products[productIndex].product.assets[
              assetIndex
            ].phase = label;
          }
          success({ styleboardModel: this });
        },
      },
    });
  }
}
