import React from 'react';
import {
  Popover,
  Table,
  Glyphicon,
  Badge,
  OverlayTrigger,
} from 'react-bootstrap';
import ImageModal from '../ImageModal';
import upperFirst from 'lodash/upperFirst';

const DesignProductModal = ({
  zoomImageIndex,
  images,
  showImage,
  close,
  previousImage,
  nextImage,
}) => {
  const zoomImage = images[zoomImageIndex];

  const headerText = (
    <h5>
      {zoomImage.slug} ({zoomImageIndex + 1}/{images.length})
    </h5>
  );

  const tags = (
    <Popover title="Product Tags" id="tags-popover" className="product-tags">
      <Table condensed bordered striped>
        <thead>
          <tr>
            {Object.keys(zoomImage.tags).map((key, i) => {
              return <th key={i}>{key}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.entries(zoomImage.tags).map((entry, i) => {
              return (
                <td key={i}>{upperFirst(entry.splice(1)[0].join(', '))}</td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    </Popover>
  );

  const footer = (
    <Table condensed>
      <thead>
        <tr>
          <th>ID</th>
          <th>3D Status</th>
          <th>In Stock?</th>
          <th>Tags</th>
          <th>Source</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{zoomImage.id}</td>
          <td>{upperFirst(zoomImage.assets_status)}</td>
          <td>
            <Glyphicon glyph={zoomImage.out_of_stock ? 'remove' : 'ok'} />
          </td>
          <td>
            <OverlayTrigger placement="top" overlay={tags}>
              <Badge>{Object.keys(zoomImage.tags).length}</Badge>
            </OverlayTrigger>
          </td>
          <td>{zoomImage.source}</td>
          <td>${zoomImage.price}</td>
        </tr>
      </tbody>
    </Table>
  );

  return (
    <ImageModal
      imageURL={zoomImage.images[0] ? zoomImage.images[0].filename : null}
      showImage={showImage}
      close={close}
      previousImage={previousImage}
      nextImage={nextImage}
    >
      <div key="header">{headerText}</div>
      <div key="footer">{footer}</div>
    </ImageModal>
  );
};

export default DesignProductModal;
