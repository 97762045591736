import { useEffect, useState } from 'react';

import { ApiConfig } from '../../config';
import * as request from '../../global/request';
import { bugsnagError } from '../../services/bugsnag/index';

export default function useQualityMetrics({
  activeSpaceID = 0,
  useFlag = () => {},
}) {
  const [qualityMetricsResults, setQualityMetricsResults] = useState(
    initialState
  );
  const [hasMetricsTextFile, setHasMetricsTextFile] = useState(true);

  // Get the threshold values from the IOS Config-cat
  // Or set a default value of 0.5
  // Doc here: https://docs.google.com/document/d/1ecz3jORv9c9E9Gc9q59EoE9tTCEywVtXwElnMIX_SIA/edit
  const thresholdValues = {
    erraticness: useFlag('erraticness_metric_threshold', {
      clientName,
      defaultValue,
    }),
    inward: useFlag('inward_metric_threshold', {
      clientName,
      defaultValue,
    }),
    outward: useFlag('outward_metric_threshold', {
      clientName,
      defaultValue,
    }),
    tripod: useFlag('tripod_metric_threshold', {
      clientName,
      defaultValue,
    }),
    loopClosureFailure: useFlag('loop_closure_metric_threshold', {
      clientName,
      defaultValue,
    }),
    viewAngles: useFlag('view_angles_metric_threshold', {
      clientName,
      defaultValue,
    }),
    levelness: useFlag('levelness_metric_threshold', {
      clientName,
      defaultValue,
    }),
  };

  useEffect(() => {
    let mounted = true; // Prevents memory leakage when unmounted
    const initialize = async () => {
      const copyOfQualityMetricsResults = { ...qualityMetricsResults };
      // Get the quality metrics response
      // We have to POST to AWS to retrieve the correct URL
      const metricsUrl = request
        .get(ApiConfig.GET_MOBILE_CAPTURE(activeSpaceID))
        .then((response) => {
          const dataId = response.data.id;
          const url = ApiConfig.PRESIGNED_DOWNLOAD_URL();
          const s3key = `capture/${dataId}/csd/quality_metrics.txt`;
          return request
            .post(url, JSON.stringify({ s3keys: [s3key] }))
            .then((response) => response.data[s3key])
            .catch((error) => bugsnagError(error));
        })
        .catch((error) => bugsnagError(error));

      // Lets retrieve the metrics data if available
      const qualityMetricsData = await Promise.resolve(metricsUrl)
        .then((response) =>
          fetch(response)
            .then((response) =>
              response.text().then((text) => JSON.parse(text))
            )
            .catch((error) => bugsnagError(error))
        )
        .catch((error) => bugsnagError(error));

      if (!qualityMetricsData) {
        setHasMetricsTextFile(false);
        return;
      }

      // Necessary check since this value is not yet available from the response in AWS.
      // See Doc here: https://docs.google.com/document/d/1ecz3jORv9c9E9Gc9q59EoE9tTCEywVtXwElnMIX_SIA/edit
      if (!qualityMetricsData.loopClosureFailure) {
        qualityMetricsData.loopClosureFailure = 0;
      }

      // This sets the pass bool for each metric result,
      // so that the UI can show the correct state
      for (const key in thresholdValues) {
        const thresholdValue = thresholdValues[key];
        const qualityMetric = qualityMetricsData[key];

        // Camera direction is a special case that must check tripod, outward, and inward.
        // One failure equals an automatic failure for this check
        const shouldCameraDirectionPass =
          qualityMetricsData.tripod <= thresholdValues.tripod &&
          qualityMetricsData.outward <= thresholdValues.outward &&
          qualityMetricsData.inward <= thresholdValues.inward;

        if (key === 'loopClosureFailure') {
          copyOfQualityMetricsResults.trajectory.passed =
            qualityMetric <= thresholdValue;
        }
        if (key === 'levelness') {
          copyOfQualityMetricsResults.cameraLevel.passed =
            qualityMetric <= thresholdValue;
        }
        if (key === 'erraticness') {
          copyOfQualityMetricsResults.erraticPath.passed =
            qualityMetric <= thresholdValue;
        }
        if (key === 'viewAngles') {
          copyOfQualityMetricsResults.spaceCoverage.passed =
            qualityMetric <= thresholdValue;
        }

        copyOfQualityMetricsResults.cameraDirection.passed = shouldCameraDirectionPass;
      }
      // Check if component is mounted
      if (mounted) {
        setQualityMetricsResults(copyOfQualityMetricsResults);
      }
    };
    initialize();
    return () => (mounted = false);
  }, [activeSpaceID]); // eslint-disable-line

  const isRecommended = Object.keys(qualityMetricsResults).every(
    (key) => qualityMetricsResults[key].passed
  );
  const finalRecommendation = isRecommended ? 'ACCEPT' : 'REJECT';

  return {
    currentState: {
      finalRecommendation,
      hasMetricsTextFile,
      isRecommended,
      qualityMetricsResults,
    },
  };
}

const defaultValue = 0.5;
const clientName = 'ios';

// Initial Quality Checking state
const initialState = {
  trajectory: { label: 'Trajectory', passed: true },
  cameraDirection: { label: 'Camera Direction', passed: true },
  cameraLevel: { label: 'Camera Level', passed: true },
  erraticPath: { label: 'Smooth Path', passed: true },
  spaceCoverage: { label: 'Space Coverage', passed: true },
};
