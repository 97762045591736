import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import get from 'lodash/get';
import DesignWrapperContainer from './DesignWrapperContainer';
import LoadingIcon from '../components/LoadingIcon';

const DesignsContainer = ({ renders, designs }) => {
  const {
    designsData = null,
    designError = null,
    designsAreFetching = false
  } = designs;

  const {
    rendersAreFetching = false,
    rendersError = null,
    rendersData = null
  } = renders;

  // handle loading and errors
  if (rendersError || designError) {
    return (
      <Alert bsStyle="danger">
        <strong>Oops!</strong> Something went wrong fetching the designs
        information.
      </Alert>
    );
  } else if (rendersAreFetching || !rendersData || designsAreFetching) {
    return <LoadingIcon />;
  } else if (!designsData) {
    return (
      <Alert bsStyle="warning">
        <strong>Hmm...</strong> This space has no active designs.
      </Alert>
    );
  }
  return <DesignWrapperContainer />;
};

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;

  return {
    renders: {
      rendersAreFetching: get(
        state,
        `space_data[${activeSpaceID}].renders.isFetching`,
      ),
      rendersLastUpdated: get(
        state,
        `space_data[${activeSpaceID}].renders.lastUpdated`,
      ),
      rendersData: get(
        state,
        `space_data[${activeSpaceID}].renders.data.results`,
      ),
      rendersError: get(state, `space_data[${activeSpaceID}].renders.error`),
    },
    designs: {
      designsAreFetching: get(
        state,
        `space_data[${activeSpaceID}].designsV2.isFetching`,
      ),
      designsLastUpdated: get(
        state,
        `space_data[${activeSpaceID}].designs.lastUpdated`,
      ),
      designsData: get(
        state,
        `space_data[${activeSpaceID}].designsV2.data.results`,
      ),
      designsError: get(state, `space_data[${activeSpaceID}].designs.error`),
    },
    activeSpaceID,
  };
};

export default connect(mapStateToProps)(DesignsContainer);
