import Utils from '../../../../utils';
import { patchData } from '../../../../actions/api-patch-request-generator';
import { ApiConfig } from '../../../../config';
import {
  BUDGET_CHOICES_DEFAULT,
  BUDGET_FORMAT_TRANSITION_DATE
} from '../../../../constants';

export const getBudgetAdherenceCopy = ({
  showDoNotExceed = false,
  stickToBudgetMessage = '',
  doNotExceed = 0,
}) => {
  if (showDoNotExceed) {
    return ` ${stickToBudgetMessage} ${doNotExceed.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
  }
  return ` ${stickToBudgetMessage}`;
};

export const hasOldBudgetFormat = ({
  createdAt = ''
}) => {
  const createdDate = new Date(createdAt);
  const transitionDate = new Date(BUDGET_FORMAT_TRANSITION_DATE);

  if (Utils.isValidDate(createdDate) && Utils.isValidDate(transitionDate)) {
    return createdDate < transitionDate;
  }
  return false;
};

export const formatBudgetData = ({
  value = BUDGET_CHOICES_DEFAULT
}) => {
  switch (value) {
    case BUDGET_CHOICES_DEFAULT:
      return {
        budget_min: -1,
        budget_max: 0
      };
    default:
      return {
        budget_min: 0,
        budget_max: value
      };
  }
};

export const setSpaceInfoBudget = ({
  styleboardInfoId = 0
}) => (value) => {
  if (styleboardInfoId) {
    return [patchData(`${ApiConfig.SPACE_INFO}/${styleboardInfoId}?heidi=true`, 'space_info_change', {
      data: formatBudgetData({ value }),
    })]
  }
  return [];
};
