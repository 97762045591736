import React, { useState } from 'react';

import {
  GENOME_HEADERS,
  GRID_COLUMN_COUNT,
  PRODUCTS_DEFAULT_STR,
  QUIZ_SOURCES,
} from './constants';
import { SecondaryCta } from '../../../../sharedComponents/button';

import QuizProductsModal from '../../../../components/QuizProductsModal';
import GenomeModal from '../../../../components/GenomeModal';
import AddGenome from './AddGenome';
import Utils from '../../../../utils';
import { ADD_GENOME_STR } from './constants';

import {
  ButtonsContainer,
  ContainerGrid,
  GridItem,
  Header,
  MainContainer,
} from './styles';
import QuizResult from './QuizResult';

/** Transforms the returned quiz results by adding
 * a version number to the object
 */
const transformQuizResults = (userQuizData) => {
  const quizResults = userQuizData.results;
  return quizResults.map((key) => {
    let result = Object.assign({}, key);
    result.version = userQuizData.version;
    return result;
  });
};

const getCreatedByStr = (key) => {
  const NoneStr = 'None';
  if (!key || (key && (key.source === NoneStr || !key.source))) {
    return NoneStr;
  }
  const source = key.source;
  const assignedBySourceValue = '3';
  const authorRole = key.author_role;
  const createdBy = key.created_by || 'Unknown';
  const hasAuthor = authorRole && authorRole !== '';
  const hasCreatedBy = createdBy && createdBy !== '';

  let finalStr = QUIZ_SOURCES[source];

  if (source === assignedBySourceValue) {
    switch (true) {
      case hasAuthor && hasCreatedBy:
        finalStr += ` by ${authorRole.toUpperCase()} ${createdBy}`;
        break;
      case hasCreatedBy:
        finalStr += ` by ${createdBy}`;
        break;
      default:
        break;
    }
  }

  return finalStr;
};

export default function QuizResults({
  activeSpaceID = 0,
  genomeModalProps = {},
  handleSubmitGenome = () => {},
  openModal = () => {},
  productsModalProps = {},
  userQuizData = [],
}) {
  const hasUserQuizData = userQuizData && userQuizData.length > 0;
  const hasProductModalData =
    productsModalProps &&
    productsModalProps.data &&
    productsModalProps.data.length > 0;
  const [shouldShowAddGenomeModal, setShouldShowAddGenomeModal] = useState(
    false
  );
  const [genomeId, setGenomeID] = useState(null);

  let formattedUserQuizData = [];

  if (hasUserQuizData) {
    for (let i = userQuizData.length - 1; i >= 0; i--) {
      const quizResults = transformQuizResults(userQuizData[i]).reverse();
      formattedUserQuizData = formattedUserQuizData.concat(quizResults);
    }
  }

  const hasFormatttedUserQuizData = formattedUserQuizData.length > 0;

  // Lets show all spaces associated with the current space, as well as all global spaces which are denoted as a zero
  formattedUserQuizData =
    hasFormatttedUserQuizData &&
    formattedUserQuizData.filter(
      (key) => key.space_id === activeSpaceID || key.space_id === 0
    );

  const getFormattedDateTime = (date) => {
    if (date && date !== '') {
      return `${Utils.getFormattedDateTime(date)} PT`;
    }
    return 'Not Available';
  };

  /** Returns the formatted genome
   * for instances when said genome returns
   * a truthy value for nuanced
   */
  const getFormattedGenome = (key) => {
    const isNuanced = key && !!key.nuanced;
    let genome = key.style_combination && key.style_combination.style_result;

    if (isNuanced) {
      genome = `${genome} (Nuanced)`;
    }
    return genome;
  };

  const AssignedCollectionStr = 'Assigned Collection';
  return (
    <MainContainer>
      <AddGenome
        handleSubmitGenome={handleSubmitGenome}
        genomeId={genomeId}
        setGenomeID={setGenomeID}
        shouldShowAddGenomeModal={shouldShowAddGenomeModal}
        setShouldShowAddGenomeModal={setShouldShowAddGenomeModal}
      />
      <Header>{AssignedCollectionStr}</Header>
      <ContainerGrid gridTemplateColumns={GRID_COLUMN_COUNT}>
        {Object.keys(GENOME_HEADERS).map((key, index) => {
          const genomeHeader = GENOME_HEADERS[key];
          return (
            <GridItem key={`${key}-${index}`} isHeaderItem>
              {genomeHeader}
            </GridItem>
          );
        })}
        <React.Fragment>
          {hasFormatttedUserQuizData &&
            formattedUserQuizData.map((key, index) => {
              const genome = getFormattedGenome(key);
              const dateTaken = getFormattedDateTime(key.created_at);
              const hasSurveyProducts =
                key.survey_result && key.survey_result.survey_products;
              const surveyProducts = hasSurveyProducts
                ? key.survey_result.survey_products
                : [];

              const productsCount =
                (key.survey_result &&
                  key.survey_result.survey_products.length) ||
                PRODUCTS_DEFAULT_STR;
              const pollAllignment = key && key.poll_alignment;
              const source = getCreatedByStr(key);
              const shouldItemBeBold = index === 0;
              const isQuizVersionTwo = key && key.version === 2;

              return (
                <QuizResult
                  dateTaken={dateTaken}
                  isQuizVersionTwo={isQuizVersionTwo}
                  index={index}
                  genome={genome}
                  hasSurveyProducts={hasSurveyProducts}
                  key={`${key}-genomeV1-items-${index}`}
                  openModal={openModal}
                  pollAllignment={pollAllignment}
                  productsCount={productsCount}
                  source={source}
                  surveyProducts={surveyProducts}
                  quizData={key}
                  shouldItemBeBold={shouldItemBeBold}
                />
              );
            })}
        </React.Fragment>
      </ContainerGrid>
      {hasProductModalData && <QuizProductsModal {...productsModalProps} />}
      <GenomeModal {...genomeModalProps} />
      <ButtonsContainer>
        <SecondaryCta
          width="116px"
          buttonHeight="30px"
          onClick={() => setShouldShowAddGenomeModal(true)}
          id={`quiz-results-add-genome-button`}
        >
          {ADD_GENOME_STR}
        </SecondaryCta>
      </ButtonsContainer>
    </MainContainer>
  );
}
