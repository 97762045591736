import React from "react";
import get from "lodash/get";
import { Row, OverlayTrigger } from "react-bootstrap";
import ImageThumbnail from "../ImageThumbnail";
import { flagTooltips } from "../../tooltips";

const sx = {
  footer: {
    textAlign:'left',
    width:'250px',
    paddingRight:'0',
    paddingLeft:'0'
  }
}

const FavoritePropThumbnail = ({ index, image, open }) => {

    const { reasons, like, tags, id: imageId, name: imageName } = image || {};

  const flagClass =
    tags.Type && tags.Type.indexOf("Custom Product") > -1
      ? "flag-custom-prop"
      : "empty";

    let joinedReasons = null;

    if (reasons && Array.isArray(reasons) && reasons.length > 0) {
        joinedReasons = reasons.join(', ');
    }
  return (
    <Row>
      <OverlayTrigger placement="top" overlay={flagTooltips[flagClass]}>
        <div className={flagClass} />
      </OverlayTrigger>
      <ImageThumbnail
        isFavoriteModal={true}
        favoritesStyling={'favorites-row'}
        handleClick={() => open(index)}
        src={get(image, "images.0.filename")}
      >
        <div key="footer" className="thumbnail-footer" style={sx.footer}>
          <strong>{imageId}</strong> {imageName}
          {
            like === -1 && joinedReasons &&
            <div><strong>Reason(s):</strong> {joinedReasons}</div>
          }
        </div>
      </ImageThumbnail>
    </Row>
  );
};

export default FavoritePropThumbnail;
