import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { setCurrentDesignNumber } from '../../actions/redesign-request-modal-action';

import { DropdownStyles } from '../../constants';

export default class RedesignRoomInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.count = 0;
  }

  state = {
    studioSelectedOption : null,
    bShowRoomTypes : false,
  }

  componentDidUpdate(prevProps) {
    // reset the text color of the room info when closing the modal
    const { bShowRedesignRequestModal } = this.props;
    if(prevProps.bShowRedesignRequestModal !== bShowRedesignRequestModal && this.count !== 0) {
      this.count = 0;
    };
  }

  handleStudioChange = (studioSelectedOption) => {
    const { dispatch, fetchSelectedSpaceData } = this.props;

    dispatch(setCurrentDesignNumber(null));

    fetchSelectedSpaceData(studioSelectedOption.value);
    this.setState({ studioSelectedOption, bShowRoomTypes: false });
    this.count++;
  }

  handleShowRoomTypes = () => {
    this.setState({ bShowRoomTypes: !this.state.bShowRoomTypes })
  }

  render () {
    const { bShowRoomTypes, studioSelectedOption } = this.state;
    const { currentSpaceData } = this.props;
    return (
      <RoomInfoContainer>
        {
        !bShowRoomTypes || currentSpaceData.spaceList.length === 1 ?
        <RoomTextButton hasCursor = { currentSpaceData.spaceList.length === 1 ? false : true } onClick = { () => this.handleShowRoomTypes() } color = { this.count > 0 ? '#4978BC' : '#000' } >
          R{ currentSpaceData.spaceNumber } - { currentSpaceData.roomType }
        </RoomTextButton>
        :
        <SelectContainer>
          <Select
              value={ studioSelectedOption }
              onChange={ this.handleStudioChange }
              autoFocus = { true }
              options={currentSpaceData.spaceList}
              placeholder = {(currentSpaceData.spaceList.length > 0 && currentSpaceData.spaceList[0].label) || studioSelectedOption}
              defaultMenuIsOpen = { true }
              styles={DropdownStyles}
            />
        </SelectContainer>
        }
        <Text>
            { currentSpaceData.lastName }, { currentSpaceData.firstName } <TextSpan>({ currentSpaceData.email })</TextSpan>
        </Text>
        <Text paddingBottom = '0'>
          { currentSpaceData.packageType }
        </Text>
      </RoomInfoContainer>
    )
  }
}
const RoomInfoContainer = styled.div`
  border-radius: 4px;
  background-color: #EFF3F9;
  padding: 24px;
  margin-bottom: 24px;
`;

const SelectContainer = styled.div`
  margin-bottom: 8px;
  width: 390px;
`;

const Text = styled.div`
  font-family: graphik;
  color: #000;
  font-size: 18px;
  line-height: 18px;
  padding-bottom: ${ props => props.paddingBottom ? props.paddingBottom : '8px' };
`;

const RoomTextButton = styled.button`
  border: none;
  color: ${ props => props.color ? props.color : '#000' };
  cursor: ${ props => props.hasCursor ? 'pointer' : 'auto' };
  font-size: 18px;
  line-height: 18px;
  padding: 0 0 8px 0 !important;
  font-weight: 500 !important;
  background: none;
`;

const TextSpan = styled.span`
  color: #8B8B8B;
`;
