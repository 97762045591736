import React from 'react';
import { US_STATES } from '../studio/constants';
import EditableTextField from './EditableTextField';
import EditableDropdownField from './EditableDropdownField';

const EditableAddress = ({ obj, setAddressField }) => {
  if (!obj.editable) {
    return (
      <div>
        {obj.first_name} {obj.last_name} <br className="clear" />
        {obj.street}<br className="clear" />
        {obj.city}, {obj.state} {obj.postal} {obj.country}<br className="clear" />
        {obj.phone}
        <br className="clear" />
      </div>
    );
  }
  return (
    <div>
      <EditableAddressTextField
        field="first_name"
        obj={obj}
        setAddressField={setAddressField}
      />
      <EditableAddressTextField
        field="last_name"
        obj={obj}
        setAddressField={setAddressField}
      />
      <br className="clear" />
      <EditableAddressTextField
        field="street"
        obj={obj}
        setAddressField={setAddressField}
      />
      <br className="clear" />
      <EditableAddressTextField
        field="city"
        obj={obj}
        setAddressField={setAddressField}
      />
      <EditableAddressDropdownField
        field="state"
        obj={obj}
        setAddressField={setAddressField}
        optionChoices={US_STATES}
      />
      <EditableAddressTextField
        field="postal"
        obj={obj}
        setAddressField={setAddressField}
      />
      <EditableAddressTextField
        field="country"
        obj={obj}
        setAddressField={setAddressField}
      />
      <br className="clear" />
      <EditableAddressTextField
        field="phone"
        obj={obj}
        setAddressField={setAddressField}
      />
      <br className="clear" />
    </div>
  );
};

const EditableAddressDropdownField = ({ field, obj, setAddressField, optionChoices }) => {
  const defaultLinkValue = (
    <span className="empty">provide {field.replace('_', ' ')}</span>
  );
  return (
    <div className="editableDropdownHolder">
      <EditableDropdownField
        requestGenerator={(value) => value in optionChoices ? setAddressField(field, obj)(value) : []}
        initialValue={obj[field] || ''}
        linkValue={obj[field] || defaultLinkValue}
      >
        <option value="" disabled>
          Select a US State...
        </option>
        {
          Object.keys(optionChoices).map((key, i) => (
            <option key={i} value={key}>
              {optionChoices[key]}
            </option>
          ))
        }
      </EditableDropdownField>
      &nbsp;
    </div>
  );
};


const EditableAddressTextField = ({ field, obj, setAddressField }) => {
  return (
    <div className="editableTextHolder">
      <EditableTextField
        requestGenerator={setAddressField(field, obj)}
        identifier="dimensions" // this seems wrong, do i need my own identifier?
        initialValue={obj[field]}
        validationRegex={/(.*?)/}
        // validationMessage="Please enter a number."
      >
        <span>
          {obj[field] || (
            <span className="empty">provide {field.replace('_', ' ')}</span>
          )}
        </span>
      </EditableTextField>
      &nbsp;
    </div>
  );
};

export default EditableAddress;
