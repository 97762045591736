import React from 'react';
import get from 'lodash/get';

import Utils from '../../utils';
import {
  SPACE_ETA_STATUSES,
  ETA_UNKNOWN,
  ETA_DELAYED,
  ETA_VERY_DELAYED,
  DEFAULT_TURNAROUND_TIME_VALUE
} from '../../constants';

import TurnAroundTimeTab from './TurnAroundTimeTab';

export default function TurnAroundTimeTabController ({
  status = '',
  initialEta = '',
  delayedEta = '',
  etaEditedAt = '',
  deliveredAt = ''
} = {}) {
  const isDelayed = [ETA_DELAYED, ETA_VERY_DELAYED].includes(status);
  const eta = isDelayed ? delayedEta : initialEta;

  const formattedEta = Utils.getShortFormattedDate(eta) || DEFAULT_TURNAROUND_TIME_VALUE;
  const formattedInitialEta = Utils.getShortFormattedDate(initialEta) || DEFAULT_TURNAROUND_TIME_VALUE;
  const formattedEtaEditedAt = Utils.getShortFormattedDate(etaEditedAt);
  const formattedDeliveredAt = Utils.getShortFormattedDate(deliveredAt);
  const formattedStatus = get(SPACE_ETA_STATUSES, status, SPACE_ETA_STATUSES[ETA_UNKNOWN]);

  return (
  <TurnAroundTimeTab
    isDelayed={isDelayed}
    status={formattedStatus}
    eta={formattedEta}
    initialEta={formattedInitialEta}
    etaEditedAt={formattedEtaEditedAt}
    deliveredAt={formattedDeliveredAt}
  />
  );
}
