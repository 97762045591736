import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import get from 'lodash/get';
import PhotosAndMeasurements from '../components/PhotosAndMeasurements';
import LoadingIcon from '../components/LoadingIcon';
import useRenovationsData from './RenovationsPM/useRenovationsData';
import useFloorPaintSampleImages from './FloorPaintSampleImages/useFloorPaintSampleImages';
import { PHOTO_STATUS_REJECTED } from '../constants';

const PhotosAndMeasurementsContainer = ({
  additionalPhotosData,
  dispatch,
  isFetching,
  data,
  error,
  activeSpaceID,
  packageType,
  ppoRoomDetails,
  spaceStatus,
}) => {
  /**
   * We want to show a 'review phase' button if:
   * - all photos are rejected
   * AND
   * - Space is on Pending status
   */
  function showReviewPhaseButton() {
    const dataResults = data && data.results && data.results[0];
    const photoList =
      (dataResults && get(dataResults, 'photos.photos_list', [])) || [];
    const isSpacePending = spaceStatus === 'pending';
    const isAllPhotosRejected = photoList.every(
      (photo) => photo.status === PHOTO_STATUS_REJECTED
    );

    return isSpacePending && isAllPhotosRejected;
  }


  const { currentState } = useRenovationsData({
    activeSpaceID,
    packageType,
    ppoRoomDetails,
  });

  const { floorPaintSampleState } = useFloorPaintSampleImages({
    ...additionalPhotosData,
    activeSpaceID,
  });

  // handle loading and errors
  if (error) {
    return (
      <Alert bsStyle="danger">
        <strong>Hmmm...</strong> Something went wrong fetching the photos and
        measurements.
      </Alert>
    );
  } else if (isFetching && !data) {
    return <LoadingIcon />;
  }
  return (
    <PhotosAndMeasurements
      dimensions={data}
      dispatch={dispatch}
      activeSpaceID={activeSpaceID}
      currentState={currentState}
      floorPaintSampleState={floorPaintSampleState}
      showReviewPhaseButton={showReviewPhaseButton()}
      spaceStatus={spaceStatus}
    />
  );
};

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  return {
    additionalPhotosData:
      get(state, `space_data[${activeSpaceID}].additional_photos`, {}) || {},
    isFetching: get(
      state,
      `space_data[${activeSpaceID}].dimensions.isFetching`
    ),
    lastUpdated: get(
      state,
      `space_data[${activeSpaceID}].dimensions.lastUpdated`
    ),
    data: get(state, `space_data[${activeSpaceID}].dimensions.data`),
    error: get(state, `space_data[${activeSpaceID}].dimensions.error`),
    ppoRoomDetails: get(state, `space_data[${activeSpaceID}].ppo_room_details`),
    packageType: get(
      state,
      `space_data[${activeSpaceID}].space_infoV2.data.service_packages_sku[0]`
    ),
    activeSpaceID,
  };
};

export default connect(mapStateToProps)(PhotosAndMeasurementsContainer);
