import React from 'react';
import {
  FormGroup,
  FormControl,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import enhanceWithClickOutside from 'react-click-outside';
import PatchButtonContainer from '../containers/PatchButtonContainer';
import EditableFieldContainer from '../containers/EditableFieldContainer';
// import LoadingIcon from './LoadingIcon';

class EditableDropdownField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { validationError: null };
  }
  // handleKeyPress = (e) => {
  //   switch (e.keyCode) {
  //     case 27:
  //       this.props.handleClickOutside();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  handleClickOutside() {
    // this.props.handleClickOutside();
    this.props.handleClick();
  }

  render() {
    const validationState = this.props.error ? 'error' : null;
    return (
      <FormGroup
        className={this.props.styling || 'editable-dropdown-field'}
        validationState={validationState}
      >
        <OverlayTrigger
          trigger={['hover']}
          placement="top"
          overlay={
            <Popover
              id="error-popover"
              style={{ display: this.props.error ? 'block' : 'none' }}
            >
              <span className="rejected">{'Error updating data.'}</span>
            </Popover>
          }
        >
          <FormControl
            componentClass="select"
            // value={
            //   this.props.loading ? (
            //     <LoadingIcon size="small" />
            //   ) : (
            //     this.props.initialValue
            //   )
            // }
            type="select"
            value={this.props.value}
            onChange={this.props.handleFieldChange}
            // onKeyDown={this.handleKeyPress}
            autoFocus
            disabled={this.props.disabled}
          >
            {this.props.children}
          </FormControl>
        </OverlayTrigger>
      </FormGroup>
    );
  }
}

/**
  * enhanceWithClickOutside is an HOC that calls onClickOutside()
    whenever the user clicks outside the component
  *                        -----
  * PatchButtonContainer is an HOC that wraps a component to
  * provide patch request functionality
  *                        -----
  * EditableFieldContainer is an HOC that abstracts basic editable field needs
  * i.e. shows on click, hides on click anywhere outside, value updating, etc.
  */
export default EditableFieldContainer(
  PatchButtonContainer(enhanceWithClickOutside(EditableDropdownField)),
);
