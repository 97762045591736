import React from 'react';
import { Row, Col, Pagination } from 'react-bootstrap';

import ImageList from 'components/ImageList';
import CustomPropHeader from 'components/image-list-components/CustomPropHeader';
import ModalGenerator from 'components/image-list-components/ModalGenerator';

/**
 * ImageListContainer renders a list of images and modals.
 * 1) Add the imageListType to constants.js
 * 2) Create customized modal header/footer component and add to ModalGenerator
 * 3) Create thumbnail component and add to ThumbnailGenerator
 * 4) Render ImageListContainer with appropriate arguments
 * */

export default class ImageListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImage: false,
      zoomImageIndex: 0,
      activePage: 1,
      pageSize: 100,
    };
  }

  componentDidUpdate() {
    if (this.state.showImage && !this.props.noArrowNavigation) {
      document.onkeydown = (event) => {
        const code = event.keyCode;
        switch (code) {
          case 37:
            this.previousImage();
            event.preventDefault();
            break;
          case 39:
            this.nextImage();
            event.preventDefault();
            break;
          default:
            break;
        }
      };
    }
  }

  open = (key) => {
    this.setState({ showImage: true, zoomImageIndex: key });
  };

  close = () => {
    this.setState({ showImage: false, zoomImageIndex: 0 });
  };

  nextImage = () => {
    const images = this.props.images;
    const currIndex = this.state.zoomImageIndex;
    if (currIndex < images.length - 1) {
      this.setState({ zoomImageIndex: this.state.zoomImageIndex + 1 });
    }
  };

  previousImage = () => {
    const currIndex = this.state.zoomImageIndex;
    if (currIndex > 0) {
      this.setState({ zoomImageIndex: this.state.zoomImageIndex - 1 });
    }
  };

  handlePageSelect = (page) => {
    this.setState({ activePage: page });
  };

  render() {
    const {
      imageListType,
      images,
      isFromCustomProps,
      spaceID,
      title,
      totalNumberOfProps,
    } = this.props;

    const header = (
      <div className="separation-border">
        <h4>
          {isFromCustomProps ? (
            <CustomPropHeader
              imageListType={imageListType}
              images={images}
              nextImage={this.nextImage}
              previousImage={this.previousImage}
              showImage={this.state.showImage}
              spaceID={spaceID}
              totalNumberOfProps={totalNumberOfProps}
            />
          ) : (
            title
          )}
        </h4>
      </div>
    );

    if (!images.length) {
      return (
        <Col>
          <Row className="image-list">
            <Col>{header}</Col>
          </Row>
          <br />
        </Col>
      );
    }

    // calculate the necessary number of pages, assuming 9 pictures per page
    const numPages = Math.ceil(images.length / this.state.pageSize);
    const startIndex = (this.state.activePage - 1) * this.state.pageSize;
    const endIndex =
      startIndex + this.state.pageSize < images.length
        ? startIndex + this.state.pageSize
        : images.length;
    const paginatedImages = images.slice(startIndex, endIndex);

    // create modal for close-up images - unique for each image list
    const imageModal = (
      <ModalGenerator
        zoomImageIndex={this.state.zoomImageIndex}
        showImage={this.state.showImage}
        close={this.close}
        previousImage={this.previousImage}
        nextImage={this.nextImage}
        images={images}
        imageListType={this.props.imageListType}
      />
    );

    return (
      <Col>
        <Row className="image-list">
          <Col>
            {header}
            {imageModal}
            <ImageList
              images={paginatedImages}
              open={this.open}
              startIndex={startIndex}
              imageListType={this.props.imageListType}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <Pagination
              next
              prev
              items={numPages}
              activePage={this.state.activePage}
              onSelect={this.handlePageSelect}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}
