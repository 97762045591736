import React from "react";
import get from "lodash/get";
import { Row, OverlayTrigger } from "react-bootstrap";
import ImageThumbnail from "../ImageThumbnail";
import { flagTooltips } from "../../tooltips";

const DesignProductThumbnail = ({ index, image, open }) => {
  const flagClass =
    image.tags.Type && image.tags.Type.indexOf("Custom Product") > -1
      ? "flag-custom-prop"
      : "empty";
  return (
    <Row>
      <OverlayTrigger placement="top" overlay={flagTooltips[flagClass]}>
        <div className={flagClass} />
      </OverlayTrigger>
      <ImageThumbnail
        handleClick={() => open(index)}
        src={get(image, "images.0.filename")}
      >
        <div key="footer" className="center-text thumbnail-footer">
          <strong>{image.id}</strong> {image.name}
        </div>
      </ImageThumbnail>
    </Row>
  );
};

export default DesignProductThumbnail;
