import React from 'react';
import { connect } from 'react-redux';
import ImageModal from '../ImageModal';
import CustomPropInformation from '../CustomPropInformation';
import { CUSTOM_PROP_STATUS_MAP, CUSTOM_PROP_STATUS, CUSTOM_PROP_TYPE, CUSTOM_PROP_TYPE_MAP } from '../../constants';
import NO_UPLOADED_IMAGES from '../images/no-uploaded-images@3x.png';
import { Badge } from "react-bootstrap";

class CustomPropModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      propPhotoIndex: 0,
    };
  }

  componentDidUpdate() {
    if (this.props.showImage) {
      document.onkeydown = (event) => {
        if (document.activeElement.nodeName === 'INPUT') return;
        const code = event.keyCode;
        switch (code) {
          case 37:
            this.previousPropPhoto();
            event.preventDefault();
            break;
          case 39:
            this.nextPropPhoto();
            event.preventDefault();
            break;
          default:
            break;
        }
      };
    }
  }

  nextPropPhoto = () => {
    const zoomImage = this.props.images[this.props.zoomImageIndex];
    const photos = zoomImage.photos.filter(item => item.filename);
    const currIndex = this.state.propPhotoIndex;
    if (currIndex < photos.length - 1) {
      this.setState({ propPhotoIndex: this.state.propPhotoIndex + 1 });
    }
  };

  previousPropPhoto = () => {
    const currIndex = this.state.propPhotoIndex;
    if (currIndex > 0) {
      this.setState({ propPhotoIndex: this.state.propPhotoIndex - 1 });
    }
  };

  shouldShowAlternativesRequestDropdown = () => {
    const { images, zoomImageIndex } = this.props;
    const currentProduct = images[zoomImageIndex] || {};

    return currentProduct.type === CUSTOM_PROP_TYPE.ALTERNATIVE_PRODUCT || currentProduct.type === CUSTOM_PROP_TYPE.UNKNOWN;
  }

  
  render() {
    const {
      close,
      images,
      showImage,
      sourcesData,
      spaceCustomPropProductTypesData,
      tagCategoriesData,
      tagsData,
      zoomImageIndex,
    } = this.props;

    if (zoomImageIndex > images.length) {
      return null;
    };

    const zoomImage = images[zoomImageIndex] || {};
    const customPropStatusList = CUSTOM_PROP_STATUS_MAP[zoomImage.status] || [];
    const customPropStatusClassName = customPropStatusList[1];
    const customPropsStatusTitle = customPropStatusList[0];

    const propPhotos = (zoomImage && zoomImage.photos && zoomImage.photos.filter(item => item.filename)) || [];
    const headerText = (
      <div>
        <h5>
          {zoomImage.name}
          &nbsp;(Photo {this.state.propPhotoIndex + 1}/{propPhotos.length}):
          <span className={customPropStatusClassName}>
            &nbsp;<strong>{customPropsStatusTitle}</strong>
          </span> &nbsp;
          { zoomImage.status === CUSTOM_PROP_STATUS.APPROVED && zoomImage.type !== CUSTOM_PROP_TYPE.UNKNOWN
              ? <Badge pill> {CUSTOM_PROP_TYPE_MAP[zoomImage.type]} </Badge>
              : null
          }
        </h5>
      </div>
    );

    const footer = (
      <CustomPropInformation
        shouldShowAlternativesRequestDropdown = { this.shouldShowAlternativesRequestDropdown }
        zoomImage={zoomImage}
        sources={sourcesData}
        spaceCustomPropProductTypes={spaceCustomPropProductTypesData}
        tags={tagsData}
        tagCategories={tagCategoriesData}
        close={close}
      />
    );

    return (
      <ImageModal
        imageURL={
          propPhotos.length ? (
            propPhotos[this.state.propPhotoIndex].filename
          ) : (
            NO_UPLOADED_IMAGES
          )
        }
        showImage={showImage}
        close={close}
        previousImage={this.previousPropPhoto}
        nextImage={this.nextPropPhoto}
        styling="custom-props-modal"
      >
        <div key="header" className="custom-prop-modal-header">
          {headerText}
        </div>
        <div key="footer">{footer}</div>
      </ImageModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { data: sourcesData } = state.sources;
  const { data: tagsData } = state.tags;
  const { data: tagCategoriesData } = state.tag_categories;
  const { data: spaceCustomPropProductTypesData } = state.space_custom_prop_product_types;

  return {
    sourcesData,
    tagsData,
    tagCategoriesData,
    spaceCustomPropProductTypesData,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(CustomPropModal);
