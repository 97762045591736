import React from 'react';

const DefaultWidth = '12px';
const DefaultHeight = '12px';
const DefaultStyles = { width: DefaultWidth, height: DefaultHeight };

export const StarIcon = ({ fillInner, fillOuter }) => (
  <svg
    fill="none"
    viewBox={`0 0 15 15`}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <path
      d="M11.512 14.354a.52.52 0 00.365-.221.575.575 0 00.099-.43l-.686-4.358 3-3.105a.588.588 0 00.133-.572.564.564 0 00-.163-.255.526.526 0 00-.266-.128L9.82 4.618 7.928.668A.553.553 0 007.73.442a.52.52 0 00-.563 0 .553.553 0 00-.198.226l-1.892 3.95-4.174.667a.526.526 0 00-.266.128.564.564 0 00-.163.254.587.587 0 00.133.573l3 3.105-.686 4.358a.586.586 0 00.033.307c.038.097.1.18.182.242a.522.522 0 00.565.042l3.747-2.03 3.748 2.03a.516.516 0 00.316.06zm-.77-1.572l-3.046-1.65a.517.517 0 00-.495 0l-3.047 1.65.559-3.541a.587.587 0 00-.153-.491L2.12 6.223l3.389-.535a.524.524 0 00.235-.1.556.556 0 00.165-.203l1.54-3.21 1.538 3.21c.039.08.096.15.165.203.07.052.15.087.236.1l3.389.535-2.44 2.527a.587.587 0 00-.153.49l.558 3.542z"
      fill={fillOuter}
    />
    <path
      d="M7.373 1.002L9.11 4.59c.169.349.496.593.878.656l3.764.623-2.764 2.846a1.19 1.19 0 00-.321 1.02l.644 3.988-3.383-1.831a1.191 1.191 0 00-1.137.001l-3.359 1.83.596-3.87a1.191 1.191 0 00-.296-.981L1.003 5.865l3.748-.619c.382-.063.708-.307.877-.654l1.745-3.59z"
      fill={fillInner}
    />
  </svg>
);

export const MagnifyGlassIcon = ({ fill, fillOpacity }) => (
  <svg
    width="42"
    height="44"
    viewBox="0 0 42 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.5741 41.048L30.8301 30.304C37.0341 23.39 36.8141 12.717 30.1671 6.06705C26.8361 2.73705 22.4061 0.904053 17.6981 0.904053C12.9901 0.904053 8.56007 2.73905 5.23007 6.06705C-1.64493 12.941 -1.64193 24.13 5.23207 31.004C8.56207 34.335 12.9901 36.1671 17.6981 36.1671C21.9261 36.1671 25.9251 34.6841 29.1081 31.9751L39.8771 42.744C40.1121 42.9781 40.4191 43.095 40.7261 43.095C41.0331 43.095 41.3401 42.9781 41.5741 42.744C42.0431 42.275 42.0431 41.516 41.5741 41.047V41.048ZM28.5571 29.216C28.4511 29.272 28.3481 29.338 28.2591 29.429C28.1791 29.509 28.1181 29.601 28.0651 29.694C25.2381 32.324 21.5791 33.7681 17.7011 33.7681C13.6321 33.7681 9.80708 32.183 6.93108 29.305C0.992075 23.366 0.992076 13.7 6.92908 7.76204C9.80708 4.88604 13.6321 3.30205 17.7011 3.30205C21.7691 3.30205 25.5941 4.88604 28.4731 7.76204C34.3811 13.673 34.4071 23.2701 28.5571 29.216ZM25.2991 17.336H18.8981V10.937C18.8981 10.274 18.3611 9.73705 17.6981 9.73705C17.0351 9.73705 16.4981 10.274 16.4981 10.937V17.336H10.1001C9.43608 17.336 8.90007 17.872 8.90007 18.536C8.90007 19.199 9.43608 19.736 10.1001 19.736H16.4981V26.136C16.4981 26.8 17.0351 27.336 17.6981 27.336C18.3611 27.336 18.8981 26.8 18.8981 26.136V19.736H25.2971C25.9601 19.736 26.4971 19.199 26.4971 18.536C26.4991 17.872 25.9621 17.336 25.2991 17.336Z"
      fill={fill ? fill : 'white'}
      fillOpacity={fillOpacity ? fillOpacity : '0.9'}
    />
  </svg>
);

export const LeftCarot = ({ fill, onClick, className }) => (
  <svg
    onClick={onClick}
    className={className}
    width="52"
    height="88"
    viewBox="0 0 52 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M47.4785 77.15L42.791 82L4.00027 42L42.791 2L47.4785 6.825L13.3979 42L47.4785 77.15Z"
        fill={fill ? fill : 'white'}
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="51.4783"
        height="88"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const RightCarot = ({ fill, onClick, className }) => (
  <svg
    onClick={onClick}
    className={className}
    width="52"
    height="88"
    viewBox="0 0 52 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M4.52148 77.15L9.20898 82L47.9997 42L9.20898 2L4.52148 6.825L38.6021 42L4.52148 77.15Z"
        fill={fill ? fill : 'white'}
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0.521484"
        y="0"
        width="51.4783"
        height="88"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const CloseIcon = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4494 16.7994L12.65 10L19.4494 3.20057C20.1835 2.46648 20.1835 1.28465 19.4494 0.550566C18.7153 -0.183522 17.5335 -0.183522 16.7994 0.550566L10 7.34999L3.20057 0.550566C2.46648 -0.183522 1.28465 -0.183522 0.550566 0.550566C-0.183522 1.28465 -0.183522 2.46648 0.550566 3.20057L7.34999 10L0.550566 16.7994C-0.183522 17.5335 -0.183522 18.7153 0.550566 19.4494C1.28465 20.1835 2.46648 20.1835 3.20057 19.4494L10 12.65L16.7994 19.4494C17.5335 20.1835 18.7153 20.1835 19.4494 19.4494C20.1783 18.7153 20.1783 17.5283 19.4494 16.7994Z"
      fill={fill ? fill : 'black'}
    />
  </svg>
);

export const CircleCheckIcon = ({ fill }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15.3534"
      cy="15.282"
      r="14.25"
      stroke={fill ? fill : 'black'}
      strokeWidth="1.5"
    />
    <path
      d="M21.1695 10.0935L21.0809 10.0044L20.9923 10.0935L12.9796 18.1452L9.99217 15.1662L9.90356 15.0778L9.8153 15.1665L8.66472 16.3227L8.57669 16.4112L8.66501 16.4993L12.3043 20.1327C12.3043 20.1328 12.3044 20.1328 12.3044 20.1329C12.4823 20.3116 12.7236 20.4527 12.9799 20.4527C13.2146 20.4527 13.456 20.3097 13.6321 20.1327L22.32 11.4261L22.408 11.338L22.3201 11.2497L21.1695 10.0935Z"
      fill={fill ? fill : 'black'}
      stroke={fill ? fill : 'black'}
      strokeWidth="0.25"
    />
  </svg>
);

export const SuccessIcon = ({ style, circleFill, fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill={circleFill ? circleFill : '#067039'} />
    <path d="M13.037 5.99976L7.62142 11.2323L5.5732 9.26747L4.80078 10.0084L7.23087 12.3395C7.33935 12.4436 7.48689 12.5269 7.61708 12.5269C7.74726 12.5269 7.89046 12.4436 7.99895 12.3437L13.8008 6.74904L13.037 5.99976Z" fill={fill ? fill : 'white'} />
  </svg>
);

export const PendingIcon = ({ fill }) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="15"
      cy="15.6641"
      rx="15"
      ry="15.1244"
      fill={fill ? fill : '#EF4E22'}
    />
    <path
      d="M20.181 20.8749L14.6253 18.074L14 17.0531V6.75887H16.2695V16.3454L21.1936 18.8268L20.181 20.8749Z"
      fill="white"
    />
  </svg>
);

export const LinkIcon = ({ fill, fillOpacity }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1264 2.87996L7.45008 7.55564C7.17571 7.83065 7.17571 8.27626 7.45008 8.55127C7.72445 8.82564 8.17133 8.82628 8.44571 8.55127L13.1201 3.87751V5.44063C13.1201 5.79313 13.4064 6.08001 13.7601 6.08001C14.1126 6.08001 14.4001 5.79375 14.4001 5.44063V2.23935C14.4001 2.0631 14.3282 1.90372 14.2126 1.78748C14.097 1.67185 13.937 1.59998 13.7607 1.59998H10.5595C10.207 1.59998 9.92008 1.88623 9.92008 2.23998C9.92008 2.59247 10.2063 2.87998 10.5595 2.87998L12.1264 2.87996ZM14.4002 9.27996V6.2374V12.8049C14.4002 13.6855 13.7652 14.3999 12.9814 14.3999H3.01885C2.23511 14.3999 1.6001 13.6843 1.6001 12.8049V3.195C1.6001 2.31438 2.23511 1.59999 3.01885 1.59999H9.85069H6.72013C7.07387 1.59999 7.36013 1.88625 7.36013 2.23999C7.36013 2.59374 7.07387 2.87999 6.72013 2.87999H3.24445C3.04071 2.87999 2.88008 3.07124 2.88008 3.30687V12.6931C2.88008 12.9244 3.0432 13.12 3.24445 13.12H12.7557C12.9594 13.12 13.12 12.9287 13.12 12.6931V9.27999C13.12 8.92625 13.4063 8.63999 13.76 8.63999C14.1138 8.63999 14.4 8.92625 14.4 9.27999L14.4002 9.27996Z"
      fill={fill ? fill : 'white'}
      fillOpacity={fillOpacity ? fillOpacity : '0.6'}
    />
  </svg>
);

export const CheckmarkIcon = ({
  fill = 'black',
  stroke = 'white',
  strokeWidth = '3',
  style = DefaultStyles,
}) => (
  <svg
    style={style}
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8045 2.30871C13.6309 2.13751 13.3519 2.1375 13.1783 2.3087L5.8535 9.52959L2.94846 6.66577C2.77481 6.49458 2.49587 6.49458 2.32221 6.66576V6.66576C2.14501 6.84044 2.14501 7.12634 2.3222 7.30103L5.5358 10.4691L5.68195 10.6069C5.77828 10.6978 5.92871 10.6977 6.02502 10.6069L6.1711 10.4691L13.8045 2.94396C13.9817 2.76928 13.9817 2.4834 13.8045 2.30871V2.30871Z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export const ExclamationIcon = ({ style }) => (
  <svg
    style={style}
    width="30"
    height="30"
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0C4.03664 0 0 4.03664 0 9C0 13.9634 4.03664 18 9 18C13.9634 18 18 13.9634 18 9C18 4.03664 13.9634 0 9 0Z" fill="#E8371C" />
    <path d="M8.60449 3.85229H9.75649V11.4843H8.60449V3.85229Z" fill="white" />
    <path d="M8.60449 13.2478H9.75649V14.2558H8.60449V13.2478Z" fill="white" />
  </svg>
);

export const QuestionMarkIcon = ({
  fill = 'white',
  stroke = 'white',
  strokeWidth = '3',
  style = DefaultStyles,
}) => (
  <svg
    style={style}
    width="11"
    height="15"
    viewBox="0 0 11 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4.22528C3.86667 0.563752 9 1.86828 9 4.91218C9 7.95608 5.37336 8.07921 5.37336 8.37129V11"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
    <circle cx="5.5" cy="13.5" r="1.5" fill={fill} />
  </svg>
);

export const StopIcon = ({ fill = 'white', style = DefaultStyles }) => (
  <svg
    style={style}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6569 13.6569C10.5327 16.781 5.46734 16.781 2.34315 13.6569C-0.781049 10.5327 -0.781049 5.46734 2.34315 2.34315C5.46734 -0.781049 10.5327 -0.781049 13.6569 2.34315C16.781 5.46734 16.781 10.5327 13.6569 13.6569ZM3.10891 11.4769L11.4769 3.10891C9.13367 1.43898 5.85959 1.65513 3.75736 3.75736C1.65513 5.85958 1.43898 9.13367 3.10891 11.4769ZM12.2426 12.2426C10.1404 14.3449 6.86633 14.561 4.52313 12.8911L12.8911 4.52313C14.561 6.86633 14.3449 10.1404 12.2426 12.2426Z"
      fill={fill}
    />
  </svg>
);

export const XIcon = ({ fill = 'white', style = DefaultStyles }) => (
  <svg
    style={style}
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0041 3.32409L12.1757 0.495667L7.99978 4.67154L3.82391 0.49567L0.995483 3.3241L5.17136 7.49997L0.99554 11.6758L3.82397 14.5042L7.99978 10.3284L12.1756 14.5042L15.004 11.6758L10.8282 7.49997L15.0041 3.32409Z"
      fill={fill}
    />
  </svg>
);


export const ClockIcon = ({ style }) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="15" cy="15.1244" rx="15" ry="15.1244" fill="#EF4E22"/>
    <path d="M20.181 20.3351L14.6253 17.5341L14 16.5133V6.21899H16.2695V15.8056L21.1936 18.2869L20.181 20.3351Z" fill="white"/>
  </svg>
);
