import React from 'react';
import QualityMetrics from './components/QualityMetrics';
import { connect } from 'react-redux';
import useQualityMetrics from './useQualityMetrics';
import { useFlag } from '../../services/configcat';

function QualityMetricsController ({ activeSpaceID }) {
  
  const { currentState } = useQualityMetrics({
    activeSpaceID,
    useFlag,
  });  
  return (
    <QualityMetrics 
      { ...currentState }
    />
    
  )
}

const mapStateToProps = (state) => {
  const activeSpaceID = state.space_data.activeID;
  return {
    activeSpaceID,
  };
};

export default connect(mapStateToProps)(QualityMetricsController);
