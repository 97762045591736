import React, { useState } from 'react';
import {
  Button,
  Col,
  ControlLabel,
  FormGroup,
  Modal,
  Table,
} from 'react-bootstrap';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import { convertedBudget } from '../../../../constants';
import {
  LOW_PRICE_BAND_STRING,
  MEDIUM_PRICE_BAND_STRING,
  HIGH_PRICE_BAND_STRING,
} from '../constants';

const [LOW_SHORTCODE, MEDIUM_SHORTCODE, HIGH_SHORTCODE] = ['L', 'M', 'H'];

const getBudgetLimits = (product) => {
  return {
    good: {
      min: product.low_budget_low_limit,
      max: product.low_budget_high_limit,
      label: LOW_PRICE_BAND_STRING,
    },
    better: {
      min: product.budget_low_limit,
      max: product.budget_high_limit,
      label: MEDIUM_PRICE_BAND_STRING,
    },
    best: {
      min: product.high_budget_low_limit,
      max: product.high_budget_high_limit,
      label: HIGH_PRICE_BAND_STRING,
    },
  };
};

const budgetOptionsFor = (productCat) => {
  if (!productCat) {
    return null;
  }

  const budgetLimits = getBudgetLimits(productCat);
  let options = [];
  for (let key in budgetLimits) {
    const budgetMin = budgetLimits[key].min;
    const budgetMax = budgetLimits[key].max;
    const label = budgetLimits[key].label;

    options.push(
      <option key={key} value={label}>
        {capitalize(key)} (${budgetMin}-{budgetMax})
      </option>
    );
  }
  return options;
};

export default function IntendedPieces(props) {
  const {
    intended_pieces,
    isPPOFlowWithDefaultBudgets,
    productCategories,
    sendIntendedPieces,
    spaceList,
    styleboardInfoId,
  } = props;

  const [addingPiece, setAddingPiece] = useState(false);
  const [selectedCatIndex, selectCategoryIndex] = useState(null);
  const [selectedBudget, selectBudget] = useState();
  const [editedBudgetIndex, setEditedBudgetIndex] = useState(null);

  const handleClose = () => {
    setAddingPiece(false);
  };

  const categoryByName = (name) =>
    productCategories.find((cat) => cat.product_type === name);

  const massageExistingPieces = (intendedPieces) => {
    // the pieces we load up don't have the same format as the ones we have to send to BE
    // so we change them just before sending them out, thusly:
    const budgetsForPost = {
      [LOW_SHORTCODE]: LOW_PRICE_BAND_STRING,
      [MEDIUM_SHORTCODE]: MEDIUM_PRICE_BAND_STRING,
      [HIGH_SHORTCODE]: HIGH_PRICE_BAND_STRING,
    };

    return intendedPieces.map((pc) => {
      if (!pc.type) {
        pc.product_type = pc.categories_by_room_type;
        return pc;
      }
      return {
        product_type:
          typeof pc.type === 'number'
            ? pc.type
            : categoryByName(pc.type.trim()).id,
        budget: budgetsForPost[pc.budget],
        min_budget: pc.budget_low_limit,
        max_budget: pc.budget_high_limit,
      };
    });
  };

  const selectBudgetRange = (
    budgetStr,
    product = productCategories[selectedCatIndex]
  ) => {
    const budgetLimits = getBudgetLimits(product);
    let optsForBudgets = {};
    for (let key in budgetLimits) {
      const label = budgetLimits[key].label;
      const min = budgetLimits[key].min;
      const max = budgetLimits[key].max;
      optsForBudgets = Object.assign(optsForBudgets, { [label]: { min, max } });
    }
    const budgetForPost = {
      budget: budgetStr,
      min_budget: optsForBudgets[budgetStr].min,
      max_budget: optsForBudgets[budgetStr].max,
    };
    selectBudget(budgetForPost);
    return budgetForPost;
  };

  const budgetRangeMenu = (
    changeHandler,
    product = productCategories[selectedCatIndex]
  ) => {
    return (
      <select
        onChange={(e) => {
          changeHandler(e.target.value);
        }}
      >
        <option key="-">-</option>
        {budgetOptionsFor(product)}
      </select>
    );
  };

  const editBudgetRange = (index, product, budgetStr) => {
    const newBudgetBits = selectBudgetRange(budgetStr, product);
    intended_pieces[index] = { ...intended_pieces[index], ...newBudgetBits };
    sendIntendedPieces(
      styleboardInfoId,
      massageExistingPieces(intended_pieces)
    );
    setEditedBudgetIndex(null);
  };

  const handleAddPiece = () => {
    if (!selectedBudget || selectedCatIndex === null) {
      return false;
    }
    const productCategory = productCategories[selectedCatIndex];
    const newPieces = intended_pieces.concat([
      {
        type: productCategory.id,
        budget: selectedBudget.budget.substr(0, 1),
        budget_low_limit: selectedBudget.min_budget,
        budget_high_limit: selectedBudget.max_budget,
      },
    ]);
    sendIntendedPieces(
      styleboardInfoId,
      massageExistingPieces(newPieces),
      productCategories
    );
    setAddingPiece(false);
  };

  const addPieceModal = (productCategories) => {
    if (!productCategories) {
      return null;
    }
    return (
      <Modal show={addingPiece} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Intended Piece</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <ControlLabel>Category:</ControlLabel>{' '}
            <select onChange={(e) => selectCategoryIndex(e.target.value)}>
              <option key="-">-</option>
              {productCategories
                .map((cat, ind) => (
                  <option
                    key={`category${cat.id}-product-type-${cat.product_type}`}
                    value={ind}
                  >
                    {cat.product_type}
                  </option>
                ))
                .sort((elm1, elm2) => (elm1.key < elm2.key ? -1 : 1))}
            </select>
          </p>
          <p>
            <ControlLabel>Budget Range:</ControlLabel>{' '}
            {budgetRangeMenu(selectBudgetRange)}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          <Button bsStyle="primary" onClick={handleAddPiece}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Col xs={12}>
      {addPieceModal(productCategories)}

      <FormGroup className="how-to-use" controlId="formControlsHowToUseSpace">
        <ControlLabel>Which pieces are you looking to purchase?</ControlLabel>
        <Table responsive>
          <IntendedPiecesTableHeader
            spaceList={spaceList}
            isPPOFlowWithDefaultBudgets={isPPOFlowWithDefaultBudgets}
          />
          <tbody>
            {intended_pieces.map((piece, index) => {
              const product = productCategories.find(
                (category) => category.id === piece.categories_by_room_type
              );
              const isRangedBudget = piece.min_budget && piece.max_budget;
              let budget = '---';
              if (isRangedBudget) {
                budget = `$${piece.min_budget.toLocaleString(
                  'en-US'
                )}-${piece.max_budget.toLocaleString(
                  'en-US'
                )} (${convertedBudget(piece.budget)})`;
              } else {
                switch (piece.budget) {
                  case LOW_PRICE_BAND_STRING:
                    budget = `< $${product && product.budget_low_limit}`;
                    break;
                  case MEDIUM_PRICE_BAND_STRING:
                    budget = `$${product && product.budget_low_limit} - $${
                      product && product.budget_high_limit
                    }`;
                    break;
                  case HIGH_PRICE_BAND_STRING:
                    budget = `> $${product && product.budget_high_limit}`;
                    break;
                  default:
                    budget = piece.budget;
                }
              }

              return (
                <tr key={index}>
                  <td>{product && product.product_category}</td>
                  <td>{product && product.product_type}</td>
                  {spaceList && !isPPOFlowWithDefaultBudgets && (
                    <td>
                      {isRangedBudget ? (
                        editedBudgetIndex === index ? (
                          budgetRangeMenu(
                            (budgetStr) =>
                              editBudgetRange(index, product, budgetStr),
                            product
                          )
                        ) : (
                          <EditLink onClick={() => setEditedBudgetIndex(index)}>
                            {budget}
                          </EditLink>
                        )
                      ) : (
                        budget
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
              <tr>
                <td colSpan="3">
                  <EditLink onClick={() => setAddingPiece(true)}>
                    Add new intended piece
                  </EditLink>
                </td>
              </tr>
          </tbody>
        </Table>
      </FormGroup>
    </Col>
  );
}

export const IntendedPiecesTableHeader = ({
  hidden = false,
  isPPOFlowWithDefaultBudgets = false,
  spaceList = [],
  titleRefCategory = null,
  titleRefProduct = null,
  titleRefBudget = null,
}) => {
  return (
    <thead
      id="intended-pieces-header"
      style={{ visibility: hidden ? 'hidden' : 'unset' }}
    >
      <tr>
        <td ref={titleRefCategory}>Category</td>
        <td ref={titleRefProduct}>Product</td>
        {spaceList && !isPPOFlowWithDefaultBudgets && (
          <td ref={titleRefBudget}>Budget</td>
        )}
      </tr>
    </thead>
  );
};

const EditLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
