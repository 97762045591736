import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser';
import Header from '../../components/Header';
import { initSegment } from '../../services/segment';


export default class App extends Component {
  UNSAFE_componentWillMount() {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    }
    initSegment();
  }

  render() {
    return (
      <div>
        <Header />
        {this.props.children}
      </div>
    );
  }
}
