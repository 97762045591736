import React from 'react';
import styled from 'styled-components';

export default function CouponCode({
  couponCode = '',
  couponStatus = '',
  couponIssueDate = '',
  couponExpirationDate = '',
  shouldShowCouponMessage = false,
  timesUsed = '',
}) {
  const CouponTitle = 'Merchandise Coupons';
  const formattedCouponStatus =
    couponStatus.charAt(0).toUpperCase() + couponStatus.slice(1);

  return (
    <TextContainer>
      <Text isHeaderItem>{CouponTitle}</Text>
      {shouldShowCouponMessage ? (
        <Text>User coupons not available</Text>
      ) : (
        <React.Fragment>
          <Text>Code: {couponCode}</Text>
          <Text>Status: {formattedCouponStatus}</Text>
          <Text>Issue date: {couponIssueDate}</Text>
          <Text>Expiration date: {couponExpirationDate}</Text>
          <Text>Times used: {timesUsed}</Text>
        </React.Fragment>
      )}
    </TextContainer>
  );
}

const TextContainer = styled.div`
  margin: 8px 0 32px 8px;
`;

const Text = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: ${(p) => p.isHeaderItem && '700 !important'};
`;
