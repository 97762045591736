import { useEffect, useState } from 'react';
import $ from 'jquery';
import cookie from 'js-cookie';

import { ApiConfig } from '../../config';

export default function useMobileCaptureGif({ activeSpaceID = 0 }) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    $.ajax({
      type: 'GET',
      url: ApiConfig.GET_MOBILE_CAPTURE(activeSpaceID),
      dataType: 'json',
      xhrFields: {
        withCredentials: true,
      },
      beforeSend(xhr) {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      success: (data) => {
        const s3key = `capture/${data.id}/wlt/capture.gif`;
        $.ajax({
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          url: ApiConfig.PRESIGNED_DOWNLOAD_URL(),
          xhrFields: {
            withCredentials: true,
          },
          data: JSON.stringify({ s3keys: [s3key] }),
          beforeSend: (xhr) => {
            xhr.setRequestHeader(
              'Authorization',
              `JWT ${cookie.get('jwtToken')}`
            );
          },
          success: (data) => {
            setUrl(data[s3key]);
          },
        });
      },
    });
  }, [activeSpaceID])

  return {
    url
  }
}
