import React from 'react';
import styled from 'styled-components';
import PanelLink from '../../../../sharedComponents/PanelLink/PanelLink';
import TextareaAutosizeExtended from '../../../../sharedComponents/TextareaAutosizeExtended';

export default function StyleDescription({
  contentDisplay = {},
  handleChange = () => {},
  handleFormattingLinks = () => {},
  setContentDisplayOption = () => {},
  style_description = '',
}) {
  const HeaderStr = `Customer’s description of their style (from Your Room Style results page)`;
  return (
    <Container>
      <PanelLink
        header={HeaderStr}
        link={handleFormattingLinks('', style_description)}
        displayContent={contentDisplay.isStyleDescription}
        onClick={() => setContentDisplayOption('isStyleDescription')}
      >
        <TextareaAutosizeExtended
          minheight={'100px'}
          value={style_description}
          onChange={(e) => handleChange(e.target.value, 'style_description')}
        />
      </PanelLink>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 16px 40px 16px;
`;
