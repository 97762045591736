import React from 'react';
import styled from 'styled-components';
import { BaseGrid } from 'sharedComponents/sharedStyledComponents';
import { PrimaryCta, SecondaryCta } from 'sharedComponents/button';
import greyCloseIcon from 'images/grey_close_icon.svg';

export default function SaveModule({ hideSaveModule, saveData }) {
  return (
    <SaveContainer>
      <IconContainer>
        <Icon
          src={greyCloseIcon}
          alt="close icon"
          onClick={() => hideSaveModule()}
        />
      </IconContainer>
      <Header>Save Roombuilding info</Header>
      <Text>
        Your changes will be lost unless you save! Do not refresh this page
        until saved.
      </Text>
      <ButtonContainer>
        <SecondaryCta
          data-testid="SecondaryCta"
          onClick={() => hideSaveModule()}
        >
          Cancel
        </SecondaryCta>
        <PrimaryCta data-testid="PrimaryCta" onClick={() => saveData()}>
          Save
        </PrimaryCta>
      </ButtonContainer>
    </SaveContainer>
  );
}

const SaveContainer = styled.div`
  margin-top: 25px;
  min-height: 160px;
  background: #fcf8e3;
  border: 1px solid #faebcc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  position: relative;
`;

const Header = styled.h4``;

Header.displayName = 'Header';

const Text = styled.div`
  margin: 16px 0;
`;

Text.displayName = 'Text';

const ButtonContainer = styled(BaseGrid)`
  justify-content: flex-end;
  width: 100%;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 132px);
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Icon = styled.img`
  padding: 16px;
  cursor: pointer;
`;
