import { useEffect, useReducer, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { bugsnagError } from 'services/bugsnag';
import {
  dataRefresh,
  fetchDataIfNeeded
} from 'actions/api-data-request-generator';
import axios from 'axios';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { ApiConfig } from 'config';
import * as events from './events';

/** Used to compare current data with the previous */
function usePrevHook (data) {
  const prevDataRef = useRef();
  useEffect(() => {
    prevDataRef.current = data;
  });

  const prevData = prevDataRef.current || false;

  return prevData;
}

const initialState = {
  shouldShowRenovationChoices: false,
  shouldShowRenovationQuestion: false,
  shouldShowShapeChoice: false
};

const reducer = (state, { type, payload } = {}) => {
  switch (type) {
    case events.showRenovationChoices().type: {
      const { show } = payload;
      return { ...state, shouldShowRenovationChoices: show };
    }

    case events.showRenovationQuestion().type: {
      const { show } = payload;
      return { ...state, shouldShowRenovationQuestion: show };
    }

    case events.showShapeChoice().type: {
      const { show } = payload;
      return { ...state, shouldShowShapeChoice: show };
    }

    default:
      break;
  }
};

export default function useRenovationsData ({
  activeSpaceID = 0,
  packageType = '',
  ppoRoomDetails = {},
}) {
  const { data, needRefresh } = ppoRoomDetails;
  const [roomData, setRoomData] = useState({
    choices: {},
    currentText: '',
    currentKey: '',
    currentNode: ''
  });

  const [internalNotes, setInternalNotes] = useState({
    title: '',
    text: '',
    type: 'internal-notes'
  });

  const [state, internalDispatch] = useReducer(reducer, initialState);
  const [shapeData, setShapeData] = useState({
    choices: {},
    currentShapeNode: null,
  });

  const [shouldShowSaveModule, setShouldShowSaveModule] = useState(false);

  const dispatch = useDispatch();

  const isDataChanged = !isEqual(usePrevHook(data), data);

  const isLuxePackage = packageType.includes('LUXE');

  useEffect(() => {
    initialize();
  }, [dispatch, activeSpaceID, needRefresh, isDataChanged]); // eslint-disable-line

  /** Get the ppo room details data */
  const getPPORoomDetails = () => {
    const ppoDataCache = ['user_data', activeSpaceID, 'coupon'];

    // Load the data and store in redux
    dispatch(
      fetchDataIfNeeded(
        `${ApiConfig.PPO_DATA(activeSpaceID)}/room-details`,
        'ppo_room_details',
        {
          keys: { space: activeSpaceID },
          cache: ppoDataCache
        }
      )
    ).catch((error) => bugsnagError(error));
  };

  /** Initalizes and transforms data from the api response. */
  function initialize () {
    const { data } = ppoRoomDetails;

    const initialRoomData = { ...roomData };
    const initialShapeData = { ...shapeData };
    let initialInternalNotes = { ...internalNotes };

    getPPORoomDetails();

    // Get the roombuilding internal notes
    initialInternalNotes = Object.assign(initialInternalNotes, {
      title: get(data, 'metadata.questions[0].title', ''),
      text: get(data, 'state.questions[0].value.text', '')
    });

    // Get the renovation choices pages so that we can get the
    // questions state
    const hasPages = data && data.state && data.state.nodes.length > 0;
    let pagesQuestions = {};
    pagesQuestions = hasPages && data.state.nodes.reduce((obj, item) => {
      const hasQuestionNode = item.questions.length > 0;
      obj[hasQuestionNode ? item.questions[0].name : item.name] = item;
      return obj;
    }, {});

    formatRoomData(data, initialRoomData, pagesQuestions);
    formatShapeData(data, initialShapeData);
    setRoomData(initialRoomData);
    setShapeData(initialShapeData);
    setInternalNotes(initialInternalNotes);
  }

  /** Sets the renovation choice and currently displayed text */
  const getSelectedChoiceAndText = (key) => {
    const copyOfRoomData = { ...roomData };

    for (const otherKey in copyOfRoomData.choices) {
      if (key !== otherKey) {
        copyOfRoomData.choices[otherKey].selected = false;
      }
    }

    getUpdatedCurrentChoiceData(copyOfRoomData, key);

    setRoomData(copyOfRoomData);
    setShouldShowSaveModule(true);
  };

  const getShapeChoice = (key) => {
    const copyOfShapeData = { ...shapeData };

    for (const otherKey in copyOfShapeData.choices) {
      if (key !== otherKey) {
        copyOfShapeData.choices[otherKey].selected = false;
      }
    }
    copyOfShapeData.choices[key].selected = true;
    copyOfShapeData.currentShapeNode = key;
    setShapeData(copyOfShapeData);
    setShouldShowSaveModule(true);
  }

  const hideSaveModule = () => {
    setShouldShowSaveModule(false);
  };

  const handleChange = (event, key) => {
    const copyOfRoomData = { ...roomData };
    const copyOfInternalNotes = { ...internalNotes };

    if (key === 'internal-notes') {
      copyOfInternalNotes.text = event.target.value;
      setInternalNotes(copyOfInternalNotes);
    } else {
      copyOfRoomData.currentText = event.target.value;
      setRoomData(copyOfRoomData);
    }
    setShouldShowSaveModule(true);
  };

  /** Handles patching the data to the server */
  const sendPatchRequest = async (activeSpaceID, data) => {
    try {
      const resp = await axios.patch(`${ApiConfig.PPO_DATA(activeSpaceID)}`, {
        ...data
      });
      return resp;
    } catch (err) {
      // Handle Error Here
      bugsnagError(err);
    }
  };

  /** Handles what data to save to the server */
  const saveData = () => {
    const { currentKey, currentNode, currentText } = roomData;
    const { currentShapeNode } = shapeData;

    const data = { questions: [] };

    // Roombuilding internal notes is always shown in the DOM
    data.questions = [
      {
        name: 'room-details.room-building-internal-notes',
        value: {
          text: internalNotes.text
        }
      }
    ];

    // Save out question only if there is a key
    if (currentKey) {
      data.questions.push({
        name: 'room-details.renovations.renovations-questions',
        value: {
          choices: [
            {
              name: currentKey,
              selected: true
            }
          ]
        }
      });

      // Also only save out the question if
      // the option is not no-updates as that field
      // does not have a corresponding text-area
      if (currentKey !== 'no-updates') {
        data.questions.push({
          name: `room-details.${currentNode}.${currentKey}`,
          value: {
            text: currentText
          }
        });
      }
    }

    if (currentShapeNode) {
      // options: "shape-simple", "shape-unique", "shape-open-concept"
      // Add shape to be saved out
      data.questions.push({
        name: 'room-details.space-shape.spaceshape_questions',
        value: {
          choices: [
            {
              name: currentShapeNode,
              selected: true
            }
          ]
        }
      });
    }


    sendPatchRequest(activeSpaceID, data).then((response) => {
      dispatch(dataRefresh('ppo_room_details', { space: activeSpaceID }));
      setShouldShowSaveModule(false);
    });
  };

  const { title } = shapeData.choices[shapeData.currentShapeNode] || {};

  return {
    currentState: {
      ...state,
      currentShapeTtitle: title,
      dispatch: internalDispatch,
      getSelectedChoiceAndText,
      getShapeChoice,
      handleChange,
      hideSaveModule,
      internalNotes,
      isLuxePackage,
      packageType,
      roomData,
      saveData,
      shapeData,
      shapeCopy,
      shouldShowSaveModule
    }
  };
}

const getUpdatedCurrentChoiceData = (copyOfRoomData, key) => {
  copyOfRoomData.choices[key].selected = !copyOfRoomData.choices[key]
    .selected;
  copyOfRoomData.currentText = copyOfRoomData.choices[key].question;
  copyOfRoomData.currentKey = key;
  copyOfRoomData.currentNode = copyOfRoomData.choices[key].node;
};

/** Handles formatting the shape state and metadata
 */
const formatShapeData = (data, initialShapeData, pagesQuestions) => {
  let shapesMeta = [];
  let shapesState = [];

  shapesMeta = get(
    data,
    'metadata.nodes[1].questions[0].answer_template.choices',
    {}
  );

  shapesState = get(
    data,
    'state.nodes[1].questions[0].value.choices',
    {}
  );

  for (const key in shapesState) {
    const { name, title } = shapesMeta[key];
    const { disabled, selected } = shapesState[key];
    if (disabled) return;

    if (selected) {
      initialShapeData.currentShapeNode = name;
    }

    // Set all of the choices data
    initialShapeData.choices[name] = {
      name,
      disabled,
      selected,
      title
    };
  }
  return initialShapeData;
};

/** Handles formatting the renovation state and metadata as wells as the currently selected
 * data
 */
const formatRoomData = (data, initialRoomData, pagesQuestions) => {
  let choicesMetaData = [];
  let choicesStateData = [];

  // Get the metadata and state for each renvoation choice
  // Metadata holds the titles, subtitle
  // State holds the users response, eg an answer to a question
  choicesMetaData = get(
    data,
    'metadata.nodes[0].questions[0].answer_template.choices',
    {}
  );
  choicesStateData = get(data, 'state.nodes[0].questions[0].value.choices', {});

  // Format the data to be used in the UI
  for (const key in choicesStateData) {
    const { title, subtitle, name } = choicesMetaData[key] || [];
    const { disabled } = choicesStateData[key] || [];
    if (disabled) return;

    const selected = choicesStateData[key].selected;

    const question = get(pagesQuestions, `[${name}].questions[0].value.text`, '');
    const node = name !== 'no-updates' ? get(pagesQuestions, `[${name}].name`) : '';

    // Set the currently selected question
    // key, and node for quick access
    if (selected) {
      initialRoomData.currentText = question;
      initialRoomData.currentKey = name;
      initialRoomData.currentNode = node;
    }

    // Set all of the choices data
    initialRoomData.choices[name] = {
      node,
      question,
      selected,
      subtitle,
      title
    };
  }

  return initialRoomData;
};

const shapeCopy = 'What shape is your room?';
