import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  Container,
  QuoteSymbol,
} from '../../sharedComponents/sharedStyledComponents';

const LAYOUT_DESIGN_NOTE_BASE = (designName) =>
  `I recommend ${designName} because`;

export default function DesignStyleNote({
  anyLayoutSelected,
  backgroundColor = '#F4EEE9',
  designName = '',
  isLayout = false,
  quoteColor = '#F9B8A7',
  styleNoteData = null,
}) {
  const renderNote = styleNoteData && styleNoteData.note;

  const containerAdditionalProps = isLayout ? { style: { minHeight: 0 } } : {};

  const noteData = get(styleNoteData, 'note', '');

  const note = isLayout
    ? `${LAYOUT_DESIGN_NOTE_BASE(designName)} ${noteData}`
    : noteData;

  return (
    <Container
      isLayout={isLayout}
      backgroundColor={backgroundColor}
      {...containerAdditionalProps}
    >
      {renderNote && (
        <ContentContainer>
          <QuoteSymbol padding="8px 8px 0 16px" color={quoteColor} />
          <StyleNoteContainer dangerouslySetInnerHTML={{ __html: note }} />
        </ContentContainer>
      )}
    </Container>
  );
}

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const StyleNoteContainer = styled.div`
  padding-top: 4px;
  padding-left: 24px;
`;
