import React from 'react';
import $ from 'jquery';
import cookie from 'js-cookie';
import Dropzone from 'react-dropzone';
import { Glyphicon, ProgressBar } from 'react-bootstrap';

export class FileUploaderIcon extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({ uploading: false, now: 0 });
  }

  onDrop(files) {
    const formData = new FormData();
    formData.append('product', this.props.slug);
    formData.append('modeler', 1);
    // TODO: needs current user
    formData.append('assetfile', files[0]);
    const url = this.props.uploadUrl;
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url,
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
        this.setState({ uploading: true, now: 30 });
      },
      xhrFields: {
        withCredentials: true,
        onProgress: (event) => {
          if (event.lengthComputable) {
            this.setState({ now: event.loaded / event.total * 100 });
          }
        },
      },
      success: (data3DAsset) => {
        this.props.onAssetChange(data3DAsset);
        this.setState({ uploading: false });
      },
    });
  }

  render() {
    return (
      <div
        style={{
          width: '75px',
          height: '75px',
          fontSize: '3.0em',
          textAlign: 'center',
          color: 'black',
        }}
      >
        <Dropzone
          onDrop={this.onDrop.bind(this)}
          activeStyle={{ color: 'lime', border: 'dashed' }}
        >
          <Glyphicon glyph="cloud-upload" />
        </Dropzone>
        <div hidden={!this.state.uploading}>
          <ProgressBar active={this.state.uploading} now={this.state.now} />
        </div>
      </div>
    );
  }
}
