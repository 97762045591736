import isEmpty from 'lodash/isEmpty';
import Utils from '../../../../utils';
import { patchData } from '../../../../actions/api-patch-request-generator';
import { ApiConfig } from '../../../../config';

export const setSpaceListFieldGenerator = ({
  field = '',
  styleboardInfo = {}
} = {}) => (value) => {
  if (!isEmpty(styleboardInfo)) {
    return [patchData(
      `${ApiConfig.STYLEBOARD}/${styleboardInfo.styleboard}?heidi=true`,
      'space_list_change',
      { data: Utils.buildState(field, value) }
    )];
  }
  return [];
};
