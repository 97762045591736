import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'js-cookie';
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Alert,
} from 'react-bootstrap';
import { browserHistory } from 'react-router';
import { signInUser } from '../../actions/users';
import { auth } from '../../auth';
import './style.css';
import { identifySegment } from '../../services/segment'

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const creds = {
      username: this.state.username.trim(),
      password: this.state.password.trim(),
    };

    this.props.dispatch(signInUser(creds)).then((result) => {
      if (result.payload.data && !result.payload.data.ok) {
        this.setState({ error: result.payload.data });
        return false;
      }

      cookie.set('jwtToken', result.payload.data.results.token, {
        expires: 7,
      });
      identifySegment(result.payload.data.results.user_id, {'username': creds.username});
      auth.login(() => {
        // this is a somewhat simplistic approach, able to account for just this 1 param
        const parts = window.location.search.split('=');
        if (parts.length > 1 && parts[0] === '?forwardTo') {
          browserHistory.push(decodeURIComponent(parts[1]));
        } else {
          browserHistory.push('/');
        }
      });
    });
  };

  render() {
    let error;
    if (this.state.error) {
      error = (
        <Alert
          bsStyle="danger"
          style={{ width: '100%', textAlign: 'center' }}
          onDismiss={this.handleAlertDismiss}
        >
          {this.state.error.reason}
        </Alert>
      );
    }
    return (
      <div className="heidi-login">
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 100px',
            minHeight: '112px',
          }}
        >
          {error}
        </div>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="username" bsSize="large">
            <ControlLabel> Username </ControlLabel>

            <FormControl
              autoFocus
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>{' '}
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel> Password </ControlLabel>

            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>{' '}
          <Button
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
          >
            Login
          </Button>
        </form>
      </div>
    );
  }
}

export default connect()(Login);
