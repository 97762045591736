import React from 'react';
import styled from 'styled-components';
import { Container } from 'sharedComponents/sharedStyledComponents';
import { Rating } from 'sharedComponents/rating';
import { CUSTOMER_FEEDBACK_OPTIONS } from '../../constants';

const Text = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
`;

const RatingContainer = styled.div`
  padding-bottom: 16px;
`;

const FeedbackUnorderedList = styled.ul`
  font-size: 12px;
  padding-left: 16px;
`;

const FeedbackList = styled.li`
  padding-top: 8px;
`;

export default function DesignCustomerRating(props) {
  const { designFeedback } = props;

  return (
    <Container backgroundColor="#EFF3F9">
      <RatingContainer>
        <Rating
          height={19}
          width={19}
          rating={designFeedback && designFeedback.rating}
        />
      </RatingContainer>
      {Object.keys(CUSTOMER_FEEDBACK_OPTIONS).some((key) => {
        let feedbackValue = CUSTOMER_FEEDBACK_OPTIONS[key].value;
        return designFeedback[feedbackValue];
      }) && (
        <Text>
          {designFeedback.rating > 3
            ? 'What we did well: '
            : designFeedback.rating !== 0 && 'What went wrong: '}
        </Text>
      )}
      <FeedbackUnorderedList>
        {designFeedback &&
          Object.keys(CUSTOMER_FEEDBACK_OPTIONS).map((key, index) => {
            let feedbackLabel = CUSTOMER_FEEDBACK_OPTIONS[key].label;
            let feedbackValue = CUSTOMER_FEEDBACK_OPTIONS[key].value;
            return (
              designFeedback[feedbackValue] && (
                <FeedbackList key={index}>
                  {designFeedback[feedbackValue] && feedbackLabel}
                </FeedbackList>
              )
            );
          })}
      </FeedbackUnorderedList>
    </Container>
  );
}
