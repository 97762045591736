import { connect } from 'react-redux';
import get from 'lodash/get';

import ExternalUserLinks from 'components/ExternalUserLinks';

const mapStateToProps = (state) => ({
  userId: state.user_data.user_id,
  zendeskId: get(state.user_data, 'basic_information.data.zendesk_id', null),
});

export default connect(mapStateToProps)(ExternalUserLinks);
