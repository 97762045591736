import React from 'react';
import { Button, FormControl, Image } from 'react-bootstrap';

import {
  DELIVERY_STATUSES,
  INVENTORY_STATUSES,
  DELIVERY_REASONS,
} from '../../constants';
import { PRODUCT_SHIPPING_METHODS } from './constants';
import Utils from '../../utils';
import { buildColumn2, buildColumn3, buildOrderQuantities } from './utils';
import { useFlag } from '../../services/configcat';
import RowNameAndInput from './RowNameAndInput';
import Shipments from './OrderProcessingShipments';
import EstimatedShipHistory from './OrderProcessingEstShipHistory';
import OrderProcessingScrapingStatus from './OrderProcessingScrapingStatus';

export default function OrderProcessingDetailsOrderItem({
  dirtyFields = {},
  item = {},
  orderDetails = {},
  updateOrderItemDetails = (name, value, orderItemId) => { },
  orderAction = (action, itemId, availableAmount, suborderId) => { },
  className = '',
  orderShippingHistory = {},
  wholesaleClass = '',
  datePicker = {},
  isEligibleFreeShipping = false,
  isScrapedOrder = false
} = {}) {
  const showAddtlShipInfo = useFlag('showAddtlShippingInfo');
  const useFormattedProductImageUrl = useFlag('useFormattedProductImageUrl');

  const product = orderDetails.data.products[item.product_id];

  const SHIPPING_DETAILS_SCRAPE_RESULT_IN_PROGRESS = 'in_progress'
  let isScraping = item.shipping_details_scrape_status === SHIPPING_DETAILS_SCRAPE_RESULT_IN_PROGRESS ? true : false;

  const column2 = buildColumn2({
    deliveryReasons: DELIVERY_REASONS,
    deliveryStatuses: DELIVERY_STATUSES,
    inventoryStatuses: INVENTORY_STATUSES,
    item,
    product,
    options: PRODUCT_SHIPPING_METHODS,
  });

  const column3 = buildColumn3({
    isEligibleFreeShipping,
    item,
    orderDetails: orderDetails,
    product,
    quantities: buildOrderQuantities(30, product.minimum_order_quantity || 1),
  });

  const { wholesale_cost, wholesale_shipping_fee } = item.properties || {};

  const multipleShipmentsEnabled = useFlag('enable_multiple_shipments', {
    defaultValue: false,
  });

  const shipmentLogEnabled = useFlag('enable_shipment_log', {
    defaultValue: false,
  });

  const productImageUrl = useFormattedProductImageUrl
    ? product.product_image
    : `https://modsy.s3.amazonaws.com/${product.product_image}`;

  return (
    <div
      style={
        !item.quantity || item.quantity === '0'
          ? { opacity: 0.2, position: 'relative' }
          : { position: 'relative' }
      }
    >
      <div className="col1">
        <a href={product.url} target="_blank" rel="noopener noreferrer">
          <Image src={productImageUrl} />
        </a>
      </div>
      <div className={wholesaleClass}>
        <span className="fieldLabel">Wholesale Price</span>
        <FormControl
          onChange={(e) =>
            updateOrderItemDetails(e.target.name, e.target.value, item.id)
          }
          name="wholesale_price"
          value={item.properties.wholesale_price}
          className={dirtyFields['wholesale_price' + item.id] ? 'dirty' : null}
          disabled={isScraping}
        />

        <br />

        {wholesale_cost && parseFloat(wholesale_cost) > 0.01 ? (
          <button
            onClick={() =>
              updateOrderItemDetails('wholesale_price', wholesale_cost, item.id)
            }
            style={{ margin: '3px 0 10px 0' }}
          >
            <span role="img" aria-label="Up">
              ☝️
            </span>
            {Utils.formatMoney(wholesale_cost, 2, true)}
          </button>
        ) : null}

        <span className="fieldLabel">Wholesale Shipping Fee</span>
        <FormControl
          onChange={(e) =>
            updateOrderItemDetails(e.target.name, e.target.value, item.id)
          }
          name="wholesale_shipping_fee"
          value={wholesale_shipping_fee}
          className={
            dirtyFields['wholesale_shipping_fee' + item.id] ? 'dirty' : null
          }
          disabled={isScraping}
        />
      </div>

      {/* the second column, inside the box */}
      <div className={className}>
        <div style={{ display: 'flex', paddingLeft: 10, paddingTop: 5, }}>
          <div style={{ fontSize: '16px' }}>
            {product.name} (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                'https://www.warehouse.modsy.com/admin/warehouse/product/' +
                item.product_id +
                '/'
              }
            >
              {item.product_id}
            </a>
            )
          </div>
          {isScrapedOrder ? <OrderProcessingScrapingStatus status={item.shipping_details_scrape_status}
            message={item.scrape_error_message} /> : ""}
        </div>
        <div className="col2">
          <table>
            {column2.map((entry, index) => {
              // only show delivery_reason dropdown if delivery_status is cancelled, return nothing in all other cases
              if (
                entry.field === 'delivery_reason' &&
                item.delivery_status !== 'cancelled'
              ) {
                return null;
              }
              // Temporary way of removing tracking number input when multiple shipment feature flag is enabled
              if (
                entry.field === 'retailer_tracking_number' &&
                multipleShipmentsEnabled
              ) {
                return null;
              }
              return (
                <RowNameAndInput
                  key={index}
                  entry={entry}
                  item={item}
                  updateOrderItemDetails={updateOrderItemDetails}
                  datePicker={datePicker}
                  dirtyFields={dirtyFields}
                  disabled={isScraping}
                />
              );
            })}
          </table>
        </div>

        {/* the third column */}
        <div className="col3">
          <table>
            {column3.map((entry, index) => {
              return (
                <RowNameAndInput
                  key={index}
                  entry={entry}
                  item={item}
                  className={
                    entry.name === 'External Id' ? 'test-external-id' : ''
                  }
                  updateOrderItemDetails={updateOrderItemDetails}
                  datePicker={datePicker}
                  dirtyFields={dirtyFields}
                  disabled={isScraping}
                />
              );
            })}
            <tbody>
              {orderDetails.after_capture &&
                item.properties.refundable_amount === 0 &&
                item.quantity > 0 ? (
                <tr>
                  <td colSpan="2">
                    <div className="refund-info">Fully Refunded</div>
                  </td>
                </tr>
              ) : null}

              {orderDetails.after_capture &&
                item.properties.refundable_amount > 0 ? (
                <tr>
                  <td className="right">
                    <Button
                      className="action-button refund_item"
                      onClick={() =>
                        orderAction(
                          'refund',
                          item.id,
                          item.properties.refundable_amount,
                          item.suborder_id
                        )
                      }
                    >
                      Refund Item
                    </Button>
                  </td>
                  <td>
                    {Utils.formatMoney(item.properties.refundable_amount, 2)}{' '}
                    <span className="fieldLabel">available</span>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>

        <br className="clear" />
        {shipmentLogEnabled && (
          <EstimatedShipHistory
            item={item}
            orderShippingHistory={orderShippingHistory}
          />
        )}

        {multipleShipmentsEnabled && (
          <Shipments
            updateOrderItemDetails={updateOrderItemDetails}
            item={item}
            dirtyFields={dirtyFields}
            disabled={isScraping}
          />
        )}

        {showAddtlShipInfo && (
          <div className="postCols">
            <header className="right fieldLabel">
              Additional Shipment/ Delivery Info
            </header>
            <FormControl
              componentClass="textarea"
              rows={3}
              onChange={(e) =>
                updateOrderItemDetails(e.target.name, e.target.value, item.id)
              }
              name="addtl_shipment_info"
              value={item.addtl_shipment_info || ''}
              className={
                dirtyFields['addtl_shipment_info' + item.id] ? 'dirty' : null
              }
              disabled={isScraping}
            />
          </div>
        )}

        <br className="clear" />
      </div>
      <br className="clear" />
    </div>
  );
}
