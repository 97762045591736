import styled from 'styled-components';

const RadioButton = styled.button`
  cursor: pointer;
  background: #ffffff;

  box-sizing: border-box;
  border: ${(props) =>
    props.selected ? '2px solid #41538D' : '1px solid #CCCCCC'};
  box-shadow: ${(props) =>
    props.selected &&
    '0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.14)'};
  border-radius: 4px;

  font-size: 14px;
  line-height: 16px;

  &:first-child {
    margin-right: 8px;
  }

  width: 50%;
  padding: 14px 0;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(182, 201, 228, 0.24),
        rgba(182, 201, 228, 0.24)
      ),
      #ffffff;
  }
`;

export default RadioButton;
