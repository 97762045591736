import React from 'react';
import { connect } from 'react-redux';
import { Grid, Col, Row, Pagination, Alert } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import URLSearchParams from 'url-search-params';
import debounce from 'lodash/debounce';

import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import UserList from '../components/UserList';
import UserSearch from '../components/UserSearch';
import { ApiConfig, WAREHOUSE_API_URL } from '../config';
import Utils from '../utils';
import { USER_ORDER_BY, SPACE_STATUS_MAP } from '../constants';
import LoadingIcon from '../components/LoadingIcon';
import * as request from '../global/request';
import { PACKAGE_TYPES, OLD_PACKAGES_TYPES } from '../constants';
import { bugsnagError } from '../services/bugsnag';

class UsersContainer extends React.Component {
  constructor() {
    super();
    // get parameters from the browser URL
    this.getUsers = debounce(this.getUsers, 1000);
    const params = new URLSearchParams(window.location.search);
    this.state = {
      filterBy: {
        // gets state from URL if passed, otherwise defaults
        packageType: params.get('packageType') || '',
        spaceStatus: params.get('spaceStatus') || SPACE_STATUS_MAP.ANY,
        userType: params.get('userType') || 'all',
        active: params.get('active') || 'true',
        pendingFilter: params.get('pendingFilter') || null,
      },
      activePage: parseInt(params.get('page'), 10) || 1,
      searchTerms: params.get('searchTerms') || '',
      orderBy: params.get('orderBy') || 'user__id',
      orderDirection: params.get('orderDirection') || '-',
      activePackages: {},
      useOfConstantForPackages: false,
    };
  }

  componentDidMount() {
    this.getUsers(this.state);
    this.getAllActivePackages();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {
      activePage,
      searchTerms,
      filterBy,
      orderBy,
      orderDirection,
    } = this.state;
    if (
      nextState.activePage !== activePage ||
      nextState.searchTerms !== searchTerms ||
      nextState.filterBy !== filterBy ||
      nextState.orderBy !== orderBy ||
      nextState.orderDirection !== orderDirection
    ) {
      this.getUsers(nextState);
    }
  }

  getUsers(state) {
    const params = Object.assign({}, state, state.filterBy);
    browserHistory.push({
      pathname: window.location.pathname,
      search: `?${Utils.buildURLParams(params)}`,
    });
    const { dispatch } = this.props;
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.ADMIN_STYLEBOARD}`, 'users', {
        params: {
          page: state.activePage,
          spaceStatus:
            parseInt(state.filterBy.spaceStatus, 10) === SPACE_STATUS_MAP.ANY
              ? null
              : state.filterBy.spaceStatus,
          pendingFilter: state.filterBy.pendingFilter,
          packageType: state.filterBy.packageType,
          userType:
            state.filterBy.userType === 'all' ? null : state.filterBy.userType,
          search: state.searchTerms || null,
          orderBy: `${state.orderDirection || ''}${state.orderBy}` || null,
        },
      }),
    ).catch((error) => bugsnagError(error));
  }

  /**
   * async method to fetch all the activePackages.
   * To be able to display the package names in the dropdown filter
   * instead of manually fill up the PACKAGE_TYPES constant
   * whenever we update/create a package
   * @return [Object] activePackages - with key: the name used to filter, value: displayName
   * 
      ex: activePackages = {
        Modsy + Style Advisor: "Premium"
        Modsy Consult: "Modsy Consult"
        Modsy Design Package: "Basic"
        Modsy Plus: "Classic"
        Modsy Showroom: "Express"
        Platinum Package: "Multi-Room"
      }
   */
  getAllActivePackages = async () => {
    try {
      const response = await request.get(`${WAREHOUSE_API_URL}/api/payment/get_metadata`);
      const activePackages = {};
      // package is a reserved word in strict mode so using pack instead
      response.data.packages.forEach(pack => activePackages[pack.name] = pack.displayName);
      this.setState({ activePackages });
    } catch (error) {
      console.error('Error while fetching packages - Constant used instead');
      this.setState({ activePackages: PACKAGE_TYPES });
      this.setState({ useOfConstantForPackages: true });
    }
  };

  // handle when the user selects an option for the results to be filtered
  // filter parameter is an array of form [type, value]
  handleFilter = (filter) => {
    const type = filter[0];
    const value = filter[1];
    const valueSecond = filter[2];
    switch (type) {
      case 'space-status-filter':
        this.setState({
          filterBy: Object.assign({}, this.state.filterBy, {
            spaceStatus: value,
            activePage: 1,
            pendingFilter: null,
          }),
        });
        break;
      case 'user-type-filter':
        this.setState({
          filterBy: Object.assign({}, this.state.filterBy, {
            userType: value,
            activePage: 1,
          }),
        });
        break;
      case 'package-type-filter':
        // note that the value here is a string (ie 'Modsy Design Package'), not an id
        // this is because the values are stored as strings in the database
        this.setState({
          filterBy: Object.assign({}, this.state.filterBy, {
            packageType: value === 'All Packages' ? '' : value,
            activePage: 1,
          }),
        });
        break;
      case 'pending-filter':
        this.setState({
          filterBy: Object.assign({}, this.state.filterBy, {
            spaceStatus: valueSecond || (value === 'custom_props' ? 999 : '0'),
            pendingFilter: value,
            activePage: 1,
          }),
        });
        break;
      default:
        break;
    }
  };

  // handle clicks on the pagination
  handlePaginationSelect = (page) => {
    this.setState({ activePage: page });
  };

  updateSearchTerms = (e) => {
    this.setState({ searchTerms: e.target.value });
  };

  // handles clicking on the table header to filter
  handleHeaderClick = ({ filter = '' }) => {
    const order = USER_ORDER_BY[filter];
    if (this.state.orderBy === order) {
      this.setState({
        orderDirection: this.state.orderDirection === '-' ? null : '-',
      });
    } else {
      this.setState({ orderBy: order, orderDirection: null });
    }
  };

  render() {
    // handle loading and errors
    if (this.props.error) {
      return (
        <Alert bsStyle="danger">
          <strong>Oops!</strong>&nbsp; Something went wrong fetching the users.
          Please refresh and try again.
        </Alert>
      );
    }

    const currentActivePackageName = this.state.activePackages[this.state.filterBy.packageType];
    const currentUnactivePackageIndex = Object.values(OLD_PACKAGES_TYPES).indexOf(this.state.filterBy.packageType);
    const currentInactivePackageName = Object.values(OLD_PACKAGES_TYPES)[currentUnactivePackageIndex];

    return (
      <div className="heidi">
        <Grid className="users">
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Col xs={8} className="center-text">
              <UserSearch
                filterBy={this.state.filterBy}
                handleFilter={this.handleFilter}
                searchTerms={this.state.searchTerms}
                updateSearchTerms={this.updateSearchTerms}
                loading={this.props.isFetching || !this.props.data}
                resultCount={this.props.data && this.props.data.count}

                // for dropdown packages filter
                useOfConstantForPackages={this.state.useOfConstantForPackages}
                activePackages={this.state.activePackages}
                currentActivePackageName={currentActivePackageName}
                currentInactivePackageName={currentInactivePackageName}
              />
            </Col>
            <Col md={1}>
              {this.props.isFetching || !this.props.data ? (
                <LoadingIcon styling="no-padding" />
              ) : (
                <div>
                  <strong>{this.props.data && this.props.data.count}</strong>{' '}
                  Results
                </div>
              )}
            </Col>
            <Col xs={3} className="center-text">
              <Pagination
                prev
                next
                ellipsis
                boundaryLinks
                // the default page size is 50 (defined in api/viewsets.api)
                items={
                  this.props.data ? Math.ceil(this.props.data.count / 50) : null
                }
                maxButtons={3}
                activePage={this.state.activePage}
                onSelect={this.handlePaginationSelect}
              />
            </Col>
          </Row>
          <Row>
            <UserList
              handleHeaderClick={this.handleHeaderClick}
              userList={this.props.data ? this.props.data.results : null}
            />
          </Row>
        </Grid>
      </div>
    );
  }
}

let latestResponse = {};

const mapStateToProps = (state) => {
  const { isFetching, lastUpdated, data, error } = state.users;

  // if the current initiatedAt is earlier than the previous one seen, it's out of order
  // just return the last saved response
  if (state.users.initiatedAt < latestResponse.initiatedAt) {
    return latestResponse;
  }
  // this is a successful response, save it in the variable above for future use
  if (state.users.data && !state.users.isFetching) {
    latestResponse = state.users;
  }

  return {
    isFetching,
    lastUpdated,
    data,
    error,
  };
};

export default connect(mapStateToProps)(UsersContainer);
