import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import BasicInformation from '../components/BasicInformation';
import LoadingIcon from '../components/LoadingIcon';
import { ApiConfig } from '../config';
import { bugsnagError } from '../services/bugsnag';


class BasicInformationContainer extends React.Component {
  componentDidMount() {
    const { dispatch, userID } = this.props;
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.USERS}/${userID}`, 'basic_information'),
    ).catch((error) => bugsnagError(error));
  }

  render() {
    // handle loading and errors
    if (this.props.error) {
      return (
        <Alert bsStyle="danger">
          <strong>Oops!</strong>&nbsp; Something went wrong fetching the user
          information. Probably, the passed user ID (in the URL) is incorrect.
          Please double-check, and refresh. If issues persist, contact the
          network administrator.
        </Alert>
      );
    }

    return this.props.isFetching || !this.props.data ? (
      <LoadingIcon />
    ) : (
      <BasicInformation info={this.props.data} />
    );
  }
}

const mapStateToProps = (state) => {
  const userID = state.user_data.user_id;
  const {
    isFetching,
    lastUpdated,
    data,
    error,
  } = state.user_data.basic_information;

  return {
    isFetching,
    lastUpdated,
    data,
    error,
    userID,
  };
};

export default connect(mapStateToProps)(BasicInformationContainer);
