import React from 'react';
import { Row, Image, Modal, Button, Glyphicon } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { patchData } from '../actions/api-patch-request-generator';
import PatchButton from './PatchButton';

export default class UploadModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { files: [] };
  }

  onDrop = (acceptedFiles) => {
    const files = [
      ...this.state.files,
      ...acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))];
    this.setState({ files });
  };

  removeFile(fileIndex) {
    const { files } = this.state;
    const editedFiles = [
      ...files.slice(0, fileIndex),
      ...files.slice(fileIndex + 1),
    ];
    this.setState({ files: editedFiles });
  }

  /** This handles closing the modal and removes the files from the queue
   * after they have been added.
   */
  handleClose = () => {
    this.setState({files: []});
    return this.props.close();
  }

  render() {
    const { actionIdentifier, close, method, refreshIdentifier, show, url, isLegacy } = this.props;
    const { files } = this.state;
    const form = new FormData();
    files.forEach((file) => {
      // This incorporates the new and proper way of sending in multiple files to the server
      // But we also still need to maintain the legacy way for Custom Props and the original Photos api
      let formKey = file.name;
      if (isLegacy) formKey = 'photo';
      form.append(formKey, file);
    });;
    return (
      <Modal show={show} className="heidi" onHide={close}>
        <span className="upload-modal">
          <Modal.Header closeButton>{this.props.title}</Modal.Header>
          <Modal.Body className="center-text">
            <div className="dropzone">
              <Dropzone
                onDrop={this.onDrop}
                accept="image/*"
                className="upload-dropzone"
                activeClassName="upload-dropzone-active"
                rejectClassName="upload-dropzone-rejected"
              >
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Row id="first-row-upload">
                      <span className="upload-glyph">
                        <Glyphicon glyph="cloud-upload" />
                      </span>
                    </Row>
                    <Row>
                      <h3>
                        <strong><button>Browse files</button></strong>
                        {' '}
                        or drag them here.
                      </h3>
                    </Row>
                  </div>
                </section>
              )}
            </Dropzone>
              <Row id="preview-row">
                {files.map((file, i) => {
                  return (
                    <div className="image-preview" key={i}>
                      <Button
                        className="trash-glyph"
                        onClick={() => this.removeFile(i)}
                      >
                        <Glyphicon glyph="trash" />
                      </Button>
                      <Image src={file.preview} thumbnail />
                    </div>
                  );
                })}
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={close}>Cancel</Button>
            <PatchButton
              buttonStyle="action-button"
              patchRequests={[
                patchData(url, actionIdentifier, {
                  method: method || 'post',
                  data: form,
                }),
              ]}
              identifier={refreshIdentifier}
              next={this.handleClose}
            >
              Add Photos
            </PatchButton>
          </Modal.Footer>
        </span>
      </Modal>
    );
  }
}
