import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import ContentEditable from '../ContentEditable';
import { NoAnswerProvidedStr } from '../../constants';
import warningTriangle from '../../images/warning-triangle-yellow.svg';
/**
 * A resusable component for dynamically hiding and
 * showing data when a user clicks on a link.
 * <caption>Example usage of PanelLink.</caption>
 *
 * @component
 * @example
 * // Gets the Child component that is nested in the PanelLink
 * const children = {<ChildComponent/>}
 *
 * // Displays the panels header string
 * const header = {'Header string'}
 *
 * // Displays warning text
 * const warning = {'Warning string'}
 *
 * // Displays a string value underneath the header that is clickable
 * const link = {'Pops of color'}
 *
 * // Returns a bool to show/hide content
 * const displayContent = {true}
 *
 * // Takes in a function to handle showing/hiding content
 * // Note only show is currently implemented, but can be expanded to hide
 * const onClick = () => {this.handleContentsDisplay()}
 */
export default function PanelLink(props) {
  const { children, header, warning, link, displayContent, onClick } = props;
  return (
    <>
      <Col>
        <Label>{header}</Label>
        {warning && warning.length !== '' && <Warning ><img src={warningTriangle} alt='Warning triangle' />{warning}</Warning>}
      </Col>
      <LinkText display={displayContent ? 'none' : 'block'} onClick={onClick}>
        <ContentEditableExtended
          islinktype="true"
          // Since we add zero width spaces, need to trim them to proerly display if value is empty
          sanitizedtext={(link && link.trim()) || NoAnswerProvidedStr}
          disabled={true}
        />
      </LinkText>
      <Children display={displayContent ? 'block' : 'none'}>
        {children}
      </Children>
    </>
  );
}

const Label = styled.label`
  font-weight: 700 !important;
`;

const LinkText = styled.a`
  display: ${(p) => p.display};
  cursor: pointer;

  &:visited,
  &:link,
  &:active {
    color: #4978bc;
  }
`;

const ContentEditableExtended = styled(ContentEditable)`
  &:hover {
    text-decoration: underline;
  }
`;

const Children = styled.div`
  display: ${(p) => p.display};
`;

const Warning = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

LinkText.displayName = 'LinkText';
Label.displayName = 'Label';
Warning.displayName = 'Warning';
Children.displayName = 'Children';
