import React from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Glyphicon,
  OverlayTrigger,
  Pagination,
  Nav,
  NavItem,
} from 'react-bootstrap';
import ProductsMasonry from './ProductsMasonry';
import { QUIZ_PRODUCTS_FILTER, QUIZ_PRODUCTS_COUNT } from '../constants';
import { quizProductsModalInfo } from '../tooltips';

const QuizProductsModal = ({
  show,
  activePage,
  data,
  userAction,
  close,
  handleActionChange,
  handlePageSelect,
}) => {
  // stop execution if no data
  if (!data) return null;

  // initialize liked and unliked arrays
  const likedProducts = [];
  const dislikedProducts = [];

  data.forEach((image) => {
    if (image.positive_choice) {
      likedProducts.push(image);
    } else {
      dislikedProducts.push(image);
    }
  });

  // get selected products
  const products =
    userAction === QUIZ_PRODUCTS_FILTER.LIKED
      ? likedProducts
      : dislikedProducts;

  // calculate the necessary number of pages, assuming 100 pictures per page
  const numPages = Math.ceil(products.length / QUIZ_PRODUCTS_COUNT);

  // calculate start index in array (so, first page start index must be 0)
  const startIndex = (activePage - 1) * QUIZ_PRODUCTS_COUNT;

  // calculate end index (startIndex + 100 rows if in range)
  const endIndex =
    startIndex + QUIZ_PRODUCTS_COUNT < products.length ? startIndex + QUIZ_PRODUCTS_COUNT : products.length;

  // slice images array according to start index and end index
  const pageImages = products.slice(startIndex, endIndex);

  // generate genome modal
  return (
    <Modal show={show} onHide={close} className="heidi">
      <span className="products-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <Row>
              <Col xs={10}>Style Quiz Products</Col>
              <Col xs={1}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={quizProductsModalInfo}
                >
                  <Glyphicon className="glyph-formatting" glyph="info-sign" />
                </OverlayTrigger>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={5} className="center-text">
              <Pagination
                prev
                next
                items={numPages}
                activePage={activePage}
                onSelect={handlePageSelect}
                className="pagination-formatting"
              />
            </Col>
            <Col xs={7} className="center-text">
              <Nav
                bsStyle="pills"
                activeKey={userAction}
                onSelect={handleActionChange}
              >
                <NavItem eventKey={QUIZ_PRODUCTS_FILTER.LIKED}>
                  Liked Products
                </NavItem>
                <NavItem
                  eventKey={QUIZ_PRODUCTS_FILTER.DISLIKED}
                  className="product-action-btn"
                >
                  Disliked Products
                </NavItem>
              </Nav>
            </Col>
          </Row>
          {/* Dynamically generate product list */}
          <Row>
            <ProductsMasonry data={pageImages} />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close}>Close</Button>
        </Modal.Footer>
      </span>
    </Modal>
  );
};

export default QuizProductsModal;
