/* Custom hook for the coupon code business logic */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { bugsnagError } from '../../services/bugsnag/index';
import { fetchDataIfNeeded } from '../../actions/api-data-request-generator';
import { ApiConfig } from '../../config';

export default function useCouponCode(data) {
  const { userCoupon, userID } = data;

  const dispatch = useDispatch();

  useEffect(() => {
    const couponsCache = ['user_data', userID, 'coupon'];
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.GET_USER_COUPONS(userID)}`, 'coupon', {
        keys: { user: userID },
        cache: couponsCache,
      })
    ).catch((error) => bugsnagError(error));
  }, [dispatch, userID]);

  let hasCouponData = true;
  if (!userCoupon) {
    hasCouponData = false;
    return { hasCouponData };
  }

  let shouldShowCouponMessage = false;
  if (!userCoupon.coupon) {
    shouldShowCouponMessage = true;
    return {
      hasCouponData,
      shouldShowCouponMessage,
    };
  }

  const getCouponStatus = (userCoupon) => {
    let couponStatus;
    switch (true) {
      case userCoupon.is_valid:
        couponStatus = 'valid';
        break;
      case userCoupon.reason:
        couponStatus = '';
        break;
      default:
        couponStatus = 'none';
    }

    return couponStatus;
  };

  // Format each piece of data needed to display the coupon code to the DOM
  const couponCode = userCoupon.coupon.code ? userCoupon.coupon.code : {};

  const couponStatus = getCouponStatus(userCoupon);

  const couponIssueDate = userCoupon.coupon.created_at
    ? moment(userCoupon.coupon.created_at).format('M/DD/YYYY, h:mm:ss a')
    : 'none';
  const couponExpirationDate = userCoupon.coupon.end_date
    ? moment(userCoupon.coupon.end_date).format('M/DD/YYYY')
    : 'none';
  const timesUsed = userCoupon.coupon.times_used
    ? userCoupon.coupon.times_used
    : '0';

  return {
    couponCode,
    couponStatus,
    couponIssueDate,
    couponExpirationDate,
    hasCouponData,
    shouldShowCouponMessage,
    timesUsed,
  };
}
