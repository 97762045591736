import axios from 'axios';
import * as types from './action-types';
import qs from 'qs';

export const dataRequest = (identifier, keys) => ({
  type: types[`${identifier.toUpperCase()}_REQUEST`],
  keys,
});

export const dataSuccess = (data, identifier, keys = {}) => ({
  // format identifier for types
  type: types[`${identifier.toUpperCase()}_SUCCESS`],
  recievedAt: Date.now(),
  data,
  keys,
});

export const dataFailure = (error, identifier, keys = {}) => ({
  // format identifier for types
  type: types[`${identifier.toUpperCase()}_FAILURE`],
  error,
  keys,
});

export const dataRefresh = (identifier, keys = {}) => ({
  type: types[`${identifier.toUpperCase()}_REFRESH`],
  keys,
});

const fetchData = (url, identifier, params, data, method, keys) => (
  dispatch
) => {
  // add on the time when the request was made, use this downstream (on success) to determine out of order
  keys.initiatedAt = Date.now();

  // dispatch request
  dispatch(dataRequest(identifier, keys));

  // make API call with necessary headers (content type, XSS protection, credentials, etc)
  return axios({
    method,
    url,
    params,
    data,
    paramsSerializer: params => {
      return qs.stringify(params, {arrayFormat: "repeat"})
    },
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((response) => {
      dispatch(dataSuccess(response.data, identifier, keys));
    })
    .catch((error) => {
      // breaks everything -- TODO: why?
      dispatch(dataFailure(error.toString(), identifier, keys));
      // breaks api if nothing is returned
      // return Promise.reject(new Error(error.toString()));
    });
};

// returns true if the API should fetch the required information, otherwise false
const shouldFetchData = (state, identifier, cache) => {
  if (!cache) return true;

  let dataWrapper = state;
  cache.forEach((path) => {
    if (dataWrapper) {
      dataWrapper = dataWrapper[path];
    }
  });

  // if no data has yet been loaded
  if (!dataWrapper || !dataWrapper.data) {
    return true;
  }

  // if its still in the process of loading
  if (dataWrapper.isFetching) {
    return false;
  }
  // whether the data needs to be refreshed (if changed)
  return dataWrapper.needRefresh;
};

/*
 * Used to request data from API endpoint
 * @params:
 *   url (string),
 *   identifier (string) that can be used to reference results and is used for action generation,
 *   params (object) parameters to be passed in request
 *   method (string) self explanatory... 'get', 'patch', etc.
 *   data (object) for body of request
 *   keys (object) used for caching data and later referring (i.e. spaces, designs)
 *   cache (array of strings) path to where cached data is stored (used for shouldFetchData)
 */
export const fetchDataIfNeeded = (
  url,
  identifier,
  { params = {}, data = {}, method = 'get', keys = {}, cache = null } = {}
) => (dispatch, getState) => {
  if (shouldFetchData(getState(), identifier, cache)) {
    return dispatch(fetchData(url, identifier, params, data, method, keys));
  }
  return Promise.resolve();
};
