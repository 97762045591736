
  /* 
  // Rating Comoponent
  // Reusable component for setting the number of stars for a user's space
  */
  
  import React from 'react';
  import styled from 'styled-components';
  import { StarIcon } from './icons';


  const IconContainer = styled.div`
    margin-right: 3px;
    height : ${ props => props.height };
    width: ${ props => props.width };
`;

  const Icons = styled.div`
  display: flex;
  flex-direction: row;
`;
  export const Rating = ({height, rating, width}) => {
    if(!rating) {
      rating = 0;
    }
    let stars = 5;
    let ratingArray = [];

    for(let i = 0; i < stars; i++) {
      let currIndex = i + 1;
      ratingArray.push(
        <IconContainer
          key = { 'icon' + i }
          height = { height + 'px' } 
          width = { width + 'px' }
          >
          <StarIcon 
            fillInner = { currIndex <= rating ? '#6D93C9' : 'none' } 
            fillOuter = { rating === 0 ? 'rgba(0, 0, 0, 0.15)' : '#6D93C9' } 
          />
        </IconContainer>);
    }

    return (
      <Icons>
        { ratingArray.map(value => value) }
      </Icons>
    )
  }
