import React from 'react';
import styled from 'styled-components';

import { getDelayedCopy } from './TurnAroundTimeTabReducer';

export default function TurnAroundTimeTab ({
  status = '',
  eta = '',
  initialEta = '',
  etaEditedAt = '',
  deliveredAt = '',
  isDelayed = false
} = {}) {

  const delayedCopy = getDelayedCopy({ isDelayed, initialEta, etaEditedAt });

  return (
  <TurnAroundTimeTabWrapper>
    <Header>Initial Designs ETA</Header>
    <Value>
      {eta}
    </Value>
    { isDelayed
      ? <Value>
        {delayedCopy}
      </Value>
      : null
    }
    { deliveredAt
      ? <Value>
        Delivered: {deliveredAt}
      </Value>
      : null
    }
  </TurnAroundTimeTabWrapper>
  );
}

const Header = styled.h6`
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;
Header.displayName = 'Header';

const Value = styled.div`
  font-size: 14px;
  line-height: 18px;
`;
Value.displayName = 'Value';

const TurnAroundTimeTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
