import React from 'react';
import {
  Col,
  Row,
  MenuItem,
  FormControl,
  ButtonToolbar,
  DropdownButton,
} from 'react-bootstrap';
import {
  USER_FILTERS,
  SPACE_STATUS,
  PENDING_STATUS,
  PACKAGE_TYPES,
  OLD_PACKAGES_TYPES,
} from '../constants';

const UserSearch = ({
  filterBy,
  handleFilter,
  searchTerms,
  loading,
  updateSearchTerms,
  resultCount,
  useOfConstantForPackages,
  activePackages,
  currentActivePackageName,
  currentInactivePackageName,
}) => {
  let roomStatusTitle =
    PENDING_STATUS[filterBy.pendingFilter] ||
    SPACE_STATUS[filterBy.spaceStatus];
  if (filterBy.pendingFilter === 'custom_props' && filterBy.spaceStatus === 7) {
    roomStatusTitle = 'Under Review: CP Review';
  }
  if (filterBy.pendingFilter === 'custom_props' && filterBy.spaceStatus === 9) {
    roomStatusTitle = 'In Progress: CP Review';
  }
  if (
    filterBy.pendingFilter === 'dimensions_pending' &&
    filterBy.spaceStatus === 7
  ) {
    roomStatusTitle = 'Under Review: P&M Review';
  }

  return (
    <Row className="users-header">
      <Col md={4}>
        <FormControl
          onChange={updateSearchTerms}
          name="search"
          type="text"
          value={searchTerms}
          placeholder="Enter room ID, customer name, or email"
        />
      </Col>
      <Col md={8}>
        <div style={{ float: 'left' }}>
          <ButtonToolbar>
            { useOfConstantForPackages
              ? (
                <DropdownButton
                  title={filterBy.packageType || 'All Packages'}
                  id="package-type-filter"
                  onSelect={filter => handleFilter(['package-type-filter', filter])}
                >
                  <MenuItem disabled>
                    <strong>Package Type</strong>
                  </MenuItem>
                  {Object.keys(PACKAGE_TYPES).map((id) => {
                    return (
                      <MenuItem key={id} eventKey={PACKAGE_TYPES[id]}>
                        {PACKAGE_TYPES[id]}
                      </MenuItem>
                    );
                  })}
                  <MenuItem eventKey="Essential Package">
                    Essential Package
                  </MenuItem>
                </DropdownButton>
              )
              : (
                <React.Fragment>
                  <DropdownButton
                    title={currentInactivePackageName || 'Old Packages'}
                    id="package-type-filter"
                    onSelect={filter => handleFilter(['package-type-filter', filter])}
                  >
                    <MenuItem disabled>
                      <strong>Old Packages</strong>
                    </MenuItem>
                    {Object.keys(OLD_PACKAGES_TYPES).map((id) => {
                      return (
                        <MenuItem key={id} eventKey={PACKAGE_TYPES[id]}>
                          {PACKAGE_TYPES[id]}
                        </MenuItem>
                      );
                    })}
                  </DropdownButton>

                  <DropdownButton
                    title={currentActivePackageName || 'Current Packages'}
                    id="package-type-filter"
                    onSelect={filter => handleFilter(['package-type-filter', filter])}
                  >
                    <MenuItem disabled>
                      <strong>Current Packages</strong>
                    </MenuItem>
                    <MenuItem>
                      <p style={{color: 'rgb(166, 94, 94)'}}>All Packages (Current & Old)</p>
                    </MenuItem>
                    {Object.entries(activePackages).map((modsyPackage, i) => {
                      const packageNameToFilter = modsyPackage[0];
                      const packageDisplayName = modsyPackage[1];
                      return (
                        <MenuItem key={i} eventKey={packageNameToFilter}>
                          {packageDisplayName}
                        </MenuItem>
                      );
                    })}
                  </DropdownButton>
                </React.Fragment>
              )
            }

            <DropdownButton
              title={USER_FILTERS[filterBy.userType]}
              id="user-type-filter"
              onSelect={filter => handleFilter(['user-type-filter', filter])}
            >
              <MenuItem disabled>
                <strong>User Type</strong>
              </MenuItem>
              <MenuItem divider />
              <MenuItem eventKey="all">All Users</MenuItem>
              <MenuItem eventKey="customer">Customers</MenuItem>
              <MenuItem eventKey="user__is_staff">Staff</MenuItem>
            </DropdownButton>
            <DropdownButton
              title={roomStatusTitle}
              id="space-status-filter"
              onSelect={filter => handleFilter(filter)}
            >
              <MenuItem disabled>
                <strong>Room status</strong>
              </MenuItem>
              <MenuItem divider />
              <MenuItem eventKey={['space-status-filter', '999']}>Any</MenuItem>
              <MenuItem eventKey={['pending-filter', 'custom_props', 7]}>
                Under Review: CP Review
              </MenuItem>
              <MenuItem eventKey={['pending-filter', 'custom_props', 9]}>
                In Progress: CP Review
              </MenuItem>
              <MenuItem eventKey={['pending-filter', 'custom_props']}>
                Any: CP Review
              </MenuItem>
              <MenuItem divider />
              <MenuItem eventKey={['space-status-filter', '0']}>
                Pending: Any
              </MenuItem>
              <MenuItem eventKey={['pending-filter', 'dimensions_pending']}>
                Pending: P&M Review
              </MenuItem>
              <MenuItem eventKey={['pending-filter', 'dimensions_rejected']}>
                Pending: P&M Rejected
              </MenuItem>
              <MenuItem eventKey={['pending-filter', 'dimensions_pending', 7]}>
                Under Review: P&M Review
              </MenuItem>
              <MenuItem divider />
              <MenuItem eventKey={['space-status-filter', '9']}>
                In Progress
              </MenuItem>
              <MenuItem eventKey={['space-status-filter', '1']}>Ready</MenuItem>
            </DropdownButton>
            <span className="filter-title">Filter rooms by:</span>
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );
};

export default UserSearch;
