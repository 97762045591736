import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';

const CustomCheckbox = ({ checked, styling, handleClick }) => {
  return (
    <Button
      onClick={handleClick}
      className={`input-custom-checkbox ${styling}`}
    >
      <Glyphicon glyph="ok" style={{ display: checked ? null : 'none' }} />
    </Button>
  );
};

export default CustomCheckbox;
