import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';

import { bugsnagError } from '../../services/bugsnag';

import { ApiConfig } from '../../config';

import * as request from '../../global/request';

import { SecondaryCta } from '../../sharedComponents/button';
import DesignCustomerRating from './DesignCustomerRating';
import DesignHeaderLayoutStatus from './DesignHeaderLayoutStatus';
import DesignRedesignRequest from './DesignRedesignRequest';
import DesignStyleNote from './DesignStyleNote';
import LayoutFeedback from './LayoutFeedback';
import ExpandToggle from '../../sharedComponents/ExpandToggle';
import ImageViewer from '../../sharedComponents/imageViewer';
import { Rating } from '../../sharedComponents/rating';
import Utils from '../../utils';
import RequestStatus from './RequestStatus';
import LoadingIcon from '../LoadingIcon';
import {
  device,
  DESIGN_STATUS,
  DESIGN_CHOICES,
  RENDER_DATA_TO_DISPLAY,
} from '../../constants';

const COLLAPSE_ALL_STR = 'Collapse All';
const EXPAND_ALL_STR = 'Expand All';

export default class DesignsV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setNewLayoutStatus = this.setNewLayoutStatus.bind(this);
  }

  componentDidMount() {
    // Detect when scrolled to bottom.
    if (this.designContent) {
      this.designContent.addEventListener('scroll', () => {
        if (
          this.designContent.scrollTop + this.designContent.clientHeight >=
          this.designContent.scrollHeight
        ) {
          this.props.handleLoadNewPage();
        }
      });
    }
  }

  getFooterContent = () => {
    const { designsData } = this.props;

    return Object.keys(designsData).map((key) => {
      let design = designsData[key];
      return design.renders;
    });
  };

  setNewLayoutStatus = ({ designID, selected, customerNotes = '' }) => {
    const { activeSpaceID, handleUpdateLayoutInfo } = this.props;
    // Updates the layout as selected
    request
      .post(ApiConfig.SPACE_SELECT_LAYOUT(activeSpaceID), {
        selected,
        design_pk: designID,
        customer_notes: customerNotes || '',
      })
      .then((response) => {
        // Use the response data to update the existing design data
        const responseDesigns = get(response, 'data.designs', []);
        // Map the designs into the form { id:, layoutInfo: }
        // This is used in the callback in DesignWrapperContainer
        // to update the existing design data
        const updatedData = responseDesigns
          .filter((design) => !!design.layout_info)
          .map((design) => ({
            id: get(design, 'id', -1),
            layoutInfo: get(design, 'layout_info', {}),
          }));
        handleUpdateLayoutInfo(updatedData);
      })
      .catch((error) => {
        bugsnagError(`Error Updating Layout Status:${error}`);
      });
  };

  render() {
    const {
      areAllDesignsExpanded,
      designsCount,
      designsData,
      dispatch,
      loading,
      handleAreAllDesignsExpanded,
      toggleExpand,
    } = this.props;

    const selectedLayoutName = Object.keys(designsData).reduce(
      (selectedName, key) => {
        const { layoutInfo } = designsData[key];
        return (
          selectedName ||
          (layoutInfo && layoutInfo.selected && designsData[key].designName)
        );
      },
      ''
    );

    const anyLayoutSelected = !!selectedLayoutName;

    return (
      <Containers>
        <HeaderContainer>
          <HeaderText mainText>
            {`Designs `}
            <SpanText>({designsCount})</SpanText>
          </HeaderText>
          <TextButton onClick={() => handleAreAllDesignsExpanded()}>
            {areAllDesignsExpanded ? COLLAPSE_ALL_STR : EXPAND_ALL_STR}
          </TextButton>
        </HeaderContainer>

        <ScrollLoadContainer ref={(el) => (this.designContent = el)}>
          {designsData &&
            Object.keys(designsData).map((key) => {
              let design = designsData[key];

              const {
                createdBy,
                createdWhere,
                designFeedback,
                designIndex,
                elsieURL,
                expanded,
                layoutInfo,
                designName,
                id,
                redesignRequest,
                sentOrUpdatedDate,
                styleNote,
                status: designStatus,
                renders,
                isCustomerDesign,
              } = design;

              const isLayout = !!layoutInfo;

              // design status
              const isInProgress =
                designStatus === DESIGN_STATUS.by_name.IN_PROGRESS;
              const formattedDesignStatus = get(
                DESIGN_CHOICES,
                designStatus,
                ''
              );

              // design feedback
              const shouldShowDesignFeedback =
                designFeedback &&
                Object.keys(designFeedback).length > 0 &&
                designFeedback.rating > 0;
              const designFeedbackDate =
                shouldShowDesignFeedback && designFeedback.created_at;
              const designFeedbackFormattedDate = getFormattedDate(
                designFeedbackDate
              );

              // layout feedback
              const layoutFeedback = get(layoutInfo, 'customer_notes', '');
              const layoutFeedbackDate = get(layoutInfo, 'selected_at', '');
              const layoutFeedbackFormattedDate = getFormattedDate(
                layoutFeedbackDate
              );
              const layoutSelected = get(layoutInfo, 'selected', false);

              // revision request`
              const hasRedesignRequest =
                redesignRequest && redesignRequest.length > 0;
              const redesignFeedbackDate =
                hasRedesignRequest && redesignRequest[0].created_at;
              const redesignRequestFormattedDate = getFormattedDate(
                redesignFeedbackDate
              );

              // style note
              const styleNoteFormattedDate =
                styleNote && getFormattedDate(styleNote.dateCreated);

              const renderRating = !isLayout;

              const renderRecommendedBadge = isLayout && layoutInfo.recommended;

              const renderLayoutFeedback = (isLayout && layoutFeedback) || '';

              const renderStyleNote =
                (isLayout && !anyLayoutSelected) ||
                (styleNote && styleNote.note);

              const openDesignText = isLayout
                ? `Open ${designName} in Elsie`
                : `Open Design ${designIndex} in Elsie`;

              return (
                <ContentContainer key={key} expanded={expanded}>
                  <ExpandableInfoContainer
                    onClick={() => {
                      toggleExpand(key);
                    }}
                    customerCreated={isCustomerDesign}
                  >
                    <HeaderInfoContainer>
                      <HeaderRow>
                        <HeaderText>{designName}</HeaderText>
                        {renderRating && (
                          <RatingContainer>
                            <Rating
                              height={14}
                              width={14}
                              rating={designFeedback && designFeedback.rating}
                            />
                          </RatingContainer>
                        )}
                        {renderRecommendedBadge && (
                          <RecommendedBadge>Recommended</RecommendedBadge>
                        )}
                        {isLayout && (
                          <DesignHeaderLayoutStatus
                            anyLayoutSelected={anyLayoutSelected}
                            layoutInfo={design.layoutInfo}
                          />
                        )}
                      </HeaderRow>

                      <HeaderRow>
                        {createdBy && (
                          <Text paddingRight="16px">{`${createdBy} `}</Text>
                        )}
                        <Text>
                          {`${sentOrUpdatedDate} ${
                            createdWhere === '' ? '' : '- ' + createdWhere
                          }`}
                          <SpanText padding="0 0 0 8px" color="#EF4E22">
                            {' '}
                            {formattedDesignStatus}
                          </SpanText>
                        </Text>
                      </HeaderRow>
                    </HeaderInfoContainer>

                    <RequestStatus
                      requestData={redesignRequest}
                      paddingRight="48px"
                      width="100%"
                    />

                    <ExpandToggleContainer>
                      {!isInProgress && <ExpandToggle expanded={expanded} />}
                    </ExpandToggleContainer>
                  </ExpandableInfoContainer>
                  {!isInProgress && expanded && (
                    <BodyContentContainer>
                      {/* container for the Renders only.  Can be renamed if we add more items to the left side */}
                      <Containers>
                        <ImageViewer
                          render={renders}
                          renderThumbnails={!design.layoutInfo}
                          footerData={RENDER_DATA_TO_DISPLAY}
                        />
                      </Containers>
                      {/* container for all info on the right */}
                      <Containers>
                        <ButtonContainer>
                          <Button
                            onClick={elsieURL}
                            children={openDesignText}
                          />
                        </ButtonContainer>

                        {/* Handle design feedback information */}
                        {shouldShowDesignFeedback && (
                          <Containers>
                            <Title>
                              Customer rating{' '}
                              <SpanText>{`- ${designFeedbackFormattedDate}`}</SpanText>
                            </Title>
                            <DesignCustomerRating
                              designFeedback={designFeedback}
                            />
                          </Containers>
                        )}

                        {hasRedesignRequest && (
                          <React.Fragment>
                            <Title>
                              Revision request based on this design{' '}
                              <SpanText>{`- ${redesignRequestFormattedDate}`}</SpanText>
                            </Title>

                            <DesignRedesignRequest
                              redesignRequest={redesignRequest}
                              designStatus={designStatus}
                              dispatch={dispatch}
                              designID={id}
                            />
                          </React.Fragment>
                        )}

                        {/* Handle layout feedback information */}
                        <LayoutFeedback
                          currentLayoutFeedback={renderLayoutFeedback}
                          layoutFeedbackFormattedDate={
                            layoutFeedbackFormattedDate
                          }
                          designID={id}
                          designName={designName}
                          submitLayoutFeedback={this.setNewLayoutStatus}
                          layoutSelected={layoutSelected}
                          anyLayoutSelected={anyLayoutSelected}
                          selectedLayoutName={selectedLayoutName}
                        />

                        {/* Handle style note data */}
                        {renderStyleNote && (
                          <Containers>
                            <Title>
                              Style note to customer
                              <SpanText>
                                {` - ${styleNoteFormattedDate}`}
                              </SpanText>
                            </Title>

                            <DesignStyleNote
                              anyLayoutSelected={anyLayoutSelected}
                              designName={designName}
                              isLayout={isLayout}
                              styleNoteData={styleNote}
                            />
                          </Containers>
                        )}
                      </Containers>
                    </BodyContentContainer>
                  )}
                </ContentContainer>
              );
            })}
        </ScrollLoadContainer>
        {loading && (
          <LoadingIcon
            style={{ position: 'absolute', bottom: '0', width: '100%' }}
          />
        )}
      </Containers>
    );
  }
}

const getFormattedDate = (date) => Utils.getFormattedDate(date);

const Containers = styled.div`
  position: relative;
`;
Containers.displayName = 'Containers';

const ContentContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: ${(props) => (props.expanded ? 'auto' : '66px')};
  transition: transform 0.2s ease-out;
  margin: 0 0 8px 0;
  position: relative;
`;
ContentContainer.displayName = 'ContentContainer';

const BodyContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 16px;
  grid-gap: 16px;
  height: auto;
  position: relative;
  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`;
BodyContentContainer.displayName = 'BodyContentContainer';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 16px 0;
`;
ButtonContainer.displayName = 'ButtonContainer';

/* Temp, will imlement a reusable component for buttons */
const Button = styled(SecondaryCta)`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 38px;
  background: #fff;
  border: 1px solid #41538d;
  box-sizing: border-box;
  font-weight: 500 !important;
`;
Button.displayName = 'Button';

const ExpandToggleContainer = styled.div`
  position: absolute;
  right: 32px;
`;
ExpandToggleContainer.displayName = 'ExpandToggleContainer';

const ExpandableInfoContainer = styled.div`
  height: 66px;
  display: flex;
  align-items: center;
  background: ${(props) => (props.customerCreated ? '#EFF3F9' : '#F4EEE9')};
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  font-family: 'proxima-nova', sans-serif;
  position: relative;
`;
ExpandableInfoContainer.displayName = 'ExpandableInfoContainer';

const Text = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.color ? props.color : '#000')};
  padding-right: ${(props) => props.paddingRight};
`;
Text.displayName = 'Text';

const Title = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: ${(props) => (props.date ? '200 !important' : '500 !important')};
  color: ${(props) => (props.date ? '#979797' : '#000')};
  padding-bottom: 8px;
`;
Title.displayName = 'Title';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;
HeaderContainer.displayName = 'HeaderContainer';

const HeaderInfoContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content;
`;
HeaderInfoContainer.displayName = 'HeaderInfoContainer';

const HeaderText = styled.div`
  font-size: ${(props) => (props.mainText ? '22px' : '18px')};
  margin-top: ${(props) => props.mainText && '16px'};
  padding-bottom: ${(props) => props.mainText && '16px'};
  line-height: 24px;
  font-weight: 500 !important;
  flex: ${(props) => props.mainText && '1'};
`;
HeaderText.displayName = 'HeaderText';

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`;
HeaderRow.displayName = 'HeaderRow';

const TextButton = styled.button`
  font-size: 14px;
  color: #4978bc;
  background: none;
  border: none;
`;
TextButton.displayName = 'TextButton';

const RatingContainer = styled.div`
  padding-left: 16px;
`;
RatingContainer.displayName = 'RatingContainer';

const SpanText = styled.span`
  display: ${(props) => props.bIsRequest && 'flex'};
  align-items: ${(props) => props.bIsRequest && 'center'};
  padding: ${(props) => props.padding};
  font-weight: 200 !important;
  color: ${(props) => (props.color ? props.color : '#979797')};
`;
SpanText.displayName = 'SpanText';

const ScrollLoadContainer = styled.div`
  position: relative;
  max-height: 1000px;
  min-height: 200px;
  overflow: auto;
`;
ScrollLoadContainer.displayName = 'ScrollLoadContainer';

const RecommendedBadge = styled.div`
  font-family: graphik;
  display: flex;
  padding: 3px 5px;
  margin-left: 24px;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 2px;
  color: white;
  font-weight: 500 !important; // Because '.heidi div' overwrites this value
  font-size: 10px;
  line-height: 10px;
`;
RecommendedBadge.displayName = 'RecommendedBadge';
