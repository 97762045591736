import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Alert, Button } from 'react-bootstrap';
import { ApiConfig } from '../config';
import { isNullOrUndefined } from 'util';
import { dataRefresh } from '../actions/api-data-request-generator';
import ContentEditable from 'sharedComponents/ContentEditable';

export default class AdditionalUserInfo extends React.Component {
  state = {
    additionalInfoText: '',
    isAlertVisible: false,
  };

  handleChange = (evt) => {
    this.setState({
      additionalInfoText: evt.target.value,
      isAlertVisible: true,
    });
  };

  handleAdditionalInfoNeedsToRefresh(userId, additionalInfoText) {
    const { dispatch } = this.props;
    dispatch(
      dataRefresh('about_you', {
        user: userId,
        additional_info: additionalInfoText,
      })
    );
  }

  handleSaveResults = () => {
    const { additionalInfo, data, userId } = this.props;
    const { additionalInfoText } = this.state;
    const phoneNumber = (data && data.phone) || '+10000000000';
    const isTextEnabled = (data && data.texting_enabled) || false;

    if (isNullOrUndefined(data) && isNullOrUndefined(additionalInfo)) {
      axios
        .post(`${ApiConfig.ABOUTYOU}?heidi=true`, {
          user: userId,
          additional_info: additionalInfoText,
          phone: phoneNumber,
          texting_enabled: isTextEnabled,
        })
        .then(() => {
          this.handleAdditionalInfoNeedsToRefresh(userId, additionalInfoText);
        });
    } else {
      if (additionalInfo !== additionalInfoText) {
        axios
          .patch(`${ApiConfig.ABOUTYOU}/${userId}?heidi=true`, {
            additional_info: additionalInfoText,
            phone: phoneNumber,
            texting_enabled: isTextEnabled,
          })
          .then(() => {
            this.handleAdditionalInfoNeedsToRefresh(userId, additionalInfoText);
          });
      }
    }
    this.setState({ isAlertVisible: false });
  };

  handleDismissAlert = () => {
    const { additionalInfo } = this.props;

    this.setState({
      additionalInfoText: additionalInfo,
      isAlertVisible: false,
    });
  };

  componentDidUpdate(prevProps) {
    const { additionalInfo } = this.props;

    if (additionalInfo && prevProps.additionalInfo !== additionalInfo)
      this.setState({ additionalInfoText: additionalInfo });
  }

  render() {
    const { additionalInfoText, isAlertVisible } = this.state;

    return (
      <Container>
        {isAlertVisible && (
          <Alert bsStyle="warning" onDismiss={this.handleDismissAlert}>
            <h4>Save Additional Info</h4>
            <p>
              Save Additional Info - if you don’t save or refresh changes will
              be lost!
            </p>
            <p>
              <Button onClick={this.handleDismissAlert}>Cancel</Button>
              <span> or </span>
              <Button onClick={this.handleSaveResults}>Save</Button>
            </p>
          </Alert>
        )}
        <Title>Additional info (optional)</Title>
        <ContentEditable
          html={additionalInfoText}
          onChange={this.handleChange}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  margin: 24px 0;
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 500;
`;
