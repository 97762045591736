import React from 'react';
import moment from 'moment';

import Utils from '../utils';
import LoadingIcon from '../components/LoadingIcon';
import { DAY_OF_WEEK } from '../constants';

function renderMoneySpan(s, color) {
  if (!s) return <span>-</span>;
  const val = Utils.formatMoney(s);
  if (color) {
    if (s > 0) {
      return <span style={{ color: 'green' }}>{val} </span>;
    } else if (s < 0) {
      return <span style={{ color: 'red' }}>{val} </span>;
    }
  }
  return <span>{val} </span>;
}

const OrdersReportList = ({ orders, updateSort, sort, grouping, expandRow, columnOptions, columnOrder }) => {
  if (orders.isFetching) {
    return (
      <div style={{ marginTop: '100px', textAlign: 'center' }}>
        <LoadingIcon />
      </div>
    );
  }
  if (!orders.data) {
    return <div className="message-style">uhh ohh!</div>;
  }
  if (!orders.data.rows) {
    return <div className="message-style">uhh ohh part 2!</div>;
  }
  if (orders.data.rows.length === 0) {
    return (
      <div className="message-style">
        No results for your search<br />¯\_(ツ)_/¯
      </div>
    );
  }
  columnOrder = columnOrder();

  return (
    <div style={{width: ((columnOrder.length + 2) * 100) + 'px'}}>
      <div id="headerRow">
        {// print the header row
          columnOrder.map((term, index) => {
            let name = columnOptions[term].name;
            if (sort === term) {
              name += ' ⬇';
            }
            if (sort === `${term}asc`) {
              name += ' ⬆';
            }
            return (
              <span
                key={index}
                className={`completed ${term} ${columnOptions[term].sort ? 'clickable' : ''}`}
                onClick={() => (columnOptions[term].sort ? updateSort(term) : null)}
              >
                {name}
              </span>
            );
          })}
        <br className="clear" />
      </div>

      {// print all the data rows
        orders.data.rows.map((row, index) => {
        // these values are derivative of primary metrics
          row.items_both = row.items
          if(row.items_returned){
            row.items_both += ' ('+row.items_returned+')'
          }
          row.created_at = moment(row.created_at).format('MM-DD HH:mm');
          row.net = row.subtotal - (row.credits||0) - row.discount - row.tiered_discount - row.retailer_discount;
          row.aov = row.net / row.orders || 0;

          // NOTE: These calculations should move to a domain layer and should be consolidated with the above logic
          let takeRate = 0;
          if (row.refunded_amount > 0) {

            // Break out appeasements from full refund
            const appeasements = row.refunded_amount_appeasement;
            const refundedAmount = (appeasements > 0) ? row.refunded_amount - appeasements : row.refunded_amount;
            const wholesaleRefund = row.wholesale_refund;
            const wholesaleDeduction = (wholesaleRefund > 0) ? row.wholesale_subtotal - wholesaleRefund : row.wholesale_subtotal;

            takeRate = ((row.net - refundedAmount) - wholesaleDeduction) - appeasements;
          }
          else {
            takeRate = row.net - row.wholesale_subtotal;
          }

          row.take_rate = takeRate;

          if (grouping === 'order') {
            row.grp = (
              <a href={`/order_processing?orderId=${row.grp}`} target="_blank" rel="noopener noreferrer">
                {row.grp}
              </a>
            );
          }
          if (grouping === 'day_of_week') {
            row.grp = DAY_OF_WEEK[row.grp];
          }
          return (
            <div key={index}>
              <div
                className={`dataRow ${index % 2 === 0 ? 'even' : 'odd'} ${expandRow ? 'clickable' : ''}`}
                id={row.grp_id || row.grp}
                onClick={() => (expandRow ? expandRow(row) : null)}
              >
                {// print the columns
                  columnOrder.map((term, index) => {
                    let value = row[term]
                    if (columnOptions[term].money === 'color') {
                      value = renderMoneySpan(value, true);
                    } else if (columnOptions[term].money) {
                      value = renderMoneySpan(value);
                    }
                    return (
                      <span key={index} className={`completed ${term}`}>
                        {value}
                      </span>
                    );
                  })}
                <br className="clear" />
              </div>
              <div className="dataRow" id={`inner${row.grp_id || row.grp}`} style={{ display: 'none' }} />
            </div>
          );
        })}
      <div style={{color:'#aaa', padding:'4px', fontSize: '12px'}}>{orders.data.rows.length} rows ...</div>
    </div>
  );
};

export default OrdersReportList;
