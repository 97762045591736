import * as types from './action-types';

export function setRedesignModalRequestDisplay(display) {
 return {
   type: types.SET_REDESIGN_REQUEST_MODAL_DISPLAY,
   display
 }
}

export function setCurrentDesignNumber(designID) {
  return {
    type: types.SET_CURRENT_DESIGN_NUMBER,
    designID
  }
}

export function setIsRequestEditable(isRequestEditable) {
  return {
    type: types.SET_IS_REQUEST_EDITABLE,
    isRequestEditable,
  }
}

export function setCurrentDesignsReasons(reasons) {
  return {
    type: types.SET_CURRENT_DESIGNS_REASONS,
    reasons
  }
}

export function setCurrentDesignsStyleNote(styleNote) {
  return {
    type: types.SET_CURRENT_DESIGN_STYLE_NOTE,
    styleNote
  }
}

export function setCurrentSpaceAssistanceCount(assistanceCount) {
  return {
    type: types.SET_CURRENT_SPACE_ASSISTANCE_COUNT,
    assistanceCount
  }
}

export function setRedesignRequestID(assistanceID) {
  return {
    type: types.SET_CURRENT_REDESIGN_REQUEST_ID,
    assistanceID
  }
}