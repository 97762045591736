import * as types from '../actions/action-types';
import get from 'lodash/get';

const initialState = {
  data: {},
};

const likeReasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LIKE_REASONS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getLikeReasons = (state) => get(state, 'like_reasons.data', {});

export default likeReasonsReducer;
