import React from 'react';
import { connect } from 'react-redux';

import { fetchDataIfNeeded } from '../actions/api-data-request-generator';
import { getAboutYouData, getAboutYouNeedsRefresh } from '../reducers/user-data-reducer';
import { ApiConfig } from '../config';
import { bugsnagError } from '../services/bugsnag';
import AboutYouInfo from '../components/AboutYouInfo';

class AboutYouInfoContainer extends React.PureComponent {
  componentDidMount() {
    this.handleUpdatingAboutYou();
  }

  componentDidUpdate() {
    const { aboutYouNeedsRefresh } = this.props;
    if(aboutYouNeedsRefresh === true) {
      this.handleUpdatingAboutYou();
    }
  }

  handleUpdatingAboutYou() {
    const { dispatch, userId } = this.props;

    // Handles loading the data as well as updating
    // the data if a change to the api is made
    dispatch(
      fetchDataIfNeeded(`${ApiConfig.ABOUTYOU}/${userId}`, 'about_you'),
    ).catch((error) => bugsnagError(error));
  }

  render() {
    const { data, dispatch, userId } = this.props;
    return(
      <AboutYouInfo
        data={data}
        aboutYou={data && data.about_your_space}
        dispatch={ dispatch }
        userId={ userId }
      />
    );
  }
}

const mapStateToProps = (state) => {
  const data = getAboutYouData(state);
  const userId = state.user_data.user_id;
  const aboutYouNeedsRefresh = getAboutYouNeedsRefresh(state);
  return {
    aboutYouNeedsRefresh,
    data,
    userId
  };
};

export default connect(mapStateToProps)(AboutYouInfoContainer);
