import React from 'react';
import styled from 'styled-components';
import { Table, Button, Pagination } from 'react-bootstrap';

import LoadingIcon from '../LoadingIcon';
import Utils from '../../utils';
import { getInitials } from './utils';

export const OrderProcessingList = ({
  orders = {},
  listHeaders = [],
  orderSort = '',
  setOrderSort = () => {},
  selectOrder = () => {},
  showCreateOrder = () => {},
  selectedOrder = null,
  orderListPage = null,
  orderListPageSize = null,
  purchasingAgents = {},
  selectOrderListPage = () => {},
}) => {
  if (orders.isFetching) {
    return (
      <div className="message-style">
        <LoadingIcon size="small" />
      </div>
    );
  }
  if (!orders.data) {
    return <div className="message-style">uhh ohh!</div>;
  }
  if (!orders.data.orders) {
    return <div className="message-style">uhh ohh part 2!</div>;
  }
  if (orders.data.orders.length === 0) {
    return (
      <div className="message-style">
        No results for your search
        <br />
        ¯\_(ツ)_/¯
      </div>
    );
  }

  // the default page size is 20 (defined in controller)
  const numPages = orders.data.orders
    ? Math.ceil(orders.data.total_count / orderListPageSize)
    : null;
  return (
    <div>
      {numPages > 1 ? (
        <Pagination
          style={{ width: 300 }}
          prev
          next
          ellipsis
          boundaryLinks
          items={numPages}
          bsSize="small"
          maxButtons={2}
          activePage={orderListPage}
          onSelect={selectOrderListPage}
        />
      ) : null}
      <Table striped condensed hover className="white">
        <thead>
          <tr>
            {listHeaders.map((row, index) => {
              let link = (
                <ListHeaderButton onClick={() => setOrderSort(row.sort)}>
                  {row.name}
                  {orderSort === row.sort ? ' ↓' : null}
                  {orderSort === `-${row.sort}` ? ' ↑' : null}
                </ListHeaderButton>
              );

              if (!row.sort) {
                link = row.name;
              }

              return (
                <th key={index}>
                  <div style={{ width: row.width }}>{link}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {orders.data.orders.map((row) => {
            return (
              <tr
                key={row.id}
                className={selectedOrder === row.id ? 'selected' : ''}
                onClick={() => selectOrder(row.id)}
              >
                <td>
                  <OrderIdButton>{row.id}</OrderIdButton>
                </td>
                <td>{getInitials(purchasingAgents, row.agent_user_id)}</td>
                <td>{row.status_name} </td>
                <td>
                  {row.is_luxe_order && (
                    <span role="img" title="Luxe" aria-label="Luxe order">
                      🎩
                    </span>
                  )}
                  {row.is_reno_order && (
                    <span role="img" title="Reno" aria-label="Reno order">
                      🛠
                    </span>
                  )}
                  {row.is_replacement_order && (
                    <span
                      role="img"
                      title="Replacement order"
                      aria-label="Replacement order"
                    >
                      🔄
                    </span>
                  )}
                  {row.is_automation_failure && (
                    <span
                      role="img"
                      title="Automation failed"
                      aria-label="Failed order"
                    >
                      ⛔
                    </span>
                  )}
                </td>
                <td>{row.user_name} </td>
                <td>
                  {Utils.getDate(row.created_at)}{' '}
                  {Utils.getTime(row.created_at)}
                </td>
                <td>{Utils.formatMoney(row.customer.total)} </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="order-count">
        {orders.data.total_count - orders.data.orders.length} more orders ...
      </div>
      <hr />
      <div className="manual-order">
        <Button
          className="action-button green"
          onClick={() => showCreateOrder()}
        >
          Manual Order
        </Button>
      </div>
    </div>
  );
};

const ListHeaderButton = styled.button`
  text-decoration: none;
  color: rgb(51, 122, 183);
  border: 0;
  padding: 0;
`;

const OrderIdButton = styled(ListHeaderButton)`
  font-weight: 500;
`;
