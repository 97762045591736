import React from 'react';
import styled from 'styled-components';
import { FormGroup, ControlLabel, Row, Col, Radio } from 'react-bootstrap';
import find from 'lodash/find';
import map from 'lodash/map';
import {
  BRANDS_MAP,
  COLOR_FAMILY_CHOICES,
  COLOR_INTENSITY_CHOICES_MAP,
  NoAnswerProvidedStr,
} from '../../../constants';
import InspirationLinks from './Budget/InspirationLinks/InspirationLinks';
import TextareaAutosizeExtended from '../../../sharedComponents/TextareaAutosizeExtended';
import PanelLink from '../../../sharedComponents/PanelLink/PanelLink';
import Checkbox from '../../../sharedComponents/Checkbox/Checkbox';
import { handleFormattingLinks } from '../Utils';

import LikedDesignIdeas from './StyleAndColorComponents/LikedDesignIdeas';
import QuizResults from './QuizResults/QuizResults';
import StyleMatches from './StyleMatch/StyleMatches';
import StyleDescription from './StyleDescription/StyleDescription';

export default function StyleAndColor(props) {
  const {
    activeSpaceID = 0,
    color_family = [],
    color_intensity = '',
    colors_to_avoid = '',
    contentDisplay = {},
    furniture_brands = [],
    genomeModalProps = {},
    handleChange = () => {},
    handleSubmitGenome = () => {},
    likeReasons = {},
    other_brand = '',
    openModal = () => {},
    productsModalProps = {},
    ppo_version = 0,
    setContentDisplayOption = () => {},

    styleCollections = {},
    userFirstName = '',
    userFeedpageFavorites,
    style_description = '',
    userQuizData = [],
  } = props;

  // Adding a second check because it is possible that a user
  // opens an older space, removes all checked furniture, and then the furniture
  // panel will disappear.  Note that below is not the best solution, but we don't
  // have back-end in place to know when brands and color was removed from
  // PPO.  Removal of furniture brands was a warehouse-client/FE change only
  // See thread in slack for background: https://modsy.slack.com/archives/G4GAGDCBV/p1580411045082500
  const hasFurnitureData =
    ppo_version < 2 ||
    (props && furniture_brands && furniture_brands.length > 0);

  const colorIntensityChoiceStr =
    color_intensity && color_intensity !== ''
      ? COLOR_INTENSITY_CHOICES_MAP[color_intensity]
      : NoAnswerProvidedStr;

  const hasStyleCollectionRooms =
    styleCollections &&
    styleCollections.rooms &&
    styleCollections.rooms.length > 0;
  const styleCollectionRooms = hasStyleCollectionRooms
    ? styleCollections.rooms
    : [];

  const handleSelectedFurniture = (e, value) => {
    if (e.target.checked) {
      handleChange([...furniture_brands, value], 'furniture_brands');
    } else {
      const brands = furniture_brands.filter((brand) => brand !== value);
      handleChange(brands, 'furniture_brands');
    }
  };

  const handleSelectedColors = (e, colorChoiceKey) => {
    if (colorChoiceKey === 'Not Sure') {
      if (e.target.checked) {
        handleChange(['Not Sure'], 'color_family');
      } else {
        handleChange([], 'color_family');
      }
    } else {
      let colors = [...color_family, colorChoiceKey].filter(
        (colorCopy) => colorCopy !== 'Not Sure'
      );
      if (e.target.checked && colors.length <= 2) {
        handleChange(colors, 'color_family');
      } else {
        colors = color_family.filter(
          (colorCopy) => colorCopy !== colorChoiceKey
        );
        handleChange(colors, 'color_family');
      }
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <FormGroup controlId="formControlsHowToUseSpace">
          <PanelLink
            header="How much color do you want to see in your space?"
            link={colorIntensityChoiceStr}
            displayContent={contentDisplay.isColorIntensity}
            onClick={() => setContentDisplayOption('isColorIntensity')}
          >
            <ContentContainer id="color-intensity">
              {Object.keys(COLOR_INTENSITY_CHOICES_MAP).map((key, index) => {
                let colorValue = key;
                let colorLabel = COLOR_INTENSITY_CHOICES_MAP[key];
                let isColorValueChecked = colorValue === color_intensity;

                // To do, move Radio to styled-components
                // asana ticket: https://app.asana.com/0/1133576522235947/1163857925116041
                return (
                  <Radio
                    key={`${colorLabel}-${index}`}
                    id={colorLabel + '-choice'}
                    checked={isColorValueChecked}
                    value={colorValue}
                    onChange={(e) =>
                      handleChange(e.target.value, 'color_intensity')
                    }
                  >
                    {colorLabel}
                  </Radio>
                );
              })}
            </ContentContainer>
          </PanelLink>
        </FormGroup>
      </Col>
      <Col xs={12}>
        <FormGroup controlId="formControlsLayoutConsiderations">
          <PanelLink
            header="Which color palette are you most drawn to?"
            link={handleFormattingLinks('colorChoice', '')}
            displayContent={contentDisplay.isColorFamily}
            onClick={() => setContentDisplayOption('isColorFamily')}
          >
            {Object.entries(COLOR_FAMILY_CHOICES).map((color, index) => {
              const colorChoiceKey = color[0];
              return (
                <Checkbox
                  key={index}
                  className="colorChoice"
                  checked={
                    !!find(
                      color_family,
                      (colorValue) => colorValue === colorChoiceKey
                    )
                  }
                  value={color[0]}
                  label={colorChoiceKey}
                  onChange={(e) => {
                    handleSelectedColors(e, colorChoiceKey);
                  }}
                />
              );
            })}
          </PanelLink>
        </FormGroup>
        <FormGroup controlId="formControlsSpecialRequests">
          <PanelLink
            header={`Is there any color you'd like us to avoid in your room design?`}
            link={handleFormattingLinks('', colors_to_avoid)}
            displayContent={contentDisplay.isColorsToAvoid}
            onClick={() => setContentDisplayOption('isColorsToAvoid')}
          >
            <TextareaAutosizeExtended
              value={colors_to_avoid}
              onChange={(e) => handleChange(e.target.value, 'colors_to_avoid')}
            />
          </PanelLink>
        </FormGroup>
      </Col>
      {hasFurnitureData && (
        <Col xs={12}>
          <FormGroup controlId="formControlsWhoLivesHere">
            <PanelLink
              header="Which furniture brands are you generally drawn towards? (old PPO question)"
              link={handleFormattingLinks('hasFurnitureBrand', other_brand)}
              displayContent={contentDisplay.isFurnitureBrand}
              onClick={() => setContentDisplayOption('isFurnitureBrand')}
            >
              {map(BRANDS_MAP, (value, index) => {
                return (
                  <Checkbox
                    key={index}
                    className={'hasFurnitureBrand'}
                    checked={
                      !!find(furniture_brands, (brand) => brand === value)
                    }
                    value={value}
                    label={value}
                    onChange={(e) => {
                      handleSelectedFurniture(e, value);
                    }}
                  />
                );
              })}
              <TextareaAutosizeExtended
                value={other_brand}
                onChange={(e) => handleChange(e.target.value, 'other_brand')}
              />
            </PanelLink>
          </FormGroup>
        </Col>
      )}
      <Col xs={12}>
        <FormGroup controlId="formControlsInspirationLinks">
          <ControlLabel>Inspiration Links</ControlLabel>
          <InspirationLinks {...props} />
        </FormGroup>
      </Col>
      <QuizResults
            activeSpaceID={activeSpaceID}
            genomeModalProps={genomeModalProps}
            handleSubmitGenome={handleSubmitGenome}
            openModal={openModal}
            productsModalProps={productsModalProps}
            userQuizData={userQuizData}
        />

        <StyleDescription
            contentDisplay={contentDisplay}
            handleChange={handleChange}
            handleFormattingLinks={handleFormattingLinks}
            setContentDisplayOption={setContentDisplayOption}
            style_description={style_description}
        />

        <StyleMatches
            likeReasons={likeReasons}
            hasStyleCollectionRooms={hasStyleCollectionRooms}
            styleCollectionRooms={styleCollectionRooms}
        />

      <LikedDesignIdeas
        userFirstName={userFirstName}
        userFeedpageFavorites={userFeedpageFavorites}
      />
    </Row>
  );
}

const ContentContainer = styled.div`
  display: block;
`;
