import { combineReducers } from 'redux';
import apiDataReducerGenerator from './api-data-reducer-generator';
import initialInformationReducer from './initial-information-reducer';
import { USER_SPECIFIC_API_IDENTIFIERS } from '../constants';
import get from 'lodash/get';

// returns object based on USER_SPECIFIC_API_IDENTIFIERS
const buildUserAPIReducers = () => {
  const apiReducers = {};
  USER_SPECIFIC_API_IDENTIFIERS.forEach((identifier) => {
    apiReducers[identifier] = apiDataReducerGenerator(identifier);
  });
  return apiReducers;
};

// merge user API reducers with any static reducers
const userDataReducer = combineReducers(
  Object.assign(
    {},
    {
      user_id: initialInformationReducer,
    },
    buildUserAPIReducers(),
  ),
);

export const getUserBasicInfoData = state => get(state, 'user_data.basic_information.data', {});
export const getAboutYouData = state => get(state, 'user_data.about_you.data', {});
export const getAboutYouNeedsRefresh = state => get(state, 'user_data.about_you.needRefresh', false);
export const getBasicInformationNeedsRefresh = state => get(state, 'user_data.basic_information.needRefresh', false);
export const getUserId = state => get(state, 'user_data.basic_information.data.id', null);

export default userDataReducer;
