import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippy.js/react';

const RedesignToolTip = styled(Tippy)`
  background-color: white;
  box-sizing: border-box !important;
  color: #000;
  box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.12);
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: left;
  font-weight: 300;

  &[x-placement^='left'] {
    .tippy-arrow {
      border-left-color: #FFF;
    }
  }
  &[x-placement^='right'] {
    .tippy-arrow {
      border-right-color: #FFF;
    }
  }
  &[x-placement^='bottom'] {
    .tippy-arrow {
      border-bottom-color: #FFF;
    }
  }
  &[x-placement^='top'] {
    .tippy-arrow {
      border-top-color: #FFF;
    }
  }
`;

const Image = styled.img`
  margin: ${props => props.margin};
  cursor: pointer;
`;

export default function Tooltips(
  {
    content,
    toolTipClassName,
    margin,
    icon,
    alt,
  },
) {
  return (
    <RedesignToolTip
      content={content}
      allowHTML={true}
      arrow={true}
      animation="fade"
      className={toolTipClassName}
      distance={7}
      placement='right-start'
      hideOnClick={false}
    >
      <Image margin={margin} src={icon} alt={alt} />                
    </RedesignToolTip>
  );
}
