import React from 'react';
import { Col } from 'react-bootstrap';
import ThumbnailGenerator from './image-list-components/ThumbnailGenerator';

const ImageList = ({ images, open, startIndex, imageListType }) => {

  const rightHalf = [];
  const leftHalf = [];
  images.forEach( (item,index) => {
    if(index % 2 === 0){
      leftHalf.push(item)
    } else {
      rightHalf.push(item)
    }
  });

  const imageCols = [leftHalf, rightHalf];

  return (
    <div>
      {imageCols.map((items, i) => {
        return (
          <Col xs={6} key={i} className="image-col">
            {items.map((image, j) => {
              const index = 2 * j + i;
              return (
                <ThumbnailGenerator
                  key={j}
                  index={index + startIndex}
                  image={image}
                  open={open}
                  imageListType={imageListType}
                />
              );
            })}
          </Col>
        );
      })}
    </div>
  );
};

export default ImageList;
