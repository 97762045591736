import axios from 'axios';
import cookie from 'js-cookie';
import * as types from './action-types';

export const patchRequest = identifier => ({
  type: types[`${identifier.toUpperCase()}_REQUEST`],
});

export const patchSuccess = (data, identifier) => {
  return {
    type: types[`${identifier.toUpperCase()}_SUCCESS`],
    recievedAt: Date.now(),
    data,
  };
};

export const patchFailure = (error, identifier) => ({
  type: types[`${identifier.toUpperCase()}_FAILURE`],
  error,
});

const sendRequest = (url, identifier, params, data, method) => (dispatch) => {
  // dispatch request
  dispatch(patchRequest(identifier));

  // make API call with necessary headers (content type, XSS protection, credentials, etc)
  return axios({
    method,
    url,
    params,
    data,
    credentials: 'include',
    headers: {
      'X-CSRFToken': cookie.get('csrftoken'),
      'content-type': 'application/json',
    },
  }).then((response) => {
    dispatch(patchSuccess(response.data, identifier));
    return Promise.resolve(response.data);
  }).catch((error) => {
    dispatch(patchFailure(error.toString(), identifier));
    return Promise.reject(new Error(error.toString()));
  });
};

/*
 * Used to patch data from API endpoint
 * @params:
 *   url (string),
 *   identifier (string) that can be used to reference results and is used for action generation
 *   params (object) parameters to be passed in request... probably {}
 *   method (string) self explanatory... probably 'patch'
 *   data (object) for body of request
 */
export const patchData = (
  url,
  identifier,
  { params = {}, data = {}, method = 'patch' } = {},
) => (dispatch) => {
  return dispatch(sendRequest(url, identifier, params, data, method));
};
