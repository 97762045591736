export const styleResults = [
  {
    id: 16,
    style_1_id: 9,
    style_2_id: 8,
    description:
      'Warm and inviting, your style mix makes your house feel like a home. You love a comfortable atmosphere with a blend of rustic touches and antiques.',
    tips:
      'Combine a large, weathered wooden chest, a vintage carpet and plush furniture with linen upholstery.  ',
    picture: 'images/StyleCombinations/Contemporary_Rustic.jpg',
    styleResult: 'Rustic Warmth',
    blurb: '',
  },
  {
    id: 17,
    style_1_id: 9,
    style_2_id: 4,
    description:
      "Comfortable rooms with plush furniture are the basis of your style. But you're also drawn to things that suggest a hardworking past. By mixing rustic metal furniture into a livable space, your style creates a smart atmosphere with a neat twist.",
    tips:
      'Balance the warm and cool notes of each style, pairing weathered metal and wood furniture with comfortable seating. Accents in muted colors will add warmth.',
    picture: 'images/StyleCombinations/Contemporary_Industrial.jpg',
    styleResult: 'Inviting Industrial',
    blurb: '',
  },
  {
    id: 18,
    style_1_id: 9,
    style_2_id: 3,
    description:
      'Drawn to unique pieces that are conversation starters, your style strays a bit from the conventional. You feel most at home in  comfortable, lived-in spaces and prefer to rely on decorative accessories to define the atmosphere.',
    tips:
      'Anchor your room with Traditional, comfortable furniture. Then incorporate bold accent pieces, such as a vintage table lamp, patterned rug, or exotic wall art.',
    picture: 'images/StyleCombinations/Contemporary_Eclectic.jpg',
    styleResult: 'Contemporary Collector',
    blurb: '',
  },
  {
    id: 19,
    style_1_id: 9,
    style_2_id: 7,
    description:
      'You want your house to feel like a comfortable and inviting home,  but can\'t ignore that little voice inside screaming, "More gold, more silver, more pattern, more drama!" Your style  draws on these bold aspects to elevate your space.',
    tips:
      'Pair Traditional pieces with unexpected accents, such as a crystal light fixture or a brass dog sculpture.',
    picture: 'images/StyleCombinations/Contemporary_Glam.jpg',
    styleResult: 'Comfortably Chic',
    blurb: '',
  },
  {
    id: 20,
    style_1_id: 9,
    style_2_id: 2,
    description:
      'Warm and inviting, your style mix makes your house feel like a home. You love a comfortable atmosphere with a blend of rustic touches and antiques.',
    tips:
      'Balance the warm and cool notes of each style, pairing weathered metal and wood furniture with comfortable seating. Accents in muted colors will add warmth.',
    picture: 'images/StyleCombinations/Contemporary_Traditional.jpg',
    styleResult: 'Updated Traditional',
    blurb: '',
  },
  {
    id: 22,
    style_1_id: 9,
    style_2_id: 1,
    description:
      "Drawn to both American antiques and modern forms, you're a pro at mixing styles. You love spaces steeped in tradition but prefer a  slightly less formal vibe.",
    tips:
      'Ground your room with a few key antique pieces. Mix in furniture featuring neutral upholstery and modern lines.',
    picture: 'images/StyleCombinations/Rustic_Industrial.jpg',
    styleResult: 'Contemporary Connoisseur',
    blurb: '',
  },
  {
    id: 3,
    style_1_id: 8,
    style_2_id: 6,
    description:
      'A cool, spare room warmed with a touch of rustic charm is like a little slice of heaven to you. Your style combines pared-down vintage elements in a calm, neutral palette. ',
    tips:
      'Hold back on the decorative accessories. Make a weathered Barcelona chair or a crisply painted Thonet bistro chair your statement piece. ',
    picture: 'images/StyleCombinations/Rustic_Minimalist.png',
    styleResult: 'Refined Farmhouse',
    blurb: '',
  },
  {
    id: 21,
    style_1_id: 8,
    style_2_id: 4,
    description:
      "You're beckoned by the reclaimed, in both objects and environments. Whether it's rustic wood or distressed metal, you love to create a space that reflects the past.",
    tips:
      'Highlight the unique architecture of your space with light colors and minimal wall treatments; fill in with found vintage objects.',
    picture: 'images/StyleCombinations/Rustic_Industrial.jpg',
    styleResult: 'Reclaimed Visionary',
    blurb: '',
  },
  {
    id: 23,
    style_1_id: 8,
    style_2_id: 3,
    description:
      "Vintage spaces with a rustic feel are your cup of tea, and you're mad for  pulling in elements from many different styles and time periods. Your favorite places to shop are flea markets and antique stores, and you have that special gift for turning a street find into an amazing one-of-a-kind piece.",
    tips:
      'Mix unexpected pieces, such as a farmhouse table and Eames shell chairs.',
    picture: 'images/StyleCombinations/Rustic_Eclectic.jpg',
    styleResult: 'Rustic Traveler ',
    blurb: '',
  },
  {
    id: 24,
    style_1_id: 8,
    style_2_id: 7,
    description:
      'Your design style combines a love for rustic spaces with glamorous  touches. You delight in statement pieces with an alluring story to stir up conversations amongst your guests.',
    tips:
      'Mix statement pieces with hearty classics.Try placing a cowhide rug beneath a weathered wood table. ',
    picture: 'images/StyleCombinations/Rustic_Glam.jpg',
    styleResult: 'Farmhouse Chic',
    blurb: '',
  },
  {
    id: 25,
    style_1_id: 8,
    style_2_id: 2,
    description:
      "You revere stately antiques and formal shapes, but feel more relaxed and at home in rustic spaces. Ideally, you'd live in an historic home filled with pieces that highlight the wear and patina of their glorious history.",
    tips:
      'Mix and match Chippendale chairs with Thonet Bentwood chairs at the dining table.',
    picture: 'images/StyleCombinations/Rustic_Traditional.jpg',
    styleResult: 'Vintage Farmhouse',
    blurb: '',
  },
  {
    id: 26,
    style_1_id: 8,
    style_2_id: 1,
    description:
      "Vintage spaces with a rustic feel are your cup of tea, and you're mad for  pulling in elements from many different styles and time periods. Your favorite places to shop are flea markets and antique stores, and you have that special gift for turning a street find into an amazing one-of-a-kind piece.",
    tips:
      'Mix unexpected pieces, such as a farmhouse table and a crystal chandelier.',
    picture: 'images/StyleCombinations/Classic_Rustic.jpg',
    styleResult: 'Refined Rustic',
    blurb: '',
  },
  {
    id: 34,
    style_1_id: 7,
    style_2_id: 2,
    description:
      'You want your house to feel like a comfortable and inviting home,  but can\'t ignore that little voice inside screaming, "More gold, more silver, more pattern, more drama!" Your style  draws on these bold aspects to elevate your space.',
    tips:
      'Anchor your room with Traditional, comfortable furniture. Then incorporate bold accent pieces, such as a vintage table lamp, patterned rug, or exotic wall art.',
    picture: 'images/StyleCombinations/Tradidtional_Glam.jpg',
    styleResult: 'Traditionally Chic',
    blurb: '',
  },
  {
    id: 35,
    style_1_id: 7,
    style_2_id: 1,
    description:
      "A space that feels like a showroom is your thing. It's completely polished and perfectly considered. But you do have a tendency toward the unexpected. You prefer to root your designs in classic antiques and accessorize then add jaw-dropping touches.",
    tips:
      'Layer a wingback chair with luxurious textures, like hide pillows and Mongolian fur.',
    picture: 'images/StyleCombinations/Classic_Glam.jpg',
    styleResult: 'Elegant Connoisseur',
    blurb: '',
  },
  {
    id: 1,
    style_1_id: 6,
    style_2_id: 5,
    description:
      'You dig a retro look almost as much as you crave a clean space. Sleek forms and modern styling are your weakness. You thrive in monochromatic spaces and can easily imagine yourself seated  on an iconic piece such as an Eames lounge or Barcelona chair.',
    tips:
      'Pair iconic chrome furniture with a large overdyed rug to soften the Minimalist edge.',
    picture: 'images/StyleCombinations/Mid_Century_Minimalist.jpg',
    styleResult: 'Mod Visionary',
    blurb: '',
  },
  {
    id: 2,
    style_1_id: 6,
    style_2_id: 9,
    description:
      'Though you have one foot firmly planted in the comfort of timeless classics and traditional lines, you are also drawn to the calm openness of modern spaces. A combination of these aesthetics is your sweet spot. Neutral palettes with minimal ornamentation set in light, open spaces makes you feel right at home.',
    tips:
      'Build upon a foundation of neutral furniture and pair down decorative accessories to your absolute favorites.',
    picture: 'images/StyleCombinations/Contemporary_Minimalist.jpg',
    styleResult: 'Contemporary Minimalist',
    blurb: '',
  },
  {
    id: 4,
    style_1_id: 6,
    style_2_id: 4,
    description:
      "You're drawn to rooms that present an illustrative history, in a spare, crisp setting. Non-fussy iconic furniture ranging from classic shop pieces to Bauhaus favorites ring your bell, especially in a space with open architecture and exposed construction. ",
    tips:
      'Your ideal interior: a large loft space with concrete floors and exposed brick, furnished with Minimalist pieces. ',
    picture: 'images/StyleCombinations/Industrial_Minimalist.jpg',
    styleResult: 'Industrial Visionary',
    blurb: '',
  },
  {
    id: 5,
    style_1_id: 6,
    style_2_id: 3,
    description:
      'Drawn to high-drama spaces with lots of contrast, you also have a very spare aesthetic. Clean and bare spaces pull you in, especially when they display personality through a mixed collection of furnishings and a few bold accessories. ',
    tips:
      'Rely on Minimalist furniture as a foundation. Select a few of your favorite Eclectic pieces, but keep a common color palette, such as black and white, to maintain a clean look. ',
    picture: 'images/StyleCombinations/Eclectic_Minimalist.jpg',
    styleResult: 'Discerning Collector',
    blurb: '',
  },
  {
    id: 6,
    style_1_id: 6,
    style_2_id: 7,
    description:
      'You seek drama, which explains why your two favorite styles are on opposite ends of the spectrum. Part of you is drawn to clean lines and spare rooms, while your other side can’t resist luxurious textures, sparkle and ornament. Create the perfect middle ground by anchoring your rooms with minimalist furniture, then glam it up in the accessories.',
    tips:
      'Minimalist metal and leather furniture make a lover of Hollywood Glam swoon, so use this as your foundation. Add some drama with bold artwork and knockout lighting fixtures.',
    picture: 'images/StyleCombinations/Minimalist_Glam.jpg',
    styleResult: 'Less is More Chic',
    blurb: '',
  },
  {
    id: 7,
    style_1_id: 6,
    style_2_id: 2,
    description:
      "Your true love is the comfort and timeless quality of traditional furnishings, but you're also drawn to the crisp, serene feeling of modern design. Your dream space is achieved by basing comfortable furniture in a room with minimal ornamentation and spare accessories. Neutral palettes with a bright and airy feel are your favorites.",
    tips:
      'Pare down patterns and opt for solid accent pieces. Select warmer neutrals such as taupes and tans, to add a touch of warmth to a Minimalist base.',
    picture: 'images/StyleCombinations/Traditional_Minimalist.jpg',
    styleResult: 'Rustic Minimalist',
    blurb: '',
  },
  {
    id: 8,
    style_1_id: 6,
    style_2_id: 1,
    description:
      "Clean, spare rooms feel relaxing to you, but you're also a sucker for beautiful ornamentation. High contrast and drama in a space entices you. Get your drama fix with your furniture, but opt for a monochromatic palette to satisfy your minimalist side.",
    tips:
      'Whitewash everything--the walls, the wood furniture, the floors--and introduce one contrasting color. Perhaps a dark neutral like black or navy? ',
    picture: 'images/StyleCombinations/Classic_Minimalist.jpg',
    styleResult: 'Refined Connoisseur',
    blurb: '',
  },
  {
    id: 9,
    style_1_id: 5,
    style_2_id: 9,
    description:
      'For you, comfort is on a par with style. You go for Danish Modern design and streamlined furniture, but incorporating some traditional pieces for comfort strikes the perfect balance.',
    tips:
      "Opt for light woods, warm colors, a bright rug, and plush seating to create a look you'll love.",
    picture: 'images/StyleCombinations/Mid_Century_Traditional.jpg',
    styleResult: 'Mod Enthusiast',
    blurb: '',
  },
  {
    id: 10,
    style_1_id: 5,
    style_2_id: 8,
    description:
      'Drawn to both Danish Modern and weathered vintage pieces, you prefer natural wood and comfortable, informal interiors. Your style shines with just the right dose of rustic details and old-school finds.',
    tips:
      'Mix a reclaimed wood table with retro Eames shell chairs for a note of contrast. ',
    picture: 'images/StyleCombinations/Contemporary_Mid_Century.jpg',
    styleResult: 'Modern Rustic',
    blurb: '',
  },
  {
    id: 11,
    style_1_id: 5,
    style_2_id: 4,
    description:
      'You furniture of choice is Danish modern, and you thrive in weathered spaces that show some history. Vintage metals and reclaimed wood paired with Atomic era furniture is your perfect blend.',
    tips:
      'Pick Atomic pieces with minimal ornamentation and soft colors that will highlight the Industrial accents in your space.',
    picture: 'images/StyleCombinations/Mid_Cenutry_Industrial.jpg',
    styleResult: 'Atomic Industrial',
    blurb: '',
  },
  {
    id: 12,
    style_1_id: 5,
    style_2_id: 3,
    description:
      'Though modern is your go-to style of choice, you need a space that showcases your personality and individual taste. Your style draws from many different time periods and cultures and presents them in a unique and fresh way. ',
    tips:
      "You'll thrive in a space that uses Mid-Century Modern furniture with a few statement pieces in a variety of styles. ",
    picture: 'images/StyleCombinations/Mid_Century_Eclectic.jpg',
    styleResult: 'Mod Collector',
    blurb: '',
  },
  {
    id: 13,
    style_1_id: 5,
    style_2_id: 7,
    description:
      'You love the idea of livable spaces, but crave a bit more drama. Your style is distinctly yours, combining Mid-Century aesthetics, like warm wood tones, with bold statement pieces, like a patterned rug.',
    tips:
      'Play up Hollywood Glam love of metallics and use Mid-Century pieces that have an Atomic feel, like chrome lighting.',
    picture: 'images/StyleCombinations/Mid_Century_Glam.jpg',
    styleResult: 'Mid-Century Chic',
    blurb: '',
  },
  {
    id: 14,
    style_1_id: 5,
    style_2_id: 2,
    description:
      'You like to feel at home in your space. With a modern sensibility, you love Danish Modern and streamlined furniture, but you also like to incorporate traditional pieces for extra comfort. ',
    tips:
      "Opt for light woods, warm colors, a bright rug, and inviting seating to create a look you'll love.",
    picture: 'images/StyleCombinations/Mid_Century_Traditional.jpg',
    styleResult: 'Modern Vintage',
    blurb: '',
  },
  {
    id: 15,
    style_1_id: 5,
    style_2_id: 1,
    description:
      'You adore exquisite craftsmanship and rooms filled with beautiful  furniture. Drawn to spaces that are a bit more formal, your taste is a refined interior that allows room for comfort.',
    tips:
      'Look for Modern pieces that showcase beautiful wood grain and pair them with a bold Oriental carpet. ',
    picture: 'images/StyleCombinations/Mid_Century_Classic.jpg',
    styleResult: 'Refined Modern',
    blurb: '',
  },
  {
    id: 27,
    style_1_id: 4,
    style_2_id: 3,
    description:
      'Repurposed pieces and vintage spaces set your heart aflutter. Your  style is all about combining unique objects and adding a bit of unexpected color.',
    tips:
      'Balance weathered Industrial pieces with softer accents, such as a colorful, patterned rug.',
    picture: 'images/StyleCombinations/Industrial_Eclectic.jpg',
    styleResult: 'Urban Collector',
    blurb: '',
  },
  {
    id: 28,
    style_1_id: 4,
    style_2_id: 7,
    description:
      'Dramatic spaces with atmospheric lighting captivate your soul. As an unexpected element, you enjoy mixing luxe and rustic accessories.',
    tips:
      'Pair a weathered, leather Chesterfield sofa with a cowhide rug and a chrome chandelier.',
    picture: 'images/StyleCombinations/Industrial_Glam.jpg',
    styleResult: 'Industrial Chic',
    blurb: '',
  },
  {
    id: 29,
    style_1_id: 4,
    style_2_id: 2,
    description:
      "You're drawn to spaces and objects with great history. A lover of classic forms, you've got an edge to your style and incorporate an ode to a weathered past.",
    tips:
      'Highlight Rustic features and pair Industrial pieces with a colorful, patterned rug.',
    picture: 'images/StyleCombinations/Industrial_Traditional.jpg',
    styleResult: 'Urban Traditional',
    blurb: '',
  },
  {
    id: 30,
    style_1_id: 4,
    style_2_id: 1,
    description:
      "You're enchanted by spaces and objects with great history.  While a lover of classic forms, you've got an edge to  your style that suggests an ode to a weathered past.",
    tips:
      "Don't be afraid to pair exposed brick or concrete floors with stately antiques--opt for Neoclassical styles with clean lines and robust forms.",
    picture: 'images/StyleCombinations/Classic_Industrial.jpg',
    styleResult: 'Refined Industrial',
    blurb: '',
  },
  {
    id: 31,
    style_1_id: 3,
    style_2_id: 7,
    description:
      "You're wild for drama and excitement! Your style reflects your unique, spirited personality, great eye, and love for collecting treasures - in every style.",
    tips:
      'Why have just one statement piece when you can have five? Incorporate interesting conversation starters throughout your interior.',
    picture: 'images/StyleCombinations/Eclectic_Glam.jpg',
    styleResult: 'Chic Collector ',
    blurb: '',
  },
  {
    id: 32,
    style_1_id: 3,
    style_2_id: 2,
    description:
      'You are enthralled by pieces with a great story, especially antiques. Mixing a collection of found objects and stately antique elements, your style results in a unique environment that is livable, yet polished.',
    tips:
      'Anchor your room with Traditional, comfortable furniture. Then incorporate your favorite collected accent pieces, such as a vintage table lamp or patterned rug.',
    picture: 'images/StyleCombinations/Traditional_Eclectic.jpg',
    styleResult: 'Traditional Collector',
    blurb: '',
  },
  {
    id: 33,
    style_1_id: 3,
    style_2_id: 1,
    description:
      'You are enthralled by pieces with a great story, especially antiques. Mixing a collection of found objects and stately antique elements, your style results in a unique environment that is livable, yet polished.',
    tips: 'Reupholster a classic slipper chair with a bold, modern print.',
    picture: 'images/StyleCombinations/Classic_Eclectic.jpg',
    styleResult: 'Classic Collector',
    blurb: '',
  },
  {
    id: 36,
    style_1_id: 2,
    style_2_id: 1,
    description:
      'You are enthralled by pieces with a great story, especially antiques. Mixing a collection of found objects and stately antique elements, your style results in a unique environment that is livable, yet polished.',
    tips:
      'Anchor your room with Traditional, comfortable furniture. Then incorporate antique pieces, such as your favorite paintings, vases, and accent furniture.',
    picture: 'images/StyleCombinations/Mid_Century_Minimalist.jpg',
    styleResult: 'Timeless Traditional',
    blurb: '',
  },
];

export const GENOME_HEADERS = {
  GENOME: 'Genome',
  AGREED: 'Agreed',
  PRODUCTS: 'Products',
  SOURCE: 'Source',
  DATE_TAKEN: 'Date Taken',
};

export const QUIZ_SOURCES = {
  0: 'Pre-purchase style quiz',
  1: 'PPO Style Quiz',
  2: 'IOS Style Quiz',
  3: 'Assigned',
};

export const ADD_GENOME_STR = 'Add genome';
export const AGREED_DEFAULT_STR = 'N/A';
export const PRODUCTS_DEFAULT_STR = '--';
export const GRID_COLUMN_COUNT =
  '1fr minmax(auto, 100px) minmax(auto, 100px) 1fr 1fr';
