import $ from 'jquery';
import cookie from 'js-cookie';
import React from 'react';
import axios from 'axios';
import { bugsnagError } from '../services/bugsnag';
import {
  Col,
  ButtonGroup,
  Button,
  Table,
  Panel,
  DropdownButton,
  Glyphicon,
  Alert,
} from 'react-bootstrap';
import styled from 'styled-components';
import ImageListContainer from '../containers/ImageListContainer';
import {
  MEASUREMENT_STATUS,
  MEASUREMENT_STATUS_MAP,
  IMAGE_LIST_TYPES,
  FEET_TO_MILLIMETERS,
} from '../constants';
import PatchMenuItem from './PatchMenuItem';
import PatchButton from './PatchButton';
import EditableTextField from './EditableTextField';
import { patchData } from '../actions/api-patch-request-generator';
import { setSpaceStatus } from '../../src/actions/space-data';
import { ApiConfig } from '../config';
import Utils from '../utils';
import UploadModal from './UploadModal';
import LoadingIcon from './LoadingIcon';

import QualityMetrics from './QualityMetrics';
import MobileCaptureGif from './MobileCaptureGif/MobileCaptureGifController';

import RenovationChoice from '../containers/RenovationsPM/components/RenovationChoice';
import SpaceShape from '../containers/RenovationsPM/components/SpaceShape';
import InternalNotesPM from '../containers/RenovationsPM/components/InternalNotesPM';
import SaveModule from '../containers/RenovationsPM/components/SaveModule';

import { BaseFlex } from '../sharedComponents/sharedStyledComponents';
import FloorPaintSampleImages from '../containers/FloorPaintSampleImages/components/FloorPaintSampleImages';
import { auth } from '../auth';

class TrajectoryImageLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: undefined };
  }

  componentDidMount() {
    const { spaceId } = this.props;
    $.ajax({
      type: 'GET',
      url: ApiConfig.GET_MOBILE_CAPTURE(spaceId),
      dataType: 'json',
      xhrFields: {
        withCredentials: true,
      },
      beforeSend(xhr) {
        xhr.setRequestHeader('Authorization', `JWT ${cookie.get('jwtToken')}`);
      },
      success: (data) => {
        const s3key = `capture/${data.id}/wlt/trajectory.png`;
        $.ajax({
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          url: ApiConfig.PRESIGNED_DOWNLOAD_URL(),
          xhrFields: {
            withCredentials: true,
          },
          data: JSON.stringify({ s3keys: [s3key] }),
          beforeSend: (xhr) => {
            xhr.setRequestHeader(
              'Authorization',
              `JWT ${cookie.get('jwtToken')}`
            );
          },
          success: (data) => {
            this.setState({ url: data[s3key] });
          },
        });
      },
    });
  }

  render() {
    const { url } = this.state;
    if (!url) {
      return '';
    }
    return (
      <div>
        <img
          id="mobile-photo-url"
          src={url}
          alt=""
          style={{ width: '100%', marginTop: '52px' }}
        />
      </div>
    );
  }
}

export default class PhotosAndMeasurements extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      units: 'mm',
      editBudget: false,
      editRoomType: false,
      showUploadModal: false,
      isLoading: false,
      spaceStatus: this.props.spaceStatus,
    };
  }

  // change the units of the dimensions
  handleUnitChange = (units) => {
    this.setState({ units });
  };


  render() {
    const {
      activeSpaceID,
      dispatch,
      currentState,
      floorPaintSampleState,
      showReviewPhaseButton,
    } = this.props;

    const { showUploadModal } = this.state;
    const hasAuth = (
      auth.isInGroup('(OP) Operations - 3D Team') || 
      auth.isInGroup('(EN) Engineering - All') || 
      auth.isInGroup('Senior P&M Artist') || 
      auth.isInGroup('(CS) Customer Success - Design Specialist') || 
      auth.isInGroup('(CS) Customer Success - Design Specialist Team Leaders')
    );

    // null check
    if (!this.props.dimensions || !this.props.dimensions.results[0]) {
      // We need to show Luxe choices regardless of
      // whether or not there are photos and measurements
      // data
      if (currentState.isLuxePackage) {
        return <RenovationChoice {...currentState} />;
      }
      return (
        <Alert bsStyle="warning">
          There are no photos and measurements results
        </Alert>
      );
    }

    // reassign
    const dimensions = this.props.dimensions.results[0];
    const photos = dimensions.photos.photos_list;
    const captureType = dimensions.capture_type;

    const isIOSCaptureType = captureType === 1;

    const setMeasurementsStatus = (status) => {
      return patchData(
        `${ApiConfig.PHOTOS_AND_MEASURMENTS}/${dimensions.id}`,
        'measurement_status_change',
        { data: { status } }
      );
    };

    const setMeasurementsField = (field) => (value) => {
      return [
        patchData(
          `${ApiConfig.PHOTOS_AND_MEASURMENTS}/${dimensions.id}`,
          'measurement_field_change',
          // 308.4 millimeters / foot
          {
            data: Utils.buildState(
              field,
              this.state.units === 'ft' ? value * FEET_TO_MILLIMETERS : value
            ),
          }
        ),
      ];
    };

    const getSpaceInfo = (dispatch) => async () => {
      function onSuccess(response) {
        const responseData = response.data;
        dispatch(setSpaceStatus(responseData.status));
        return response;
      }
      function onError(error) {
        bugsnagError(error);
        return error;
      }
      try {
        const response = await axios.get(ApiConfig.GET_SPACE(activeSpaceID));
        return onSuccess(response);
      } catch (error) {
        return onError(error);
      }
    };

    const setSpaceToUnderReviewStatus = async () => {
      try {
        this.setState({ isLoading: true });
        await axios.patch(
          `${ApiConfig.FORCE_SET_SPACE_TO_UNDER_REVIEW_STATUS(activeSpaceID)}`
        );
        await getSpaceInfo(dispatch)();
      } catch (err) {
        bugsnagError(err);
      } finally {
        this.setState({ isLoading: false });
      }
    };

    const approveAllPhotos = () => {
      return patchData(
        `${ApiConfig.APPROVE_USER_IMAGES(activeSpaceID)}`,
        'user_photo_status_change'
      );
    };

    /** Handles both regular Approve All as well as IOS Approvals */
    const approveAllByType = () => {
      return [
        approveAllPhotos(),
        setMeasurementsStatus(MEASUREMENT_STATUS.APPROVED),
        setMeasurementsDropdown(),
      ];
    };

    const rejectAllPhotos = () => {
      return patchData(
        `${ApiConfig.REJECT_USER_IMAGES(activeSpaceID)}`,
        'user_photo_status_change'
      );
    };

    const rejectAllPhotosAndMeasurements = () => {
      return [
        rejectAllPhotos(),
        setMeasurementsStatus(MEASUREMENT_STATUS.NOT_APPROVED),
        setMeasurementsDropdown(),
      ];
    };

    const setMeasurementsDropdown = () => {
      const status = dimensions.status;
      const title = MEASUREMENT_STATUS_MAP[status][0];
      const bsStyle = MEASUREMENT_STATUS_MAP[status][1];
      const isSpacePending = this.props.spaceStatus === 'pending';

      return (
        <DropdownButton
          title={title}
          bsStyle={bsStyle}
          id="measurements-dropdown"
          disabled={title === 'Measurements Rejected' && isSpacePending}
        >
          <PatchMenuItem
            patchRequests={[setMeasurementsStatus(MEASUREMENT_STATUS.PENDING)]}
            identifier="dimensions"
          >
            Measurements Pending
          </PatchMenuItem>
          <PatchMenuItem
            patchRequests={[setMeasurementsStatus(MEASUREMENT_STATUS.APPROVED)]}
            identifier="dimensions"
          >
            Measurements Approved
          </PatchMenuItem>
          <PatchMenuItem
            patchRequests={[
              setMeasurementsStatus(MEASUREMENT_STATUS.NOT_APPROVED),
            ]}
            identifier="dimensions"
          >
            Measurements Rejected
          </PatchMenuItem>
        </DropdownButton>
      );
    };

    const reviewPhaseButton = (
      <button onClick={setSpaceToUnderReviewStatus}>
        {this.state.isLoading ? (
          <LoadingIcon size="small" />
          ) : (
              `Go back to review phase`
        )}
      </button>
    )

    const approveRejectButtons = (
      <div style={{ display: 'flex' }}>
        <PatchButton
          buttonStyle="reject-all-button"
          next={getSpaceInfo(dispatch)}
          patchRequests={rejectAllPhotosAndMeasurements()}
          identifier="dimensions"
        >
          {isIOSCaptureType ? 'Reject Capture' : 'Reject All'}
        </PatchButton>

        <PatchButton
          buttonStyle="approve-all-button"
          patchRequests={approveAllByType()}
          identifier="dimensions"
        >
          {isIOSCaptureType ? 'Approve Capture' : 'Approve All'}
        </PatchButton>
      </div>
    );

    const imagesToolbar = (
      <ButtonsContainer>
        {!isIOSCaptureType && (
          <React.Fragment>
            <Button
              id="upload-photos-button"
              onClick={() => {
                this.setState({ showUploadModal: true });
              }}
            >
              <Glyphicon glyph="plus" />
            </Button>
            <UploadModal
              show={showUploadModal}
              close={() => {
                this.setState({ showUploadModal: false });
              }}
              title="Upload User Photos"
              url={ApiConfig.USER_IMAGE_ADMIN_UPLOAD.replace(
                '{spaceID}',
                activeSpaceID
              )}
              isLegacy
              actionIdentifier="user_photo_upload"
              refreshIdentifier="dimensions"
            />
          </React.Fragment>
        )}
        {showReviewPhaseButton && reviewPhaseButton}
        {hasAuth && !showReviewPhaseButton && approveRejectButtons}
      </ButtonsContainer>
    );

    const listTitle = (
      <ListTitleContainer>
        <Text>
          {isIOSCaptureType ? (
            `Mobile Room Capture`
          ) : (
              <React.Fragment>
                Photos
                <SpanText>{` (${photos.length})`}</SpanText>
              </React.Fragment>
            )}
        </Text>
        {imagesToolbar}
      </ListTitleContainer>
    );

    const getMeasurements = {
      Length: {
        measurementsField: setMeasurementsField('length'),
        dimensionValue: Utils.getConvertedValue(
          dimensions.length,
          this.state.units
        ),
      },
      Width: {
        measurementsField: setMeasurementsField('width'),
        dimensionValue: Utils.getConvertedValue(
          dimensions.width,
          this.state.units
        ),
      },
      Height: {
        measurementsField: setMeasurementsField('height'),
        dimensionValue: Utils.getConvertedValue(
          dimensions.height,
          this.state.units
        ),
      },
      'Door Height': {
        measurementsField: setMeasurementsField('door_height'),
        dimensionValue: Utils.getConvertedValue(
          dimensions.door_height,
          this.state.units
        ),
      },
    };

    return (
      <div className="photos-and-measurements">
        <Container>
          <MeasurementsContainer>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Dimensions</th>
                  <th className="center-text" id="units-dropdown">
                    <ButtonGroup>
                      <Button
                        bsSize="xsmall"
                        onClick={() => this.handleUnitChange('ft')}
                        active={this.state.units === 'ft'}
                      >
                        ft
                      </Button>
                      <Button
                        bsSize="xsmall"
                        onClick={() => this.handleUnitChange('mm')}
                        active={this.state.units === 'mm'}
                      >
                        mm
                      </Button>
                    </ButtonGroup>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(getMeasurements).map((key, index) => {
                  const label = key;
                  const measurementsField =
                    getMeasurements[key].measurementsField;
                  const dimensionValue = getMeasurements[key].dimensionValue;

                  return (
                    <tr key={`${label}-${index}`}>
                      <td>{label}</td>
                      <td>
                        {hasAuth ? (
                          <EditableTextField
                            requestGenerator={measurementsField}
                            identifier="dimensions"
                            initialValue={dimensionValue}
                            validationRegex={/^[+-]?(\d*\.)?\d+$/}
                            validationMessage="Please enter a number."
                            styling="dimensions-input"
                          >
                            <span>
                              {dimensionValue}
                            &nbsp;{this.state.units}
                            </span>
                          </EditableTextField>
                        ) : (
                          <div>
                            {dimensionValue}
                          </div>
                          )
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {hasAuth && !isIOSCaptureType && setMeasurementsDropdown()}
            {dimensions && dimensions.comment && (
              <Col md={8} xs={12}>
                <Panel header="Measurement Notes">
                  <p>{dimensions.comment}</p>
                </Panel>
              </Col>
            )}
          </MeasurementsContainer>
          <ColumnContent>
              <SpaceShape {...currentState} />

            <RenovationChoice {...currentState} />

            <FloorPaintSampleImages {...floorPaintSampleState} />
            
            <InternalNotesPM {...currentState} />
            {currentState.shouldShowSaveModule && (
              <SaveModule
                hideSaveModule={currentState.hideSaveModule}
                saveData={currentState.saveData}
              />
            )}
            {isIOSCaptureType && (
              <>
                <MobileCaptureGif />
                <TrajectoryImageLoader spaceId={activeSpaceID} />
                <QualityMetrics />
              </>
            )}
          </ColumnContent>
        </Container>
        <ImageListContainer
          images={photos}
          imageListType={IMAGE_LIST_TYPES.PHOTOS_AND_MEASUREMENTS}
          title={listTitle}
        />
      </div>
    );
  }
}

const Text = styled(BaseFlex)`
  font-weight: 500 !important;
  font-size: 22px;
  margin-right: 16px;
`;

const SpanText = styled.span`
  color: #979797;
  margin-left: 4px;
  margin-right: 8px;
`;

const MeasurementsContainer = styled.div`
  flex-wrap: wrap;
  max-width: 250px;
  margin-right: 118px;
`;

const ButtonsContainer = styled(BaseFlex)``;

const Container = styled(BaseFlex)`
  flex-direction: row;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ColumnContent = styled(BaseFlex)`
  margin-bottom: 8px;
  flex-direction: column;
`;

const ListTitleContainer = styled(BaseFlex)`
  align-items: center;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-top: 40px;
  flex-wrap: wrap;
`;
