import { trackSegmentEvent } from './segment';
import partition from 'lodash/partition';

export const trackStartOrderProcessing = ({
  agentUserId = null,
  orderId = null,
}) => {
  trackSegmentEvent({
    event: 'order_processing_start',
    data: {
      agent_user_id: agentUserId,
      order_id: orderId
    }
  });
};

export const trackEndOrderProcessing = ({
  agentUserId = null,
  orderId = null,
  orderStatus = '',
  products = {},
}) => {
  // Requirement 1: count all unique retailers.
  const uniqueRetailerIDs = new Set();
  Object.values(products).forEach(product => {
    uniqueRetailerIDs.add(product.source__id);
  });
  const countRetailers = uniqueRetailerIDs.size;

  // Requirement 2: count automated vs manual orders.
  // Let's split our products into 2 subarrays:
  const [automatedSuborders, manualSuborders] = partition(
    Object.values(products),
    product => !!product.source__order_automation_platform__id
  );
  const countAutomatedVendors = (automatedSuborders && automatedSuborders.length) || 0;
  const countManualOrders = (manualSuborders && manualSuborders.length) || 0;

  trackSegmentEvent({
    event: 'order_processing_end',
    data: {
      agent_user_id: agentUserId,
      order_id: orderId,
      order_status: orderStatus,
      count_automated_vendors: countAutomatedVendors,
      count_manual_orders: countManualOrders,
      count_retailers: countRetailers
    }
  });
};